import { all, find, path } from 'ramda';

/**
 * B1: Mandatory inclusion criteria:
 * - "Kyllä" tulos edellyttää, että kaikki alakohdat ovat "Kyllä"
 * - "Ei" tulos edellyttää, että  vähintään 1/3 alakohdista on "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const B1 = (d: IPSP): TYesNoUnknown => {
  const mandatoryInclusionCriteriaFields = [
    'sporadicOccurence',
    'age40OrOlderAtOnsetOfFirstPSPRelatedSymptom',
    'gradualProgressionOfPSPRelatedSymptoms',
  ];
  if (all((field: string) => path([field], d) === 'yes', mandatoryInclusionCriteriaFields)) return 'yes';
  if (find((field: string) => path([field], d) === 'no', mandatoryInclusionCriteriaFields)) return 'no';
  return 'unknown';
};

/**
 * B2: Mandatory exclusion criteria:
 * - "Kyllä" tulos edellyttää, että vähintään 1/10 alakohdista on "Kyllä"
 * - "Ei" tulos edellyttää, että 10/10 alakohdista on "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const B2 = (d: IPSP): TYesNoUnknown => {
  const mandatoryExclusionCriteriaFields = [
    'predominantUnexplainedImpairmentOfEpisodicMemory',
    'predominantUnexplainedAutonomicFailure',
    'predominantUnexplainedVisualHallucinations',
    'predominantUnexplainedMultisegmentalUpperAndLowerMotorNeuronSigns',
    'suddenOnsetOrStepWiseOrRapidProgressionOfSymptoms',
    'historyOfEncephalitis',
    'prominentAppendicularAtaxia',
    'identifiableCauseOfPosturalInstability',
    'severeLeukoencephalopathy',
    'relevantStructuralAbnormality',
  ];
  if (find((field: string) => path([field], d) === 'yes', mandatoryExclusionCriteriaFields)) return 'yes';
  if (all((field: string) => path([field], d) === 'no', mandatoryExclusionCriteriaFields)) return 'no';
  return 'unknown';
};

/**
 * B3: Context dependent exclusion criteria:
 * - "Kyllä" tulos edellyttää, että "Suggestive clinical findings of other conditions are present" on Kyllä,
 *     ja [vähintään yksi imaging findings tai Laboratory findings alakohdista on "Can not be excluded"
 *         tai ("At least one first- or second-degree relative has a PSP-like syndrome with a Mendelian inheritance trait or known rare variants" on Kyllä"
 *               ja vähintään yksi Genetic findings alakohdista on "Can not be excluded")]
 * - "Ei" tulos edellyttää, että "Suggestive clinical findings of other conditions are present" on Ei,
 *     tai [kaikki imaging findings ja Laboratory findings alakohdista on "Excluded"
 *         ja ("At least one first- or second-degree relative has a PSP-like syndrome with a Mendelian inheritance trait or known rare variants" on Ei"
 *             tai kaikki Genetic findings alakohdista on "Excluded")]
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const B3 = (d: IPSP): TYesNoUnknown => {
  const imagingFindingsFields = ['inSyndromesWithSuddenOnsetOrStepWiseProgression', 'inCasesWithVeryRapidProgression'];
  const laboratoryFindingsFields = [
    'inPatientsWithPSPCBS',
    'wilsonsDisease',
    'niemannPickDiseaseTypeCLessThan45',
    'hypoparathyroidism',
    'neuroacanthocytosis',
    'neurosyphilis',
    'prionDisease',
    'paraneoplasticEncephalitis',
    'inPatientsWithSuggestiveFeatures',
  ];
  const geneticFindigsFields = [
    'MAPTRareVariants',
    'MAPTH2HaplotypeHomozygosity',
    'LRRK2AndParkinRareVariants',
    'nonMAPTAssociatedFrontotemporalDementia',
    'PD',
    'AD',
    'niemannPickDiseaseTypeC',
    'kuforRakebSyndrome',
    'perrySyndrome',
    'mitochondrialDiseases',
    'dentatorubralPallidoluysianAtrophy',
    'prionRelatedDiseases',
    'huntingtonsDisease',
    'spinocerebellarAtaxia',
  ];
  if (
    d.suggestiveClinicalFindingsOfOtherConditions === 'yes' &&
    (find((field: string) => path([field], d) === 'canNotBeExcluded', imagingFindingsFields) ||
      find((field: string) => path([field], d) === 'canNotBeExcluded', laboratoryFindingsFields) ||
      (d.atLeastOneFirstOrSecondDegreeRelativeHasPSPLikeSyndrome === 'yes' &&
        find((field: string) => path([field], d) === 'canNotBeExcluded', geneticFindigsFields)))
  )
    return 'yes';
  if (
    d.suggestiveClinicalFindingsOfOtherConditions === 'no' ||
    (all((field: string) => path([field], d) === 'excluded', imagingFindingsFields) &&
      all((field: string) => path([field], d) === 'excluded', laboratoryFindingsFields) &&
      (d.atLeastOneFirstOrSecondDegreeRelativeHasPSPLikeSyndrome === 'no' ||
        all((field: string) => path([field], d) === 'excluded', geneticFindigsFields)))
  )
    return 'no';
  return 'unknown';
};

/**
 * C1 Speech/language disorder:
 * - "Kyllä" tulos edellyttää, että vähintään toinen alakohdista on "Kyllä"
 * - "Ei" tulos edellyttää, että  molemmat alakohdat ovat "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const C1 = (d: IPSP): TYesNoUnknown => {
  if (d.C1NonfluentAgrammaticVariantOfPrimaryLossOfGrammar === 'yes' || d.C1ProgressiveApraxiaOfSpeech === 'yes')
    return 'yes';
  if (d.C1NonfluentAgrammaticVariantOfPrimaryLossOfGrammar === 'no' && d.C1ProgressiveApraxiaOfSpeech === 'no')
    return 'no';

  return 'unknown';
};

/**
 * C2 Frontal cognitive/behavioral presentation:
 * - "Kyllä" tulos edellyttää, että alakohdista vähintään 3/5 on "Kyllä"
 * - "Ei" tulos edellyttää, että  alakohdista vähintään 3/5 ovat "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const C2 = (d: IPSP): TYesNoUnknown => {
  const fields = [
    'C2Apathy',
    'C2Bradyphrenia',
    'C2DysexecutiveSyndrome',
    'C2ReducedPhonemicVerbalFluency',
    'C2ImpulsivityDisinhibitionOrPerseveration',
  ];
  const filteredYes = fields.filter((f) => (d as { [key: string]: any })[f] === 'yes');
  const filteredNo = fields.filter((f) => (d as { [key: string]: any })[f] === 'no');

  if (filteredYes.length >= 3) return 'yes';
  if (filteredNo.length >= 3) return 'no';

  return 'unknown';
};

/**
 * C3 CBS:
 * - "Kyllä" tulos edellyttää, että vähintään 1/3 Cortical signs ja 1/3 Movement disorder signsalakohdista on "Kyllä"
 * - "Ei" tulos edellyttää, että  joko 3/3 Cortical signs tai 3/3 Movement disorder signs alakohdista on "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const C3 = (d: IPSP): TYesNoUnknown => {
  const cortical = ['C3OrobuccalOrLimbApraxia', 'C3CorticalSensoryDeficit', 'C3AlienLimbPhenomena'];
  const movement = ['C3LimbRigidity', 'C3LimbAkinesia', 'C3LimbMyoclonus'];

  const filteredCorticalYes = cortical.filter((f) => (d as { [key: string]: any })[f] === 'yes');
  const filteredCorticalNo = cortical.filter((f) => (d as { [key: string]: any })[f] === 'no');

  const filteredMovementYes = movement.filter((f) => (d as { [key: string]: any })[f] === 'yes');
  const filteredMovementNo = movement.filter((f) => (d as { [key: string]: any })[f] === 'no');

  if (filteredCorticalYes.length >= 1 && filteredMovementYes.length >= 1) return 'yes';
  if (filteredCorticalNo.length === 3 || filteredMovementNo.length === 3) return 'no';

  return 'unknown';
};

/**
 * Probable PSP-RS (PSP with Richardson's syndrome):
 *   (O1 or O2) + (P1 or P2)
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että O1 tai O2 on "Kyllä" ja (P1 tai P2 on "Kyllä")
 * - "Ei" vastaus edellyttää, että  O1 ja O2 ovat "Ei" tai (P1 ja P2 ovat "Ei")
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const probablePSPRS = (d: IPSP): TYesNoUnknown => {
  if (
    (d.O1VerticalSupranuclearGazePalsy === 'yes' || d.O2SlowVelocityOfVerticalSaccades === 'yes') &&
    (d.P1RepeatedUnprovokedFallsWithin3Years === 'yes' || d.P2TendencyToFallOnThePulltestWithin3Years === 'yes')
  )
    return 'yes';
  if (
    (d.O1VerticalSupranuclearGazePalsy === 'no' && d.O2SlowVelocityOfVerticalSaccades === 'no') ||
    (d.P1RepeatedUnprovokedFallsWithin3Years === 'no' && d.P2TendencyToFallOnThePulltestWithin3Years === 'no')
  )
    return 'no';

  return 'unknown';
};

/**
 * Probable PSP-PGF (PSP with progressive gait freezing):
 *   (O1 or O2) + A1
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että O1 tai O2 on "Kyllä" ja A1 on "Kyllä"
 * - "Ei" vastaus edellyttää, että  O1 ja O2 ovat "Ei" tai A1 on "Ei"
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const probablePSPPGF = (d: IPSP): TYesNoUnknown => {
  if (
    (d.O1VerticalSupranuclearGazePalsy === 'yes' || d.O2SlowVelocityOfVerticalSaccades === 'yes') &&
    d.A1ProgressiveGaitFreezingWithin3Years === 'yes'
  )
    return 'yes';
  if (
    (d.O1VerticalSupranuclearGazePalsy === 'no' && d.O2SlowVelocityOfVerticalSaccades === 'no') ||
    d.A1ProgressiveGaitFreezingWithin3Years === 'no'
  )
    return 'no';

  return 'unknown';
};

/**
 * Probable PSP-P (PSP with predominant parkinsonism):
 *   (O1 or O2) + (A2 or A3)
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että O1 tai O2 on "Kyllä" ja (A2 tai A3 ovat "Kyllä")
 * - "Ei" vastaus edellyttää, että  O1 ja O2 ovat "Ei" tai (A2 ja A3 ovat "Ei")
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const probablePSPP = (d: IPSP): TYesNoUnknown => {
  if (
    (d.O1VerticalSupranuclearGazePalsy === 'yes' || d.O2SlowVelocityOfVerticalSaccades === 'yes') &&
    (d.A2ParkinsonismAkineticrigidPredominantlyAxialAndLevodopaResistant === 'yes' ||
      d.A3ParkinsonismWithTremorAndOrAsymmetricAndOrLevodopaResponsive === 'yes')
  )
    return 'yes';
  if (
    (d.O1VerticalSupranuclearGazePalsy === 'no' && d.O2SlowVelocityOfVerticalSaccades === 'no') ||
    (d.A2ParkinsonismAkineticrigidPredominantlyAxialAndLevodopaResistant === 'no' &&
      d.A3ParkinsonismWithTremorAndOrAsymmetricAndOrLevodopaResponsive === 'no')
  )
    return 'no';

  return 'unknown';
};

/**
 * Probable PSP-F (PSP with predominant frontal presentation):
 *   (O1 or O2) + C2
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että O1 tai O2 on "Kyllä" ja C2 on "Kyllä"
 * - "Ei" vastaus edellyttää, että  O1 ja O2 ovat "Ei" tai C2 on "Ei"
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const probablePSPF = (d: IPSP): TYesNoUnknown => {
  if ((d.O1VerticalSupranuclearGazePalsy === 'yes' || d.O2SlowVelocityOfVerticalSaccades === 'yes') && C2(d) === 'yes')
    return 'yes';
  if ((d.O1VerticalSupranuclearGazePalsy === 'no' && d.O2SlowVelocityOfVerticalSaccades === 'no') || C2(d) === 'no')
    return 'no';

  return 'unknown';
};

/**
 * Possible PSP-OM (PSP with predominant ocular motor dysfunction):
 *   O1
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että O1 on "Kyllä"
 * - "Ei" vastaus edellyttää, että  O1 on "Ei"
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const possiblePSPOM = (d: IPSP): TYesNoUnknown => {
  if (d.O1VerticalSupranuclearGazePalsy === 'yes') return 'yes';
  if (d.O1VerticalSupranuclearGazePalsy === 'no') return 'no';

  return 'unknown';
};

/**
 * Possible PSP-RS (PSP with Richardson’s syndrome):
 *  O2 + P3
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että O2 on "Kyllä" ja P3 on "Kyllä"
 * - "Ei" vastaus edellyttää, että  O2 tai P3 on "Ei"
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const possiblePSPRS = (d: IPSP): TYesNoUnknown => {
  if (d.O2SlowVelocityOfVerticalSaccades === 'yes' && d.P3MoreThanTwoStepsBackwardOnThePulltestWithin3Years === 'yes')
    return 'yes';
  if (d.O2SlowVelocityOfVerticalSaccades === 'no' || d.P3MoreThanTwoStepsBackwardOnThePulltestWithin3Years === 'no')
    return 'no';

  return 'unknown';
};

/**
 * Possible PSP-PGF (PSP with progressive gait freezing):
 *  A1
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että A1 on "Kyllä"
 * - "Ei" vastaus edellyttää, että  A1 on "Ei"
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const possiblePSPPGF = (d: IPSP): TYesNoUnknown => {
  if (d.A1ProgressiveGaitFreezingWithin3Years === 'yes') return 'yes';
  if (d.A1ProgressiveGaitFreezingWithin3Years === 'no') return 'no';

  return 'unknown';
};

/**
 * Possible PSP-SL (PSP with predominant speech/language disorder):
 *  (O1 or O2) + C1
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että O1 tai O2 on "Kyllä" ja C1 on "Kyllä"
 * - "Ei" vastaus edellyttää, että  O1 ja O2 ovat "Ei" tai C1 on "Ei"
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const possiblePSPSL = (d: IPSP): TYesNoUnknown => {
  if ((d.O1VerticalSupranuclearGazePalsy === 'yes' || d.O2SlowVelocityOfVerticalSaccades === 'yes') && C1(d) === 'yes')
    return 'yes';
  if ((d.O1VerticalSupranuclearGazePalsy === 'no' && d.O2SlowVelocityOfVerticalSaccades === 'no') || C1(d) === 'no')
    return 'no';

  return 'unknown';
};

/**
 * Possible PSP-CBS (PSP with predominant CBS):
 *  (O1 or O2) + C3
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että O1 tai O2 on "Kyllä" ja C3 on "Kyllä"
 * - "Ei" vastaus edellyttää, että  O1 ja O2 ovat "Ei" tai C3 on "Ei"
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const possiblePSPCBS = (d: IPSP): TYesNoUnknown => {
  if ((d.O1VerticalSupranuclearGazePalsy === 'yes' || d.O2SlowVelocityOfVerticalSaccades === 'yes') && C3(d) === 'yes')
    return 'yes';
  if ((d.O1VerticalSupranuclearGazePalsy === 'no' && d.O2SlowVelocityOfVerticalSaccades === 'no') || C3(d) === 'no')
    return 'no';

  return 'unknown';
};

/**
 * Suggestive of PSP-OM (PSP with predominant ocular motor dysfunction)
 *  O2 or O3
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että O2 on "Kyllä" tai O3 on "Kyllä"
 * - "Ei" vastaus edellyttää, että  O2 ja O3 ovat "Ei"
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const suggestivePSPOM = (d: IPSP): TYesNoUnknown => {
  if (d.O2SlowVelocityOfVerticalSaccades === 'yes' || d.O3FrequentMacroSquareWaveJerksOrEyelidOpeningApraxia === 'yes')
    return 'yes';
  if (d.O2SlowVelocityOfVerticalSaccades === 'no' && d.O3FrequentMacroSquareWaveJerksOrEyelidOpeningApraxia === 'no')
    return 'no';

  return 'unknown';
};

/**
 * Suggestive of PSP-PI (PSP with predominant postural instability)
 *  P1 or P2
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että P1 on "Kyllä" tai P2 on "Kyllä"
 * - "Ei" vastaus edellyttää, että  P1 ja P2 ovat "Ei"
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const suggestivePSPPI = (d: IPSP): TYesNoUnknown => {
  if (d.P1RepeatedUnprovokedFallsWithin3Years === 'yes' || d.P2TendencyToFallOnThePulltestWithin3Years === 'yes')
    return 'yes';
  if (d.P1RepeatedUnprovokedFallsWithin3Years === 'no' && d.P2TendencyToFallOnThePulltestWithin3Years === 'no')
    return 'no';

  return 'unknown';
};

/**
 * Suggestive of PSP-RS (PSP with Richardson’s syndrome)
 *  O3 + (P2 or P3)
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että O3 on "Kyllä" ja (P2 tai P3 on "Kyllä")
 * - "Ei" vastaus edellyttää, että  O3 on "Ei" tai (P2 ja P3 ovat "Ei")
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const suggestivePSPRS = (d: IPSP): TYesNoUnknown => {
  if (
    d.O3FrequentMacroSquareWaveJerksOrEyelidOpeningApraxia === 'yes' &&
    (d.P2TendencyToFallOnThePulltestWithin3Years === 'yes' ||
      d.P3MoreThanTwoStepsBackwardOnThePulltestWithin3Years === 'yes')
  )
    return 'yes';
  if (
    d.O3FrequentMacroSquareWaveJerksOrEyelidOpeningApraxia === 'no' ||
    (d.P2TendencyToFallOnThePulltestWithin3Years === 'no' &&
      d.P3MoreThanTwoStepsBackwardOnThePulltestWithin3Years === 'no')
  )
    return 'no';

  return 'unknown';
};

/**
 * Suggestive of PSP-P (PSP with predominant parkinsonism)
 *  (A2 or A3) + (O3, P1, P2, C1, C2, CC1, CC2, CC3, or CC4)
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että A2 tai A3 on "Kyllä" ja (O3, P1, P2, C1, C2, CC1, CC2, CC3 tai CC4 on "Kyllä")
 * - "Ei" vastaus edellyttää, että  A2 ja A3 ovat "Ei" tai (O3, P1, P2, C1, C2, CC1, CC2, CC3 ja CC4 ovat "Ei")
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const suggestivePSPP = (d: IPSP): TYesNoUnknown => {
  const otherFields = [
    'O3FrequentMacroSquareWaveJerksOrEyelidOpeningApraxia',
    'P1RepeatedUnprovokedFallsWithin3Years',
    'P2TendencyToFallOnThePulltestWithin3Years',
    'CC1LevodopaResistance',
    'CC2HypokineticSpasticDysarthria',
    'CC3Dysphagia',
    'CC4Photophobia',
  ];

  const filteredOptYes = otherFields.filter((f) => (d as { [key: string]: any })[f] === 'yes');
  const filteredOptNo = otherFields.filter((f) => (d as { [key: string]: any })[f] === 'no');

  const othersYes = filteredOptYes.length >= 1 || C1(d) === 'yes' || C2(d) === 'yes';
  const othersNo = filteredOptNo.length === otherFields.length && C1(d) === 'no' && C2(d) === 'no';

  if (
    (d.A2ParkinsonismAkineticrigidPredominantlyAxialAndLevodopaResistant === 'yes' ||
      d.A3ParkinsonismWithTremorAndOrAsymmetricAndOrLevodopaResponsive === 'yes') &&
    othersYes
  )
    return 'yes';

  if (
    (d.A2ParkinsonismAkineticrigidPredominantlyAxialAndLevodopaResistant === 'no' &&
      d.A3ParkinsonismWithTremorAndOrAsymmetricAndOrLevodopaResponsive === 'no') ||
    othersNo
  )
    return 'no';

  return 'unknown';
};

/**
 * Suggestive of PSP-SL (PSP with predominant speech/language disorder)
 *  C1
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että C1 on "Kyllä"
 * - "Ei" vastaus edellyttää, että  C1 on "Ei"
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const suggestivePSPSL = (d: IPSP): TYesNoUnknown => {
  if (C1(d) === 'yes') return 'yes';
  if (C1(d) === 'no') return 'no';

  return 'unknown';
};

/**
 * Suggestive of PSP-F (PSP with predominant frontal presentation)
 *  C2 + (O3 or P3)
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että C2 on "Kyllä" ja (O2 tai P3 on "Kyllä")
 * - "Ei" vastaus edellyttää, että  C2 on "Ei" tai (O2 ja P3 ovat "Ei")
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const suggestivePSPF = (d: IPSP): TYesNoUnknown => {
  if (
    C2(d) === 'yes' &&
    (d.O3FrequentMacroSquareWaveJerksOrEyelidOpeningApraxia === 'yes' ||
      d.P3MoreThanTwoStepsBackwardOnThePulltestWithin3Years === 'yes')
  )
    return 'yes';
  if (
    C2(d) === 'no' ||
    (d.O3FrequentMacroSquareWaveJerksOrEyelidOpeningApraxia === 'no' &&
      d.P3MoreThanTwoStepsBackwardOnThePulltestWithin3Years === 'no')
  )
    return 'no';

  return 'unknown';
};

/**
 * Suggestive of PSP-CBS (PSP with predominant CBS)
 *  C3
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että C3 on "Kyllä"
 * - "Ei" vastaus edellyttää, että  C3 on "Ei"
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const suggestivePSPCBS = (d: IPSP): TYesNoUnknown => {
  if (C3(d) === 'yes') return 'yes';
  if (C3(d) === 'no') return 'no';

  return 'unknown';
};
