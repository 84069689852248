import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import { FormattedMessage } from 'react-intl';

const optionFormatter =
  (opt: number | string) =>
  (name: number | string): JSX.Element => <FormattedMessage id={`clinicalstudy.${opt}.${name}`} />;

const OCTSCANForm = ({ formData }: IOwnProps): JSX.Element => (
  <React.Fragment>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={true}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="clinicalstudy.result">
      <InputHandler
        type="Radio"
        editing={true}
        name="result"
        formData={formData}
        options={['normal', 'abnormal']}
        optionFormatter={optionFormatter('opts.normalAbnormal')}
      />
    </FormRow>
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<IOCTSCAN>;
}

export default OCTSCANForm;
