import { exists } from 'neuro-utils';
import { resolvePath } from '../../utils';

const getBodyPart = (bodyPart: string): string => {
  const separate = bodyPart.split(/(?=[A-Z])/);
  return separate.slice(0, separate.length - 1).join('');
};

const getSide = (bodyPart: string): string => {
  const separate = bodyPart.split(/(?=[A-Z])/);
  return separate[separate.length - 1];
};

const allNormal = (values: { [key: string]: any }): boolean => {
  return Object.keys(values).filter((key) => {
    return key === 'limbStrength'
      ? Object.keys(values[key]).filter((key2) => {
          return exists(values[key][key2]) && values[key][key2] !== 5 && values[key][key2] !== 'notDone';
        }).length > 0
      : key === 'reflexes'
        ? Object.keys(values[key]).filter((key2) => {
            return ['biceps', 'triceps', 'brachioradialis', 'patella', 'achilles'].includes(getBodyPart(key2))
              ? exists(values[key][key2]) && values[key][key2] !== 2 && values[key][key2] !== 'notDone'
              : exists(values[key][key2]) && values[key][key2] !== 0 && values[key][key2] !== 'notDone';
          }).length > 0
        : undefined;
  }).length > 0
    ? false
    : true;
};

const allNotDoneOrUndefined = (values: { [key: string]: any }): boolean => {
  return Object.keys(values).filter((key) => {
    return !Object.keys(values?.[key]).every(
      (key2) => !exists(values?.[key][key2]) || values?.[key][key2] === 'notDone',
    );
  }).length > 0
    ? false
    : true;
};

const pyramidalCalculator = (values: { [key: string]: any }): number | undefined => {
  const ambulatoryScore = values ? values?.ambulatoryScore ?? 0 : 0;
  const p = values ? values?.pyramidalFunctions ?? {} : {};

  const countMuscles = (minValue: number): number => {
    return Object.keys(p?.limbStrength ?? {})
      .filter((key: string) => {
        return exists(p?.limbStrength[key]) && p?.limbStrength[key] < minValue;
      })
      .map((muscle) => getBodyPart(muscle)).length;
  };

  const countLimbsByMuscle = (minValue: number, requireAll?: boolean, location?: string): number => {
    const upperLimbMuscles = ['deltoid', 'biceps', 'triceps', 'wristFingerFlexors', 'wristFingerExtensors'];
    const lowerLimbMuscles = ['kneeFlexors', 'kneeExtensors', 'plantarFlexion', 'dorsiflexion'];

    const checkLimb = (side: string, isUpper: boolean): boolean => {
      return (
        Object.keys(p?.limbStrength ?? {}).filter((key: string) => {
          return getSide(key) === side && (isUpper ? upperLimbMuscles : lowerLimbMuscles).includes(getBodyPart(key))
            ? exists(p?.limbStrength[key]) && p?.limbStrength[key] < minValue
            : undefined;
        }).length > (requireAll ? (isUpper ? 4 : 3) : 0)
      );
    };

    return location === 'lower'
      ? Number(checkLimb('Right', false)) + Number(checkLimb('Left', false))
      : location === 'Right'
        ? Number(checkLimb('Right', true)) + Number(checkLimb('Right', false))
        : location === 'Left'
          ? Number(checkLimb('Left', true)) + Number(checkLimb('Left', false))
          : Number(checkLimb('Right', true)) +
            Number(checkLimb('Left', true)) +
            Number(checkLimb('Right', false)) +
            Number(checkLimb('Left', false));
  };

  let score = 0;

  if (allNotDoneOrUndefined(p)) {
    if (ambulatoryScore !== 1) {
      return undefined;
    }
  }
  if (allNormal(p)) {
    score = 0;
  }
  if (!allNormal(p)) {
    score = 1;
  }
  if (ambulatoryScore === 1 || resolvePath('spasticity.overallMotorPerformance', p) > 0 || countMuscles(5) > 0) {
    score = 2;
  }
  if (countMuscles(5) > 2 || countMuscles(4) > 0) {
    score = 3;
  }
  if (countLimbsByMuscle(3) > 1 || countLimbsByMuscle(2) > 0 || countLimbsByMuscle(4) > 2) {
    score = 4;
  }
  if (
    countLimbsByMuscle(3) > 2 ||
    countLimbsByMuscle(2, true, 'lower') > 1 ||
    countLimbsByMuscle(2, true, 'Right') === 2 ||
    countLimbsByMuscle(2, true, 'Left') === 2
  ) {
    score = 5;
  }
  if (countLimbsByMuscle(2, true) > 3) {
    score = 6;
  }
  return score;
};

export default pyramidalCalculator;
