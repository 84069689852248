import * as React from 'react';
import FormRow from '../../../../../../../../components/FormRow';
import FormSectionHistoryAcceptor from '../../../../../../../../components/FormSectionHistoryAcceptor';
import InputHandler from '../../../../../../../../components/InputHandler';
import { fm } from 'Components/FormatMessage';
import { StyledDescriptionField } from 'Routes/Diagnosis/utils/styled';
import { ISection } from '../../../../../../utils/definitions';

const optionFormatter = (s?: React.ReactText): JSX.Element =>
  s ? fm(`diagnosis.msDiagnosticCriteria.mcDonald2010DiagnosticCriteria.mcDonald2010.opts.${s}`) : <>-</>;

const mcDonald2010Description: Array<ISection> = [
  {
    marginTop: 0,
    section: 'description2010RRMS',
    descriptions: [
      {
        section: 'description2010RRMS.opts',
        fields: ['1'],
        marginLeft: 0,
      },
      {
        section: 'description2010RRMS.opts',
        fields: ['2'],
        marginLeft: 0,
      },
      {
        section: 'description2010RRMS.opts',
        fields: ['3'],
        marginLeft: 0,
      },
      {
        section: 'description2010RRMS.opts',
        fields: ['4'],
        marginLeft: 0,
      },
    ],
  },
  {
    marginTop: 2,
    section: 'description2010PPMS',
    descriptions: [
      {
        section: 'description2010PPMS.opts',
        fields: ['title'],
        marginLeft: 0,
      },
      {
        section: 'description2010PPMS.opts',
        fields: ['a'],
        marginLeft: 3,
      },
      {
        section: 'description2010PPMS.opts',
        fields: ['b'],
        marginLeft: 3,
      },
      {
        section: 'description2010PPMS.opts',
        fields: ['c'],
        marginLeft: 3,
      },
    ],
  },
];

const MakeFormRow = ({
  title,
  description,
  name,
  formData,
  viewing,
  bottomMargin = true,
}: {
  title: string;
  description?: JSX.Element;
  name: string;
  formData: IOwnProps['formData'];
  viewing: boolean;
  bottomMargin?: boolean;
}): JSX.Element => {
  return (
    <FormRow title={title} description={description} headerWidth={6} bottomMargin={bottomMargin}>
      <InputHandler
        editing={!viewing}
        formData={formData}
        name={name}
        type="Select"
        width={9}
        optionFormatter={optionFormatter}
        options={['1', '2', '3', '4', '5']}
        placeholder={'diagnosis.msDiagnosticCriteria.criteria.placeholder'}
      />
    </FormRow>
  );
};

const McDonald2010 = ({ documents, formData, view, editingID }: IOwnProps): JSX.Element => {
  const isViewing = !editingID && !!view?.viewing;
  const docID = editingID ?? view?.viewing;

  return (
    <React.Fragment>
      <FormSectionHistoryAcceptor
        formData={formData}
        documentID={docID}
        documents={documents}
        optionFormatter={optionFormatter}
        header="diagnosis.msDiagnosticCriteria.mcDonald2010DiagnosticCriteria.title"
        hideCopyButton={true}
      >
        {{
          description: {
            header: (
              <div style={{ marginBottom: '4.5rem' }}>
                <StyledDescriptionField
                  sections={mcDonald2010Description}
                  sectionPath="diagnosis.msDiagnosticCriteria.mcDonald2010DiagnosticCriteria.mcDonald2010.descOpts"
                  path="diagnosis.msDiagnosticCriteria.mcDonald2010DiagnosticCriteria.mcDonald2010.descOpts"
                  title={undefined}
                  sectionTitle="title"
                />
              </div>
            ),
          },
          mcDonald2010: {
            element: (
              <MakeFormRow
                title="diagnosis.msDiagnosticCriteria.mcDonald2010DiagnosticCriteria.mcDonald2010.title"
                name="mcDonald2010"
                formData={formData}
                viewing={isViewing}
                bottomMargin={false}
              />
            ),
          },
        }}
      </FormSectionHistoryAcceptor>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents?: Array<IMSCriteria>;
  formData: IFormData<IMSCriteria>;
  view?: IView;
  editingID?: string;
}

export default McDonald2010;
