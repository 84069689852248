import * as React from 'react';
import PlasmaChangeForm from './PlasmaChange';
import ThymectomyForm from './Thymectomy';

const ProceduresForm = ({ formData, editing, procedureCode }: IOwnProps): JSX.Element | null => {
  return procedureCode === '19647005' ? (
    <PlasmaChangeForm formData={formData} editing={editing} />
  ) : procedureCode === '399750009' ? (
    <ThymectomyForm formData={formData} editing={editing} />
  ) : null;
};

interface IOwnProps {
  formData: IFormData<IProcedure>;
  editing: string;
  procedureCode: string;
}

export default ProceduresForm;
