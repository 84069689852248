import * as React from 'react';

import FormRow from '../../../../components/FormRow';
import FormSection from '../../../../components/FormSection';

import { formatPartialDate } from 'neuro-utils';
import { FormattedMessage } from 'react-intl';
import EventStepper from '../../../../components/EventStepper';
import InputHandler from '../../../../components/InputHandler';

const optFormatter = (name: string | number): JSX.Element => <FormattedMessage id={`nutrition.opts.${name}`} />;

const NutritionForm = ({ editing, formData, document }: IOwnProps): JSX.Element => {
  return (
    <React.Fragment>
      <FormSection header="nutrition.title">
        {editing ? (
          <EventStepper
            name="feedingTubes"
            formData={formData}
            stepLabelText={(d: IFeedingTube): JSX.Element => (
              <span>
                {formatPartialDate(d.startDate)}
                {d.endDate && ` - ${formatPartialDate(d.endDate)}`}
              </span>
            )}
            stepContent={(d: IFeedingTube): JSX.Element => (
              <div>
                {d.feedingTubeType ? <FormattedMessage id={`nutrition.opts.${d.feedingTubeType}`} /> : ''}
                <br />
                {d.feedingTubePractice ? <FormattedMessage id={`nutrition.opts.${d.feedingTubePractice}`} /> : ''}
              </div>
            )}
            addNewTextHeader="nutrition.newFeedingTubeType"
            addNewTextButton="nutrition.newFeedingTubeUse"
            previousEventsTextHeader="nutrition.previousFeedingTubes"
            noConcurrence={true}
            noConcurrenceText="nutrition.completeFeedingTubeUse"
            editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => (
              <React.Fragment>
                <div style={{ marginBottom: '2rem' }}>
                  <FormattedMessage id="nutrition.startDate" />
                  <InputHandler
                    type="PartialDate"
                    editing={true}
                    name="startDate"
                    formData={{
                      onChange,
                      document: {
                        startDate: formData.document.feedingTubes?.[index]?.startDate,
                      },
                    }}
                    dateDefault={'now'}
                    isNotCancellable={true}
                    dateHook={{ dateHookCeiling: formData.document.feedingTubes?.[index]?.endDate }}
                  />
                </div>
                <div style={{ marginBottom: '2rem' }}>
                  <FormattedMessage id="nutrition.endDate" />
                  <InputHandler
                    type="PartialDate"
                    editing={true}
                    name="endDate"
                    formData={{
                      onChange,
                      document: {
                        endDate: formData.document.feedingTubes?.[index]?.endDate,
                      },
                    }}
                    dateHook={{ dateHookFloor: formData.document.feedingTubes?.[index]?.startDate }}
                  />
                </div>
                <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                  <FormattedMessage id="nutrition.feedingTubeType" />
                  <InputHandler
                    type="Radio"
                    editing={true}
                    name="feedingTubeType"
                    formData={{
                      onChange,
                      document: {
                        feedingTubeType: formData.document.feedingTubes?.[index]?.feedingTubeType,
                      },
                    }}
                    options={['nasalFeedingTube', 'gastricFeedingTube', 'poAutonomously', 'poAssisted']}
                    optionFormatter={optFormatter}
                  />
                </div>
                <div style={{ marginBottom: '4rem' }}>
                  <FormattedMessage id="nutrition.feedingTubePractice" />
                  <InputHandler
                    type="Radio"
                    editing={true}
                    name="feedingTubePractice"
                    formData={{
                      onChange,
                      document: {
                        feedingTubePractice: formData.document.feedingTubes?.[index]?.feedingTubePractice,
                      },
                    }}
                    options={['onlyWithTube', 'inAdditionWithTube']}
                    optionFormatter={optFormatter}
                  />
                </div>
              </React.Fragment>
            )}
          />
        ) : undefined}
        {
          // If not in editing mode
          !editing && document?.feedingTubes ? (
            <>
              <FormRow title="nutrition.latestFeedingTube">
                {document?.feedingTubes?.[0]?.startDate && (
                  <div>
                    {formatPartialDate(document?.feedingTubes[0].startDate) + ' - '}
                    {document?.feedingTubes?.[0]?.endDate && formatPartialDate(document?.feedingTubes?.[0]?.endDate)}
                  </div>
                )}

                {document.feedingTubes?.[0]?.feedingTubeType ? (
                  <div style={{ fontWeight: 600 }}>
                    <FormattedMessage id={`nutrition.opts.${document.feedingTubes?.[0]?.feedingTubeType}`} />
                  </div>
                ) : undefined}
                {document.feedingTubes?.[0]?.feedingTubePractice ? (
                  <div style={{ fontWeight: 600 }}>
                    <FormattedMessage id={`nutrition.opts.${document.feedingTubes?.[0]?.feedingTubePractice}`} />
                  </div>
                ) : undefined}
              </FormRow>
              {document?.feedingTubes.map((f: IFeedingTube, i: number) => (
                <FormRow
                  key={'feedingTubeHistory' + i}
                  condition={i > 0}
                  title={i === 1 ? 'nutrition.earlierFeedingTubes' : ''}
                >
                  {f.startDate && (
                    <div>
                      {formatPartialDate(f.startDate)}
                      {f.endDate && ` - ${formatPartialDate(f.endDate)}`}
                    </div>
                  )}
                  {f.feedingTubeType && (
                    <div style={{ fontWeight: 600 }}>
                      <FormattedMessage id={`nutrition.opts.${f.feedingTubeType}`} />
                    </div>
                  )}
                  {f.feedingTubePractice && (
                    <div style={{ fontWeight: 600 }}>
                      <FormattedMessage id={`nutrition.opts.${f.feedingTubePractice}`} />
                    </div>
                  )}
                </FormRow>
              ))}
            </>
          ) : undefined
        }
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  editing?: string;
  formData: IFormData<INutrition>;
  document?: INutrition;
}

export default NutritionForm;
