import * as React from 'react';
import { Item } from '../../../../../../components/Grid';
import HistoryRow from '../../../../../../components/HistoryRow';
import HistoryRowControls from '../../../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../../../components/HistoryRowListing';
import { IHistoryContext, withHistoryContext } from '../../../../../../containers/FormContextHandler';
import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import { newButton } from '../../../../utils/functions';

const UNHDRSHistory = ({ documents = [], startEdit, view, fm = (): string => '' }: IHistoryContext): JSX.Element => {
  const uhdrs: Array<IUHDRSDiagnosticConfidenceIndex> = documents
    .filter((d: any) => d._type === 'uhdrsConfidenceIndex')
    .sort((d1: IUHDRSDiagnosticConfidenceIndex, d2: IUHDRSDiagnosticConfidenceIndex) =>
      sortPartialDate(d1.date, d2.date),
    )
    .reverse() as Array<IUHDRSDiagnosticConfidenceIndex>;
  return (
    <HistoryRow
      headerText={fm('diagnosis.huntington.uhdrs.history.header')}
      rowButton={
        startEdit
          ? newButton('uhdrsConfidenceIndex', startEdit({}, 'uhdrsConfidenceIndex'), 'general.new', 15)
          : undefined
      }
    >
      <HistoryRowListing
        documents={uhdrs}
        headers={
          <>
            <Item xs={2}>{fm('general.date')}</Item>
            <Item xs={10}>{fm('diagnosis.huntington.uhdrs.history.index')}</Item>
          </>
        }
        contentFormat={(d: IUHDRSDiagnosticConfidenceIndex): JSX.Element => (
          <>
            <Item xs={2} style={{ fontWeight: '600' }}>
              {d.date && formatPartialDate(d.date)}
            </Item>
            <Item xs={7}>{d.index ? `${d.index} - ` + fm(`diagnosis.huntington.uhdrs.form.opts.${d.index}`) : ''}</Item>
            <Item xs={3}>
              {startEdit ? <HistoryRowControls document={d} startEdit={startEdit} view={view} /> : null}
            </Item>
          </>
        )}
      />
    </HistoryRow>
  );
};

export default withHistoryContext(UNHDRSHistory);
