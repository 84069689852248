import * as React from 'react';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import HistoryRowVerticalItem from 'Components/HistoryRowVerticalItem';
import Unit from 'Components/Unit';
import { DoctorsOrdersContext } from '../..';
import TreatmentsTable from '../Components';
import { subjectOfTreatmentTitle } from 'Utility/ninmtUtil';

const RTMSHistory = ({ d }: IRTMSHistory): JSX.Element => (
  <DoctorsOrdersContext.Consumer>
    {({ fm }) => {
      return (
        d.rtms && (
          <React.Fragment>
            <HistoryRowSubHeader header={fm('doctorsOrders.rtms.header')} />
            <HistoryRowVerticalItem
              header={fm('doctorsOrders.rtms.endRTMS.title')}
              value={d.rtms.endRTMS ? fm('general.yes') : '-'}
              condition={!!d.rtms.endRTMS}
            />
            {!d.rtms.endRTMS && (
              <React.Fragment>
                <HistoryRowVerticalItem
                  header={fm('doctorsOrders.rtms.treatmentFrequency.title')}
                  value={d.rtms.treatmentFrequency}
                  condition={!!d.rtms.treatmentFrequency}
                />
                {!d.rtms.doesNotApplyToRMT && (
                  <React.Fragment>
                    <HistoryRowVerticalItem
                      header={fm('doctorsOrders.rtms.device.title')}
                      value={d.rtms.device ? fm(`doctorsOrders.rtms.device.opts.${d.rtms.device}`) : '-'}
                      condition={!!d.rtms.device}
                    />
                    {d.rtms.device && (
                      <React.Fragment>
                        <HistoryRowVerticalItem
                          header={fm('doctorsOrders.rtms.rmt.title')}
                          value={
                            <Unit unit={fm('doctorsOrders.rtms.rmt.unit')} fontWeight={'bold'}>
                              {d.rtms.rmt ?? '-'}
                            </Unit>
                          }
                          condition={!!d.rtms.rmt}
                        />
                        <HistoryRowVerticalItem
                          header={fm('doctorsOrders.rtms.peelingDepth.title')}
                          value={d.rtms.peelingDepth}
                          condition={!!d.rtms.peelingDepth}
                        />
                        <HistoryRowVerticalItem
                          header={fm('doctorsOrders.rtms.rmtLocation.title')}
                          value={
                            d.rtms.rmtLocation ? fm(`doctorsOrders.rtms.rmtLocation.opts.${d.rtms.rmtLocation}`) : '-'
                          }
                          condition={!!d.rtms.rmtLocation}
                        />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                {!d.rtms.doesNotApplyToSubjectOfTreatment && (
                  <React.Fragment>
                    <TreatmentsTable document={d} treatmentType="rtms" treatmentName="subjectOfTreatment" />
                    <HistoryRowVerticalItem
                      header={fm('doctorsOrders.rtms.backupSubjectOfTreatment.title')}
                      value={d.rtms.backupSubjectOfTreatment?.map((s) => subjectOfTreatmentTitle(s, 'rtms', fm))}
                      condition={Array.isArray(d.rtms.backupSubjectOfTreatment)}
                    />
                    <HistoryRowVerticalItem
                      header={fm('doctorsOrders.rtms.additionalInformation.title')}
                      value={d.rtms.additionalInformation}
                      condition={!!d.rtms.additionalInformation}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            <HistoryRowVerticalItem
              header={fm('doctorsOrders.rtms.otherOrder.title')}
              value={d.rtms.otherOrder}
              condition={!!d.rtms.otherOrder}
            />
          </React.Fragment>
        )
      );
    }}
  </DoctorsOrdersContext.Consumer>
);

interface IRTMSHistory {
  d: IDoctorsOrder;
  mode?: 'default' | 'review';
}

export default RTMSHistory;
