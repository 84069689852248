import * as React from 'react';

import HistoryRowVerticalItem from '../../../../../../components/HistoryRowVerticalItem';
import HistoryRow from '../../../../../../components/HistoryRow';

import { formatPartialDate } from 'neuro-utils';
import { newButton } from '../../../../utils/functions';
import { isNil } from 'Utility/ramdaReplacement';
import { IHistoryContext, withHistoryContext } from '../../../../../../containers/FormContextHandler';

const ParkinsonSymptoms = ({ documents = [], startEdit, fm = (): string => '' }: IHistoryContext): JSX.Element => {
  const parkinsonSymptoms: IParkinsonSymptoms = documents.filter(
    (d) => d._type === 'parkinsonSymptoms',
  )[0] as IParkinsonSymptoms;

  return (
    <HistoryRow
      headerText={fm('diagnosis.parkinsonSymptoms')}
      rowButton={
        startEdit
          ? newButton(
              'parkinsonSymptoms',
              startEdit(parkinsonSymptoms ?? {}, 'parkinsonSymptoms'),
              'general.edit',
              undefined,
              undefined,
              !isNil(((parkinsonSymptoms ?? {}) as IControlProps)._lockedFor),
              undefined,
              undefined,
              parkinsonSymptoms,
            )
          : undefined
      }
    >
      <React.Fragment>
        <HistoryRowVerticalItem
          header={fm('general.date')}
          value={parkinsonSymptoms?.symptomOnsetDate ? formatPartialDate(parkinsonSymptoms.symptomOnsetDate) : ' - '}
        />
        <HistoryRowVerticalItem
          header={fm('diagnosis.parkinsonEarlySymptom.title')}
          value={
            parkinsonSymptoms?.parkinsonEarlySymptom && parkinsonSymptoms.parkinsonEarlySymptom.length > 0
              ? parkinsonSymptoms.parkinsonEarlySymptom.map((s) => fm(`diagnosis.parkinsonEarlySymptom.${s}`))
              : '-'
          }
        />
        <HistoryRowVerticalItem
          header={fm('diagnosis.parkinsonSymptomOnsetOther')}
          value={
            parkinsonSymptoms?.parkinsonSymptomOnsetOther && parkinsonSymptoms.parkinsonSymptomOnsetOther?.length > 0
              ? parkinsonSymptoms.parkinsonSymptomOnsetOther
              : ' - '
          }
        />
        <HistoryRowVerticalItem
          header={fm('diagnosis.parkinsonMotoricSymptomOnset.title')}
          value={fm(
            `diagnosis.parkinsonMotoricSymptomOnset.${
              parkinsonSymptoms?.parkinsonMotoricSymptomOnset
                ? 'opts.' + parkinsonSymptoms?.parkinsonMotoricSymptomOnset
                : 'empty'
            }`,
          )}
        />
        {parkinsonSymptoms?.parkinsonMotoricSymptomOnset === 'other' && (
          <HistoryRowVerticalItem
            header={fm('diagnosis.parkinsonMotoricSymptomOnset.otherSymptom')}
            value={parkinsonSymptoms?.parkinsonMotoricSymptomOnsetOtherSymptom}
          />
        )}
        <HistoryRowVerticalItem
          header={fm('diagnosis.parkinsonMotoricSymptomOnsetSide.title')}
          value={fm(
            `diagnosis.parkinsonMotoricSymptomOnsetSide.${
              parkinsonSymptoms?.parkinsonMotoricSymptomOnsetSide
                ? 'opts.' + parkinsonSymptoms.parkinsonMotoricSymptomOnsetSide
                : 'empty'
            }`,
          )}
        />
        <HistoryRowVerticalItem
          header={fm('diagnosis.parkinsonMotoricSymptomOnsetInfo')}
          value={
            parkinsonSymptoms?.parkinsonMotoricSymptomOnsetInfo
              ? parkinsonSymptoms.parkinsonMotoricSymptomOnsetInfo
              : ' - '
          }
        />
      </React.Fragment>
    </HistoryRow>
  );
};

export default withHistoryContext(ParkinsonSymptoms);
