import * as React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { actions } from 'Store/myapp/actions';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import { FormContextProvider } from '../../../containers/FormContextHandler';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import PatientReportedSeizureForm from './Form/PatientReportedSeizureForm';

import SeizuresHistory from './HistoryRowData';
import StandAloneFormEditingHandler from 'Containers/StandAloneFormEditingHandler';

const Seizure = ({ documents }: IOwnProps): JSX.Element => {
  const [patRepSeizFormOpen, setPatRepSeizFormOpen] = React.useState<
    { doc: IPatientReportedSeizure & IControlProps; type: 'edit' | 'view' } | undefined
  >(undefined);
  const [tempPatRepSeiz, setTempPatRepSeiz] = React.useState<(IPatientReportedSeizure & IControlProps) | undefined>(
    undefined,
  );
  const [openTab, setOpenTab] = React.useState<number>(0);
  const [saving, setSaving] = React.useState<boolean>(false);
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });
  const dispatch = useDispatch();

  const savePatRepSeiz = async () => {
    setSaving(true);
    await actions.putNewCommit('patientReportedSeizure', patRepSeizFormOpen?.doc._id ?? '', tempPatRepSeiz, dispatch);
    setSaving(false);
    setPatRepSeizFormOpen(undefined);
  };

  const handleFormChange = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    if (name) {
      setTempPatRepSeiz((prev) => ({ ...(prev as IPatientReportedSeizure & IControlProps), [name]: value }));
    }
  };

  React.useEffect(() => {
    if (patRepSeizFormOpen && patRepSeizFormOpen.doc) {
      setTempPatRepSeiz(structuredClone(patRepSeizFormOpen.doc));
    }
  }, [patRepSeizFormOpen]);

  return (
    <>
      {patRepSeizFormOpen ? (
        <>
          <StandAloneFormEditingHandler
            currentPageName={'seizure'}
            headerLocaleId={'seizure.title'}
            viewing={patRepSeizFormOpen.type === 'view'}
            endViewingAction={() => setPatRepSeizFormOpen(undefined)}
            cancelEditingAction={() => {
              setPatRepSeizFormOpen(undefined);
              setTempPatRepSeiz(undefined);
            }}
            saveAction={() => savePatRepSeiz()}
            loading={saving}
          >
            <PatientReportedSeizureForm
              fm={fm}
              formData={{ document: tempPatRepSeiz ?? {}, onChange: handleFormChange }}
              editing={patRepSeizFormOpen.type === 'edit'}
            />
          </StandAloneFormEditingHandler>
        </>
      ) : (
        <FormEditingHandler name="seizure" documents={documents}>
          {(editing, startEdit, formData, view, saveDraft): JSX.Element => {
            return (
              <DocumentWrapper>
                <DocumentHeader headerId="seizure.title" />

                <FormContextProvider
                  context={{
                    documents: documents,
                    editing: editing,
                    formData: formData,
                    view: view,
                    startEdit: startEdit,
                    saveDraft: saveDraft,
                  }}
                >
                  <SeizuresHistory
                    props={{ documents, editing, formData, view, startEdit, saveDraft, fm }}
                    setPatRepSeizFormOpen={setPatRepSeizFormOpen}
                    openTab={openTab}
                    setOpenTab={setOpenTab}
                  />
                </FormContextProvider>
              </DocumentWrapper>
            );
          }}
        </FormEditingHandler>
      )}
    </>
  );
};

interface IOwnProps {
  documents: Array<ISeizureDoc>;
}

export default Seizure;
