import FormRow from 'Components/FormRow';
import FormSection from 'Components/FormSection';
import InputHandler from 'Components/InputHandler';
import QuestionStepper from 'Components/QuestionStepper';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getCdrClass, getCdrTotalScore, getFtldCdrClass, getFtldCdrTotalScore } from 'Routes/Cognition/utils';
import colors from '../../../../../config/theme/colors';

const cdrSteps: (keyof ICDR)[] = [
  'memory',
  'orientation',
  'judgementAndProblemSolving',
  'communityAffairs',
  'homeAndHobbies',
  'personalCare',
];

const ftldSteps: (keyof ICDR)[] = ['behaviour', 'language'];

const CDRForm = ({ formData, viewing }: IOwnProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const getCdrStepContent = (
    stepName: string,
  ): ReturnType<React.ComponentProps<typeof QuestionStepper>['getStepContent']> => {
    if (stepName === 'personalCare') {
      return {
        opts: [0, 1, 2, 3],
        optionFormatter: (number: number | string) => fm(`cognition.cdr.opts.${stepName}.${number}`),
      };
    }
    return {
      opts: [0, 0.5, 1, 2, 3],
      optionFormatter: (number: number | string) => fm(`cognition.cdr.opts.${stepName}.${number}`),
    };
  };

  return (
    <>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormSection header={'cognition.cdr.cdrTitle'}>
        <div>
          <FormattedMessage id="cognition.cdr.formInfo" values={{ br: <p /> }} />
        </div>
        <div style={{ marginBottom: '5rem', paddingTop: '2rem' }}>
          <QuestionStepper
            viewing={viewing}
            formData={formData}
            steps={cdrSteps}
            getStepContent={getCdrStepContent}
            labelFormatter={(name: string | number) => fm(`cognition.cdr.${name}`)}
          />
        </div>
        <FormRow title="cognition.cdr.cdrClass" bottomMargin={false}>
          <div>{getCdrClass(formData.document) ?? '-'}</div>
        </FormRow>
        <div style={{ color: colors.tertiaryText, marginBottom: '4.5rem' }}>
          <p style={{ margin: 0, marginTop: '1rem' }}>
            0 = <FormattedMessage id="cognition.cdr.cdrClassDesc.0" />
          </p>
          <p style={{ margin: 0 }}>
            0.5 = <FormattedMessage id="cognition.cdr.cdrClassDesc.05" />
          </p>
          <p style={{ margin: 0 }}>
            1 = <FormattedMessage id="cognition.cdr.cdrClassDesc.1" />
          </p>
          <p style={{ margin: 0 }}>
            2 = <FormattedMessage id="cognition.cdr.cdrClassDesc.2" />
          </p>
          <p style={{ margin: 0 }}>
            3 = <FormattedMessage id="cognition.cdr.cdrClassDesc.3" />
          </p>
        </div>
        <FormRow title="cognition.cdr.cdrTotalScore">
          <div>{getCdrTotalScore(formData.document) ?? '-'}</div>
        </FormRow>
        <div style={{ fontStyle: 'italic', color: colors.secondaryText }}>
          <FormattedMessage id="cognition.cdr.referenceText" />
        </div>
      </FormSection>
      <FormSection header={'cognition.cdr.ftldTitle'}>
        <div>
          <FormattedMessage id="cognition.cdr.ftldInfoText" />
        </div>
        <div style={{ paddingTop: '2rem', marginBottom: '5rem' }}>
          <QuestionStepper
            viewing={viewing}
            formData={formData}
            pageType="cognition"
            docType="cdr"
            steps={ftldSteps}
            getStepContent={getCdrStepContent}
            labelFormatter={(name: string | number) => fm(`cognition.cdr.${name}`)}
          />
        </div>
        <FormRow title="cognition.cdr.ftldCdrClass" bottomMargin={false}>
          <div>{getFtldCdrClass(formData.document) ?? '-'}</div>
        </FormRow>
        <div style={{ color: colors.tertiaryText, marginBottom: '4.5rem' }}>
          <p style={{ margin: 0, marginTop: '1rem' }}>
            0 = <FormattedMessage id="cognition.cdr.ftldCdrClassDesc.0" />
          </p>
          <p style={{ margin: 0 }}>
            0.5 = <FormattedMessage id="cognition.cdr.ftldCdrClassDesc.05" />
          </p>
          <p style={{ margin: 0 }}>
            1 = <FormattedMessage id="cognition.cdr.ftldCdrClassDesc.1" />
          </p>
          <p style={{ margin: 0 }}>
            2 = <FormattedMessage id="cognition.cdr.ftldCdrClassDesc.2" />
          </p>
          <p style={{ margin: 0 }}>
            3 = <FormattedMessage id="cognition.cdr.ftldCdrClassDesc.3" />
          </p>
        </div>
        <FormRow title="cognition.cdr.ftldCdrTotalScore">
          <div>{getFtldCdrTotalScore(formData.document) ?? '-'}</div>
        </FormRow>
        <div style={{ fontStyle: 'italic', color: colors.secondaryText }}>
          <FormattedMessage id="cognition.cdr.ftldReferenceText" />
        </div>
      </FormSection>
    </>
  );
};

interface IOwnProps {
  formData: IFormData<ICDR>;
  viewing?: boolean;
}

export default CDRForm;
