import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

import FormRow from '../../../../../../components/FormRow';
import FormSectionHistoryAcceptor from '../../../../../../components/FormSectionHistoryAcceptor';
import InputHandler from '../../../../../../components/InputHandler';
import colors from '../../../../../../config/theme/colors';
import { IFormContext, withFormContext } from '../../../../../../containers/FormContextHandler';

import {
  probableMSA,
  possibleMSA,
  numberOfSupportingFeatures,
  numberOfNonSupportingFeatures,
} from '../../../../utils/MSA';

import { ReferenceText, SubHeader } from '../../../../utils/styled';

const OrHeader = styled('div')({
  fontStyle: 'italic',
  fontSize: '1.8rem',
  margin: '2rem 0',
});

const AndHeader = styled('div')({
  fontWeight: 'bold',
  margin: '2rem 0',
});

const OrGroupStyle = styled('div')({
  backgroundColor: colors.lightestGray,
  padding: '1rem 0 1rem 1rem',
});

const orGroupElement = (elem?: JSX.Element): JSX.Element => <OrGroupStyle>{elem}</OrGroupStyle>;

const formFields = [
  'probableAutonomicFailureInvolvingUrinaryIncontinence',
  'probableOrthostaticDecreaseOfBloodPressure',
  'probablePoorlyLevodopaResponsiveParkinsonism',
  'gaitAtaxiaWithCerebellarDysarthria',
  'limbAtaxia',
  'cerebellarOculomotorDysfunction',
  'possibleBradykinesiaWithRigidity',
  'possibleTremor',
  'possiblePosturalInstability',
  'possibleOtherwiseUnexplainedUrinaryUrgency',
  'possibleFrequencyOrIncompleteBladderEmptying',
  'possibleErectileDysfunctionInMales',
  'significantOrthostaticBloodPressure',
  'possibleBabinskiSignWithHyperreflexia',
  'possibleStridor',
  'possibleMSAPRapidlyProgressiveParkinsonism',
  'possibleMSAPPoorResponseToLevodopa',
  'possibleMSAPPosturalInstabilityWithin3yOfMotorOnset',
  'possibleMSAPGaitAtaxiaCerebellarDysarthriaLimbAtaxiaOrCerebellarOculomotorDysfunction',
  'possibleMSAPDysphagiaWithin5yOfMotorOnset',
  'possibleMSAPAtrophyOnMRIOfPutamenMiddleCerebellarPedunclePonsOrCerebellum',
  'possibleMSAPHypometabolismOnFDGPETInPutamenBrainstemOrCerebellum',
  'possibleMSACParkinsonism',
  'possibleMSACAtrophyOnMRIOfPutamenMiddleCerebellarPeduncleOrPons',
  'possibleMSACHypometabolismOnFDGPETInPutamen',
  'possibleMSACPresynapticNigrostriatalDopaminergicDenervationOnSPECTOrPET',
  'supportingOrofacialDystonia',
  'supportingDisproportionateAntecollis',
  'supportingCamptocormia',
  'supportingContracturesOfHandsOrFeet',
  'supportingInspiratorySighs',
  'supportingSevereDysphonia',
  'supportingSevereDysatrhria',
  'supportingNewOrIncreasedSnoring',
  'supportingColdHandsAndFeet',
  'supportingPathologicLaughterOrCrying',
  'supportingJerkyMyoclonicPosturalActionTremor',
  'nonsupportingClassicPillrollingRestTremor',
  'nonsupportingClinicallySignificantNeuropathy',
  'nonsupportingHallucinationsNotInducedByDrugs',
  'nonsupportingOnsetAfter75y',
  'nonsupportingFamilyHistoryOfAtaxiaOrParkinsonism',
  'nonsupportingDementia',
  'nonsupportingWhiteMatterLesionsSuggestingMultipleSclerosis',
];

const setAllUnknown = (formData: IFormContext['formData'], viewing: boolean): void => {
  formFields.forEach((f) => {
    if (!viewing && !(formData?.document as { [key: string]: any })[f]) {
      formData?.onChange?.({
        [f]: 'unknown',
      });
    }
  });
};

const MakeFormRow = ({
  title,
  description,
  name,
  formData,
  viewing,
  bottomMargin = true,
}: {
  title: string;
  description?: JSX.Element | string;
  name: string;
  formData: IFormContext['formData'];
  viewing: boolean;
  bottomMargin?: boolean;
}): JSX.Element => (
  <FormRow title={title} description={description} headerWidth={6} bottomMargin={bottomMargin}>
    <InputHandler
      editing={!viewing}
      formData={formData}
      name={name}
      type="RadioButtonRow"
      preset="yesNoUnknown"
      height={3.5}
      width={9}
    />
  </FormRow>
);

const MSAForm = ({ documents, formData, view, editing, fm = (): string => '' }: IFormContext<IMSA>): JSX.Element => {
  const isViewing = !editing && !!view?.viewing;
  const docID = editing ?? view?.viewing;

  React.useEffect(() => {
    setAllUnknown(formData, isViewing);
  }, [formData, isViewing]);

  return (
    <React.Fragment>
      {formData ? (
        <React.Fragment>
          <FormRow title="general.date">
            <InputHandler
              type="PartialDate"
              editing={!isViewing}
              name="date"
              formData={formData}
              dateDefault="now"
              isNotCancellable={true}
            />
          </FormRow>
          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={(s: string): JSX.Element => <FormattedMessage id={'general.' + s} />}
            header="diagnosis.msa.probableMSA.title"
            hideCopyButton={isViewing}
          >
            {{
              headerSporadic: { header: <SubHeader>{fm('diagnosis.msa.probableMSA.headerSporadic')}</SubHeader> },
              group1: {
                group: {
                  groupElement: orGroupElement,
                  children: {
                    probableAutonomicFailureInvolvingUrinaryIncontinence: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.probableMSA.probableAutonomicFailureInvolvingUrinaryIncontinence"
                          name="probableAutonomicFailureInvolvingUrinaryIncontinence"
                          formData={formData}
                          viewing={isViewing}
                          bottomMargin={false}
                        />
                      ),
                    },
                    or: { header: <OrHeader>{fm('diagnosis.msa.or')}</OrHeader> },
                    probableOrthostaticDecreaseOfBloodPressure: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.probableMSA.probableOrthostaticDecreaseOfBloodPressure"
                          name="probableOrthostaticDecreaseOfBloodPressure"
                          formData={formData}
                          viewing={isViewing}
                          bottomMargin={false}
                        />
                      ),
                    },
                  },
                },
              },
              and: { header: <AndHeader>{fm('diagnosis.msa.and')}</AndHeader> },

              group2: {
                group: {
                  groupElement: orGroupElement,
                  children: {
                    probablePoorlyLevodopaResponsiveParkinsonism: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.probableMSA.probablePoorlyLevodopaResponsiveParkinsonism"
                          name="probablePoorlyLevodopaResponsiveParkinsonism"
                          formData={formData}
                          viewing={isViewing}
                          bottomMargin={false}
                        />
                      ),
                    },
                    or2: { header: <OrHeader>{fm('diagnosis.msa.or')}</OrHeader> },
                    cerebellarSyndrome: {
                      header: <SubHeader>{fm('diagnosis.msa.probableMSA.cerebellarSyndrome')}</SubHeader>,
                    },
                    gaitAtaxiaWithCerebellarDysarthria: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.probableMSA.gaitAtaxiaWithCerebellarDysarthria"
                          name="gaitAtaxiaWithCerebellarDysarthria"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    limbAtaxia: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.probableMSA.limbAtaxia"
                          name="limbAtaxia"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    cerebellarOculomotorDysfunction: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.probableMSA.cerebellarOculomotorDysfunction"
                          name="cerebellarOculomotorDysfunction"
                          formData={formData}
                          viewing={isViewing}
                          bottomMargin={false}
                        />
                      ),
                    },
                  },
                },
              },

              probableMSACanBeDiagnosed: {
                header: (
                  <Container style={{ marginTop: '3.5rem' }}>
                    <Item xs={5}>{fm('diagnosis.msa.probableMSA.probableMSACanBeDiagnosed')}</Item>
                    <Item style={{ fontWeight: 600 }}>{fm(`general.${probableMSA(formData.document)}`)}</Item>
                  </Container>
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={(s: string): JSX.Element => <FormattedMessage id={'general.' + s} />}
            header="diagnosis.msa.possibleMSA.title"
            hideCopyButton={isViewing}
          >
            {{
              sporadicProgressiveDisease: {
                header: <SubHeader>{fm('diagnosis.msa.possibleMSA.sporadicProgressiveDisease')}</SubHeader>,
              },
              group1: {
                group: {
                  groupElement: orGroupElement,
                  children: {
                    parkinsonism: { header: <SubHeader>{fm('diagnosis.msa.possibleMSA.parkinsonism')}</SubHeader> },
                    possibleBradykinesiaWithRigidity: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleBradykinesiaWithRigidity"
                          name="possibleBradykinesiaWithRigidity"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    possibleTremor: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleTremor"
                          name="possibleTremor"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    possiblePosturalInstability: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possiblePosturalInstability"
                          name="possiblePosturalInstability"
                          formData={formData}
                          viewing={isViewing}
                          bottomMargin={false}
                        />
                      ),
                    },
                    or2: { header: <OrHeader>{fm('diagnosis.msa.or')}</OrHeader> },
                    cerebellarSyndrome: {
                      header: <SubHeader>{fm('diagnosis.msa.possibleMSA.cerebellarSyndrome')}</SubHeader>,
                    },
                    gaitAtaxiaWithCerebellarDysarthria: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.gaitAtaxiaWithCerebellarDysarthria"
                          name="gaitAtaxiaWithCerebellarDysarthria"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    limbAtaxia: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.limbAtaxia"
                          name="limbAtaxia"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    cerebellarOculomotorDysfunction: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.cerebellarOculomotorDysfunction"
                          name="cerebellarOculomotorDysfunction"
                          formData={formData}
                          viewing={isViewing}
                          bottomMargin={false}
                        />
                      ),
                    },
                  },
                },
              },

              and: { header: <AndHeader>{fm('diagnosis.msa.and')}</AndHeader> },

              group2: {
                group: {
                  groupElement: orGroupElement,
                  children: {
                    autonomicDysfunction: {
                      header: <SubHeader>{fm('diagnosis.msa.possibleMSA.autonomicDysfunction')}</SubHeader>,
                    },
                    possibleOtherwiseUnexplainedUrinaryUrgency: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleOtherwiseUnexplainedUrinaryUrgency"
                          name="possibleOtherwiseUnexplainedUrinaryUrgency"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    possibleFrequencyOrIncompleteBladderEmptying: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleFrequencyOrIncompleteBladderEmptying"
                          name="possibleFrequencyOrIncompleteBladderEmptying"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    possibleErectileDysfunctionInMales: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleErectileDysfunctionInMales"
                          name="possibleErectileDysfunctionInMales"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    significantOrthostaticBloodPressure: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.significantOrthostaticBloodPressure"
                          name="significantOrthostaticBloodPressure"
                          formData={formData}
                          viewing={isViewing}
                          bottomMargin={false}
                        />
                      ),
                    },
                  },
                },
              },

              and2: { header: <AndHeader>{fm('diagnosis.msa.and')}</AndHeader> },

              group3: {
                group: {
                  groupElement: orGroupElement,
                  children: {
                    atLeastOneOfTheAdditionalFeatures: {
                      header: (
                        <SubHeader>{fm('diagnosis.msa.possibleMSA.atLeastOneOfTheAdditionalFeatures')}</SubHeader>
                      ),
                    },
                    MSAPandMSAC: {
                      header: <SubHeader>{fm('diagnosis.msa.possibleMSA.MSAPandMSAC')}</SubHeader>,
                    },
                    possibleBabinskiSignWithHyperreflexia: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleBabinskiSignWithHyperreflexia"
                          name="possibleBabinskiSignWithHyperreflexia"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    possibleStridor: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleStridor"
                          name="possibleStridor"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },

                    MSAP: {
                      header: <SubHeader>{fm('diagnosis.msa.possibleMSA.MSAP')}</SubHeader>,
                    },
                    possibleMSAPRapidlyProgressiveParkinsonism: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleMSAPRapidlyProgressiveParkinsonism"
                          name="possibleMSAPRapidlyProgressiveParkinsonism"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    possibleMSAPPoorResponseToLevodopa: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleMSAPPoorResponseToLevodopa"
                          name="possibleMSAPPoorResponseToLevodopa"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    possibleMSAPPosturalInstabilityWithin3yOfMotorOnset: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleMSAPPosturalInstabilityWithin3yOfMotorOnset"
                          name="possibleMSAPPosturalInstabilityWithin3yOfMotorOnset"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    possibleMSAPGaitAtaxiaCerebellarDysarthriaLimbAtaxiaOrCerebellarOculomotorDysfunction: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleMSAPGaitAtaxiaCerebellarDysarthriaLimbAtaxiaOrCerebellarOculomotorDysfunction"
                          name="possibleMSAPGaitAtaxiaCerebellarDysarthriaLimbAtaxiaOrCerebellarOculomotorDysfunction"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    possibleMSAPDysphagiaWithin5yOfMotorOnset: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleMSAPDysphagiaWithin5yOfMotorOnset"
                          name="possibleMSAPDysphagiaWithin5yOfMotorOnset"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    possibleMSAPAtrophyOnMRIOfPutamenMiddleCerebellarPedunclePonsOrCerebellum: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleMSAPAtrophyOnMRIOfPutamenMiddleCerebellarPedunclePonsOrCerebellum"
                          name="possibleMSAPAtrophyOnMRIOfPutamenMiddleCerebellarPedunclePonsOrCerebellum"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    possibleMSAPHypometabolismOnFDGPETInPutamenBrainstemOrCerebellum: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleMSAPHypometabolismOnFDGPETInPutamenBrainstemOrCerebellum"
                          name="possibleMSAPHypometabolismOnFDGPETInPutamenBrainstemOrCerebellum"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    MSAC: {
                      header: <SubHeader>{fm('diagnosis.msa.possibleMSA.MSAC')}</SubHeader>,
                    },
                    possibleMSACParkinsonism: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleMSACParkinsonism"
                          name="possibleMSACParkinsonism"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    possibleMSACAtrophyOnMRIOfPutamenMiddleCerebellarPeduncleOrPons: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleMSACAtrophyOnMRIOfPutamenMiddleCerebellarPeduncleOrPons"
                          name="possibleMSACAtrophyOnMRIOfPutamenMiddleCerebellarPeduncleOrPons"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    possibleMSACHypometabolismOnFDGPETInPutamen: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleMSACHypometabolismOnFDGPETInPutamen"
                          name="possibleMSACHypometabolismOnFDGPETInPutamen"
                          formData={formData}
                          viewing={isViewing}
                        />
                      ),
                    },
                    possibleMSACPresynapticNigrostriatalDopaminergicDenervationOnSPECTOrPET: {
                      element: (
                        <MakeFormRow
                          title="diagnosis.msa.possibleMSA.possibleMSACPresynapticNigrostriatalDopaminergicDenervationOnSPECTOrPET"
                          name="possibleMSACPresynapticNigrostriatalDopaminergicDenervationOnSPECTOrPET"
                          formData={formData}
                          viewing={isViewing}
                          bottomMargin={false}
                        />
                      ),
                    },
                  },
                },
              },

              possibleMSACanBeDiagnosed: {
                header: (
                  <Container style={{ marginTop: '3.5rem' }}>
                    <Item xs={5}>{fm('diagnosis.msa.possibleMSA.possibleMSACanBeDiagnosed')}</Item>
                    <Item style={{ fontWeight: 600 }}>{fm(`general.${possibleMSA(formData.document)}`)}</Item>
                  </Container>
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={(s: string): JSX.Element => <FormattedMessage id={'general.' + s} />}
            header="diagnosis.msa.supportingFeatures.title"
            hideCopyButton={isViewing}
          >
            {{
              supportingFeatures: {
                header: <SubHeader>{fm('diagnosis.msa.supportingFeatures.supportingFeatures')}</SubHeader>,
              },
              supportingOrofacialDystonia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.supportingOrofacialDystonia"
                    name="supportingOrofacialDystonia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              supportingDisproportionateAntecollis: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.supportingDisproportionateAntecollis"
                    name="supportingDisproportionateAntecollis"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              supportingCamptocormia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.supportingCamptocormia"
                    name="supportingCamptocormia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              supportingContracturesOfHandsOrFeet: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.supportingContracturesOfHandsOrFeet"
                    name="supportingContracturesOfHandsOrFeet"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              supportingInspiratorySighs: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.supportingInspiratorySighs"
                    name="supportingInspiratorySighs"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              supportingSevereDysphonia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.supportingSevereDysphonia"
                    name="supportingSevereDysphonia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              supportingSevereDysatrhria: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.supportingSevereDysatrhria"
                    name="supportingSevereDysatrhria"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              supportingNewOrIncreasedSnoring: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.supportingNewOrIncreasedSnoring"
                    name="supportingNewOrIncreasedSnoring"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              supportingColdHandsAndFeet: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.supportingColdHandsAndFeet"
                    name="supportingColdHandsAndFeet"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              supportingPathologicLaughterOrCrying: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.supportingPathologicLaughterOrCrying"
                    name="supportingPathologicLaughterOrCrying"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              supportingJerkyMyoclonicPosturalActionTremor: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.supportingJerkyMyoclonicPosturalActionTremor"
                    name="supportingJerkyMyoclonicPosturalActionTremor"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              nonsupportingFeatures: {
                header: <SubHeader>{fm('diagnosis.msa.supportingFeatures.nonsupportingFeatures')}</SubHeader>,
              },
              nonsupportingClassicPillrollingRestTremor: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.nonsupportingClassicPillrollingRestTremor"
                    name="nonsupportingClassicPillrollingRestTremor"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              nonsupportingClinicallySignificantNeuropathy: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.nonsupportingClinicallySignificantNeuropathy"
                    name="nonsupportingClinicallySignificantNeuropathy"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              nonsupportingHallucinationsNotInducedByDrugs: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.nonsupportingHallucinationsNotInducedByDrugs"
                    name="nonsupportingHallucinationsNotInducedByDrugs"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              nonsupportingOnsetAfter75y: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.nonsupportingOnsetAfter75y"
                    name="nonsupportingOnsetAfter75y"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              nonsupportingFamilyHistoryOfAtaxiaOrParkinsonism: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.nonsupportingFamilyHistoryOfAtaxiaOrParkinsonism"
                    name="nonsupportingFamilyHistoryOfAtaxiaOrParkinsonism"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              nonsupportingDementia: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.nonsupportingDementia"
                    name="nonsupportingDementia"
                    formData={formData}
                    viewing={isViewing}
                  />
                ),
              },
              nonsupportingWhiteMatterLesionsSuggestingMultipleSclerosis: {
                element: (
                  <MakeFormRow
                    title="diagnosis.msa.supportingFeatures.nonsupportingWhiteMatterLesionsSuggestingMultipleSclerosis"
                    name="nonsupportingWhiteMatterLesionsSuggestingMultipleSclerosis"
                    formData={formData}
                    viewing={isViewing}
                    bottomMargin={false}
                  />
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <FormSectionHistoryAcceptor
            formData={formData}
            documentID={docID}
            documents={documents}
            optionFormatter={(s: string): JSX.Element => <span>{fm(`general.${s}`)}</span>}
            header="diagnosis.msa.summary.title"
            hideCopyButton={true}
          >
            {{
              probableDLBCanBeDiagnosed: {
                header: (
                  <Container id="probableDLBcanBeDiagnosed">
                    <Item xs={5} style={{ fontWeight: 400, paddingRight: '1rem' }}>
                      {fm('diagnosis.msa.summary.probableMSACanBeDiagnosed')}
                    </Item>
                    <Item style={{ paddingTop: '1rem', fontWeight: 600 }}>
                      {fm(`general.${probableMSA(formData.document)}`)}
                    </Item>
                  </Container>
                ),
              },
              possibleDLBCanBeDiagnosed: {
                header: (
                  <Container id="possibleDLBcanBeDiagnosed">
                    <Item xs={5} style={{ fontWeight: 400, paddingRight: '1rem' }}>
                      {fm('diagnosis.msa.summary.possibleMSACanBeDiagnosed')}
                    </Item>
                    <Item style={{ paddingTop: '1rem', fontWeight: 600 }}>
                      {fm(`general.${possibleMSA(formData.document)}`)}
                    </Item>
                  </Container>
                ),
              },
              numberOfSupportingFeatures: {
                header: (
                  <Container>
                    <Item xs={5} style={{ paddingRight: '1rem' }}>
                      <SubHeader>{fm('diagnosis.msa.summary.numberOfSupportingFeatures')}</SubHeader>
                    </Item>
                  </Container>
                ),
              },
              numberOfSupportingFeaturesYes: {
                element: (
                  <Container id="numberOfSupportingFeaturesYes">
                    <Item xs={5} style={{ fontWeight: 400, marginLeft: '3rem' }}>
                      {fm('general.yes')}
                    </Item>
                    <Item style={{ paddingTop: '1rem', marginLeft: '6rem', fontWeight: 600 }}>
                      {numberOfSupportingFeatures(formData.document, 'yes') + ' / 11'}
                    </Item>
                  </Container>
                ),
              },
              numberOfSupportingFeaturesNo: {
                element: (
                  <Container id="numberOfSupportingFeaturesNo">
                    <Item xs={5} style={{ fontWeight: 400, marginLeft: '3rem' }}>
                      {fm('general.no')}
                    </Item>
                    <Item style={{ paddingTop: '1rem', marginLeft: '6rem', fontWeight: 600 }}>
                      {numberOfSupportingFeatures(formData.document, 'no') + ' / 11'}
                    </Item>
                  </Container>
                ),
              },
              numberOfSupportingFeaturesUnknown: {
                element: (
                  <Container id="numberOfSupportingFeaturesUnknown">
                    <Item xs={5} style={{ fontWeight: 400, marginLeft: '3rem' }}>
                      {fm('general.unknown')}
                    </Item>
                    <Item style={{ paddingTop: '1rem', marginLeft: '6rem', fontWeight: 600 }}>
                      {numberOfSupportingFeatures(formData.document, 'unknown') + ' / 11'}
                    </Item>
                  </Container>
                ),
              },
              numberOfNonSupportingFeatures: {
                header: (
                  <Container>
                    <Item xs={5} style={{ paddingRight: '1rem' }}>
                      <SubHeader>{fm('diagnosis.msa.summary.numberOfNonSupportingFeatures')}</SubHeader>
                    </Item>
                  </Container>
                ),
              },
              numberOfNonSupportingFeaturesYes: {
                element: (
                  <Container id="numberOfNonSupportingFeaturesYes">
                    <Item xs={5} style={{ fontWeight: 400, marginLeft: '3rem' }}>
                      {fm('general.yes')}
                    </Item>
                    <Item style={{ paddingTop: '1rem', marginLeft: '6rem', fontWeight: 600 }}>
                      {numberOfNonSupportingFeatures(formData.document, 'yes') + ' / 7'}
                    </Item>
                  </Container>
                ),
              },
              numberOfNonSupportingFeaturesNo: {
                element: (
                  <Container id="numberOfNonSupportingFeaturesNo">
                    <Item xs={5} style={{ fontWeight: 400, marginLeft: '3rem' }}>
                      {fm('general.no')}
                    </Item>
                    <Item style={{ paddingTop: '1rem', marginLeft: '6rem', fontWeight: 600 }}>
                      {numberOfNonSupportingFeatures(formData.document, 'no') + ' / 7'}
                    </Item>
                  </Container>
                ),
              },
              numberOfNonSupportingFeaturesUnknown: {
                element: (
                  <Container id="numberOfNonSupportingFeaturesUnknown">
                    <Item xs={5} style={{ fontWeight: 400, marginLeft: '3rem' }}>
                      {fm('general.unknown')}
                    </Item>
                    <Item style={{ paddingTop: '1rem', marginLeft: '6rem', fontWeight: 600 }}>
                      {numberOfNonSupportingFeatures(formData.document, 'unknown') + ' / 7'}
                    </Item>
                  </Container>
                ),
              },
            }}
          </FormSectionHistoryAcceptor>

          <Container>
            <Item style={{ padding: '2rem 0' }}>
              <ReferenceText>
                Gilman, S., Wenning, G.K., Low, P.A., Brooks, D.J., Mathias, C.J., Trojanowski, J.Q., Wood, N.W.,
                Colosimo, C., Dürr, A., Fowler, C.J., Kaufmann, H., Klockgether, T., Lees, A., Poewe, W., Quinn, N.,
                Revesz, T., Robertson, D., Sandroni, P., Seppi, K., Vidailhet, M. Second consensus statement on the
                diagnosis of multiple system atrophy. Neurology, 26, 670-6.
              </ReferenceText>
            </Item>
          </Container>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default withFormContext(MSAForm);
