import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistorySection from '../../../../components/HistorySection';

import HistoryRowListing from 'Components/HistoryRowListing';

const optFormatter = (
  name: string | number,
  version?: (IInflammatoryDiseaseActivityMonitoring | IInflammatoryDiseaseActivityMonitoring2024)['version'],
): JSX.Element => (
  <span>
    <FormattedMessage id={'inflammatoryDiseaseActivity.opts.' + name} />
    <span>
      &nbsp;
      {'('}
      <FormattedMessage id="inflammatoryDiseaseActivity.careGuidelines" />
      &nbsp;
      {version === '2024' ? '2024' : '2020'}
      {')'}
    </span>
  </span>
);

const MonitoringHistory = ({ documents, startEdit, view }: IOwnProps): JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id={'inflammatoryDiseaseActivity.inflammatoryDiseaseActivityMonitoring'} />}
      newButton={
        <DocumentCreationButton
          name="inflammatoryDiseaseActivityMonitoring"
          text={'inflammatoryDiseaseActivity.newActivity'}
          onClick={startEdit({}, 'inflammatoryDiseaseActivityMonitoring')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={12}>
              <FormattedMessage id={'inflammatoryDiseaseActivity.inflammatoryDiseaseActivity'} />
            </Item>
          </>
        }
        contentFormat={(d: IInflammatoryDiseaseActivityMonitoring): JSX.Element => (
          <Item xs={12}>
            {d.inflammatoryDiseaseActivity ? optFormatter(d.inflammatoryDiseaseActivity, d.version) : '-'}
          </Item>
        )}
        makeDate
        makeControls
        historyRowControls={(d: IControlProps) => <HistoryRowControls document={d} startEdit={startEdit} view={view} />}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: (IInflammatoryDiseaseActivityMonitoring | IInflammatoryDiseaseActivityMonitoring2024)[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default MonitoringHistory;
