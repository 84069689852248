import { exists, calculatePartialDateDiffenceDuration, isPartialDate, sortPartialDate } from 'neuro-utils';

export type TDocument = (IInflammatoryDiseaseActivityStart | IInflammatoryDiseaseActivityStart2024) &
  (IInflammatoryDiseaseActivityMonitoring | IInflammatoryDiseaseActivityMonitoring2024);

/**
 * Relapses status for Inflammatory disease activity
 * Returns '0' if no relapses past 12 months,
 * '1' if there is one relapse without cortisone,
 * '≥1' if there is one relapse with cortisone given or more than one relapse with or without cortisone
 * @param {IRelapse[] | undefined} documents
 * @returns {'0' | '1' | '≥1'} - Relapses status
 */
export const relapseScore = (documents?: IRelapse[], date?: PartialDate): '0' | '1' | '≥1' => {
  if (documents && documents.length > 0) {
    let relapseMonths = documents.map((r) =>
      r.startDate && isPartialDate(r.startDate)
        ? {
            months: calculatePartialDateDiffenceDuration('months', r.startDate, date).months,
            cortisone: r.wasCortisoneGiven,
          }
        : {},
    );
    relapseMonths = relapseMonths.filter((m) => m.months !== undefined && m.months >= 0 && m.months <= 12);

    if (relapseMonths.length === 0) return '0';
    if (relapseMonths.some((m) => m.cortisone === 'yes')) return '≥1';
    if (relapseMonths.length > 1) return '≥1';
    return '1';
  }

  return '0';
};

/**
 * T2 MRI changes
 * @param {'start' | 'monitoring'} type
 * @param {IMRI[] | undefined} documents
 * @returns {'unknown' | '0' | '1-4' | '≥5' | '1-8' | '≥9'} - T2 changes for IDA start or monitoring
 */
export const t2Change = (
  type: 'start' | 'monitoring',
  documents?: IMRI[],
  date?: PartialDate,
): TT2ChangeStart | TT2ChangeMonitoring => {
  const docs = documents?.sort((n1, n2) => sortPartialDate(n1.date, n2.date)).reverse();

  const firstDate = docs?.[0]?.date;
  if (
    (firstDate && calculatePartialDateDiffenceDuration('months', firstDate, date).months > 12) ||
    (firstDate && calculatePartialDateDiffenceDuration('months', firstDate, date).months < 0)
  )
    return 'unknown';

  if (docs?.[0]) {
    const brainT2ChangesAmount = docs[0].brainT2Flair?.total?.lesionsDelta;
    const spinalCordT2ChangesAmount = docs[0].spinalCordT2?.total?.lesionsDelta;

    if (!exists(brainT2ChangesAmount) && !exists(spinalCordT2ChangesAmount)) return 'unknown';

    if (type === 'start') {
      const results = ['0', '1-8', '≥9'];
      let [brainT2Index, spinalCordT2Index] = [0, 0];

      if (brainT2ChangesAmount === 0) brainT2Index = 0;
      if (spinalCordT2ChangesAmount === 0) spinalCordT2Index = 0;

      if (brainT2ChangesAmount) {
        if (brainT2ChangesAmount >= 1 && brainT2ChangesAmount < 9) {
          brainT2Index = 1;
        }
        if (brainT2ChangesAmount >= 9) {
          brainT2Index = 2;
        }
      }
      if (spinalCordT2ChangesAmount) {
        if (spinalCordT2ChangesAmount >= 1 && spinalCordT2ChangesAmount < 9) {
          spinalCordT2Index = 1;
        }
        if (spinalCordT2ChangesAmount >= 9) {
          spinalCordT2Index = 2;
        }
      }
      return results[brainT2Index > spinalCordT2Index ? brainT2Index : spinalCordT2Index] as '0' | '1-8' | '≥9';
    }

    if (type === 'monitoring') {
      const results = ['0', '1-4', '≥5'];
      let [brainT2Index, spinalCordT2Index] = [0, 0];

      if (brainT2ChangesAmount === 0) brainT2Index = 0;
      if (spinalCordT2ChangesAmount === 0) spinalCordT2Index = 0;

      if (brainT2ChangesAmount) {
        if (brainT2ChangesAmount >= 1 && brainT2ChangesAmount < 5) {
          brainT2Index = 1;
        }
        if (brainT2ChangesAmount >= 5) {
          brainT2Index = 2;
        }
      }
      if (spinalCordT2ChangesAmount) {
        if (spinalCordT2ChangesAmount >= 1 && spinalCordT2ChangesAmount < 5) {
          spinalCordT2Index = 1;
        }
        if (spinalCordT2ChangesAmount >= 5) {
          spinalCordT2Index = 2;
        }
      }
      return results[brainT2Index > spinalCordT2Index ? brainT2Index : spinalCordT2Index] as '0' | '1-4' | '≥5';
    }
  }

  return 'unknown';
};

type TT2ChangeStart = IInflammatoryDiseaseActivityStart['T2Changes'];
type TT2ChangeMonitoring = IInflammatoryDiseaseActivityMonitoring['T2ChangesNeworExpanding'];

/**
 * T1 MRI changes
 * @param {IMRI[] | undefined} documents
 * @returns {'unknown' | '0' | '1' | '>1'} - T1 changes for IDA start or monitoring
 */
export const t1EnchancedChange = (documents?: IMRI[], date?: PartialDate): 'unknown' | '0' | '1' | '>1' => {
  const docs = documents?.sort((n1, n2) => sortPartialDate(n1.date, n2.date)).reverse();

  const firstDate = docs?.[0]?.date;
  if (
    (firstDate && calculatePartialDateDiffenceDuration('months', firstDate, date).months > 12) ||
    (firstDate && calculatePartialDateDiffenceDuration('months', firstDate, date).months < 0)
  )
    return 'unknown';

  if (docs?.[0]) {
    const brainT1ChangesAmount = docs[0].brainT1?.gadoliniumEnhanced?.lesionsDelta;
    const spinalCordT1ChangesAmount = docs[0].spinalCordT1?.gadoliniumEnhanced?.lesionsDelta;

    if (!exists(brainT1ChangesAmount) && !exists(spinalCordT1ChangesAmount)) return 'unknown';

    const results = ['0', '1', '>1'];
    let [brainT1Index, spinalCordT1Index] = [0, 0];

    if (brainT1ChangesAmount === 0) brainT1Index = 0;
    if (spinalCordT1ChangesAmount === 0) spinalCordT1Index = 0;

    if (brainT1ChangesAmount) {
      if (brainT1ChangesAmount === 1) {
        brainT1Index = 1;
      }
      if (brainT1ChangesAmount > 1) {
        brainT1Index = 2;
      }
    }
    if (spinalCordT1ChangesAmount) {
      if (spinalCordT1ChangesAmount === 1) {
        spinalCordT1Index = 1;
      }
      if (spinalCordT1ChangesAmount > 1) {
        spinalCordT1Index = 2;
      }
    }
    return results[brainT1Index > spinalCordT1Index ? brainT1Index : spinalCordT1Index] as '0' | '1' | '>1';
  }

  return 'unknown';
};

/**
 * Inflammatory disease activity classification for start
 * @param {IInflammatoryDiseaseActivityStart} d
 * @returns {'unknown' | 'activeMS' | 'veryActiveMS'} - IDA classification
 */
export const idaClassificationStart2020 = (
  d: IInflammatoryDiseaseActivityStart,
): 'unknown' | 'activeMS' | 'veryActiveMS' => {
  if (
    d.relapseCountLast12Months === '≥1' &&
    (d.T2Changes === '≥9' ||
      d.T1GadoliniumEnhancedActiveChanges === '1' ||
      d.T1GadoliniumEnhancedActiveChanges === '>1')
  )
    return 'veryActiveMS';

  if (
    (d.relapseCountLast12Months === '1' || d.relapseCountLast12Months === '≥1') &&
    (d.T2Changes === '1-8' ||
      d.T2Changes === '≥9' ||
      d.T1GadoliniumEnhancedActiveChanges === '1' ||
      d.T1GadoliniumEnhancedActiveChanges === '>1')
  )
    return 'activeMS';

  return 'unknown';
};

/**
 * Inflammatory disease activity classification for monitoring
 * @param {IInflammatoryDiseaseActivityMonitoring} d
 * @returns {'unknown' | 'stableMS' | 'activeMS' | 'veryActiveMS'} - IDA classification
 */
export const idaClassificationMonitoring2020 = (
  d: IInflammatoryDiseaseActivityMonitoring,
): 'unknown' | 'stableMS' | 'activeMS' | 'veryActiveMS' => {
  if (
    d.relapseCountLast12Months === '0' &&
    d.T2ChangesNeworExpanding === '0' &&
    d.T1GadoliniumEnhancedActiveChanges === '0'
  )
    return 'stableMS';

  if (
    d.relapseCountLast12Months === '≥1' &&
    (d.T2ChangesNeworExpanding === '≥5' ||
      d.T1GadoliniumEnhancedActiveChanges === '1' ||
      d.T1GadoliniumEnhancedActiveChanges === '>1')
  )
    return 'veryActiveMS';

  if (
    d.relapseCountLast12Months === '1' ||
    d.relapseCountLast12Months === '≥1' ||
    d.T2ChangesNeworExpanding === '1-4' ||
    d.T2ChangesNeworExpanding === '≥5' ||
    d.T1GadoliniumEnhancedActiveChanges === '1' ||
    d.T1GadoliniumEnhancedActiveChanges === '>1'
  )
    return 'activeMS';

  return 'unknown';
};
