import * as React from 'react';

/** Context used by the following forms:
 * - CyberKnife
 * - Disconnection
 * - IntracranialImaging
 * - Lesionectomy
 * - LocalResection
 * - Thermocoagulation
 */
export const OperationContext: React.Context<{ operationIndex?: number }> = React.createContext({});
