import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import { Item } from '../../../../../components/Grid';
import InputHandler from '../../../../../components/InputHandler';
import QuestionStepper from '../../../../../components/QuestionStepper';
import colors from '../../../../../config/theme/colors';

import { steps as pbaSSteps, getStepContent as getPbaSStepContent } from '../pbaSSettings';

const PBASForm = ({ formData, viewing }: IOwnProps): JSX.Element => (
  <React.Fragment>
    <div style={{ fontStyle: 'italic', color: colors.darkGray, margin: '0rem 0 3rem 0' }}>
      The EHDN’s (European Huntington’s Disease Network) Behavioural Phenotype Working group and David Craufurd have the
      copyright over the PBA-s. Organizations seeking to use PBA-s must obtain a permission. Using PBA-s requires a
      training provided by the EHDN&apos;s Behavioural Working Group. Please contact permissions@euro-hd.net for further
      information on the permissions and callaghan@euro-hd.net for further information on the training.
    </div>
    <Item style={{ marginBottom: '4.5rem', color: colors.tertiaryText }}>
      <FormattedMessage id="behaviour.pbaS.startInfo" />{' '}
    </Item>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={!viewing}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="behaviour.pbaS.isInformantRelative">
      <InputHandler
        type="Radio"
        editing={!viewing}
        name="isInformantRelative"
        formData={formData}
        options={[
          'spouseOrPartner',
          'parent',
          'sibling',
          'child',
          'otherRelative',
          'friendOrNeighbour',
          'professionalCareWorker',
          'other',
          'noInformant',
        ]}
        optionFormatter={(name: string | number): JSX.Element => (
          <FormattedMessage id={`behaviour.pbaS.opts.${name}`} />
        )}
      />
    </FormRow>
    <FormRow
      title="behaviour.pbaS.isInformantHouseholdMember"
      description={<FormattedMessage id="behaviour.pbaS.isInformantHouseholdMemberDescription" />}
    >
      <InputHandler
        type="Radio"
        editing={!viewing}
        name="isInformantHouseholdMember"
        formData={formData}
        options={['1', '2', '3', '4']}
        optionFormatter={(name: string | number): JSX.Element => (
          <FormattedMessage id={`behaviour.pbaS.opts.${name}`} />
        )}
      />
    </FormRow>
    <FormRow
      title="behaviour.pbaS.generalComments"
      description={<FormattedMessage id="behaviour.pbaS.generalCommentsDescription" />}
    >
      <InputHandler
        type="TextArea"
        editing={!viewing}
        name="generalComments"
        formData={formData}
        placeholder="behaviour.pbaS.generalComments"
      />
    </FormRow>
    <FormSection>
      <QuestionStepper
        viewing={viewing}
        formData={formData}
        pageType="behaviour"
        docType="pbaS"
        steps={pbaSSteps}
        getStepContent={getPbaSStepContent(formData.document)}
      />
    </FormSection>
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<IPBAS>;
  viewing?: boolean;
}

export default PBASForm;
