import { isPartialDate, partialDateToValue, sortPartialDate, subtractMonths } from 'neuro-utils';

/**
 * Gets count of relapses during last 12 months for inflammatory disease activity form (2024)
 * @param {PartialDate} date - Date from where to count the 12 months from
 * @param {Array<IRelapse>} relapseDocs - Relapse docs
 * @returns {'0' | '1' | '≥1' | '≥2'} - string value representing relapse count
 */
export const getRelapseCount = (
  date: PartialDate,
  relapseDocs: Array<IRelapse>,
  form: 'start' | 'monitoring',
): '0' | '1' | '≥1' | '≥2' => {
  const dateFloor = subtractMonths(date, 12);

  const relapseDocsInTimeframe = relapseDocs.filter(
    (d) => isPartialDate(d.startDate) && partialDateToValue(d.startDate) >= partialDateToValue(dateFloor),
  );

  const relapseCount = relapseDocsInTimeframe.length;

  if (form === 'start') {
    if (relapseCount > 1) return '≥2';
    if (relapseCount === 1) return '1';
  }
  if (form === 'monitoring') {
    if (relapseCount >= 1) return '≥1';
  }
  return '0';
};

/**
 * Gets new changes from MRI docs during last 12 months for inflammatory disease activity form (2024)
 * @param {PartialDate} date - Date from where to count the 12 months from
 * @param {Array<IRelapse>} mriDocs - MRI docs
 * @param {'T2' | 'T2New' | 'T1GadoliniumEnhanced'} type - Type of changes
 * @returns {TMRIChanges} - string value representing MRI changes
 */
export const getMRIChanges = (
  date: PartialDate,
  mriDocs: Array<IMRI>,
  type: 'T2' | 'T2New' | 'T1GadoliniumEnhanced',
  form: 'start' | 'monitoring',
): '0' | '1' | '≥1' | '≥2' | '1-8' | '≥9' | 'unknown' => {
  const dateFloor = subtractMonths(date, 12);

  const mriDocsInTimeframe = mriDocs.filter(
    (d) => isPartialDate(d.date) && partialDateToValue(d.date) >= partialDateToValue(dateFloor),
  );

  const latest = mriDocsInTimeframe
    .slice()
    .sort((a, b) => b._cdate - a._cdate)
    .sort((a, b) => sortPartialDate(b.date, a.date))[0];

  if (!latest) return 'unknown';

  const brainT2 = latest.brainT2Flair?.total;
  const brainT1 = latest.brainT1?.gadoliniumEnhanced;
  const spinalCordT2 = latest.spinalCordT2?.total;
  const spinalCordT1 = latest.spinalCordT1?.gadoliniumEnhanced;

  switch (type) {
    case 'T2': {
      // returns '0' | '1-8' | '≥9' | 'unknown'
      if (form === 'monitoring') return 'unknown';
      const brainLesions = brainT2?.lesions;
      const spinalCordLesions = spinalCordT2?.lesions;
      const results = [brainLesions, spinalCordLesions].map((value) => {
        if (!(value || value === 0) || ['X', 'N/A'].includes(`${value}`)) return 'unknown';
        if (['9', '10-20', '21-40', '>40'].includes(`${value}`)) return '≥9';
        if (typeof value === 'number' && value > 0) return '1-8';
        return '0';
      });
      // pick "max"
      if (results.some((r) => r === '≥9')) return '≥9';
      if (results.some((r) => r === '1-8')) return '1-8';
      if (results.some((r) => r === '0')) return '0';
      return 'unknown';
    }
    case 'T2New': {
      // returns '0' | '1' | '≥1' | '≥2' | 'unknown'
      const brainLesions = brainT2?.lesionsDelta;
      const spinalCordLesions = spinalCordT2?.lesionsDelta;
      if (!(brainLesions || brainLesions === 0) && !(spinalCordLesions && spinalCordLesions === 0)) return 'unknown';
      const result = Math.max(brainLesions ?? 0, spinalCordLesions ?? 0);
      if (form === 'start') {
        if (result >= 1) return '≥1';
      }
      if (form === 'monitoring') {
        if (result > 1) return '≥2';
        if (result === 1) return '1';
      }
      return '0';
    }
    case 'T1GadoliniumEnhanced': {
      // returns '0' | '≥1' | 'unknown'
      const brainLesions = brainT1?.lesions;
      const spinalCordLesions = spinalCordT1?.lesions;
      const results = [brainLesions, spinalCordLesions].map((value) => {
        if (!(value || value === 0) || ['X', 'N/A'].includes(`${value}`)) return 'unknown';
        if ((typeof value === 'number' && value > 0) || ['10-20', '21-40', '>40'].includes(`${value}`)) return '≥1';
        return '0';
      });
      // pick "max"
      if (results.some((r) => r === '≥1')) return '≥1';
      if (results.some((r) => r === '0')) return '0';
      return 'unknown';
    }
    default:
      return 'unknown';
  }
};

/**
 * Tautiaktiivisuuden päättelylogiikka
 *
 * Jos ("Pahenemisvaiheiden lukumäärä edellisen 12 kk aikana" = "≥ 2")
 * tai ("Pahenemisvaiheiden lukumäärä edellisen 12 kk aikana" = "1" ja "Onko pahenemisvaiheesta jäännösoireita?" = "Kyllä")
 * tai ["Pahenemisvaiheiden lukumäärä edellisen 12 kk aikana" = "1" ja "T2 muutokset" = "≥  9" ja ("T1 gadoliniumilla tehostuvat aktiiviset muutokset" = "≥ 1" tai "Uudet T2-muutokset" = "≥ 1")],
 * niin "Tautiaktiivisuus" = "Erittäin aktiivinen MS"
 *
 * muutoin
 *
 * Jos "Pahenemisvaiheiden lukumäärä edellisen 12 kk aikana" = "1"
 * ja ("Onko pahenemisvaiheesta jäännösoireita?" = "Ei tiedossa" tai "T2 muutokset" = "Ei tiedossa" tai "T1 gadoliniumilla tehostuvat aktiiviset muutokset" = "Ei tiedossa" tai "Uudet T2-muutokset" = "Ei tiedossa"),
 * niin "Tautiaktiivisuus" = "Ei tiedossa"
 *
 * muutoin
 *
 * "Tautiaktiivisuus" = "Ei viitteitä erittäin aktiivisesta MS-taudista"
 *
 * @param document - Inflammatory disease activity document (2024) to classify
 * @returns {'veryActiveMS' | 'noIndicationOfVeryActiveMS' | 'unknown'} - Classification
 */
export const idaClassificationStart2024 = (
  document: IInflammatoryDiseaseActivityStart2024,
): 'veryActiveMS' | 'noIndicationOfVeryActiveMS' | 'unknown' => {
  const {
    relapseCountLast12Months,
    relapseSymptomsRemain,
    T2Changes,
    T2ChangesNew,
    T1GadoliniumEnhancedActiveChanges,
  } = document;

  if (relapseCountLast12Months === '≥2') return 'veryActiveMS';
  if (relapseCountLast12Months === '1' && relapseSymptomsRemain === 'yes') return 'veryActiveMS';
  if (
    relapseCountLast12Months === '1' &&
    T2Changes === '≥9' &&
    (T1GadoliniumEnhancedActiveChanges === '≥1' || T2ChangesNew === '≥1')
  ) {
    return 'veryActiveMS';
  }

  if (
    relapseCountLast12Months === '1' &&
    (relapseSymptomsRemain === 'unknown' ||
      T2Changes === 'unknown' ||
      T1GadoliniumEnhancedActiveChanges === 'unknown' ||
      T2ChangesNew === 'unknown')
  ) {
    return 'unknown';
  }

  return 'noIndicationOfVeryActiveMS';
};

/**
 * Tautiaktiivisuuden päättelylogiikka
 *
 * Jos "Pahenemisvaiheiden lukumäärä edellisen 12 kk aikana" = "≥ 1"
 * tai "Uudet T2-muutokset" = "≥ 2"
 * tai "T1 gadoliniumilla tehostuvat aktiiviset muutokset" = "≥ 1",
 * niin "Tautiaktiivisuus" = "Erittäin aktiivinen MS"
 *
 * muutoin
 *
 * Jos "Uudet T2 muutokset" = "Ei tiedossa"
 * tai "T1 gadoliniumilla tehostuvat aktiiviset muutokset" = "Ei tiedossa",
 * niin "Tautiaktiivisuus" = "Ei tiedossa"
 *
 * muutoin
 *
 * "Tautiaktiivisuus" = "Ei viitteitä erittäin aktiivisesta MS-taudista"
 *
 * @param document - Inflammatory disease activity document (2024) to classify
 * @returns {'veryActiveMS' | 'noIndicationOfVeryActiveMS' | 'unknown'} - Classification
 */
export const idaClassificationMonitoring2024 = (
  document: IInflammatoryDiseaseActivityMonitoring2024,
): 'veryActiveMS' | 'noIndicationOfVeryActiveMS' | 'unknown' => {
  const { relapseCountLast12Months, T2ChangesNew, T1GadoliniumEnhancedActiveChanges } = document;

  if (relapseCountLast12Months === '≥1') return 'veryActiveMS';
  if (T2ChangesNew === '≥2') return 'veryActiveMS';
  if (T1GadoliniumEnhancedActiveChanges === '≥1') return 'veryActiveMS';

  if (T2ChangesNew === 'unknown') return 'unknown';
  if (T1GadoliniumEnhancedActiveChanges === 'unknown') return 'unknown';

  return 'noIndicationOfVeryActiveMS';
};
