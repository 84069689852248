import * as React from 'react';
import TabContent from 'Components/TabContent';
import { convertSymptomsAndLocationsToRatingFormat } from 'Utility/ninmtUtil';
import PatientsRating from '../PatientsRating';
import { equals } from 'ramda';
import { styled } from '@mui/system';
import colors from '../../../../../../../config/theme/colors';

const EmptyTabContent = styled('div')({
  margin: '0rem 0rem 0rem 0rem',
  padding: '2rem 2rem 2rem 2rem',
  marginRight: '0rem',
  border: `1px solid ${colors.gray}`,
  backgroundColor: colors.white,
});

const PatientsRatings = ({
  editIndex,
  formData,
  fm,
  symptomsAndLocations,
}: {
  editIndex: number;
  formData: IFormData<ITDCS>;
  fm: (m: string) => string;
  symptomsAndLocations: Array<ISymptomsAndLocations>;
}): JSX.Element => {
  const { document, onChange } = formData;

  // Initialize/update patient's rating array
  React.useEffect(() => {
    const newSessions: Array<ITDCSSession> = JSON.parse(JSON.stringify(document.sessions));
    if (newSessions?.[editIndex].date) {
      const newPatientsRating = convertSymptomsAndLocationsToRatingFormat(
        newSessions?.[editIndex],
        symptomsAndLocations,
      );
      const currentPatientsRating = newSessions?.[editIndex].patientsRating;

      if ((!currentPatientsRating || currentPatientsRating.length === 0) && newPatientsRating.length > 0) {
        newSessions[editIndex].patientsRating = newPatientsRating;
        onChange && onChange({ sessions: newSessions });
        return;
      }

      if (
        !equals(
          newPatientsRating.map((n) => n.ratingByLocation?.map((r) => r.symptomsAndLocationsId)),
          currentPatientsRating?.map((c) => c.ratingByLocation?.map((r) => r.symptomsAndLocationsId)),
        )
      ) {
        newSessions[editIndex].patientsRating = newPatientsRating;
        onChange && onChange({ sessions: newSessions });
        return;
      }
    }
  }, [symptomsAndLocations]);

  const patientsRating =
    Array.isArray(document.sessions?.[editIndex].patientsRating) &&
    (document.sessions?.[editIndex]?.patientsRating ?? []).filter((s) => s).length > 0
      ? document.sessions?.[editIndex].patientsRating
      : [];

  // onChange for individual patient's ratings
  const patientsRatingOnChange =
    (index: number) =>
    (value: TOnChangeValues): void => {
      // Check if there exists an patient's rating with given index
      if (Array.isArray(patientsRating) && patientsRating.length >= index) {
        const newSessions = JSON.parse(JSON.stringify(document.sessions));
        const newPatientsRating = JSON.parse(JSON.stringify(patientsRating));
        newPatientsRating[index] = Object.assign({}, newPatientsRating[index], value);
        newSessions[editIndex].patientsRating = newPatientsRating;
        onChange && onChange({ sessions: newSessions });
      }
    };

  return Array.isArray(patientsRating) && patientsRating.length > 0 ? (
    <TabContent>
      {
        patientsRating?.map((s: ITDCSRating, i: number) => ({
          key: `${s.id}${i}`,
          id: `${s.id}Tab${i}`,
          title: s.symptom ? fm(`tdcs.patientsRating.opts.${s.symptom}`) : fm('tdcs.patientsRating.symptomPlaceholder'),
          content: <PatientsRating document={s} onChange={patientsRatingOnChange(i)} />,
          count: i,
        })) as []
      }
    </TabContent>
  ) : (
    <EmptyTabContent>{fm('tdcs.patientsRating.noSymptomsAndLocations')}</EmptyTabContent>
  );
};

export default PatientsRatings;
