import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import * as React from 'react';
import { DoctorsOrdersContext } from '../..';

// These and localizations also used in Notes
export const additionalDocumentsTypes = ['bai', 'gad-7', 'other'];
export const additionalQuestionnairesTypes = ['madrs', 'gaf', 'other'];

const AdditionalDocumentsForm = ({ formData }: { formData: IFormData<IDoctorsOrderAdditional> }): JSX.Element => {
  return (
    <React.Fragment>
      <DoctorsOrdersContext.Consumer>
        {({ fm, isEditing }) => {
          return (
            <React.Fragment>
              <FormRow
                title="doctorsOrders.additionalDocuments.documentsUsed.title"
                description={fm('doctorsOrders.additionalDocuments.documentsUsed.description')}
              >
                <InputHandler
                  editing={isEditing}
                  formData={formData}
                  name="additionalDocuments"
                  type="Checkbox"
                  options={additionalDocumentsTypes}
                  optionFormatter={(o: string | number): string =>
                    fm(`doctorsOrders.additionalDocuments.documentsUsed.opts.${o}`)
                  }
                />
              </FormRow>
              <FormRow
                title="doctorsOrders.additionalDocuments.additionalDocumentsOther.title"
                condition={
                  Array.isArray(formData.document.additionalDocuments) &&
                  formData.document.additionalDocuments.includes('other')
                }
              >
                <InputHandler
                  type="TextArea"
                  name="additionalDocumentsOther"
                  editing={isEditing}
                  formData={formData}
                  placeholder="doctorsOrders.additionalDocuments.additionalDocumentsOther.placeholder"
                />
              </FormRow>
              <FormRow title="doctorsOrders.additionalDocuments.questionnairesUsed.title">
                <InputHandler
                  editing={isEditing}
                  formData={formData}
                  name="additionalQuestionnaires"
                  type="Checkbox"
                  options={additionalQuestionnairesTypes}
                  optionFormatter={(o: string | number): string =>
                    fm(`doctorsOrders.additionalDocuments.questionnairesUsed.opts.${o}`)
                  }
                />
              </FormRow>
              <FormRow
                title="doctorsOrders.additionalDocuments.additionalDocumentsOther.title"
                condition={
                  Array.isArray(formData.document.additionalQuestionnaires) &&
                  formData.document.additionalQuestionnaires.includes('other')
                }
              >
                <InputHandler
                  type="TextArea"
                  name="additionalQuestionnairesOther"
                  editing={isEditing}
                  formData={formData}
                  placeholder="doctorsOrders.additionalDocuments.additionalDocumentsOther.placeholder"
                />
              </FormRow>
            </React.Fragment>
          );
        }}
      </DoctorsOrdersContext.Consumer>
    </React.Fragment>
  );
};

export default AdditionalDocumentsForm;
