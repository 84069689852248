import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import HistorySection from '../../../components/HistorySection';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import HistoryRowData from '../../Dbs/Document/HistoryRowData';
import DBSForm from './Form';
import { findActive, findEnded } from '../utils';
import DocumentCreationButton from '../../../components/DocumentCreationButton';

const History = ({ documents, startEdit, setAnchor }: IHistory): JSX.Element => {
  return (
    <React.Fragment>
      {findActive(documents)?.length > 0 && (
        <HistorySection headerText={<FormattedMessage id="dbs.dbsActive" />} hasHistoryRows={true}>
          {findActive(documents).map((d: IDBS, i: number) => (
            <div key={'dbs' + i}>
              <HistoryRowData d={d} startEdit={startEdit} setAnchor={setAnchor} />
            </div>
          ))}
        </HistorySection>
      )}
      {findEnded(documents)?.length > 0 && (
        <HistorySection headerText={<FormattedMessage id="dbs.dbsEnded" />} inactive={true} hasHistoryRows={true}>
          {findEnded(documents).map((d: IDBS, i: number) => (
            <div key={'dbs' + i}>
              <HistoryRowData d={d} startEdit={startEdit} setAnchor={setAnchor} />
            </div>
          ))}
        </HistorySection>
      )}
    </React.Fragment>
  );
};

interface IHistory {
  documents: IDBS[];
  startEdit: (document: IDBS) => (e: React.MouseEvent) => void;
  setAnchor: (anchor?: string) => () => void;
}

const DBS = ({ documents }: IOwnProps): JSX.Element => {
  // Anchor for scrolling to specific form sections in form
  const [anchor, setAnchor] = React.useState<string | undefined>(undefined);
  const setAnchorValue = (anchor?: string) => (): void => {
    setAnchor(anchor);
  };

  return (
    <FormEditingHandler name="dbs" documents={documents}>
      {(editing, startEdit, formData): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name={'dbs'}
            headerId={'dbs.title'}
            editing={editing}
            editButtons={
              documents && documents.length > 0 && findEnded(documents).length < documents.length ? (
                <div>&nbsp;</div>
              ) : (
                <div>
                  <DocumentCreationButton name="dbs" text={'general.new'} onClick={startEdit({})} />
                </div>
              )
            }
          />
          {editing ? <DBSForm editing={editing} formData={formData} anchor={anchor} /> : undefined}
          {!editing
            ? documents && <History documents={documents} startEdit={startEdit} setAnchor={setAnchorValue} />
            : undefined}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: IDBS[];
}

export default DBS;
