import * as React from 'react';
import EventStepper from '../../../../../components/EventStepper';
import { fm } from 'Components/FormatMessage';
import InputHandler from '../../../../../components/InputHandler';
import { formatPartialDate } from 'neuro-utils';
import { PostoperativeComplicationDetails } from '../../../components';

const PostoperativeComplicationEvents = ({ formData, disabled }: IOwnProps): JSX.Element => {
  return (
    <EventStepper
      name="postoperativeComplications"
      formData={formData}
      stepLabelText={(d: IVNSPostoperativeComplicationEvent): string => formatPartialDate(d.date)}
      stepContent={(d: IVNSPostoperativeComplicationEvent): JSX.Element => (
        <PostoperativeComplicationDetails postoperativeComplication={d} />
      )}
      addNewTextHeader="vns.newPostoperativeComplication"
      addNewTextButton="vns.newComplication"
      buttonDisabled={disabled}
      buttonDisabledMessage={fm('vns.addGeneratorFirst')}
      previousEventsTextHeader="vns.postoperativeComplications"
      editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => (
        <React.Fragment>
          <div style={{ marginBottom: '2rem' }}>
            <InputHandler
              type="PartialDate"
              editing={true}
              name="date"
              formData={{
                onChange,
                document: { date: formData.document.postoperativeComplications?.[index]?.date || '' },
              }}
              dateDefault="now"
              isNotCancellable={true}
              dateHook={{
                dateHookFloor: formData.document.date,
              }}
            />
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <InputHandler
              type="Checkbox"
              editing={true}
              name="complications"
              formData={{
                onChange,
                document: { complications: formData.document.postoperativeComplications?.[index]?.complications || '' },
              }}
              options={['fibrosis', 'infection', 'other']}
              optionFormatter={(name: string | number): JSX.Element => fm(`vns.opts.postoperativeComplication.${name}`)}
            />
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <InputHandler
              type="TextArea"
              editing={true}
              name="additionalInformation"
              formData={{
                onChange,
                document: {
                  additionalInformation:
                    formData.document.postoperativeComplications?.[index]?.additionalInformation || '',
                },
              }}
              placeholder="vns.postoperativeComplicationAdditionalInformation"
            />
          </div>
        </React.Fragment>
      )}
    />
  );
};

interface IOwnProps {
  formData: IFormData<IVNS>;
  disabled?: boolean;
}

export default PostoperativeComplicationEvents;
