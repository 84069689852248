import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import { renderTmtRow as renderRow } from '../../../utils';

const TMTForm = ({ formData, viewing }: IOwnProps): JSX.Element => (
  <React.Fragment>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={!viewing}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="cognition.download">
      <a href="/files/TMT-Test-Finnish.pdf" target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="cognition.downloadHere" />
      </a>
    </FormRow>
    <FormSection>
      <FormRow title="cognition.tmt.tmta" condition={renderRow(formData.document, viewing, 'tmtaTime')}>
        <InputHandler
          type="NumberField"
          editing={!viewing}
          name="tmtaTime"
          formData={formData}
          placeholder={'cognition.tmt.tmtPlaceholder'}
        />
      </FormRow>
      <FormRow
        title={!viewing ? 'cognition.tmt.unableToPerform' : 'cognition.tmt.tmta'}
        condition={renderRow(formData.document, viewing, 'tmtaUnableToPerform')}
      >
        <InputHandler
          type="Checkbox"
          editing={!viewing}
          name="tmtaUnableToPerform"
          formData={formData}
          options={['unableToPerform']}
          optionFormatter={(name: string | number): JSX.Element => <FormattedMessage id={`cognition.tmt.${name}`} />}
        />
      </FormRow>
    </FormSection>
    <FormSection>
      <FormRow title="cognition.tmt.tmtb" condition={renderRow(formData.document, viewing, 'tmtbTime')}>
        <InputHandler
          type="NumberField"
          editing={!viewing}
          name="tmtbTime"
          formData={formData}
          placeholder={'cognition.tmt.tmtPlaceholder'}
        />
      </FormRow>
      <FormRow
        title={!viewing ? 'cognition.tmt.unableToPerform' : 'cognition.tmt.tmtb'}
        description={<FormattedMessage id="cognition.tmt.tmtbUnableToPerformDescription" />}
        condition={renderRow(formData.document, viewing, 'tmtbUnableToPerform')}
      >
        <InputHandler
          type="Checkbox"
          editing={!viewing}
          name="tmtbUnableToPerform"
          formData={formData}
          options={['unableToPerform']}
          optionFormatter={(name: string | number): JSX.Element => <FormattedMessage id={`cognition.tmt.${name}`} />}
        />
      </FormRow>
    </FormSection>
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<ITMT>;
  viewing?: boolean;
}

export default TMTForm;
