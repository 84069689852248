import * as React from 'react';
import { Container, Item } from '../../../../../../components/Grid';
import HistoryRow from '../../../../../../components/HistoryRow';
import HistoryRowControls from '../../../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../../../components/HistoryRowListing';
import { IHistoryContext, withHistoryContext } from '../../../../../../containers/FormContextHandler';
import { exists, formatPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import { newButton } from '../../../../utils/functions';

const PerformanceRatingHistory = ({ documents = [], startEdit }: IHistoryContext): JSX.Element => {
  const performanceRatingDocuments: IPerformanceRating[] = documents.filter(
    (d) => d._type === 'performanceRating',
  ) as IPerformanceRating[];
  return (
    <HistoryRow
      headerText={fm('diagnosis.sleepApnea.performanceRating.title')}
      rowButton={
        startEdit ? newButton('performanceRating', startEdit({}, 'performanceRating'), 'general.new', 15) : undefined
      }
    >
      <HistoryRowListing
        documents={performanceRatingDocuments}
        headers={
          <Item xs={12}>
            <Container>
              <Item xs={5}>{fm('general.date')}</Item>
              <Item xs={7}>{fm('diagnosis.sleepApnea.performanceRating.rating')}</Item>
            </Container>
          </Item>
        }
        contentFormat={(d: IPerformanceRating): JSX.Element => (
          <Item xs={12}>
            <Container>
              <Item xs={5}>{d?.date ? formatPartialDate(d?.date) : '-'}</Item>
              <Item xs={5} style={{ paddingRight: '1rem' }}>
                {exists(d?.rating) ? (
                  <span>
                    {d?.rating} - {fm(`diagnosis.sleepApnea.performanceRating.opts.${d?.rating}`)}
                  </span>
                ) : (
                  '-'
                )}
              </Item>
              <Item xs={2}>{startEdit ? <HistoryRowControls document={d} startEdit={startEdit} /> : null}</Item>
            </Container>
          </Item>
        )}
        collapse={false}
      />
    </HistoryRow>
  );
};

export default withHistoryContext(PerformanceRatingHistory);
