import { exists } from 'neuro-utils';

const getLevel = (symptom: string): string => {
  return symptom.split(/(?=[A-Z])/)[0];
};

const getSide = (symptom: string): string => {
  const separate = symptom.split(/(?=[A-Z])/);
  return separate[separate.length - 1];
};

const allNormal = (values: { [key: string]: any }): boolean => {
  return Object.keys(values).filter((key) => {
    return typeof values[key] === 'object' && values[key] !== null
      ? Object.keys(values[key]).filter((key2) => {
          return values[key][key2] && values[key][key2] !== 0 && values[key][key2] !== 'notDone';
        }).length > 0
      : values[key] && values[key] !== 0 && values[key] !== 'notDone';
  }).length > 0
    ? false
    : true;
};

const allNotDoneOrUndefined = (values: { [key: string]: any }): boolean => {
  return Object.keys(values).filter((key) => {
    return typeof values?.[key] === 'object' && values?.[key] !== null
      ? !Object.keys(values?.[key]).every((key2) => !exists(values?.[key][key2]) || values?.[key][key2] === 'notDone')
      : !(!exists(values?.[key]) || values?.[key] === 'notDone');
  }).length > 0
    ? false
    : true;
};

const limbStatus = (values: { [key: string]: any }, maxValue: number, level: string, side: string): boolean => {
  return Object.keys(values).filter((key) => {
    return (
      typeof values[key] === 'object' &&
      values[key] !== null &&
      Object.keys(values[key]).filter((key2) => {
        return getLevel(key2) === level && getSide(key2) === side
          ? values[key][key2] && values[key][key2] > maxValue
          : false;
      }).length > 0
    );
  }).length > 0
    ? true
    : false;
};

const cerebellarCalculator = (cerebellarFunctions: { [key: string]: any }): number | undefined => {
  const c = cerebellarFunctions;

  const checkLimbScores = (maxValue: number): number => {
    return (
      Number(limbStatus(c, maxValue, 'upper', 'Right')) +
      Number(limbStatus(c, maxValue, 'upper', 'Left')) +
      Number(limbStatus(c, maxValue, 'lower', 'Right')) +
      Number(limbStatus(c, maxValue, 'lower', 'Left'))
    );
  };

  let score = 0;

  if (allNotDoneOrUndefined(c)) {
    return undefined;
  }
  if (allNormal(c)) {
    score = 0;
  }
  if (!allNormal(c)) {
    score = 1;
  }
  if (checkLimbScores(1) > 0 || c?.rombergTest > 1 || c?.tandemWalking > 1) {
    score = 2;
  }
  if (checkLimbScores(2) > 0 || c?.gaitAtaxia > 2 || c?.truncalAtaxia > 2) {
    score = 3;
  }
  if ((c?.gaitAtaxia > 3 || c?.truncalAtaxia > 3) && checkLimbScores(3) > 2) {
    score = 4;
  }
  if (
    c?.headTremor > 2 &&
    c?.truncalAtaxia > 3 &&
    checkLimbScores(3) > 3 &&
    c?.tandemWalking > 1 &&
    c?.gaitAtaxia > 3 &&
    c?.rombergTest > 2
  ) {
    score = 5;
  }
  return score;
};

export default cerebellarCalculator;
