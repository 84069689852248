import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

import { Container, Item } from '../../../../../components/Grid';

import { formatPartialDate, formatTime, isPartialDate, isTime, sortPartialDate } from 'neuro-utils';

import { staticRegimenElements } from '../../config';
import { canEditLockedRegimen } from '../../../utils';
import {
  RegimenCustomHistory,
  RegimenDefaultHistory,
  RegimenOnDemandHistory,
  RegimenOtherHistory,
  RegimenSingleDoseHistory,
} from './components/Regimen/RegimenItems';

import ModifyLockedRegimenDialog from '../../Form/Medication/ModifyLockedRegimenDialog';
import DosingInstructions from '../../Form/Medication/RegimenDialog/components/DosingInstructions';
import MedicationEndReasonDialog from 'Components/HistoryRowControls/MedicationEndReasonDialog';
import MedicationStartDialog from 'Components/HistoryRowControls/MedicationStartDialog';
import { PartialDate } from 'neuro-utils/types/common';
import {
  AdverseEventIcon,
  DeleteButton,
  EditButton,
  HistoryListHeader,
  NewButton,
  RegimenIconByType,
  StatusIconByStatus,
} from './components/Common';
import findMatchingAdverseEvents from 'Routes/Medication/utils/findMatchingAdverseEvents';
import AdverseEventListItem from './AdverseEventListItem';
import { isNil } from 'Utility/ramdaReplacement';
import { useAppSelector } from 'Store/index';
import { HistoryEventListItem, HistoryEventListTopHeader } from 'Components/_NewElements/HistoryEvents';

const StyledHistory = styled('div')({
  fontSize: '1.6rem',
});

const StyledButtonArea = styled(Item)(({ bigMargin }: { bigMargin?: boolean }) => ({
  '* > &:not(:last-of-type)': { marginBottom: bigMargin ? '2.5rem' : '1.5rem' },
}));

const EditLockedRegimenButtonAndDialog = ({
  d,
  regimen,
}: {
  d: IMedication;
  regimen: IRegimenBasics & (IRegimenCustom | IRegimenDefault | IRegimenOnDemand | IRegimenSingleDose | IRegimenOther);
}): JSX.Element | null => {
  const [open, setOpen] = React.useState<boolean>(false);
  const openDialog = (thisOpen: boolean): void => {
    setOpen(thisOpen);
  };

  // Set this dynamically if needed for some other field
  const fieldName = 'infusionDoses' as keyof typeof regimen;

  return canEditLockedRegimen(d, regimen) ? (
    <>
      <Container style={{ marginTop: '2rem' }}>
        <Item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <EditButton
            text={'general.edit'}
            onClick={(): void => {
              openDialog(true);
            }}
          />
        </Item>
      </Container>
      <ModifyLockedRegimenDialog open={open} setOpenDialog={openDialog} regimen={regimen} d={d} field={fieldName} />
    </>
  ) : null;
};

const DosageAndRegimen = ({ regimen, doc }: { regimen: Regimen; doc: IMedication }): JSX.Element => {
  const regimenType = regimen.regimenType;
  return (
    <>
      <HistoryEventListTopHeader alignItems="center">
        <Item xs={9}>
          <FormattedMessage id={`medication.regimenTypes.${regimenType}`} />
          {regimen.routeOfAdministration && (
            <span>
              {` (`}
              <FormattedMessage id={`medication.opts.mr.${regimen.routeOfAdministration}`} />
              {`)`}
            </span>
          )}
        </Item>
        <Item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DosingInstructions medication={doc} />
        </Item>
      </HistoryEventListTopHeader>

      {regimen?.regimenType === 'default' ? (
        <RegimenDefaultHistory regimen={regimen as IRegimenBasics & IRegimenDefault} />
      ) : regimen?.regimenType === 'onDemand' ? (
        <RegimenOnDemandHistory regimen={regimen as IRegimenBasics & IRegimenOnDemand} />
      ) : regimen?.regimenType === 'custom' ? (
        <RegimenCustomHistory regimen={regimen as IRegimenBasics & IRegimenCustom} d={doc} />
      ) : regimen?.regimenType === 'single-dose' ? (
        <RegimenSingleDoseHistory regimen={regimen as IRegimenBasics & IRegimenSingleDose} />
      ) : regimen?.regimenType === 'other' ? (
        <RegimenOtherHistory regimen={regimen as IRegimenBasics & IRegimenOther} />
      ) : regimen?.regimenType === 'static' ? (
        <HistoryEventListItem
          value={staticRegimenElements[doc?.medicationName?.toUpperCase() || ''] || undefined}
          fullWidthValue
        />
      ) : undefined}
    </>
  );
};

const Administration = ({ administration }: { administration: IAdministration }): JSX.Element => {
  return (
    <>
      <HistoryEventListItem
        title="medication.administration.date"
        value={administration.date && formatPartialDate(administration.date)}
      />
      <HistoryEventListItem
        title="medication.administration.startTime"
        value={isTime(administration.startTime) ? formatTime(administration.startTime) : undefined}
      />
      <HistoryEventListItem
        title="medication.administration.endDate"
        value={isPartialDate(administration.endDate) ? formatPartialDate(administration.endDate) : undefined}
      />
      <HistoryEventListItem
        title="medication.administration.endTime"
        value={isTime(administration.endTime) ? formatTime(administration.endTime) : undefined}
      />
      <HistoryEventListItem
        title="medication.administration.doseQuantity"
        value={
          <>
            {administration.doseQuantity || null}{' '}
            {administration.doseQuantityUnit ? <FormattedMessage id={`${administration.doseQuantityUnit}`} /> : ''}
          </>
        }
      />
      <HistoryEventListItem
        title="medication.administration.isExtraDose"
        value={<FormattedMessage id={`general.${administration?.isExtraDose?.[0] ? 'yes' : 'no'}`} />}
      />
      <HistoryEventListItem title="medication.additionalInformation" value={administration?.additionalInformation} />
    </>
  );
};

const Pausation = ({ pause }: { pause: IMedicationPause }): JSX.Element => (
  <>
    <HistoryEventListItem
      title="medication.pausationReason"
      value={pause.pausationReason && <FormattedMessage id={`medication.pausationReasons.${pause.pausationReason}`} />}
    />
    <HistoryEventListItem
      title="medication.pausationDate"
      value={
        isPartialDate(pause.startDate) ? (
          <div>
            <div>{formatPartialDate(pause.startDate)}</div>
            <div>{formatTime(pause.startTime)}</div>
          </div>
        ) : undefined
      }
    />
    <HistoryEventListItem
      title="medication.continuationDate"
      value={
        isPartialDate(pause.endDate) ? (
          <div>
            <div>{formatPartialDate(pause.endDate)}</div>
            <div>{formatTime(pause.endTime)}</div>
          </div>
        ) : undefined
      }
    />

    <HistoryEventListItem title="medication.pausationAdditionalInfoPlaceholder" value={pause.additionalInfo} />
  </>
);

const Ending = ({ doc }: { doc: IMedication }) => {
  return (
    <>
      <HistoryEventListItem
        title="general.date"
        value={
          <>
            {formatPartialDate(doc.endDate)}
            {`${doc.endTime ? ` (${formatTime(doc.endTime)})` : ''}`}
          </>
        }
      />
      <HistoryEventListItem
        title="medication.endedReason"
        value={
          <>
            {doc.endReason?.map((reason, i) => (
              <React.Fragment key={reason}>
                <FormattedMessage id={`medication.opts.${reason}`} />
                {`${i < (doc.endReason?.length || 0) - 1 ? ', ' : ''}` /* Print commas between reasons */}
              </React.Fragment>
            ))}
          </>
        }
      />
      <HistoryEventListItem title="medication.endedReasonOther" value={doc.endReasonOther} />
    </>
  );
};

const HistoryListIcons = (
  type: 'regimen' | 'administration' | 'pause' | 'adverseEffect' | 'ending',
  regimenType?: IRegimenBasics['regimenType'],
) => {
  return type === 'pause'
    ? StatusIconByStatus('paused')
    : type === 'administration'
      ? RegimenIconByType('single-dose')
      : type === 'adverseEffect'
        ? AdverseEventIcon()
        : type === 'ending'
          ? StatusIconByStatus('ended')
          : regimenType
            ? RegimenIconByType(regimenType)
            : undefined;
};

const MedicationHistoryRowData = ({ d, startEdit, setMedID, adverseEvents }: IOwnProps): JSX.Element => {
  const [medicationStartDialogOpen, setMedicationStartDialogOpen] = React.useState<boolean>(false);
  const [medicationEndDialogOpen, setMedicationEndDialogOpen] = React.useState<boolean>(false);

  const userOrg = useAppSelector((s: IState) => s.session.data?.orgid) || '';
  const lockedFor = !isNil((d as IControlProps)._lockedFor);
  const ownedByThisOrg = d._ownerOrg === userOrg;

  const medicationDialogOpener = (dialogType: 'start' | 'end') => {
    switch (dialogType) {
      case 'start':
        setMedicationStartDialogOpen(true);
        break;
      case 'end':
        setMedicationEndDialogOpen(true);
    }
  };

  const matchedAdverseEffects = findMatchingAdverseEvents(adverseEvents, d._id);

  const medicationEnding = d.hasEnded?.[0] === true ? { type: 'ending', data: d } : null;

  const events = (
    [
      ...(medicationEnding ? [medicationEnding] : []),
      ...(d.regimen || []).map((reg) => ({ type: 'regimen', data: reg })),
      ...(d.administration || []).map((adm) => ({ type: 'administration', data: adm })),
      ...(d.pauses || []).map((pau) => ({ type: 'pause', data: pau })),
      ...(matchedAdverseEffects || []).map((adv) => ({ type: 'adverseEffect', data: adv })),
    ] as Array<{ type: 'regimen' | 'administration' | 'pause' | 'adverseEffect' | 'ending'; data: any }>
  ).sort((e1, e2) => {
    const e1date = e1.data.date || e1.data.startDate;
    const e2date = e2.data.date || e2.data.startDate;
    if (e2.type === 'ending') return 1;
    return sortPartialDate(e2date, e1date);
  });

  const [historyItemOpenId, setHistoryItemOpeId] = React.useState<string | null>(
    //events.length > 0 && 'id' in events[0].data ? events[0].data.id : events.length > 0 ? events[0].type : null,
    null,
  );

  return (
    <StyledHistory>
      <Container style={{ padding: '1rem 1rem 1rem 0' }}>
        <Item xs={8.5}>
          {events.map((ev, i) => {
            const date: PartialDate | undefined =
              'date' in ev.data ? ev.data.date : 'startDate' in ev.data ? ev.data.startDate : undefined;
            const id: string = 'id' in ev.data ? ev.data.id : '_id' in ev.data ? ev.data._id : ev.type;
            const historyItemOpen = historyItemOpenId === null ? i === 0 : id === historyItemOpenId;
            return (
              <React.Fragment key={id}>
                <HistoryListHeader
                  open={historyItemOpen}
                  date={date}
                  type={ev.type}
                  typeIcon={HistoryListIcons(ev.type, 'regimenType' in ev.data && ev.data.regimenType)}
                  onClick={() => setHistoryItemOpeId(id === historyItemOpenId ? null : id)}
                  dates={ev.type === 'pause' ? { startDate: ev.data.startDate, endDate: ev.data.endDate } : undefined}
                />
                {historyItemOpen && (
                  <>
                    <Container style={{ marginBottom: '2rem' }}>
                      <Item xs={0.6}></Item>
                      <Item xs={1.5}></Item>
                      <Item xs={0.7}></Item>
                      <Item xs={true}>
                        {ev.type === 'regimen' && <DosageAndRegimen regimen={ev.data as Regimen} doc={d} />}
                        {ev.type === 'administration' && <Administration administration={ev.data as IAdministration} />}
                        {ev.type === 'pause' && <Pausation pause={ev.data as IMedicationPause} />}
                        {ev.type === 'adverseEffect' && (
                          <AdverseEventListItem adverseEvent={ev.data} startEdit={startEdit} />
                        )}
                        {ev.type === 'ending' && <Ending doc={d} />}
                        {/** Delete and Edit buttons for adverse effects */}
                        {ev.type === 'adverseEffect' && (
                          <Container style={{ marginTop: '2rem' }}>
                            <Item xs={6}>
                              <DeleteButton text="medication.deleteAdverseEffect" document={ev.data} />
                            </Item>
                            <Item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <EditButton document={ev.data} documentType="adverseEffect" startEdit={startEdit} />
                            </Item>
                          </Container>
                        )}

                        {/** Edit button for editable regimens */}
                        {ev.type === 'regimen' && <EditLockedRegimenButtonAndDialog d={d} regimen={ev.data} />}
                      </Item>
                    </Container>
                  </>
                )}
              </React.Fragment>
            );
          })}
        </Item>
        <Item xs={0.5}></Item>
        <Item xs={true}>
          <Container direction={'column'} style={{ marginTop: '4rem', marginBottom: '2rem' }}>
            <StyledButtonArea>
              <NewButton
                text="medication.newAdverseEffect"
                onClick={(e: React.MouseEvent): void => {
                  setMedID(d._id);
                  startEdit({}, 'adverseEffect')(e);
                }}
              />
            </StyledButtonArea>
            {lockedFor && ownedByThisOrg && medicationEnding && (
              <StyledButtonArea>
                <NewButton
                  text={d.endReason ? 'medication.editEndReason' : 'medication.addEndReason'}
                  onClick={() => medicationDialogOpener('end')}
                />
              </StyledButtonArea>
            )}

            {lockedFor && ownedByThisOrg && (
              <StyledButtonArea>
                <NewButton
                  text={d.usageStartDate ? 'medication.editStartDate' : 'medication.addStartDate'}
                  onClick={() => medicationDialogOpener('start')}
                />
              </StyledButtonArea>
            )}

            <StyledButtonArea style={{ marginTop: '0.5rem' }}>
              <EditButton
                text="medication.editMedication"
                document={d}
                documentType="medication"
                startEdit={startEdit}
                buttonProps={{
                  width: 22,
                  height: 3,
                  disabled: lockedFor,
                }}
              />
            </StyledButtonArea>

            <StyledButtonArea style={{ marginTop: '0.5rem' }}>
              <DeleteButton
                text="medication.deleteMedication"
                document={d}
                buttonProps={{
                  width: 22,
                  height: 3,
                }}
              />
            </StyledButtonArea>
          </Container>
        </Item>
      </Container>

      {medicationStartDialogOpen && (
        <MedicationStartDialog document={d} openState={[medicationStartDialogOpen, setMedicationStartDialogOpen]} />
      )}
      {medicationEndDialogOpen && (
        <MedicationEndReasonDialog document={d} openState={[medicationEndDialogOpen, setMedicationEndDialogOpen]} />
      )}
    </StyledHistory>
  );
};

interface IOwnProps {
  d: IMedication;
  ended?: boolean;
  startEdit: (document: { _id?: string }, name?: string) => (e: React.MouseEvent) => void;
  setMedID: (id: string) => void;
  adverseEvents: IMedicationAdverseEvent[];
  paused?: boolean;
}

export default MedicationHistoryRowData;
