import * as React from 'react';
import * as Component from './Components';

import HistoryRowControls from '../../../../components/HistoryRowControls';
import { IHistoryContext, withHistoryContext } from '../../../../containers/FormContextHandler';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import HistoryRow from '../../../../components/HistoryRow';
import HistoryRowSubHeader from '../../../../components/HistoryRowSubHeader';

import { DataContext } from './Components/context';

const headerText = (d: ISurgicalTreatment, fm?: (id: string) => string): string => {
  const date = d.date ? formatPartialDate(d.date) + ' - ' : '';
  const typeOfOperationFirst =
    d.operations?.[0].typeOfOperation && fm
      ? fm(`surgicalTreatment.operation.typeOfOperation.opts.${d.operations[0].typeOfOperation}`)
      : '';
  const typeOfOperationSecond =
    d.operations?.[1]?.typeOfOperation && fm
      ? ' / ' + fm(`surgicalTreatment.operation.typeOfOperation.opts.${d.operations[1].typeOfOperation}`)
      : '';
  return date + typeOfOperationFirst + typeOfOperationSecond;
};

const SurgicalTreatmentHistoryRow = ({ startEdit, documents, fm }: IHistoryContext): JSX.Element => {
  const locPath = 'surgicalTreatment';
  return (
    <React.Fragment>
      {startEdit &&
        documents &&
        documents.length > 0 &&
        (documents
          ? documents
              .toSorted((a, b) => sortPartialDate(b.date, a.date))
              .map((d: ISurgicalTreatment) => {
                const uncededCompication =
                  d.complications && d.complications.filter((comp) => !isPartialDate(comp.complicationCededDate));
                const cededCompication =
                  d.complications && d.complications.filter((comp) => isPartialDate(comp.complicationCededDate));
                return (
                  <React.Fragment key={d._id}>
                    <HistoryRow
                      headerText={headerText(d, fm)}
                      rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
                      controlsMargin={false}
                    >
                      <React.Fragment>
                        {d.operations
                          ? d.operations.map((o: IOperation) => (
                              <DataContext.Provider key={o.id} value={{ d: o, fm: fm }}>
                                <Component.Operations />
                              </DataContext.Provider>
                            ))
                          : ''}
                        {d.pads ? (
                          <React.Fragment>
                            <HistoryRowSubHeader header={fm ? fm(`${locPath}.pad.header`) : ''} />
                            {d.pads.map((p: IPad) => (
                              <DataContext.Provider key={p.id} value={{ d: p, fm: fm }}>
                                <Component.Pads />
                              </DataContext.Provider>
                            ))}
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                        {Array.isArray(uncededCompication) && uncededCompication.length > 0 ? (
                          <React.Fragment>
                            <HistoryRowSubHeader header={fm ? fm(`${locPath}.complications.header`) : ''} />
                            {uncededCompication
                              .toSorted((a, b) => sortPartialDate(a.date, b.date))
                              .map((c: IComplications) => (
                                <DataContext.Provider key={c.id} value={{ d: c, fm: fm }}>
                                  <Component.Complications />
                                </DataContext.Provider>
                              ))}
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                        {Array.isArray(cededCompication) && cededCompication.length > 0 ? (
                          <React.Fragment>
                            <HistoryRowSubHeader header={fm ? fm(`${locPath}.complications.cededComplications`) : ''} />
                            {cededCompication
                              .toSorted((a, b) => sortPartialDate(a.date, b.date))
                              .map((c: IComplications) => (
                                <DataContext.Provider key={c.id} value={{ d: c, fm: fm }}>
                                  <Component.Complications />
                                </DataContext.Provider>
                              ))}
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                        {Array.isArray(d.engelClassification) && d.engelClassification.length > 0 ? (
                          <React.Fragment>
                            <HistoryRowSubHeader header={fm ? fm(`${locPath}.engelClassification.header`) : ''} />
                            {d.engelClassification
                              .toSorted((a, b) => sortPartialDate(a.date, b.date))
                              .map((e: IEngelClassification) => (
                                <DataContext.Provider key={e.id} value={{ d: e, fm: fm }}>
                                  <Component.EngelClassification />
                                </DataContext.Provider>
                              ))}
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    </HistoryRow>
                  </React.Fragment>
                );
              })
          : null)}
    </React.Fragment>
  );
};

export default withHistoryContext(SurgicalTreatmentHistoryRow);
