import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistoryRowBasicItem from '../../../../components/HistoryRowBasicItem';
import { Container, Item } from '../../../../components/Grid';
import { getDescription } from '../../../../utility/randomUtil';

const fm = (id: string, values?: { [key: string]: string | number | null }): JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const HospitalizationHistoryRowData = ({ d }: IOwnProps): JSX.Element => {
  const [icd10description, setIcd10description] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    let isMounted = true;
    d.classification?.icd10code &&
      getDescription(d.classification.icd10code)
        .then((r) => {
          if (isMounted) {
            setIcd10description(r);
          }
        })
        .catch(() => {
          return;
        });
    return (): void => {
      isMounted = false;
    };
  }, [d?.classification?.icd10code]);

  return (
    <>
      <Container alignItems="flex-start">
        <Item xs={2}>
          {d.hospitalizationType && (
            <HistoryRowBasicItem
              header={fm('comorbidity.hospitalizationType')}
              value={<div style={{ fontWeight: 600 }}>{fm('comorbidity.opts.' + d.hospitalizationType)}</div>}
            />
          )}
        </Item>
        <Item xs={7}>
          {d.hospitalizationType && d.hospitalizationType === 'planned' && d.classification ? (
            <Container>
              <Item xs={6}>
                <HistoryRowBasicItem
                  header={fm('comorbidity.plannedReason')}
                  value={
                    <div>
                      {d.classification?.icd10code
                        ? `${d.classification?.icd10code}${icd10description ? ' - ' + icd10description : ''}`
                        : '-'}
                    </div>
                  }
                />
              </Item>
              <Item xs={6}>
                <HistoryRowBasicItem header={fm('comorbidity.details')} value={d.hospitalizationDetails} />
              </Item>
            </Container>
          ) : d.hospitalizationType && d.hospitalizationType === 'acute' && d.classification ? (
            <Container>
              <Item xs={4}>
                <HistoryRowBasicItem
                  header={fm('comorbidity.acuteReason')}
                  value={
                    <div>
                      {d.classification?.icd10code
                        ? `${d.classification?.icd10code}${icd10description ? ' - ' + icd10description : ''}`
                        : '-'}
                    </div>
                  }
                />
              </Item>
              <Item xs={4}>
                <HistoryRowBasicItem header={fm('comorbidity.details')} value={d.hospitalizationDetails} />
              </Item>
              <Item xs={4}>
                <HistoryRowBasicItem
                  header={fm('comorbidity.acuteAdverse')}
                  value={d.acuteAdverseEffect ? fm(`general.${d.acuteAdverseEffect}`) : '-'}
                />
              </Item>
            </Container>
          ) : (
            ''
          )}
        </Item>
      </Container>
    </>
  );
};

interface IOwnProps {
  d: IHospitalization;
}

export default HospitalizationHistoryRowData;
