import { filter, includes, length, map, path } from 'ramda';
import {
  coreClinicalFields,
  indicativeBiomarkerFields,
  supportiveBiomarkerFields,
  supportiveClinicalFields,
} from '../definitions';

// Functions for counting and comparison
const countAnswers = (document: IDLB, answerType: 'yes' | 'unknown', fields: Array<string>): number => {
  let count = 0;
  map((field: string) => {
    if (path([field], document) === answerType) count++;
  }, fields);
  return count;
};

//Probable DLB comparison
/** coreYes >= 2 */
const propableDLBaYes = (coreYes: number): boolean => coreYes >= 2;

/** coreYes + coreUnknown <= 1 */
const propableDLBaNo = (coreYes: number, coreUnknown: number): boolean => coreYes + coreUnknown <= 1;

/** coreYes === 1 && bioYes >= 1 */
const propableDLBbYes = (coreYes: number, bioYes: number): boolean => coreYes === 1 && bioYes >= 1;

/** coreYes + coreUnknown === 0 || (coreYes === 1 && coreUnknown === 0 && bioYes + bioUnknown === 0) */
const propableDLBbNo = (coreYes: number, coreUnknown: number, bioYes: number, bioUnknown: number): boolean =>
  coreYes + coreUnknown === 0 || (coreYes === 1 && coreUnknown === 0 && bioYes + bioUnknown === 0);

// Possible DLB comparison
/** coreYes === 1 */
const possibleDLBaYes = (coreYes: number): boolean => coreYes === 1;

/** coreYes + coreUnknown === 0 */
const possibleDLBaNo = (coreYes: number, coreUnknown: number): boolean => coreYes + coreUnknown === 0;

/** bioYes >= 1 */
const possibleDLBbYes = (bioYes: number): boolean => bioYes >= 1;

/** bioYes + bioUnknown === 0 */
const possibleDLBbNo = (bioYes: number, bioUnknown: number): boolean => bioYes + bioUnknown === 0;

/** yes ? 'yes' : no ? 'no' : 'unknown' */
const setYesNoUnkown = (yes: boolean, no: boolean): TYesNoUnknown => (yes ? 'yes' : no ? 'no' : 'unknown');

// Functions for DLB diagnostic criteria
/**
    Ohjelma päättelee tämän edellä olevien vastausten perusteella:
    - "Kyllä" vastaus edellyttää, että core clinical features "Kyllä" statuksella on 2 tai enemmän. 
    - "Ei" vastaus edellyttää, että core clinical features "Kyllä" + "Ei tiedossa" statuksella on 1 tai vähemmän. 
    - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const setProbableDLBAnswerA = (document: IDLB): TYesNoUnknown =>
  setYesNoUnkown(
    propableDLBaYes(countAnswers(document, 'yes', coreClinicalFields)),
    propableDLBaNo(
      countAnswers(document, 'yes', coreClinicalFields),
      countAnswers(document, 'unknown', coreClinicalFields),
    ),
  );

/**
    Ohjelma päättelee tämän edellä olevien vastausten perusteella:
    - "Kyllä" vastaus edellyttää, että core clinical features "Kyllä" statuksella on 1, ja indicative biomarkers "Kyllä" statuksella 1 tai enemmän. 
    - "Ei" vastaus edellyttää, että 
        a) core clinical features "Kyllä" + "Ei tiedossa" statuksella on 0, tai 
        b)  core clinical features "Kyllä" statuksella on 1, "Ei tiedossa" statuksella 0 ja indicative biomarkers "Kyllä" + "Ei tiedossa" statuksella on 0. 
    - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const setProbableDLBAnswerB = (document: IDLB): TYesNoUnknownEmpty =>
  setYesNoUnkown(
    propableDLBbYes(
      countAnswers(document, 'yes', coreClinicalFields),
      countAnswers(document, 'yes', indicativeBiomarkerFields),
    ),
    propableDLBbNo(
      countAnswers(document, 'yes', coreClinicalFields),
      countAnswers(document, 'unknown', coreClinicalFields),
      countAnswers(document, 'yes', indicativeBiomarkerFields),
      countAnswers(document, 'unknown', indicativeBiomarkerFields),
    ),
  );
/**
    Ohjelma päättelee tämän edellä olevien vastausten perusteella:
    - "Kyllä" vastaus edellyttää, että core clinical features "Kyllä" statuksella on 1. 
    - "Ei" vastaus edellyttää, että a) core clinical features "Kyllä" + "Ei tiedossa" statuksella on 0. 
    - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const setPossibleDLBAnswerA = (document: IDLB): TYesNoUnknownEmpty =>
  setYesNoUnkown(
    possibleDLBaYes(countAnswers(document, 'yes', coreClinicalFields)),
    possibleDLBaNo(
      countAnswers(document, 'yes', coreClinicalFields),
      countAnswers(document, 'unknown', coreClinicalFields),
    ),
  );

/**
    Ohjelma päättelee tämän edellä olevien vastausten perusteella:
    - "Kyllä" vastaus edellyttää, että indicative biomarkers statuksella "Kyllä"  on 1 tai enemmän. 
    - "Ei" vastaus edellyttää, että  indicative biomarkers statuksella "Kyllä" + "Ei tiedossa" on 0. 
    - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 */
export const setPossibleDLBAnswerB = (document: IDLB): TYesNoUnknownEmpty =>
  setYesNoUnkown(
    possibleDLBbYes(countAnswers(document, 'yes', indicativeBiomarkerFields)),
    possibleDLBbNo(
      countAnswers(document, 'yes', indicativeBiomarkerFields),
      countAnswers(document, 'unknown', indicativeBiomarkerFields),
    ),
  );

// DLB diagnostic criteria history answer
const historyDLBanswer = (a?: TYesNoUnknownEmpty, b?: TYesNoUnknownEmpty): TYesNoUnknownEmpty => {
  if (includes('yes', [a, b])) return 'yes';
  if (a === 'no' && b === 'no') return 'no';
  if (includes('unknown', [a, b])) return 'unknown';
  return 'empty';
};

export const probableDLB = (document: IDLB): TYesNoUnknownEmpty =>
  historyDLBanswer(setProbableDLBAnswerA(document), setProbableDLBAnswerB(document));

export const possibleDLB = (document: IDLB): TYesNoUnknownEmpty =>
  historyDLBanswer(setPossibleDLBAnswerA(document), setPossibleDLBAnswerB(document));

//Functions for Summary field values
export const probableDLBCanBeDiagnosed = (document: IDLB): TDLBYesNoUnknownEmpty => {
  const yes = setProbableDLBAnswerA(document) === 'yes' || setProbableDLBAnswerB(document) === 'yes';
  const no = setProbableDLBAnswerA(document) === 'no' && setProbableDLBAnswerB(document) === 'no';
  if (yes) {
    return 'yes';
  } else if (no) {
    return 'no';
  } else {
    return 'unknown';
  }
};

export const possibleDLBCanBeDiagnosed = (document: IDLB): TDLBYesNoUnknownEmpty => {
  const yes = setPossibleDLBAnswerA(document) === 'yes' || setPossibleDLBAnswerB(document) === 'yes';
  const no = setPossibleDLBAnswerA(document) === 'no' && setPossibleDLBAnswerB(document) === 'no';
  if (yes) {
    return 'yes';
  } else if (no) {
    return 'no';
  } else {
    return 'unknown';
  }
};

export const numberOfSupprotiveClinicalFeatures = (document: IDLB, answer: TYesNoUnknown): number =>
  length(filter((feature: string) => path([feature], document) === answer, supportiveClinicalFields));

export const numberOfSupprotiveBiomarkers = (document: IDLB, answer: TYesNoUnknown): number =>
  length(filter((feature: string) => path([feature], document) === answer, supportiveBiomarkerFields));
