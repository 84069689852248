import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useIntl } from 'react-intl';
import { isArrayOfGivenType } from 'neuro-utils';

const isArrayOfStrings = (arg: unknown[]): arg is Array<string> => {
  return isArrayOfGivenType(arg, 'string');
};

const isArrayOfICategoryOptions = (arg: any[]): arg is Array<ICategoryOptions> => {
  return arg.every((o) => Object.keys(o).includes('localization'));
};

const ACSearch = ({ acOptions, setFilter, width, onChangeEffect }: IOwnProps): JSX.Element => {
  const [value, setValue] = React.useState<string | null>(null);
  const [inputValue, setInputValue] = React.useState('');

  let options: Array<string> = [];
  if (isArrayOfStrings(acOptions)) {
    options = acOptions;
  } else if (isArrayOfICategoryOptions(acOptions)) {
    options = acOptions.map((o) => o.title);
  }

  React.useEffect(() => {
    setFilter(value ?? '');
  }, [value, setFilter]);

  React.useEffect(() => {
    setFilter(inputValue);
  }, [inputValue, setFilter]);

  const intl = useIntl();

  return (
    <Autocomplete
      value={value}
      onChange={(_event, newValue): void => {
        onChangeEffect && onChangeEffect();
        setValue(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue): void => {
        setInputValue(newInputValue);
      }}
      options={options}
      style={{ width: `${width || 25}rem` }}
      renderInput={(params): JSX.Element => (
        <TextField {...params} placeholder={intl.formatMessage({ id: 'general.filter' })} />
      )}
      renderOption={(p: React.HTMLAttributes<HTMLLIElement>, o): React.ReactNode => {
        if (isArrayOfStrings(acOptions)) return <li {...p}>{o}</li>;
        else if (isArrayOfICategoryOptions(acOptions)) {
          return <li {...p}>{intl.formatMessage({ id: acOptions.find((n) => n.title === o)?.localization ?? '' })}</li>;
        }
        return null;
      }}
    />
  );
};
interface IOwnProps {
  /** All allowed options for auto complete. */
  acOptions: Array<string> | Array<ICategoryOptions>;
  /** A React "set state" hook which updates a filtering string used elsewhere. */
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  /** Width of the input field in rem. */
  width?: number;
  /** Addition callback for value onChange event. */
  onChangeEffect?: () => void;
}

/**
 * Autocomplete component similar to `InputHandler/components/AutoCompleteSelect.tsx`
 * but not for submitting to form. This component only renders an autocompleted drop
 * down input field and updates some search string using a hook it receives in props.
 */
export default ACSearch;
