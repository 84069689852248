import * as React from 'react';
import FormRow from '../../../../../../components/FormRow';
import FormSection from '../../../../../../components/FormSection';
import InputHandler from '../../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../../containers/FormContextHandler';

const optionFormatter = (o: string | undefined, fm: (id: string) => string): string =>
  o ? fm(`diagnosis.huntington.uhdrs.form.opts.${o}`) : '';

const UHDRSForm = ({ documents, formData, editing, view, fm = (): string => '' }: IFormContext): JSX.Element => (
  <FormSection>
    <FormRow title="diagnosis.huntington.uhdrs.form.date">
      <InputHandler
        type="PartialDate"
        name="date"
        editing={!!editing}
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="diagnosis.huntington.uhdrs.form.index">
      <React.Fragment>
        {editing ? (
          <InputHandler
            type="Radio"
            name="index"
            editing={!!editing}
            formData={formData}
            options={['0', '1', '2', '3', '4']}
            optionFormatter={(name: string | number): string | JSX.Element =>
              `${name} - ` + fm(`diagnosis.huntington.uhdrs.form.opts.${name}`)
            }
          />
        ) : null}
        {view?.viewing && documents?.find((d) => d._id === view?.viewing).index
          ? optionFormatter(documents?.find((d) => d._id === view?.viewing).index, fm)
          : null}
      </React.Fragment>
    </FormRow>
  </FormSection>
);

export default withFormContext(UHDRSForm);
