import FormRow from 'Components/FormRow';
import FormSection from 'Components/FormSection';
import InputHandler from 'Components/InputHandler';
import { IFormContext, withFormContext } from 'Containers/FormContextHandler';
import { sortPartialDate } from 'neuro-utils';
import * as React from 'react';
import colors from '../../../../../../config/theme/colors';

const SymptomsAndLocationsForm = ({
  documents,
  editing,
  formData,
  fm,
  consumeTreatmentId,
}: IFormContext & {
  consumeTreatmentId: () => string | null;
}): JSX.Element => {
  const treatmentId = consumeTreatmentId() || formData.document?.treatmentPeriodId;

  React.useEffect(() => {
    if (treatmentId) {
      // If treatment period id is available (consumed), set the treatment id
      if (!formData.document?.treatmentPeriodId) {
        formData.onChange?.({ treatmentPeriodId: treatmentId });
      }
    } else {
      const nearestId =
        Array.isArray(documents) &&
        documents
          .slice()
          .sort((d1, d2) => sortPartialDate(d2.date, d1.date))
          .find((d) => d._type === 'ninmtTreatmentPeriod' && sortPartialDate(formData.document.date, d.date) >= 0)?._id;
      formData.onChange?.({ treatmentPeriodId: nearestId ?? '' });
    }
  }, [treatmentId, formData]);

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="diagnosis.ninmt.symptomsAndLocations.startDate">
          <InputHandler
            type="PartialDate"
            name="startDate"
            formData={formData}
            editing={!!editing}
            dateDefault="now"
            dateHook={{ dateHookCeiling: formData.document.endDate }}
            isNotCancellable
          />
        </FormRow>
        <FormRow title="diagnosis.ninmt.symptomsAndLocations.endDate">
          <InputHandler
            type="PartialDate"
            name="endDate"
            formData={formData}
            editing={!!editing}
            dateHook={{ dateHookFloor: formData.document.startDate }}
          />
        </FormRow>
      </FormSection>
      <FormSection>
        <FormRow title="diagnosis.ninmt.symptomsAndLocations.symptom">
          <InputHandler
            type="Select"
            name="symptom"
            formData={formData}
            editing={!!editing}
            options={['pain', 'tinnitus', 'other']}
            optionFormatter={(name: string | number): string => fm(`diagnosis.ninmt.symptomsAndLocations.opts.${name}`)}
            placeholder={'diagnosis.ninmt.symptomsAndLocations.symptomPlaceholder'}
            dependentFieldsList={(): string[] => ['mentalSymptom', 'symptomDescription', 'symptomLocation']}
          />
        </FormRow>
        <FormRow condition={formData.document.symptom === 'other'}>
          <div style={{ display: 'flex' }}>
            <InputHandler
              name="mentalSymptom"
              formData={formData}
              type="CheckboxSingle"
              editing={!!editing}
              option="true"
              showLabel
              optionFormatter={() => fm('diagnosis.ninmt.symptomsAndLocations.mentalSymptom')}
              dependentFieldsList={() => ['averageSymptomIntensity']}
            />
            <span style={{ color: colors.secondaryText, paddingLeft: '0.5rem' }}>
              {`- ${fm('diagnosis.ninmt.symptomsAndLocations.mentalSymptomInfoText')}`}
            </span>
          </div>
        </FormRow>
        {formData.document.symptom === 'other' ? (
          <FormRow title="diagnosis.ninmt.symptomsAndLocations.symptomDescription">
            <InputHandler
              type="TextArea"
              name="symptomDescription"
              formData={formData}
              editing={!!editing}
              placeholder="diagnosis.ninmt.symptomsAndLocations.symptomDescriptionPlaceholder"
              rows={1}
              maxLength={40}
              width={40}
            />
          </FormRow>
        ) : (
          <FormRow title="diagnosis.ninmt.symptomsAndLocations.symptomLocation">
            <InputHandler
              type="Select"
              name="symptomLocation"
              formData={formData}
              editing={!!editing}
              options={
                formData.document.symptom === 'pain'
                  ? [
                      'upperLimbLeft',
                      'upperLimbRight',
                      'upperLimbBoth',
                      'lowerLimbLeft',
                      'lowerLimbRight',
                      'lowerLimbBoth',
                      'body',
                      'hipOrGenitalia',
                      'headArea',
                      'widespreadPainSymptoms',
                    ]
                  : ['right', 'left', 'middle']
              }
              optionFormatter={(name: string | number): string =>
                fm(`diagnosis.ninmt.symptomsAndLocations.opts.${name}`)
              }
              placeholder={'diagnosis.ninmt.symptomsAndLocations.symptomLocationPlaceholder'}
            />
          </FormRow>
        )}
        <FormRow
          title="diagnosis.ninmt.symptomsAndLocations.averagePainTitle"
          description={fm('diagnosis.ninmt.symptomsAndLocations.averagePainDesc')}
          condition={!formData.document.mentalSymptom}
        >
          <InputHandler
            type="NumberField"
            name="averageSymptomIntensity"
            formData={formData}
            editing={!!editing}
            min={0}
            max={10}
            precision={1}
            enableTrailingZero
            disablePlaceholderFormatting
            placeholder={'0-10'}
            width={6}
          />
        </FormRow>
        <FormRow
          title="diagnosis.ninmt.symptomsAndLocations.averageHarmTitle"
          description={fm('diagnosis.ninmt.symptomsAndLocations.averagePainDesc')}
          condition={!formData.document.mentalSymptom}
        >
          <InputHandler
            type="NumberField"
            name="averageSymptomHarm"
            formData={formData}
            editing={!!editing}
            min={0}
            max={10}
            precision={1}
            enableTrailingZero
            disablePlaceholderFormatting
            placeholder={'0-10'}
            width={6}
          />
        </FormRow>
        <FormRow title="diagnosis.ninmt.symptomsAndLocations.additionalInformation">
          <InputHandler
            type="TextArea"
            name="additionalInformation"
            formData={formData}
            editing={!!editing}
            placeholder={'diagnosis.ninmt.symptomsAndLocations.additionalInformationPlaceholder'}
          />
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};

export default withFormContext(SymptomsAndLocationsForm);
