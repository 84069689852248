import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import HistorySection from '../../../../components/HistorySection';
import { IHistoryContext, withHistoryContext } from '../../../../containers/FormContextHandler';
import { TreatmentHistorySection } from './components';

const OtherTreatment = ({ documents = [], startEdit, view }: IHistoryContext<IOtherTreatment>): JSX.Element => (
  <HistorySection
    headerText={<FormattedMessage id="treatment.otherTreatment.title" />}
    newButton={
      startEdit ? (
        <DocumentCreationButton
          name="otherTreatment"
          text="treatment.otherTreatment.newOtherTreatment"
          onClick={startEdit({}, 'otherTreatment')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      ) : (
        <></>
      )
    }
  >
    <TreatmentHistorySection documents={documents} startEdit={startEdit} type="otherTreatment" view={view} />
  </HistorySection>
);

export default withHistoryContext(OtherTreatment);
