import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../../components/FormRow';
import { Container, Item } from '../../../../../components/Grid';
import InputHandler from '../../../../../components/InputHandler';
import Unit from '../../../../../components/Unit';

const fieldWidth = 20;

const SpirometryForm = ({ formData, viewing }: IOwnProps): JSX.Element => {
  return (
    <React.Fragment>
      <FormRow title="clpAndCnpTests.date">
        <InputHandler
          type="PartialDate"
          editing={!viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="clpAndCnpTests.vc" description={<FormattedMessage id="clpAndCnpTests.vcDescription" />}>
        <Container>
          <Item xs={true}>
            <Unit unit={<FormattedMessage id="clpAndCnpTests.liters" />}>
              <InputHandler
                type="NumberField"
                editing={!viewing}
                name="vcLiters"
                formData={formData}
                precision={2}
                min={-1000}
                max={1000}
                placeholder="clpAndCnpTests.vc"
                width={fieldWidth}
              />
            </Unit>
          </Item>
          <Item xs={true}>
            <Unit unit={<FormattedMessage id="clpAndCnpTests.zValue" />}>
              <InputHandler
                type="NumberField"
                editing={!viewing}
                name="vcZValue"
                formData={formData}
                precision={2}
                min={-10000}
                max={10000}
                placeholder="clpAndCnpTests.vc"
                width={fieldWidth}
              />
            </Unit>
          </Item>
        </Container>
      </FormRow>
      <FormRow title="clpAndCnpTests.fvc" description={<FormattedMessage id="clpAndCnpTests.fvcDescription" />}>
        <Container>
          <Item xs={12} md={6}>
            <Unit unit={<FormattedMessage id="clpAndCnpTests.liters" />}>
              <InputHandler
                type="NumberField"
                editing={!viewing}
                name="fvcLiters"
                formData={{
                  document: formData.document,
                  onChange: (values: any): void => {
                    if (formData.onChange) {
                      formData.onChange(values);
                    }
                    // Calculate value for fevPercents
                    if (formData.onChange) {
                      const percents: number | undefined =
                        (formData.document.fev1Liters || formData.document.fev1Liters === 0) &&
                        (values.fvcLiters || values.fvcLiters === 0)
                          ? parseFloat(((formData.document.fev1Liters / values.fvcLiters) * 100).toFixed(2))
                          : undefined;
                      formData.onChange({ fevPercents: percents });
                    }
                  },
                }}
                precision={2}
                min={-1000}
                max={1000}
                placeholder="clpAndCnpTests.fvc"
                width={fieldWidth}
              />
            </Unit>
          </Item>
          <Item xs={12} md={6}>
            <Unit unit={<FormattedMessage id="clpAndCnpTests.zValue" />}>
              <InputHandler
                type="NumberField"
                editing={!viewing}
                name="fvcZValue"
                formData={formData}
                precision={2}
                min={-10000}
                max={10000}
                placeholder="clpAndCnpTests.fvc"
                width={fieldWidth}
              />
            </Unit>
          </Item>
        </Container>
      </FormRow>
      <FormRow title="clpAndCnpTests.fev1" description={<FormattedMessage id="clpAndCnpTests.fev1Description" />}>
        <Container>
          <Item xs={12} md={6}>
            <Unit unit={<FormattedMessage id="clpAndCnpTests.liters" />}>
              <InputHandler
                type="NumberField"
                editing={!viewing}
                name="fev1Liters"
                formData={{
                  document: formData.document,
                  onChange: (values: any): void => {
                    if (formData.onChange) {
                      formData.onChange(values);
                    }
                    // Calculate value for fevPercents
                    if (formData.onChange) {
                      const percents: number | undefined =
                        (formData.document.fvcLiters || formData.document.fvcLiters === 0) &&
                        (values.fev1Liters || values.fev1Liters === 0)
                          ? parseFloat(((values.fev1Liters / formData.document.fvcLiters) * 100).toFixed(2))
                          : undefined;
                      formData.onChange({ fevPercents: percents });
                    }
                  },
                }}
                precision={2}
                min={-1000}
                max={1000}
                placeholder="clpAndCnpTests.fev1"
                width={fieldWidth}
              />
            </Unit>
          </Item>
          <Item xs={12} md={6}>
            <Unit unit={<FormattedMessage id="clpAndCnpTests.zValue" />}>
              <InputHandler
                type="NumberField"
                editing={!viewing}
                name="fev1ZValue"
                formData={formData}
                precision={2}
                min={-10000}
                max={10000}
                placeholder="clpAndCnpTests.fev1"
                width={fieldWidth}
              />
            </Unit>
          </Item>
        </Container>
      </FormRow>
      <FormRow
        title="clpAndCnpTests.fevPercents"
        description={<FormattedMessage id="clpAndCnpTests.fevPercentsDescription" />}
      >
        <Container>
          <Item xs={12} md={6}>
            <Unit unit="%">
              <InputHandler
                type="NumberField"
                editing={!viewing}
                name="fevPercents"
                formData={formData}
                precision={2}
                min={-10000}
                max={10000}
                placeholder="clpAndCnpTests.fevPercents"
                width={fieldWidth}
              />
            </Unit>
          </Item>
          <Item xs={12} md={6}>
            <Unit unit={<FormattedMessage id="clpAndCnpTests.zValue" />}>
              <InputHandler
                type="NumberField"
                editing={!viewing}
                name="fevPercentsZValue"
                formData={formData}
                precision={2}
                min={-10000}
                max={10000}
                placeholder="clpAndCnpTests.fevPercents"
                width={fieldWidth}
              />
            </Unit>
          </Item>
        </Container>
      </FormRow>
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<ISpirometry>;
  viewing: boolean;
}

export default SpirometryForm;
