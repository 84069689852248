import * as React from 'react';
import { styled } from '@mui/system';
import { Container, Item } from 'Components/Grid';
import { fm } from 'Components/FormatMessage';
import colors from '../../../../../config/theme/colors';
import Unit from 'Components/Unit';
import { defaultTDCSTreatmentUnits } from '../../config';
import { subjectOfTreatmentNames } from 'Routes/DoctorsOrders/Document/config';
import { getSessionNumber, getTdcsRowData, tdcsFields, valueDiffersFromDoctorsOrders } from 'Utility/ninmtUtil';
import { equals, path } from 'ramda';
import ToolTip from 'Components/ToolTip';
import { capitalize } from 'Utility/string';

const TitleContainer = styled(Container)({
  color: colors.darkGray,
  fontWeight: 'bold',
});

const NameContainer = styled(Container)({
  color: colors.tertiaryText,
  padding: '0 0 5px 0',
});

const ValueItem = styled(Item)((props: { index: number }) => ({
  padding: '0.4rem 0 0.4rem 0',
  borderTop: props.index === 0 ? `1px solid ${colors.gray}` : undefined,
  backgroundColor: props.index % 2 === 0 ? colors.lightestGray : undefined,
}));

const TitleItem = styled(ValueItem)({
  color: colors.tertiaryText,
});

const nameSelector = (t: ITDCSTreatment): JSX.Element => {
  const name = t.name
    ? subjectOfTreatmentNames.includes(t.name)
      ? fm(`tdcs.subjectOfTreatmentNames.${t.name}`)
      : t.name
    : '-';
  const specifier = t.specifier ? ` ${t.specifier}` : '';
  return (
    <span>
      {name}
      {specifier}
    </span>
  );
};

const valueFormatter = (name: string, value?: any, currentSession?: ITDCSSession, currentIndex?: number) => {
  const additionalStyle =
    name !== 'additionalInformation' && valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value)
      ? { color: colors.white, backgroundColor: colors.appBlue.default, padding: '0 2px 0 2px', borderRadius: '0.5rem' }
      : undefined;
  return (
    <Unit unit={name === 'current' || name === 'currentDuration' ? defaultTDCSTreatmentUnits[name] : undefined}>
      <ToolTip
        title={fm('tdcs.subjectOfTreatment.valueInDoctorsOrders')}
        cursor={valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value) ? 'Pointer' : 'Default'}
        description={
          <>
            {valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value, undefined, true)
              ? valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value, undefined, true)
              : '-'}
          </>
        }
        flipTitleAndDescStyle
        hover={true}
        disabled={!valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value)}
        content={
          <span
            style={{
              fontWeight: 600,
              ...additionalStyle,
            }}
          >
            {['type', 'cathode', 'anode'].includes(name) && value
              ? fm(`tdcs.opts.subjectOfTreatment.${value}`)
              : value && `${value}`.length > 0
                ? (value as string | number)
                : '-'}
          </span>
        }
      ></ToolTip>
    </Unit>
  );
};

const deleteReasonFormatter = (t: ITDCSTreatment): string | JSX.Element =>
  t.deleteReason ? (
    <React.Fragment>
      <div
        style={{
          fontWeight: 600,
          color: t.deleteReason === 'partOfTreatmentProtocol' ? colors.tertiaryText : colors.error.default,
        }}
      >
        {fm(
          `tdcs.subjectOfTreatment.${
            t.deleteReason === 'partOfTreatmentProtocol' ? 'accordingToProtocol' : 'contraryToProtocol'
          }`,
        )}
      </div>
      <div style={{ color: colors.tertiaryText, textTransform: 'lowercase' }}>
        {fm('tdcs.subjectOfTreatment.noTreatmentGiven')}
      </div>
    </React.Fragment>
  ) : (
    ''
  );

const TreatmentsBySession = ({ event, eventsBeyondThisDoc }: ITreatmentsBySession): JSX.Element => {
  if (!event || !Array.isArray(event.subjectOfTreatment) || event.subjectOfTreatment?.length === 0) return <></>;

  const patientsRating = event.patientsRating;
  const patientsRatingPeriods = ['beforeTreatment', 'afterTreatment'];
  const patientsRatingNames = ['intensity', 'harm'];

  const treatments = event.subjectOfTreatment;
  const currentIndex = eventsBeyondThisDoc.findIndex((e) => equals(e, event));
  return (
    <React.Fragment>
      {Array.isArray(patientsRating) && patientsRating.length > 0 && (
        <div style={{ margin: '4rem 0 4.5rem 0' }}>
          <TitleContainer style={{ marginBottom: '1.5rem' }}>
            <Item xs={true}>{fm('tdcs.patientsRating.title')}</Item>
          </TitleContainer>
          {patientsRating?.map(
            (p, index) =>
              Array.isArray(p?.ratingByLocation) &&
              p.ratingByLocation.map((r, ind) => (
                <React.Fragment key={`${r.location}${ind}`}>
                  <NameContainer>
                    <Item xs={3} style={{ minHeight: 30 }}>
                      {p.symptom === 'other' ? (
                        <div style={{ paddingRight: '1.5rem' }}>
                          {fm('tdcs.patientsRating.opts.other')}
                          {r.description && r.description.length > 0 && (
                            <ToolTip
                              cursor={r.description.length > 30 ? 'Pointer' : 'Default'}
                              description={r.description}
                              hover={true}
                              disabled={r.description.length <= 30}
                              content={
                                <span>
                                  {r.description.length > 30
                                    ? `: ${r.description.slice(0, 30)}...`
                                    : `: ${r.description}`}
                                </span>
                              }
                            />
                          )}
                        </div>
                      ) : r.location ? (
                        fm(`tdcs.patientsRating.opts.${r.location}`)
                      ) : (
                        ''
                      )}
                    </Item>
                    {index === 0 &&
                      ind === 0 &&
                      patientsRatingPeriods.map((period, i) => (
                        <Item key={`${period}${i}`} xs={2}>
                          {fm(`tdcs.patientsRating.${period}`)}
                        </Item>
                      ))}
                  </NameContainer>
                  {patientsRatingNames.map((name, j) => (
                    <Container key={j} style={{ marginBottom: j > 0 ? '1.5rem' : undefined }}>
                      <TitleItem xs={3} index={j}>
                        <span style={{ paddingLeft: '1rem' }}>
                          {fm(
                            `tdcs.patientsRating.${p.symptom === 'other' ? 'symptom' : p.symptom}${capitalize(name)}`,
                          )}
                        </span>
                      </TitleItem>
                      {patientsRatingPeriods?.map((period, k) => {
                        const v = path([name, period], r);
                        return (
                          <ValueItem key={`${period}${k}`} xs={2} index={j} style={{ fontWeight: 600 }}>
                            {typeof v === 'number' && !isNaN(v) ? v.toFixed(1) : '-'}
                          </ValueItem>
                        );
                      })}
                    </Container>
                  ))}
                </React.Fragment>
              )),
          )}
        </div>
      )}
      <div style={{ margin: '4rem 0 4.5rem 0' }}>
        <TitleContainer>
          <Item xs={true}>{fm('tdcs.subjectOfTreatment.title')}</Item>
        </TitleContainer>
        <NameContainer>
          <Item xs={3} style={{ minHeight: 30 }} />
          {treatments?.map((t, i) => (
            <Item key={i} xs={2}>
              {nameSelector(t)}
            </Item>
          ))}
        </NameContainer>
        {tdcsFields.map((f, i) => (
          <Container key={i}>
            <TitleItem xs={3} index={i}>
              <span style={{ paddingLeft: '1rem' }}>
                {fm(`tdcs.subjectOfTreatment.${f === 'additionalInformation' ? `${f}Subject` : f}`)}
              </span>
            </TitleItem>
            {treatments?.map((t, j) => {
              const { name, value, fieldMatchesType } = getTdcsRowData(f as keyof ITDCSTreatmentEvent, t);
              return (
                <ValueItem key={j} xs={2} index={i}>
                  {t.deleted
                    ? f === 'additionalInformation'
                      ? deleteReasonFormatter(t)
                      : ''
                    : f === 'session'
                      ? getSessionNumber(eventsBeyondThisDoc, currentIndex - 1, t) || ''
                      : fieldMatchesType
                        ? valueFormatter(name, value, event, j)
                        : ''}
                </ValueItem>
              );
            })}
          </Container>
        ))}
      </div>
    </React.Fragment>
  );
};

interface ITreatmentsBySession {
  event: ITDCSSession;
  eventsBeyondThisDoc: Array<ITDCSSession>;
}

export default TreatmentsBySession;
