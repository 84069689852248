export const steps = [
  'speechUpdrs',
  'facialExpressionUpdrs',
  {
    restTremor: [
      'restTremorFace',
      'restTremorRightHand',
      'restTremorLeftHand',
      'restTremorRightLeg',
      'restTremorLeftLeg',
    ],
  },
  { actionPosturalTremor: ['actionPosturalTremorRightHand', 'actionPosturalTremorLeftHand'] },
  {
    rigidityUpdrs: [
      'rigidityNeckUpdrs',
      'rigidityRightHand',
      'rigidityLeftHand',
      'rigidityRightLeg',
      'rigidityLeftLeg',
    ],
  },
  { fingerTaps: ['fingerTapsRight', 'fingerTapsLeft'] },
  { handMovementsUpdrs: ['handMovementsRightUpdrs', 'handMovementsLeftUpdrs'] },
  { pronationSupinationUpdrs: ['pronationSupinationRightUpdrs', 'pronationSupinationLeftUpdrs'] },
  { legAgilityUpdrs: ['legAgilityRightUpdrs', 'legAgilityLeftUpdrs'] },
  'arisingFromChairUpdrs',
  'postureUpdrs',
  'walking',
  'balance',
  'bradykinesiaHypokinesia',
] as Array<string | { [key: string]: string[] }>;

export const getStepContent = (
  stepName: string,
): { opts: number[]; optsLocale: string; info?: string; height?: number } => {
  switch (stepName) {
    case 'speechUpdrs':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'speechUpdrs' };
    case 'facialExpressionUpdrs':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'facialExpressionUpdrs' };
    case 'restTremorFace':
    case 'restTremorRightHand':
    case 'restTremorLeftHand':
    case 'restTremorRightLeg':
    case 'restTremorLeftLeg':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'restTremor', height: 20 };
    case 'actionPosturalTremorRightHand':
    case 'actionPosturalTremorLeftHand':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'actionPosturalTremor' };
    case 'rigidityNeckUpdrs':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'rigidityUpdrs', info: 'rigidityInfo' };
    case 'rigidityRightHand':
    case 'rigidityLeftHand':
    case 'rigidityRightLeg':
    case 'rigidityLeftLeg':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'rigidityUpdrs' };
    case 'fingerTapsRight':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'fingerTaps', info: 'fingerTapsInfo' };
    case 'fingerTapsLeft':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'fingerTaps' };
    case 'handMovementsRightUpdrs':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'handMovementsUpdrs', info: 'handMovementsInfo', height: 20 };
    case 'handMovementsLeftUpdrs':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'handMovementsUpdrs', height: 20 };
    case 'pronationSupinationRightUpdrs':
      return {
        opts: [0, 1, 2, 3, 4],
        optsLocale: 'pronationSupinationUpdrs',
        info: 'pronationSupinationInfo',
        height: 20,
      };
    case 'pronationSupinationLeftUpdrs':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'pronationSupinationUpdrs', height: 20 };
    case 'legAgilityRightUpdrs':
    case 'legAgilityLeftUpdrs':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'legAgilityUpdrs', height: 20 };
    case 'arisingFromChairUpdrs':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'arisingFromChairUpdrs', info: 'arisingFromChairInfo' };
    case 'postureUpdrs':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'postureUpdrs' };
    case 'walking':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'walking', height: 17 };
    case 'balance':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'balance', info: 'balanceInfo' };
    case 'bradykinesiaHypokinesia':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'bradykinesiaHypokinesia', info: 'bradykinesiaHypokinesiaInfo' };
    default:
      return { opts: [0], optsLocale: '' };
  }
};
