import * as React from 'react';

import { TDocument } from '../../utils';
import NPIForm from './NPI';
import FBIMODForm from './FBIMOD';
import BNSQForm from './BNSQ';
import ADLForm from './ADL';
import IADLForm from './IADL';

const DiagnosticSurveyForm = ({ document }: IOwnProps): JSX.Element | null => {
  if (!document) return null;
  return document._type === 'npi' ? (
    <NPIForm />
  ) : document._type === 'fbimod' ? (
    <FBIMODForm />
  ) : document._type === 'bnsq' ? (
    <BNSQForm />
  ) : document._type === 'adl' ? (
    <ADLForm />
  ) : document._type === 'iadl' ? (
    <IADLForm />
  ) : null;
};

interface IOwnProps {
  document?: TDocument;
}

export default DiagnosticSurveyForm;
