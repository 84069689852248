import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import OptionChooser from '../../../../../components/OptionChooser';
import colors from '../../../../../config/theme/colors';
import { optFormatter, opts } from '../modifiedChildrensGlobalAssessmentScaleSettings';

const ModifiedChildrensGlobalAssessmentScaleForm = ({ formData, viewing }: IOwnProps): JSX.Element => (
  <React.Fragment>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={!viewing}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <div style={{ fontStyle: 'italic', color: colors.darkGray, marginBottom: '2rem' }}>
      <FormattedMessage id="functionalPerformance.modifiedChildrensGlobalAssessmentScaleInfo1" />
    </div>
    <div style={{ fontStyle: 'italic', color: colors.darkGray, marginBottom: '2rem' }}>
      <FormattedMessage id="functionalPerformance.modifiedChildrensGlobalAssessmentScaleInfo2" />
    </div>
    <FormRow title="functionalPerformance.rating">
      <OptionChooser formData={formData} name="rating" opts={opts} optionFormatter={optFormatter} viewing={viewing} />
    </FormRow>
    <span style={{ fontStyle: 'italic', color: colors.darkGray }}>
      <FormattedMessage id="functionalPerformance.modifiedChildrensGlobalAssessmentScaleInfo3" />
    </span>
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<IModifiedChildrensGlobalAssessmentScale>;
  viewing?: boolean;
}

export default ModifiedChildrensGlobalAssessmentScaleForm;
