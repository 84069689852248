import * as React from 'react';
import EventStepper from '../../../../../components/EventStepper';
import { fm } from 'Components/FormatMessage';
import InputHandler from '../../../../../components/InputHandler';
import { formatPartialDate } from 'neuro-utils';
import { StepperHeaderFormInputPair, StepperHeaderValuePair } from 'Components/EventStepper/components';

const AdverseEffects = ({ formData }: IOwnProps): JSX.Element => {
  return (
    <EventStepper
      name="adverseEffects"
      formData={formData}
      stepLabelText={(d: ITDCSAdverseEffect): string => formatPartialDate(d.date)}
      stepContent={(d: ITDCSAdverseEffect): JSX.Element => (
        <React.Fragment>
          {['adverseEffects', 'additionalInformation'].map((key, index) => {
            const value = d[key as keyof ITDCSAdverseEffect];
            return (
              <StepperHeaderValuePair
                key={`${key}${index}`}
                header={fm(`tdcs.${key}`)}
                value={
                  value && (Array.isArray(value) ? value.length > 0 : true)
                    ? key === 'adverseEffects' && Array.isArray(value)
                      ? value.map((v) => <div key={v}>{fm(`tdcs.opts.adverseEffects.${v}`)}</div>)
                      : value
                    : '-'
                }
              />
            );
          })}
        </React.Fragment>
      )}
      addNewTextHeader="tdcs.adverseEffects"
      addNewTextButton="tdcs.newAdverseEffect"
      previousEventsTextHeader="tdcs.previousAdverseEffects"
      editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => (
        <React.Fragment>
          <StepperHeaderFormInputPair
            header={fm('general.date')}
            input={
              <InputHandler
                type="PartialDate"
                editing={true}
                name="date"
                formData={{
                  onChange,
                  document: { date: formData.document.adverseEffects?.[index]?.date || '' },
                }}
                dateDefault="now"
                isNotCancellable={true}
                dateHook={{
                  dateHookFloor: formData.document.date,
                }}
              />
            }
          />
          <StepperHeaderFormInputPair
            header={fm('tdcs.adverseEffects')}
            input={
              <InputHandler
                type="Checkbox"
                editing={true}
                name="adverseEffects"
                formData={{
                  onChange,
                  document: { adverseEffects: formData.document.adverseEffects?.[index]?.adverseEffects || '' },
                }}
                options={[
                  'relapse',
                  'headache',
                  'fatigue',
                  'nausea',
                  'skinIrritation',
                  'mentalConfusion',
                  'memoryProblem',
                  'sensoryDistortion',
                  'epilepticSeizure',
                  'other',
                ]}
                optionFormatter={(name: string | number): JSX.Element => fm(`tdcs.opts.adverseEffects.${name}`)}
              />
            }
          />
          <StepperHeaderFormInputPair
            header={fm('tdcs.additionalInformation')}
            input={
              <InputHandler
                type="TextArea"
                editing={true}
                name="additionalInformation"
                formData={{
                  onChange,
                  document: {
                    additionalInformation: formData.document.adverseEffects?.[index]?.additionalInformation || '',
                  },
                }}
                placeholder="tdcs.additionalInformationAdverseEffects"
              />
            }
          />
        </React.Fragment>
      )}
    />
  );
};

interface IOwnProps {
  formData: IFormData<ITDCS>;
}

export default AdverseEffects;
