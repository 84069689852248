import * as React from 'react';

const styles = {
  st0: { fill: '#010101' },
  st1: { fill: '#FFFFFF' },
  st2: { fill: '#818181' },
  st3: { fill: '#C9C9C9' },
};

const svgImage = (): JSX.Element => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 400 400"
    style={{
      //enableBackground:"new 0 0 400 400;"
      fillRule: 'evenodd' as const,
      clipRule: 'evenodd' as const,
    }}
    xmlSpace="preserve"
  >
    <g>
      <g transform="matrix(0.749529,0,0,0.749529,-194.551,-967.824)">
        <rect x="589.26" y="1422.32" style={styles.st0} width="55.27" height="38.06" />
      </g>
      <g transform="matrix(0.749529,0,0,0.749529,-500.406,-37.1958)">
        <circle style={styles.st0} cx="758.85" cy="372.12" r="13.06" />
      </g>
      <g transform="matrix(0.749529,0,0,0.749529,-358.487,-36.0639)">
        <circle style={styles.st0} cx="707.76" cy="371.71" r="13.06" />
      </g>
      <g transform="matrix(0.749529,0,0,0.749529,-209.269,-32.4118)">
        <circle style={styles.st0} cx="506.57" cy="508.65" r="13.06" />
      </g>
      <g transform="matrix(0.749529,0,0,0.749529,-491.99,-223.649)">
        <circle style={styles.st0} cx="755.83" cy="439.24" r="13.06" />
      </g>
      <g transform="matrix(0.749529,0,0,0.749529,-522.272,-117.162)">
        <circle style={styles.st0} cx="918.11" cy="299.64" r="13.06" />
      </g>
      <g transform="matrix(0.749529,0,0,0.749529,-429.285,-87.7248)">
        <circle style={styles.st0} cx="889.38" cy="255.42" r="13.06" />
      </g>
      <g transform="matrix(0.544549,0.0119973,-0.0177078,0.803745,-331.486,-352.971)">
        <polygon style={styles.st0} points="766.76,516.57 762.17,734.74 755.15,734.79 759.74,516.61 		" />
      </g>
      <g transform="matrix(0.721653,0.202513,-0.202513,0.721653,-380.08,-432.428)">
        <ellipse
          transform="matrix(-0.2702 0.9628 -0.9628 -0.2702 1431.7386 -153.9032)"
          style={styles.st0}
          cx="774.2"
          cy="465.67"
          rx="29.34"
          ry="29.34"
        />
      </g>
      <g transform="matrix(0.721653,0.202513,-0.202513,0.721653,-380.08,-432.428)">
        <ellipse
          transform="matrix(-0.2702 0.9628 -0.9628 -0.2702 1431.7386 -153.9032)"
          style={styles.st1}
          cx="774.2"
          cy="465.67"
          rx="25.73"
          ry="25.73"
        />
      </g>
      <g transform="matrix(0.295174,0.0828331,-0.0828331,0.295174,-76.3425,-138.696)">
        <circle style={styles.st0} cx="712.65" cy="458.58" r="13.06" />
      </g>
      <g transform="matrix(1.20423,0,0,0.177156,-498.105,186.669)">
        <rect x="557.45" y="910.77" style={styles.st0} width="19.14" height="19.09" />
      </g>
      <g transform="matrix(1.58211,0,0,1.04896,-735.972,-434.898)">
        <rect x="572.16" y="645.82" style={styles.st0} width="2.68" height="102.48" />
      </g>
      <g transform="matrix(0.946541,0.321113,-0.346623,1.02173,-266.09,-690.53)">
        <polygon style={styles.st0} points="732.69,550.33 668.48,572.7 667.16,568.88 731.37,546.51 		" />
      </g>
      <g transform="matrix(0.946541,0.321113,-0.346623,1.02173,-266.09,-690.53)">
        <polygon style={styles.st0} points="663.74,572.89 581.79,598.04 580.71,594.6 662.66,569.44 		" />
      </g>
      <g transform="matrix(1.58149,0.0441665,-0.0305024,1.09221,-864.464,-496.974)">
        <polygon style={styles.st0} points="668.83,649.18 668.96,652.94 602.1,653.59 601.97,649.83 		" />
      </g>
      <g transform="matrix(0.749529,0,0,0.749529,-194.551,-967.824)">
        <rect x="579.82" y="1572.04" style={styles.st2} width="39.33" height="192.2" />
      </g>
      <g transform="matrix(0.749529,0,0,0.749529,-194.551,-967.824)">
        <rect x="490.65" y="1554.33" style={styles.st2} width="217.66" height="22.27" />
      </g>
      <g transform="matrix(0.749529,0,0,0.749529,-194.551,-967.824)">
        <rect x="591.51" y="1424.68" style={styles.st3} width="50.91" height="33.78" />
      </g>
      <g transform="matrix(0.749529,0,0,0.749529,-194.551,-967.824)">
        <rect x="338.63" y="1626.79" style={styles.st2} width="98.1" height="136.14" />
      </g>
    </g>
  </svg>
);

export default svgImage;
