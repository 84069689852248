import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import HistoryRowControls from '../../../../../components/HistoryRowControls';
import HistoryRowVerticalItem from '../../../../../components/HistoryRowVerticalItem';
import HistoryRow from '../../../../../components/HistoryRow';

import colors from '../../../../../config/theme/colors';
import { exists, formatPartialDate } from 'neuro-utils';

import { existsAndIsNotNull } from '../../../utils';
import { rowData, TPulmonaryRowDataKey } from '../../Form/Pulmonary/rowData';
import { ConditionalCollapse } from '.';

export const History = ({ d, startEdit, rows, index }: IHistory): JSX.Element => {
  return (
    <React.Fragment>
      <HistoryRow
        headerText={formatPartialDate(d.date)}
        rowButton={startEdit && <HistoryRowControls document={d} startEdit={startEdit} />}
        controlsMargin={false}
      >
        <ConditionalCollapse condition={index > 0 && rows.length > 1} rows={rows}>
          <React.Fragment>
            {rows.map((row) => {
              const thisRow = d?.[row];

              const rowValue = existsAndIsNotNull(thisRow)
                ? thisRow && typeof thisRow === 'object'
                  ? thisRow?.value
                  : thisRow
                : undefined;
              const rowDate = typeof thisRow === 'object' ? thisRow?.date : undefined;
              return (
                <React.Fragment key={row}>
                  <HistoryRowVerticalItem
                    header={<FormattedMessage id={`pulmonary.${row}`} />}
                    value={
                      <>
                        {exists(rowValue) ? (
                          rowData[row] && rowData[row]?.['optionFormat'] ? (
                            <>
                              <FormattedMessage id={`pulmonary.opts.${rowData[row]?.['optionFormat']}.${rowValue}`} />
                            </>
                          ) : (
                            rowValue
                          )
                        ) : (
                          '-'
                        )}
                        <div style={{ fontSize: '1.2rem', color: colors.darkGray }}>
                          {rowDate ? formatPartialDate(rowDate) : ''}
                        </div>
                      </>
                    }
                  />
                </React.Fragment>
              );
            })}
          </React.Fragment>
        </ConditionalCollapse>
      </HistoryRow>
    </React.Fragment>
  );
};

export type TPulmonaryWithPreviousDates =
  | IPulmonary
  | ({ [Property in keyof IPulmonary]: { value: any; date: any } } & { date: PartialDate } & IControlProps);

interface IHistory {
  d: TPulmonaryWithPreviousDates;
  startEdit?: (document: TAnyObject) => (e: React.MouseEvent) => void;
  rows: Array<TPulmonaryRowDataKey>;
  index: number;
}
