import { Stepper, Step } from '@mui/material';
import { isEmpty, path } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'Store/index';

import FormRow from '../../../../components/FormRow';
import FormSection from '../../../../components/FormSection';
import InputHandler from '../../../../components/InputHandler';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import EventStepper from '../../../../components/EventStepper';
import ActionButton from '../../../../components/ActionButton';
import FormSectionBar from '../../../../components/FormSectionBar';

import LeadClicker, { ILeadClickerType } from './LeadClicker';
import SettingEvents from './SettingEvents';
import GeneratorSetting from './GeneratorSetting';
import GeneratorStepper from './GeneratorEvents';

import DiscontinuationEvents from './DiscontinuationEvents';
import PlatformConditional from '../../../../components/PlatformConditional';

import {
  generatorsExist,
  generatorsUnavailable,
  getAllLeads,
  getCompatibleLeads,
  getLatestDiscontinuation,
  hasLfpRegistration,
} from '../../utils';
import {
  StyledText,
  settingsDescription,
  StyledRow,
  StyledDiv,
  StyledClearIcon,
  StyledTabArea,
  StyledTab,
} from '../../components/index';
import {
  Connector,
  IconChooser,
  StepperHeaderValuePair,
  StyledLabelText,
  StyledStepContent,
  StyledStepLabel,
} from '../../../../components/EventStepper/components';
import { exists, isPartialDate, formatPartialDate, minPartialDate, nowPartialDate } from 'neuro-utils';
import { actions } from '../../../../store/form';
import { defaultUnits } from '../config';
import { Container, Item } from '../../../../components/Grid';
import { isNil } from 'Utility/ramdaReplacement';

const optionFormatter = (name: string | number): JSX.Element => <FormattedMessage id={'dbs.opts.' + name} />;

const presetFormatter = (name: string | number): JSX.Element => (
  <FormattedMessage id={`dbs.opts.yesNoUnknown.${name}`} />
);

const SettingsDisplay = ({ id, formData }: { id: number; formData: IOwnProps['formData'] }): JSX.Element => {
  const [selectedProgram, setSelectedProgram] = React.useState<number>(0);
  const setting = formData.document.settings?.[id];

  const leadFormData = (): IFormData<ILeadClickerType> => ({
    onChange: formData.onChange,
    document: {
      ...formData.document,
      rightGeneratorSetting: setting?.programs?.[selectedProgram]?.['rightGeneratorSetting'],
      leftGeneratorSetting: setting?.programs?.[selectedProgram]?.['leftGeneratorSetting'],
      rightSettings: setting?.programs?.[selectedProgram]?.['rightSettings'],
      leftSettings: setting?.programs?.[selectedProgram]?.['leftSettings'],
    },
  });

  return (
    <React.Fragment>
      {id === 0 && (
        <StyledStepLabel>
          <StyledLabelText style={{ marginLeft: '2rem' }}>
            {setting?.generatorAdjustedDate && formatPartialDate(setting?.generatorAdjustedDate)}
          </StyledLabelText>
        </StyledStepLabel>
      )}
      <div style={{ padding: id === 0 ? '2rem' : undefined }}>
        <StyledRow
          firstElement={
            <span style={{ fontWeight: 600 }}>
              {formData.document.generators
                ?.map((generator: IDBSGenerator) => {
                  if (generator.id === setting?.generator) {
                    return `${generator?.generator} - ${formatPartialDate(generator?.generatorDate)}`;
                  }
                  return undefined;
                })
                .filter((g) => g)
                .join('')}
            </span>
          }
          secondElement={undefined}
          thirdElement={undefined}
        />
        <StyledTabArea style={{ margin: '2rem 0 4.5rem 0' }}>
          <Container alignItems="stretch">
            {setting &&
              Array.isArray(setting?.programs) &&
              setting.programs.map(
                (program: any, index: number): JSX.Element => (
                  <React.Fragment key={program.programName + index}>
                    <StyledTab
                      xs={true}
                      style={{
                        backgroundColor: selectedProgram === index ? 'rgba(4, 90, 139, 0.28)' : undefined,
                      }}
                      onClick={(): void => setSelectedProgram(index)}
                    >
                      {program.programName}
                    </StyledTab>
                  </React.Fragment>
                ),
              )}
          </Container>
        </StyledTabArea>
        <StyledRow
          firstElement={undefined}
          secondElement={
            <StyledText>
              <FormattedMessage id="general.right" />
            </StyledText>
          }
          thirdElement={
            <StyledText>
              <FormattedMessage id="general.left" />
            </StyledText>
          }
          childCSS={{ textAlign: 'center' }}
        />
        <StyledRow
          firstElement={<FormattedMessage id="dbs.generator" />}
          secondElement={
            <Container justifyContent="center">
              <Item>
                <GeneratorSetting editable={false} name="rightGeneratorSetting" formData={leadFormData()} />
              </Item>
            </Container>
          }
          thirdElement={
            <Container justifyContent="center">
              <Item>
                <GeneratorSetting editable={false} name="leftGeneratorSetting" formData={leadFormData()} />
              </Item>
            </Container>
          }
          parentCSS={{ marginTop: '3rem' }}
          childCSS={{ textAlign: 'center' }}
        />
        <StyledRow
          firstElement={
            <Container>
              <Item xs={12}>
                {settingsDescription(
                  hasLfpRegistration(setting ?? ({} as IDBSSetting)) &&
                    selectedProgram === (setting?.programs ?? []).length - 1,
                )}
              </Item>
            </Container>
          }
          secondElement={<LeadClicker editable={'false'} name={'rightSettings'} formData={leadFormData()} />}
          thirdElement={<LeadClicker editable={'false'} name={'leftSettings'} formData={leadFormData()} />}
          parentCSS={{ marginTop: '1rem' }}
          childCSS={{ minWidth: '300px', minHeight: '370px', maxWidth: '100%', maxHeight: '100%' }}
        />
        {!(
          hasLfpRegistration(setting ?? ({} as IDBSSetting)) && selectedProgram === (setting?.programs ?? []).length - 1
        ) && (
          <React.Fragment>
            <StyledRow
              firstElement={<FormattedMessage id="dbs.amplitude" />}
              secondElement={
                exists(setting?.programs?.[selectedProgram]?.rightAmplitude)
                  ? `${setting?.programs?.[selectedProgram]?.rightAmplitude} ${
                      setting?.programs?.[selectedProgram]?.amplitudeUnit === 'V'
                        ? 'V'
                        : setting?.programs?.[selectedProgram]?.amplitudeUnit === 'mA'
                          ? 'mA'
                          : ''
                    }`
                  : '-'
              }
              thirdElement={
                exists(setting?.programs?.[selectedProgram]?.leftAmplitude)
                  ? `${setting?.programs?.[selectedProgram]?.leftAmplitude} ${
                      setting?.programs?.[selectedProgram]?.amplitudeUnit === 'V'
                        ? 'V'
                        : setting?.programs?.[selectedProgram]?.amplitudeUnit === 'mA'
                          ? 'mA'
                          : ''
                    }`
                  : '-'
              }
              parentCSS={{ marginTop: '2rem' }}
              childCSS={{ textAlign: 'center' }}
            />
            <StyledRow
              firstElement={<FormattedMessage id="dbs.pulseWidth" />}
              secondElement={
                exists(setting?.programs?.[selectedProgram]?.rightPulseWidth)
                  ? `${setting?.programs?.[selectedProgram]?.rightPulseWidth} ${defaultUnits.pulseWidth}`
                  : '-'
              }
              thirdElement={
                exists(setting?.programs?.[selectedProgram]?.leftPulseWidth)
                  ? `${setting?.programs?.[selectedProgram]?.leftPulseWidth} ${defaultUnits.pulseWidth}`
                  : '-'
              }
              parentCSS={{ marginTop: '1rem' }}
              childCSS={{ textAlign: 'center' }}
            />
            <StyledRow
              firstElement={<FormattedMessage id="dbs.rate" />}
              secondElement={
                exists(setting?.programs?.[selectedProgram]?.rightRate)
                  ? `${setting?.programs?.[selectedProgram]?.rightRate} ${defaultUnits.rate}`
                  : '-'
              }
              thirdElement={
                exists(setting?.programs?.[selectedProgram]?.leftRate)
                  ? `${setting?.programs?.[selectedProgram]?.leftRate} ${defaultUnits.rate}`
                  : '-'
              }
              parentCSS={{ marginTop: '1rem' }}
              childCSS={{ textAlign: 'center' }}
            />
          </React.Fragment>
        )}
        <PlatformConditional platform="parkinson">
          <StyledRow
            firstElement={<FormattedMessage id="dbs.interlacing" />}
            secondElement={
              !isNil(setting?.programs?.[selectedProgram]?.interlacing) ? (
                <FormattedMessage id={`general.${setting?.programs?.[selectedProgram]?.interlacing}`} />
              ) : (
                '-'
              )
            }
            thirdElement={undefined}
            parentCSS={{ marginTop: '3rem' }}
            childCSS={{ textAlign: 'center' }}
          />
        </PlatformConditional>
        <PlatformConditional platform="epilepsy">
          <React.Fragment>
            {hasLfpRegistration(setting ?? ({} as IDBSSetting)) &&
            selectedProgram === (setting?.programs ?? []).length - 1 ? (
              <React.Fragment>
                <FormSection header="dbs.automaticRegistration" style={{ border: 'none' }}>
                  <StyledRow
                    firstElement={<FormattedMessage id="dbs.rate" />}
                    secondElement={
                      exists(setting?.programs?.[selectedProgram]?.automaticRegistrationRate)
                        ? `${setting?.programs?.[selectedProgram]?.automaticRegistrationRate} ${defaultUnits.rate}`
                        : '-'
                    }
                    thirdElement={undefined}
                    parentCSS={{ marginTop: '3rem' }}
                    childCSS={{ textAlign: 'center' }}
                  />
                </FormSection>
                <FormSection header="dbs.eventRegistration" style={{ border: 'none' }}>
                  <StyledRow
                    firstElement={<FormattedMessage id="dbs.eventRegistrationEvents" />}
                    secondElement={
                      setting?.programs?.[selectedProgram]?.eventRegistration ? (
                        <FormattedMessage
                          id={`dbs.opts.inUseNotInUse.${setting?.programs?.[selectedProgram]?.eventRegistration}`}
                        />
                      ) : (
                        '-'
                      )
                    }
                    thirdElement={undefined}
                    parentCSS={{ marginTop: '3rem' }}
                    childCSS={{ textAlign: 'center' }}
                  />
                  {setting?.programs?.[selectedProgram]?.eventRegistration === 'inUse' && (
                    <StyledRow
                      firstElement={<FormattedMessage id="dbs.eventRegistrationEvent" />}
                      secondElement={
                        setting?.programs?.[selectedProgram]?.events ? (
                          <React.Fragment>
                            {(setting?.programs?.[selectedProgram]?.events ?? []).map((event, index) => (
                              <div key={index}>{event}</div>
                            ))}
                          </React.Fragment>
                        ) : (
                          '-'
                        )
                      }
                      thirdElement={undefined}
                      parentCSS={{ marginTop: '3rem' }}
                      childCSS={{ textAlign: 'center' }}
                    />
                  )}
                </FormSection>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <StyledRow
                  firstElement={<FormattedMessage id="dbs.mode" />}
                  secondElement={
                    setting?.programs?.[selectedProgram]?.mode ? (
                      <FormattedMessage id={`dbs.opts.${setting?.programs?.[selectedProgram]?.mode}`} />
                    ) : (
                      '-'
                    )
                  }
                  thirdElement={undefined}
                  parentCSS={{ marginTop: '3rem' }}
                  childCSS={{ textAlign: 'center' }}
                />
                {setting?.programs?.[selectedProgram]?.mode === 'cyclicStimulation' && (
                  <React.Fragment>
                    <StyledRow
                      firstElement={<FormattedMessage id="dbs.cyclicONTime" />}
                      secondElement={
                        exists(setting?.programs?.[selectedProgram]?.cyclicONTime)
                          ? `${setting?.programs?.[selectedProgram]?.cyclicONTime} ${defaultUnits.cyclicONTime}`
                          : '-'
                      }
                      thirdElement={undefined}
                      parentCSS={{ marginTop: '3rem' }}
                      childCSS={{ textAlign: 'center' }}
                    />
                    <StyledRow
                      firstElement={<FormattedMessage id="dbs.cyclicOFFTime" />}
                      secondElement={
                        exists(setting?.programs?.[selectedProgram]?.cyclicOFFTime)
                          ? `${setting?.programs?.[selectedProgram]?.cyclicOFFTime} ${defaultUnits.cyclicOFFTime}`
                          : '-'
                      }
                      thirdElement={undefined}
                      parentCSS={{ marginTop: '1rem' }}
                      childCSS={{ textAlign: 'center' }}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        </PlatformConditional>
      </div>
    </React.Fragment>
  );
};

const DBSForm = ({ editing, formData, anchor }: IOwnProps): JSX.Element | null => {
  const [activeStepSet, setActiveStepSet] = React.useState<number>(-1);

  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [target, setTarget] = React.useState<{ name: string; value: string } | undefined>(undefined);

  const isMountRender = React.useRef(true);

  const state = useSelector((s: { session: ISessionStore }) => s);
  const platform = state.session.platforms?.selected;

  const toggleDialog = (): void => {
    setDialogOpen(!dialogOpen);
  };

  const activateStepSet = (id: number) => (): void => {
    if (id !== activeStepSet) setActiveStepSet(id);
    else setActiveStepSet(-1);
  };

  const clearLeadSelection = (lead: string) => (): void => {
    if (formData.onChange) {
      formData.onChange({ [lead]: undefined, [`${lead}SerialNo`]: undefined });
    }
  };

  const resetLeadSettings = (): void => {
    if (formData.onChange && target) {
      formData.onChange({ settings: [], [target?.name]: target?.value });
    }
    setTarget(undefined);
    toggleDialog();
  };

  const leadOnChange = (values: TOnChangeValues): void => {
    const name = Object.keys(values)?.[0];
    const value = `${values[name]}`;

    setTarget({ name, value });
    if (formData.document.settings) {
      if (Array.isArray(formData.document.settings) && formData.document.settings.length > 0) {
        toggleDialog();
      } else if (formData.onChange) {
        formData.onChange({ [name]: value }); // in formEditingHandler
      }
    } else if (formData.onChange) {
      formData.onChange({ [name]: value }); // in formEditingHandler
    }
  };

  // Ref for scrolling to specific part of the form
  const ref = React.createRef() as React.RefObject<HTMLDivElement>;

  React.useEffect(() => {
    // When form is opened, scroll to ref if anchor is set
    if (isMountRender.current === true && anchor && ref.current && !isEmpty(formData.document)) {
      isMountRender.current = false;
      scroll({ top: ref.current.getBoundingClientRect().top - 75, behavior: 'smooth' });
    }
  }, [anchor, formData.document, ref]);

  const dateRef = React.useRef(formData.document.date);

  React.useEffect(() => {
    // Updates date of first generator when changing implantation date.
    if (dateRef.current !== formData.document.date) {
      const generators: IDBSGenerator[] = [];

      const formDataGenerators = formData.document.generators ? [...formData.document.generators] : [];

      formDataGenerators.forEach((g) => {
        const thisGenerator = { ...g };
        if (thisGenerator.firstGenerator) {
          thisGenerator.generatorDate = formData.document.date;
        }
        generators.push(thisGenerator);
      });
      formData.onChange && formData.onChange({ generators: generators });
      dateRef.current = formData.document.date;
    }
  }, [formData]);

  const implantationSiteOptions: IDBS['implantationSite'] = ['HYKS', 'TYKS', 'KYS', 'TAYS', 'OYS'];
  const noGenerators: boolean = !formData.document.generators || isEmpty(formData.document.generators);

  const [continuationDate, setContinuationDate] = React.useState<PartialDate>(nowPartialDate());

  const handleContinuationDateChange = (e: TOnChangeValues): void => {
    if (isPartialDate(e['treatmentContinuationDate'])) {
      setContinuationDate(e['treatmentContinuationDate']);
    }
  };

  const dispatch = useDispatch();

  const handleContinuation = (): void => {
    const newDiscontinuations = JSON.parse(JSON.stringify(formData.document.discontinuationEvents));
    const activeIndex = newDiscontinuations.indexOf(getLatestDiscontinuation(newDiscontinuations));

    if (newDiscontinuations[activeIndex]['discontinuationType'] === 'generatorSwitchedOff' && editing) {
      newDiscontinuations[activeIndex] = { ...newDiscontinuations[activeIndex], endDate: continuationDate };
      dispatch(
        actions.updateFormValuesBatch({ discontinuationEvents: newDiscontinuations, status: 'inProgress' }, editing),
      );
    }
  };

  const rightLead = formData.document?.leadRight;
  const leftLead = formData.document?.leadLeft;

  return platform ? (
    <React.Fragment>
      {formData.document.status === 'suspended' &&
        getLatestDiscontinuation(formData.document.discontinuationEvents) &&
        (getLatestDiscontinuation(formData.document.discontinuationEvents)?.discontinuationType ===
        'generatorSwitchedOff' ? (
          <FormSection>
            <FormRow
              title="dbs.continueTreatment"
              description={<FormattedMessage id="dbs.continueTreatmentDescription" />}
            >
              <InputHandler
                type="PartialDate"
                name="treatmentContinuationDate"
                editing={true}
                formData={{
                  onChange: handleContinuationDateChange,
                  document: { treatmentContinuationDate: continuationDate },
                }}
                dateHook={{
                  dateHookFloor: getLatestDiscontinuation(
                    formData.document.discontinuationEvents ? formData.document.discontinuationEvents : [],
                  )?.date,
                }}
                isNotCancellable={true}
              />
            </FormRow>
            <FormRow>
              <ActionButton
                text="dbs.continueTreatment"
                onClick={handleContinuation}
                width={20}
                height={5}
                fontSize={18}
              />
            </FormRow>
          </FormSection>
        ) : [undefined, 'generatorRemoved', 'generatorAndLeadsRemoved'].includes(
            getLatestDiscontinuation(formData.document.discontinuationEvents)?.discontinuationType ?? '',
          ) ? (
          <FormSection>
            <FormRow title="dbs.continueTreatment">
              <div style={{ whiteSpace: 'pre-line' }}>
                {!getLatestDiscontinuation(formData.document.discontinuationEvents)?.discontinuationType ? (
                  <FormattedMessage id="dbs.continueTreatmentUndefined" />
                ) : (
                  <FormattedMessage id="dbs.continueTreatmentAfterGeneratorRemoval" />
                )}
              </div>
            </FormRow>
          </FormSection>
        ) : null)}
      <FormSectionBar header="dbs.generator" />
      <FormSection>
        <FormRow title="dbs.started">
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="date"
            formData={formData}
            dateDefault="now"
            // Implantation date is capped to the date of the starting point of the second generator.
            dateHook={{
              dateHookCeiling: minPartialDate([
                formData.document.hasEnded?.[0] === true ? formData.document.endDate : undefined,
                ...(Array.isArray(formData.document.generators) && formData.document.generators.length > 1
                  ? formData.document.generators.filter((g) => !g.firstGenerator).map((g) => g.generatorDate)
                  : []),
              ]),
            }}
            isNotCancellable={true}
          />
        </FormRow>
        <FormRow title="dbs.implantationSite">
          <InputHandler
            type="Select"
            editing={!!editing}
            name="implantationSite"
            options={implantationSiteOptions}
            formData={formData}
            placeholder="dbs.chooseImplantationSite"
          />
        </FormRow>
        <React.Fragment>
          {['Right', 'Left'].map((side) => (
            <div key={`lead${side}`}>
              <FormRow title={`dbs.${side.toLowerCase()}Lead`} bottomMargin>
                <StyledDiv style={{ minWidth: '51rem' }}>
                  <InputHandler
                    type="Select"
                    editing={noGenerators}
                    name={`lead${side}`}
                    options={
                      side === 'Right' && leftLead
                        ? getCompatibleLeads(leftLead, platform)
                        : side === 'Left' && rightLead
                          ? getCompatibleLeads(rightLead, platform)
                          : getAllLeads(platform)
                    }
                    placeholder="dbs.chooseLead"
                    width={51}
                    formData={{
                      onChange: leadOnChange,
                      document: { [`lead${side}`]: path(['document', `lead${side}`], formData) || '' },
                    }}
                    optionFormatter={(name: string | number): string | JSX.Element =>
                      name === 'Medtronic SenSight (0.5 mm Spaced Lead Assembly)' ? (
                        <FormattedMessage id="dbs.medtronicSenSightLead0.5mm" />
                      ) : name === 'Medtronic SenSight (1.5 mm Spaced Lead Assembly)' ? (
                        <FormattedMessage id="dbs.medtronicSenSightLead1.5mm" />
                      ) : (
                        name.toString()
                      )
                    }
                  />
                </StyledDiv>
                <StyledDiv
                  style={{ verticalAlign: 'bottom', paddingLeft: '1rem', position: 'relative', top: '0.2rem' }}
                >
                  {noGenerators && path(['document', `lead${side}`], formData) ? (
                    <StyledClearIcon onClick={clearLeadSelection(`lead${side}`)} />
                  ) : (
                    <></>
                  )}
                </StyledDiv>
                <div style={{ marginTop: '1rem' }}>
                  <InputHandler
                    type="TextField"
                    name={`lead${side}SerialNo`}
                    editing={!!editing}
                    formData={{
                      onChange: formData.onChange,
                      document: {
                        [`lead${side}SerialNo`]: path(['document', `lead${side}SerialNo`], formData) || '',
                      },
                    }}
                    placeholder={'dbs.enterSerialNo'}
                  />
                </div>
              </FormRow>
            </div>
          ))}
        </React.Fragment>

        <ConfirmationDialog
          open={dialogOpen}
          text={<FormattedMessage id="dbs.leadChangeWarning" />}
          cancel={{ callback: toggleDialog }}
          confirm={{ callback: resetLeadSettings }}
        />

        <FormRow title="dbs.targetNucleus">
          <InputHandler
            type="Select"
            editing={noGenerators}
            name="targetNucleus"
            formData={formData}
            options={(platform === 'parkinson'
              ? ['STN', 'GPi', 'VIM', 'Zona incerta']
              : platform === 'epilepsy'
                ? ['ANT', 'CMN']
                : []
            ).filter((o) => o !== formData.document.targetNucleusLeft)}
            placeholder="dbs.chooseNucleus"
          />
        </FormRow>

        <PlatformConditional platform="parkinson">
          <React.Fragment>
            <FormRow title="dbs.leftLeadHasDifferentTargetNucleus">
              <InputHandler
                type="CheckboxSingle"
                editing={noGenerators}
                name="leftLeadHasDifferentTargetNucleus"
                formData={formData}
                option={'true'}
                optionFormatter={() => <FormattedMessage id="general.yes" />}
                dependentFieldsList={(): string[] => ['targetNucleusLeft']}
                dependentFieldsRemovalWarning={true}
              />
            </FormRow>
            {formData.document.leftLeadHasDifferentTargetNucleus && (
              <FormRow title="dbs.targetNucleusLeft">
                <InputHandler
                  type="Select"
                  editing={noGenerators}
                  name="targetNucleusLeft"
                  formData={formData}
                  options={(platform === 'parkinson'
                    ? ['STN', 'GPi', 'VIM', 'Zona incerta']
                    : // This field is currently Parkinson-only, but keep these options here in case it needs to be enabled for Epilepsy later
                      platform === 'epilepsy'
                      ? ['ANT', 'CMN']
                      : []
                  ).filter((o) => o !== formData.document.targetNucleus)}
                  placeholder="dbs.chooseNucleus"
                />
              </FormRow>
            )}
          </React.Fragment>
        </PlatformConditional>
      </FormSection>
      <FormSection>
        <GeneratorStepper formData={formData} platform={platform} />
      </FormSection>

      <FormSectionBar header="dbs.adjustGenerator" />
      <FormSection headerRef={anchor === 'settings' ? ref : undefined}>
        <FormRow title="dbs.newSetting">
          <div style={{ marginBottom: '2rem' }}>
            <SettingEvents formData={formData} platform={platform} />
          </div>
        </FormRow>
      </FormSection>

      {formData.document?.settings && formData.document.settings.length > 0 ? ( // First and current setting
        <FormSection header="dbs.currentSetting">
          <SettingsDisplay id={0} formData={formData} />
          <div style={{ marginTop: '2rem', marginRight: '2rem' }}>
            <SettingEvents formData={formData} editIndex={0} platform={platform} />
          </div>
        </FormSection>
      ) : undefined}
      {formData.document?.settings && formData.document.settings.length > 1 ? ( // Previous regimen
        <React.Fragment>
          <FormSection header="dbs.previousSettings">
            <Stepper
              activeStep={activeStepSet}
              orientation="vertical"
              style={{ margin: '2rem 0 0 0', padding: '0' }}
              connector={<Connector />}
            >
              {formData.document.settings.map((d: IDBSSetting, i: number) => {
                if (i > 0) {
                  return (
                    <Step key={'regimen' + i}>
                      <StyledStepLabel StepIconComponent={IconChooser} onClick={activateStepSet(i - 1)}>
                        <StyledLabelText>{formatPartialDate(d.generatorAdjustedDate)}</StyledLabelText>
                      </StyledStepLabel>
                      <StyledStepContent>
                        <SettingsDisplay id={i} formData={formData} />
                        <div style={{ marginBottom: '1rem' }}>
                          <SettingEvents formData={formData} editIndex={i} platform={platform} />
                        </div>
                      </StyledStepContent>
                    </Step>
                  );
                } else return undefined;
              })}
            </Stepper>
          </FormSection>
        </React.Fragment>
      ) : undefined}
      <FormSectionBar header="dbs.allEvents" />
      <PlatformConditional platform="epilepsy">
        <FormSection
          header="dbs.postoperativeComplications"
          headerRef={anchor === 'postoperativeComplications' ? ref : undefined}
        >
          <EventStepper
            name="postoperativeComplications"
            formData={formData}
            stepLabelText={(d: IDBSPostoperativeComplicationEvent): string => formatPartialDate(d.date)}
            stepContent={(d: IDBSPostoperativeComplicationEvent): JSX.Element => (
              <React.Fragment>
                <StepperHeaderValuePair
                  header={<FormattedMessage id="dbs.complications" />}
                  value={
                    !d.complications || d.complications.length < 1 ? null : (
                      <React.Fragment>
                        {d.complications.map((item: string, i: number) => (
                          <div key={i + 'dbsPostoperativeComplications'} style={{ fontWeight: 600 }}>
                            <FormattedMessage id={`dbs.opts.${item}`} />
                          </div>
                        ))}
                      </React.Fragment>
                    )
                  }
                />
                <StepperHeaderValuePair
                  header={<FormattedMessage id="dbs.postoperativeComplicationDetails" />}
                  value={d.additionalInformation}
                />
              </React.Fragment>
            )}
            addNewTextHeader="dbs.newPostoperativeComplication"
            addNewTextButton="dbs.newComplication"
            buttonDisabled={generatorsUnavailable(formData.document, platform) || !generatorsExist(formData.document)}
            buttonDisabledMessage={<FormattedMessage id="dbs.addGeneratorFirst" />}
            previousEventsTextHeader="dbs.postoperativeComplications"
            editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => (
              <React.Fragment>
                <div style={{ marginBottom: '2rem' }}>
                  <InputHandler
                    type="PartialDate"
                    name="date"
                    editing={true}
                    formData={{
                      onChange,
                      document: { date: formData.document.postoperativeComplications?.[index]?.date || '' },
                    }}
                    dateDefault="now"
                    dateHook={{ dateHookFloor: formData.document.date, dateHookCeiling: formData.document.endDate }}
                    isNotCancellable={true}
                  />
                </div>
                <div style={{ marginBottom: '2rem' }}>
                  <InputHandler
                    type="Checkbox"
                    name="complications"
                    editing={true}
                    formData={{
                      onChange,
                      document: {
                        complications: formData.document.postoperativeComplications?.[index]?.complications || '',
                      },
                    }}
                    options={['fibrosis', 'infection', 'other']}
                    optionFormatter={optionFormatter}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <InputHandler // Use InputHandler here so that we can use placeholder with TextField
                    type="TextArea"
                    name="additionalInformation"
                    editing={true}
                    formData={{
                      onChange,
                      document: {
                        additionalInformation:
                          formData.document.postoperativeComplications?.[index]?.additionalInformation || '',
                      },
                    }}
                    placeholder={'dbs.postoperativeComplicationDetails'}
                  />
                </div>
              </React.Fragment>
            )}
          />
        </FormSection>
      </PlatformConditional>
      <FormSection header="dbs.events" headerRef={anchor === 'dbsEvents' ? ref : undefined}>
        <EventStepper
          name="dbsEvents"
          formData={formData}
          stepLabelText={(d: IDBSEvent): string => formatPartialDate(d.date)}
          stepContent={(d: IDBSEvent): JSX.Element => (
            <React.Fragment>
              <StepperHeaderValuePair
                header={<FormattedMessage id="dbs.effects" />}
                value={
                  !d.eventTypes || d.eventTypes.length < 1 ? null : (
                    <React.Fragment>
                      {d.eventTypes.map((item: string, i: number) => (
                        <div key={i + 'dbsevents'} style={{ fontWeight: 600 }}>
                          <FormattedMessage id={`dbs.opts.${item}`} />
                          {item === 'other' && d.otherDetails && `: ${d.otherDetails}`}
                        </div>
                      ))}
                    </React.Fragment>
                  )
                }
              />
              <StepperHeaderValuePair header={<FormattedMessage id="dbs.eventDetailsShort" />} value={d.eventDetails} />
            </React.Fragment>
          )}
          addNewTextHeader="dbs.newEvent"
          addNewTextButton="dbs.newEvent"
          buttonDisabled={generatorsUnavailable(formData.document, platform) || !generatorsExist(formData.document)}
          buttonDisabledMessage={<FormattedMessage id="dbs.addGeneratorFirst" />}
          previousEventsTextHeader="dbs.events"
          editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => (
            <React.Fragment>
              <div style={{ marginBottom: '2rem' }}>
                <InputHandler
                  type="PartialDate"
                  name="date"
                  editing={true}
                  formData={{ onChange, document: { date: formData.document.dbsEvents?.[index]?.date || '' } }}
                  dateDefault="now"
                  dateHook={{ dateHookFloor: formData.document.date, dateHookCeiling: formData.document.endDate }}
                  isNotCancellable={true}
                />
              </div>
              <PlatformConditional platform="parkinson">
                <div style={{ marginBottom: '2rem' }}>
                  <InputHandler
                    type="Checkbox"
                    name="eventTypes"
                    editing={true}
                    formData={{
                      onChange,
                      document: { eventTypes: formData.document.dbsEvents?.[index]?.eventTypes || '' },
                    }}
                    options={[
                      'dysarthria',
                      'stability',
                      'paresthesy',
                      'cramp',
                      'duobleImage',
                      'infection',
                      'sweating',
                      'cerebralHemorrhage',
                      'hallucination',
                      'suboptimalTreatmentResponse',
                      'other',
                    ]}
                    optionFormatter={optionFormatter}
                    placeholder={'dbs.eventTypeSelect'}
                  />
                  {formData.document.dbsEvents?.[index]?.eventTypes?.includes('other') ? (
                    <div style={{ marginTop: '2rem' }}>
                      <InputHandler
                        type="TextField"
                        name="otherDetails"
                        editing={true}
                        formData={{
                          onChange,
                          document: {
                            otherDetails: formData.document.dbsEvents?.[index]?.otherDetails || '',
                          },
                        }}
                        placeholder={'dbs.whichOtherEvent'}
                      />
                    </div>
                  ) : null}
                </div>
              </PlatformConditional>
              <PlatformConditional platform="epilepsy">
                <div style={{ marginBottom: '2rem' }}>
                  <InputHandler
                    type="Checkbox"
                    name="eventTypes"
                    editing={true}
                    formData={{
                      onChange,
                      document: { eventTypes: formData.document.dbsEvents?.[index]?.eventTypes || '' },
                    }}
                    options={[
                      'paresthesy',
                      'painAtImplantationSite',
                      'depression',
                      'memoryProblems',
                      'headache',
                      'other',
                    ]}
                    optionFormatter={optionFormatter}
                    placeholder={'dbs.eventTypeSelect'}
                  />
                </div>
              </PlatformConditional>

              <div style={{ marginBottom: '2rem' }}>
                <InputHandler // Use InputHandler here so that we can use placeholder with TextField
                  type="TextArea"
                  name="eventDetails"
                  editing={true}
                  formData={{
                    onChange,
                    document: {
                      eventDetails: formData.document.dbsEvents?.[index]?.eventDetails || '',
                    },
                  }}
                  placeholder={'dbs.eventDetails'}
                />
              </div>
            </React.Fragment>
          )}
        />
      </FormSection>
      <FormSection header="dbs.technicalIssue" headerRef={anchor === 'technicalIssues' ? ref : undefined}>
        <EventStepper
          name="technicalIssues"
          formData={formData}
          stepLabelText={(d: IDBSTechnicalIssue): string => formatPartialDate(d.date)}
          stepContent={(d: IDBSTechnicalIssue): JSX.Element => (
            <React.Fragment>
              <StepperHeaderValuePair
                header={<FormattedMessage id="dbs.technicalIssueType" />}
                value={d.issueType && <FormattedMessage id={'dbs.opts.' + d.issueType} />}
              />

              <StepperHeaderValuePair header={<FormattedMessage id="dbs.issueDetailsShort" />} value={d.issueDetails} />
            </React.Fragment>
          )}
          addNewTextHeader="dbs.newTechnicalIssue"
          addNewTextButton="dbs.newTechnicalIssue"
          buttonDisabled={generatorsUnavailable(formData.document, platform) || !generatorsExist(formData.document)}
          buttonDisabledMessage={<FormattedMessage id="dbs.addGeneratorFirst" />}
          previousEventsTextHeader="dbs.technicalIssue"
          editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => (
            <React.Fragment>
              <div style={{ marginBottom: '2rem' }}>
                <InputHandler
                  type="PartialDate"
                  name="date"
                  editing={true}
                  formData={{ onChange, document: { date: formData.document.technicalIssues?.[index]?.date || '' } }}
                  dateDefault="now"
                  dateHook={{ dateHookFloor: formData.document.date, dateHookCeiling: formData.document.endDate }}
                  isNotCancellable={true}
                />
              </div>
              <div style={{ marginBottom: '2rem' }}>
                <InputHandler
                  type="Radio"
                  name="issueType"
                  editing={true}
                  formData={{
                    onChange,
                    document: { issueType: formData.document.technicalIssues?.[index]?.issueType || '' },
                  }}
                  options={[
                    'batteryBroken',
                    'generatorBroken',
                    'extensionBroken',
                    'leadBroken',
                    'jointBetweenLeadAndExtensionBroken',
                    'jointBetweenExtensionAndGeneratorBroken',
                    'other',
                  ]}
                  optionFormatter={optionFormatter}
                  placeholder={'dbs.issueTypeSelect'}
                />
                <div style={{ marginTop: '2rem' }}>
                  <InputHandler
                    type="TextArea"
                    name="issueDetails"
                    editing={true}
                    formData={{
                      onChange,
                      document: {
                        issueDetails: formData.document.technicalIssues?.[index]?.issueDetails || '',
                      },
                    }}
                    placeholder={'dbs.issueDetails'}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        />
      </FormSection>
      <FormSection header="dbs.dbsDiscontinuation" headerRef={anchor === 'discontinuations' ? ref : undefined}>
        <DiscontinuationEvents
          formData={formData}
          disabled={!(Array.isArray(formData.document?.generators) && formData.document.generators?.length > 0)}
        />
      </FormSection>
      <FormSection header="dbs.dbsEnding" headerRef={anchor === 'dbsEnding' ? ref : undefined}>
        <FormRow title="dbs.end">
          <InputHandler
            type="Checkbox"
            editing={!!editing}
            name="hasEnded"
            formData={{
              onChange: (values: TOnChangeValues): void => {
                if (path(['hasEnded', 0], formData.document) === true) {
                  formData.onChange && formData.onChange({ ...values, hasEnded: [] });
                } else {
                  formData.onChange && formData.onChange({ ...values, hasEnded: [true] });
                }
              },
              document: formData.document,
            }}
            options={['true']}
            optionFormatter={(): JSX.Element => <FormattedMessage id="dbs.end" />}
            dependentFieldsList={(value?: TFieldValue): string[] => {
              // Return empty array (dont check dependent fields), if checkbox is checked
              if (Array.isArray(value) && value[0] === true) return [];

              return ['endDate', 'endReason', 'removed', 'removalDate', 'removalReason', 'removalType'];
            }}
            dependentFieldsRemovalWarning={true}
          />
        </FormRow>
        <FormRow title="dbs.endedDate" condition={path(['hasEnded', 0], formData.document) === true}>
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="endDate"
            formData={formData}
            dateHook={{ dateHookFloor: formData.document.date }}
          />
        </FormRow>
        <FormRow title="dbs.endedReason" condition={path(['hasEnded', 0], formData.document) === true}>
          <PlatformConditional platform="parkinson">
            <InputHandler
              type="Radio"
              editing={!!editing}
              name="endReason"
              formData={formData}
              options={['doesntHelp', 'parkinsonPlus', 'other']}
              optionFormatter={optionFormatter}
            />
          </PlatformConditional>
          <PlatformConditional platform="epilepsy">
            <InputHandler
              type="Radio"
              editing={!!editing}
              name="endReason"
              formData={formData}
              options={['noClinicallyEffectiveResponseToTreatment', 'other']}
              optionFormatter={optionFormatter}
            />
          </PlatformConditional>
        </FormRow>
        <FormRow title="dbs.endedDetails" condition={path(['hasEnded', 0], formData.document) === true}>
          <InputHandler type="TextArea" editing={!!editing} name="endDetails" formData={formData} />
        </FormRow>
        <FormRow title="dbs.removed" condition={path(['hasEnded', 0], formData.document) === true}>
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="removed"
            formData={{
              onChange: (values: TOnChangeValues): void => {
                formData.onChange && formData.onChange(values);
                if (values[Object.keys(values)?.[0]] !== 'yes') {
                  formData.onChange &&
                    formData.onChange({
                      removalDate: null,
                      removalReason: null,
                      removalAdditionalInformation: null,
                      removalType: null,
                    });
                }
              },
              document: formData.document,
            }}
            options={['yes', 'no', 'unknown']}
            optionFormatter={presetFormatter}
          />
        </FormRow>
        <FormRow
          title="dbs.removalDate"
          condition={
            path(['hasEnded', 0], formData.document) === true && path(['removed'], formData.document) === 'yes'
          }
        >
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="removalDate"
            formData={formData}
            dateHook={{ dateHookFloor: formData.document.date }}
          />
        </FormRow>
        <FormRow
          title="dbs.removalReason"
          condition={
            path(['hasEnded', 0], formData.document) === true && path(['removed'], formData.document) === 'yes'
          }
        >
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="removalReason"
            formData={formData}
            options={['infection', 'other']}
            optionFormatter={optionFormatter}
            placeholder="dbs.removalReason"
          />
        </FormRow>
        <FormRow
          title="dbs.removalAdditionalInformation"
          condition={
            path(['hasEnded', 0], formData.document) === true && path(['removed'], formData.document) === 'yes'
          }
        >
          <InputHandler type="TextArea" editing={!!editing} name="removalAdditionalInformation" formData={formData} />
        </FormRow>
        <FormRow
          title="dbs.removalType"
          condition={
            path(['hasEnded', 0], formData.document) === true && path(['removed'], formData.document) === 'yes'
          }
        >
          <InputHandler
            type="Checkbox"
            editing={!!editing}
            name="removalType"
            formData={formData}
            options={['generator', 'extension', 'rightLead', 'leftLead']}
            optionFormatter={optionFormatter}
            placeholder="dbs.removalType"
          />
        </FormRow>
      </FormSection>
    </React.Fragment>
  ) : null;
};

interface IOwnProps {
  editing?: string;
  formData: IFormData<IDBS>;
  anchor?: string;
}

export default DBSForm;
