import DocumentCreationButton from 'Components/DocumentCreationButton';
import { Item } from 'Components/Grid';
import HistoryRowControls from 'Components/HistoryRowControls';
import HistoryRowListing from 'Components/HistoryRowListing';
import HistorySection from 'Components/HistorySection';
import { IHistoryContext, withHistoryContext } from 'Containers/FormContextHandler';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

const GAFHistory = ({ documents = [], startEdit, view }: IHistoryContext<IGAF>): JSX.Element => (
  <HistorySection
    headerText={<FormattedMessage id="interviewSurveys.gaf.title" />}
    newButton={
      <DocumentCreationButton
        name="cmap"
        text={'interviewSurveys.gaf.newGaf'}
        onClick={startEdit && startEdit({} as IGAF, 'gaf')}
        width={15}
        height={3}
        fontSize={14}
        alternate={true}
      />
    }
  >
    <HistoryRowListing
      documents={documents?.filter((d) => d._type === 'gaf')}
      headers={
        <>
          <Item xs={2}>
            <FormattedMessage id="general.date" />
          </Item>
          <Item xs={2}>
            <FormattedMessage id="interviewSurveys.gaf.gafValue" />
          </Item>
        </>
      }
      contentFormat={(d: IGAF): JSX.Element => (
        <>
          <Item xs={2}>{formatPartialDate(d.date)}</Item>
          <Item xs={2}>{d.gafClass === '0' ? '0' : d.gafValue || d.gafValue === 0 ? d.gafValue : '-'}</Item>
          <Item xs={5}>{d.gafClass && <FormattedMessage id={`interviewSurveys.opts.gaf.${d.gafClass}`} />}</Item>
          <Item xs={3}>{startEdit && <HistoryRowControls document={d} startEdit={startEdit} view={view} />}</Item>
        </>
      )}
    />
  </HistorySection>
);

export default withHistoryContext(GAFHistory);
