import * as React from 'react';
import FormSection from 'Components/FormSection';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import { diagnosticExamCodes } from 'Routes/DiagnosticTests/utils';
import { useIntl } from 'react-intl';

const DiagnosticTestsForm = ({ formData, editing, diagnosticExamKey }: IOwnProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  React.useEffect(() => {
    if (editing && !formData.document?.code) {
      formData.onChange?.({ code: diagnosticExamCodes[diagnosticExamKey] });
    }
  }, []);

  const handleInterpretationChange = (values: TOnChangeValues) => {
    const value = Object.values(values)?.[0];
    formData.onChange?.({
      finding: { ...formData?.document?.finding, supportDiagnosis: value === 'support' ? true : false },
    });
  };

  return (
    <FormSection>
      <FormRow title="general.date">
        <InputHandler editing={!!editing} name="date" type="PartialDate" formData={formData} dateDefault="now" />
      </FormRow>
      <FormRow title="diagnosticTests.interpretation">
        <InputHandler
          name="interpretation"
          type="Radio"
          editing={!!editing}
          options={['support', 'noSupport']}
          optionFormatter={(o) => fm(`diagnosticTests.opts.${o}`)}
          formData={{
            document: {
              interpretation: formData?.document?.finding?.supportDiagnosis
                ? 'support'
                : formData?.document?.finding?.supportDiagnosis === false
                  ? 'noSupport'
                  : undefined,
            },
            onChange: handleInterpretationChange,
          }}
        />
      </FormRow>
    </FormSection>
  );
};

interface IOwnProps {
  formData: IFormData<IDiagnosticExamination>;
  editing: string;
  diagnosticExamKey: TDiagnosticExamKeys;
}

export default DiagnosticTestsForm;
