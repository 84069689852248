/**
 * Functions for "Criteria for the clinical diagnosis of probable and possible MSA (Wenning et al. 2022)"
 */

/** 
  Ohjelma päättelee tämän edellä olevien vastausten perusteella:
  - "Kyllä" vastaus edellyttää, että vähintään kaksi seuraavista on “Kyllä”: 
  1. Autonomic dysfunction defined as (edellyttää. että vähintään yksi seuraavista on "Kyllä": “Unexplained voiding difficulties with post-void urinary residual volume, “Unexplained urinary urge incontinence”, “Neurogenic OH (≥ 20/10 mmHg blood pressure drop) within 10 minutes of standing or head-up tilt test”), 
  2. Poorly levodopa-responsive parkinsonism, 
  3. A cerebellar syndrome (edellyttää, että vähintään yksi seuraavista on “Kyllä”: “gait ataxia”, “limb ataxia”, “cerebellar dysarthria”, “oculomotor features”).
  - "Ei" vastaus edellyttää, että vähintään kaksi seuraavista on “Ei”: 
  1. Autonomic dysfunction defined as (edellyttää. että vähintään kaikki seuraavista on "Ei": “Unexplained voiding difficulties with post-void urinary residual volume, “Unexplained urinary urge incontinence”, “Neurogenic OH (≥ 20/10 mmHg blood pressure drop) within 10 minutes of standing or head-up tilt test”), 
  2. Poorly levodopa-responsive parkinsonism, 
  3. A cerebellar syndrome (edellyttää, että vähintään kaikki seuraavista on “Ei”: “gait ataxia”, “limb ataxia”, “cerebellar dysarthria”, “oculomotor features”).
  - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"

  * @param {IMSA2022} document - MSA 2022 document to be checked
  * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
*/
export const checkCoreClinicalFeaturesForClinicallyEstablishedMSA = (document: IMSA2022): TMSAYesNoUnknown => {
  const autonomicDysfunction = document.coreClinicalFeaturesForClinicallyEstablishedMSA?.autonomicDysfunction || {};
  const cerebellarSyndrome = document.coreClinicalFeaturesForClinicallyEstablishedMSA?.cerebellarSyndrome || {};

  // Extract values
  const unexplainedVoiding = autonomicDysfunction.unexplainedVoidingDifficulties;
  const urinaryUrgeIncontinence = autonomicDysfunction.unexplainedUrinaryUrgeIncontinence;
  const neurogenicOH = autonomicDysfunction.neurogenicOrthostaticHypertension;
  const poorlyResponsiveParkinsonism =
    document.coreClinicalFeaturesForClinicallyEstablishedMSA?.poorlyLevodopaResponsiveParkinsonism;

  const gaitAtaxia = cerebellarSyndrome.gaitAtaxia;
  const limbAtaxia = cerebellarSyndrome.limbAtaxia;
  const cerebellarDysarthria = cerebellarSyndrome.cerebellarDysarthria;
  const oculomotorFeatures = cerebellarSyndrome.oculomotorFeatures;

  // Check for "Kyllä" condition
  const autonomicYesCount = [unexplainedVoiding, urinaryUrgeIncontinence, neurogenicOH].filter(
    (ans) => ans === 'yes',
  ).length;
  const cerebellarYesCount = [gaitAtaxia, limbAtaxia, cerebellarDysarthria, oculomotorFeatures].filter(
    (ans) => ans === 'yes',
  ).length;

  const meetsYesCriteria =
    autonomicYesCount >= 2 && (poorlyResponsiveParkinsonism === 'yes' || cerebellarYesCount >= 2);

  // Check for "Ei" condition
  const autonomicNoCount = [unexplainedVoiding, urinaryUrgeIncontinence, neurogenicOH].filter(
    (ans) => ans === 'no',
  ).length;
  const cerebellarNoCount = [gaitAtaxia, limbAtaxia, cerebellarDysarthria, oculomotorFeatures].filter(
    (ans) => ans === 'no',
  ).length;

  const meetsNoCriteria = autonomicNoCount >= 2 && poorlyResponsiveParkinsonism === 'no' && cerebellarNoCount >= 3;

  // Determine result
  if (meetsYesCriteria) {
    return 'yes';
  } else if (meetsNoCriteria) {
    return 'no';
  } else {
    return 'unknown';
  }
};

/**
  Ohjelma päättelee tämän edellä olevien vastausten perusteella:
  - "Kyllä" vastaus edellyttää, että vähintään kaksi seuraavista on “Kyllä”: 
  1. Autonomic dysfunction defined as (edellyttää. että vähintään yksi seuraavista on "Kyllä": “Unexplained voiding difficulties with post-void urinary residual volume, “Unexplained urinary urge incontinence”, “Neurogenic OH (≥ 20/10 mmHg blood pressure drop) within 10 minutes of standing or head-up tilt test”), 
  2. Poorly levodopa-responsive parkinsonism, 
  3. A cerebellar syndrome (edellyttää, että vähintään yksi seuraavista on “Kyllä”: “gait ataxia”, “limb ataxia”, “cerebellar dysarthria”, “oculomotor features”).
  - "Ei" vastaus edellyttää, että vähintään kaksi seuraavista on “Ei”: 
  1. Autonomic dysfunction defined as (edellyttää. että vähintään kaikki seuraavista on "Ei": “Unexplained voiding difficulties with post-void urinary residual volume, “Unexplained urinary urge incontinence”, “Neurogenic OH (≥ 20/10 mmHg blood pressure drop) within 10 minutes of standing or head-up tilt test”), 
  2. Poorly levodopa-responsive parkinsonism, 
  3. A cerebellar syndrome (edellyttää, että vähintään kaikki seuraavista on “Ei”: “gait ataxia”, “limb ataxia”, “cerebellar dysarthria”, “oculomotor features”).
  - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"

  * @param {IMSA2022} document - MSA 2022 document to be checked
  * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const checkCoreClinicalFeaturesForClinicallyProbableMSA = (document: IMSA2022): TMSAYesNoUnknown => {
  const autonomicDysfunction = document.coreClinicalFeaturesForClinicallyProbableMSA?.autonomicDysfunction || {};
  const cerebellarSyndrome = document.coreClinicalFeaturesForClinicallyProbableMSA?.cerebellarSyndrome || {};

  // Extract values
  const unexplainedVoiding = autonomicDysfunction.unexplainedVoidingDifficulties;
  const urinaryUrgeIncontinence = autonomicDysfunction.unexplainedUrinaryUrgeIncontinence;
  const neurogenicOH = autonomicDysfunction.neurogenicOrthostaticHypertension;
  const poorlyResponsiveParkinsonism =
    document.coreClinicalFeaturesForClinicallyProbableMSA?.poorlyLevodopaResponsiveParkinsonism;

  const gaitAtaxia = cerebellarSyndrome.gaitAtaxia;
  const limbAtaxia = cerebellarSyndrome.limbAtaxia;
  const cerebellarDysarthria = cerebellarSyndrome.cerebellarDysarthria;
  const oculomotorFeatures = cerebellarSyndrome.oculomotorFeatures;

  // Check for "Kyllä" condition
  const autonomicYesCount = [unexplainedVoiding, urinaryUrgeIncontinence, neurogenicOH].filter(
    (ans) => ans === 'yes',
  ).length;
  const cerebellarYesCount = [gaitAtaxia, limbAtaxia, cerebellarDysarthria, oculomotorFeatures].filter(
    (ans) => ans === 'yes',
  ).length;

  const meetsYesCriteria = autonomicYesCount >= 1 && poorlyResponsiveParkinsonism === 'yes' && cerebellarYesCount >= 1;

  // Check for "Ei" condition
  const autonomicNoCount = [unexplainedVoiding, urinaryUrgeIncontinence, neurogenicOH].filter(
    (ans) => ans === 'no',
  ).length;
  const cerebellarNoCount = [gaitAtaxia, limbAtaxia, cerebellarDysarthria, oculomotorFeatures].filter(
    (ans) => ans === 'no',
  ).length;

  const meetsNoCriteria = autonomicNoCount === 3 && poorlyResponsiveParkinsonism === 'no' && cerebellarNoCount === 4;

  // Determine result
  if (meetsYesCriteria) {
    return 'yes';
  } else if (meetsNoCriteria) {
    return 'no';
  } else {
    return 'unknown';
  }
};

/**
  Ohjelma päättelee tämän edellä olevien vastausten perusteella:
  - "Kyllä" vastaus edellyttää, että vähintään kaksi Supportive clinical (motor or non-motor) features -oireista on "Kyllä".
  - "Ei" vastaus edellyttää, että yhtä lukuunottamatta kaikki Supportive clinical (motor or non-motor) features -oireista on "Ei".
  - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"

  * @param {IMSA2022} document - MSA 2022 document to be checked
  * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const checkSupportiveClinicalFeaturesForClinicallyEstablishedMSA = (document: IMSA2022): TMSAYesNoUnknown => {
  const supportiveMotorFeatures = document.supportiveClinicalFeatures?.supportiveMotorFeatures || [];
  const supportiveNonMotorFeatures = document.supportiveClinicalFeatures?.supportiveNonMotorFeatures || [];

  // Combine both motor and non-motor features
  const allSupportiveFeatures = [
    ...Object.values(supportiveMotorFeatures),
    ...Object.values(supportiveNonMotorFeatures),
  ];

  // Count the number of "Kyllä" and "Ei" answers
  const yesCount = allSupportiveFeatures.filter((feature) => feature === 'yes').length;
  const noCount = allSupportiveFeatures.filter((feature) => feature === 'no').length;

  // Check if "Kyllä" criteria are met (at least two "Kyllä" answers)
  const meetsYesCriteria = yesCount >= 2;

  // Check if "Ei" criteria are met (all but one are "Ei")
  const meetsNoCriteria = noCount >= allSupportiveFeatures.length - 1;

  // Determine the result
  if (meetsYesCriteria) {
    return 'yes';
  } else if (meetsNoCriteria) {
    return 'no';
  } else {
    return 'unknown';
  }
};

/**
  Ohjelma päättelee tämän edellä olevien vastausten perusteella:
  - "Kyllä" vastaus edellyttää, että vähintään yksi Supportive clinical (motor or non-motor) features -oireista on "Kyllä".
  - "Ei" vastaus edellyttää, että kaikki Supportive clinical (motor or non-motor) features -oireista on "Ei".
  - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"

  * @param {IMSA2022} document - MSA 2022 document to be checked
  * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const checkSupportiveClinicalFeaturesForClinicallyProbableMSA = (document: IMSA2022): TMSAYesNoUnknown => {
  const supportiveMotorFeatures = document.supportiveClinicalFeatures?.supportiveMotorFeatures || [];
  const supportiveNonMotorFeatures = document.supportiveClinicalFeatures?.supportiveNonMotorFeatures || [];

  // Combine both motor and non-motor features
  const allSupportiveFeatures = [
    ...Object.values(supportiveMotorFeatures),
    ...Object.values(supportiveNonMotorFeatures),
  ];

  // Count the number of "Kyllä" and "Ei" answers
  const yesCount = allSupportiveFeatures.filter((feature) => feature === 'yes').length;
  const noCount = allSupportiveFeatures.filter((feature) => feature === 'no').length;

  // Check if "Kyllä" criteria are met (at least one "Kyllä" answer)
  const meetsYesCriteria = yesCount >= 1;

  // Check if "Ei" criteria are met (all are "Ei")
  const meetsNoCriteria = noCount === allSupportiveFeatures.length;

  // Determine the result
  if (meetsYesCriteria) {
    return 'yes';
  } else if (meetsNoCriteria) {
    return 'no';
  } else {
    return 'unknown';
  }
};

/**
  Ohjelma päättelee tämän edellä olevien vastausten perusteella:
  - "Kyllä" vastaus edellyttää, että vähintään yksi MRI markers of clinically established MSA -löydöksistä on "Kyllä".
  - "Ei" vastaus edellyttää, että kaikki MRI markers of clinically established MSA -löydöksistä on "Ei".
  - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"

  * @param {IMSA2022} document - MSA 2022 document to be checked
  * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const checkMRIMarkersForClinicallyEstablishedMSAP = (document: IMSA2022): TMSAYesNoUnknown => {
  const mriMarkers = document.mriMarkersOfClinicallyEstablishedMSA?.msaP;

  if (!mriMarkers) {
    return 'unknown'; // Return "Ei tiedossa" if no MRI markers for MSA-P
  }

  // Check if at least one MRI marker is "Kyllä"
  const meetsYesCriteria =
    mriMarkers.atrophy?.putamenSignalDecreaseOnIronSensitiveSequences === 'yes' ||
    mriMarkers.atrophy?.middleCerebellarPeduncle === 'yes' ||
    mriMarkers.atrophy?.pons === 'yes' ||
    mriMarkers.atrophy?.cerebellum === 'yes' ||
    mriMarkers.hotCrossBunSign === 'yes' ||
    mriMarkers.increasedDiffusivity?.putamen === 'yes' ||
    mriMarkers.increasedDiffusivity?.middleCerebellarPeduncle === 'yes';

  // Check if all MRI markers are "Ei"
  const meetsNoCriteria =
    mriMarkers.atrophy?.putamenSignalDecreaseOnIronSensitiveSequences === 'no' &&
    mriMarkers.atrophy?.middleCerebellarPeduncle === 'no' &&
    mriMarkers.atrophy?.pons === 'no' &&
    mriMarkers.atrophy?.cerebellum === 'no' &&
    mriMarkers.hotCrossBunSign === 'no' &&
    mriMarkers.increasedDiffusivity?.putamen === 'no' &&
    mriMarkers.increasedDiffusivity?.middleCerebellarPeduncle === 'no';

  // Return result based on the conditions
  if (meetsYesCriteria) {
    return 'yes';
  } else if (meetsNoCriteria) {
    return 'no';
  } else {
    return 'unknown';
  }
};

/**
  Ohjelma päättelee tämän edellä olevien vastausten perusteella:
  - "Kyllä" vastaus edellyttää, että vähintään yksi MRI markers of clinically established MSA -löydöksistä on "Kyllä".
  - "Ei" vastaus edellyttää, että kaikki MRI markers of clinically established MSA -löydöksistä on "Ei".
  - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"

  * @param {IMSA2022} document - MSA 2022 document to be checked
  * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const checkMRIMarkersForClinicallyEstablishedMSAC = (document: IMSA2022): TMSAYesNoUnknown => {
  const mriMarkers = document.mriMarkersOfClinicallyEstablishedMSA?.msaC;

  if (!mriMarkers) {
    return 'unknown'; // Return "Ei tiedossa" if no MRI markers for MSA-C
  }

  // Check if at least one MRI marker is "Kyllä"
  const meetsYesCriteria =
    mriMarkers.atrophy?.putamenSignalDecreaseOnIronSensitiveSequences === 'yes' ||
    mriMarkers.atrophy?.infratentorialStructures === 'yes' ||
    mriMarkers.hotCrossBunSign === 'yes' ||
    mriMarkers.increasedDiffusivity?.putamen === 'yes';

  // Check if all MRI markers are "Ei"
  const meetsNoCriteria =
    mriMarkers.atrophy?.putamenSignalDecreaseOnIronSensitiveSequences === 'no' &&
    mriMarkers.atrophy?.infratentorialStructures === 'no' &&
    mriMarkers.hotCrossBunSign === 'no' &&
    mriMarkers.increasedDiffusivity?.putamen === 'no';

  // Return result based on the conditions
  if (meetsYesCriteria) {
    return 'yes';
  } else if (meetsNoCriteria) {
    return 'no';
  } else {
    return 'unknown';
  }
};

/**
  Ohjelma päättelee tämän edellä olevien vastausten perusteella:
  - "Kyllä" vastaus edellyttää, että kaikki Exclusion criteria -kohdista on "Ei".
  - "Ei" vastaus edellyttää, että vähintään yksi Exclusion criteria -kohdista on "Kyllä".
  - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"

  * @param {IMSA2022} document - MSA 2022 document to be checked
  * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const checkExclusionCriteriaAbsent = (document: IMSA2022): TMSAYesNoUnknown => {
  const exclusionCriteria = document.exclusionCriteria;

  if (!exclusionCriteria) {
    return 'unknown'; // Return "Ei tiedossa" if exclusion criteria are not provided
  }

  // Check if all exclusion criteria are "Ei"
  const allExcluded = Object.values(exclusionCriteria).every((value) => value === 'no');

  // Check if at least one exclusion criterion is "Kyllä"
  const atLeastOneIncluded = Object.values(exclusionCriteria).some((value) => value === 'yes');

  // Return result based on the conditions
  if (allExcluded) {
    return 'yes';
  } else if (atLeastOneIncluded) {
    return 'no';
  } else {
    return 'unknown';
  }
};

/**
  Ohjelma päättelee tämän edellä olevien perusteella:
  - "Kyllä" vastaus edellyttää, että "Essential features are met" JA "Core clinical features are met" JA "Supportive clinicaö(motor or non-motor) features are met" JA ("MRI markers of clinicallyestablished MSA-P are met" TAI "MRI markers of clinically established MSA-C") JA "Exclusion criteria are absent" ovat "Kyllä".
  - "Ei" vastaus edellyttää, että "Essential features are met" TAI "Core clinical features are met" TAI "Supportive clinicaö(motor or non-motor) features are met" TAI ("MRI markers of clinicallyestablished MSA-P are met" JA "MRI markers of clinically established MSA-C") TAI "Exclusion criteria are absent" on "Ei".
  - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"

  * @param {IMSA2022} document - MSA 2022 document to be checked
  * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const checkDiagnosticCriteriaForClinicallyEstablishedMSA = (document: IMSA2022): TMSAYesNoUnknown => {
  // Check if the essential features for sporadic progressive adult-onset disease are met
  const essentialFeaturesMet = document.essentialFeatures?.sporadicProgressiveAdultOnsetDisease;

  // Check all necessary criteria
  const coreClinicalFeaturesMet = checkCoreClinicalFeaturesForClinicallyEstablishedMSA(document);
  const supportiveClinicalFeaturesMet = checkSupportiveClinicalFeaturesForClinicallyEstablishedMSA(document);
  const mriMarkersMSAPMet = checkMRIMarkersForClinicallyEstablishedMSAP(document);
  const mriMarkersMSACMet = checkMRIMarkersForClinicallyEstablishedMSAC(document);
  const exclusionCriteriaAbsent = checkExclusionCriteriaAbsent(document);

  // All conditions need to be true for "Kyllä"
  if (
    [
      essentialFeaturesMet,
      coreClinicalFeaturesMet,
      supportiveClinicalFeaturesMet,
      mriMarkersMSAPMet === 'yes' ? 'yes' : mriMarkersMSACMet === 'yes' ? 'yes' : 'no',
      exclusionCriteriaAbsent,
    ].every((c) => c === 'yes')
  ) {
    return 'yes';
  }

  // If any of the conditions are "Ei", return "Ei"
  if (
    [
      essentialFeaturesMet,
      coreClinicalFeaturesMet,
      supportiveClinicalFeaturesMet,
      mriMarkersMSAPMet === 'no' && mriMarkersMSACMet === 'no' ? 'no' : 'unknown',
      exclusionCriteriaAbsent,
    ].some((c) => c === 'no')
  ) {
    return 'no';
  }

  // If the conditions don't meet the "Kyllä" or "Ei" criteria, return "Ei tiedossa"
  return 'unknown';
};

/**
  Ohjelma päättelee tämän edellä olevien perusteella:
  - "Kyllä" vastaus edellyttää, että "Essential features are met" JA "Core clinical features are met" JA "Supportive clinicaö(motor or non-motor) features are met" JA "Exclusion criteria are absent" ovat "Kyllä".
  - "Ei" vastaus edellyttää, että "Essential features are met" TAI "Core clinical features are met" TAI "Supportive clinicaö(motor or non-motor) features are met" TAI "Exclusion criteria are absent" on "Ei".
  - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"

  * @param {IMSA2022} document - MSA 2022 document to be checked
  * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const checkDiagnosticCriteriaForClinicallyProbableMSA = (document: IMSA2022): TMSAYesNoUnknown => {
  // Check if the essential features for sporadic progressive adult-onset disease are met
  const essentialFeaturesMet = document.essentialFeatures?.sporadicProgressiveAdultOnsetDisease;

  // Check all necessary criteria for clinically probable MSA
  const coreClinicalFeaturesMet = checkCoreClinicalFeaturesForClinicallyProbableMSA(document);
  const supportiveClinicalFeaturesMet = checkSupportiveClinicalFeaturesForClinicallyProbableMSA(document);
  const exclusionCriteriaAbsent = checkExclusionCriteriaAbsent(document);

  // All conditions need to be true for "Kyllä"
  if (
    [essentialFeaturesMet, coreClinicalFeaturesMet, supportiveClinicalFeaturesMet, exclusionCriteriaAbsent].every(
      (c) => c === 'yes',
    )
  ) {
    return 'yes';
  }

  // If any of the conditions are "Ei", return "Ei"
  if (
    [essentialFeaturesMet, coreClinicalFeaturesMet, supportiveClinicalFeaturesMet, exclusionCriteriaAbsent].some(
      (c) => c === 'no',
    )
  ) {
    return 'no';
  }

  // If the conditions don't meet the "Kyllä" or "Ei" criteria, return "Ei tiedossa"
  return 'unknown';
};

/**
 * Functions for "Criteria for the clinical diagnosis of probable and possible MSA (Gilman et al. 2008)"
 */

/**
 * Returns whether MSA is probable
 * @param  {IMSA} d - MSA document
 * @returns {'yes' | 'no' | 'unknown'} If MSA is probable
 */
export const probableMSA = (d: IMSA): 'yes' | 'no' | 'unknown' => {
  const yes =
    (d.probableAutonomicFailureInvolvingUrinaryIncontinence === 'yes' ||
      d.probableOrthostaticDecreaseOfBloodPressure === 'yes') &&
    (d.probablePoorlyLevodopaResponsiveParkinsonism === 'yes' ||
      d.gaitAtaxiaWithCerebellarDysarthria === 'yes' ||
      d.limbAtaxia === 'yes' ||
      d.cerebellarOculomotorDysfunction === 'yes');
  const no =
    (d.probableAutonomicFailureInvolvingUrinaryIncontinence === 'no' &&
      d.probableOrthostaticDecreaseOfBloodPressure === 'no') ||
    (d.probablePoorlyLevodopaResponsiveParkinsonism === 'no' &&
      d.gaitAtaxiaWithCerebellarDysarthria === 'no' &&
      d.limbAtaxia === 'no' &&
      d.cerebellarOculomotorDysfunction === 'no');
  if (yes) return 'yes';
  if (no) return 'no';
  return 'unknown';
};

const someOf = (fields: string[], condition: string, d: IMSA): boolean =>
  fields.some((f) => (d as { [key: string]: any })[f] === condition);

const allOf = (fields: string[], condition: string, d: IMSA): boolean => {
  const filtered = fields.filter((f) => (d as { [key: string]: any })[f] === condition);
  return fields.length === filtered.length;
};

/**
 * Returns whether MSA is possible
 * @param  {IMSA} d - MSA document
 * @returns {'yes' | 'no' | 'unknown'} If MSA is possible
 */
export const possibleMSA = (d: IMSA): 'yes' | 'no' | 'unknown' => {
  const parkinsonism = (): 'yes' | 'no' | 'unknown' => {
    const fields = ['possibleBradykinesiaWithRigidity', 'possibleTremor', 'possiblePosturalInstability'];

    return someOf(fields, 'yes', d) ? 'yes' : allOf(fields, 'no', d) ? 'no' : 'unknown';
  };

  const cerebellar = (): 'yes' | 'no' | 'unknown' => {
    const fields = ['gaitAtaxiaWithCerebellarDysarthria', 'limbAtaxia', 'cerebellarOculomotorDysfunction'];

    return someOf(fields, 'yes', d) ? 'yes' : allOf(fields, 'no', d) ? 'no' : 'unknown';
  };

  const autonomic = (): 'yes' | 'no' | 'unknown' => {
    const fields = [
      'possibleOtherwiseUnexplainedUrinaryUrgency',
      'possibleFrequencyOrIncompleteBladderEmptying',
      'possibleErectileDysfunctionInMales',
      'significantOrthostaticBloodPressure',
    ];

    return someOf(fields, 'yes', d) ? 'yes' : allOf(fields, 'no', d) ? 'no' : 'unknown';
  };

  const additional = (): 'yes' | 'no' | 'unknown' => {
    const fields = [
      'possibleBabinskiSignWithHyperreflexia',
      'possibleStridor',
      'possibleMSAPRapidlyProgressiveParkinsonism',
      'possibleMSAPPoorResponseToLevodopa',
      'possibleMSAPPosturalInstabilityWithin3yOfMotorOnset',
      'possibleMSAPGaitAtaxiaCerebellarDysarthriaLimbAtaxiaOrCerebellarOculomotorDysfunction',
      'possibleMSAPDysphagiaWithin5yOfMotorOnset',
      'possibleMSAPAtrophyOnMRIOfPutamenMiddleCerebellarPedunclePonsOrCerebellum',
      'possibleMSAPHypometabolismOnFDGPETInPutamenBrainstemOrCerebellum',
      'possibleMSACParkinsonism',
      'possibleMSACAtrophyOnMRIOfPutamenMiddleCerebellarPeduncleOrPons',
      'possibleMSACHypometabolismOnFDGPETInPutamen',
      'possibleMSACPresynapticNigrostriatalDopaminergicDenervationOnSPECTOrPET',
    ];

    return someOf(fields, 'yes', d) ? 'yes' : allOf(fields, 'no', d) ? 'no' : 'unknown';
  };

  if ((parkinsonism() === 'yes' || cerebellar() === 'yes') && autonomic() === 'yes' && additional() === 'yes')
    return 'yes';
  if ((parkinsonism() === 'no' && cerebellar() === 'no') || autonomic() === 'no' || additional() === 'no') return 'no';

  return 'unknown';
};

const supportingFeaturesFields: Array<keyof IMSA> = [
  'supportingOrofacialDystonia',
  'supportingDisproportionateAntecollis',
  'supportingCamptocormia',
  'supportingContracturesOfHandsOrFeet',
  'supportingInspiratorySighs',
  'supportingSevereDysphonia',
  'supportingSevereDysatrhria',
  'supportingNewOrIncreasedSnoring',
  'supportingColdHandsAndFeet',
  'supportingPathologicLaughterOrCrying',
  'supportingJerkyMyoclonicPosturalActionTremor',
];

const nonSupportingFeaturesFields: Array<keyof IMSA> = [
  'nonsupportingClassicPillrollingRestTremor',
  'nonsupportingClinicallySignificantNeuropathy',
  'nonsupportingHallucinationsNotInducedByDrugs',
  'nonsupportingOnsetAfter75y',
  'nonsupportingFamilyHistoryOfAtaxiaOrParkinsonism',
  'nonsupportingDementia',
  'nonsupportingWhiteMatterLesionsSuggestingMultipleSclerosis',
];

/**
 * Counts the number of MSA supporting features answers (yes, no or unknown)
 * @param {IMSA} document - MSA document to be checked
 * @param {TYesNoUnknown} answer - The answers to count
 * @returns {number} - Number of answers
 */
export const numberOfSupportingFeatures = (document: IMSA, answer: TYesNoUnknown): number =>
  supportingFeaturesFields.filter((feature: keyof IMSA) => document[feature] === answer).length;

/**
 * Counts the number of MSA nonsupporting features answers (yes, no or unknown)
 * @param {IMSA} document - MSA document to be checked
 * @param {TYesNoUnknown} answer - The answers to count
 * @returns {number} - Number of answers
 */
export const numberOfNonSupportingFeatures = (document: IMSA, answer: TYesNoUnknown): number =>
  nonSupportingFeaturesFields.filter((feature: keyof IMSA) => document[feature] === answer).length;
