import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { resolvePath } from '../utils';

export const options: { [key: string]: any } = {
  ambulatoryScore: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
  pyramidalFunctions: {
    score: [0, 1, 2, 3, 4, 5, 6],
    reflexes: {
      biceps: ['notDone', 0, 1, 2, 3, 4, 5],
      triceps: ['notDone', 0, 1, 2, 3, 4, 5],
      brachioradialis: ['notDone', 0, 1, 2, 3, 4, 5],
      patella: ['notDone', 0, 1, 2, 3, 4, 5],
      achilles: ['notDone', 0, 1, 2, 3, 4, 5],
      babinski: ['notDone', 0, 1, 2],
      cutatenous: ['notDone', 0, 1, 2],
      palmomental: ['notDone', 0, 1],
    },
    limbStrength: {
      deltoid: ['notDone', 5, 4, 3, 2, 1, 0],
      biceps: ['notDone', 5, 4, 3, 2, 1, 0],
      triceps: ['notDone', 5, 4, 3, 2, 1, 0],
      wristFingerFlexors: ['notDone', 5, 4, 3, 2, 1, 0],
      wristFingerExtensors: ['notDone', 5, 4, 3, 2, 1, 0],
      hipFlexors: ['notDone', 5, 4, 3, 2, 1, 0],
      kneeFlexors: ['notDone', 5, 4, 3, 2, 1, 0],
      kneeExtensors: ['notDone', 5, 4, 3, 2, 1, 0],
      plantarFlexion: ['notDone', 5, 4, 3, 2, 1, 0],
      dorsiflexion: ['notDone', 5, 4, 3, 2, 1, 0],
    },
    functionalTests: {
      pronatorDriftUpperExtremities: ['notDone', 0, 1, 2],
      positionTestUEDownwardDrift: ['notDone', 0, 1, 2],
      positionTestLESinking: ['notDone', 0, 1, 2, 3, 4],
      ableToLiftOnlyOneLegAtATimeGrade: [],
      walkingOnHeels: ['notDone', 0, 1, 2],
      walkingOnToes: ['notDone', 0, 1, 2],
      hoppingOnOneFoot: ['notDone', 0, 1, 2, 3],
    },
    spasticity: {
      arms: ['notDone', 0, 1, 2, 3, 4],
      legs: ['notDone', 0, 1, 2, 3, 4],
      gait: ['notDone', 0, 1, 2, 3],
      overallMotorPerformance: ['notDone', 0, 1, 2],
    },
  },
  cerebellarFunctions: {
    score: [0, 1, 2, 3, 4, 5],
    headTremor: ['notDone', 0, 1, 2, 3],
    truncalAtaxia: ['notDone', 0, 1, 2, 3, 4],
    tremor: {
      upperLimbTremor: ['notDone', 0, 1, 2, 3, 4],
      lowerLimbTremor: ['notDone', 0, 1, 2, 3, 4],
    },
    rapidAlternatingMovements: {
      upperLimbRapid: ['notDone', 0, 1, 2, 3, 4],
      lowerLimbRapid: ['notDone', 0, 1, 2, 3, 4],
    },
    tandemWalking: ['notDone', 0, 1, 2],
    gaitAtaxia: ['notDone', 0, 1, 2, 3, 4],
    rombergTest: ['notDone', 0, 1, 2, 3],
    otherCerebellarTests: ['notDone', 0, 1, 2, 3],
  },
  brainstemFunctions: {
    score: [0, 1, 2, 3, 4, 5],
    extraocularMovementsImpairment: ['notDone', 0, 1, 2, 3, 4],
    nystagmus: ['notDone', 0, 1, 2, 3],
    trigeminalInjury: ['notDone', 0, 1, 2, 3, 4],
    facialWeakness: ['notDone', 0, 1, 2, 3, 4],
    hearingLoss: ['notDone', 0, 1, 2, 3, 4],
    dysarthria: ['notDone', 0, 1, 2, 3, 4, 5],
    dysphagia: ['notDone', 0, 1, 2, 3, 4, 5],
    otherCranialNerveFunctions: ['notDone', 0, 1, 2, 3, 4],
  },
  sensoryFunctions: {
    score: [0, 1, 2, 3, 4, 5, 6],
    superficialSense: {
      upperExtremities: ['notDone', 0, 1, 2, 3, 4, 5],
      trunk: ['notDone', 0, 1, 2, 3, 4, 5],
      lowerExtremities: ['notDone', 0, 1, 2, 3, 4, 5],
    },
    vibrationSense: {
      upperExtremities: ['notDone', 0, 1, 2, 3],
      lowerExtremities: ['notDone', 0, 1, 2, 3],
    },
    positionSense: {
      upperExtremities: ['notDone', 0, 1, 2, 3],
      lowerExtremities: ['notDone', 0, 1, 2, 3],
    },
    lhermittesSign: ['notDone', 0, 1],
    paresthesiae: ['notDone', 0, 1],
  },
  bowelAndBladderFunctions: {
    score: [0, 1, 2, 3, 4, 5, 6],
    urinaryRetention: ['notDone', 0, 1, 2, 3, 4],
    urinaryIncontinence: ['notDone', 0, 1, 2, 3, 4],
    urinaryCatheterization: ['notDone', 0, 1, 2],
    bowelDysfunction: ['notDone', 0, 1, 2, 3, 4],
  },
  visualAcuity: {
    score: [0, 1, 2, 3, 4, 5, 6],
    visionCorrection: ['yes', 'no', 'unknown'],
    visualAcuity: [],
    visualField: ['notDone', 0, 1, 2, 3],
    scotoma: ['notDone', 0, 1, 2],
    discPallor: ['notDone', 0, 1],
  },
  cerebralFunctions: {
    score: [0, 1, 2, 3, 4, 5],
    decreaseInMentation: ['notDone', 0, 2, 3, 4, 5],
    fatigue: ['notDone', 0, 1, 2, 3],
  },
  edssStep: [0, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0, 5.5, 6.0, 6.5, 7.0, 7.5, 8.0, 8.5, 9.0, 9.5, 10],
};

export const tooltips = (target: string): Array<{ index: number; message: JSX.Element }> => {
  const edssStepFormatter = (value: number): string => {
    return value !== 0 && value !== 10 ? value.toFixed(1) : value.toString();
  };

  return resolvePath(target, options).map((value: number | string, index: number) => {
    return {
      index: index,
      message: (
        <FormattedMessage
          id={`neurologicalStatusAndEDSS.${target}.opts.${
            target === 'edssStep' ? edssStepFormatter(value as number) : value
          }`}
        />
      ),
    };
  });
};
