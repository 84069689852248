import * as React from 'react';
import PlatformConditional from 'Components/PlatformConditional';
import { TDocument } from 'Routes/Treatment/utils';
import DEXAHistory from './DEXA';
import CMAPHistory from './CMAP';
import MuscleImagingHistory from './MuscleImaging';
import EchocardiographyHistory from './Echocardiography';
import ScoliosisHistory from './Scoliosis';
import PelvisHistory from './Pelvis';
import MriHistory from './Mri';
import ScintigraphyHistory from './Scintigraphy';
import TTHistory from './TT';
import ThymusCTHistory from './ThymusCT';
import ThymusMRIHistory from './ThymusMRI';

const History = ({ documents, startEdit }: IImagingHistory): JSX.Element => {
  const DEXADocs = documents && documents.filter((d) => d._type === 'dexa');
  const CMAPDocs = documents && documents.filter((d) => d._type === 'cmap');
  const MuscleImagingDocs = documents && documents.filter((d) => d._type === 'muscleImaging');
  const EchocardiographyDocs = documents && documents.filter((d) => d._type === 'echocardiography');
  const ScoliosisDocs = documents && documents.filter((d) => d._type === 'scoliosis');
  const PelvisDocs = documents && documents.filter((d) => d._type === 'pelvis');
  const MriDocs = documents && documents.filter((d) => d._type === 'mri');
  const TTDocs = documents && documents.filter((d) => d._type === 'tt');
  const ScintigraphyDocs = documents && documents.filter((d) => d._type === 'scintigraphy');
  const ThymusCTDocs = documents && documents.filter((d) => d._type === 'thymusCt');
  const ThymusMRIDocs = documents && documents.filter((d) => d._type === 'thymusMri');

  return (
    <React.Fragment>
      <PlatformConditional platform={['sma', 'dmd']}>
        <React.Fragment>
          <DEXAHistory documents={DEXADocs as IDEXA[]} startEdit={startEdit} />

          <CMAPHistory documents={CMAPDocs as ICMAP[]} startEdit={startEdit} />

          <MuscleImagingHistory documents={MuscleImagingDocs as IMuscleImaging[]} startEdit={startEdit} />
        </React.Fragment>
      </PlatformConditional>

      <PlatformConditional platform="dmd">
        <EchocardiographyHistory documents={EchocardiographyDocs as IEchocardiography[]} startEdit={startEdit} />
      </PlatformConditional>

      <PlatformConditional platform={['sma', 'dmd']}>
        <React.Fragment>
          <ScoliosisHistory documents={ScoliosisDocs as IScoliosisRontgen[]} startEdit={startEdit} />
          <PelvisHistory documents={PelvisDocs as IPelvicRontgen[]} startEdit={startEdit} />
        </React.Fragment>
      </PlatformConditional>

      <PlatformConditional platform={['sma', 'ms', 'parkinson']}>
        <MriHistory documents={MriDocs as IMRI[]} startEdit={startEdit} />
      </PlatformConditional>

      <PlatformConditional platform="parkinson">
        <React.Fragment>
          <TTHistory documents={TTDocs as ITT[]} startEdit={startEdit} />
          <ScintigraphyHistory documents={ScintigraphyDocs as IScintigraphy[]} startEdit={startEdit} />
        </React.Fragment>
      </PlatformConditional>

      <PlatformConditional platform="ninmt">
        <React.Fragment>
          <MriHistory documents={MriDocs as IMRI[]} startEdit={startEdit} />
        </React.Fragment>
      </PlatformConditional>

      <PlatformConditional platform="mgravis">
        <React.Fragment>
          <ThymusCTHistory documents={ThymusCTDocs as IThymusCT[]} startEdit={startEdit} />
          <ThymusMRIHistory documents={ThymusMRIDocs as IThymusMRI[]} startEdit={startEdit} />
        </React.Fragment>
      </PlatformConditional>
    </React.Fragment>
  );
};

interface IImagingHistory {
  documents: Array<TDocument>;
  startEdit: (document: TDocument) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default History;
