import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import OptionChooser from '../../../../../components/OptionChooser';

import { fm } from 'Components/FormatMessage';

const MGFAForm = ({ formData, viewing }: IOwnProps): JSX.Element => (
  <FormSection>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={!viewing}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="functionalPerformanceMgravis.classification" bottomMargin={true}>
      <OptionChooser
        formData={formData}
        name="classification"
        opts={['I', 'II', 'IIa', 'IIb', 'III', 'IIIa', 'IIIb', 'IV', 'IVa', 'IVb', 'V']}
        optionFormatter={(opt: string | number) => fm(`functionalPerformanceMgravis.opts.mgfa.${opt}`)}
        viewing={viewing}
        isSecondaryOption={(opt: string | number): boolean =>
          typeof opt === 'string' && (opt.includes('a') || opt.includes('b'))
        }
        allInstructionsOpenAtOnce={true}
      />
    </FormRow>
  </FormSection>
);

interface IOwnProps {
  formData: IFormData<IMGFA>;
  viewing: boolean;
}

export default MGFAForm;
