import { includes, intersperse } from 'ramda';
import * as React from 'react';
import { Item } from '../../../../../../../components/Grid';
import HistoryRow from '../../../../../../../components/HistoryRow';
import HistoryRowControls from '../../../../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../../../../components/HistoryRowListing';
import { IHistoryContext, withHistoryContext } from '../../../../../../../containers/FormContextHandler';
import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import { newButton } from '../../../../../utils/functions';
import { mcDonald2017ResultForHistoryView } from '../../../../../utils/MS';

const formatCriteriaType = (d: IMSCriteria, fm: (id: string) => string): JSX.Element => {
  return (
    <div>
      {fm(`diagnosis.msDiagnosticCriteria.criteria.opts.${d.criteria ?? 'empty'}`)}
      {d.revision ? ' ' : ''}
      {d.revision ? fm(`diagnosis.msDiagnosticCriteria.mcDonaldRevision.opts.${d.revision}`) : ''}
    </div>
  );
};

const formatDiagnosticCriteriaAnswer = (d: IMSCriteria, fm: (id: string) => string): JSX.Element | string => {
  if (includes(d.criteria, ['poser', 'schumacher']) || includes(d.revision, ['2001', '2005', 'unknown']))
    return <span>{'-'}</span>;
  if (d.criteria === 'mcdonald') {
    if (d.revision === '2010')
      return includes(d.mcDonald2010, ['1', '2', '3', '4'])
        ? fm('diagnosis.msDiagnosticCriteria.history.rr.rr')
        : d.mcDonald2010 === '5'
          ? fm('diagnosis.msDiagnosticCriteria.history.pp.pp')
          : fm('general.empty');
    const resultArr = intersperse('comma', mcDonald2017ResultForHistoryView(d));
    if (d.revision === '2017')
      return (
        <React.Fragment>
          {resultArr.map(
            (r: string, index: number): JSX.Element => (
              <React.Fragment key={r + index}>{fm(`diagnosis.msDiagnosticCriteria.history.${r}`)}</React.Fragment>
            ),
          )}
        </React.Fragment>
      );
  }
  return fm('general.unknown');
};

const MSDiagnosticCriteriaHistory = ({
  documents,
  startEdit,
  view,
  fm = (): string => '',
}: IHistoryContext): JSX.Element => {
  const msCriteria: Array<IMSCriteria> = documents
    ?.filter((d) => d._type === 'msDiagnosticCriteria')
    .sort((d1: IMSCriteria, d2: IMSCriteria) => sortPartialDate(d1.date, d2.date))
    .reverse() as Array<IMSCriteria>;
  return (
    <HistoryRow
      headerText={fm('diagnosis.msDiagnosticCriteria.history.title')}
      rowButton={
        startEdit
          ? newButton('msDiagnosticCriteria', startEdit({}, 'msDiagnosticCriteria'), 'general.new', 15)
          : undefined
      }
    >
      <HistoryRowListing
        documents={msCriteria}
        headers={
          <>
            <Item xs={2}>{fm('general.date')}</Item>
            <Item xs={3}>{fm('diagnosis.msDiagnosticCriteria.history.usedCriteria')}</Item>
            <Item xs={7}>{fm('diagnosis.msDiagnosticCriteria.history.criteriasAreFullfilled')}</Item>
          </>
        }
        contentFormat={(d: IMSCriteria): JSX.Element => (
          <>
            <Item xs={2} style={{ fontWeight: '600' }}>
              {d.date && formatPartialDate(d.date)}
            </Item>
            <Item xs={3}>{formatCriteriaType(d, fm)}</Item>
            <Item xs={4}>{formatDiagnosticCriteriaAnswer(d, fm)}</Item>
            <Item xs={3}>
              {startEdit ? <HistoryRowControls document={d} startEdit={startEdit} view={view} /> : null}
            </Item>
          </>
        )}
      />
    </HistoryRow>
  );
};

export default withHistoryContext(MSDiagnosticCriteriaHistory);
