import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';

import { formatPartialDate } from 'neuro-utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const SDMT = ({ documents, startEdit, view }: IOwnProps): JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id="cognition.sdmt.sdmt" />}
      newButton={
        <DocumentCreationButton
          name="sdmt"
          text="cognition.sdmt.newSdmt"
          onClick={startEdit({}, 'sdmt')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={2}>
              <FormattedMessage id="general.date" />
            </Item>
            <Item xs={2}>
              <FormattedMessage id="cognition.sdmt.testType" />
            </Item>
            <Item xs={2}>
              <FormattedMessage id="cognition.sdmt.correctAnswers" />
            </Item>
            <Item xs={2}>
              <FormattedMessage id="cognition.sdmt.allAnswers" />
            </Item>
          </>
        }
        contentFormat={(d: ISDMT): JSX.Element => (
          <>
            <Item xs={2}>{formatPartialDate(d.date ?? undefined)}</Item>
            <Item xs={2}>{d.testType ? <FormattedMessage id={`cognition.sdmt.${d.testType}`} /> : '-'}</Item>
            <Item xs={2}>{d.correctAnswers ?? '-'}</Item>
            <Item xs={2}>{d.allAnswers ?? '-'}</Item>
            <Item xs={1}>&nbsp;</Item>
            <Item xs={3}>
              <HistoryRowControls document={d} startEdit={startEdit} view={view} />
            </Item>
          </>
        )}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: ISDMT[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default SDMT;
