import * as React from 'react';

import { TDocument } from '../../utils';

import DEXAForm from './DEXA';
import CMAPForm from './CMAP';
import MuscleImagingForm from './MuscleImaging';
import EchocardiographyForm from './Echocardiography';
import ScoliosisForm from './Scoliosis';
import MriForm from './Mri';
import TTForm from './TT';
import ScintigraphyForm from './Scintigraphy';
import PelvisForm from './Pelvis';
import ThymusCTForm from './ThymusCT';
import ThymusMRIForm from './ThymusMRI';

const ImagingForm = ({ formData, document }: IOwnProps): JSX.Element | null =>
  document?._type === 'dexa' ? (
    <DEXAForm formData={formData} />
  ) : document?._type === 'cmap' ? (
    <CMAPForm formData={formData} />
  ) : document?._type === 'muscleImaging' ? (
    <MuscleImagingForm formData={formData} />
  ) : document?._type === 'echocardiography' ? (
    <EchocardiographyForm formData={formData as IFormData<IEchocardiography>} />
  ) : document?._type === 'scoliosis' ? (
    <ScoliosisForm formData={formData} />
  ) : document?._type === 'pelvis' ? (
    <PelvisForm formData={formData} />
  ) : document?._type === 'mri' ? (
    <MriForm formData={formData} />
  ) : document?._type === 'tt' ? (
    <TTForm formData={formData} />
  ) : document?._type === 'scintigraphy' ? (
    <ScintigraphyForm formData={formData as IFormData<IScintigraphy>} />
  ) : document?._type === 'thymusCt' ? (
    <ThymusCTForm formData={formData as IFormData<IThymusCT>} />
  ) : document?._type === 'thymusMri' ? (
    <ThymusMRIForm formData={formData as IFormData<IThymusMRI>} />
  ) : null;
interface IOwnProps {
  formData: IFormData<TDocument>;
  document?: TDocument;
  viewing?: boolean;
}

export default ImagingForm;
