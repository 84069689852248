import * as React from 'react';
import { formatPartialDate } from 'neuro-utils';

import { Container, Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRow from 'Components/HistoryRow';

const _BPHistory = ({ documents, startEdit }: IOwnProps): JSX.Element => (
  <>
    {documents &&
      documents
        //.sort((n1, n2) => n1._cdate - n2._cdate)
        //.sort((n1, n2) => sortPartialDate(n1.date, n2.date))
        .reverse()
        .map((d: INotes) => (
          <HistoryRow
            headerText={d.date ? formatPartialDate(d.date) : ''}
            key={d._id}
            rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
            controlsMargin={false}
          >
            <Container>
              <Item xs={2}>
                {/* <HistoryRowBasicItem
                    header={<FormattedMessage id={'bp.bp'} />}
                    value={}
                  /> */}
              </Item>
            </Container>
          </HistoryRow>
        ))}
  </>
);

interface IOwnProps {
  documents: any;
  startEdit: (document: any, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default _BPHistory;
