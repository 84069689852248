import { find, path } from 'ramda';
import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';

import UPDRSDialog from '../UPDRSDialog/updrsDialog';

import { calculateScore, isUpdrsComplete } from '../../../utils';
import { FormattedMessage } from 'react-intl';

const beforeDoc = (documents: IOwnProps['documents'], formData: IOwnProps['formData']): IUPDRSIII | undefined =>
  (find((d) => path(['document', 'updrsIIIBeforeID'], formData) === d._id, documents) as IUPDRSIII) || undefined;

const afterDoc = (documents: IOwnProps['documents'], formData: IOwnProps['formData']): IUPDRSIII | undefined =>
  (find((d) => path(['document', 'updrsIIIAfterID'], formData) === d._id, documents) as IUPDRSIII) || undefined;

const calculateLevodopa = (documents: IOwnProps['documents'], formData: IOwnProps['formData']): number =>
  beforeDoc(documents, formData) && afterDoc(documents, formData)
    ? calculateScore(beforeDoc(documents, formData), afterDoc(documents, formData))
    : 0;

// Levodopatestiä poistettaessa  vain levodopa-dokumentti katoaa, updrsiii-dokumentit jäävät -> aiheuttaa ongelmia kun tehdään uutta testiä
// Sama case dbs:n vaikutuksessa

const Levodopa = ({ formData, documents, viewing, setSubDocuments }: IOwnProps): JSX.Element => {
  const { document, onChange } = formData;

  const commitsLength = documents.find((d) => d._editing === true)?._commitsLength;
  const beforeCommitsLength = beforeDoc(documents, formData)?._commitsLength;
  const afterCommitsLength = afterDoc(documents, formData)?._commitsLength;

  React.useEffect(() => {
    // Make here the decision of which test is rendered and which test is the alternative one
    let temp: 'UPDRS' | 'MDSUPDRS';

    if (documents && documents.length > 0) {
      if (documents.filter((item) => item.testType === 'UPDRS' && item.type === 'levodopa').length > 0) {
        temp = 'UPDRS';
      } else {
        temp = 'MDSUPDRS';
      }
    } else {
      temp = 'MDSUPDRS';
    }

    if (!document.testType) {
      onChange &&
        onChange({
          testType: temp,
        });
    }
  }, [document.testType]);

  React.useEffect(() => {
    const subDocuments = [];
    if (!viewing && commitsLength) {
      // Clear updrsIIIBeforeID or/and updrsIIIAfterID if those documents no longer exist
      if (document.updrsIIIBeforeID && !beforeDoc(documents, formData)) {
        onChange && onChange({ updrsIIIBeforeID: undefined });
      }
      if (document.updrsIIIAfterID && !afterDoc(documents, formData)) {
        onChange && onChange({ updrsIIIAfterID: undefined });
      }

      // Make subdocuments list from IDs
      if (beforeCommitsLength && beforeCommitsLength >= commitsLength && document.updrsIIIBeforeID) {
        subDocuments.push({ name: 'updrs_iii', id: document.updrsIIIBeforeID });
      }
      if (afterCommitsLength && afterCommitsLength >= commitsLength && document.updrsIIIAfterID) {
        subDocuments.push({ name: 'updrs_iii', id: document.updrsIIIAfterID });
      }
      setSubDocuments && setSubDocuments(subDocuments);
    }
  }, [commitsLength, beforeCommitsLength, afterCommitsLength]);

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="general.date">
          <InputHandler
            type="PartialDate"
            editing={!viewing}
            name="date"
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
        <FormRow title="updrs.updrsOrMdsupdrs">
          <InputHandler
            type="Radio"
            name="testType"
            editing={!viewing}
            formData={formData}
            options={['UPDRS', 'MDSUPDRS']}
            optionFormatter={(n: string | number): JSX.Element => (
              <FormattedMessage id={`updrs.${n === 'UPDRS' ? 'updrsIII' : 'mds'}`} />
            )}
            disabledOptions={
              formData.document.updrsIIIBeforeID || formData.document.updrsIIIAfterID ? ['UPDRS', 'MDSUPDRS'] : []
            }
          />
        </FormRow>
        <FormRow title="updrs.updrsWithoutMedication">
          <UPDRSDialog
            name="updrsIIIBeforeID"
            parentDate={document?.date}
            onChange={onChange}
            document={beforeDoc(documents, formData)}
            viewing={viewing}
            context={'levodopa'}
            testType={document.testType || 'MDSUPDRS'}
          />
        </FormRow>
        <FormRow title="updrs.updrsWithMedication">
          <UPDRSDialog
            name="updrsIIIAfterID"
            parentDate={document?.date}
            onChange={onChange}
            document={afterDoc(documents, formData)}
            viewing={viewing}
            context={'levodopa'}
            retro={beforeDoc(documents, formData)?.retrospectiveData?.[0] === true}
            testType={document.testType || 'MDSUPDRS'}
          />
        </FormRow>
      </FormSection>
      <FormSection>
        <FormRow title="updrs.levodopatestScore">
          {/* (Before - After) / Before * 100% */}
          {beforeDoc(documents, formData) &&
          afterDoc(documents, formData) &&
          isUpdrsComplete(beforeDoc(documents, formData), document.testType) &&
          isUpdrsComplete(afterDoc(documents, formData), document.testType)
            ? Math.round(calculateLevodopa(documents, formData) * 100) / 100 + ' %'
            : '-'}
        </FormRow>
        <FormRow title="updrs.levodopatestScoreManual">
          <InputHandler
            type="NumberField"
            editing={!viewing}
            name="manualScore"
            formData={formData}
            width={4.7}
            height={3}
            maxLength={5}
          />
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<ILevodopatest>;
  documents: Array<IUPDRSIII | ILevodopatest>;
  viewing: boolean;
  setSubDocuments?: (subDocuments: { name: string; id: string }[]) => void;
}

export default Levodopa;
