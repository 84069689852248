import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import FormSection from '../../../../../components/FormSection';
import { FormattedMessage } from 'react-intl';

const FVCForm = ({ formData }: IOwnProps): JSX.Element => (
  <React.Fragment>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={true}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormSection header="clinicalstudy.fvcShort">
      <FormRow title="clinicalstudy.fvcLitre">
        <InputHandler
          type="NumberField"
          editing={true}
          name="litres"
          formData={formData}
          placeholder="clinicalstudy.litrePlaceholder"
          width={4}
          height={3}
          maxLength={4}
          min={0}
          max={99}
          precision={2}
        />
      </FormRow>
      <FormRow title="clinicalstudy.fvcPredictedPercentage">
        <InputHandler
          type="NumberField"
          editing={true}
          name="predicted"
          formData={formData}
          placeholder="clinicalstudy.percentagePlaceholder"
          width={5.2}
          height={3}
          maxLength={5}
          min={0}
          max={150}
          precision={1}
        />
      </FormRow>
      <FormRow title="clinicalstudy.sitOrLieDown">
        <InputHandler
          type="Radio"
          editing={true}
          name="sittingOrLyingDown"
          formData={formData}
          options={['sitting', 'laying']}
          optionFormatter={(s: string | number): JSX.Element => <FormattedMessage id={'clinicalstudy.opts.' + s} />}
        />
      </FormRow>
    </FormSection>
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<IFVC>;
}

export default FVCForm;
