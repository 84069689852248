import * as React from 'react';

import FormRow from '../../../../../../components/FormRow';
import FormSection from '../../../../../../components/FormSection';
import InputHandler from '../../../../../../components/InputHandler';

import fetchAdverseEvents from '../../../../utils/fetchAdverseEvents';
import { useIntl } from 'react-intl';
import { TAdverseEventTimeframe } from '../../../../utils/findMedTimeframe';
import { isPartialDate, nowPartialDate } from 'neuro-utils';
import { pickAll, uniqBy } from 'ramda';

const otherEventEnabled = (events?: IAdverseEffect[]): boolean => {
  if (!events || !Array.isArray(events)) return false;
  return events.some(
    (ev: IAdverseEffect) => ev.eventName === 'Muu haittavaikutus' || ev.eventName === 'Other adverse effect',
  );
};

const MedicationAdverseEventForm = ({ formData, consumeMedID, allDocuments }: IOwnProps): JSX.Element => {
  const medID = consumeMedID() || formData.document?.medicationId; // Consumes med name from parent ref. Consume returns the med name only once.
  const [adverseEffects, setAdverseEffects] = React.useState<IAdverseEffect[] | null>(null);
  const [timeframe, setTimeframe] = React.useState<TAdverseEventTimeframe>([null, null]);
  const [medicationName, setMedicationName] = React.useState<string | undefined>(undefined);

  const medicationDoc = allDocuments.find((d) => d._id === medID);

  const { formatMessage } = useIntl();

  React.useEffect(() => {
    if (medicationDoc) {
      setMedicationName(
        (medicationDoc && 'medicationName' in medicationDoc && medicationDoc.medicationName) || undefined,
      );

      const medicationStart = medicationDoc && 'startDate' in medicationDoc && medicationDoc.startDate;
      medicationStart && isPartialDate(medicationStart) && setTimeframe([medicationStart, null]);
    }
  }, [medicationDoc]);

  React.useEffect(() => {
    // Fetch adverse effects from api if not yet fetched
    if (!adverseEffects && medicationName) {
      fetchAdverseEvents(formatMessage, medicationName)
        .then((res) => {
          setAdverseEffects(res);
        })
        .catch(() => {
          return;
        });
    }
  }, [medicationName]);

  React.useEffect(() => {
    if (medID) {
      // If medication name is available (consumed), set the medication name
      if (!formData.document?.medicationId) {
        formData.onChange?.({ medicationId: medID });
      }
    }
  }, [medID, allDocuments, formData]);

  const filteredEffects = adverseEffects ? adverseEffects.filter((ae) => ae.eventName !== null) : [];

  return (
    <FormSection>
      <FormRow title="medication.medication">
        <InputHandler
          type="TextField"
          editing={false}
          name="medicationName"
          formData={{ document: { medicationName } }}
        />
      </FormRow>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={true}
          name="date"
          dateHook={{ dateHookFloor: timeframe[0] || undefined, dateHookCeiling: nowPartialDate() }} // Adverse events cant be set to the future
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="medication.events">
        <InputHandler
          type="AutoCompleteSelect"
          name="adverseEffects"
          editing={true}
          formData={formData}
          placeholder={'medication.adverseEffectSelect'}
          options={uniqBy(pickAll(['eventName', 'targetOrgan']), filteredEffects)} // Filter duplicate effects within targetOrgans
          labelID={'eventName'}
          groupID={'targetOrgan'}
          width={60}
          multiple={true}
        />
      </FormRow>
      {otherEventEnabled(formData?.document?.adverseEffects) && (
        <FormRow title="medication.otherAdverseEffect">
          <InputHandler
            type="TextField"
            name="otherAdverseEffect"
            editing={true}
            formData={formData}
            placeholder={'medication.otherAdverseEffectWhich'}
          />
        </FormRow>
      )}
      <FormRow title="medication.eventDetails">
        <InputHandler // Use InputHandler here so that we can use placeholder
          type="TextArea"
          name="eventDetails"
          editing={true}
          formData={formData}
          placeholder={'medication.eventDetails'}
        />
      </FormRow>
    </FormSection>
  );
};

interface IOwnProps {
  formData: IFormData<IMedicationAdverseEvent>;
  consumeMedID: () => string | null;
  allDocuments: Array<IMedication | IMedicationAdverseEvent>;
}

export default MedicationAdverseEventForm;
