import EventStepper from 'Components/EventStepper';
import { StepperHeaderFormInputPair, StepperHeaderValuePair } from 'Components/EventStepper/components';
import { fm } from 'Components/FormatMessage';
import FormRow from 'Components/FormRow';
import FormSection from 'Components/FormSection';
import InputHandler from 'Components/InputHandler';
import { IFormContext, withFormContext } from 'Containers/FormContextHandler';
import { formatPartialDate } from 'neuro-utils';
import { isEmpty } from 'ramda';
import * as React from 'react';

const HifuForm = ({ formData, editing }: IFormContext<IHifuTreatment>): JSX.Element => {
  return (
    <>
      <FormSection>
        <FormRow title="thalamotomyHifu.thalamotomy.date">
          <InputHandler
            name="date"
            type="PartialDate"
            formData={formData}
            editing={!!editing}
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
        <FormRow title="thalamotomyHifu.hifu.treatmentIndication">
          <InputHandler
            name="treatmentIndication"
            type="Radio"
            options={['essentialTremor', 'tremorDominantParkinson', 'other']}
            formData={formData}
            editing={!!editing}
            optionFormatter={(opt: string | number): JSX.Element => fm(`thalamotomyHifu.hifu.opts.${opt}`)}
            dependentFieldsList={(): string[] => ['otherTreatmentIndication']}
          />
        </FormRow>
        <FormRow condition={formData.document.treatmentIndication === 'other'}>
          <InputHandler
            type="TextArea"
            name="treatmentIndicationOther"
            formData={formData}
            editing={!!editing}
            placeholder="thalamotomyHifu.hifu.whatOther"
          />
        </FormRow>
        <FormRow title="thalamotomyHifu.hifu.treatmentTarget">
          <InputHandler
            name="treatmentTarget"
            type="Radio"
            formData={formData}
            editing={!!editing}
            options={['talamusVIMRight', 'talamusVIMLeft', 'talamusVIMBoth', 'stnRight', 'stnLeft', 'stnBoth']}
            optionFormatter={(opt: string | number): JSX.Element => fm(`thalamotomyHifu.hifu.opts.${opt}`)}
          />
        </FormRow>
      </FormSection>
      <FormSection>
        <EventStepper
          name="adverseEffects"
          formData={formData}
          stepLabelText={(d: IThalamotomyOrHifuTreatmentAdverseEffect): string => formatPartialDate(d.date)}
          stepContent={(d: IThalamotomyOrHifuTreatmentAdverseEffect): JSX.Element => (
            <React.Fragment>
              <StepperHeaderValuePair
                header={fm('thalamotomyHifu.adverseEffect')}
                value={
                  d.adverseEffects &&
                  !isEmpty(d.adverseEffects) &&
                  d.adverseEffects.map((e, i) => {
                    return <div key={i}>{fm(`thalamotomyHifu.opts.${e}`)}</div>;
                  })
                }
              />
              <StepperHeaderValuePair
                header={fm('thalamotomyHifu.adverseEffectAdditionalInformation')}
                value={d.additionalInformation}
              />
            </React.Fragment>
          )}
          addNewTextHeader="thalamotomyHifu.newAdverseEffect"
          addNewTextButton="thalamotomyHifu.newAdverseEffect"
          previousEventsTextHeader="thalamotomyHifu.previousAdverseEffects"
          noPreviousEventsTextHeader="thalamotomyHifu.noPreviousAdverseEffects"
          editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => (
            <React.Fragment>
              <StepperHeaderFormInputPair
                header={fm('general.date')}
                input={
                  <InputHandler
                    type="PartialDate"
                    editing={true}
                    name="date"
                    formData={{
                      onChange,
                      document: { date: formData.document.adverseEffects?.[index]?.date || '' },
                    }}
                    dateDefault="now"
                    isNotCancellable={true}
                    dateHook={{
                      dateHookFloor: formData.document.date,
                    }}
                  />
                }
              />
              <StepperHeaderFormInputPair
                header={fm('thalamotomyHifu.adverseEffect')}
                input={
                  <InputHandler
                    type="Checkbox"
                    name="adverseEffects"
                    editing={true}
                    formData={{
                      onChange,
                      document: { adverseEffects: formData.document.adverseEffects?.[index]?.adverseEffects },
                    }}
                    options={[
                      'dysarthria',
                      'stability',
                      'paresthesy',
                      'cramp',
                      'doubleImages',
                      'infection',
                      'sweating',
                      'cerebralHemorrhage',
                      'hallucination',
                      'suboptimalResponse',
                      'other',
                    ]}
                    optionFormatter={(opt: string | number): JSX.Element => fm(`thalamotomyHifu.opts.${opt}`)}
                  />
                }
              />
              <StepperHeaderFormInputPair
                header={fm('thalamotomyHifu.adverseEffectAdditionalInformation')}
                input={
                  <InputHandler
                    name="additionalInformation"
                    type="TextArea"
                    formData={{
                      onChange,
                      document: {
                        additionalInformation: formData.document.adverseEffects?.[index]?.additionalInformation,
                      },
                    }}
                    editing={true}
                    placeholder={'thalamotomyHifu.adverseEffectAdditionalInformation'}
                  />
                }
              />
            </React.Fragment>
          )}
          viewing={!editing}
        />
      </FormSection>
    </>
  );
};

export default withFormContext(HifuForm);
