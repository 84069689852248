import { Container, Item } from 'Components/Grid';
import * as React from 'react';

import FormRow from '../../../../../../components/FormRow';
import InputHandler from '../../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../../containers/FormContextHandler';

import { ReferenceText } from '../../../../utils/styled';
import BasicFeatures from './BasicFeatures';
import CoreClinicalFeatures from './CoreClinicalFeatures';
import DiagnosticCertainty from './DiagnosticCertainty';

const PSPForm = ({ formData, editing }: IFormContext): JSX.Element => (
  <React.Fragment>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={!!editing}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <CoreClinicalFeatures />
    <BasicFeatures />
    <DiagnosticCertainty />

    <Container>
      <Item style={{ padding: '2rem 0' }}>
        <ReferenceText>
          Höglinger GU, Respondek G, Stamelou M, Kurz C, Josephs KA, Lang AE, Mollenhauer B, Müller U, Nilsson C,
          Whitwell JL, Arzberger T, Englund E, Gelpi E, Giese A, Irwin DJ, Meissner WG, Pantelyat A, Rajput A, van
          Swieten JC, Troakes C, Antonini A, Bhatia KP, Bordelon Y, Compta Y, Corvol JC, Colosimo C, Dickson DW, Dodel
          R, Ferguson L, Grossman M, Kassubek J, Krismer F, Levin J, Lorenzl S, Morris HR, Nestor P, Oertel WH, Poewe W,
          Rabinovici G, Rowe JB, Schellenberg GD, Seppi K, van Eimeren T, Wenning GK, Boxer AL, Golbe LI, Litvan I;
          Movement Disorder Society-endorsed PSP Study Group. Clinical Diagnosis of Progressive Supranuclear Palsy: The
          Movement Disorder Society Criteria. Mov Disord. 2017 Jun;32(6):853-864.
        </ReferenceText>
      </Item>
    </Container>
  </React.Fragment>
);

export default withFormContext(PSPForm);
