import { filter } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import HistoryRow from '../../../components/HistoryRow';
import HistoryRowControls from '../../../components/HistoryRowControls';
import HistorySection from '../../../components/HistorySection';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import { findLatest, formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import { stateToDietProps, TDocument } from '../utils';
import DietTherapyForm from './Form';
import DietHistory from './HistoryRowData/index';
import DocumentCreationButton from '../../../components/DocumentCreationButton';
import { isEnded } from '../../../utility/isEnded';

const activeDietTherapyHistory = (
  documents: Array<TDocument>,
  startEdit: (document: TAnyObject) => (e: React.MouseEvent<Element, MouseEvent>) => void,
  view?: IView,
): JSX.Element | undefined => {
  return documents.length > 0 ? (
    <HistorySection headerText={<FormattedMessage id="dietTherapy.activeDiet" />} hasHistoryRows={true}>
      {documents
        .sort((n1, n2) => sortPartialDate(n1.startDate, n2.startDate))
        .reverse()
        .map((d: IDietTherapy, i: number) => (
          <HistoryRow
            headerText={
              d.startDate
                ? formatPartialDate(d.startDate) + ' - ' + (d.endDate ? formatPartialDate(d.endDate) : '')
                : undefined
            }
            key={i + 'active'}
            rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
            controlsMargin={false}
          >
            <DietHistory document={d} startEdit={startEdit} view={view} />
          </HistoryRow>
        ))}
    </HistorySection>
  ) : undefined;
};

const endedDietTherapyHistory = (
  documents: Array<TDocument>,
  startEdit: (document: TAnyObject) => (e: React.MouseEvent<Element, MouseEvent>) => void,
  view?: IView,
): JSX.Element | undefined => {
  return documents.length > 0 ? (
    <HistorySection
      headerText={<FormattedMessage id="dietTherapy.endedDietTherapy" />}
      inactive={true}
      hasHistoryRows={true}
    >
      {documents
        .sort((n1, n2) => sortPartialDate(n1.endDate, n2.endDate))
        .reverse()
        .map((d: IDietTherapy, i: number) => (
          <HistoryRow
            headerText={
              d.startDate
                ? formatPartialDate(d.startDate) + ' - ' + (d.endDate ? formatPartialDate(d.endDate) : '')
                : undefined
            }
            key={i + 'active'}
            rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
            controlsMargin={false}
          >
            <DietHistory document={d} startEdit={startEdit} view={view} />
          </HistoryRow>
        ))}
    </HistorySection>
  ) : undefined;
};

const _DietTherapy = ({ documents, visitreason }: IDietProps): JSX.Element => {
  // conditions for restricted operations
  const isRetrospective = visitreason === 'retrospectiveDataTransfer';
  const isOngoingDiet = !!(
    documents &&
    findLatest<IDietTherapy & { date?: PartialDate }>(
      documents.map((d) => ({ ...d, date: d.startDate })),
      (diet) => !isPartialDate(diet.endDate),
      {
        singleton: true,
      },
    )
  );
  return (
    <FormEditingHandler name="dietTherapy" documents={documents}>
      {(editing, startEdit, formData, view): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name={'dietTherapy'}
            headerId={'dietTherapy.title'}
            editing={editing}
            editButtons={
              <>
                {!view?.viewing && (
                  <DocumentCreationButton
                    name="diet"
                    text={'general.new'}
                    onClick={startEdit({}, 'diet')}
                    disabled={!isRetrospective && isOngoingDiet}
                    disabledTooltip={<FormattedMessage id="dietTherapy.endOngoingToAddNew" />}
                  />
                )}
              </>
            }
          />
          {editing ? (
            <DietTherapyForm
              formData={formData}
              document={documents && documents.find((d: TDocument) => d._id === editing)}
              editing={editing}
              documents={documents}
            />
          ) : null}

          {view?.viewing ? (
            <DietTherapyForm
              formData={formData}
              document={documents && documents.find((d: TDocument) => d._id === view?.viewing)}
              viewing={!!view.viewing}
            />
          ) : null}

          {!editing && !view?.viewing && documents ? (
            <div>
              {activeDietTherapyHistory(
                filter((d: IDietTherapy) => !isEnded(d.endDate), documents),
                startEdit,
                view,
              )}
              {endedDietTherapyHistory(
                filter((d: IDietTherapy) => isEnded(d.endDate), documents),
                startEdit,
                view,
              )}
            </div>
          ) : undefined}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};
const DietTherapy = connect(stateToDietProps)(_DietTherapy);

interface IDietProps extends Pick<IJWTBody, 'visitreason'> {
  documents: Array<TDocument> | undefined; // undefined when no diet docs
}

export default DietTherapy;
