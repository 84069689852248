import * as React from 'react';
import DiagnosisForm from './DiagnosisForm';
import MSDiagnosticCriteria from './MSForm/MsDiagnosticsCriteria/MS';
import NMOSDDiagnosticCriteria from './MSForm/MsDiagnosticsCriteria/NMOSD';
import MsSymptomsForm from './MSForm/MsSymptoms';
import CBDForm from './ParkinsonForm/CBD';
import DLBForm from './ParkinsonForm/DLB';
import FTDForm from './ParkinsonForm/FTD';
import MSAForm from './ParkinsonForm/MSA';
import PDForm from './ParkinsonForm/PD';
import PPAForm from './ParkinsonForm/PPA';
import PSPForm from './ParkinsonForm/PSP';
import HuntingtonSymptomsForm from './HuntingtonForm/HuntingtonSymptoms';
import { IFormContext, withFormContext } from '../../../../containers/FormContextHandler';
import ParkinsonSymptomForm from './ParkinsonForm/ParkinsonsSymptoms';
import HTTForm from './HuntingtonForm/HTT';
import UHDRS from './HuntingtonForm/UHDRS';
import SyndromeForm from './EpilepsyForm/Syndrome';
import EtiologyForm from './EpilepsyForm/Etiology';
import SeizureTypeForm from './EpilepsyForm/SeizureType';
import FirstVisitSleepApneaForm from './SleepApneaForm/FirstVisitSleepApnea';
import FirstVisitRespiratoryForm from './SleepApneaForm/FirstVisitRespiratory';
import SleepApneaSeverityForm from './SleepApneaForm/SleepApneaSeverity';
import PerformanceRatingForm from './SleepApneaForm/PerformanceRating';
import IndicationForTreatmentForm from './NINMTForm/IndicationForTreatment';
import ReferringPhysicianAndUnitForm from './NINMTForm/ReferringPhysicianAndUnit';
import SymptomsDetails from './NINMTForm/SymptomsDetails';
import SymptomsAndLocations from './NINMTForm/SymptomsAndLocations';
import ContraIndicationsToTreatment from './NINMTForm/ContraIndicationsToTreatment';
import MgravisSymptoms from './MgravisForm/MgravisSymptoms';

const FormComponent = ({
  documents,
  editing,
  view,
  setDiagnosisSelected,
  consumeTreatmentId,
}: IFormContext & {
  setDiagnosisSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  consumeTreatmentId: () => string | null;
}): JSX.Element => {
  const documentType = documents?.find((d) => d._id === editing || d._id === view?.viewing)?._type;
  switch (documentType) {
    case 'diagnosis':
      return <DiagnosisForm setDiagnosisSelected={setDiagnosisSelected} />;
    case 'msSymptoms':
      return <MsSymptomsForm />;
    case 'msDiagnosticCriteria':
      return <MSDiagnosticCriteria />;
    case 'nmosdDiagnosticCriteria':
      return <NMOSDDiagnosticCriteria />;
    case 'parkinsonSymptoms':
      return <ParkinsonSymptomForm />;
    case 'pd':
      return <PDForm />;
    case 'msa':
      return <MSAForm />;
    case 'psp':
      return <PSPForm />;
    case 'dlb':
      return <DLBForm />;
    case 'cbd':
      return <CBDForm />;
    case 'ftd':
      return <FTDForm />;
    case 'ppa':
      return <PPAForm />;
    case 'huntingtonSymptoms':
      return <HuntingtonSymptomsForm />;
    case 'htt':
      return <HTTForm />;
    case 'uhdrsConfidenceIndex':
      return <UHDRS />;
    case 'seizureType':
      return <SeizureTypeForm />;
    case 'syndrome':
      return <SyndromeForm />;
    case 'etiology':
      return <EtiologyForm />;
    case 'sleepApneaFirstVisit':
      return <FirstVisitSleepApneaForm />;
    case 'respiratoryFirstVisit':
      return <FirstVisitRespiratoryForm />;
    case 'sleepApneaSeverity':
      return <SleepApneaSeverityForm />;
    case 'performanceRating':
      return <PerformanceRatingForm />;
    case 'indicationForTreatment':
      return <IndicationForTreatmentForm consumeTreatmentId={consumeTreatmentId} />;
    case 'contraIndicationsToTreatment':
      return <ContraIndicationsToTreatment consumeTreatmentId={consumeTreatmentId} />;
    case 'symptomsDetails':
      return <SymptomsDetails consumeTreatmentId={consumeTreatmentId} />;
    case 'symptomsAndLocations':
      return <SymptomsAndLocations consumeTreatmentId={consumeTreatmentId} />;
    case 'referringPhysicianAndUnit':
      return <ReferringPhysicianAndUnitForm consumeTreatmentId={consumeTreatmentId} />;
    case 'mgravisSymptoms':
      return <MgravisSymptoms />;
    default:
      return <React.Fragment />;
  }
};

const Form = withFormContext(FormComponent);

export default Form;
