import { path, find } from 'ramda';
import * as React from 'react';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import DiagnosticSurveyForm from './Form';

import { TDocument } from '../utils';
import NPIHistory from './HistoryRowData/NPI';
import FBIMODHistory from './HistoryRowData/FBIMOD';
import BNSQHistory from './HistoryRowData/BNSQ';
import { FormContextProvider } from '../../../containers/FormContextHandler';
import { InformationButton } from '../components';
import { npiFormInformation } from './Form/NPI/definitions';
import ADLHistory from './HistoryRowData/ADL';
import IADLHistory from './HistoryRowData/IADL';

const docType = (documents: Array<TDocument>, editing: string, view?: IView): string | undefined =>
  documents && editing
    ? path(
        ['_type'],
        find((d: TDocument) => d._id === editing, documents),
      ) || undefined
    : documents && view?.viewing
      ? path(
          ['_type'],
          find((d: TDocument) => d._id === view?.viewing, documents),
        ) || undefined
      : undefined;

const DiagnosticSurveyHistory = (
  documents: Array<TDocument>,
  startEdit: (document: TAnyObject) => (e: React.MouseEvent<Element, MouseEvent>) => void,
  view?: IView,
): JSX.Element => {
  const NPIDocs = documents && documents.filter((d) => d._type === 'npi');
  const FBIMODDocs = documents && documents.filter((d) => d._type === 'fbimod');
  const BNSQDocs = documents && documents.filter((d) => d._type === 'bnsq');
  const ADLDocs = documents && documents.filter((d) => d._type === 'adl');
  const IADLDocs = documents && documents.filter((d) => d._type === 'iadl');
  return (
    <React.Fragment>
      <NPIHistory documents={NPIDocs as INPI[]} startEdit={startEdit} view={view} />

      <FBIMODHistory documents={FBIMODDocs as IFBIMOD[]} startEdit={startEdit} view={view} />

      <BNSQHistory documents={BNSQDocs as IBNSQ[]} startEdit={startEdit} view={view} />

      <ADLHistory documents={ADLDocs as IADL[]} startEdit={startEdit} view={view} />

      <IADLHistory documents={IADLDocs as IIADL[]} startEdit={startEdit} view={view} />
    </React.Fragment>
  );
};

const DiagnosticSurvey = ({ documents }: IOwnProps): JSX.Element => {
  const surveyDocs = documents.filter((d) => !['diagnosis', 'mmse'].includes(d._type));
  return (
    <FormEditingHandler name="diagnosticSurvey" documents={documents}>
      {(editing, startEdit, formData, view): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name={'diagnosticSurvey'}
            headerId={
              docType(surveyDocs, editing, view) === 'npi'
                ? 'diagnosticSurvey.npi.title'
                : docType(surveyDocs, editing, view) === 'fbimod'
                  ? 'diagnosticSurvey.fbimod.title'
                  : docType(surveyDocs, editing, view) === 'bnsq'
                    ? 'diagnosticSurvey.bnsq.title'
                    : docType(surveyDocs, editing, view) === 'adl'
                      ? 'diagnosticSurvey.adl.title'
                      : docType(surveyDocs, editing, view) === 'iadl'
                        ? 'diagnosticSurvey.iadl.title'
                        : 'diagnosticSurvey.title'
            }
            info={
              docType(surveyDocs, editing, view) === 'npi' ? (
                <InformationButton info={npiFormInformation.survey} size="3rem" />
              ) : undefined
            }
            infoText={
              docType(surveyDocs, editing, view) === 'npi'
                ? undefined
                : docType(surveyDocs, editing, view) === 'fbimod'
                  ? 'diagnosticSurvey.fbimod.info'
                  : docType(surveyDocs, editing, view) === 'bnsq'
                    ? 'diagnosticSurvey.bnsq.info'
                    : docType(surveyDocs, editing, view) === 'adl'
                      ? 'diagnosticSurvey.adl.info'
                      : docType(surveyDocs, editing, view) === 'iadl'
                        ? 'diagnosticSurvey.iadl.info'
                        : undefined
            }
            editing={editing}
          />
          {editing || view?.viewing ? (
            <FormContextProvider context={{ formData, editing, view, documents }}>
              <DiagnosticSurveyForm
                document={surveyDocs && surveyDocs.find((d) => d._id === editing || d._id === view?.viewing)}
              />
            </FormContextProvider>
          ) : null}

          {!editing && !view?.viewing ? DiagnosticSurveyHistory(surveyDocs, startEdit, view) : undefined}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: Array<TDocument | IDiagnosis | IMMSE>;
}

export default DiagnosticSurvey;
