import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export const steps: (keyof IMFM)[] = [
  'holdsTheHead',
  'raisesTheHead',
  'flexesTheHip',
  'dorsiflexesTheFoot',
  'raisesTheHand',
  'raisesThePelvis',
  'turnsOverIntoProne',
  'sitsUp',
  'isCapableOfMaintainingContact',
  'leansForward',
  'standsUp',
  'sitsDown',
  'maintainsSeatedPosition',
  'fromHeadInCompleteFlexionRaisesTheHead',
  'placesBothHandsOnTopOfHead',
  'reachesThePencil',
  'picksUpAndHoldsTenCoins',
  'goesRoundTheEdgeOfTheCd',
  'picksUpPencil',
  'tearsTheSheetOfPaper',
  'picksUpTheBall',
  'raisesTheFinger',
  'placesTheTwoForearms',
  'withoutUpperLimbSupportStandsUp',
  'maintainsAStandingPosition',
  'raisesTheFoot',
  'touchesTheFloor',
  'takesTenStepsForwardOnBothHeels',
  'takesTenStepsForwardOnALine',
  'runsTenMeters',
  'hopsTenTimes',
  'managesToSquatAndGetsUpTwiceInARow',
];

const instructions = (stepName: string): JSX.Element => (
  <div style={{ fontStyle: 'italic' }}>
    <FormattedMessage id={'motor.labels.mfm.' + stepName + 'Instruction'} />
  </div>
);

export const getStepContent = (
  stepName: string,
): {
  opts: (number | string)[];
  optsLocale: string;
  info?: string;
  height?: number;
  instruction?: JSX.Element;
  instruction2?: JSX.Element;
  instruction3?: JSX.Element;
} => ({
  opts: [0, 1, 2, 3],
  optsLocale: 'opts',
  instruction: instructions(stepName),
});
