import CircularProgressLocal from 'Components/CircularProgress';
import { fm } from 'Components/FormatMessage';
import * as React from 'react';
import colors from '../../../config/theme/colors';

const SearchSpinner = ({ text }: { text: string }): JSX.Element => (
  <div
    style={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <CircularProgressLocal size={'12rem'} style={{ display: 'block', color: colors.primary, marginBottom: '2.5rem' }} />
    <span style={{ color: colors.primary, fontWeight: 600 }}>{fm(text)}</span>
  </div>
);

export default SearchSpinner;
