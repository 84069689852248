import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import QuestionStepper from '../../../../../components/QuestionStepper';

import { steps as hfmsESteps, getStepContent as getHFMSESteps } from '../hfmsESettings';
import {
  disabledSteps as hfmsEDisabledSteps,
  getDisabledStepContent as getHFMSEDisabledSteps,
} from '../hfmsEDisabledSettings';
import FormSection from '../../../../../components/FormSection';
import { useIntl } from 'react-intl';
import { hfmsEScore } from '../../../utils';
import InfoPopover from '../../../../../components/InfoPopover';
import colors from '../../../../../config/theme/colors';
import { Container } from 'Components/Grid';
import SimpleList from 'Components/SimpleList';
import { getMissingFields } from 'Utility/documentHandling';

const disabled = [
  'supportedStanding',
  'standUnsupported',
  'stepping',
  'highKneelingToStandLeadingWithLeftLeg',
  'highKeelingToStandLeadingWithRightLeg',
  'standToSit',
  'squat',
  'jump12InchForward',
  'ascendsStairsWithRail',
  'descendsStairsWithRail',
  'ascendsStairsWithoutRail',
  'descendsStairsWithoutRail',
];

const HFMSEForm = ({ formData, viewing }: IOwnProps): JSX.Element => {
  const intl = useIntl();
  const fm = (id?: string): string => (id ? intl.formatMessage({ id: id }) : '');

  const onHighestCurrentLevelOfMobilityChange = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];

    formData.onChange &&
      formData.onChange({
        [name]: value,
      });
    if (value === 'none' || value === 'rolls' || value === 'bottomShuffles' || value === 'CreepsCrawls') {
      disabled.map((item) => formData.onChange && formData.onChange({ [item]: 0 }));
    }
  };

  return (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="motor.timeTakenToComplete">
        <InputHandler
          type="NumberField"
          editing={!viewing}
          name="timeTakenToComplete"
          formData={formData}
          placeholder="motor.minutes"
          width={6}
          height={2.5}
          maxLength={3}
          fontSize={1.6}
        />
      </FormRow>
      <FormRow title="motor.highestCurrentLevelOfMobility" description={fm('motor.disableQuestions')}>
        <React.Fragment>
          <InputHandler
            type="Radio"
            editing={!viewing}
            name="highestCurrentLevelOfMobility"
            formData={{
              document: formData.document,
              onChange: onHighestCurrentLevelOfMobilityChange,
            }}
            options={[
              'none',
              'rolls',
              'bottomShuffles',
              'CreepsCrawls',
              'walksWithCrutchesFrameRollator',
              'walksWithKAFOSAFOS',
              'independentWalking',
            ]}
            optionFormatter={(name: string | number): string => (name ? fm('motor.opts.hfmsE.' + name) : '-')}
          />
        </React.Fragment>
      </FormRow>
      <FormRow title="motor.highestCurrentLevelOfMobilityInfo">
        <InputHandler type="TextArea" editing={!viewing} name="highestCurrentLevelOfMobilityInfo" formData={formData} />
      </FormRow>
      <FormSection>
        <QuestionStepper
          viewing={viewing}
          formData={formData}
          pageType={'motor'}
          docType={'hfmsE'}
          steps={
            formData.document.highestCurrentLevelOfMobility === 'none' ||
            formData.document.highestCurrentLevelOfMobility === 'rolls' ||
            formData.document.highestCurrentLevelOfMobility === 'bottomShuffles' ||
            formData.document.highestCurrentLevelOfMobility === 'CreepsCrawls'
              ? hfmsEDisabledSteps
              : hfmsESteps
          }
          getStepContent={
            formData.document.highestCurrentLevelOfMobility === 'none' ||
            formData.document.highestCurrentLevelOfMobility === 'rolls' ||
            formData.document.highestCurrentLevelOfMobility === 'bottomShuffles' ||
            formData.document.highestCurrentLevelOfMobility === 'CreepsCrawls'
              ? getHFMSEDisabledSteps
              : getHFMSESteps
          }
        />
      </FormSection>
      <FormSection>
        <FormRow
          title={
            hfmsEScore(formData.document) === formData.document.manualScore && formData.document.manualScore != null
              ? 'motor.manuallyGivenScore'
              : 'motor.automaticScore'
          }
        >
          <Container style={{ fontSize: '1.8rem', fontWeight: 600, color: colors.primary }}>
            {hfmsEScore(formData.document) === 'notCounted' ? (
              <InfoPopover
                text={
                  <SimpleList
                    title={fm('general.couldntCalculate')}
                    listItems={getMissingFields(hfmsESteps, formData.document).map((f) =>
                      fm(`motor.labels.hfmsE.${f}`),
                    )}
                  />
                }
                color="primary"
              />
            ) : (
              hfmsEScore(formData.document)
            )}
          </Container>
        </FormRow>
        <FormRow title="motor.manualScore">
          <Container style={{ fontSize: '1.8rem', fontWeight: 600, color: colors.primary }}>
            <InputHandler
              type="NumberField"
              editing={!viewing}
              name="manualScore"
              formData={formData}
              width={4}
              height={3}
              maxLength={2}
            />{' '}
          </Container>
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<IHFMSE>;
  viewing: boolean;
}

export default HFMSEForm;
