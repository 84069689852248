import { exists } from 'neuro-utils';

export const mapResult = (value: number): number => {
  switch (value) {
    default:
    case 0:
      return 0;
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
    case 4:
      return 3;
    case 5:
      return 4;
    case 6:
      return 5;
  }
};

const allNormal = (values: { [key: string]: any }): boolean => {
  return Object.keys(values)?.filter((key) => {
    return values[key] && values[key] !== 0 && values[key] !== 'notDone';
  }).length > 0
    ? false
    : true;
};

const allNotDoneOrUndefined = (values: { [key: string]: any }): boolean => {
  return Object.keys(values).every((key) => !exists(values?.[key]) || values?.[key] === 'notDone');
};

const bowelAndBladderCalculator = (bowelAndBladderFunctions: { [key: string]: any }): number | undefined => {
  const b = bowelAndBladderFunctions;

  if (allNormal(b)) {
    if (allNotDoneOrUndefined(b)) {
      return undefined;
    }
    return mapResult(0);
  }
  if (b?.urinaryRetention > 1 || b?.urinaryIncontinence > 1 || b?.bowelDysfunction > 1) {
    if (
      b?.urinaryRetention > 2 ||
      b?.urinaryIncontinence > 2 ||
      b?.bowelDysfunction > 2 ||
      b?.urinaryCatheterization > 0
    ) {
      if (b?.urinaryRetention > 3 || b?.urinaryIncontinence > 3 || b?.urinaryCatheterization > 0) {
        if (
          b?.urinaryRetention > 3 ||
          b?.urinaryIncontinence > 3 ||
          b?.bowelDysfunction > 3 ||
          b?.urinaryCatheterization > 1
        ) {
          if ((b?.urinaryIncontinence > 3 || b?.urinaryRetention > 3) && b?.bowelDysfunction > 3) {
            return mapResult(6);
          }
          return mapResult(5);
        }
        return mapResult(4);
      }
      if (b?.bowelDysfunction > 3) {
        return mapResult(5);
      }
      return mapResult(3);
    }
    return mapResult(2);
  }
  if (b?.urinaryCatheterization > 0) {
    if (b?.urinaryCatheterization > 1) {
      return mapResult(5);
    }
    return mapResult(4);
  }
  return mapResult(1);
};

export default bowelAndBladderCalculator;
