import * as React from 'react';
import { exists, formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import EventStepper from '../../../../../../components/EventStepper';
import InputHandler from '../../../../../../components/InputHandler';
import { papTreatmentResponseFields } from '../../../definitions';
import { defaultPapTreatmentResponseUnits } from '../../../config';
import {
  StepperHeaderFormInputPair,
  StepperHeaderValuePair,
} from '../../../../../../components/EventStepper/components';
import Unit from '../../../../../../components/Unit';
import { Container, Item } from '../../../../../../components/Grid';
import InformationIcon from 'Components/InfoIcon';

const TreatmentResponses = ({ formData, viewing }: IOwnProps): JSX.Element => {
  // Used to filter treatment responses by device in table view
  const [deviceFilter, setDeviceFilter] = React.useState<string | undefined>(undefined);

  const deviceMapper = (device: string): string =>
    device === 'autosetForHer'
      ? 'selfAdjustingCPAP'
      : ['selfAdjusting2PV', 'adaptiveServoVentilator', 'automaticAdaptiveServoVentilator'].includes(device)
        ? 'constantPressure2PV'
        : device;

  // All valid devices
  const devices: IPapDevice[] = (formData.document.devices || []).filter((d) => d.type && isPartialDate(d.date));

  // Devices that are relevant in table view (devices linked to a treatment response)
  const devicesToFilter =
    formData.document.devices?.filter((d) =>
      (formData.document.treatmentResponses ?? []).map((t) => t.deviceId).includes(d.id),
    ) ?? [];

  // Filtering is done by device type - treatment responses with deviceIds matching selected type will be shown
  const deviceFilterIds = devicesToFilter.filter((d) => d.type === deviceFilter).map((d) => d.id);
  const deviceTypes: Array<IPapDevice['type']> = devicesToFilter.map((d) => d.type) ?? [];

  const filterTreatmentResponseEventsByDevice = (events: IPapTreatmentResponse[]) =>
    events.filter((e: IPapTreatmentResponse) => deviceFilterIds.includes(e.deviceId ?? '')).reverse();

  const latestDevice =
    Array.isArray(formData.document.devices) &&
    formData.document.devices.length > 0 &&
    formData.document.devices.find((d) => d.type) &&
    (formData.document.devices || [])
      .filter((d) => d.type)
      .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
      .reverse()[0].id;

  // Set default device filter on mount
  React.useEffect(() => {
    const deviceTypesToFilter = (devicesToFilter ?? []).map((device) => device.type);
    devicesToFilter[0] &&
      (!deviceFilter || !deviceTypesToFilter.includes(deviceFilter as keyof (typeof deviceTypesToFilter)[0])) &&
      setDeviceFilter(devicesToFilter[0].type);
  }, [devicesToFilter]);

  type TValueTypes = string | number | PartialDate;

  const FormattedValue = ({ name, value }: { name: string; value?: TValueTypes }): JSX.Element => (
    <React.Fragment>
      {exists(value) ? (
        name === 'startDateOfPeriodUnderReview' && isPartialDate(value) ? (
          formatPartialDate(value)
        ) : name === 'targetVentilationAchievedInPlusMinus10PercentPrecision' ? (
          fm(`treatment.opts.yesNoUnknown.${value}`)
        ) : (
          <span style={{ fontWeight: 600 }}>
            {value}{' '}
            {['day', 'inhalationsPerMinute'].includes(defaultPapTreatmentResponseUnits[name])
              ? fm(`treatment.papTherapy.${defaultPapTreatmentResponseUnits[name]}`)
              : defaultPapTreatmentResponseUnits[name] ?? ''}
          </span>
        )
      ) : (
        '-'
      )}
    </React.Fragment>
  );

  return (
    <EventStepper
      name="treatmentResponses"
      formData={formData}
      stepLabelText={(d: IPapTreatmentResponse): string => formatPartialDate(d.date)}
      stepContent={(d: IPapTreatmentResponse): JSX.Element => {
        const device = d.deviceId
          ? (formData.document?.devices ?? []).find((device) => device.id === d.deviceId)?.type
          : undefined;
        return (
          <React.Fragment>
            {device && (
              <StepperHeaderValuePair
                header={fm('treatment.papTherapy.device')}
                value={fm('treatment.papTherapy.opts.' + device)}
              />
            )}
            {device &&
              Array.isArray(papTreatmentResponseFields[deviceMapper(device)]) &&
              papTreatmentResponseFields[deviceMapper(device)].map((field, index) => {
                const value = d[field.name as keyof IPapTreatmentResponse];
                return (field.condition && field.condition(d)) || !field.condition ? (
                  <StepperHeaderValuePair
                    key={`${field.name}${index}`}
                    header={fm(
                      `treatment${field.name === 'startDateOfPeriodUnderReview' ? '.' : '.papTherapy.'}${field.name}`,
                    )}
                    value={<FormattedValue name={field.name} value={value} />}
                  />
                ) : null;
              })}
          </React.Fragment>
        );
      }}
      addNewTextHeader="treatment.papTherapy.newTreatmentResponse"
      addNewTextButton="treatment.papTherapy.newTreatmentResponse"
      buttonDisabled={!latestDevice}
      buttonDisabledMessage={fm('treatment.papTherapy.addDeviceFirst')}
      previousEventsTextHeader="treatment.papTherapy.previousTreatmentResponses"
      noPreviousEventsTextHeader="treatment.papTherapy.noPreviousTreatmentResponses"
      editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
        // Type of the device selected
        const device = formData.document.devices?.find(
          (d) => d.id === formData.document.treatmentResponses?.[index]?.deviceId,
        )?.type;
        // Device ids - the treatment response is device-specific, therefore deviceId is used to link them
        const deviceIds = devices.map((d) => d.id);
        return (
          <React.Fragment>
            <StepperHeaderFormInputPair
              header={fm('treatment.papTherapy.device')}
              input={
                <InputHandler
                  type="Select"
                  editing={true}
                  name="deviceId"
                  formData={{
                    onChange,
                    document: { deviceId: formData.document.treatmentResponses?.[index]?.deviceId || '' },
                  }}
                  options={deviceIds}
                  optionFormatter={(name: React.ReactText): JSX.Element => {
                    const device = (formData.document.devices || []).find((d) => d.id === name);
                    if (!device) return <span>{''}</span>;
                    return (
                      <span>
                        {fm(`treatment.papTherapy.opts.${device?.type}`)}
                        {` - ${formatPartialDate(device?.date)}`}
                      </span>
                    );
                  }}
                />
              }
            />
            <StepperHeaderFormInputPair
              header={fm('treatment.papTherapy.treatmentResponseDate')}
              input={
                <InputHandler
                  type="PartialDate"
                  editing={true}
                  name="date"
                  formData={{
                    onChange,
                    document: { date: formData.document.treatmentResponses?.[index]?.date || '' },
                  }}
                  dateDefault="now"
                  isNotCancellable={true}
                  dateHook={{
                    dateHookFloor: formData.document.date,
                  }}
                />
              }
            />
            <StepperHeaderFormInputPair
              header={fm('treatment.startDateOfPeriodUnderReview')}
              input={
                <InputHandler
                  type="PartialDate"
                  editing={true}
                  name="startDateOfPeriodUnderReview"
                  formData={{
                    onChange,
                    document: {
                      startDateOfPeriodUnderReview:
                        formData.document.treatmentResponses?.[index]?.startDateOfPeriodUnderReview || '',
                    },
                  }}
                  dateHook={{
                    dateHookFloor: formData.document.date,
                  }}
                />
              }
            />
            {device &&
              Array.isArray(papTreatmentResponseFields[deviceMapper(device)]) &&
              papTreatmentResponseFields[deviceMapper(device)]
                .filter((f) => f.name !== 'startDateOfPeriodUnderReview')
                .map(
                  (
                    field: {
                      name: string;
                      type: 'NumberField' | 'Radio' | 'PartialDate';
                      options?: (string | number)[];
                      title?: string;
                      condition?: (formData?: IPapSetting) => boolean;
                    },
                    i: number,
                  ): JSX.Element =>
                    (field.condition && field.condition(formData.document.treatmentResponses?.[index])) ||
                    !field.condition ? (
                      <StepperHeaderFormInputPair
                        key={`${field.name}${i}`}
                        header={fm(`treatment.papTherapy.${field.title ?? field.name}`)}
                        input={
                          <div style={{ display: 'flex' }}>
                            <div>
                              <Unit
                                unit={
                                  ['day', 'inhalationsPerMinute'].includes(defaultPapTreatmentResponseUnits[field.name])
                                    ? fm(`treatment.papTherapy.${defaultPapTreatmentResponseUnits[field.name]}`)
                                    : defaultPapTreatmentResponseUnits[field.name] ?? ''
                                }
                                fontWeight={viewing ? 'bold' : 'normal'}
                              >
                                <InputHandler
                                  type={field.type}
                                  editing={true}
                                  name={field.name}
                                  formData={{
                                    onChange,
                                    document: {
                                      [field.name]: exists(
                                        formData.document.treatmentResponses?.[index]?.[
                                          field.name as keyof IPapTreatmentResponse
                                        ],
                                      )
                                        ? formData.document.treatmentResponses?.[index]?.[
                                            field.name as keyof IPapTreatmentResponse
                                          ]
                                        : '',
                                    },
                                  }}
                                  options={field.options}
                                  optionFormatter={(name: string | number): JSX.Element =>
                                    fm(`treatment.opts.yesNoUnknown.${name}`)
                                  }
                                  precision={2}
                                  width={field.type === 'NumberField' ? 7 : 12}
                                  disabled={field.name === 'targetVentilation'}
                                />
                              </Unit>
                            </div>
                            <div>
                              {field.name === 'targetVentilation' ? (
                                <InformationIcon info={fm('treatment.papTherapy.deprecatedField')} />
                              ) : null}
                            </div>
                          </div>
                        }
                      />
                    ) : (
                      <React.Fragment key={`${field.name}${i}`}></React.Fragment>
                    ),
                )}
          </React.Fragment>
        );
      }}
      viewing={viewing}
      defaultValues={{ deviceId: latestDevice }}
      tableContent={(events: IPapTreatmentResponse[]) => (
        <React.Fragment>
          {Array.isArray(papTreatmentResponseFields[deviceMapper(deviceFilter ?? '')]) &&
            papTreatmentResponseFields[deviceMapper(deviceFilter ?? '')].map((field, index, arr) => (
              <Container
                key={index}
                alignItems="baseline"
                style={{
                  marginBottom: index < arr.length - 1 ? '1rem' : '4.5rem',
                }}
              >
                <Item xs={3} style={{ paddingLeft: '2rem' }}>
                  {fm(`treatment${index > 0 ? '.papTherapy' : ''}.${field.name}`)}
                </Item>
                {events.map((e: IPapTreatmentResponse, i: number, arr) => {
                  const name = field.name as keyof typeof e;
                  const value = e[field.name as keyof IPapTreatmentResponse];

                  return (
                    <Item key={i} xs={index === 0 && i === arr.length - 1 ? 1 : 2}>
                      <FormattedValue name={name} value={value} />
                    </Item>
                  );
                })}
              </Container>
            ))}
        </React.Fragment>
      )}
      tableContentFilter={{
        input: (
          <InputHandler
            type="Select"
            editing={true}
            name="deviceFilter"
            formData={{
              onChange: (values: TOnChangeValues): void => setDeviceFilter(values['deviceFilter'] as string),
              document: { deviceFilter: deviceFilter },
            }}
            options={deviceTypes as string[]}
            optionFormatter={(name: React.ReactText): JSX.Element => fm(`treatment.papTherapy.opts.${name}`)}
            placeholder="treatment.papTherapy.device"
          />
        ),
        eventFilter: filterTreatmentResponseEventsByDevice,
      }}
    />
  );
};

interface IOwnProps {
  formData: IFormData<IPAPTherapy>;
  viewing: boolean;
  resMedTreatmentResponses?: Array<IPapTreatmentResponse>;
}

export default TreatmentResponses;
