import * as React from 'react';

import StartForm from './Start';
import MonitoringForm from './Monitoring';
import { TDocument } from '../../utils';

const InflammatoryDiseaseActivityForm = ({
  formData,
  document,
  viewing,
  relapseDocs,
  mriDocs,
}: IOwnProps): JSX.Element | null =>
  document._type === 'inflammatoryDiseaseActivityStart' ? (
    <StartForm formData={formData} viewing={viewing} doc={document} relapseDocs={relapseDocs} mriDocs={mriDocs} />
  ) : document._type === 'inflammatoryDiseaseActivityMonitoring' ? (
    <MonitoringForm formData={formData} viewing={viewing} doc={document} relapseDocs={relapseDocs} mriDocs={mriDocs} />
  ) : null;

interface IOwnProps {
  formData: IFormData<TDocument>;
  document: TDocument;
  viewing?: string;
  relapseDocs?: IRelapse[];
  mriDocs?: IMRI[];
}

export default InflammatoryDiseaseActivityForm;
