import * as React from 'react';

const svgImage = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 400 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd' as const,
      clipRule: 'evenodd' as const,
      strokeLinecap: 'round' as const,
      strokeLinejoin: 'round' as const,
      strokeMiterlimit: 1.5,
    }}
  >
    <g transform="matrix(0.406083,0,0,0.406083,-173.608,-11.8196)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-30.9749,46.6553)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,45.4111,25.0559)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-76.3709,-19.7687)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-264.199,40.6551)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-213.704,41.1941)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-204.52,-0.698752)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-193.909,60.1533)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-249.747,58.867)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-147.856,61.4352)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-108.495,-9.6741)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-63.8143,61.7773)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(-0.0266062,-0.293897,0.358731,-0.0324755,-58.3734,430.861)">
      <rect x="763.845" y="554.566" width="11.338" height="266.459" />
    </g>
    <g transform="matrix(0.382053,-0.137619,0.137619,0.382053,-198.527,-47.6816)">
      <rect x="731.179" y="863.229" width="88.328" height="10.12" />
    </g>
    <g transform="matrix(0.38385,-0.132523,0.132523,0.38385,-293.239,-43.601)">
      <rect x="731.179" y="863.229" width="88.328" height="10.12" />
    </g>
    <g transform="matrix(0.384482,-0.130678,0.130678,0.384482,-262.688,77.3108)">
      <circle cx="784.129" cy="472.301" r="48.515" style={{ fill: 'white', stroke: 'black', strokeWidth: '9.76px' }} />
    </g>
    <g transform="matrix(0.157263,-0.0534507,0.0534507,0.157263,-52.2914,120.709)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.564663,0.422885,-0.213265,0.284765,-144.371,-285.377)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.567873,0,0,0.0959804,-50.6358,163.616)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.646688,-0.294397,0.0397671,0.0873545,-238.94,416.096)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.889399,-0.537137,0.0449278,0.0743922,-550.158,513.892)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.723555,-0.128007,0.0619783,0.350331,-448.389,80.2278)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.541527,0,0,0.540974,-264.99,-137.946)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.857159,0,0,0.564626,-353.967,-153.11)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(-0.429097,0.742023,-0.470764,-0.272234,800.232,-65.0064)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.82565,-0.230271,0.143136,0.513224,-319.399,7.06713)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.541527,0,0,0.572707,-279.523,-179.147)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.475524,0.713161,-0.436109,0.29079,262.877,-466.312)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
  </svg>
);

export default svgImage;
