import * as React from 'react';
import FormRow from '../../../../../../components/FormRow';
import FormSection from '../../../../../../components/FormSection';
import InputHandler from '../../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../../containers/FormContextHandler';
import { optionFormatter } from '../../../../utils/functions';
import { calculateIntervalYMD, isPartialDate } from 'neuro-utils';
import { useAppSelector as useSelector } from 'Store/index';

const MgravisSymptomsForm = ({ editing, formData }: IMsSymptomsFormProps): JSX.Element => {
  const birthDate = useSelector((s: IState) => s.patient.data?.dateOfBirth);
  const onChangeDate = (values: TOnChangeValues) => {
    const date = values['date'] as PartialDate;
    const age = isPartialDate(birthDate) && isPartialDate(date) ? calculateIntervalYMD(birthDate, date) : undefined;
    if (isPartialDate(date)) {
      formData.onChange?.({ date, age: age ? age.years : undefined });
    }
  };

  return (
    <FormSection>
      <div style={{ marginBottom: '5rem' }} />
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!!editing}
          name="date"
          formData={{ document: { date: formData.document.date }, onChange: onChangeDate }}
          dateDefault="now"
        />
      </FormRow>
      <FormRow title="diagnosis.mgravis.symptoms.age">
        <InputHandler type="TextField" editing={false} name="age" formData={formData} />
      </FormRow>
      <FormRow title="diagnosis.mgravis.symptoms.title">
        <InputHandler
          type="Checkbox"
          editing={!!editing}
          name="symptomLocation"
          formData={formData}
          options={['eyes', 'limbs', 'bulbar region']}
          optionFormatter={optionFormatter('mgravis.symptoms')}
        />
      </FormRow>
    </FormSection>
  );
};

type IMsSymptomsFormProps = IFormContext;

export default withFormContext(MgravisSymptomsForm);
