import { exists } from 'neuro-utils';
import { resolvePath } from '../utils';
import calculateScore from './calculateScore';
import { mapResult as mapBowelBladderScore } from './Calculators/bowelAndBladderCalculator';
import { mapResult as mapVisualScore } from './Calculators/visualCalculator';

export const resolveSubScore = (key: string, document: { [key: string]: any }) => {
  if (key === 'ambulatoryScore') return null;
  else if (key === 'pyramidalFunctions') {
    return calculateScore('pyramidalFunctions', {
      ambulatoryScore: resolvePath('ambulatoryScore', document),
      pyramidalFunctions: resolvePath('pyramidalFunctions', document) ?? {},
    });
  } else {
    return calculateScore(key, resolvePath(key, document));
  }
};

const calculateEdss = (document: INeurologicalStatusAndEDSS): number => {
  const convertAmbulatoryToEdss = (): number => {
    const ambulatoryScore = document?.ambulatoryScore
      ? document?.ambulatoryScore.toString().length === 0
        ? undefined
        : document?.ambulatoryScore
      : undefined;
    const scores = [0.0, 2.0, 4.5, 5.0, 5.5, 6.0, 6.0, 6.0, 6.5, 6.5, 7.0, 7.5, 8.0, 8.5, 9.0, 9.5];
    return scores[ambulatoryScore ?? 0];
  };

  const scores = Object.keys(document)
    .filter((key) => {
      return !['date', 'ambulatoryScore', 'edssStep', 'calculatedEdssStep'].includes(key);
    })
    .map((key) =>
      exists(resolvePath('score', (document as { [key: string]: any })[key]))
        ? key === 'bowelAndBladderFunctions'
          ? mapBowelBladderScore(resolvePath('score', (document as { [key: string]: any })[key]))
          : key === 'visualAcuity'
            ? mapVisualScore(resolvePath('score', (document as { [key: string]: any })[key]))
            : resolvePath('score', (document as { [key: string]: any })[key])
        : resolveSubScore(key, document),
    );

  const convertScoresToEdss = (): number => {
    if (
      scores.length === 0 ||
      scores.reduce((a, b) => a + b) === 0 ||
      scores.every((value) => {
        return !value;
      })
    ) {
      return 0;
    }

    const scoreCountOf = (value: number): number => {
      return scores.filter((score) => score === value).length;
    };

    const maxScore = (max: number): boolean => {
      return !scores.some((score) => score > max);
    };

    if (scoreCountOf(1) === 1 && maxScore(1)) {
      return 1;
    }
    if (scoreCountOf(1) > 1 && maxScore(1)) {
      return 1.5;
    }
    if (scoreCountOf(2) === 1 && maxScore(2)) {
      return 2;
    }
    if (scoreCountOf(2) > 1 && maxScore(2)) {
      let score = 2;
      [2, 3, 5, 6].forEach((count) => {
        if (scoreCountOf(2) >= count) {
          score += 0.5;
        }
      });
      return score;
    }
    if (scoreCountOf(3) >= 1 && maxScore(3)) {
      let score = 3;
      if (scoreCountOf(3) === 1) {
        [1, 5].forEach((count) => {
          if (scoreCountOf(2) >= count) {
            score += 0.5;
          }
        });
        return score;
      }
      [2, 3, 5, 6].forEach((count) => {
        if (scoreCountOf(3) >= count) {
          score += 0.5;
        }
      });
      if (score === 3.5) {
        if (scoreCountOf(2) >= 4) {
          score += 0.5;
        }
      }
      return score;
    }
    if (scoreCountOf(4) >= 1 && maxScore(4)) {
      if (scoreCountOf(4) === 1 && scoreCountOf(3) > 0) {
        if (scoreCountOf(3) > 4) {
          return 5.0;
        }
        return 4.5;
      }
      if (scoreCountOf(4) > 1) {
        return 5.0;
      }
      return 4.0;
    }
    if (scoreCountOf(5) > 0 && maxScore(5)) {
      return 5.0;
    }
    if (scoreCountOf(6) > 0) {
      return 6.0;
    }
    return 0;
  };

  return Math.max(convertAmbulatoryToEdss(), convertScoresToEdss()) ?? 0;
};

export default calculateEdss;
