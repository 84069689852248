/**
 * Return answer options of selected fields of document
 * that meets the number given as parameter.
 * @param d: ICBD
 * @param n: number
 * @param fields: Array<string>
 */
const numberOf = (d: ICBD, n: number, fields: string[]): 'yes' | 'no' | 'unknown' => {
  const yes = fields.filter((f) => (d as { [key: string]: any })?.[f] === 'yes');
  const no = fields.filter((f) => (d as { [key: string]: any })?.[f] === 'no');

  if (yes.length >= n) return 'yes';
  if (no.length >= n) return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän edellä olevista vastauksista (päivitetty 23.03.2021).
 * - "Kyllä" vastaus edellyttää, että  kaksi kohdista a), b) ja c) ovat "Kyllä" statuksella ja kaksi kohdista d), e) ja f) ovat "Kyllä" statuksella
 * - "Ei" vastaus edellyttää, että  kaksi kohdista a), b) ja c) ovat "Ei" statuksella tai kaksi kohdista d), e) ja f) ovat "Ei" statuksella
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param d: ICBD
 */
export const probableCBSRequirementsMet = (d: ICBD): 'yes' | 'no' | 'unknown' => {
  const fieldsABC = ['limbRigidityOrAkinesia', 'limbDystonia', 'limbMyclonus'];
  const fieldsDEF = ['orobuccalOrLimbApraxia', 'corticalSensoryDeficit', 'alienLimbPhenomena'];

  if (numberOf(d, 2, fieldsABC) === 'yes' && numberOf(d, 2, fieldsDEF) === 'yes') return 'yes';
  if (numberOf(d, 2, fieldsABC) === 'no' || numberOf(d, 2, fieldsDEF) === 'no') return 'no';
  return 'unknown';
};

/**
 * Ohjelma päättelee tämän edellä olevista vastauksista (päivitetty 23.03.2021).
 * - "Kyllä" vastaus edellyttää, että  yksi kohdista a), b) ja c) on "Kyllä" statuksella ja yksi kohdista d), e) ja f) on "Kyllä" statuksella
 * - "Ei" vastaus edellyttää, että  kolme kohdista a), b) ja c) ovat "Ei" statuksella tai kolme kohdista d), e) ja f) ovat "Ei" statuksella
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param d: ICBD
 */
export const possibleCBSRequirementsMet = (d: ICBD): 'yes' | 'no' | 'unknown' => {
  const fieldsABC = ['limbRigidityOrAkinesia', 'limbDystonia', 'limbMyclonus'];
  const fieldsDEF = ['orobuccalOrLimbApraxia', 'corticalSensoryDeficit', 'alienLimbPhenomena'];

  if (numberOf(d, 1, fieldsABC) === 'yes' && numberOf(d, 1, fieldsDEF) === 'yes') return 'yes';
  if (numberOf(d, 3, fieldsABC) === 'no' || numberOf(d, 3, fieldsDEF) === 'no') return 'no';
  return 'unknown';
};

/**
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että  a) ja b), tai a) ja )c, tai b) ja c) ovat "Kyllä" statuksella.
 * - "Ei" vastaus edellyttää, että a) ja b), tai a) ja )c, tai b) ja c) ovat "Ei" statuksella.
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param d: ICBD
 */
export const FBSRequirementsAreMet = (d: ICBD): 'yes' | 'no' | 'unknown' => {
  const subFields = ['FBSExecutiveDysfunction', 'FBSBehavioralOrPersonalityChanges', 'FBSVisuospatialDeficits'];

  if (numberOf(d, 2, subFields) === 'yes') return 'yes';
  if (numberOf(d, 2, subFields) === 'no') return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että "Effortful, agrammatic speech" ja a), tai "Effortful, agrammatic speech" ja b) ovat "Kyllä" statuksella.
 * - "Ei" vastaus edellyttää, että "Effortful, agrammatic speech",  tai a)  ja b) ovat "Ei" statuksella.
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param d: ICBD
 */
export const NAVRequirementsAreMet = (d: ICBD): 'yes' | 'no' | 'unknown' => {
  const subFields = ['NAVImpairedGrammarSentenceComprehension', 'NAVGropingDistortedSpeechProduction'];

  if (d.NAVEffortfulAgrammaticSpeech === 'yes' && numberOf(d, 1, subFields) === 'yes') return 'yes';
  if (d.NAVEffortfulAgrammaticSpeech === 'no' || numberOf(d, 2, subFields) === 'no') return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän edellä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että  kolme kohdista a), b), c), d) ja e) ovat  "Kyllä" statuksella
 * - "Ei" vastaus edellyttää, että kolme kohdista a), b), c), d) ja e) ovat  "Ei" statuksella
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param d: ICBD
 */
export const PSPSRequirementsAreMet = (d: ICBD): 'yes' | 'no' | 'unknown' => {
  const subFields = [
    'PSPSAxialOrSymmetricLimbRigidityOrAkinesia',
    'PSPSPosturalInstabilityOrFails',
    'PSPSUrinaryIncontinence',
    'PSPSBehavioralChanges',
    'PSPSSupranuclearVerticalGazePalsyOrDecreasedVelocityOrVerticalSaccades',
  ];

  if (numberOf(d, 3, subFields) === 'yes') return 'yes';
  if (numberOf(d, 3, subFields) === 'no') return 'no';

  return 'unknown';
};

/**
 * FBS or NAV plus at least one CBS feature (a-f)
 * @param d: ICBD
 */
export const FBSorNAVandOneCBS = (d: ICBD): 'yes' | 'no' | 'unknown' => {
  const CBSFields = [
    'limbRigidityOrAkinesia',
    'limbDystonia',
    'limbMyclonus',
    'orobuccalOrLimbApraxia',
    'corticalSensoryDeficit',
    'alienLimbPhenomena',
  ];

  if ((NAVRequirementsAreMet(d) === 'yes' || FBSRequirementsAreMet(d) === 'yes') && numberOf(d, 1, CBSFields) === 'yes')
    return 'yes';

  if ((NAVRequirementsAreMet(d) === 'no' && FBSRequirementsAreMet(d) === 'no') || numberOf(d, 6, CBSFields) === 'no')
    return 'no';

  return 'unknown';
};

/**
 * FBS or NAV
 * @param d: ICBD
 */
export const FBSorNAV = (d: ICBD): 'yes' | 'no' | 'unknown' => {
  if (NAVRequirementsAreMet(d) === 'yes' || FBSRequirementsAreMet(d) === 'yes') return 'yes';

  if (NAVRequirementsAreMet(d) === 'no' && FBSRequirementsAreMet(d) === 'no') return 'no';

  return 'unknown';
};

/**
 * PSPS plus at least one CBS feature (a-f)
 * @param d: ICBD
 */
export const PSPSandOneCBS = (d: ICBD): 'yes' | 'no' | 'unknown' => {
  const CBSFields = [
    'limbRigidityOrAkinesia',
    'limbDystonia',
    'limbMyclonus',
    'orobuccalOrLimbApraxia',
    'corticalSensoryDeficit',
    'alienLimbPhenomena',
  ];

  if (PSPSRequirementsAreMet(d) === 'yes' && numberOf(d, 1, CBSFields) === 'yes') return 'yes';

  if (PSPSRequirementsAreMet(d) === 'no' || numberOf(d, 6, CBSFields) === 'no') return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän yllä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että
 *    - "Insidious onset and gradual progression" on "Kyllä", ja
 *    - "Minimum duration of symptoms; 1 year" on "Kyllä", ja
 *    - "Age at onset: ≥ 50 year" on "Kyllä", ja
 *    - "Family history (2 or more relatives)" on "Ei", ja
 *    - "Probable CBS requirements are met" on "Kyllä" tai {["FBS requirements are met" tai "NAV requirements are met" on "Kyllä"] ja yksi CBS feature a-f on "Kyllä"}, ja
 *    - "Genetic mutation affecting tau (e.g. MAPT)" on "Ei".
 * - "Ei" vastaus edellyttää, että
 *    - "Insidious onset and gradual progression" on "Ei", tai
 *    - "Minimum duration of symptoms; 1 year" on "Ei", tai
 *    - "Age at onset: ≥ 50 year" on "EI", tai
 *    - "Family history (2 or more relatives)" on "Kyllä", tai
 *    - "Probable CBS requirements are met" on "Ei" ja {["FBS requirements are met" ja "NAV requirements are met" on "Ei"] tai kaikki CBS featuret a-f on "Ei"}, tai
 *    - "Genetic mutation affecting tau (e.g. MAPT)" on "Kyllä".
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param d: ICBD
 */
export const ProbableCBDCanBeDiagnosed = (d: ICBD): 'yes' | 'no' | 'unknown' => {
  const CBSFields = [
    'limbRigidityOrAkinesia',
    'limbDystonia',
    'limbMyclonus',
    'orobuccalOrLimbApraxia',
    'corticalSensoryDeficit',
    'alienLimbPhenomena',
  ];

  if (
    d.presentationInsidiousOnsetAndGradualProgression === 'yes' &&
    d.minimumDurationOfSymptoms1Year === 'yes' &&
    d.probableAgeAtOnset50Year === 'yes' &&
    d.probableFamilyHistory === 'no' &&
    (probableCBSRequirementsMet(d) === 'yes' ||
      ((FBSRequirementsAreMet(d) === 'yes' || NAVRequirementsAreMet(d) === 'yes') &&
        numberOf(d, 1, CBSFields) === 'yes')) &&
    d.probableGeneticMutationAffectingTau === 'no'
  )
    return 'yes';

  if (
    d.presentationInsidiousOnsetAndGradualProgression === 'no' ||
    d.minimumDurationOfSymptoms1Year === 'no' ||
    d.probableAgeAtOnset50Year === 'no' ||
    d.probableFamilyHistory === 'yes' ||
    (probableCBSRequirementsMet(d) === 'no' &&
      ((FBSRequirementsAreMet(d) === 'no' && NAVRequirementsAreMet(d) === 'no') ||
        numberOf(d, 6, CBSFields) === 'no')) ||
    d.probableGeneticMutationAffectingTau === 'yes'
  )
    return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän yllä olevista vastauksista.
 * - "Kyllä" vastaus edellyttää, että
 *    - "Insidious onset and gradual progression" on "Kyllä", ja
 *    - "Minimum duration of symptoms; 1 year" on "Kyllä", ja
 *    - "Possible CBS requirements are met" on "Kyllä" tai ["FBS requirements are met" tai "NAV requirements are met" on "Kyllä"] tai  [PSPS requirements are met" on "Kyllä" ja vähintään yksi CBS feature a-f on "Kyllä"].
 * - "Ei" vastaus edellyttää, että
 *    - "Insidious onset and gradual progression" on "Ei", tai
 *    - "Minimum duration of symptoms; 1 year" on "Ei", tai
 *    - "Possible CBS requirements are met" on "EI" ja ["FBS requirements are met" ja "NAV requirements are met" on "Ei"] ja  [PSPS requirements are met" on "Ei" tai kaikki CBS featuret a-f on "EI"].
 * - Jos "Kyllä" tai "Ei" vastausten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param d: ICBD
 */
export const PossibleCBDCanBeDiagnosed = (d: ICBD): 'yes' | 'no' | 'unknown' => {
  const CBSFields = [
    'limbRigidityOrAkinesia',
    'limbDystonia',
    'limbMyclonus',
    'orobuccalOrLimbApraxia',
    'corticalSensoryDeficit',
    'alienLimbPhenomena',
  ];

  if (
    d.presentationInsidiousOnsetAndGradualProgression === 'yes' &&
    d.minimumDurationOfSymptoms1Year === 'yes' &&
    (possibleCBSRequirementsMet(d) === 'yes' ||
      FBSRequirementsAreMet(d) === 'yes' ||
      NAVRequirementsAreMet(d) === 'yes' ||
      (PSPSRequirementsAreMet(d) === 'yes' && numberOf(d, 1, CBSFields) === 'yes'))
  )
    return 'yes';

  if (
    d.presentationInsidiousOnsetAndGradualProgression === 'no' ||
    d.minimumDurationOfSymptoms1Year === 'no' ||
    (possibleCBSRequirementsMet(d) === 'no' &&
      FBSRequirementsAreMet(d) === 'no' &&
      NAVRequirementsAreMet(d) === 'no' &&
      (PSPSRequirementsAreMet(d) === 'no' || numberOf(d, 6, CBSFields) === 'no'))
  )
    return 'no';

  return 'unknown';
};
