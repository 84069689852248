import { path } from 'Utility/ramdaReplacement';
import { PPAFormContent } from '../definitions';

/**
 * Return answer count of selected fields of document
 * that meets the answer given as parameter.
 * @param {{ [key: string]: string }} section - PPA section to check
 * @param {string[]} fields - Fields to check
 * @param {string} answer - Answers to count
 * @returns {number} - Answer count
 */
const answerCount = (section: { [key: string]: string }, fields: string[], answer: string): number => {
  const answers = fields.filter((f) => (section as { [key: string]: string })?.[f] === answer) ?? [];
  return answers.length;
};

/**
 * Ohjelma päättelee tämän seuraavasti
 * - "Kyllä" tulos edellyttää, että 3/3 kohdan I kriteereistä on "Kyllä"
 * - "Ei" tulos edellyttää, että  vähintään 1/3 kohdan I kriteereistä on "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param {IPPA} document - PPA document to be checked
 * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const charactersticsOfSectionIArePresentInTheSyndrome = (document: IPPA): 'yes' | 'no' | 'unknown' => {
  const d = Object.assign({}, document);
  const criteria = path(['general', 'theFollowingThreeCharacteristicsShouldBePresentInTheSyndrome'], d) as {
    [key: string]: string;
  };
  const section = (PPAFormContent.general.theFollowingThreeCharacteristicsShouldBePresentInTheSyndrome.criteria ??
    []) as string[];

  if (criteria && answerCount(criteria, section, 'yes') >= 3) return 'yes';

  if (criteria && answerCount(criteria, section, 'no') >= 1) return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän seuraavasti
 * - "Kyllä" tulos edellyttää, että  4/4 kohdan II kriteereistä on "Ei"
 * - "Ei" tulos edellyttää, että  vähintään 1/4 kohdan II kriteereistä on "Kyllä"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param {IPPA} document - PPA document to be checked
 * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const charactersticsOfSectionIIAreNotPresentInTheSyndrome = (document: IPPA): 'yes' | 'no' | 'unknown' => {
  const d = JSON.parse(JSON.stringify(document));
  const criteria = path(['general', 'theFollowingFourCharacteristicsShouldNotBePresentInTheSyndrome'], d) as {
    [key: string]: string;
  };
  const section = (PPAFormContent.general.theFollowingFourCharacteristicsShouldNotBePresentInTheSyndrome.criteria ??
    []) as string[];

  if (criteria && answerCount(criteria, section, 'no') >= 4) return 'yes';

  if (criteria && answerCount(criteria, section, 'yes') >= 1) return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän seuraavasti
 * - "Kyllä" tulos edellyttää, että  "Kohdan I piirteet esiintyvät oireistossa" (rivi 25)  ja "Kohdan II piirteet eivät esiinny oireistossa" (rivi 42) ovat "Kyllä"
 * - "Ei" tulos edellyttää, että  "Kohdan I piirteet esiintyvät oireistossa" (rivi 25) on "Kyllä" tai "Kohdan II piirteet eivät esiinny oireistossa" (rivi 42) on "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param {IPPA} document - PPA document to be checked
 * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const generalCriteriaFulfilled = (document: IPPA): 'yes' | 'no' | 'unknown' => {
  const d = JSON.parse(JSON.stringify(document));
  const charactersticsOfSectionIArePresent = charactersticsOfSectionIArePresentInTheSyndrome(d);
  const charactersticsOfSectionIIAreNotPresent = charactersticsOfSectionIIAreNotPresentInTheSyndrome(d);

  if (charactersticsOfSectionIArePresent === 'yes' && charactersticsOfSectionIIAreNotPresent === 'yes') return 'yes';

  if (charactersticsOfSectionIArePresent === 'no' || charactersticsOfSectionIIAreNotPresent === 'no') return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän seuraavasti
 * - "Kyllä" tulos edellyttää, että kohdan I kriteereistä vähintään 1) tai 2) on "Kyllä" ja että kriteereistä 3), 4) ja 5) vähintään kaksi on "Kyllä"
 * - "Ei" tulos edellyttää, että  kohdan I kriteereistä 2/2  on "Ei" tai että kriteereistä 3), 4) ja 5) vähintään kaksi on "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param {IPPA} document - PPA document to be checked
 * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const criteriaForClinicalDiagnosisForNfvPPAFulfilled = (document: IPPA): 'yes' | 'no' | 'unknown' => {
  const d = JSON.parse(JSON.stringify(document));
  const criteria = path(['nonfluentVariantPPA', 'clinicalDiagnosis'], d) as { [key: string]: string };
  const section = (PPAFormContent.nonfluentVariantPPA.clinicalDiagnosis.criteria ?? []) as string[];
  const section2 = (PPAFormContent.nonfluentVariantPPA.clinicalDiagnosis.criteria2 ?? []) as string[];

  if (criteria && answerCount(criteria, section, 'yes') >= 1 && answerCount(criteria, section2, 'yes') >= 2)
    return 'yes';

  if (criteria && (answerCount(criteria, section, 'no') >= 2 || answerCount(criteria, section2, 'no') >= 2))
    return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän seuraavasti
 * - "Kyllä" tulos edellyttää, että 1) on "Kyllä" ja että 2a) tai 2b) on "Kyllä"
 * - "Ei" tulos edellyttää, että  1) on "Ei" tai että 2a) ja 2b) ovat "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param {IPPA} document - PPA document to be checked
 * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const criteriaForDiagnosisSupportedByImagingStudiesForNfvPPAFulfilled = (
  document: IPPA,
): 'yes' | 'no' | 'unknown' => {
  const d = JSON.parse(JSON.stringify(document));
  const criteria = (path(['nonfluentVariantPPA', 'diagnosisSupportedByImagingStudies'], d) ?? {}) as {
    [key: string]: string;
  };

  criteria.criteriaForClinicalDiagnosisForNfvPPAFulfilled =
    path(
      ['nonfluentVariantPPA', 'diagnosisSupportedByImagingStudies', 'criteriaForClinicalDiagnosisForNfvPPAFulfilled'],
      d,
    ) ?? criteriaForClinicalDiagnosisForNfvPPAFulfilled(d);

  const section = (PPAFormContent.nonfluentVariantPPA.diagnosisSupportedByImagingStudies.criteria ?? []) as string[];
  const section2 = (PPAFormContent.nonfluentVariantPPA.diagnosisSupportedByImagingStudies.criteria2 ?? []) as string[];

  if (criteria && answerCount(criteria, section, 'yes') >= 1 && answerCount(criteria, section2, 'yes') >= 1)
    return 'yes';

  if (criteria && (answerCount(criteria, section, 'no') >= 1 || answerCount(criteria, section2, 'no') >= 2))
    return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän seuraavasti
 * - "Kyllä" tulos edellyttää, että 1) on "Kyllä" ja että 2) tai 3) on "Kyllä"
 * - "Ei" tulos edellyttää, että  1) on "Ei" tai että 2) ja 3) ovat "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param {IPPA} document - PPA document to be checked
 * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const criteriaForNeuropathologicallyConfirmedDiagnosisForNfvPPAFulfilled = (
  document: IPPA,
): 'yes' | 'no' | 'unknown' => {
  const d = JSON.parse(JSON.stringify(document));
  const criteria = (path(['nonfluentVariantPPA', 'neuropathologicallyConfirmedDiagnosis'], d) ?? {}) as {
    [key: string]: string;
  };

  criteria.criteriaForClinicalDiagnosisForNfvPPAFulfilled =
    path(
      [
        'nonfluentVariantPPA',
        'neuropathologicallyConfirmedDiagnosis',
        'criteriaForClinicalDiagnosisForNfvPPAFulfilled',
      ],
      d,
    ) ?? criteriaForClinicalDiagnosisForNfvPPAFulfilled(d);

  const section = (PPAFormContent.nonfluentVariantPPA.neuropathologicallyConfirmedDiagnosis.criteria ?? []) as string[];

  if (
    criteria &&
    answerCount(criteria, [section[0]], 'yes') >= 1 &&
    answerCount(criteria, section.slice(1), 'yes') >= 1
  )
    return 'yes';

  if (
    criteria &&
    (answerCount(criteria, [section[0]], 'no') >= 1 || answerCount(criteria, section.slice(1), 'no') >= 2)
  )
    return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän seuraavasti
 * - "Kyllä" tulos edellyttää, että 1) ja 2) ovat "Kyllä" ja että kohdista 3), 4), 5) ja 6) vähintään 3/4 ovat "Kyllä"
 * - "Ei" tulos edellyttää, että 1) TAI* 2) ovat "Ei" tai että kohdista 3), 4), 5) ja 6) vähintään 2/4 ovat "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 *
 *  (* = vaatimus korjattu ja -> tai )
 * @param {IPPA} document - PPA document to be checked
 * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const criteriaForClinicalDiagnosisForSemanticDementiaFulfilled = (document: IPPA): 'yes' | 'no' | 'unknown' => {
  const d = JSON.parse(JSON.stringify(document));
  const criteria = path(['semanticDementia', 'clinicalDiagnosis'], d) as { [key: string]: string };
  const section = (PPAFormContent.semanticDementia.clinicalDiagnosis.criteria ?? []) as string[];
  const section2 = (PPAFormContent.semanticDementia.clinicalDiagnosis.criteria2 ?? []) as string[];

  if (criteria && answerCount(criteria, section, 'yes') >= 2 && answerCount(criteria, section2, 'yes') >= 3)
    return 'yes';

  if (criteria && (answerCount(criteria, section, 'no') >= 1 || answerCount(criteria, section2, 'no') >= 2))
    return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän seuraavasti
 * - "Kyllä" tulos edellyttää, että 1) on "Kyllä" ja että 2a) tai 2b) on "Kyllä"
 * - "Ei" tulos edellyttää, että  1) on "Ei" tai että 2a) ja 2b) ovat "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param {IPPA} document - PPA document to be checked
 * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const criteriaForDiagnosisSupportedByImagingStudiesForSemanticDementiaFulfilled = (
  document: IPPA,
): 'yes' | 'no' | 'unknown' => {
  const d = JSON.parse(JSON.stringify(document));
  const criteria = (path(['semanticDementia', 'diagnosisSupportedByImagingStudies'], d) ?? {}) as {
    [key: string]: string;
  };

  criteria.criteriaForClinicalDiagnosisForSemanticDementiaFulfilled =
    path(
      [
        'semanticDementia',
        'diagnosisSupportedByImagingStudies',
        'criteriaForClinicalDiagnosisForSemanticDementiaFulfilled',
      ],
      d,
    ) ?? criteriaForClinicalDiagnosisForSemanticDementiaFulfilled(d);

  const section = (PPAFormContent.semanticDementia.diagnosisSupportedByImagingStudies.criteria ?? []) as string[];
  const section2 = (PPAFormContent.semanticDementia.diagnosisSupportedByImagingStudies.criteria2 ?? []) as string[];

  if (criteria && answerCount(criteria, section, 'yes') >= 1 && answerCount(criteria, section2, 'yes') >= 1)
    return 'yes';

  if (criteria && (answerCount(criteria, section, 'no') >= 1 || answerCount(criteria, section2, 'no') >= 2))
    return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän seuraavasti
 * - "Kyllä" tulos edellyttää, että 1) on "Kyllä" ja että 2) tai 3) on "Kyllä"
 * - "Ei" tulos edellyttää, että  1) on "Ei" tai että 2) ja 3) ovat "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param {IPPA} document - PPA document to be checked
 * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const criteriaForNeuropathologicallyConfirmedDiagnosisForSemanticDementiaFulfilled = (
  document: IPPA,
): 'yes' | 'no' | 'unknown' => {
  const d = JSON.parse(JSON.stringify(document));
  const criteria = (path(['semanticDementia', 'neuropathologicallyConfirmedDiagnosis'], d) ?? {}) as {
    [key: string]: string;
  };

  criteria.criteriaForClinicalDiagnosisForSemanticDementiaFulfilled =
    path(
      [
        'semanticDementia',
        'neuropathologicallyConfirmedDiagnosis',
        'criteriaForClinicalDiagnosisForSemanticDementiaFulfilled',
      ],
      d,
    ) ?? criteriaForClinicalDiagnosisForSemanticDementiaFulfilled(d);

  const section = (PPAFormContent.semanticDementia.neuropathologicallyConfirmedDiagnosis.criteria ?? []) as string[];

  if (
    criteria &&
    answerCount(criteria, [section[0]], 'yes') >= 1 &&
    answerCount(criteria, section.slice(1), 'yes') >= 1
  )
    return 'yes';

  if (
    criteria &&
    (answerCount(criteria, [section[0]], 'no') >= 1 || answerCount(criteria, section.slice(1), 'no') >= 2)
  )
    return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän seuraavasti
 * - "Kyllä" tulos edellyttää, että 1)  ja 2) ovat "Kyllä" ja että kohdista 3), 4), 5) ja 6) vähintään 3/4 ovat "Kyllä"
 * - "Ei" tulos edellyttää, että 1) TAI* 2) ovat "Ei" tai että kohdista 3), 4), 5) ja 6) vähintään 2/4 ovat "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 *
 *  (* = vaatimusta päivitetty "ja" -> "tai")
 * @param {IPPA} document - PPA document to be checked
 * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const criteriaForClinicalDiagnosisForLogopenicProgressiveAphasiaFulfilled = (
  document: IPPA,
): 'yes' | 'no' | 'unknown' => {
  const d = JSON.parse(JSON.stringify(document));
  const criteria = path(['logopenicProgressiveAphasia', 'clinicalDiagnosis'], d) as { [key: string]: string };
  const section = (PPAFormContent.logopenicProgressiveAphasia.clinicalDiagnosis.criteria ?? []) as string[];
  const section2 = (PPAFormContent.logopenicProgressiveAphasia.clinicalDiagnosis.criteria2 ?? []) as string[];

  if (criteria && answerCount(criteria, section, 'yes') >= 2 && answerCount(criteria, section2, 'yes') >= 3)
    return 'yes';

  if (criteria && (answerCount(criteria, section, 'no') >= 1 || answerCount(criteria, section2, 'no') >= 2))
    return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän seuraavasti
 * - "Kyllä" tulos edellyttää, että 1) on "Kyllä" ja että 2a) tai 2b) on "Kyllä"
 * - "Ei" tulos edellyttää, että  1) on "Ei" tai että 2a) ja 2b) ovat "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param {IPPA} document - PPA document to be checked
 * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const criteriaForDiagnosisSupportedByImagingStudiesForLogopenicProgressiveAphasiaFulfilled = (
  document: IPPA,
): 'yes' | 'no' | 'unknown' => {
  const d = JSON.parse(JSON.stringify(document));
  const criteria = (path(['logopenicProgressiveAphasia', 'diagnosisSupportedByImagingStudies'], d) ?? {}) as {
    [key: string]: string;
  };

  criteria.criteriaForClinicalDiagnosisForLogopenicProgressiveAphasiaFulfilled =
    path(
      [
        'logopenicProgressiveAphasia',
        'diagnosisSupportedByImagingStudies',
        'criteriaForClinicalDiagnosisForLogopenicProgressiveAphasiaFulfilled',
      ],
      d,
    ) ?? criteriaForClinicalDiagnosisForLogopenicProgressiveAphasiaFulfilled(d);

  const section = (PPAFormContent.logopenicProgressiveAphasia.diagnosisSupportedByImagingStudies.criteria ??
    []) as string[];
  const section2 = (PPAFormContent.logopenicProgressiveAphasia.diagnosisSupportedByImagingStudies.criteria2 ??
    []) as string[];

  if (criteria && answerCount(criteria, section, 'yes') >= 1 && answerCount(criteria, section2, 'yes') >= 1)
    return 'yes';

  if (criteria && (answerCount(criteria, section, 'no') >= 1 || answerCount(criteria, section2, 'no') >= 2))
    return 'no';

  return 'unknown';
};

/**
 * Ohjelma päättelee tämän seuraavasti
 * - "Kyllä" tulos edellyttää, että 1) on "Kyllä" ja että 2) tai 3) on "Kyllä"
 * - "Ei" tulos edellyttää, että  1) on "Ei" tai että 2) ja 3) ovat "Ei"
 * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
 * @param {IPPA} document - PPA document to be checked
 * @returns {'yes' | 'no' | 'unknown'} - Result based on values in the document
 */
export const criteriaForNeuropathologicallyConfirmedDiagnosisForLogopenicProgressiveAphasiaFulfilled = (
  document: IPPA,
): 'yes' | 'no' | 'unknown' => {
  const d = JSON.parse(JSON.stringify(document));
  const criteria = (path(['logopenicProgressiveAphasia', 'neuropathologicallyConfirmedDiagnosis'], d) ?? {}) as {
    [key: string]: string;
  };

  criteria.criteriaForClinicalDiagnosisForLogopenicProgressiveAphasiaFulfilled =
    path(
      [
        'logopenicProgressiveAphasia',
        'neuropathologicallyConfirmedDiagnosis',
        'criteriaForClinicalDiagnosisForLogopenicProgressiveAphasiaFulfilled',
      ],
      d,
    ) ?? criteriaForClinicalDiagnosisForLogopenicProgressiveAphasiaFulfilled(d);

  const section = (PPAFormContent.logopenicProgressiveAphasia.neuropathologicallyConfirmedDiagnosis.criteria ??
    []) as string[];

  if (
    criteria &&
    answerCount(criteria, [section[0]], 'yes') >= 1 &&
    answerCount(criteria, section.slice(1), 'yes') >= 1
  )
    return 'yes';

  if (
    criteria &&
    (answerCount(criteria, [section[0]], 'no') >= 1 || answerCount(criteria, section.slice(1), 'no') >= 2)
  )
    return 'no';

  return 'unknown';
};

const summaryResultPicker = (
  result1: 'yes' | 'no' | 'unknown',
  result2: 'yes' | 'no' | 'unknown',
): 'yes' | 'no' | 'unknown' =>
  [result1, result2].every((r) => r === 'yes') ? 'yes' : [result1, result2].includes('no') ? 'no' : 'unknown';

/**
 * Pick a value for a form row
 * @param {IPPA} document - PPA document to be checked
 * @param {string} section - Name of the section the value is picked for
 * @param {string} subSection - Name of the subSection the value is picked for
 * @param {string} key - Name of the individual subSection key the value is picked for
 * @returns {'yes' | 'no' | 'unknown'} - Result for the row
 */
export const resultPicker = (
  document: IPPA,
  section: string,
  subSection: string,
  key?: string,
): 'yes' | 'no' | 'unknown' => {
  const d = JSON.parse(JSON.stringify(document));
  switch (section) {
    case 'general':
      switch (subSection) {
        case 'theFollowingThreeCharacteristicsShouldBePresentInTheSyndrome':
          return charactersticsOfSectionIArePresentInTheSyndrome(d);
        case 'theFollowingFourCharacteristicsShouldNotBePresentInTheSyndrome':
          switch (key) {
            case 'generalCriteriaFulfilled':
              return generalCriteriaFulfilled(d);
            default:
              return charactersticsOfSectionIIAreNotPresentInTheSyndrome(d);
          }
        default:
          return 'unknown';
      }
    case 'nonfluentVariantPPA':
      switch (subSection) {
        case 'clinicalDiagnosis':
          return criteriaForClinicalDiagnosisForNfvPPAFulfilled(d);
        case 'diagnosisSupportedByImagingStudies':
          return criteriaForDiagnosisSupportedByImagingStudiesForNfvPPAFulfilled(d);
        case 'neuropathologicallyConfirmedDiagnosis':
          return criteriaForNeuropathologicallyConfirmedDiagnosisForNfvPPAFulfilled(d);
        default:
          return 'unknown';
      }
    case 'semanticDementia':
      switch (subSection) {
        case 'clinicalDiagnosis':
          return criteriaForClinicalDiagnosisForSemanticDementiaFulfilled(d);
        case 'diagnosisSupportedByImagingStudies':
          return criteriaForDiagnosisSupportedByImagingStudiesForSemanticDementiaFulfilled(d);
        case 'neuropathologicallyConfirmedDiagnosis':
          return criteriaForNeuropathologicallyConfirmedDiagnosisForSemanticDementiaFulfilled(d);
        default:
          return 'unknown';
      }
    case 'logopenicProgressiveAphasia':
      switch (subSection) {
        case 'clinicalDiagnosis':
          return criteriaForClinicalDiagnosisForLogopenicProgressiveAphasiaFulfilled(d);
        case 'diagnosisSupportedByImagingStudies':
          return criteriaForDiagnosisSupportedByImagingStudiesForLogopenicProgressiveAphasiaFulfilled(d);
        case 'neuropathologicallyConfirmedDiagnosis':
          return criteriaForNeuropathologicallyConfirmedDiagnosisForLogopenicProgressiveAphasiaFulfilled(d);
        case 'summary':
          switch (key) {
            case 'criteriaForDiagnosisSupportedByImagingStudiesForNfvPPAFulfilled':
              /**
               * Ohjelma päättelee tämän seuraavasti
               * - "Kyllä" tulos edellyttää, että  "Yleiset kriteerit täyttyvät" (rivi 45)  ja "Kohdan II Kuvantamistutkimusten tukeman diagnoosin kriteerit täyttyvät" (rivi 88) ovat "Kyllä"
               * - "Ei" tulos edellyttää, että  "Yleiset kriteerit täyttyvät" (rivi 45)  tai "Kohdan II Kuvantamistutkimusten tukeman diagnoosin kriteerit täyttyvät" (rivi 88) on "Ei"
               * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
               */
              return summaryResultPicker(
                generalCriteriaFulfilled(d),
                criteriaForDiagnosisSupportedByImagingStudiesForNfvPPAFulfilled(d),
              );
            case 'criteriaForNeuropathologicallyConfirmedDiagnosisForNfvPPAFulfilled':
              /**
               * Ohjelma päättelee tämän seuraavasti
               * - "Kyllä" tulos edellyttää, että  "Yleiset kriteerit täyttyvät" (rivi 45)  ja "Kohdan III Neuropatologisesti varmennetun diagnoosin kriteerit täyttyvät" (rivi 103) ovat "Kyllä"
               * - "Ei" tulos edellyttää, että  "Yleiset kriteerit täyttyvät" (rivi 45)  tai "Kohdan III Neuropatologisesti varmennetun diagnoosin kriteerit täyttyvät" (rivi 103) on "Ei"
               * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
               */
              return summaryResultPicker(
                generalCriteriaFulfilled(d),
                criteriaForNeuropathologicallyConfirmedDiagnosisForNfvPPAFulfilled(d),
              );
            case 'criteriaForDiagnosisSupportedByImagingStudiesForSemanticDementiaFulfilled':
              /**
               * Ohjelma päättelee tämän seuraavasti
               * - "Kyllä" tulos edellyttää, että  "Yleiset kriteerit täyttyvät" (rivi 45)  ja "Kohdan II Kuvantamistutkimusten tukeman diagnoosin kriteerit täyttyvät" (rivi 148) ovat "Kyllä"
               * - "Ei" tulos edellyttää, että  "Yleiset kriteerit täyttyvät" (rivi 45)  tai "Kohdan II Kuvantamistutkimusten tukeman diagnoosin kriteerit täyttyvät" (rivi 148) on "Ei"
               * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
               */
              return summaryResultPicker(
                generalCriteriaFulfilled(d),
                criteriaForDiagnosisSupportedByImagingStudiesForSemanticDementiaFulfilled(d),
              );
            case 'criteriaForNeuropathologicallyConfirmedDiagnosisForSemanticDementiaFulfilled':
              /**
               * Ohjelma päättelee tämän seuraavasti
               * - "Kyllä" tulos edellyttää, että  "Yleiset kriteerit täyttyvät" (rivi 45)  ja "Kohdan III Varmennettuun patologiaan liittyvän semanttisen dementian kriteerit täyttyvät" (rivi 163) ovat "Kyllä"
               * - "Ei" tulos edellyttää, että  "Yleiset kriteerit täyttyvät" (rivi 45)  tai "Kohdan III Varmennettuun patologiaan liittyvän semanttisen dementian kriteerit täyttyvät" (rivi 163) on "Ei"
               * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
               */
              return summaryResultPicker(
                generalCriteriaFulfilled(d),
                criteriaForNeuropathologicallyConfirmedDiagnosisForSemanticDementiaFulfilled(d),
              );
            case 'criteriaForDiagnosisSupportedByImagingStudiesForLogopenicProgressiveAphasiaFulfilled':
              /**
               * Ohjelma päättelee tämän seuraavasti
               * - "Kyllä" tulos edellyttää, että  "Yleiset kriteerit täyttyvät" (rivi 45)  ja "Kohdan II Kuvantamistutkimusten tukeman diagnoosin kriteerit täyttyvät" (rivi 208) ovat "Kyllä"
               * - "Ei" tulos edellyttää, että  "Yleiset kriteerit täyttyvät" (rivi 45)  tai "Kohdan II Kuvantamistutkimusten tukeman diagnoosin kriteerit täyttyvät" (rivi 208) on "Ei"
               * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
               */
              return summaryResultPicker(
                generalCriteriaFulfilled(d),
                criteriaForDiagnosisSupportedByImagingStudiesForLogopenicProgressiveAphasiaFulfilled(d),
              );
            case 'criteriaForNeuropathologicallyConfirmedDiagnosisForLogopenicProgressiveAphasiaFulfilled':
              /**
               * Ohjelma päättelee tämän seuraavasti
               * - "Kyllä" tulos edellyttää, että  "Yleiset kriteerit täyttyvät" (rivi 45)  ja "Kohdan III Neuropatologisesti varmennetun diagnoosin kriteerit täyttyvät" (rivi 223) ovat "Kyllä"
               * - "Ei" tulos edellyttää, että  "Yleiset kriteerit täyttyvät" (rivi 45)  tai "Kohdan III Neuropatologisesti varmennetun diagnoosin kriteerit täyttyvät" (rivi 223) on "Ei"
               * - Jos "Kyllä" tai "Ei" tulosten kriteerit eivät täyty, niin vastaus on "Ei tiedossa"
               */
              return summaryResultPicker(
                generalCriteriaFulfilled(d),
                criteriaForNeuropathologicallyConfirmedDiagnosisForLogopenicProgressiveAphasiaFulfilled(d),
              );
            default:
              return 'unknown';
          }
        default:
          return 'unknown';
      }
    default:
      return 'unknown';
  }
};
