import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import HistorySection from '../../../../components/HistorySection';
import { withHistoryContext, IHistoryContext } from '../../../../containers/FormContextHandler';
import { TreatmentHistorySection } from './components';

const SurgicalTreatment = ({ documents = [], startEdit, view }: IHistoryContext<ISurgicalTreatment>): JSX.Element => (
  <HistorySection
    headerText={<FormattedMessage id="treatment.surgicalTreatment.title" />}
    newButton={
      startEdit ? (
        <DocumentCreationButton
          name="surgicalTreatment"
          text="treatment.surgicalTreatment.newSurgicalTreatment"
          onClick={startEdit({}, 'surgicalTreatment')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      ) : (
        <></>
      )
    }
  >
    <TreatmentHistorySection documents={documents} startEdit={startEdit} type="surgicalTreatment" view={view} />
  </HistorySection>
);

export default withHistoryContext(SurgicalTreatment);
