import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import FormSection from '../../../../../components/FormSection';
import colors from '../../../../../config/theme/colors';
import { Container } from 'Components/Grid';

const MWTForm = ({ formData, viewing }: IOwnProps): JSX.Element => (
  <React.Fragment>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={!viewing}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="general.timeOfDay" id={'time'}>
      <InputHandler type="TimePicker" editing={!viewing} name="time" formData={formData} timeDefault="now" />
    </FormRow>

    <FormSection>
      <FormRow title="motor.distance">
        <Container style={{ fontSize: '1.8rem', fontWeight: 600, color: colors.primary }}>
          <InputHandler
            type="NumberField"
            editing={!viewing}
            name="meters"
            formData={formData}
            placeholder="motor.meters"
            width={4.6}
            height={3}
            maxLength={5}
          />
        </Container>
      </FormRow>
      <FormRow title="motor.additionalInformation">
        <InputHandler type="TextArea" editing={!viewing} name="comments" formData={formData} />
      </FormRow>
    </FormSection>
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<I6MWT>;
  viewing: boolean;
}

export default MWTForm;
