import * as React from 'react';
import FormSection from 'Components/FormSection';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';

const PlasmaChangeForm = ({ formData, editing }: IOwnProps): JSX.Element => {
  React.useEffect(() => {
    if (!formData.document?.code) {
      formData.onChange?.({
        code: { codeSystem: 'http://snomed.info/sct', code: '19647005', description: 'Plasmanvaihto' },
      });
    }
  }, []);

  return (
    <>
      <FormSection>
        <FormRow title="general.date">
          <InputHandler editing={!!editing} name="date" type="PartialDate" formData={formData} dateDefault="now" />
        </FormRow>
      </FormSection>
    </>
  );
};

interface IOwnProps {
  formData: IFormData<IProcedure>;
  editing: string;
}

export default PlasmaChangeForm;
