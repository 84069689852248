import * as React from 'react';

import HineMMForm from './HineMM';
import RULMForm from './RULM';
import CHOPINTENDForm from './CHOPINTEND';
import HFMSEForm from './HFMSE';
import MWTForm from './MWT';
import NSAAForm from './NSAA';
import { TDocument } from '../../utils';
import MFMForm from './MFM';

const MotorForm = ({ formData, document, viewing = false, editing }: IOwnProps): JSX.Element | null =>
  document?._type === 'hine_mm' ? (
    <HineMMForm formData={formData as IFormData<IHINEMM>} viewing={viewing} />
  ) : document?._type === 'rulm' ? (
    <RULMForm formData={formData as IFormData<IRULM>} viewing={viewing} />
  ) : document?._type === 'chop_intend' ? (
    <CHOPINTENDForm formData={formData as IFormData<ICHOPINTEND>} viewing={viewing} />
  ) : document?._type === 'hfms_e' ? (
    <HFMSEForm formData={formData as IFormData<IHFMSE>} viewing={viewing} />
  ) : document?._type === 'mwt' ? (
    <MWTForm formData={formData as IFormData<I6MWT>} viewing={viewing} />
  ) : document?._type === 'nsaa' ? (
    <NSAAForm formData={formData as IFormData<INSAA>} viewing={viewing} />
  ) : document?._type === 'mfm' ? (
    <MFMForm formData={formData as IFormData<IMFM>} viewing={viewing} editing={editing} />
  ) : null;
interface IOwnProps {
  formData: IFormData;
  document?: TDocument;
  viewing?: boolean;
  editing?: any;
}

export default MotorForm;
