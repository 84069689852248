import * as React from 'react';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import { IFormContext } from '../../../../../containers/FormContextHandler';

export const steps = [
  'hoursSleptIncludingNaps',
  'hoursSleptAtNight',
  'goingToSleepTime',
  'wakingUpTime',
  'napsHowOften',
  'snoringDuringSleep',
  'snoringQuality',
  'breathingPausesDuringSleep',
  'snoringYears',
  'fatigueDuringDay',
  'moreTiredThanOtherPeople',
  'hoursOfSleepNeeded',
  'detailsOnSleepAndHelpNeeded',
] as Array<string>;

export const getStepsLocalesGetter = (fm: IFormContext['fm']) => (step: string | number) =>
  fm('diagnosticSurvey.bnsq.' + step);

const dayRegularity: Array<TBNSQDayRegularity> = [
  'neverOrLessThanOncePerMonth',
  'lessThanOncePerWeek',
  '1to2DaysPerWeek',
  '3to5DaysPerWeek',
  'dailyOrAlmostDaily',
];

const nightRegularity: Array<TBNSQNightRegularity> = [
  'neverOrLessThanOncePerMonth',
  'lessThanOncePerWeek',
  '1to2NightsPerWeek',
  '3to5NightsPerWeek',
  'everyNightOrAlmostEveryNight',
];

export const getStepContentGetter =
  (fm: IFormContext['fm'], viewing = false) =>
  (
    stepName: string,
  ): {
    opts: string[];
    optsLocale: (o: number | string) => string;
    height?: number;
    instruction?: JSX.Element;
    customElements?: (formData: IFormData<IBNSQ>) => JSX.Element;
    nextButton?: boolean;
    customValue?: (formData: IFormData) => JSX.Element;
  } => {
    switch (stepName) {
      case 'hoursSleptIncludingNaps':
        return {
          opts: [],
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
          customElements: (formData) => (
            <FormRow title="diagnosticSurvey.bnsq.hoursSleptIncludingNaps">
              <>
                {fm('diagnosticSurvey.bnsq.iSleepOnAverage') + ' '}

                <InputHandler
                  name="hoursSleptIncludingNaps"
                  type="NumberField"
                  formData={formData}
                  editing={!viewing}
                  precision={2}
                  width={8}
                />
                {' ' + fm('diagnosticSurvey.bnsq.hoursADay')}
              </>
            </FormRow>
          ),
          nextButton: true,
          customValue: (formData: IFormData) => (
            <>
              {formData.document?.hoursSleptIncludingNaps} {fm('diagnosticSurvey.bnsq.hours')}
            </>
          ),
        };
      case 'hoursSleptAtNight':
        return {
          opts: [],
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
          customElements: (formData) => (
            <FormRow title="diagnosticSurvey.bnsq.hoursSleptAtNight">
              <>
                {fm('diagnosticSurvey.bnsq.iSleepOnAverage') + ' '}

                <InputHandler
                  name="hoursSleptAtNight"
                  type="NumberField"
                  formData={formData}
                  editing={!viewing}
                  precision={2}
                  width={8}
                />
                {' ' + fm('diagnosticSurvey.bnsq.hoursADay')}
              </>
            </FormRow>
          ),
          nextButton: true,
          customValue: (formData: IFormData) => (
            <>
              {formData.document?.hoursSleptAtNight} {fm('diagnosticSurvey.bnsq.hours')}
            </>
          ),
        };
      case 'goingToSleepTime':
        return {
          opts: [],
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
          customElements: (formData) => (
            <>
              <FormRow title="diagnosticSurvey.bnsq.goingToSleepTimeWeek">
                <InputHandler
                  name="goingToSleepTimeWeek"
                  type="TextField"
                  formData={formData}
                  editing={!viewing}
                  placeholder="diagnosticSurvey.bnsq.clock"
                />
              </FormRow>
              <FormRow title="diagnosticSurvey.bnsq.goingToSleepTimeWeekend">
                <InputHandler
                  name="goingToSleepTimeWeekend"
                  type="TextField"
                  formData={formData}
                  editing={!viewing}
                  placeholder="diagnosticSurvey.bnsq.clock"
                />
              </FormRow>
            </>
          ),
          nextButton: true,
          customValue: (formData: IFormData) => (
            <>
              {`${
                formData.document.goingToSleepTimeWeek
                  ? formData.document.goingToSleepTimeWeek + ' ' + fm('diagnosticSurvey.bnsq.weekdays')
                  : ''
              }`}
              {` ${
                formData.document.goingToSleepTimeWeekend
                  ? formData.document.goingToSleepTimeWeekend + ' ' + fm('diagnosticSurvey.bnsq.weekends')
                  : ''
              }`}
            </>
          ),
        };
      case 'wakingUpTime':
        return {
          opts: [],
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
          customElements: (formData) => (
            <>
              <FormRow title="diagnosticSurvey.bnsq.wakingUpTimeWeek">
                <InputHandler
                  name="wakingUpTimeWeek"
                  type="TextField"
                  formData={formData}
                  editing={!viewing}
                  placeholder="diagnosticSurvey.bnsq.clock"
                />
              </FormRow>
              <FormRow title="diagnosticSurvey.bnsq.wakingUpTimeWeekend">
                <InputHandler
                  name="wakingUpTimeWeekend"
                  type="TextField"
                  formData={formData}
                  editing={!viewing}
                  placeholder="diagnosticSurvey.bnsq.clock"
                />
              </FormRow>
            </>
          ),
          nextButton: true,
          customValue: (formData: IFormData) => (
            <>
              {`${
                formData.document.wakingUpTimeWeek
                  ? formData.document.wakingUpTimeWeek + ' ' + fm('diagnosticSurvey.bnsq.weekdays')
                  : ''
              }`}
              {` ${
                formData.document.wakingUpTimeWeekend
                  ? formData.document.wakingUpTimeWeekend + ' ' + fm('diagnosticSurvey.bnsq.weekends')
                  : ''
              }`}
            </>
          ),
        };
      case 'snoringDuringSleep':
        return {
          opts: nightRegularity,
          optsLocale: (o: string | number) =>
            fm('diagnosticSurvey.bnsq.opts.' + o + `${o === 'neverOrLessThanOncePerMonth' ? 'E' : ''}`),
        };
      case 'snoringQuality':
        return {
          opts: ['noSnoring', 'silentAndEven', 'evenAndLoud', 'evenAndVeryLoud', 'veryLoudAndUneven'],
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
          height: 22,
        };
      case 'breathingPausesDuringSleep':
        return {
          opts: nightRegularity,
          optsLocale: (o: string | number) =>
            fm('diagnosticSurvey.bnsq.opts.' + o + `${o === 'neverOrLessThanOncePerMonth' ? 'B' : ''}`),
          instruction: <>{fm('diagnosticSurvey.bnsq.breathingPausesDuringSleep')}</>,
        };
      case 'snoringYears':
        return {
          opts: [],
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
          customElements: (formData) => (
            <>
              <FormRow title="diagnosticSurvey.bnsq.snoringYearsText">
                <>
                  {fm('diagnosticSurvey.bnsq.iHaveSnoredAbout') + ' '}
                  <InputHandler
                    name="snoringYears"
                    type="NumberField"
                    formData={formData}
                    editing={!viewing}
                    width={8}
                  />
                  {' ' + fm('diagnosticSurvey.bnsq.yearsIwasAbout') + ' '}
                  <InputHandler
                    name="snoringStartedAge"
                    type="NumberField"
                    formData={formData}
                    editing={!viewing}
                    width={8}
                  />
                  {' ' + fm('diagnosticSurvey.bnsq.whenIStartedToSnore')}
                </>
              </FormRow>
            </>
          ),
          nextButton: true,
          customValue: (formData: IFormData) => (
            <>
              {formData.document?.snoringYears} {fm('diagnosticSurvey.bnsq.years')}
            </>
          ),
        };
      case 'napsHowOften':
      case 'fatigueDuringDay':
        return {
          opts: dayRegularity,
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
        };
      case 'moreTiredThanOtherPeople':
        return {
          opts: ['noMoreBrisk', 'noSomewhatMoreBrisk', 'noDifference', 'yesSomewhatMoreTired', 'yesClearlyMoreTired'],
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
          instruction: <>{fm('diagnosticSurvey.bnsq.moreTiredThanOtherPeople')}</>,
        };
      case 'hoursOfSleepNeeded':
        return {
          opts: [],
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
          customElements: (formData) => (
            <>
              <FormRow title="diagnosticSurvey.bnsq.hoursOfSleepNeeded">
                <>
                  {fm('diagnosticSurvey.bnsq.iNeedAbout') + ' '}
                  <InputHandler
                    name="hoursOfSleepNeeded"
                    type="NumberField"
                    formData={formData}
                    editing={!viewing}
                    width={8}
                  />
                  {' ' + fm('diagnosticSurvey.bnsq.hoursAnd') + ' '}
                  <InputHandler
                    name="minutesOfSleepNeeded"
                    type="NumberField"
                    formData={formData}
                    editing={!viewing}
                    width={8}
                  />
                  {' ' + fm('diagnosticSurvey.bnsq.minutesOfSleepADay')}
                </>
              </FormRow>
            </>
          ),
          customValue: (formData: IFormData) => (
            <>
              {`${
                formData.document.hoursOfSleepNeeded
                  ? formData.document.hoursOfSleepNeeded + ' ' + fm('diagnosticSurvey.bnsq.hours')
                  : ''
              }`}
              {` ${
                formData.document.minutesOfSleepNeeded
                  ? formData.document.minutesOfSleepNeeded + ' ' + fm('diagnosticSurvey.bnsq.minutes')
                  : ''
              }`}
            </>
          ),
          nextButton: true,
        };
      case 'detailsOnSleepAndHelpNeeded':
        return {
          opts: [],
          optsLocale: (o: string | number) => fm('diagnosticSurvey.bnsq.opts.' + o),
          customElements: (formData) => (
            <>
              <FormRow title="diagnosticSurvey.bnsq.detailsOnSleepAndHelpNeeded">
                <InputHandler
                  name="detailsOnSleepAndHelpNeeded"
                  type="TextArea"
                  formData={formData}
                  editing={!viewing}
                  placeholder="diagnosticSurvey.bnsq.details"
                />
              </FormRow>
            </>
          ),
          nextButton: false,
          customValue: (formData: IFormData) => <>{formData.document?.detailsOnSleepAndHelpNeeded}</>,
        };

      default:
        return { opts: [], optsLocale: () => '' };
    }
  };
