type TOmitted = keyof IControlProps | 'date';

export type TPulmonaryRowDataKey = keyof Omit<IPulmonary, TOmitted>;

export const rowData: {
  [Property in TPulmonaryRowDataKey]: {
    type: 'Radio' | 'TextField';
    options?: string[];
    optionFormat?: string;
    placeholder?: string;
  };
} = {
  assistanceInAirwayClearanceorSecretionMobilisation: {
    type: 'Radio',
    options: ['yes', 'no'],
    optionFormat: 'yesNo',
  },
  suction: {
    type: 'Radio',
    options: ['no', 'daily', 'onDemand'],
    optionFormat: 'noDailyOnDemand',
  },
  chestPercussion: {
    type: 'Radio',
    options: ['no', 'daily', 'onDemand'],
    optionFormat: 'noDailyOnDemand',
  },
  coughAssistDevice: {
    type: 'Radio',
    options: ['no', 'daily', 'onDemand'],
    optionFormat: 'noDailyOnDemand',
  },
  ippv: {
    type: 'Radio',
    options: ['no', 'daily', 'onDemand'],
    optionFormat: 'noDailyOnDemand',
  },
  ippvHoursPerDay: {
    type: 'Radio',
    options: ['4+', '6+', '8+', '12+'],
    optionFormat: 'ippvHoursPerDay',
  },
  other: {
    type: 'Radio',
    options: ['no', 'daily', 'onDemand'],
    optionFormat: 'noDailyOnDemand',
  },
  otherAssistance: {
    type: 'TextField',
    placeholder: 'pulmonary.otherAssistancePlaceholder',
  },
};
