import * as React from 'react';

const svgImage = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 400 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd' as const,
      clipRule: 'evenodd' as const,
      strokeLinecap: 'round' as const,
      strokeLinejoin: 'round' as const,
      strokeMiterlimit: 1.5,
    }}
  >
    <g transform="matrix(0.749529,0,0,0.749529,-500.406,-37.1958)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.749529,0,0,0.749529,-358.487,-36.0639)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.749529,0,0,0.749529,-209.269,-32.4118)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.749529,0,0,0.749529,-491.99,-223.649)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.749529,0,0,0.749529,-522.272,-117.162)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.749529,0,0,0.749529,-429.285,-87.7248)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.544549,0.0119973,-0.0177078,0.803745,-331.486,-352.971)">
      <rect x="763.845" y="554.566" width="11.338" height="266.459" />
    </g>
    <g transform="matrix(0.721653,0.202513,-0.202513,0.721653,-380.08,-432.428)">
      <circle cx="784.129" cy="472.301" r="48.515" style={{ fill: 'white', stroke: 'black', strokeWidth: '9.76px' }} />
    </g>
    <g transform="matrix(0.295174,0.0828331,-0.0828331,0.295174,-76.3425,-138.696)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(1.51179,0.426453,-0.178279,0.632003,-900.678,-540.688)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(1.20423,0,0,0.177156,-498.105,186.669)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(1.58211,0,0,1.04896,-735.972,-434.898)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.946541,0.321113,-0.346623,1.02173,-266.09,-690.53)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(1.58149,0.0441665,-0.0305024,1.09221,-864.464,-496.974)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
  </svg>
);

export default svgImage;
