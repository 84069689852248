import EventStepper from 'Components/EventStepper';
import { StepperHeaderFormInputPair, StepperHeaderValuePair } from 'Components/EventStepper/components';
import { fm } from 'Components/FormatMessage';
import FormSection from 'Components/FormSection';
import { calculateDaysDifference, exists, formatPartialDate, sortPartialDate } from 'neuro-utils';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import Unit from '../../../../../components/Unit';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import {
  checkIfAcuteTreatmentStart,
  checkIfCurrentDocumentIsTheFirstTreatment,
  getDateOfReferral,
  showDelay,
} from '../../../utils';

const locPath = 'treatment.surgicalTreatment';

const SurgicalTreatment = ({
  formData,
  view,
  documents = [],
  editing,
}: IFormContext<ISurgicalTreatment, any>): JSX.Element => {
  const thisDocument = formData.document;
  const sleepDocs: Array<ISleepPolygraphy | ISleepStudy> = (documents ?? [])
    .filter((d) => ['sleepPolygraphy', 'sleepStudy'].includes(d._type))
    .sort((a: ISleepPolygraphy | ISleepStudy, b: ISleepPolygraphy | ISleepStudy) => sortPartialDate(b.date, a.date));
  const latestSleepPolygraphyOrStudy = sleepDocs[0] ?? {};
  const referralDate = getDateOfReferral(documents);
  const isAcute = checkIfAcuteTreatmentStart(documents);
  const isFirstDoc = checkIfCurrentDocumentIsTheFirstTreatment(documents, view, editing);

  const delayFromSuspicionToTreatmentStart = calculateDaysDifference(referralDate, thisDocument.treatmentDecisionDate);
  const delayFromTreatmentDecisionToOperation = calculateDaysDifference(
    thisDocument.treatmentDecisionDate,
    thisDocument.date,
  );
  const delayFromSleepPolygraphyOrStudyToOperation = calculateDaysDifference(
    latestSleepPolygraphyOrStudy.date,
    thisDocument.date,
  );

  return (
    <>
      <FormRow title={`${locPath}.treatmentDecisionDate`}>
        <InputHandler
          type="PartialDate"
          editing={!view?.viewing}
          name="treatmentDecisionDate"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title={`${locPath}.delayFromSuspicionToTreatmentStart`} condition={showDelay(isAcute, isFirstDoc)}>
        <Unit unit={<FormattedMessage id="treatment.daysPlaceholder" />} fontWeight={view?.viewing ? 'bold' : 'normal'}>
          {exists(delayFromSuspicionToTreatmentStart) ? (delayFromSuspicionToTreatmentStart as number) : '-'}
        </Unit>
      </FormRow>
      <FormRow title={`${locPath}.operationType`}>
        <InputHandler
          type="Radio"
          editing={!view?.viewing}
          name="operationType"
          formData={formData}
          options={['jawSurgery', 'knkSurgery', 'bariatricSurgery']}
          optionFormatter={(o): string | JSX.Element => <FormattedMessage id={`${locPath}.opts.${o}`} />}
          dependentFieldsList={() => [
            'bariatricSurgeryType',
            'bariatricSurgeryTypeOther',
            'primaryOrReoperation',
            'reoperationNeed',
            'knkType',
            'monitoringPolygraphyNeed',
            'controlVisits',
            'controlVisitsJawSurgery',
          ]}
          dependentFieldsRemovalWarning
        />
      </FormRow>
      <FormRow title={`${locPath}.additionalInformation`} condition={formData.document.operationType === 'knkSurgery'}>
        <InputHandler
          type="TextArea"
          name="additionalInformation"
          editing={!view?.viewing}
          formData={formData}
          placeholder={`${locPath}.additionalInformation`}
          width={50}
        />
      </FormRow>
      <FormRow title={`${locPath}.operationDate`}>
        <InputHandler
          type="PartialDate"
          editing={!view?.viewing}
          name="date"
          formData={formData}
          dateHook={{ dateHookFloor: formData?.document.treatmentDecisionDate }}
        />
      </FormRow>
      <FormRow title={`${locPath}.delayFromTreatmentDecisionToOperation`}>
        <Unit unit={<FormattedMessage id="treatment.daysPlaceholder" />} fontWeight={view?.viewing ? 'bold' : 'normal'}>
          {exists(delayFromTreatmentDecisionToOperation) ? (delayFromTreatmentDecisionToOperation as number) : '-'}
        </Unit>
      </FormRow>
      <FormRow title={`${locPath}.delayFromSleepPolygraphyOrStudyToOperation`} condition={showDelay(false, isFirstDoc)}>
        <Unit unit={<FormattedMessage id="treatment.daysPlaceholder" />} fontWeight={view?.viewing ? 'bold' : 'normal'}>
          {exists(delayFromSleepPolygraphyOrStudyToOperation)
            ? (delayFromSleepPolygraphyOrStudyToOperation as number)
            : '-'}
        </Unit>
      </FormRow>
      <FormRow title={`${locPath}.knkType`} condition={formData.document.operationType === 'knkSurgery'}>
        <InputHandler
          type="Radio"
          name="knkType"
          formData={formData}
          editing={!view?.viewing}
          options={[
            'tonsilsRemoval5070',
            'tonsilsRemoval90',
            'tonsillectomy',
            'uvulopalatoplasty',
            'uvulopalatopharyngoplasty',
            'hypoglossusStimulator',
            'trakeostomy',
            'septoplasty',
            'nosePolypsSurgery',
            'inferiorNasalConchaHypertrophySurgery',
            'lingualTonsilsHypertrophySurgery',
          ]}
          optionFormatter={(o): string | JSX.Element => <FormattedMessage id={`${locPath}.opts.${o}`} />}
        />
      </FormRow>
      <FormRow
        title={`${locPath}.monitoringPolygraphyNeed`}
        condition={formData.document.operationType === 'knkSurgery'}
      >
        <InputHandler
          type="Radio"
          name="monitoringPolygraphyNeed"
          formData={formData}
          editing={!view?.viewing}
          options={['yes', 'no']}
          optionFormatter={(o): string | JSX.Element => <FormattedMessage id={`general.${o}`} />}
        />
      </FormRow>
      <FormSection condition={formData.document.operationType === 'knkSurgery'}>
        <EventStepper
          name="controlVisits"
          formData={formData}
          stepLabelText={(d: ISurgicalTreatmentControl): string => formatPartialDate(d.date)}
          stepContent={(d: ISurgicalTreatmentControl): JSX.Element => (
            <React.Fragment>
              <StepperHeaderValuePair
                header={fm('treatment.surgicalTreatment.controls.controlType')}
                value={d.controlType && fm(`treatment.surgicalTreatment.controls.opts.${d.controlType}`)}
              />
              <StepperHeaderValuePair
                header={fm('treatment.surgicalTreatment.controls.patientPleasedWithSurgery')}
                value={d.patientPleasedWithSurgery && fm(`general.${d.patientPleasedWithSurgery}`)}
              />
              <StepperHeaderValuePair
                header={fm('treatment.surgicalTreatment.controls.pleasedInformation')}
                value={d.pleasedInformation}
              />
              <StepperHeaderValuePair
                header={fm('treatment.surgicalTreatment.controls.dayTimeTiredness')}
                value={d.dayTimeTiredness && fm(`treatment.surgicalTreatment.controls.opts.${d.dayTimeTiredness}`)}
              />
              <StepperHeaderValuePair
                header={fm('treatment.surgicalTreatment.controls.monitoringPolygraphyDone')}
                value={d.monitoringPolygraphyDone && fm(`general.${d.monitoringPolygraphyDone}`)}
              />
              <StepperHeaderValuePair
                header={fm('treatment.surgicalTreatment.controls.treatmentComplication')}
                value={d.treatmentComplication && fm(`general.${d.treatmentComplication}`)}
              />
              {d.treatmentComplication === 'yes' && (
                <StepperHeaderValuePair
                  header={fm('treatment.surgicalTreatment.controls.complicationInformation')}
                  value={d.complicationInformation}
                />
              )}
            </React.Fragment>
          )}
          addNewTextHeader="treatment.surgicalTreatment.controls.newControl"
          addNewTextButton="treatment.surgicalTreatment.controls.newControlButton"
          previousEventsTextHeader="treatment.surgicalTreatment.controls.previousControls"
          noPreviousEventsTextHeader="treatment.surgicalTreatment.controls.noPreviousControls"
          editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => (
            <React.Fragment>
              <StepperHeaderFormInputPair
                header={fm('general.date')}
                input={
                  <InputHandler
                    type="PartialDate"
                    editing={true}
                    name="date"
                    formData={{
                      onChange,
                      document: { date: formData.document.controlVisits?.[index]?.date || '' },
                    }}
                    dateDefault="now"
                    isNotCancellable={true}
                    dateHook={{
                      dateHookFloor: formData.document.date,
                    }}
                  />
                }
              />
              <StepperHeaderFormInputPair
                header={fm('treatment.surgicalTreatment.controls.controlType')}
                input={
                  <InputHandler
                    type="Radio"
                    name="controlType"
                    editing={true}
                    formData={{
                      onChange,
                      document: { controlType: formData.document.controlVisits?.[index]?.controlType },
                    }}
                    options={['visit', 'call', 'videocall']}
                    optionFormatter={(opt: string | number): JSX.Element =>
                      fm(`treatment.surgicalTreatment.controls.opts.${opt}`)
                    }
                  />
                }
              />
              <StepperHeaderFormInputPair
                header={fm('treatment.surgicalTreatment.controls.patientPleasedWithSurgery')}
                input={
                  <InputHandler
                    name="patientPleasedWithSurgery"
                    type="Radio"
                    formData={{
                      onChange,
                      document: {
                        patientPleasedWithSurgery: formData.document.controlVisits?.[index]?.patientPleasedWithSurgery,
                      },
                    }}
                    options={['yes', 'no']}
                    editing={true}
                    optionFormatter={(o: string | number): JSX.Element => fm(`general.${o}`)}
                  />
                }
              />
              <StepperHeaderFormInputPair
                header={fm('treatment.surgicalTreatment.controls.pleasedInformation')}
                input={
                  <InputHandler
                    name="pleasedInformation"
                    type="TextArea"
                    formData={{
                      onChange,
                      document: {
                        pleasedInformation: formData.document.controlVisits?.[index]?.pleasedInformation,
                      },
                    }}
                    editing={true}
                    placeholder={'treatment.surgicalTreatment.controls.pleasedInfoPlaceholder'}
                  />
                }
              />
              <StepperHeaderFormInputPair
                header={fm('treatment.surgicalTreatment.controls.dayTimeTiredness')}
                input={
                  <InputHandler
                    type="Radio"
                    name="dayTimeTiredness"
                    editing={true}
                    formData={{
                      onChange,
                      document: {
                        dayTimeTiredness: formData.document.controlVisits?.[index]?.dayTimeTiredness,
                      },
                    }}
                    options={['noTiredness', 'onlyWhenStayingStill', 'dailyLowActivity', 'dailyHighActivity']}
                    optionFormatter={(o: string | number): JSX.Element =>
                      fm(`treatment.surgicalTreatment.controls.opts.${o}`)
                    }
                  />
                }
              />
              <StepperHeaderFormInputPair
                header={fm('treatment.surgicalTreatment.controls.monitoringPolygraphyDone')}
                input={
                  <InputHandler
                    type="Radio"
                    name="monitoringPolygraphyDone"
                    editing={true}
                    formData={{
                      onChange,
                      document: {
                        monitoringPolygraphyDone: formData.document.controlVisits?.[index]?.monitoringPolygraphyDone,
                      },
                    }}
                    options={['yes', 'no']}
                    optionFormatter={(o: string | number): JSX.Element => fm(`general.${o}`)}
                  />
                }
              />
              <StepperHeaderFormInputPair
                header={fm('treatment.surgicalTreatment.controls.treatmentComplication')}
                input={
                  <InputHandler
                    type="Radio"
                    name="treatmentComplication"
                    editing={true}
                    formData={{
                      onChange,
                      document: {
                        treatmentComplication: formData.document.controlVisits?.[index]?.treatmentComplication,
                      },
                    }}
                    options={['yes', 'no']}
                    optionFormatter={(o: string | number): JSX.Element => fm(`general.${o}`)}
                    dependentFieldsList={(): string[] => ['complicationInformation']}
                  />
                }
              />
              {formData.document.controlVisits?.[index]?.treatmentComplication === 'yes' && (
                <StepperHeaderFormInputPair
                  header={fm('treatment.surgicalTreatment.controls.complicationInformation')}
                  input={
                    <InputHandler
                      type="TextArea"
                      name="complicationInformation"
                      editing={true}
                      formData={{
                        onChange,
                        document: {
                          complicationInformation: formData.document.controlVisits?.[index]?.complicationInformation,
                        },
                      }}
                      placeholder={'treatment.surgicalTreatment.controls.complicationInformation'}
                    />
                  }
                />
              )}
            </React.Fragment>
          )}
          viewing={!editing}
        />
      </FormSection>
      <FormSection condition={formData.document.operationType === 'jawSurgery'}>
        <EventStepper
          name="controlVisitsJawSurgery"
          formData={formData}
          stepLabelText={(d: ISurgicalTreatmentControl): string => formatPartialDate(d.date)}
          stepContent={(d: ISurgicalTreatmentControl): JSX.Element => (
            <React.Fragment>
              <StepperHeaderValuePair
                header={fm('treatment.madTherapy.controls.controlType')}
                value={d.controlType && fm(`treatment.madTherapy.controls.opts.${d.controlType}`)}
              />
              <StepperHeaderValuePair
                header={fm('treatment.surgicalTreatment.controls.dayTimeTiredness')}
                value={d.dayTimeTiredness && fm(`treatment.surgicalTreatment.controls.opts.${d.dayTimeTiredness}`)}
              />
            </React.Fragment>
          )}
          addNewTextHeader="treatment.madTherapy.controls.newControl"
          addNewTextButton="treatment.madTherapy.controls.newControlButton"
          previousEventsTextHeader="treatment.madTherapy.controls.previousControls"
          noPreviousEventsTextHeader="treatment.madTherapy.controls.noPreviousControls"
          editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => (
            <React.Fragment>
              <StepperHeaderFormInputPair
                header={fm('general.date')}
                input={
                  <InputHandler
                    type="PartialDate"
                    editing={true}
                    name="date"
                    formData={{
                      onChange,
                      document: { date: formData.document.controlVisitsJawSurgery?.[index]?.date || '' },
                    }}
                    dateDefault="now"
                    isNotCancellable={true}
                    dateHook={{
                      dateHookFloor: formData.document.date,
                    }}
                  />
                }
              />
              <StepperHeaderFormInputPair
                header={fm('treatment.madTherapy.controls.controlType')}
                input={
                  <InputHandler
                    type="Radio"
                    name="controlType"
                    editing={true}
                    formData={{
                      onChange,
                      document: { controlType: formData.document.controlVisitsJawSurgery?.[index]?.controlType },
                    }}
                    options={['visit', 'call', 'videocall']}
                    optionFormatter={(opt: string | number): JSX.Element =>
                      fm(`treatment.madTherapy.controls.opts.${opt}`)
                    }
                  />
                }
              />
              <StepperHeaderFormInputPair
                header={fm('treatment.surgicalTreatment.controls.dayTimeTiredness')}
                input={
                  <InputHandler
                    type="Radio"
                    name="dayTimeTiredness"
                    editing={true}
                    formData={{
                      onChange,
                      document: {
                        dayTimeTiredness: formData.document.controlVisitsJawSurgery?.[index]?.dayTimeTiredness,
                      },
                    }}
                    options={['noTiredness', 'onlyWhenStayingStill', 'dailyLowActivity', 'dailyHighActivity']}
                    optionFormatter={(o: string | number): JSX.Element =>
                      fm(`treatment.surgicalTreatment.controls.opts.${o}`)
                    }
                  />
                }
              />
            </React.Fragment>
          )}
          viewing={!editing}
        />
      </FormSection>
      <FormRow
        title={`${locPath}.bariatricSurgeryType`}
        condition={formData.document.operationType === 'bariatricSurgery'}
      >
        <InputHandler
          type="Radio"
          editing={!view?.viewing}
          name="bariatricSurgeryType"
          formData={formData}
          options={['sleeve', 'bypass', 'other']}
          optionFormatter={(o): string | JSX.Element => <FormattedMessage id={`${locPath}.opts.${o}`} />}
        />
      </FormRow>
      <FormRow
        title={`${locPath}.bariatricSurgeryTypeOther`}
        condition={
          formData.document.operationType === 'bariatricSurgery' && formData.document.bariatricSurgeryType === 'other'
        }
      >
        <InputHandler
          type="TextField"
          editing={!view?.viewing}
          name="bariatricSurgeryTypeOther"
          formData={formData}
          placeholder={`${locPath}.bariatricSurgeryTypeOther`}
        />
      </FormRow>
      <FormRow
        title={`${locPath}.primaryOrReoperation`}
        condition={formData.document.operationType === 'bariatricSurgery'}
      >
        <InputHandler
          type="Radio"
          editing={!view?.viewing}
          name="primaryOrReoperation"
          formData={formData}
          options={['primary', 'reoperation']}
          optionFormatter={(o): string | JSX.Element => <FormattedMessage id={`${locPath}.opts.${o}`} />}
          dependentFieldsList={() => ['reoperationNeed']}
        />
      </FormRow>
      <FormRow
        title={`${locPath}.reoperationNeed`}
        condition={
          formData.document.operationType === 'bariatricSurgery' &&
          formData.document.primaryOrReoperation === 'reoperation'
        }
      >
        <InputHandler
          type="Radio"
          editing={!view?.viewing}
          name="reoperationNeed"
          formData={formData}
          options={['weight', 'complication']}
          optionFormatter={(o): string | JSX.Element => <FormattedMessage id={`${locPath}.opts.${o}`} />}
        />
      </FormRow>
    </>
  );
};

export default withFormContext(SurgicalTreatment);
