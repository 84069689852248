import HistoryTabs from 'Components/HistoryTabs';
import {
  FormContext,
  IFormContext,
  IHistoryContext,
  withFormContext,
  withHistoryContext,
} from 'Containers/FormContextHandler';
import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import * as React from 'react';
import { actions, TCreationPromiseType } from 'Store/documents/actions';
import { actions as actionsForm } from '../../../../../store/form';
import { ButtonRow } from './components';
import ContraIndicationToTreatment from './ContraIndicationToTreatment';
import IndicationForTreatment from './IndicationForTreatment';
import ReferringPhysicianAndUnit from './ReferringPhysicianAndUnit';
import SymptomsAndLocations from './SymptomsAndLocations';
import TreatmentPeriod from './TreatmentPeriod';
import TreatmentPeriodForm from '../../Form/NINMTForm/TreatmentPeriod';
import { Container, Item } from 'Components/Grid';
import { FormattedMessage } from 'react-intl';
import colors from '../../../../../config/theme/colors';
import { IDeleteDetails } from 'Components/HistoryRowControls/DeleteDialog';
import SymptomsDetails from './SymptomsDetails';
import { useAppDispatch, useAppSelector } from 'Store/index';

const TreatmentPeriodArea = withFormContext(
  ({ treatmentPeriod, editing }: IFormContext & { treatmentPeriod: ININMTTreatmentPeriod }): JSX.Element => {
    return editing ? (
      <TreatmentPeriodForm classicForm={false} />
    ) : (
      <TreatmentPeriod treatmentPeriod={treatmentPeriod} />
    );
  },
);

const NINMTHistory = ({
  documents = [],
  fm,
  setTreatmentId,
}: IHistoryContext & { setTreatmentId: (id: string) => void }): JSX.Element => {
  const [selected, setSelected] = React.useState<number>(0);
  const [lastEdited, setLastEdited] = React.useState<string | undefined>(undefined);
  const initialDocs = useAppSelector((s: { documents: IDocumentStore }) => s.documents.documents);
  const dispatch = useAppDispatch();

  const formContext = React.useContext(FormContext);
  // These are refenced when there is problematic delay loading documents
  const initialDocsFiltered = (initialDocs || []).filter((d) => d.documentType === 'ninmtTreatmentPeriod');

  const treatmentPeriodDocs = documents
    ?.filter((d) => d._type === 'ninmtTreatmentPeriod')
    .sort((a, b) => sortPartialDate(b.date, a.date));

  // Create new treatment period document
  const addNewTreatmentPeriod = (): void => {
    const fetchID = async (): Promise<TCreationPromiseType> =>
      actions.createDocument('ninmtTreatmentPeriod', true)(dispatch);
    fetchID()
      .then((id: TCreationPromiseType) => {
        if (typeof id === 'object' && id._id) {
          actions.closeCommit(
            { id: id._id as string, name: 'ninmtTreatmentPeriod' },
            {} as TAnyObject & IControlProps,
          )(dispatch);
          dispatch(actionsForm.unsetEditing(id._id as string));
        }
      })
      .catch(() => {
        return;
      });
  };

  // Delete a treatment period (and related sub documents)
  const deleteTreatmentPeriod =
    () => (selectedTabIndex: number, selectTab?: (i: number) => () => void, details?: IDeleteDetails) => {
      const { date, reason, reasonOther } = details ?? {};
      return () => {
        if (selectTab && treatmentPeriodDocs?.[selectedTabIndex]) {
          const deleteId = treatmentPeriodDocs[selectedTabIndex]._id;

          actions.deleteDocument(
            { name: 'ninmtTreatmentPeriod', id: deleteId },
            details
              ? ({ date, reason, reasonOther } as { [key: string]: string | PartialDate })
              : { reason: 'Document deleted by user.' },
            // Related sub documents
            documents
              .filter(
                (d) =>
                  [
                    'indicationForTreatment',
                    'contraIndicationsToTreatment',
                    'symptomsDetails',
                    'symptomsAndLocations',
                    'referringPhysicianAndUnit',
                  ].includes(d._type) && d.treatmentPeriodId === deleteId,
              )
              .map((d) => ({ name: d._type, id: d._id })),
          )(dispatch);
          const newIndex = selected > 0 ? selected - 1 : selected;
          selectTab(newIndex)();
          const scroll = () => {
            scrollTo(0, 0);
          };
          setTimeout(scroll, 100);
        }
      };
    };

  // Add a new tab and a new treatment period
  const newTab = () => {
    return !Array.isArray(treatmentPeriodDocs) || treatmentPeriodDocs.length < 3
      ? (selectTab: (i: number) => () => void) => (): void => {
          const newTabs = [...treatmentPeriodDocs, {}];
          if (!formContext?.editing) {
            selectTab(newTabs.length - 1)();
            addNewTreatmentPeriod();
          }
        }
      : undefined;
  };

  // Initialize treatment periods
  React.useEffect(() => {
    const editingDocument = documents.find((d) => d._editing === true);

    // Set selected index after saving/canceling edits
    let editingIndex = selected;
    if (editingDocument) {
      const currentEditingId =
        editingDocument._type !== 'ninmtTreatmentPeriod' ? editingDocument.treatmentPeriodId : editingDocument._id;

      editingIndex = treatmentPeriodDocs.findIndex((d) => d._id === currentEditingId);
      setSelected(editingIndex);
    }

    if (initialDocsFiltered.length === 0) {
      addNewTreatmentPeriod();
    }
  }, []);

  // Track latest edited document
  React.useEffect(() => {
    if (formContext?.editing) setLastEdited(formContext.editing);
  }, [treatmentPeriodDocs]);

  // Set selected index after changing treatment period date
  React.useEffect(() => {
    if (lastEdited) {
      const lastEditedIndex = treatmentPeriodDocs.findIndex((d) => d._id === lastEdited);
      if (lastEditedIndex >= 0 && lastEditedIndex !== selected) {
        setSelected(treatmentPeriodDocs.findIndex((d) => d._id === lastEdited));
        setLastEdited(undefined);
      } else if (!formContext?.editing) {
        setLastEdited(undefined);
      }
    }
  }, [treatmentPeriodDocs.findIndex((d) => d._id === lastEdited)]);

  return (
    <HistoryTabs
      disableZeroCounts={false}
      addButton={newTab()}
      buttonRow={
        treatmentPeriodDocs.length > 1 ? (
          <ButtonRow remove={deleteTreatmentPeriod()} disabled={!!formContext?.editing} />
        ) : undefined
      }
      hideCount
      indexSelectionTools={{ index: selected, changeFunction: (i: number): void => setSelected(i) }}
    >
      {
        treatmentPeriodDocs.map((tp, i) => ({
          title: (
            <span>{tp.date ? formatPartialDate(tp.date) : fm('diagnosis.ninmt.treatmentDecisionDatePlaceholder')}</span>
          ),
          content: (
            <React.Fragment>
              <TreatmentPeriodArea treatmentPeriod={tp} />
              {tp?.date || formContext?.editing ? undefined : (
                <Container>
                  <Item
                    textAlign="left"
                    style={{ color: colors.tertiaryText, paddingRight: '1rem' }}
                    paddingBottom="2em"
                  >
                    <FormattedMessage id="diagnosis.ninmt.treatmentInstructionText" />
                  </Item>
                </Container>
              )}
              <IndicationForTreatment treatmentPeriod={tp} setTreatmentId={setTreatmentId} />
              <ContraIndicationToTreatment treatmentPeriod={tp} setTreatmentId={setTreatmentId} />
              <SymptomsDetails treatmentPeriod={tp} setTreatmentId={setTreatmentId} />
              <SymptomsAndLocations treatmentPeriod={tp} setTreatmentId={setTreatmentId} />
              <ReferringPhysicianAndUnit treatmentPeriod={tp} setTreatmentId={setTreatmentId} />
            </React.Fragment>
          ),
          count: 1,
          disabled: formContext?.editing && i !== selected,
        })) as []
      }
    </HistoryTabs>
  );
};

export default withHistoryContext(NINMTHistory);
