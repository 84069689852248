import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  Theme,
  styled,
  useTheme,
} from '@mui/material';
import * as React from 'react';

import { Container, Item } from 'Components/Grid';
import ActionButton, { IActionButton } from 'Components/ActionButton';
import { omit } from 'Utility/ramdaReplacement';

const defaultDialogProps = {
  maxWidth: 'sm' as const,
  fullWidth: true,
  PaperProps: {
    square: true,
  },
};

const dialogTitleStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: '1.5rem 2.0rem',
  textTransform: 'uppercase' as const,
});
const dialogContentStyles = (type: IThemedDialog['type']) => ({
  padding: '1.5rem 2.0rem',
  ...(type === 'info' || type === 'confirm' ? { fontSize: '2rem', textAlign: 'center' as const } : {}),
});
const dialogActionsStyles = () => ({
  padding: '1.5rem 2.0rem',
});

const CloseIcon = styled(Close)({
  display: 'block',
  cursor: 'pointer',
});

const ThemedDialog = ({
  open,
  type = 'dialog',
  title,
  children,
  onClose,
  closeTimeout,
  closeOnOutsideClick = false,
  dialogActions,
  dialogActionsJustify = 'space-between',
  dialogProps,
  dialogTitleProps,
  dialogContentProps,
  dialogActionsProps,
}: IThemedDialog) => {
  const theme = useTheme();

  React.useEffect(() => {
    if (closeTimeout) {
      setTimeout(() => {
        onClose?.();
      }, closeTimeout);
    }
  }, []);

  return (
    <Dialog
      open={open}
      {...defaultDialogProps}
      maxWidth={type === 'info' || type === 'confirm' ? 'xs' : undefined}
      {...dialogProps}
      onClose={closeOnOutsideClick ? onClose : undefined}
    >
      {title && (
        <DialogTitle style={dialogTitleStyles(theme)} {...dialogTitleProps}>
          <Container alignItems="center">
            <Item xs={true}>
              <h3 style={{ margin: 0 }}>{title}</h3>
            </Item>
            {onClose && (
              <Item>
                <CloseIcon onClick={onClose} />
              </Item>
            )}
          </Container>
        </DialogTitle>
      )}

      <DialogContent
        style={{ ...dialogContentStyles(type), ...(dialogContentProps?.style || {}) }}
        {...(dialogContentProps ? omit(['style'], dialogContentProps) : {})}
      >
        <div style={{ whiteSpace: 'pre-line' }}>{children}</div>
      </DialogContent>

      {(dialogActions || (type === 'info' && !closeTimeout)) && (
        <DialogActions style={dialogActionsStyles()} {...dialogActionsProps}>
          <Container justifyContent={type === 'info' ? 'center' : dialogActionsJustify}>
            {type === 'info' ? (
              <Item>
                <ActionButton text="general.close" onClick={onClose} width={11} height={4} fontSize={16} />
              </Item>
            ) : (
              (dialogActions || []).map((button, i) => (
                <Item key={i}>
                  <ActionButton width={11} height={4} fontSize={16} {...button} />
                </Item>
              ))
            )}
          </Container>
        </DialogActions>
      )}
    </Dialog>
  );
};

export interface IThemedDialog {
  open: boolean;

  /** Type of of the dialog.
   * * Info: Simple info dialog with a close button
   * * Confirm: Dialog with cancel and accept buttons
   * * Dialog: Default type
   */
  type?: 'info' | 'confirm' | 'dialog';

  /** Top bar title text */
  title?: React.ReactNode;

  /** Dialog contents */
  children: Required<React.ReactNode>;

  /** Closing function. Called by close info type close button or X in title bar */
  onClose?: () => void;
  /** Timeout milliseconds to close dialog. If set, call onClose after time */
  closeTimeout?: number;
  /** If onClose is defined, close the dialog by clicking outside of it */
  closeOnOutsideClick?: boolean;

  dialogActions?: Array<IActionButton>;
  dialogActionsJustify?: 'space-between' | 'center';

  dialogProps?: Partial<DialogProps>;
  dialogTitleProps?: DialogTitleProps;
  dialogContentProps?: DialogContentProps;
  dialogActionsProps?: DialogActionsProps;
}

export default ThemedDialog;
