export const steps = [
  'headControl',
  'sitting',
  'voluntaryGrasp',
  'abilityToKickSupine',
  'rolling',
  'crawling',
  'standing',
  'walking',
] as Array<string>;

export const getStepContent = (
  stepName: string,
): { opts: number[]; optsLocale: string; info?: string; assets?: number[]; height?: number } => {
  switch (stepName) {
    case 'headControl':
      return { opts: [0, 1, 2], optsLocale: 'headControl' };
    case 'sitting':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'sitting', assets: [1, 2, 3, 4], height: 21 };
    case 'voluntaryGrasp':
      return { opts: [0, 1, 2, 3], optsLocale: 'voluntaryGrasp' };
    case 'abilityToKickSupine':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'abilityToKickSupine', assets: [2, 3, 4], height: 21 };
    case 'rolling':
      return { opts: [0, 1, 2, 3], optsLocale: 'rolling' };
    case 'crawling':
      return { opts: [0, 1, 2, 3, 4], optsLocale: 'crawling', assets: [1, 2, 3, 4], height: 23 };
    case 'standing':
      return { opts: [0, 1, 2, 3], optsLocale: 'standing' };
    case 'walking':
      return { opts: [0, 1, 2, 3], optsLocale: 'walking' };
    default:
      return { opts: [0], optsLocale: '' };
  }
};
