import * as React from 'react';
import { TDocument } from '../../utils';
import MMSEForm from './MMSE';
import MOCAForm from './MOCA';
import TMTForm from './TMT';
import SDMTForm from './SDMT';
import CDRForm from './CDR';

const CognitionForm = ({ formData, document, viewing = false }: IOwnProps): JSX.Element | null =>
  document?._type === 'moca' ? (
    <MOCAForm formData={formData} viewing={viewing} />
  ) : document?._type === 'mmse' ? (
    <MMSEForm formData={formData} viewing={viewing} />
  ) : document?._type === 'tmt' ? (
    <TMTForm formData={formData} viewing={viewing} />
  ) : document?._type === 'sdmt' ? (
    <SDMTForm formData={formData} viewing={viewing} />
  ) : document?._type === 'cdr' ? (
    <CDRForm formData={formData} viewing={viewing} />
  ) : null;

interface IOwnProps {
  formData: IFormData<TDocument>;
  document?: TDocument;
  viewing?: boolean;
}

export default CognitionForm;
