import * as React from 'react';
import { getDocumentsByType, TDocument } from '../../utils';

import ComorbidityForm from './ComorbidityForm';
import ContraceptionForm from './ContraceptionForm';
import HospitalizationForm from './HospitalizationForm';
import PregnancyForm from './PregnancyForm';
import SickleaveForm from './SickleaveForm';

const Form = ({
  documents,
  document,
  formData,
  editing,
  medicationNames,
  diagnosis,
  platform,
}: IOwnProps): JSX.Element | null => {
  return document ? (
    document._type === 'comorbidity' ? (
      <ComorbidityForm
        documents={getDocumentsByType(['comorbidity'], documents) as Array<IComorbidity>}
        formData={formData as IFormData<IComorbidity>}
        editing={editing}
        medicationNames={medicationNames}
        diagnosis={diagnosis}
      />
    ) : document?._type === 'hospitalization' ? (
      <HospitalizationForm
        documents={getDocumentsByType(['hospitalization'], documents) as Array<IHospitalization>}
        formData={formData}
        editing={editing}
        medicationNames={medicationNames}
        platform={platform}
      />
    ) : document?._type === 'pregnancy' ? (
      <PregnancyForm
        documents={getDocumentsByType(['pregnancy'], documents) as Array<IPregnancy>}
        formData={formData}
        editing={editing}
      />
    ) : document?._type === 'contraception' ? (
      <ContraceptionForm formData={formData} editing={editing} />
    ) : document?._type === 'sickLeave' ? (
      <SickleaveForm formData={formData} editing={editing} diagnosis={diagnosis} />
    ) : null
  ) : null;
};
interface IOwnProps {
  documents: Array<TDocument>;
  document?: TDocument;
  formData: IFormData<IComorbidity | IHospitalization | IPregnancy | IContraception | ISickLeave>;
  editing: boolean;
  medicationNames: string[];
  diagnosis: string[];
  platform?: Platform;
}

export default Form;
