import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../components/FormRow';
import FormSection from '../../../../components/FormSection';
import InputHandler from '../../../../components/InputHandler';

const optionFormatter =
  (opt: number | string) =>
  (name: number | string): JSX.Element => (
    <React.Fragment>
      {name + ' - '}
      <FormattedMessage id={`generalhealth.${opt}.${name}`} />
    </React.Fragment>
  );

const GeneralHealthForm = ({ editing, formData }: IOwnProps): JSX.Element => {
  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="general.date">
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="date"
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
      </FormSection>
      <FormSection header="generalhealth.clinicianRatingTitle">
        <FormRow title="generalhealth.clinicianRating" id={'clinicianRating'}>
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="clinicianRating"
            formData={formData}
            options={['1', '2', '3', '4', '5', '6', '7']}
            optionFormatter={optionFormatter('opts.clinicianRating')}
          />
        </FormRow>
      </FormSection>
      <FormSection header="generalhealth.patientRatingTitle">
        <FormRow title="generalhealth.patientRating" id={'patientRating'}>
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="patientRating"
            formData={formData}
            options={['1', '2', '3', '4', '5', '6', '7']}
            optionFormatter={optionFormatter('opts.patientRating')}
          />
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  editing?: string;
  formData: IFormData<IGeneralHealth>;
}

export default GeneralHealthForm;
