import * as React from 'react';

import { TDocument } from '../../utils';

import FVCForm from './FVC';
import ENMGForm from './ENMG';
import VEPForm from './VEP';
import SEPForm from './SEP';
import BAEPForm from './BAEP';
import OCTSCANForm from './OCTSCAN';
import ECGForm from './ECG';
import EchocardiographyForm from './Echocardiography';
import BloodPressureForm from './BloodPressure';
import HeartRateForm from './HeartRate';
import NocturnalPolygraphyForm from './NocturnalPolygraphy';

const ClinicalStudyForm = ({ formData, document }: IOwnProps): JSX.Element | null =>
  document?._type === 'fvc' ? (
    <FVCForm formData={formData} />
  ) : document?._type === 'enmg' ? (
    <ENMGForm formData={formData} />
  ) : document?._type === 'vep' ? (
    <VEPForm formData={formData} />
  ) : document?._type === 'sep' ? (
    <SEPForm formData={formData} />
  ) : document?._type === 'baep' ? (
    <BAEPForm formData={formData} />
  ) : document?._type === 'octscan' ? (
    <OCTSCANForm formData={formData as IFormData<IOCTSCAN>} />
  ) : document?._type === 'ecg' ? (
    <ECGForm formData={formData} />
  ) : document?._type === 'echocardiography' ? (
    <EchocardiographyForm formData={formData} />
  ) : document?._type === 'bloodPressure' ? (
    <BloodPressureForm formData={formData} />
  ) : document?._type === 'heartRate' ? (
    <HeartRateForm formData={formData} />
  ) : document?._type === 'nocturnalPolygraphy' ? (
    <NocturnalPolygraphyForm formData={formData} />
  ) : null;
interface IOwnProps {
  formData: IFormData<TDocument>;
  document?: TDocument;
  viewing?: boolean;
}

export default ClinicalStudyForm;
