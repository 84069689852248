import { DialogTitle, DialogContent, DialogActions, Dialog } from '@mui/material';
import { formatPartialDate, formatTime } from 'neuro-utils';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import ActionButton from '../../../../../components/ActionButton';
import { Container, Item } from '../../../../../components/Grid';
import HistoryRow from '../../../../../components/HistoryRow';
import HistoryRowBasicItem from '../../../../../components/HistoryRowBasicItem';
import HistoryRowControls from '../../../../../components/HistoryRowControls';

import { dialogTitle, dialogContent, dialogActions, dialogCancel } from '../../../../../config/theme/componentTheme';
import { actions } from '../../../../../store/appointments';
import { sortDocuments } from '../../../../../utility/randomUtil';
import AppointmentForm from '../../Form/appointmentForm';
import { useAppDispatch, useAppSelector } from 'Store/index';

const AppointmentList = (): JSX.Element => {
  const appointments = useAppSelector((s: IState) => s.appointments.appointments);
  const [editingAppointment, setEditingAppointment] = React.useState<string | null>(null);
  const [appointmentData, setAppointmentData] = React.useState<Partial<IAppointment> | null>(null);

  const changeAppointmentData = (values: TOnChangeValues): void =>
    setAppointmentData({ ...appointmentData, ...values });

  const setEditingAppointments = (app: IAppointment) => (): void => {
    setEditingAppointment(app.id);
    setAppointmentData(appointments?.find((a) => a.id === app.id) || null);
  };

  const unsetEditingAppointments = (): void => {
    setEditingAppointment(null);
    setAppointmentData(null);
  };

  const dispatch = useAppDispatch();

  const saveAppointment = (): void => {
    unsetEditingAppointments();
    appointmentData && actions.changeAppointmentData(appointmentData as IAppointment)(dispatch);
  };

  const deleteAppointment = (id: string) => (): void => {
    actions.deleteExistingAppointment(id)(dispatch);
  };

  const sortedAppointments = appointments?.toSorted((n1, n2) =>
    sortDocuments([
      { type: 'date', sortField: 'startDate' },
      { type: 'time', sortField: 'startTime' },
    ])(n1, n2),
  );
  return (
    <>
      {sortedAppointments?.map((a) => (
        <HistoryRow
          key={a.id}
          headerText={`${formatPartialDate(a.startDate)}${a.startTime ? ` (${formatTime(a.startTime)})` : ''}${
            a.endDate ? ` - ${formatPartialDate(a.endDate)}${a.endTime ? ` (${formatTime(a.endTime)})` : ''}` : ''
          }`}
          rowButton={
            <HistoryRowControls
              document={{ ...a, _type: 'appointment', _id: a.id, _ownerOrg: a.creatorOrg }}
              startEdit={setEditingAppointments as (d: IAppointment) => () => void}
              deleteContent={<FormattedMessage id="appointments.shallDelete" />}
              deleteHandler={deleteAppointment(a.id)}
            />
          }
          controlsMargin={false}
        >
          <Container>
            <Item xs={2}>
              <HistoryRowBasicItem
                header={<FormattedMessage id="appointments.status" />}
                value={a.status && <FormattedMessage id={`appointments.opts.${a.status}`} />}
              />
            </Item>
            <Item xs={2}>
              <HistoryRowBasicItem
                header={<FormattedMessage id="appointments.externaAppointmentType" />}
                value={a.externaAppointmentType || undefined}
              />
            </Item>
            <Item xs={6}>
              <HistoryRowBasicItem
                header={<FormattedMessage id="appointments.comments" />}
                value={a.comments ?? undefined}
              />
            </Item>
          </Container>
        </HistoryRow>
      ))}
      <Dialog open={!!editingAppointment} maxWidth="lg" fullWidth={true} PaperProps={{ square: true }}>
        <DialogTitle style={dialogTitle}>
          <FormattedMessage id="appointments.editAppointment" />
        </DialogTitle>
        <DialogContent style={dialogContent}>
          <AppointmentForm data={appointmentData} changeData={changeAppointmentData} />
        </DialogContent>
        <DialogActions style={dialogActions}>
          <Container alignItems="center">
            <Item xs={6}></Item>
            <Item xs={6}>
              <Container justifyContent="flex-end" alignItems="center">
                <Item>
                  <div style={dialogCancel} onClick={unsetEditingAppointments}>
                    <FormattedMessage id="general.cancel" />
                  </div>
                </Item>
                <Item>
                  <ActionButton text={'general.accept'} onClick={saveAppointment} width={15} height={4} fontSize={16} />
                </Item>
              </Container>
            </Item>
          </Container>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AppointmentList;
