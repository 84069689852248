import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, PaperProps } from '@mui/material';
import { includes, uniq } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'Store/index';

import { dialogActions, dialogCancel, dialogContent, dialogTitle } from '../../config/theme/componentTheme';
import { actions } from '../../store/documents/actions';

import ActionButton from '../ActionButton';
import FormRow from '../FormRow';
import InputHandler from '../InputHandler';

const DialogPaper = (props: PaperProps): JSX.Element => <Paper square={true} {...props} />;

const platformEndedReasons = (platform?: string): string[] => {
  if (platform === 'ms') return ['diseaseNatureChange', 'antibodies', 'pregnancy', 'jcvirus'];
  if (platform === 'sma' || platform === 'dmd') return ['diseaseNatureChange'];
  return [];
};

const optionFormatter = (n: string | number): JSX.Element => <FormattedMessage id={`medication.opts.${n}`} />;

const MedicationEndReasonDialog = ({ document, openState }: IMedicationEndReasonDialogProps): JSX.Element => {
  const platform = useSelector((s: { session?: ISessionStore }) => s.session?.platforms?.selected);

  const [open, setOpen] = openState;

  const { endReason, endReasonPrimary, endReasonOther } = document;

  const [thisFormData, setThisFormData] = React.useState<IEndReason>({ endReason, endReasonPrimary, endReasonOther });

  const onChange = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name] as string[];
    setThisFormData({
      ...thisFormData,
      endReasonPrimary:
        name === 'endReason' && !includes(thisFormData.endReasonPrimary, Array.isArray(value) ? value : [])
          ? undefined
          : thisFormData.endReasonPrimary,
      [name]: value,
    });
  };

  const dispatch = useDispatch();
  const saveHandler = (): void => {
    if (document._id) {
      actions.medicationEndReason({ name: 'medication', id: document._id }, thisFormData, document)(dispatch);
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} maxWidth={'md'} fullWidth={true} PaperComponent={DialogPaper}>
      <DialogTitle style={dialogTitle}>
        <FormattedMessage id={'medication.endedReason'} />
      </DialogTitle>
      <DialogContent style={dialogContent}>
        <FormRow title="medication.endedReason">
          <InputHandler
            type="Checkbox"
            editing={true}
            name="endReason"
            formData={{ document: thisFormData, onChange: onChange }}
            options={uniq([
              'inefficient',
              'adverseEffects',
              ...platformEndedReasons(platform),
              'compliance',
              'other',
              'unknown',
              ...(thisFormData.endReason || []), // Currently selected reasons added as options
            ])}
            optionFormatter={optionFormatter}
          />
        </FormRow>
        <FormRow
          title="medication.endedReasonPrimary"
          condition={thisFormData.endReason && thisFormData.endReason?.length > 1}
        >
          <InputHandler
            type="Select"
            editing={true}
            name="endReasonPrimary"
            formData={{ document: thisFormData, onChange: onChange }}
            options={thisFormData.endReason || []}
            optionFormatter={optionFormatter}
            placeholder="medication.endedReasonPrimaryPlaceholder"
          />
        </FormRow>
        <FormRow title="medication.endedReasonOther">
          <InputHandler
            type="TextArea"
            editing={true}
            name="endReasonOther"
            formData={{
              document: thisFormData,
              onChange: onChange,
            }}
            placeholder={'medication.pausationAdditionalInfoPlaceholder'}
          />
        </FormRow>
      </DialogContent>
      <DialogActions style={dialogActions}>
        <div style={dialogCancel} onClick={(): void => setOpen(false)}>
          <FormattedMessage id="general.cancel" />
        </div>
        <ActionButton
          text="general.save"
          onClick={saveHandler}
          width={15}
          height={4}
          fontSize={16}
          disabled={!thisFormData.endReason || thisFormData.endReason.length === 0}
        />
      </DialogActions>
    </Dialog>
  );
};

interface IEndReason extends IMedicationEndingReason {
  _id?: string;
}

interface IMedicationEndReasonDialogProps {
  document: IMedication;
  openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

export default MedicationEndReasonDialog;
