import * as React from 'react';
import { useIntl } from 'react-intl';

import { Container, Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';
import { getPatientAgeInMonthsWithOneDecimal } from '../../../../utility/patientInfo';
import { exists, formatPartialDate } from 'neuro-utils';
import { isMfmComplete, showPatientAgeInMonthsOnForm, mfmScore, isMfmManualComplete } from '../../utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const MFM = ({ documents, startEdit, view }: IOwnProps): JSX.Element => {
  const intl = useIntl();
  const fm = (id?: string, values?: { [key: string]: string }): string =>
    id ? intl.formatMessage({ id: id }, values) : '';

  return (
    <HistorySection
      headerText={fm('motor.mfm')}
      newButton={
        <DocumentCreationButton
          name="mfm"
          text="motor.newMfm"
          onClick={startEdit({}, 'mfm')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={2}>{fm('general.date')}</Item>
            <Item xs={2}>{fm('motor.mfm32OrMfm20Short')}</Item>
            <Item xs={6}>{fm('motor.score')}</Item>
          </>
        }
        contentFormat={(d: IMFM): JSX.Element => (
          <>
            <Item xs={2} id="mfmDate">
              {showPatientAgeInMonthsOnForm(d.date)
                ? fm('motor.dateAndMonth', {
                    date: formatPartialDate(d.date),
                    months: getPatientAgeInMonthsWithOneDecimal(d.date),
                  })
                : formatPartialDate(d.date)}
            </Item>
            <Item xs={2}>
              {exists(d.mfm32OrMfm20) && d.mfm32OrMfm20 === 'mfm20'
                ? 'MFM20'
                : exists(d.mfm32OrMfm20) && d.mfm32OrMfm20 === 'mfm32'
                  ? 'MFM32'
                  : '-'}
            </Item>
            <Item xs={6}>
              {isMfmManualComplete(d) || isMfmComplete(d) ? (
                <Container>
                  {Object.keys(mfmScore(d)).map((k) => (
                    <Item key={k} xs={3}>
                      <div style={{ fontWeight: 100 }}>{k ? fm(`motor.opts.mfm.${k}`) : ''}</div>
                      <div>{(mfmScore(d) as { [key: string]: string })[k]}</div>
                    </Item>
                  ))}
                </Container>
              ) : (
                '-'
              )}
            </Item>
          </>
        )}
        makeControls
        historyRowControls={(d: IControlProps) => <HistoryRowControls document={d} startEdit={startEdit} view={view} />}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IMFM[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default MFM;
