import FormRow from 'Components/FormRow';
import FormSection from 'Components/FormSection';
import InputHandler from 'Components/InputHandler';
import { IFormContext, withFormContext } from 'Containers/FormContextHandler';
import { sortPartialDate } from 'neuro-utils';
import * as React from 'react';

const ReferringPhysicianAndUnitForm = ({
  documents,
  editing,
  formData,
  fm,
  consumeTreatmentId,
}: IFormContext & {
  consumeTreatmentId: () => string | null;
}): JSX.Element => {
  const treatmentId = consumeTreatmentId() || formData.document?.treatmentPeriodId;

  React.useEffect(() => {
    if (treatmentId) {
      // If treatment period id is available (consumed), set the treatment id
      if (!formData.document?.treatmentPeriodId) {
        formData.onChange?.({ treatmentPeriodId: treatmentId });
      }
    } else {
      const nearestId =
        Array.isArray(documents) &&
        documents
          .slice()
          .sort((d1, d2) => sortPartialDate(d2.date, d1.date))
          .find((d) => d._type === 'ninmtTreatmentPeriod' && sortPartialDate(formData.document.date, d.date) >= 0)?._id;
      formData.onChange?.({ treatmentPeriodId: nearestId ?? '' });
    }
  }, [treatmentId, formData]);

  const treatmentPeriodsSorted = documents
    ?.filter((d) => d._type === 'ninmtTreatmentPeriod')
    .sort((a, b) => sortPartialDate(a.date, b.date));

  const currentTreatmentNumber = (treatmentPeriodsSorted?.findIndex((d) => d._id === treatmentId) ?? 0) + 1;

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="diagnosis.ninmt.treatment">{`${fm('diagnosis.ninmt.title')} ${
          currentTreatmentNumber ?? '-'
        }`}</FormRow>
        <FormRow title="diagnosis.ninmt.referringPhysicianAndUnit.referringPhysician">
          <InputHandler
            type="TextField"
            name="referringPhysician"
            formData={formData}
            editing={!!editing}
            placeholder="diagnosis.ninmt.referringPhysicianAndUnit.referringPhysicianPlaceholder"
          />
        </FormRow>
        <FormRow title="diagnosis.ninmt.referringPhysicianAndUnit.unit">
          <InputHandler
            type="TextField"
            name="unit"
            formData={formData}
            editing={!!editing}
            placeholder="diagnosis.ninmt.referringPhysicianAndUnit.unitPlaceholder"
          />
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};

export default withFormContext(ReferringPhysicianAndUnitForm);
