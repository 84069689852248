import * as React from 'react';
import { otherOnChange } from '../../utils';
import UpkeepDose from './UpkeepDose';

/**
 * Component for drawing table of infusion upkeep doses.
 *
 *  - If no upkeep doses exists then initialize one with no values
 *  - Handles top layer onChange event handling for child components
 *  - Maps one UpkeepDose element for every upkeep dose in infusion doses
 *
 * @param { IUpkeepDosesProps } Properties
 * @returns { JSX.Element } Upkeep doses table element
 */
const UpkeepDoses = ({ index, formData, str }: IUpkeepDosesProps): JSX.Element => {
  const regimen = formData?.document?.regimen?.[index] as IRegimenBasics & IRegimenCustom;
  const upkeepDoses = regimen.infusionDoses?.upkeepDoses ? [...regimen.infusionDoses.upkeepDoses] : [];

  const onChangeDoses =
    (i: number) =>
    (values: TOnChangeValues): void => {
      let upkeepDosesChanged = structuredClone(upkeepDoses);
      if (upkeepDosesChanged?.[i]) {
        upkeepDosesChanged[i] = { ...upkeepDosesChanged[i], ...values };
      } else {
        upkeepDosesChanged = [{ ...values }];
      }
      otherOnChange(
        formData.onChange,
        index,
        regimen,
        formData?.document?.regimen,
      )({ infusionDoses: { ...regimen.infusionDoses, upkeepDoses: upkeepDosesChanged } });
    };

  const deleteDosage = (i: number): void => {
    if (upkeepDoses?.[i]) {
      upkeepDoses.splice(i, 1);
      otherOnChange(
        formData.onChange,
        index,
        regimen,
        formData?.document?.regimen,
      )({ infusionDoses: { ...regimen.infusionDoses, upkeepDoses } });
    }
  };

  React.useEffect(() => {
    if (!upkeepDoses || upkeepDoses?.length === 0) {
      onChangeDoses(0)({
        upkeepDose: undefined,
        upkeepDoseFrom: undefined,
        upkeepDoseTo: undefined,
      });
    }
  }, [upkeepDoses]);

  return (
    <React.Fragment>
      {upkeepDoses &&
        upkeepDoses.length > 0 &&
        upkeepDoses.map(
          (
            d: {
              upkeepDose?: number;
              upkeepDoseFrom?: Time;
              upkeepDoseTo?: Time;
            },
            i: number,
          ): JSX.Element => (
            <UpkeepDose
              key={`upkeepdose-${i}-${upkeepDoses?.length}`}
              d={d}
              i={i}
              onChange={onChangeDoses}
              deleteDosage={deleteDosage}
              prevTime={i > 0 && upkeepDoses?.[i - 1].upkeepDoseTo ? upkeepDoses[i - 1].upkeepDoseTo : undefined}
              str={str}
            />
          ),
        )}
    </React.Fragment>
  );
};

interface IUpkeepDosesProps {
  index: number;
  formData: IFormData<IMedication>;
  str?: number;
}

export default UpkeepDoses;
