import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import colors from '../../../../../config/theme/colors';

import { mocaConfig, mocaTaskSpecificScore, mocaTotalScore } from '../../../utils/index';
import { Container, Item } from 'Components/Grid';

const optionFormatter =
  (opt: number | string) =>
  (name: number | string): JSX.Element => {
    return <FormattedMessage id={`cognition.moca.${opt}.${name}`} />;
  };

const MOCAForm = ({ formData, viewing }: IOwnProps): JSX.Element => (
  <React.Fragment>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={!viewing}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="cognition.unableToPerform">
      <InputHandler
        type="CheckboxSingle"
        name="unableToPerform"
        editing={!viewing}
        formData={formData}
        option={'true'}
        showLabel={false}
        optionFormatter={optionFormatter('unableToPerform')}
        dependentFieldsList={(): string[] => [
          'unableToPerformReason',
          'totalScoreOnly',
          'totalScore',
          'visuospatialExecutive',
          'naming',
          'memory',
          'attentionNumbers',
          'attentionLetters',
          'attentionSubstraction',
          'language',
          'abstraction',
          'delayedRecall',
          'orientation',
          'twelveYeasOrLessEducation',
        ]}
      />
    </FormRow>
    <FormRow title="general.reason" condition={formData.document.unableToPerform === true}>
      <InputHandler
        type="TextArea"
        name="unableToPerformReason"
        formData={formData}
        editing={!viewing}
        placeholder="general.reason"
        width={40}
      />
    </FormRow>
    {formData.document.unableToPerform !== true ? (
      <>
        <FormRow title="cognition.totalScoreOnly">
          <InputHandler
            type="Checkbox"
            editing={!viewing}
            name="totalScoreOnly"
            formData={formData}
            options={['totalScoreOnly']}
            optionFormatter={(name: number | string): JSX.Element => <FormattedMessage id={`cognition.${name}`} />}
          />
        </FormRow>
        <FormRow title="cognition.download">
          <a href="/files/MOCA-Test-Finnish.pdf" target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="cognition.downloadHere" />
          </a>
        </FormRow>
        {formData.document?.totalScoreOnly?.[0] === 'totalScoreOnly' ? (
          <FormSection>
            <FormRow title="cognition.totalScore">
              <InputHandler
                type="NumberField"
                editing={!viewing}
                name="totalScore"
                formData={formData}
                placeholder="cognition.totalScore"
                staticValue={formData.document.totalScore}
                min={0}
                max={30}
              />
            </FormRow>
          </FormSection>
        ) : (
          <>
            <FormSection>
              <>
                <FormRow title="cognition.moca.description">
                  <div />
                </FormRow>
                {mocaConfig.map((obj: { name: string; options: string[]; description: boolean }) => (
                  <React.Fragment key={obj.name}>
                    {obj.name != 'memory' ? (
                      <FormRow
                        title={`cognition.moca.${obj.name}.title`}
                        description={
                          obj.description === true ? (
                            <FormattedMessage id={`cognition.moca.${obj.name}.description`} />
                          ) : undefined
                        }
                      >
                        <Container>
                          <Item xs={8}>
                            <InputHandler
                              type="Checkbox"
                              editing={!viewing}
                              name={obj.name}
                              formData={formData}
                              options={obj.options}
                              optionFormatter={optionFormatter(obj.name)}
                            />
                          </Item>
                          <Item xs={4}>{mocaTaskSpecificScore(obj.name, obj.options.length, formData.document)}</Item>
                        </Container>
                      </FormRow>
                    ) : (
                      <FormRow
                        title={`cognition.moca.${obj.name}.title`}
                        description={
                          obj.description === true ? (
                            <FormattedMessage id={`cognition.moca.${obj.name}.description`} />
                          ) : undefined
                        }
                      >
                        &nbsp;
                      </FormRow>
                    )}
                  </React.Fragment>
                ))}
              </>
            </FormSection>
            <FormSection>
              <FormRow
                title="cognition.moca.twelveYearsOrLessEducation.title"
                description={<FormattedMessage id={`cognition.moca.twelveYearsOrLessEducation.description`} />}
              >
                <InputHandler
                  type="Checkbox"
                  editing={!viewing}
                  name="twelveYearsOrLessEducation"
                  formData={formData}
                  options={['yes']}
                  optionFormatter={(name: number | string): JSX.Element => <FormattedMessage id={`general.${name}`} />}
                />
              </FormRow>
              <FormRow
                title="cognition.totalScore"
                description={<FormattedMessage id="cognition.moca.totalScoreDescription" />}
              >
                <div
                  style={{
                    fontSize: '2rem',
                    fontWeight: 600,
                    color: colors.primary,
                    marginRight: '2rem',
                    marginLeft: '2lem',
                  }}
                >
                  {mocaTotalScore(formData.document)}
                </div>
              </FormRow>
            </FormSection>
          </>
        )}
      </>
    ) : undefined}
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<IMOCA>;
  viewing?: boolean;
}

export default MOCAForm;
