import * as React from 'react';
import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import PlatformConditional from '../../../components/PlatformConditional';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import { filterDocs, getHeaderId, TDocument } from '../utils';
import Form from './Form';
import PBASHistory from './HistoryRowData/PBAS';

const BehaviourHistory = (
  documents: Array<TDocument>,
  startEdit: (document: TAnyObject) => (e: React.MouseEvent<Element, MouseEvent>) => void,
  view?: IView,
): JSX.Element => {
  const PBASDocs = filterDocs('pbaS', documents);

  return (
    <React.Fragment>
      <PlatformConditional platform={'huntington'}>
        <React.Fragment>
          <PBASHistory documents={PBASDocs as IPBAS[]} startEdit={startEdit} view={view} />
        </React.Fragment>
      </PlatformConditional>
    </React.Fragment>
  );
};

const Behaviour = ({ documents }: IOwnProps): JSX.Element => (
  <FormEditingHandler name="behaviour" documents={documents}>
    {(editing, startEdit, formData, view): JSX.Element => (
      <DocumentWrapper>
        <DocumentHeader
          name={'behaviour'}
          headerId={getHeaderId(documents, editing, view?.viewing)}
          editing={editing}
          editButtons={<div />}
        />
        {editing ? (
          <Form formData={formData} document={documents && documents.find((d: TDocument) => d._id === editing)} />
        ) : null}
        {view?.viewing ? (
          <Form
            formData={{ document: view.document, onChange: (): string => '' }}
            document={documents && documents.find((d: TDocument) => d._id === view?.viewing)}
            viewing={!!view.viewing}
          />
        ) : null}
        {!editing && !view?.viewing ? BehaviourHistory(documents, startEdit, view) : null}
      </DocumentWrapper>
    )}
  </FormEditingHandler>
);

interface IOwnProps {
  documents: Array<TDocument>;
}

export default Behaviour;
