import DocumentCreationButton from 'Components/DocumentCreationButton';
import * as React from 'react';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import Form from './Form';
import NotesHistory from './HistoryRowData';

const Notes = ({ documents }: IOwnProps): JSX.Element => {
  const notesDocs = documents.filter((d) => d._type === 'notes');
  return (
    <FormEditingHandler name="notes" documents={notesDocs}>
      {(editing, startEdit, formData, view): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name={'notes'}
            headerId={'notes.title'}
            editing={editing}
            infoText={!editing ? 'notes.infoText' : undefined}
            editButtons={
              <DocumentCreationButton
                name="notes"
                text={'general.new'}
                onClick={(e: React.MouseEvent): void => {
                  startEdit({}, 'notes')(e);
                }}
              />
            }
          />
          {editing ? <Form documents={documents} formData={formData} editing={editing} /> : null}
          {!editing && !view?.viewing ? <NotesHistory documents={documents} startEdit={startEdit} /> : null}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: Array<INotes | IRTMS | ITDCS>;
}

export default Notes;
