import * as React from 'react';
import { useIntl } from 'react-intl';
import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import QuestionStepper from '../../../../../components/QuestionStepper';
import { getJWTData } from '../../../../../utility/jwtAuthTools';
import { ssnAge } from 'neuro-utils';
import { steps as mfmSteps, getStepContent as getMfmSteps } from '../mfmSettings';
import { steps as mfmDisabledSteps, getStepContent as getMfmDisabledSteps } from '../mfmDisabledSettings';
import { actions } from '../../../../../store/form';
import { useAppDispatch as useDispatch } from 'Store/index';
import { Container, Item } from '../../../../../components/Grid';
import { mfmScore } from '../../../utils';
import InfoPopover from 'Components/InfoPopover';
import SimpleList from 'Components/SimpleList';
import { getMissingFields } from 'Utility/documentHandling';

/** Used to reset some fields when changing from mfm32 to mfm20 */
const temp = {
  raisesTheHead: undefined,
  sitsUp: undefined,
  isCapableOfMaintainingContact: undefined,
  maintainsSeatedPosition: undefined,
  placesBothHandsOnTopOfHead: undefined,
  reachesThePencil: undefined,
  picksUpAndHoldsTenCoins: undefined,
  picksUpPencil: undefined,
  tearsTheSheetOfPaper: undefined,
  raisesTheFoot: undefined,
  takesTenStepsForwardOnBothHeels: undefined,
  takesTenStepsForwardOnALine: undefined,
  hopsTenTimes: undefined,
};

const MFMForm = ({ formData, viewing, editing }: IOwnProps): JSX.Element => {
  const intl = useIntl();

  /**
   * Formatting function using ReactIntl formatMessage
   *
   * @param id Message to be formatted
   * @returns Format message or return empty string if message is not defined
   */
  const fm = (id?: string): string => (id ? intl.formatMessage({ id: id }) : '');

  /**
   * Format given MFM test option
   *
   * @param name Message to be formatted
   * @returns Either format option or return '-' string if option is not defined
   */
  const formatOptions = (name?: string | number): string => (name ? fm(`motor.opts.mfm.${name}`) : '-');

  // parse from age which items of the measurement shall be executed
  React.useEffect(() => {
    if (!('mfm32OrMfm20' in formData.document)) {
      const value: 'mfm20' | 'mfm32' = (ssnAge(getJWTData()?.patientssn ?? '') ?? 15) < 7 ? 'mfm20' : 'mfm32';
      formData.onChange && formData.onChange({ mfm32OrMfm20: value });
    }
  }, [formData]);

  const dispatch = useDispatch();

  const onExecutedTestChange = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];

    formData.onChange && formData.onChange({ [name]: value });
    editing && dispatch(actions.updateFormValuesBatch(temp, editing));
  };

  return (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="motor.tiredness">
        <InputHandler
          type="Radio"
          editing={!viewing}
          name="tiredness"
          formData={formData}
          options={['fitter', 'inItsUsualState', 'moreTired', 'muchMoreTired']}
          optionFormatter={formatOptions}
        />
      </FormRow>
      <FormRow title="motor.mfm32OrMfm20">
        <InputHandler
          type="Radio"
          editing={!viewing}
          name="mfm32OrMfm20"
          formData={{
            document: formData.document,
            onChange: onExecutedTestChange,
          }}
          options={['mfm20', 'mfm32']}
          optionFormatter={formatOptions}
        />
      </FormRow>
      <FormRow title="motor.download">
        <a href="/files/MFM-Test-English.pdf" target="_blank" rel="noopener noreferrer">
          {fm('motor.downloadHere')}
        </a>
        {/**
         * Tähän icon tai button josta saa ladattua lomakkeen
         *  */}
      </FormRow>
      <FormSection>
        <QuestionStepper
          viewing={viewing}
          formData={formData}
          pageType="motor"
          docType="mfm"
          steps={formData.document.mfm32OrMfm20 === 'mfm20' ? mfmDisabledSteps : mfmSteps}
          getStepContent={formData.document.mfm32OrMfm20 === 'mfm20' ? getMfmDisabledSteps : getMfmSteps}
        />
      </FormSection>
      <FormSection>
        <FormRow title="motor.patientCooperation">
          <InputHandler
            type="Radio"
            editing={!viewing}
            name="patientCooperation"
            formData={formData}
            options={['zero', 'medium', 'optimum']}
            optionFormatter={formatOptions}
          />
        </FormRow>
        <FormRow title="motor.comments">
          <InputHandler
            type="TextArea"
            editing={!viewing}
            name="comments"
            formData={formData}
            placeholder="motor.comments"
          />
        </FormRow>
      </FormSection>
      <FormSection>
        <FormRow title="motor.automaticScore">
          <Container>
            {Object.keys(mfmScore(formData.document)).map((k) => (
              <Item
                key={k}
                xs={3}
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
              >
                <div>{formatOptions(k)}</div>
                <div style={{ fontWeight: 600 }}>
                  {k === 'total' && mfmScore(formData.document, true).total === '-' ? (
                    <InfoPopover
                      text={
                        <SimpleList
                          title={fm('general.couldntCalculate')}
                          listItems={getMissingFields(
                            mfmSteps.map((f) => fm(`motor.labels.mfm.${f}`)),
                            formData.document,
                          )}
                        />
                      }
                      color="primary"
                    />
                  ) : (
                    (mfmScore(formData.document, true) as { [key: string]: string })[k]
                  )}
                </div>
              </Item>
            ))}
          </Container>
        </FormRow>
        <FormRow title="motor.manualScore">
          <Container>
            {['manualD1Score', 'manualD2Score', 'manualD3Score', 'manualTotalScore'].map((item) => (
              <Item key={item} xs={3}>
                <span>
                  <InputHandler
                    type="NumberField"
                    editing={!viewing}
                    name={item}
                    formData={formData}
                    precision={2}
                    width={10}
                    min={0}
                    max={100}
                  />
                </span>
                <span style={{ fontWeight: 600, fontSize: '1.8rem' }}> %</span>
              </Item>
            ))}
          </Container>
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<IMFM>;
  viewing: boolean;
  editing: any;
}

export default MFMForm;
