import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';

import { formatPartialDate } from 'neuro-utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const HeartRate = ({ documents, startEdit }: IOwnProps): JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id={'clinicalstudy.heartRate'} />}
      newButton={
        <DocumentCreationButton
          name="heartRate"
          text={'clinicalstudy.newHeartRate'}
          onClick={startEdit({}, 'heartRate')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={2}>
              <FormattedMessage id={'general.date'} />
            </Item>
            <Item xs={2}>
              <FormattedMessage id={'clinicalstudy.heartRate'} />
            </Item>
            <Item xs={6} />
          </>
        }
        contentFormat={(d: IHeartRate): JSX.Element => (
          <>
            <Item xs={2} style={{ fontWeight: '600' }}>
              {formatPartialDate(d.date ?? undefined)}
            </Item>
            <Item xs={2}>
              {d?.heartRate ? <FormattedMessage id="clinicalstudy.heartRateUnit" values={{ n: d?.heartRate }} /> : '-'}
            </Item>
            <Item xs={2} />
            <Item xs={3} />
            <Item xs={3}>
              <HistoryRowControls document={d} startEdit={startEdit} />
            </Item>
          </>
        )}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IHeartRate[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default HeartRate;
