import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { patientHasRespiratoryFailureDiagnosis, patientHasSleepApneaDiagnosis } from 'Routes/Treatment/utils';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import { EndForm } from '../components';

const OtherTreatment = ({ formData, view, documents }: IFormContext<IOtherTreatment, any>): JSX.Element => {
  const locPath = 'treatment.otherTreatment';

  const dgDocs = documents?.filter((d) => d._type === 'diagnosis') as IDiagnosis[];
  const sleepApneaDG = patientHasSleepApneaDiagnosis(dgDocs);
  const noSRdiag = !patientHasSleepApneaDiagnosis(dgDocs) && !patientHasRespiratoryFailureDiagnosis(dgDocs);

  const commonOpts = ['lifestyleTreatment', 'referralToNutritionist', 'referralToWeightControl'];
  const sleepApneaOpts = ['postureTreatment', 'psychiatristConsultation', 'otherTreatment'];

  return (
    <>
      <FormRow title="treatment.date">
        <InputHandler
          type="PartialDate"
          editing={!view?.viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
          dateHook={{ dateHookCeiling: formData.document.endDate }}
        />
      </FormRow>
      <FormSection header={`${locPath}.title`}>
        <FormRow title={`${locPath}.otherTreatmentType`}>
          <InputHandler
            type="Radio"
            editing={!view?.viewing}
            name="otherTreatmentType"
            formData={formData}
            options={[...commonOpts, ...(sleepApneaDG || noSRdiag ? sleepApneaOpts : [])]}
            optionFormatter={(o): string | JSX.Element => <FormattedMessage id={`${locPath}.opts.${o}`} />}
            dependentFieldsList={(): string[] => ['hasEnded', 'endDate']}
          />
        </FormRow>
        <FormRow title={`${locPath}.otherTreatmentDetails`}>
          <InputHandler
            type="TextArea"
            editing={!view?.viewing}
            name="otherTreatmentDetails"
            formData={formData}
            placeholder={`${locPath}.otherTreatmentDetailsPlaceholder`}
          />
        </FormRow>
      </FormSection>
      {['postureTreatment', 'otherTreatment'].includes(formData.document.otherTreatmentType ?? '') && (
        <EndForm formData={formData} edit={!view?.viewing} type="otherTreatment" />
      )}
    </>
  );
};

export default withFormContext(OtherTreatment);
