import { Container, Item } from 'Components/Grid';
import { exists } from 'neuro-utils';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistoryRowBasicItem from '../../../../components/HistoryRowBasicItem';

const fm = (id: string, values?: { [key: string]: string | number | null }): JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const NeurologicalStatusAndEDSSHistory = ({ d }: IOwnProps): JSX.Element => {
  const edssStep = exists(d?.edssStep)
    ? d.edssStep === 0 || d.edssStep === 10
      ? d.edssStep
      : d?.edssStep?.toFixed(1)
    : exists(d?.calculatedEdssStep)
      ? d.calculatedEdssStep === 0
        ? d.calculatedEdssStep
        : d?.calculatedEdssStep?.toFixed(1)
      : undefined;

  return (
    <>
      <Container alignItems="center">
        <Item xs={8}>
          <Container>
            <Item xs={3}>
              <HistoryRowBasicItem
                header={fm('neurologicalStatusAndEDSS.edssStep.title')}
                value={<span style={{ fontSize: '2rem' }}>{edssStep ?? '-'}</span>}
              />
            </Item>
            <Item xs={9}>
              {exists(edssStep) && (
                <HistoryRowBasicItem
                  header={fm('neurologicalStatusAndEDSS.edssStep.description')}
                  value={fm(`neurologicalStatusAndEDSS.edssStep.opts.${edssStep}`)}
                />
              )}
            </Item>
          </Container>
        </Item>
        <Item xs={4}></Item>
      </Container>
    </>
  );
};

interface IOwnProps {
  d: INeurologicalStatusAndEDSS;
}

export default NeurologicalStatusAndEDSSHistory;
