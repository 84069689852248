import * as React from 'react';
import { TDocument } from '../../utils';
import PBASForm from './PBAS';

const BehaviourForm = ({ formData, document, viewing = false }: IOwnProps): JSX.Element | null =>
  document?._type === 'pbaS' ? <PBASForm formData={formData} viewing={viewing} /> : null;

interface IOwnProps {
  formData: IFormData<IPBAS>;
  document?: TDocument;
  viewing?: boolean;
}

export default BehaviourForm;
