import { Container, Item } from 'Components/Grid';
import { exists, formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import EventStepper from '../../../../../components/EventStepper';
import { StepperHeaderFormInputPair, StepperHeaderValuePair } from '../../../../../components/EventStepper/components';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import Unit from '../../../../../components/Unit';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';

const ResponseEventContent = ({ response }: { response: IOxygenTherapyTreatmentResponses }): JSX.Element => (
  <React.Fragment>
    <StepperHeaderValuePair
      header={<FormattedMessage id={'treatment.oxygenTherapy.treatmentResponses.oxygenSaturation'} />}
      value={
        exists(response.oxygenSaturation) ? (
          <>
            {`${response.oxygenSaturation} `}
            <FormattedMessage id={'treatment.oxygenTherapy.treatmentResponses.percent'} />
          </>
        ) : null
      }
    />
  </React.Fragment>
);

const ResponseFormContent = ({
  thisResponse,
  onChange,
  mainDate,
}: {
  thisResponse?: IOxygenTherapyTreatmentResponses;
  onChange: IFormData['onChange'];
  mainDate?: PartialDate;
}): JSX.Element => {
  const locPath = 'treatment.oxygenTherapy.treatmentResponses';

  return (
    <>
      <StepperHeaderFormInputPair
        header={<FormattedMessage id={`general.date`} />}
        input={
          <InputHandler
            type="PartialDate"
            editing={true}
            name="date"
            formData={{
              onChange,
              document: {
                date: thisResponse?.date,
              },
            }}
            dateDefault={mainDate || 'now'}
            dateHook={{ dateHookFloor: mainDate }}
            isNotCancellable={true}
          />
        }
      />
      <StepperHeaderFormInputPair
        header={<FormattedMessage id={`${locPath}.oxygenSaturation`} />}
        input={
          <Unit unit={<FormattedMessage id={`${locPath}.percent`} />}>
            <InputHandler
              type="NumberField"
              editing={true}
              name="oxygenSaturation"
              formData={{
                onChange,
                document: {
                  oxygenSaturation: thisResponse?.oxygenSaturation,
                },
              }}
              precision={2}
              placeholder={`${locPath}.oxygenSaturation`}
            />
          </Unit>
        }
      />
    </>
  );
};

const locPath = 'treatment.oxygenTherapy.';

const OxygenTherapyTreatmentResponses = ({ formData, view }: IFormContext<IOxygenTherapy, any>): JSX.Element => {
  return (
    <FormSection header={locPath + 'treatmentResponses.' + 'title'}>
      <EventStepper
        name="treatmentResponses"
        formData={formData}
        stepLabelText={(d: IOxygenTherapyTreatmentResponses): string => formatPartialDate(d.date)}
        stepContent={(d: IOxygenTherapyTreatmentResponses): JSX.Element => <ResponseEventContent response={d} />}
        addNewTextHeader={locPath + 'treatmentResponses.newTreatmentResponse'}
        addNewTextButton={locPath + 'treatmentResponses.newTreatmentResponse'}
        previousEventsTextHeader={locPath + 'treatmentResponses.previousTreatmentResponses'}
        editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
          const thisResponse = formData.document.treatmentResponses?.[index];
          return (
            <ResponseFormContent
              thisResponse={thisResponse}
              onChange={onChange}
              mainDate={
                formData.document.treatmentResponses && formData.document.treatmentResponses.length < 2
                  ? formData.document.date
                  : undefined
              }
            />
          );
        }}
        viewing={!!view?.viewing}
        tableContent={(events: IOxygenTherapyTreatmentResponses[]) => (
          <Container alignItems="baseline" style={{ marginBottom: '4.5rem' }}>
            <Item xs={3} style={{ paddingLeft: '2rem' }}>
              <FormattedMessage id={locPath + 'treatmentResponses.oxygenSaturation'} />
            </Item>
            {events.slice().map((e: IOxygenTherapyTreatmentResponses, i: number, arr) => {
              return (
                <Item key={i} xs={i === arr.length - 1 ? 1 : 2} style={{ fontWeight: 600 }}>
                  {e && exists(e.oxygenSaturation) ? e.oxygenSaturation ?? 0 : '-'}{' '}
                  <FormattedMessage id={locPath + 'treatmentResponses.percent'} />
                </Item>
              );
            })}
          </Container>
        )}
        tableContentFilter={{
          eventFilter: (events: IOxygenTherapyTreatmentResponses[]) => events.slice().reverse(),
        }}
      />
    </FormSection>
  );
};

export default withFormContext(OxygenTherapyTreatmentResponses);
