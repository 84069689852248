import * as React from 'react';
import { exists, formatPartialDate } from 'neuro-utils';

import HistoryRow from '../../../../components/HistoryRow';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistorySection from '../../../../components/HistorySection';
import HistoryRowVerticalItem from '../../../../components/HistoryRowVerticalItem';
import { sortDocuments } from '../../../../utility/randomUtil';
import { IHistoryContext, withHistoryContext } from '../../../../containers/FormContextHandler';
import { SubheaderRow } from '../components';
import { Container, Item } from '../../../../components/Grid';
import { intersperse } from 'ramda';

const prevValue = (
  document: ISRChecklist,
  name: keyof ISRChecklist,
  prevDocs?: ISRChecklist[],
): { date?: PartialDate; value: string | string[] } | undefined => {
  let returnValue: { date?: PartialDate; value: string | string[] } = { value: '-' };
  if (document.date && prevDocs) {
    // All documents previous to editing documents date
    if (prevDocs.length === 0) {
      return undefined;
    }
    // Get first occurance of the value from previous docs
    prevDocs.some((d) => {
      if (exists(d[name])) {
        returnValue = { date: d['date'], value: d[name] as string | string[] };
        return true;
      } else return false;
    });
    return returnValue;
  } else {
    return returnValue;
  }
};

const makeValues = (
  name: keyof ISRChecklist,
  document: ISRChecklist,
  prevDocs?: ISRChecklist[],
  fm?: IHistoryContext['fm'],
) => {
  const optionFormatter = (o: string | number) => fm && fm('checklist.opts.' + o);

  const previousValue = prevValue(document, name, prevDocs);

  return (
    <Container>
      <Item xs={6}>
        {name === 'treatmentsPolicyDone' || name === 'specialConsultationContactsGiven'
          ? Array.isArray(document[name]) &&
            intersperse(
              ', ',
              document[name]?.map(
                (v: string) =>
                  `${optionFormatter(v)}${
                    v === 'otherTreatmentPolicy' ? ` (${document['treatmentsPolicyDoneOther']})` : ''
                  }`,
              ) || [],
            )
          : document[name] && fm
            ? fm('checklist.opts.' + document[name])
            : '-'}
      </Item>
      <Item xs={6} style={{ fontWeight: 'normal', paddingLeft: '2rem' }}>
        {name === 'treatmentsPolicyDone' || name === 'specialConsultationContactsGiven'
          ? previousValue && Array.isArray(previousValue.value)
            ? intersperse(', ', previousValue?.value.map((v: string) => optionFormatter(v)) || [])
            : null
          : formatPartialDate(previousValue?.date)}
      </Item>
    </Container>
  );
};

const ChecklistHistoryRow = ({
  document,
  fm,
  prevDocs,
}: {
  document: ISRChecklist;
  fm: IHistoryContext['fm'];
  prevDocs?: ISRChecklist[];
}): JSX.Element => (
  <>
    <div>
      <SubheaderRow mainHeader={'checklist.medicationIsProper'} fm={fm} renderPreviousHeaders />
      <HistoryRowVerticalItem
        header={fm('checklist.medicationInhaler')}
        value={makeValues('medicationInhaler', document, prevDocs, fm)}
      />
      <HistoryRowVerticalItem
        header={fm('checklist.medicationInhalerTechniqueChecked')}
        value={makeValues('medicationInhalerTechniqueChecked', document, prevDocs, fm)}
      />
      <HistoryRowVerticalItem
        header={fm('checklist.medicationOralOpioidPrescription')}
        value={makeValues('medicationOralOpioidPrescription', document, prevDocs, fm)}
      />
      <HistoryRowVerticalItem
        header={fm('checklist.medicationOtherSymptomatic')}
        value={makeValues('medicationOtherSymptomatic', document, prevDocs, fm)}
      />
      <HistoryRowVerticalItem
        header={fm('checklist.medicationOther')}
        value={makeValues('medicationOther', document, prevDocs, fm)}
      />

      <SubheaderRow mainHeader={'checklist.weightControl'} fm={fm} />
      <HistoryRowVerticalItem
        header={fm('checklist.weightControlOverOrUnderWeight')}
        value={makeValues('weightControlOverOrUnderWeight', document, prevDocs, fm)}
      />
      <HistoryRowVerticalItem
        header={fm('checklist.weightControlInstructionsGiven')}
        value={makeValues('weightControlInstructionsGiven', document, prevDocs, fm)}
      />

      <SubheaderRow mainHeader={'checklist.treatments'} fm={fm} />
      <HistoryRowVerticalItem
        header={fm('checklist.treatmentsLivingWillFormGiven')}
        value={makeValues('treatmentsLivingWillFormGiven', document, prevDocs, fm)}
      />
      <HistoryRowVerticalItem
        header={fm('checklist.treatmentsPolicyNeeded')}
        value={makeValues('treatmentsPolicyNeeded', document, prevDocs, fm)}
      />
      <HistoryRowVerticalItem
        header={fm('checklist.treatmentsPolicyDone')}
        value={makeValues('treatmentsPolicyDone', document, prevDocs, fm)}
      />
      <HistoryRowVerticalItem
        header={fm('checklist.treatmentsPalliativePolicyDiscussed')}
        value={makeValues('treatmentsPalliativePolicyDiscussed', document, prevDocs, fm)}
      />
      <HistoryRowVerticalItem
        header={fm('checklist.treatmentsPalliativePolicyInformed')}
        value={makeValues('treatmentsPalliativePolicyInformed', document, prevDocs, fm)}
      />

      <SubheaderRow mainHeader={'checklist.instructionalLeafletGiven'} fm={fm} />
      <HistoryRowVerticalItem
        header={fm('checklist.instructionalLeafletGiven2')}
        value={makeValues('instructionalLeafletGiven', document, prevDocs, fm)}
      />

      <SubheaderRow mainHeader={'checklist.specialConsultation'} fm={fm} />
      <HistoryRowVerticalItem
        header={fm('checklist.specialConsultationNeeded')}
        value={makeValues('specialConsultationNeeded', document, prevDocs, fm)}
      />
      <HistoryRowVerticalItem
        header={fm('checklist.specialConsultationContactsGiven')}
        value={makeValues('specialConsultationContactsGiven', document, prevDocs, fm)}
      />

      <SubheaderRow mainHeader={'checklist.futureVisits'} fm={fm} />
      <HistoryRowVerticalItem
        header={fm('checklist.futureVisitsNotNeededBadHealth')}
        value={makeValues('futureVisitsNotNeededBadHealth', document, prevDocs, fm)}
      />
      <HistoryRowVerticalItem
        header={fm('checklist.futureVisitsCancelled')}
        value={makeValues('futureVisitsCancelled', document, prevDocs, fm)}
      />
    </div>
  </>
);

const ChecklistHistory = ({ documents, startEdit, fm, view }: IHistoryContext<ISRChecklist>): JSX.Element => {
  // Sort documents and use map to clone
  const sortedDocs =
    documents && documents.sort((n1, n2) => sortDocuments([{ type: 'date', sortField: 'date' }])(n1, n2)).map((d) => d);

  const firstDoc = sortedDocs?.shift();

  return (
    <>
      {firstDoc && (
        <HistorySection headerText={fm('checklist.checklist')} hasHistoryRows={true}>
          <HistoryRow
            headerText={firstDoc.date ? formatPartialDate(firstDoc.date) : undefined}
            rowButton={startEdit && <HistoryRowControls document={firstDoc} startEdit={startEdit} />}
            controlsMargin={false}
          >
            <ChecklistHistoryRow document={firstDoc} fm={fm} prevDocs={sortedDocs} />
          </HistoryRow>
        </HistorySection>
      )}
      {sortedDocs && sortedDocs?.length > 0 && (
        <HistorySection headerText={fm('checklist.previousChecklists')} hasHistoryRows={true}>
          {sortedDocs.map((d: ISRChecklist) => (
            <HistoryRow
              headerText={d.date ? formatPartialDate(d.date) : undefined}
              key={d._id}
              rowButton={startEdit && <HistoryRowControls document={d} startEdit={startEdit} view={view} />}
              controlsMargin={false}
            >
              <></>
              {/* <ChecklistHistoryRow document={d} fm={fm} /> */}
            </HistoryRow>
          ))}
        </HistorySection>
      )}
    </>
  );
};

export default withHistoryContext(ChecklistHistory);
