import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../components/FormRow';
import FormSection from '../../../../components/FormSection';
import InputHandler from '../../../../components/InputHandler';
import PlatformCapabilities from '../../../../config/capabilities';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import { ICapabilityContextProps, withCapabilities } from '../../../../containers/CapabilityHandler';
import { assertCapabilities, useAppSelector as useSelector } from 'Store/index';

const optionFormatter =
  (opt: number | string) =>
  (name: number | string): JSX.Element => <FormattedMessage id={`relapse.${opt}.${name}`} />;

const RelapseFormComponent = ({
  editing,
  formData,
  saveDraft,
  toggleRedirect,
  capabilityGroups = {},
}: IRelapseForm): JSX.Element => {
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

  const visitreason = useSelector((s: { session: ISessionStore }) => s.session.data?.visitreason);

  const medicationCreationEnabled = assertCapabilities([PlatformCapabilities.MEDICATION_CREATE], capabilityGroups)
    ? true
    : assertCapabilities([PlatformCapabilities.MEDICATION_CREATE_RETROSPECTIVE], capabilityGroups) &&
      visitreason === 'retrospectiveDataTransfer';

  let opts = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
  if (assertCapabilities([PlatformCapabilities.NMOSD_CAPABILITES], capabilityGroups))
    opts = opts.concat(['12', '13', '14', '15', '16']);

  const toggleDialog = (): void => {
    setDialogOpen(!dialogOpen);
  };

  const handleChangeAndOpenDialog = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    formData.onChange && formData.onChange({ [name]: value });

    value === 'no' && medicationCreationEnabled ? toggleDialog() : undefined;
  };

  const saveAndRedirect = (): void => {
    saveDraft ? saveDraft(editing ?? '')() : undefined;
    toggleRedirect();
  };

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="relapse.newRelapse">
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="startDate"
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
        <FormRow title="relapse.symptoms.title">
          <InputHandler
            type="Checkbox"
            editing={!!editing}
            name="symptoms"
            formData={formData}
            options={opts}
            optionFormatter={optionFormatter('symptoms.opts')}
          />
        </FormRow>
        <FormRow title="relapse.symptomLevel.title">
          <InputHandler
            type="Checkbox"
            editing={!!editing}
            name="symptomLevel"
            formData={formData}
            options={['brainStem', 'cerebrum', 'opticNerve', 'cerebellum', 'spinalCord']}
            optionFormatter={optionFormatter('symptomLevel.opts')}
          />
        </FormRow>
        <FormRow title="relapse.originOfInformation.title">
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="originOfInformation"
            formData={formData}
            options={['anamnesis', 'nurse', 'generalPractitioner', 'neurologist', 'specialist', 'ophtamologist']}
            optionFormatter={optionFormatter('originOfInformation.opts')}
          />
        </FormRow>
        <FormRow title="relapse.wasPlasmaGiven">
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="wasPlasmaGiven"
            formData={formData}
            options={['yes', 'no', 'unknown']}
            optionFormatter={optionFormatter('opts.yesNoUnknown')}
          />
        </FormRow>
        <FormRow title="relapse.wasCortisoneGiven">
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="wasCortisoneGiven"
            formData={formData}
            options={['yes', 'no', 'unknown']}
            optionFormatter={optionFormatter('opts.yesNoUnknown')}
          />
        </FormRow>
        {formData.document?.wasCortisoneGiven === 'yes' ? (
          <FormRow title="relapse.cortisoneMedicationRecorded">
            <InputHandler
              type="Radio"
              editing={!!editing}
              name="cortisoneMedicationRecorded"
              formData={{
                onChange: handleChangeAndOpenDialog,
                document: formData.document,
              }}
              options={['yes', 'no', 'unknown']}
              optionFormatter={optionFormatter('opts.yesNoUnknown')}
            />
          </FormRow>
        ) : undefined}

        <ConfirmationDialog
          open={dialogOpen}
          text={<FormattedMessage id="relapse.addNewCortisoneMedication" />}
          confirm={{ callback: saveAndRedirect }}
          cancel={{ callback: toggleDialog }}
        />
      </FormSection>
    </React.Fragment>
  );
};

const RelapseForm = withCapabilities(RelapseFormComponent);
interface IRelapseForm extends ICapabilityContextProps {
  editing?: string;
  formData: IFormData<IRelapse>;
  document?: IRelapse;
  saveDraft?: (id: string) => () => void;
  toggleRedirect: () => void;
}

export default RelapseForm;
