import { exists, formatPartialDate, partialDateToValue } from 'neuro-utils';
import { intersperse } from 'ramda';
import * as React from 'react';

import FormRow from '../../../../components/FormRow';
import FormSection from '../../../../components/FormSection';
import { Container, Item } from '../../../../components/Grid';
import InputHandler from '../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../containers/FormContextHandler';
import { sortDocuments } from '../../../../utility/randomUtil';
import { SubheaderRow } from '../components';

const FormInput = ({
  name,
  type,
  formData,
  options,
  fm,
  view,
  prevValue,
  optionSpecificElements,
}: {
  name: keyof ISRChecklist;
  type: 'Checkbox' | 'CheckboxSingle';
  options?: string[];
  prevValue: (name: keyof ISRChecklist) => { date?: PartialDate; value: any } | undefined;
  optionSpecificElements?: Array<{ [name: string]: JSX.Element }>;
} & IFormContext) => {
  const viewing = !!view?.viewing;
  const optionFormatter = (o: string | number) => fm('checklist.opts.' + o);
  return (
    <Container>
      <Item xs={6}>
        <InputHandler
          type={type}
          name={name}
          editing={!viewing}
          formData={formData}
          option={type === 'CheckboxSingle' ? 'true' : undefined}
          optionFormatter={optionFormatter}
          options={options}
          showLabel={false}
          optionSpecificElements={optionSpecificElements}
        />
      </Item>
      <Item xs={6} style={{ fontWeight: 'normal', paddingLeft: '2rem' }}>
        {name === 'treatmentsPolicyDone' || name === 'specialConsultationContactsGiven'
          ? Array.isArray(prevValue(name)?.value) &&
            intersperse(', ', prevValue(name)?.value.map((v: string) => optionFormatter(v)))
          : formatPartialDate(prevValue(name)?.date)}
      </Item>
    </Container>
  );
};

const InputWithContext = withFormContext(FormInput);

const ChecklistForm = ({
  documents,
  editing,
  formData,
  fm,
  view,
}: IFormContext<ISRChecklist, ISRChecklist>): JSX.Element => {
  const viewing = !!view?.viewing;
  // Sort documents and use map to clone
  const sortedDocs =
    documents && documents.sort((n1, n2) => sortDocuments([{ type: 'date', sortField: 'date' }])(n1, n2));

  const prevDocs = sortedDocs?.filter((d) => d._id !== editing);

  const prevValue = React.useCallback(
    (name: keyof ISRChecklist): { date?: PartialDate; value: any } | undefined => {
      const previousDocsByDate =
        prevDocs?.filter(
          (d) => partialDateToValue(d?.date) < partialDateToValue(formData?.document.date) && d?._editing === false,
        ) || [];

      let returnValue: { date?: PartialDate; value: any } = { value: '-' };
      if (formData.document.date && previousDocsByDate) {
        // All documents previous to editing documents date
        if (previousDocsByDate.length === 0) {
          return undefined;
        }
        // Get first occurance of the value from previous docs
        previousDocsByDate.some((d) => {
          if (exists(d[name])) {
            returnValue = { date: d['date'], value: d[name] };
            return true;
          } else return false;
        });
        return returnValue;
      } else {
        return returnValue;
      }
    },
    [formData.document.date],
  );

  return (
    <>
      <FormSection>
        <FormRow title="general.date">
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="date"
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
      </FormSection>

      <FormSection>
        <SubheaderRow mainHeader="checklist.medicationIsProper" fm={fm} renderPreviousHeaders />
        <FormRow title="checklist.medicationInhaler">
          <InputWithContext type="CheckboxSingle" name="medicationInhaler" prevValue={prevValue} />
        </FormRow>
        <FormRow title="checklist.medicationInhalerTechniqueChecked">
          <InputWithContext type="CheckboxSingle" name="medicationInhalerTechniqueChecked" prevValue={prevValue} />
        </FormRow>
        <FormRow title="checklist.medicationOralOpioidPrescription">
          <InputWithContext type="CheckboxSingle" name="medicationOralOpioidPrescription" prevValue={prevValue} />
        </FormRow>
        <FormRow title="checklist.medicationOtherSymptomatic">
          <InputWithContext type="CheckboxSingle" name="medicationOtherSymptomatic" prevValue={prevValue} />
        </FormRow>
        <FormRow title="checklist.medicationOther">
          <InputWithContext type="CheckboxSingle" name="medicationOther" prevValue={prevValue} />
        </FormRow>
      </FormSection>

      <FormSection>
        <SubheaderRow mainHeader="checklist.weightControl" fm={fm} />
        <FormRow title="checklist.weightControlOverOrUnderWeight">
          <InputWithContext type="CheckboxSingle" name="weightControlOverOrUnderWeight" prevValue={prevValue} />
        </FormRow>
        <FormRow title="checklist.weightControlInstructionsGiven">
          <InputWithContext type="CheckboxSingle" name="weightControlInstructionsGiven" prevValue={prevValue} />
        </FormRow>
      </FormSection>

      <FormSection>
        <SubheaderRow mainHeader="checklist.treatments" fm={fm} />
        <FormRow title="checklist.treatmentsLivingWillFormGiven">
          <InputWithContext type="CheckboxSingle" name="treatmentsLivingWillFormGiven" prevValue={prevValue} />
        </FormRow>
        <FormRow title="checklist.treatmentsPolicyNeeded">
          <InputWithContext type="CheckboxSingle" name="treatmentsPolicyNeeded" prevValue={prevValue} />
        </FormRow>
        <FormRow title="checklist.treatmentsPolicyDone">
          <InputWithContext
            type="Checkbox"
            name="treatmentsPolicyDone"
            prevValue={prevValue}
            options={[
              'noVentilatorTreatment',
              'noIntensiveCare',
              'DNAR',
              'otherTreatmentPolicy',
              'cancelPreviousPolicy',
            ]}
            optionSpecificElements={[
              {
                otherTreatmentPolicy: (
                  <InputHandler
                    type="TextField"
                    name="treatmentsPolicyDoneOther"
                    editing={!viewing}
                    formData={formData}
                    placeholder={`checklist.otherTreatmentPolicy`}
                  />
                ),
              },
            ]}
          />
        </FormRow>
        <FormRow title="checklist.treatmentsPalliativePolicyDiscussed">
          <InputWithContext type="CheckboxSingle" name="treatmentsPalliativePolicyDiscussed" prevValue={prevValue} />
        </FormRow>
        <FormRow title="checklist.treatmentsPalliativePolicyInformed">
          <InputWithContext type="CheckboxSingle" name="treatmentsPalliativePolicyInformed" prevValue={prevValue} />
        </FormRow>
      </FormSection>

      <FormSection>
        <SubheaderRow mainHeader="checklist.instructionalLeafletGiven" fm={fm} />
        <FormRow title="checklist.instructionalLeafletGiven2">
          <InputWithContext type="CheckboxSingle" name="instructionalLeafletGiven" prevValue={prevValue} />
        </FormRow>
      </FormSection>

      <FormSection>
        <SubheaderRow mainHeader="checklist.specialConsultation" fm={fm} />
        <FormRow title="checklist.specialConsultationNeeded">
          <InputWithContext type="CheckboxSingle" name="specialConsultationNeeded" prevValue={prevValue} />
        </FormRow>
        <FormRow title="checklist.specialConsultationContactsGiven">
          <InputWithContext
            type="Checkbox"
            name="specialConsultationContactsGiven"
            prevValue={prevValue}
            options={[
              'socialWorker',
              'rehabilitationInstructor',
              'psychiatricNurse',
              'psychologist',
              'palliativeCentre',
              'hospitalPriest',
            ]}
          />
        </FormRow>
      </FormSection>

      <FormSection>
        <SubheaderRow mainHeader="checklist.futureVisits" fm={fm} />
        <FormRow title="checklist.futureVisitsNotNeededBadHealth">
          <InputWithContext type="CheckboxSingle" name="futureVisitsNotNeededBadHealth" prevValue={prevValue} />
        </FormRow>
        <FormRow title="checklist.futureVisitsCancelled">
          <InputWithContext type="CheckboxSingle" name="futureVisitsCancelled" prevValue={prevValue} />
        </FormRow>
      </FormSection>
    </>
  );
};

export default withFormContext(ChecklistForm);
