import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ActionButton from '../../../../../components/ActionButton';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';

import {
  sdmtSymbolConfig,
  sdmtPracticeConfig,
  sdmtTestConfig,
  figures,
  getColorOfDigitPair,
  renderSdmtRow as renderRow,
  IDialogState,
  IPracticeState,
} from '../../../utils/index';

import { SymbolDigitPair, StyledCancel, SDMTDialogInstructionPage, SDMTResultsPage } from '../../Components/index';

const SDMTPractisePage = ({ practiceQuestionsAnswered, practiceAnswers }: IPracticePageProps): JSX.Element => {
  return (
    <React.Fragment>
      <Container>
        <Item xs={12} style={{ fontSize: '2rem', display: 'flex', justifyContent: 'center' }}>
          <FormattedMessage id="cognition.sdmt.practice" />
        </Item>
      </Container>
      <Container style={{ marginTop: '1rem', marginBottom: '4rem', display: 'flex', justifyContent: 'center' }}>
        <Item xs={8}>
          <Container>
            <Item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
              {sdmtSymbolConfig.map((fig: number, i: number) => (
                <SymbolDigitPair key={i} figure={figures[fig]} digit={fig} color={'white'} />
              ))}
            </Item>
          </Container>
        </Item>
      </Container>
      <Container style={{ marginTop: '4rem', marginBottom: '4rem', display: 'flex', justifyContent: 'center' }}>
        <Item xs={8}>
          <Container>
            <Item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
              {sdmtPracticeConfig.map((fig: number, i: number) => (
                <SymbolDigitPair
                  key={i}
                  figure={figures[fig]}
                  digit={fig}
                  color={getColorOfDigitPair(practiceAnswers, practiceQuestionsAnswered, i)}
                />
              ))}
            </Item>
          </Container>
        </Item>
      </Container>
      {practiceQuestionsAnswered === 10 && (
        <Container>
          <Item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontWeight: 600,
              fontSize: '1.8rem',
              marginBottom: '2rem',
            }}
          >
            <FormattedMessage id="cognition.sdmt.testInstructions1" />
          </Item>
          <Item xs={12} style={{ display: 'flex', justifyContent: 'center', fontSize: '1.8rem' }}>
            <FormattedMessage id="cognition.sdmt.testInstructions2" />
          </Item>
        </Container>
      )}
    </React.Fragment>
  );
};

const SDMTTestPage = ({ testQuestionsAnswered, testAnswers }: ITestPageProps): JSX.Element => {
  return (
    <React.Fragment>
      <Container>
        <Item xs={12} style={{ fontSize: '2rem', display: 'flex', justifyContent: 'center' }}>
          <FormattedMessage id="cognition.sdmt.go" />
        </Item>
      </Container>
      <Container style={{ marginTop: '1rem', marginBottom: '4rem', display: 'flex', justifyContent: 'center' }}>
        <Item xs={8}>
          <Container>
            <Item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
              {sdmtSymbolConfig.map((fig: number, i: number) => (
                <SymbolDigitPair key={i} figure={figures[fig]} digit={fig} color={'white'} />
              ))}
            </Item>
          </Container>
        </Item>
      </Container>
      <Container style={{ marginTop: '4rem', marginBottom: '4rem', display: 'flex', justifyContent: 'center' }}>
        <Item xs={8}>
          <Container>
            <Item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
              {sdmtTestConfig.map((fig: number, i: number) =>
                renderRow(testQuestionsAnswered, i) ? (
                  <SymbolDigitPair
                    key={i}
                    figure={figures[fig]}
                    digit={fig}
                    color={getColorOfDigitPair(testAnswers, testQuestionsAnswered, i)}
                  />
                ) : undefined,
              )}
            </Item>
          </Container>
        </Item>
      </Container>
    </React.Fragment>
  );
};

interface ITestPageProps {
  testQuestionsAnswered: number;
  testAnswers: Array<{ index: number; correctOrFalse: 'correct' | 'false' }>;
}

const SDMTInstructionsActions = ({ setDialogState, setOpenDialog }: IInstructionsActionsProps): JSX.Element => (
  <Container style={{ display: 'flex', justifyContent: 'center' }}>
    <Item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
      <ActionButton
        text="cognition.sdmt.beginPractice"
        onClick={(): void => setDialogState('practice')}
        width={20}
        height={4}
        fontSize={20}
      />
    </Item>
    <Item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
      <ActionButton
        text="general.cancelEnglish"
        onClick={(): void => setOpenDialog(false)}
        width={20}
        height={4}
        fontSize={20}
      />
    </Item>
  </Container>
);

const SDMTPracticeActions = ({
  setDialogState,
  setPracticeState,
  practiceState,
  setPracticeQuestionsAnswered,
  practiceQuestionsAnswered,
  setPracticeAnswers,
  practiceAnswers,
}: IPracticeActionsProps): JSX.Element => {
  React.useEffect(() => {
    if (practiceQuestionsAnswered === 10) {
      setPracticeState('startTest');
    }
  }, [practiceQuestionsAnswered, setPracticeState]);

  return (
    <Container style={{ display: 'flex', justifyContent: 'center' }}>
      {practiceState === 'practice' ? (
        <>
          <Item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
            <ActionButton
              text="cognition.sdmt.correct"
              onClick={(): void => {
                const temp = practiceAnswers.concat({ index: practiceQuestionsAnswered, correctOrFalse: 'correct' });
                setPracticeQuestionsAnswered(practiceQuestionsAnswered + 1);
                setPracticeAnswers(temp);
              }}
              width={20}
              height={4}
              fontSize={20}
            />
          </Item>
          <Item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
            <ActionButton
              text="cognition.sdmt.false"
              onClick={(): void => {
                const temp = practiceAnswers.concat({ index: practiceQuestionsAnswered, correctOrFalse: 'false' });
                setPracticeQuestionsAnswered(practiceQuestionsAnswered + 1);
                setPracticeAnswers(temp);
              }}
              width={20}
              height={4}
              fontSize={20}
            />
          </Item>
        </>
      ) : practiceState === 'startTest' ? (
        <Item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <ActionButton
            text="cognition.sdmt.startTest"
            onClick={(): void => setDialogState('test')}
            width={20}
            height={4}
            fontSize={20}
          />
        </Item>
      ) : undefined}
    </Container>
  );
};

const SDMTTestActions = ({
  setDialogState,
  testQuestionsAnswered,
  setTestQuestionsAnswered,
  setCorrectAnswers,
  correctAnswers,
  setTestAnswers,
  testAnswers,
  setTimeLeft,
  timeLeft,
}: ITestActionsProps): JSX.Element => {
  React.useEffect(() => {
    if (timeLeft === 0) {
      setDialogState('results');
    }
    const timer: any = timeLeft > 0 && setInterval(() => setTimeLeft(timeLeft - 1), 1000);
    return (): void => clearInterval(timer);
  }, [timeLeft, setDialogState, setTimeLeft]);

  React.useEffect(() => {
    if (testQuestionsAnswered === 110) {
      setDialogState('results');
    }
  }, [testQuestionsAnswered, setDialogState]);

  // A function for handling answers so that we can decide whether to display green or red symboldigitpairs
  const handleClick = (correctOrFalse: 'correct' | 'false'): void => {
    const temp = testAnswers.concat({ index: testQuestionsAnswered, correctOrFalse: correctOrFalse });
    setTestQuestionsAnswered(testQuestionsAnswered + 1);
    setTestAnswers(temp);
    if (correctOrFalse === 'correct') {
      setCorrectAnswers(correctAnswers + 1);
    }
  };

  return (
    <Container style={{ display: 'flex', justifyContent: 'center' }}>
      <Item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
        <ActionButton
          text="cognition.sdmt.correct"
          onClick={(): void => handleClick('correct')}
          width={20}
          height={4}
          fontSize={20}
        />
      </Item>
      <Item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
        <ActionButton
          text="cognition.sdmt.false"
          onClick={(): void => handleClick('false')}
          width={20}
          height={4}
          fontSize={20}
        />
      </Item>
    </Container>
  );
};

const SDMTResultsActions = ({
  formData,
  setDialogOpen,
  setDialogState,
  setPracticeQuestionsAnswered,
  setTestQuestionsAnswered,
  testQuestionsAnswered,
  setCorrectAnswers,
  correctAnswers,
  setDisableSDMTButton,
}: IResultsActionsProps): JSX.Element => {
  // Function for discarding test results
  const handleDiscard = (): void => {
    setDialogOpen(false);
    setDialogState('instructions');
    setPracticeQuestionsAnswered(0);
    setTestQuestionsAnswered(0);
    setCorrectAnswers(0);
  };
  // Function for saving test results
  const handleSave = (): void => {
    formData.onChange &&
      formData.onChange({ allAnswers: testQuestionsAnswered, correctAnswers: correctAnswers, testType: 'oral' });

    setDialogOpen(false);
    setDisableSDMTButton(true);
  };

  return (
    <Container>
      <Item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Container style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
            <StyledCancel onClick={(): void => handleDiscard()}>
              <FormattedMessage id="cognition.sdmt.discard" />
            </StyledCancel>
          </Item>
          <Item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
            <ActionButton
              text="cognition.sdmt.save"
              onClick={(): void => handleSave()}
              width={20}
              height={4}
              fontSize={20}
            />
          </Item>
        </Container>
      </Item>
    </Container>
  );
};

// This one constructs the dialog for answering questions of the sdmt test
const SDMTDialog = ({ formData, openDialog, setOpenDialog, setDisableSDMTButton }: ISDMTDialogProps): JSX.Element => {
  const [dialogState, setDialogState] = React.useState<IDialogState>('instructions');
  const [practiceState, setPracticeState] = React.useState<IPracticeState>('practice');
  const [practiceQuestionsAnswered, setPracticeQuestionsAnswered] = React.useState<number>(0);
  const [testQuestionsAnswered, setTestQuestionsAnswered] = React.useState<number>(0);
  const [correctAnswers, setCorrectAnswers] = React.useState<number>(0);
  const [practiceAnswers, setPracticeAnswers] = React.useState<
    Array<{ index: number; correctOrFalse: 'correct' | 'false' }>
  >([]);
  const [testAnswers, setTestAnswers] = React.useState<Array<{ index: number; correctOrFalse: 'correct' | 'false' }>>(
    [],
  );
  const [timeLeft, setTimeLeft] = React.useState<number>(90);

  return (
    <Dialog open={openDialog} maxWidth="lg" fullWidth={true} PaperProps={{ square: true }}>
      <DialogTitle style={{ borderBottom: '1px solid #ccc', padding: '2rem 3rem' }}>
        <div style={{ fontSize: '1.8rem', marginRight: '2rem' }}>
          {dialogState === 'instructions' || dialogState === 'results' ? (
            <FormattedMessage id="cognition.sdmt.sdmt" />
          ) : dialogState === 'practice' ? (
            <span>
              {`${practiceQuestionsAnswered} / 10 `}
              <FormattedMessage id="cognition.sdmt.answered" />
            </span>
          ) : dialogState === 'test' ? (
            <span>
              {`${testQuestionsAnswered} / 110 `}
              <FormattedMessage id="cognition.sdmt.answered" />
              {`, `}
              <FormattedMessage id="cognition.sdmt.timeLeft" values={{ seconds: timeLeft }} />
            </span>
          ) : undefined}
        </div>
      </DialogTitle>
      <DialogContent style={{ minHeight: '20rem', padding: '2rem 3rem' }}>
        {dialogState === 'instructions' ? (
          <SDMTDialogInstructionPage />
        ) : dialogState === 'practice' ? (
          <SDMTPractisePage practiceQuestionsAnswered={practiceQuestionsAnswered} practiceAnswers={practiceAnswers} />
        ) : dialogState === 'test' ? (
          <SDMTTestPage testQuestionsAnswered={testQuestionsAnswered} testAnswers={testAnswers} />
        ) : dialogState === 'results' ? (
          <SDMTResultsPage correctAnswers={correctAnswers} allAnswers={testQuestionsAnswered} />
        ) : undefined}
      </DialogContent>
      <DialogActions style={{ borderTop: '1px solid #ccc', padding: '1rem 3rem' }}>
        {dialogState === 'instructions' ? (
          <SDMTInstructionsActions setDialogState={setDialogState} setOpenDialog={setOpenDialog} />
        ) : dialogState === 'practice' ? (
          <SDMTPracticeActions
            setDialogState={setDialogState}
            setPracticeState={setPracticeState}
            practiceState={practiceState}
            setPracticeQuestionsAnswered={setPracticeQuestionsAnswered}
            practiceQuestionsAnswered={practiceQuestionsAnswered}
            setPracticeAnswers={setPracticeAnswers}
            practiceAnswers={practiceAnswers}
          />
        ) : dialogState === 'test' ? (
          <SDMTTestActions
            setDialogState={setDialogState}
            setTestQuestionsAnswered={setTestQuestionsAnswered}
            testQuestionsAnswered={testQuestionsAnswered}
            setCorrectAnswers={setCorrectAnswers}
            correctAnswers={correctAnswers}
            setTestAnswers={setTestAnswers}
            testAnswers={testAnswers}
            setTimeLeft={setTimeLeft}
            timeLeft={timeLeft}
          />
        ) : dialogState === 'results' ? (
          <SDMTResultsActions
            formData={formData}
            setDialogOpen={setOpenDialog}
            setDialogState={setDialogState}
            setPracticeQuestionsAnswered={setPracticeQuestionsAnswered}
            setTestQuestionsAnswered={setTestQuestionsAnswered}
            testQuestionsAnswered={testQuestionsAnswered}
            setCorrectAnswers={setCorrectAnswers}
            correctAnswers={correctAnswers}
            setDisableSDMTButton={setDisableSDMTButton}
          />
        ) : undefined}
      </DialogActions>
    </Dialog>
  );
};

const SDMTForm = ({ formData, viewing }: IOwnProps): JSX.Element => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [disableSDMTButton, setDisableSDMTButton] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="general.date">
          <InputHandler
            type="PartialDate"
            editing={!viewing}
            name="date"
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
        <FormRow title="cognition.sdmt.totalScoreOnly">
          <InputHandler
            type="Checkbox"
            editing={!viewing}
            name="totalScoreOnly"
            formData={formData}
            options={['totalScoreOnly']}
            optionFormatter={(name: string | number): JSX.Element => <FormattedMessage id={`cognition.sdmt.${name}`} />}
            disabled={formData.document.allAnswers || formData.document.correctAnswers ? true : false}
          />
        </FormRow>
      </FormSection>
      {!viewing && !(formData.document.totalScoreOnly?.[0] === 'totalScoreOnly') && (
        <FormSection>
          <Container style={{ marginBottom: '3rem' }}>
            <Item xs={8}>
              <span style={{ fontWeight: 600 }}>
                <FormattedMessage id="cognition.sdmt.sdmtDescription1" />
              </span>
            </Item>
          </Container>
          <Container style={{ marginTop: '3rem', marginBottom: '3rem' }}>
            <Item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
              <ActionButton
                text="cognition.sdmt.administerOralSdmt"
                onClick={(): void => setOpenDialog(true)}
                width={25}
                height={5}
                fontSize={20}
                disabled={viewing || formData.document.allAnswers ? true : disableSDMTButton}
              />
            </Item>
          </Container>
          <SDMTDialog
            formData={formData}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            setDisableSDMTButton={setDisableSDMTButton}
          />
        </FormSection>
      )}
      {(formData.document.allAnswers || formData.document.totalScoreOnly?.[0] === 'totalScoreOnly') && (
        <FormSection>
          <FormRow title="cognition.sdmt.testType">
            <InputHandler
              type="Radio"
              editing={!viewing}
              name="testType"
              formData={formData}
              options={['oral', 'written']}
              optionFormatter={(name: string | number): JSX.Element => (
                <FormattedMessage id={`cognition.sdmt.${name}`} />
              )}
              disabledOptions={
                formData.document.totalScoreOnly?.[0] === 'totalScoreOnly'
                  ? []
                  : formData.document.totalScoreOnly?.[0] != 'totalScoreOnly'
                    ? ['oral', 'written']
                    : []
              }
            />
          </FormRow>
          <FormRow title="cognition.sdmt.correctAnswers">
            <InputHandler
              type="NumberField"
              editing={!viewing}
              name="correctAnswers"
              formData={formData}
              min={0}
              max={110}
            />
          </FormRow>
          <FormRow title="cognition.sdmt.allAnswers">
            <InputHandler
              type="NumberField"
              editing={!viewing}
              name="allAnswers"
              formData={formData}
              min={0}
              max={110}
            />
          </FormRow>
        </FormSection>
      )}
      <FormSection>
        <Container style={{ marginTop: '3rem' }}>
          <Item xs={10}>
            <FormattedMessage id="cognition.sdmt.sdmtDescription2" />
          </Item>
        </Container>
      </FormSection>
    </React.Fragment>
  );
};

interface IInstructionsActionsProps {
  setDialogState: any;
  setOpenDialog: any;
}

interface IPracticeActionsProps {
  setDialogState: any;
  setPracticeState: any;
  practiceState: IPracticeState;
  setPracticeQuestionsAnswered: any;
  practiceQuestionsAnswered: number;
  setPracticeAnswers: any;
  practiceAnswers: Array<{ index: number; correctOrFalse: 'correct' | 'false' }>;
}

interface ITestActionsProps {
  setDialogState: any;
  setTestQuestionsAnswered: any;
  testQuestionsAnswered: number;
  setCorrectAnswers: any;
  correctAnswers: number;
  setTestAnswers: any;
  testAnswers: Array<{ index: number; correctOrFalse: 'correct' | 'false' }>;
  setTimeLeft: any;
  timeLeft: number;
}

interface IResultsActionsProps {
  formData: IFormData<ISDMT>;
  setDialogOpen: any;
  setDialogState: any;
  setPracticeQuestionsAnswered: any;
  setTestQuestionsAnswered: any;
  testQuestionsAnswered: number;
  setCorrectAnswers: any;
  correctAnswers: number;
  setDisableSDMTButton: any;
}

interface IPracticePageProps {
  practiceQuestionsAnswered: number;
  practiceAnswers: Array<{ index: number; correctOrFalse: 'correct' | 'false' }>;
}

interface ISDMTDialogProps {
  formData: IFormData<ISDMT>;
  openDialog: boolean;
  setOpenDialog: any;
  setDisableSDMTButton: any;
}

interface IOwnProps {
  formData: IFormData<ISDMT>;
  viewing?: boolean;
}

export default SDMTForm;
