import * as React from 'react';
import { TDocument } from '../../utils';
import UHDRSForm from './UHDRS';

const MotorFuncHuntForm = ({ formData, document, viewing = false, editing }: IOwnProps): JSX.Element | null =>
  document?._type === 'uhdrsMotorRatingScale' ? (
    <UHDRSForm formData={formData} viewing={viewing} editing={editing} />
  ) : null;

interface IOwnProps {
  formData: IFormData<TDocument>;
  document?: TDocument;
  viewing?: boolean;
  editing?: any;
}

export default MotorFuncHuntForm;
