import { Container, Item } from 'Components/Grid';
import { exists, calculateDaysDifference, formatPartialDate, formatTime, nowPartialDate } from 'neuro-utils';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import EventStepper from '../../../../../components/EventStepper';
import { StepperHeaderFormInputPair, StepperHeaderValuePair } from '../../../../../components/EventStepper/components';
import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import Unit from '../../../../../components/Unit';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import {
  checkIfAcuteTreatmentStart,
  checkIfCurrentDocumentIsTheFirstTreatment,
  getDateOfReferral,
  showDelay,
} from '../../../utils';
import { EndForm } from '../components';

const FormattedValue = ({ name, value }: { name: string; value?: Time | string | number }): JSX.Element => {
  const locPath = 'treatment.hfncTherapy.settings';

  return (
    <React.Fragment>
      {exists(value)
        ? {
            time: formatTime(value as Time),
            HFNCTreatmentType: <FormattedMessage id={`${locPath}.opts.${value}`} />,
            flowRate: <Unit unit={<FormattedMessage id={`${locPath + '.litersPerMin'}`} />}>{value as number}</Unit>,
            supplementalOxygen: <Unit unit={'%'}>{value as number}</Unit>,
            oxygenFlow: <Unit unit="l/min">{value as number}</Unit>,
          }[name] || '-'
        : '-'}
    </React.Fragment>
  );
};

const SettingsStepContent = ({ d }: { d: IHFNCSetting }): JSX.Element => {
  const locPath = 'treatment.hfncTherapy.settings';

  return (
    <>
      <StepperHeaderValuePair
        header={<FormattedMessage id="general.timeOfDay" />}
        value={<FormattedValue name="time" value={d.time} />}
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id={`${locPath}.HFNCTreatmentType`} />}
        value={<FormattedValue name="HFNCTreatmentType" value={d.HFNCTreatmentType} />}
      />
      <StepperHeaderValuePair
        header={<FormattedMessage id={`${locPath}.flowRate`} />}
        value={<FormattedValue name="flowRate" value={d.flowRate} />}
      />
      {d.HFNCTreatmentType === 'oxygenTreatment' && (
        <>
          <StepperHeaderValuePair
            header={<FormattedMessage id={`${locPath}.supplementalOxygen`} />}
            value={<FormattedValue name="supplementalOxygen" value={d.supplementalOxygen} />}
          />
          <StepperHeaderValuePair
            header={<FormattedMessage id={`${locPath}.oxygenFlow`} />}
            value={<FormattedValue name="oxygenFlow" value={d.oxygenFlow} />}
          />
        </>
      )}
    </>
  );
};

const SettingsFormContent = ({
  thisSetting,
  onChange,
  mainDate,
}: {
  thisSetting?: IHFNCSetting;
  onChange: IFormData['onChange'];
  mainDate?: PartialDate;
}): JSX.Element => {
  const locPath = 'treatment.hfncTherapy.settings';

  return (
    <>
      <StepperHeaderFormInputPair
        header={<FormattedMessage id={`${locPath}.date`} />}
        input={
          <InputHandler
            type="PartialDate"
            editing={true}
            name="date"
            formData={{
              onChange,
              document: { date: thisSetting?.date },
            }}
            dateDefault={mainDate || 'now'}
            dateHook={{ dateHookFloor: mainDate }}
            isNotCancellable={true}
          />
        }
      />
      <StepperHeaderFormInputPair
        header={<FormattedMessage id="general.timeOfDay" />}
        input={
          <InputHandler
            type="TimePicker"
            editing={true}
            name="time"
            formData={{
              onChange,
              document: { time: thisSetting?.time },
            }}
            timeDefault={[0, 0]}
            isNotCancellable={true}
          />
        }
      />
      <StepperHeaderFormInputPair
        header={<FormattedMessage id={`${locPath}.HFNCTreatmentType`} />}
        input={
          <InputHandler
            type="Radio"
            editing={true}
            name="HFNCTreatmentType"
            formData={{
              onChange,
              document: { HFNCTreatmentType: thisSetting?.HFNCTreatmentType },
            }}
            options={['oxygenTreatment', 'noOxygenTreatment']}
            optionFormatter={(o): string | JSX.Element => <FormattedMessage id={`${locPath}.opts.${o}`} />}
            dependentFieldsList={(): string[] => ['supplementalOxygen']}
          />
        }
      />
      <StepperHeaderFormInputPair
        header={<FormattedMessage id={`${locPath}.flowRate`} />}
        input={
          <Unit unit={<FormattedMessage id={`${locPath + '.litersPerMin'}`} />}>
            <InputHandler
              type="NumberField"
              editing={true}
              name="flowRate"
              formData={{
                onChange,
                document: { flowRate: thisSetting?.flowRate },
              }}
              placeholder={`${locPath}.flowRatePlaceholder`}
              precision={2}
            />
          </Unit>
        }
      />
      {thisSetting?.HFNCTreatmentType === 'oxygenTreatment' && (
        <>
          <StepperHeaderFormInputPair
            header={<FormattedMessage id={`${locPath}.supplementalOxygen`} />}
            input={
              <Unit unit={'%'}>
                <InputHandler
                  type="NumberField"
                  editing={true}
                  name="supplementalOxygen"
                  formData={{
                    onChange,
                    document: { supplementalOxygen: thisSetting?.supplementalOxygen },
                  }}
                  placeholder={`${locPath}.supplementalOxygenPlaceholder`}
                  precision={2}
                />
              </Unit>
            }
          />
          <StepperHeaderFormInputPair
            header={<FormattedMessage id={`${locPath}.oxygenFlow`} />}
            input={
              <Unit unit={'l/min'}>
                <InputHandler
                  type="NumberField"
                  editing={true}
                  name="oxygenFlow"
                  formData={{
                    onChange,
                    document: { oxygenFlow: thisSetting?.oxygenFlow },
                  }}
                  placeholder={`${locPath}.oxygenFlowPlaceholder`}
                  precision={2}
                />
              </Unit>
            }
          />
        </>
      )}
    </>
  );
};

const locPath = 'treatment.hfncTherapy';

const HFNCTherapy = ({ formData, view, documents = [], editing }: IFormContext<IHFNCTherapy, any>): JSX.Element => {
  const thisDocument = formData.document;
  const referralDate = getDateOfReferral(documents, 'respiratoryFirstVisit');
  const isAcute = checkIfAcuteTreatmentStart(documents, 'respiratoryFirstVisit');
  const isFirstDoc = checkIfCurrentDocumentIsTheFirstTreatment(documents, view, editing);

  const delayFromSuspicionToTreatmentStart = calculateDaysDifference(referralDate, thisDocument.date);

  return (
    <>
      <FormRow title="treatment.date">
        <InputHandler
          type="PartialDate"
          editing={!view?.viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
          dateHook={{ dateHookCeiling: formData.document.endDate }}
        />
      </FormRow>
      <FormRow title={`${locPath}.delayFromSuspicionToTreatmentStart`} condition={showDelay(isAcute, isFirstDoc)}>
        <Unit unit={<FormattedMessage id="treatment.daysPlaceholder" />} fontWeight={view?.viewing ? 'bold' : 'normal'}>
          {exists(delayFromSuspicionToTreatmentStart) ? (delayFromSuspicionToTreatmentStart as number) : '-'}
        </Unit>
      </FormRow>
      <FormRow title={`${locPath}.treatmentStartLocation`}>
        <InputHandler
          type="Radio"
          editing={!view?.viewing}
          name="treatmentStartLocation"
          formData={formData}
          options={['intensiveCareUnit', 'inpatientWard', 'outpatientClinic', 'primaryHealthCare']}
          optionFormatter={(o): string | JSX.Element => <FormattedMessage id={`${locPath}.opts.${o}`} />}
        />
      </FormRow>
      <FormRow title={`${locPath}.treatmentStart`}>
        <InputHandler
          type="Radio"
          editing={!view?.viewing}
          name="treatmentStart"
          formData={formData}
          options={['onCall', 'planned']}
          optionFormatter={(o): string | JSX.Element => <FormattedMessage id={`${locPath}.opts.${o}`} />}
        />
      </FormRow>
      <FormSection header={`${locPath}.settings.title`}>
        <EventStepper
          name="settings"
          formData={formData}
          stepLabelText={(d: IHFNCSetting): string => formatPartialDate(d.date)}
          stepContent={(d: IHFNCSetting): JSX.Element => <SettingsStepContent d={d} />}
          addNewTextHeader={locPath + '.settings.newSetting'}
          addNewTextButton={locPath + '.settings.newSettingButton'}
          previousEventsTextHeader={locPath + '.settings.previousSettings'}
          noPreviousEventsTextHeader={locPath + '.settings.noPreviousSettings'}
          editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
            const thisSetting = thisDocument.settings?.[index];
            return <SettingsFormContent thisSetting={thisSetting} onChange={onChange} mainDate={thisDocument.date} />;
          }}
          viewing={!!view?.viewing}
          defaultValues={{ date: thisDocument.date ?? nowPartialDate(), time: [0, 0] }}
          tableContent={(events: IHFNCSetting[]) => (
            <React.Fragment>
              {['time', 'HFNCTreatmentType', 'flowRate']
                .concat(
                  events.some((e) => e.HFNCTreatmentType === 'oxygenTreatment')
                    ? ['supplementalOxygen', 'oxygenFlow']
                    : [],
                )
                .map((field, index, arr) => (
                  <Container
                    key={index}
                    alignItems="baseline"
                    style={{
                      marginBottom: index < arr.length - 1 ? '1rem' : '4.5rem',
                    }}
                  >
                    <Item xs={3} style={{ paddingLeft: '2rem' }}>
                      <FormattedMessage
                        id={index > 0 ? `treatment.hfncTherapy.settings.${field}` : 'general.timeOfDay'}
                      />
                    </Item>
                    {events.map((e: IHFNCSetting, i: number, arr) => {
                      const value = e[field as keyof IHFNCSetting] as string | number | Time | undefined;
                      return (
                        <Item
                          key={i}
                          xs={index === 0 && i === arr.length - 1 ? 1 : 2}
                          style={{ fontWeight: index < 2 ? 600 : undefined }}
                        >
                          {field === 'supplementalOxygen' && e.HFNCTreatmentType !== 'oxygenTreatment' ? (
                            ''
                          ) : (
                            <FormattedValue name={field} value={value} />
                          )}
                        </Item>
                      );
                    })}
                  </Container>
                ))}
            </React.Fragment>
          )}
          tableContentFilter={{
            eventFilter: (events: IHFNCSetting[]) => events.slice().reverse(),
          }}
        />
      </FormSection>
      <EndForm formData={formData} edit={!view?.viewing} type="hfncTherapy" />
    </>
  );
};

export default withFormContext(HFNCTherapy);
