import * as React from 'react';

const svgImage = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 400 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd' as const,
      clipRule: 'evenodd' as const,
      strokeLinecap: 'round' as const,
      strokeLinejoin: 'round' as const,
      strokeMiterlimit: 1.5,
    }}
  >
    <g transform="matrix(0.406083,0,0,0.406083,-164.817,-44.1411)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-44.4369,46.6782)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,30.2856,24.3856)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-122.418,44.7154)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-174.137,16.9112)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-208.196,55.8121)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-203.743,-29.6881)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-227.927,30.4558)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-258.13,73.4206)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-80.058,90.236)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-158.857,62.7773)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,0.147725,90.503)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.266911,-0.125866,0.185776,0.393956,-189.841,20.4745)">
      <rect x="763.845" y="554.566" width="11.338" height="266.459" />
    </g>
    <g transform="matrix(0.366398,-0.175088,0.175088,0.366398,-266.925,64.634)">
      <rect x="731.179" y="863.229" width="88.328" height="10.12" />
    </g>
    <g transform="matrix(0.38385,-0.132523,0.132523,0.38385,-286.499,-75.2209)">
      <rect x="731.179" y="863.229" width="88.328" height="10.12" />
    </g>
    <g transform="matrix(0.384482,-0.130678,0.130678,0.384482,-255.893,45.2893)">
      <circle cx="784.129" cy="472.301" r="48.515" style={{ fill: 'white', stroke: 'black', strokeWidth: '9.76px' }} />
    </g>
    <g transform="matrix(0.157263,-0.0534507,0.0534507,0.157263,-45.4965,88.6874)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.728777,0.104117,-0.0503167,0.352195,-347.086,-130.521)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(1.01452,0,0,0.0959804,-385.471,164.875)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.714107,-0.325089,0.0397671,0.0873545,-222.099,467.892)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.862801,0.315663,-0.122238,0.334112,-440.838,-257.341)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.304827,-0.447585,0.514036,0.350082,-502.6,284.092)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.857159,0,0,0.57994,-286.411,-135.044)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.806372,0.290664,-0.201827,0.559918,-187.078,-322.399)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.82565,-0.230271,0.17564,0.629768,-357.79,-76.806)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.330609,-0.428894,0.42884,0.330567,-405.94,270.976)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.857159,0,0,0.68024,-328.113,-252.53)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
  </svg>
);

export default svgImage;
