import * as React from 'react';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import Form from './Form';
import _BPHistory from './HistoryRowData';

const _BP = ({ documents }: IOwnProps): JSX.Element => (
  <FormEditingHandler name="_BP" documents={documents}>
    {(editing, startEdit, formData, view): JSX.Element => (
      <DocumentWrapper>
        <DocumentHeader name={'_BP'} headerId={'_BP.title'} editing={editing} editButtons={<div />} />
        {editing ? <Form formData={formData} /> : null}
        {!editing && !view?.viewing ? <_BPHistory documents={documents} startEdit={startEdit} /> : null}
      </DocumentWrapper>
    )}
  </FormEditingHandler>
);

interface IOwnProps {
  documents: Array<any>;
}

export default _BP;
