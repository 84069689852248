import * as React from 'react';
import { Container, Item } from 'Components/Grid';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import { styled } from '@mui/system';
import colors from '../../../../../config/theme/colors';
import InputHandler from 'Components/InputHandler';
import Unit from 'Components/Unit';
import { defaultRTMSTreatmentOperators } from '../../config';
import { StyledTableControls } from 'Components/EventStepper/components';
import { equals } from 'ramda';
import { subjectOfTreatmentNames } from 'Routes/DoctorsOrders/Document/config';
import {
  createSubjectOfTreatmentNameObj as createSOTNameObj,
  cTBSFields,
  findPrecedingDoctorsOrder,
  getRtmsRowData,
  getSessionNumber,
  iTBSFields,
  originalRTMSFields,
  TSOTNameObj,
  valueDiffersFromDoctorsOrders,
} from 'Utility/ninmtUtil';
import { FormattedDoctorsOrderValue } from '../../functions';
import { RTMSContext } from '../..';
import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, PaperProps } from '@mui/material';
import { dialogActions, dialogContent, dialogTitle } from '../../../../../config/theme/componentTheme';
import ActionButton from 'Components/ActionButton';
import ToolTip from 'Components/ToolTip';
import { capitalize } from 'Utility/string';
import { omit } from 'Utility/ramdaReplacement';

const StyledEventContainer = styled(Container)({
  width: 'auto !important',
  margin: '0 -2.5rem 3rem -2.5rem',
  padding: '0 2.5rem',
});

const StyledBarHeaderContainer = styled(StyledEventContainer)({
  backgroundColor: `${colors.defaultBackground}`,
  height: '5rem',
});

const StyledTableContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'index' && prop !== 'arrLength',
})(({ index, arrLength }: { index: number; arrLength: number }) => ({
  alignItems: 'stretch',
  marginBottom: index < arrLength - 1 ? undefined : '2.5rem',
  padding: '0.4rem 0 0.4rem 0',
  borderTop: index === 0 ? `1px solid ${colors.gray}` : undefined,
  backgroundColor: index % 2 === 0 ? colors.lightestGray : undefined,
}));

const StyledTableItem = styled(Item, {
  shouldForwardProp: (prop) => prop !== 'firstCell',
})(({ firstCell }: { firstCell: boolean }) => ({
  wordBreak: firstCell ? undefined : ('normal' as const),
  wordWrap: 'normal' as const,
  color: firstCell ? colors.tertiaryText : undefined,
  paddingLeft: firstCell ? '1rem' : undefined,
  paddingRight: '1rem',
}));

const StyledTableSecondaryItem = styled(Item, {
  shouldForwardProp: (prop) => prop !== 'index',
})(({ index }: { index: number }) => ({
  backgroundColor: index % 2 === 0 ? colors.highlight.light : colors.highlight.lightest,
  margin: '-0.4rem 1rem -0.4rem -1rem',
  padding: '0.4rem 0 0.4rem 1rem',
}));

const StyledTitleBox = styled('div')({
  width: '1rem',
  height: '1.9rem',
  border: `1px solid ${colors.warning.default}`,
  backgroundColor: colors.warning.lightest,
  marginRight: '1rem',
});

const StyledTitleItem = styled(Item)({
  fontSize: '1.6rem',
  fontWeight: 'bold',
  color: colors.darkGray,
});

const StyledSelectButton = styled('span')({
  '&:hover': {
    cursor: 'Pointer',
    textDecoration: 'underline',
  },
});

const NameContainer = styled(Container)({
  color: colors.tertiaryText,
  padding: '0 0 5px 0',
});

const InlineDiv = styled('div')({
  display: 'inline-flex',
});

const DialogPaper = (props: PaperProps): JSX.Element => <Paper square={true} {...props} />;

const nameFormatter = (t: TSOTNameObj & { stimulationType?: IRTMSTreatment['stimulationType'] }): JSX.Element => {
  const name =
    t.name && t.name !== 'other'
      ? subjectOfTreatmentNames.includes(t.name)
        ? fm(`rtms.subjectOfTreatmentNames.${t.name}`)
        : t.name
      : t.nameOther
        ? t.nameOther
        : '-';
  const specifier = t.specifier ? ` ${t.specifier}` : '';
  return (
    <span>
      {name}
      {specifier}
    </span>
  );
};

const FormattedPatientsRatingValue = ({
  ratingByLocation,
  name,
}: {
  ratingByLocation: TRTMSRatingByLocation;
  name: 'intensity' | 'harm';
}): JSX.Element => {
  const beforeValue = ratingByLocation[name as 'intensity' | 'harm']?.beforeTreatment;
  const afterValue = ratingByLocation[name as 'intensity' | 'harm']?.afterTreatment;
  return (
    <Container>
      <Item xs={2} style={{ fontWeight: 600 }}>
        <ToolTip
          cursor={!beforeValue ? 'Default' : 'Pointer'}
          description={fm('rtms.patientsRating.beforeTreatment')}
          hover={true}
          disabled={!beforeValue}
          content={<span>{beforeValue || beforeValue === 0 ? beforeValue.toFixed(1) : '-'}</span>}
        />
      </Item>
      <Item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
        {'➞'}
      </Item>
      <Item xs={2} style={{ display: 'flex', justifyContent: 'flex-end', fontWeight: 600 }}>
        <ToolTip
          cursor={!afterValue ? 'Default' : 'Pointer'}
          description={fm('rtms.patientsRating.afterTreatment')}
          hover={true}
          disabled={!afterValue}
          content={<span>{afterValue || afterValue === 0 ? afterValue.toFixed(1) : '-'}</span>}
        />
      </Item>
    </Container>
  );
};

const FormattedRTMSTreatmentValue = ({
  name,
  value,
  currentSession,
  currentIndex,
}: {
  name: string;
  value: any;
  currentSession?: IRTMSSession;
  currentIndex?: number;
}): JSX.Element => {
  const additionalStyleDefault =
    name !== 'electricFieldStrength' && valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value)
      ? { color: colors.white, backgroundColor: colors.appBlue.default, padding: '0 2px 0 2px', borderRadius: '0.5rem' }
      : undefined;
  switch (name) {
    case 'stimulationType':
      return <span style={{ fontWeight: 600 }}>{value ? fm(`rtms.opts.stimulationType.${value}`) : '-'}</span>;
    case 'pulseIntensity':
    case 'numberOfPulses':
    case 'electricFieldStrength': {
      return (
        <Unit unit={name === 'numberOfPulses' ? undefined : fm(`rtms.units.${name}`)}>
          <React.Fragment>
            {[null, null].concat(name === 'numberOfPulses' ? [null] : []).map((_: null, i: number, arr: null[]) => {
              const additionalStyleOther =
                name !== 'electricFieldStrength' &&
                valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value?.[i], i)
                  ? {
                      color: colors.white,
                      backgroundColor: colors.appBlue.default,
                      padding: '0 2px 0 2px',
                      borderRadius: '0.5rem',
                    }
                  : undefined;
              return (
                <React.Fragment key={`${name}${i}`}>
                  <ToolTip
                    title={fm('rtms.subjectOfTreatment.valueInDoctorsOrders')}
                    cursor={
                      valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value?.[i], i)
                        ? 'Pointer'
                        : 'Default'
                    }
                    description={
                      <>
                        {valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value?.[i], i, true)
                          ? valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value?.[i], i, true)
                          : '-'}
                      </>
                    }
                    flipTitleAndDescStyle
                    hover={true}
                    disabled={!valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value?.[i], i)}
                    content={
                      <InlineDiv
                        style={{
                          fontWeight: 600,
                          ...additionalStyleOther,
                        }}
                      >
                        {(value as Array<number | null>)?.[i] ?? '-'}
                      </InlineDiv>
                    }
                  ></ToolTip>
                  {i < arr.length - 1 && (
                    <InlineDiv style={{ margin: '0rem 1rem 0rem 1rem' }}>
                      {defaultRTMSTreatmentOperators[name]}
                    </InlineDiv>
                  )}
                </React.Fragment>
              );
            })}
            {name === 'numberOfPulses' && (
              <React.Fragment>
                <InlineDiv style={{ margin: '0rem 1rem 0rem 1rem' }}>{'='}</InlineDiv>
                <InlineDiv>
                  {Array.isArray(value)
                    ? value.reduce((p: number, c: number) => (p ?? 0) + (!!c || c === 0 ? c : 0), 0)
                    : ''}
                </InlineDiv>
              </React.Fragment>
            )}
          </React.Fragment>
        </Unit>
      );
    }
    case 'additionalInformation': {
      return <span style={{ fontWeight: 600 }}>{(value as string | number) || '-'}</span>;
    }
    default: {
      return (
        <Unit unit={fm(`rtms.units.${name}`)}>
          <ToolTip
            title={fm('rtms.subjectOfTreatment.valueInDoctorsOrders')}
            cursor={
              valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value) ? 'Pointer' : 'Default'
            }
            description={
              <>
                {valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value, undefined, true)
                  ? valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value, undefined, true)
                  : '-'}
              </>
            }
            flipTitleAndDescStyle
            hover={true}
            disabled={!valueDiffersFromDoctorsOrders(currentSession, currentIndex ?? 0, name, value)}
            content={
              <span
                style={{
                  fontWeight: 600,
                  ...additionalStyleDefault,
                }}
              >
                {(value as string | number) ?? '-'}
              </span>
            }
          ></ToolTip>
        </Unit>
      );
    }
  }
};

const deleteReasonFormatter = (t: IRTMSTreatment): string | JSX.Element =>
  t.deleteReason ? (
    <React.Fragment>
      <div
        style={{
          fontWeight: 600,
          color: t.deleteReason === 'partOfTreatmentProtocol' ? colors.tertiaryText : colors.error.default,
        }}
      >
        {fm(
          `rtms.subjectOfTreatment.${
            t.deleteReason === 'partOfTreatmentProtocol' ? 'accordingToProtocol' : 'contraryToProtocol'
          }`,
        )}
      </div>
      <div style={{ color: colors.tertiaryText, textTransform: 'lowercase' }}>
        {fm('rtms.subjectOfTreatment.noTreatmentGiven')}
      </div>
    </React.Fragment>
  ) : (
    ''
  );

const TreatmentsByProtocol = ({
  events,
  secondaryEvents = [],
  eventsBeyondThisDoc,
}: ITreatmentsByProtocol): JSX.Element => {
  const rtmsContext = React.useContext(RTMSContext);
  const { allDoctorsOrders, ninmtTreatmentPeriods } = rtmsContext;

  // Used to change the range of events displayed in table view
  const [eventRange, setEventRange] = React.useState<[number, number]>([0, 3]);

  const changeEventRange = (change: number) => (): void => {
    let newRange: React.SetStateAction<[number, number]> = [...eventRange];
    newRange = [newRange[0] + change, newRange[1] + change];
    setEventRange(newRange);
  };

  // Dialog to select fields filtered by infoFilter
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

  const openCloseDialog = () => (): void => {
    !dialogOpen ? setDialogOpen(true) : setDialogOpen(false);
  };

  let allEventsSorted: Array<IRTMSSession | IRTMSUnusedSession> = [];
  allEventsSorted = ([...(events ?? []), ...(secondaryEvents ?? [])] as Array<IRTMSSession | IRTMSUnusedSession>)
    .sort((s1, s2) => (s2?.createDate ?? 0) - (s1?.createDate ?? 0))
    .sort((s1, s2) => sortPartialDate(s2.date, s1.date));
  const allEvents = allEventsSorted.length > 0 ? allEventsSorted : events;

  // Docs are ordered oldest --> newest in table view
  const docs = allEvents?.slice().reverse();

  type TSymptomAndLocation = {
    symptom: ISymptomsAndLocations['symptom'];
    location?: ISymptomsAndLocations['symptomLocation'];
    description?: ISymptomsAndLocations['symptomDescription'];
  };
  const patientsRatingLocations: Array<TSymptomAndLocation> = [];

  // Get unique name combinations (to be treated as individual protocols)
  // Also include stimulation types to determine which fields to show
  const namesOfSOT: (TSOTNameObj & { stimulationType?: IRTMSTreatment['stimulationType'] })[] = [];
  const allSubjectsOfTreatment: IRTMSTreatment[] = [];

  if (Array.isArray(events)) {
    events.forEach((d) => {
      d.patientsRating?.forEach((p) => {
        p.ratingByLocation?.forEach((r) => {
          const sl = { symptom: p.symptom, location: r.location, description: r.description };
          if (!patientsRatingLocations.some((prl) => equals(prl, sl))) patientsRatingLocations.push(sl);
        });
      });

      d.subjectOfTreatment?.forEach((s) => {
        allSubjectsOfTreatment.push(s);
        const sot = createSOTNameObj(s);
        if (sot.name && !namesOfSOT.some((name) => equals(sot, name))) namesOfSOT.push(sot);
      });
    });

    namesOfSOT.forEach((nameAndTypeObj) => {
      const treatments = allSubjectsOfTreatment.filter((s) => equals(createSOTNameObj(s), nameAndTypeObj));

      let stimulationType: IRTMSTreatment['stimulationType'] = undefined;

      // Of all the stimulation types that the subjects of treatments have, the one with the most fields is picked
      if (treatments?.some((t) => t.stimulationType === 'iTBS')) stimulationType = 'iTBS';
      else if (treatments?.some((t) => t.stimulationType === 'cTBS')) stimulationType = 'cTBS';
      else stimulationType = 'originalRTMS';

      nameAndTypeObj.stimulationType = stimulationType;
    });
  }

  const subjectsOfTreatment = events?.map((d) => d.subjectOfTreatment ?? []) ?? [];
  let subjectsOfTreatmentMax = subjectsOfTreatment?.[0] ?? [];
  subjectsOfTreatment?.slice(1).forEach((sot) => {
    if (sot.length > subjectsOfTreatmentMax.length) subjectsOfTreatmentMax = sot;
  });

  let headers: string[] = [];
  if (allSubjectsOfTreatment?.some((t) => t.stimulationType === 'iTBS')) headers = [...iTBSFields];
  else if (allSubjectsOfTreatment?.some((t) => t.stimulationType === 'cTBS')) headers = [...cTBSFields];
  else headers = [...originalRTMSFields];

  if (
    allSubjectsOfTreatment?.some((t) => t.stimulationType === 'originalRTMS') &&
    !headers.includes('pulseFrequency')
  ) {
    headers.splice(headers.indexOf('pulseFrequency3PulseBurst'), 0, 'pulseFrequency');
  }

  if (patientsRatingLocations.length > 0 && !headers.includes('patientsRating')) {
    headers.unshift('patientsRating');
  }

  const [headersFiltered, setHeadersFiltered] = React.useState<typeof headers>(headers);

  const onChangeHeadersFiltered = (value: TOnChangeValues): void => {
    setHeadersFiltered(headers.filter((h) => (value[Object.keys(value)[0]] as typeof headers).includes(h)));
  };

  // Make date headers
  const dates = docs?.map((d) => (isPartialDate(d.date) ? formatPartialDate(d.date) : ''));

  // If the length of sessions is more than 4, set eventRange to the "end" of sessions by default
  React.useEffect(() => {
    Array.isArray(docs) &&
      docs.length > 4 &&
      !equals(eventRange, [docs.length - 4, docs.length - 1]) &&
      setEventRange([docs.length - 4, docs.length - 1]);
  }, [Array.isArray(docs) && docs.length]);

  return (
    <React.Fragment>
      <Dialog
        open={dialogOpen}
        fullWidth={true}
        PaperComponent={DialogPaper}
        sx={{ '& .MuiDialog-container': { '& .MuiPaper-root': { maxWidth: '494px' } } }}
      >
        <DialogTitle style={dialogTitle}>
          <Container style={{ alignItems: 'baseline' }}>
            <Item xs={7}>{fm('rtms.selectInformation')}</Item>
            <Item xs={5} style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '1.4rem', fontWeight: 400 }}>
              <StyledSelectButton onClick={() => setHeadersFiltered(headers)}>
                {fm('rtms.selectAll')}
              </StyledSelectButton>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <StyledSelectButton onClick={() => setHeadersFiltered([])}>{fm('rtms.deselectAll')}</StyledSelectButton>
            </Item>
          </Container>
        </DialogTitle>
        <DialogContent style={dialogContent}>
          {dialogOpen && (
            <InputHandler
              name="headersFiltered"
              type="Checkbox"
              editing={true}
              formData={{ document: { headersFiltered: headersFiltered }, onChange: onChangeHeadersFiltered }}
              options={headers}
              optionFormatter={(id: string | number | boolean) =>
                id === 'patientsRating'
                  ? fm('rtms.patientsRating.title')
                  : fm(`rtms.subjectOfTreatment.${id}${id === 'additionalInformation' ? 'Subject' : ''}`)
              }
            />
          )}
        </DialogContent>
        <DialogActions style={dialogActions}>
          <ActionButton text="general.accept" onClick={openCloseDialog()} width={12} height={3} fontSize={16} />
        </DialogActions>
      </Dialog>
      <StyledBarHeaderContainer alignItems="center" justifyContent="space-between">
        <Item xs={2}>
          <InputHandler
            name="headersFilter"
            type="RadioButtonRow"
            editing={true}
            formData={{
              document: { headersFilter: headersFiltered.length === headers.length ? 'all' : 'selected' },
              onChange: (values: TOnChangeValues) => {
                const value = values['headersFilter'];
                if (value === 'selected' || (!equals(headersFiltered, headers) && value === null)) openCloseDialog()();
                else setHeadersFiltered(headers);
              },
            }}
            options={['all', 'selected']}
            optionFormatter={(id: string | number | boolean) => fm(`rtms.${id}Information`)}
          />
        </Item>
        <Item xs={true} style={{ marginRight: '-2.5rem' }}>
          {(docs?.length ?? 0) > 1 && (
            <StyledTableControls eventRange={eventRange} tableEvents={docs ?? []} changeEventRange={changeEventRange} />
          )}
        </Item>
      </StyledBarHeaderContainer>
      <Container
        style={{
          marginBottom: patientsRatingLocations.length > 0 && headersFiltered.includes('patientsRating') ? '1.5rem' : 0,
          fontWeight: 'bold',
        }}
      >
        {patientsRatingLocations.length > 0 && headersFiltered.includes('patientsRating') ? (
          <StyledTitleItem xs={3}>{fm('rtms.patientsRating.title')}</StyledTitleItem>
        ) : (
          <Item xs={3} />
        )}
        {dates?.slice(eventRange[0], eventRange[1] + 1).map((d, i) => (
          <Item key={`${d} ${i}`} xs={2}>
            {d}
          </Item>
        ))}
      </Container>
      {patientsRatingLocations.length > 0 &&
        headersFiltered.includes('patientsRating') &&
        patientsRatingLocations.map((sl, index, slArr) => (
          <React.Fragment key={index}>
            <NameContainer>
              <Item xs={3} style={{ minHeight: 30 }}>
                {sl.symptom === 'other' ? (
                  <div style={{ paddingRight: '1.5rem' }}>
                    {fm('rtms.patientsRating.opts.other')}
                    {sl.description && sl.description.length > 0 && (
                      <ToolTip
                        cursor={sl.description.length > 30 ? 'Pointer' : 'Default'}
                        description={sl.description}
                        hover={true}
                        disabled={sl.description.length <= 30}
                        content={
                          <span>
                            {sl.description.length > 30 ? `: ${sl.description.slice(0, 30)}...` : `: ${sl.description}`}
                          </span>
                        }
                      />
                    )}
                  </div>
                ) : sl.location ? (
                  fm(`rtms.patientsRating.opts.${sl.location}`)
                ) : (
                  ''
                )}
              </Item>
            </NameContainer>
            {['intensity', 'harm'].map((name, ind, arr) => {
              return (
                <StyledTableContainer
                  key={ind}
                  index={ind}
                  arrLength={arr.length}
                  style={{ marginBottom: index === slArr.length - 1 || ind < arr.length - 1 ? 0 : '1.5rem' }}
                >
                  <StyledTableItem xs={3} firstCell={true}>
                    {fm(`rtms.patientsRating.${sl.symptom === 'other' ? 'symptom' : sl.symptom}${capitalize(name)}`)}
                  </StyledTableItem>
                  {docs?.slice(eventRange[0], eventRange[1] + 1).map((e: IRTMSSession, i: number) => {
                    const patientsRating = e.patientsRating?.find(
                      (p) => p.ratingByLocation?.some((r) => r.location === sl.location),
                    );
                    const ratingByLocation = patientsRating?.ratingByLocation?.find((r) => r.location === sl.location);
                    return secondaryEvents?.includes(e) ? (
                      <StyledTableSecondaryItem key={i} index={ind} xs={2}>
                        <span style={{ visibility: 'hidden' }}>{'-'}</span>
                      </StyledTableSecondaryItem>
                    ) : (
                      <StyledTableItem key={i} firstCell={false} xs={2}>
                        {ratingByLocation && (
                          <FormattedPatientsRatingValue
                            ratingByLocation={ratingByLocation}
                            name={name as 'intensity' | 'harm'}
                          />
                        )}
                      </StyledTableItem>
                    );
                  })}
                </StyledTableContainer>
              );
            })}
          </React.Fragment>
        ))}
      <Container style={{ padding: '1.6rem 0 1.6rem 0', marginBottom: '1.3rem' }}>
        <StyledTableItem xs={3} firstCell={true} style={{ paddingLeft: 0 }}>
          <Container>
            <Item xs={true}>{fm('rtms.additionalInformationSession')}</Item>
          </Container>
        </StyledTableItem>
        {docs?.slice(eventRange[0], eventRange[1] + 1).map((e: IRTMSSession | IRTMSSession, i: number) => {
          return (
            <StyledTableItem key={i} firstCell={false} xs={2} style={{ fontWeight: 600 }}>
              {!secondaryEvents?.includes(e) && ((e as IRTMSSession).additionalInformation || '-')}
            </StyledTableItem>
          );
        })}
      </Container>
      <Container style={{ marginBottom: '2rem' }}>
        <StyledTitleItem xs={3}>{fm('rtms.deviceAndRMT')}</StyledTitleItem>
      </Container>
      {['device', 'rmt'].map((field, index, arr) => {
        return (
          <StyledTableContainer key={index} index={index} arrLength={arr.length}>
            <StyledTableItem xs={3} firstCell={true}>
              {fm(`rtms.${field}`)}
            </StyledTableItem>
            {docs?.slice(eventRange[0], eventRange[1] + 1).map((e: IRTMSSession, i: number, arr) => {
              const precedingDoctorsOrder = findPrecedingDoctorsOrder(
                'rtms',
                allDoctorsOrders,
                arr ?? [],
                i ?? 0,
                [field],
                ninmtTreatmentPeriods,
              );

              return secondaryEvents?.includes(e) ? (
                <StyledTableSecondaryItem key={i} index={index} xs={2}>
                  <span style={{ visibility: 'hidden' }}>{'-'}</span>
                </StyledTableSecondaryItem>
              ) : (
                <StyledTableItem key={i} firstCell={false} xs={2}>
                  <FormattedDoctorsOrderValue
                    name={field as 'device' | 'rmt'}
                    precedingDoctorsOrder={precedingDoctorsOrder}
                  />
                </StyledTableItem>
              );
            })}
          </StyledTableContainer>
        );
      })}
      {namesOfSOT.map((nameAndTypeObj, arrayIndex) => (
        <React.Fragment key={arrayIndex}>
          <Container style={{ marginBottom: '2rem', marginTop: arrayIndex > 0 ? '2.5rem' : undefined }}>
            <StyledTitleItem xs={3}>{nameFormatter(nameAndTypeObj)}</StyledTitleItem>
          </Container>
          {Array.isArray(headersFiltered) &&
            headersFiltered
              .filter((field) => {
                // Pulse frequency is included even if there is just one SOT with originalRTMS stimulation type
                const hasPulseFrequency = events?.some(
                  (d) =>
                    d.subjectOfTreatment?.some(
                      (s) =>
                        s.stimulationType === 'originalRTMS' &&
                        equals(createSOTNameObj(s), omit(['stimulationType'], nameAndTypeObj)),
                    ),
                );

                if (field === 'pulseFrequency') return hasPulseFrequency;

                if (nameAndTypeObj.stimulationType === 'iTBS') return iTBSFields.includes(field);
                if (nameAndTypeObj.stimulationType === 'cTBS') return cTBSFields.includes(field);
                return originalRTMSFields.includes(field);
              })
              .map((field, index, arr) => {
                const getHeader = (f: string): JSX.Element => {
                  const localizationPath = 'rtms.subjectOfTreatment.';
                  if (f === 'additionalInformation') {
                    return fm(localizationPath + 'additionalInformationSubject');
                  }
                  return fm(localizationPath + f);
                };

                return (
                  <StyledTableContainer
                    key={index}
                    index={index}
                    arrLength={arr.length}
                    style={{ marginBottom: index === arr.length - 1 ? 0 : undefined }}
                  >
                    <StyledTableItem xs={3} firstCell={true}>
                      {getHeader(field)}
                    </StyledTableItem>
                    {docs?.slice(eventRange[0], eventRange[1] + 1).map((e: IRTMSSession, i: number) => {
                      const nameObj = omit(['stimulationType'], nameAndTypeObj);

                      if (secondaryEvents?.includes(e)) {
                        return (
                          <StyledTableSecondaryItem key={i} index={index} xs={2}>
                            <span style={{ visibility: 'hidden' }}>{'-'}</span>
                          </StyledTableSecondaryItem>
                        );
                      } else {
                        const treatments = e.subjectOfTreatment?.filter((s) => equals(createSOTNameObj(s), nameObj));
                        const treatment = treatments?.find((t) => equals(createSOTNameObj(t), nameObj));

                        const allSessions = eventsBeyondThisDoc?.slice().reverse() ?? [];
                        const sessionIndex = allSessions?.findIndex((d) => equals(d, e));
                        const treatmentIndex = e.subjectOfTreatment?.findIndex((s) => equals(s, treatment)) ?? 0;

                        const sessionNumber = getSessionNumber(
                          allSessions,
                          sessionIndex,
                          treatment ?? ({} as IRTMSTreatment),
                          true,
                        );

                        const { name, value, fieldMatchesType } = getRtmsRowData(
                          field as keyof IRTMSTreatmentEvent,
                          treatment ?? { id: '', name: '' },
                        );

                        return (
                          <StyledTableItem key={i} firstCell={false} xs={2}>
                            {treatment?.deleted ? (
                              field === 'additionalInformation' ? (
                                deleteReasonFormatter(treatment)
                              ) : (
                                ''
                              )
                            ) : field === 'session' ? (
                              sessionNumber || ''
                            ) : fieldMatchesType ? (
                              treatment ? (
                                <FormattedRTMSTreatmentValue
                                  name={name}
                                  value={value}
                                  currentSession={e}
                                  currentIndex={treatmentIndex}
                                />
                              ) : (
                                ''
                              )
                            ) : (
                              ''
                            )}
                          </StyledTableItem>
                        );
                      }
                    })}
                  </StyledTableContainer>
                );
              })}
        </React.Fragment>
      ))}
      <Container style={{ padding: '1.6rem 0 1.6rem 0' }}>
        <StyledTableItem xs={3} firstCell={true}>
          <Container>
            <Item xs={1} style={{ display: 'flex', alignSelf: 'center' }}>
              <StyledTitleBox />
            </Item>
            <Item xs={true}>{fm('rtms.unusedSessionAndReason')}</Item>
          </Container>
        </StyledTableItem>
        {docs?.slice(eventRange[0], eventRange[1] + 1).map((e: IRTMSSession | IRTMSUnusedSession, i: number) => {
          if (secondaryEvents?.includes(e)) {
            const reason = (e as IRTMSUnusedSession).reason;
            const reasonDetails = (e as IRTMSUnusedSession).reasonDetails;
            const reasonDetailsOther = reasonDetails === 'other' && (e as IRTMSUnusedSession).reasonDetailsOther;
            return (
              <StyledTableSecondaryItem
                key={i}
                index={0}
                xs={2}
                style={{
                  margin: '-1.6rem 1rem -1.6rem -1rem',
                  padding: '1.6rem 0 1.6rem 1rem',
                  backgroundColor: headersFiltered.length % 2 ? colors.highlight.lightest : colors.highlight.light,
                  fontWeight: 600,
                }}
              >
                <div>{reason ? fm(`rtms.opts.unusedSessionReason.${reason}`) : '-'}</div>
                {reason && (
                  <div>
                    {reasonDetails ? fm(`rtms.opts.unusedSessionReasonDetails.${reasonDetails}`) : '-'}
                    {reasonDetails === 'other' &&
                      reasonDetailsOther &&
                      reasonDetailsOther.length > 0 &&
                      `: ${reasonDetailsOther}`}
                  </div>
                )}
              </StyledTableSecondaryItem>
            );
          } else {
            return <StyledTableItem key={i} firstCell={false} xs={2} style={{ fontWeight: 600 }} />;
          }
        })}
      </Container>
    </React.Fragment>
  );
};

interface ITreatmentsByProtocol {
  events?: Array<IRTMSSession>;
  secondaryEvents?: Array<IRTMSUnusedSession>;
  eventsBeyondThisDoc?: Array<IRTMSSession>;
}

export default TreatmentsByProtocol;
