import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { equals, intersperse, isEmpty } from 'ramda';
import { exists, formatPartialDate } from 'neuro-utils';

import colors from '../../../../config/theme/colors';
import PlatformConditional from '../../../../components/PlatformConditional';
import { rowData, RowDataPropsNested } from '../FormDynamic/rowData';
import { existsAndIsNotNull, mergeDocuments } from '../../utils';
import HistoryRowSubHeader from '../../../../components/HistoryRowSubHeader';
import HistoryRowVerticalItem from '../../../../components/HistoryRowVerticalItem';
import { Container, Item } from 'Components/Grid';
import { drawOccupation, drawSiblingDiagnosisAge, drawSickLeaveDays } from 'Routes/Background/utils/functions';
import { ICompactVaultUser } from 'neuro-data-structures';
import { surveyCreator, surveyModifyer } from 'Routes/MyService/Document/HistoryRowData/SurveysHistory';
import { useSelector } from 'react-redux';
import { packYears } from 'neuro-calculation-commons';
import { path } from 'Utility/ramdaReplacement';

const fm = (id: string, values?: { [key: string]: string }): JSX.Element => (
  <FormattedMessage id={id} values={values || {}} />
);

const StyledRow = ({ title, value, date, description }: IStyledRow): JSX.Element => {
  const filteredValue =
    typeof value === 'object' && value?.value === null
      ? undefined
      : typeof value === 'object' && value?.value === 0
        ? 0
        : value;
  return (
    <HistoryRowVerticalItem
      header={title}
      value={
        <>
          <div>
            {Array.isArray(filteredValue)
              ? filteredValue.length > 0
                ? intersperse(', ', filteredValue)
                : '-'
              : exists(filteredValue)
                ? filteredValue
                : '-'}
          </div>
          <div style={{ fontSize: '1.2rem', color: colors.darkGray }}>{date ? formatPartialDate(date) : ''}</div>
        </>
      }
      description={description}
    />
  );
};

const HistorySection = ({ d, title, rows, platform, section, docs }: IHistorySection): JSX.Element => {
  const rowDataAsserted: RowDataPropsNested = section
    ? rowData[platform][section]
    : (rowData[platform] as RowDataPropsNested);

  return (
    <React.Fragment>
      <Container alignItems="center" style={{ marginBottom: '1rem' }}>
        <Item xs={9}>
          <HistoryRowSubHeader header={<FormattedMessage id={`background.${title}`} />} />
        </Item>
        <Item xs={3}></Item>
      </Container>
      {rows
        .filter((r) => r) // prune falsy values such as empty strings
        .map((row) => {
          if (row === 'packYears' && docs) {
            const value = packYears(docs);
            const rowDate = existsAndIsNotNull(d?.[row]) ? d?.[row]['date'] : undefined;

            return (
              value > 0 && (
                <StyledRow
                  key={row}
                  title={<FormattedMessage id={`background.packYears`} />}
                  value={exists(value) ? value : '-'}
                  date={rowDate}
                  description={<FormattedMessage id={`background.packYearsInfo`} />}
                />
              )
            );
          }

          const rowAsserted: keyof IBackground = row as keyof IBackground;
          const rowValue =
            existsAndIsNotNull(d?.[row]) && !isEmpty(d?.[row])
              ? exists(d?.[row]['value'])
                ? d?.[row]['value']
                : d?.[row]
              : undefined;
          const rowDate = existsAndIsNotNull(d?.[row]) ? d?.[row]['date'] : undefined;
          return rowDataAsserted[rowAsserted] && rowDataAsserted[rowAsserted]?.['type'] === 'PartialDate' ? (
            <StyledRow
              key={row}
              title={<FormattedMessage id={`background.${row}`} />}
              value={exists(rowValue) ? formatPartialDate(rowValue as PartialDate) : '-'}
              date={exists(rowValue) && formatPartialDate(rowValue as PartialDate) ? rowDate : undefined}
            />
          ) : rowDataAsserted[rowAsserted] && rowDataAsserted[rowAsserted]?.['type'] === 'Radio' ? (
            <StyledRow
              key={row}
              title={<FormattedMessage id={`background.${row}`} />}
              value={
                exists(rowValue) ? (
                  <FormattedMessage
                    id={`background.opts.${rowDataAsserted[rowAsserted]?.['optionFormat'] ?? row}.${rowValue}`}
                  />
                ) : (
                  '-'
                )
              }
              date={rowDate}
            />
          ) : rowDataAsserted[rowAsserted] && rowDataAsserted[rowAsserted]?.['type'] === 'Checkbox' ? (
            <StyledRow
              key={row}
              title={
                <FormattedMessage id={`background.${row.substring(0, 13) === 'familyMembers' ? 'relatives' : row}`} />
              }
              value={
                exists(rowValue) && Array.isArray(rowValue) && rowValue.length > 0
                  ? rowValue
                      .map((s: string) => (
                        <React.Fragment key={s}>
                          <FormattedMessage
                            id={`background.opts.${
                              row === 'familyMembersAnamnesisHuntington'
                                ? 'relatives'
                                : row === 'familyMembersAnamnesisMS'
                                  ? 'familyMembers'
                                  : row === 'familyMembersParkinson'
                                    ? 'familyMembers'
                                    : row === 'familyMembers'
                                      ? 'relatives'
                                      : row
                            }.${s}`}
                          />
                          {s === 'relativeDegree2' && <span>&nbsp;{d.relativeDegree2FreeText}</span>}
                        </React.Fragment>
                      ))
                      .map((o, i) => <React.Fragment key={i}>{o}</React.Fragment>)
                  : '-'
              }
              date={exists(rowValue) && Array.isArray(rowValue) && rowValue.length > 0 ? rowDate : undefined}
            />
          ) : (
            <StyledRow
              key={row}
              title={<FormattedMessage id={`background.${row}`} />}
              value={exists(rowValue) ? rowValue : '-'}
              date={rowDate}
            />
          );
        })}
    </React.Fragment>
  );
};

const BackgroundHistoryRowData = ({ documents, d, users }: IOwnProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const fmFunc = (id: string) => formatMessage({ id });

  const mysqUserId = useSelector((s: IState) => s.myapp.mysqUserId) || '';
  const patientDelegates = useSelector((s: IState) => s.patient.delegates) || [];

  const historyDocument: { [key: string]: any } | Partial<IBackground> =
    d === documents[0] && documents.length > 1 ? mergeDocuments(documents) : d;

  const arrayExists = (value: Record<string, any>): boolean =>
    value && value.value && Array.isArray(value.value) && value.value.length > 0;

  return (
    <React.Fragment>
      <PlatformConditional platform="parkinson">
        <>
          <StyledRow
            title={fm('myService.reporter')}
            value={
              <span style={{ fontWeight: 600 }}>
                {surveyCreator(historyDocument, mysqUserId, users, patientDelegates, fmFunc)}
              </span>
            }
          />
          {surveyModifyer(historyDocument, mysqUserId, users, patientDelegates, fmFunc) && (
            <StyledRow
              title={fm('myService.edited')}
              value={
                <span style={{ fontWeight: 600 }}>
                  {surveyModifyer(historyDocument, mysqUserId, users, patientDelegates, fmFunc)}
                </span>
              }
            />
          )}
          <HistorySection
            d={historyDocument}
            title="familyAnamnesis"
            rows={
              drawSiblingDiagnosisAge(historyDocument)
                ? [
                    'familyPrevalenceParkinson',
                    'familyPrevalenceDisease',
                    'familyMembersParkinson',
                    'siblingDiagnosisAge',
                  ]
                : ['familyPrevalenceParkinson', 'familyPrevalenceDisease', 'familyMembersParkinson']
            }
            platform="parkinson"
            section="basicInformation"
          />
          <HistorySection
            d={historyDocument}
            title="familyAndDwelling"
            rows={['maritalStatus', 'typeOfLiving']}
            platform="parkinson"
            section="basicInformation"
          />
          <HistorySection
            d={historyDocument}
            title="workAndCurrentStatus"
            rows={[
              'educationYears',
              'employment',
              ...((drawOccupation(historyDocument) ? ['occupation'] : []) as Array<keyof IBackground>),
              'partTimeHours',
              ...((drawSickLeaveDays(historyDocument) ? ['sickLeaveDays'] : []) as Array<keyof IBackground>),
              ...((Array.isArray(historyDocument?.employment) && historyDocument.employment.includes('unemployed')
                ? ['unemploymentDays']
                : []) as Array<keyof IBackground>),
              ...((Array.isArray(historyDocument?.employment) && historyDocument.employment.includes('retired')
                ? ['retirementType']
                : []) as Array<keyof IBackground>),
            ]}
            platform="parkinson"
            section="basicInformation"
          />
          <HistorySection
            d={historyDocument}
            title="drivingHealth"
            rows={[
              'driversLicense',
              ...((historyDocument?.driversLicense === 'yes' || historyDocument?.driversLicense?.value === 'yes'
                ? ['driversLicenseGroup']
                : []) as Array<keyof IBackground>),
            ]}
            platform="parkinson"
            section="drivingHealth"
          />
          <HistorySection
            d={historyDocument}
            title="earlierHeadInjury"
            rows={['earlierHeadInjury', 'earlierHeadInjuryYear']}
            platform="parkinson"
            section="earlierHeadInjury"
          />
          <HistorySection
            d={historyDocument}
            title="powerOfAttorney"
            rows={[
              'powerOfAttorney',
              ...((historyDocument?.powerOfAttorney === 'yes' || historyDocument?.powerOfAttorney?.value === 'yes'
                ? ['powerOfAttorneyStart']
                : []) as Array<keyof IBackground>),
            ]}
            platform="parkinson"
            section="powerOfAttorney"
          />
          <HistorySection
            d={historyDocument}
            title="careAllowance"
            rows={[
              'careAllowance',
              ...((historyDocument?.careAllowance === 'yes' || historyDocument?.careAllowance?.value === 'yes'
                ? ['careAllowanceClass']
                : []) as Array<keyof IBackground>),
            ]}
            platform="parkinson"
            section="careAllowance"
          />
          <HistorySection
            d={historyDocument}
            title="firearmsLicense"
            rows={['firearmsLicense']}
            platform="parkinson"
            section="firearmsLicense"
          />
        </>
      </PlatformConditional>
      <PlatformConditional platform="sma">
        <>
          <HistorySection
            d={historyDocument}
            title="basicData"
            rows={['dominantArm', 'pregnancyWeeksAtBirth']}
            platform="sma"
          />
          <HistorySection
            d={historyDocument}
            title="familyAnamnesis"
            rows={
              historyDocument?.familyMemberDiagnosedSMA === 'yes' ||
              historyDocument?.familyMemberDiagnosedSMA?.value === 'yes'
                ? ['familyMemberDiagnosedSMA', 'familyMembers']
                : ['familyMemberDiagnosedSMA']
            }
            platform="sma"
          />
        </>
      </PlatformConditional>
      <PlatformConditional platform="dmd">
        <>
          <HistorySection
            d={historyDocument}
            title="basicData"
            rows={['dominantArm', 'pregnancyWeeksAtBirth']}
            platform="dmd"
          />
          <HistorySection
            d={historyDocument}
            title="familyAnamnesis"
            rows={
              historyDocument?.familyMemberDiagnosedDMD?.value === 'yes'
                ? ['familyMemberDiagnosedDMD', 'familyMembers']
                : ['familyMemberDiagnosedDMD']
            }
            platform="dmd"
          />
        </>
      </PlatformConditional>
      <PlatformConditional platform={['ms']}>
        <>
          <HistorySection d={historyDocument} title="basicData" rows={['smoking']} platform="ms" />
          <HistorySection
            d={historyDocument}
            title="familyAnamnesis"
            rows={
              historyDocument?.familyAnamnesisMS === 'yes' || historyDocument?.familyAnamnesisMS?.value === 'yes'
                ? ['familyAnamnesisMS', 'familyMembersAnamnesisMS']
                : ['familyAnamnesisMS']
            }
            platform="ms"
          />
          <HistorySection
            d={historyDocument}
            title="educationAndEmployment"
            rows={
              (Array.isArray(historyDocument?.employment) && historyDocument?.employment?.includes('other')) ||
              (Array.isArray(historyDocument?.employment?.value) &&
                historyDocument?.employment?.value.includes('other'))
                ? ['education', 'employment', 'employmentOther']
                : ['education', 'employment']
            }
            platform="ms"
          />
          <HistorySection
            d={historyDocument}
            title="spouseAndChildren"
            rows={
              ['maritalStatus', 'childCount', 'pregnancyDueDate', 'hormonalTherapy', 'sterilization'].filter((r) => {
                if (d === documents[0] && documents.length > 1) {
                  return Array.isArray(path(['pregnancyDueDate', 'value'], historyDocument)) &&
                    path(['pregnancy', 'value'], historyDocument) === 'pregnant' &&
                    equals(
                      path(['pregnancyDueDate', 'date'], historyDocument),
                      path(['pregnancy', 'date'], historyDocument),
                    )
                    ? r
                    : r !== 'pregnancyDueDate';
                }
                return Array.isArray(historyDocument?.pregnancyDueDate) && historyDocument?.pregnancy === 'pregnant'
                  ? r
                  : r !== 'pregnancyDueDate';
              }) as Array<keyof IBackground>
            }
            platform="ms"
          />
          {(['pregnancies', 'nursing'] as Array<keyof IBackground>).map((key: keyof IBackground) => {
            const array: Array<IPregnancyMS | INursing> = d[key] as Array<IPregnancyMS | INursing>;
            return (
              <React.Fragment key={key}>
                {Array.isArray(array) && array.length > 0 ? (
                  <StyledRow
                    title={fm(`background.${key}`)}
                    value={
                      <React.Fragment>
                        {array?.map((doc: IPregnancyMS | INursing, i: number) => (
                          <div key={i}>
                            {doc.startDate ? formatPartialDate(doc.startDate) : '-'} -{' '}
                            {doc.endDate ? formatPartialDate(doc.endDate) : '-'}
                          </div>
                        ))}
                      </React.Fragment>
                    }
                  />
                ) : (
                  <>
                    {arrayExists(historyDocument[key]) ? (
                      <StyledRow
                        title={fm(`background.${key}`)}
                        value={
                          <>
                            {historyDocument[key].value.map((set: IPregnancyMS | INursing, i: number) => (
                              <div key={i}>
                                {set.startDate ? formatPartialDate(set.startDate) : '-'} -{' '}
                                {set.endDate ? formatPartialDate(set.endDate) : '-'}
                              </div>
                            ))}
                          </>
                        }
                        date={historyDocument[key].date}
                      />
                    ) : (
                      <StyledRow title={fm(`background.${key}`)} value={'-'} />
                    )}
                  </>
                )}
              </React.Fragment>
            );
          })}
          <HistorySection
            d={historyDocument}
            title="dwellingAndAssistance"
            rows={['typeOfLiving', 'reimbursementValidUntil', 'allowance', 'familyCarer', 'personalAssistant']}
            platform="ms"
          />
        </>
      </PlatformConditional>
      <PlatformConditional platform={['huntington']}>
        <>
          <HistorySection
            d={historyDocument}
            title="familyAnamnesis"
            rows={
              historyDocument?.familyAnamnesisHuntington === 'yes' ||
              historyDocument?.familyAnamnesisHuntington?.value === 'yes'
                ? ['familyAnamnesisHuntington', 'familyMembersAnamnesisHuntington']
                : ['familyAnamnesisHuntington']
            }
            platform="huntington"
          />
          <HistorySection
            d={historyDocument}
            title="familyAndDwelling"
            rows={['maritalStatus', 'childCount', 'typeOfLiving']}
            platform="huntington"
          />
          <HistorySection
            d={historyDocument}
            title="workAndCurrentStatus"
            rows={(
              [
                'education',
                'educationYears',
                'employment',
                'retirementDate',
                'householdChoresAbility',
                'financeHandlingAbility',
                'dailyActivitiesAbility',
              ] as const
            ).filter((row) =>
              (row === 'retirementDate' &&
                Array.isArray(historyDocument.employment) &&
                !historyDocument.employment?.includes('disabilityPension')) ||
              (row === 'retirementDate' && !historyDocument.employment)
                ? row !== 'retirementDate'
                : row,
            )}
            platform="huntington"
          />
          <HistorySection
            d={historyDocument}
            title="contactPermitForResearch"
            rows={['contactPermit']}
            platform="huntington"
          />
        </>
      </PlatformConditional>
      <PlatformConditional platform={['epilepsy']}>
        <>
          <HistorySection
            d={historyDocument}
            title="basicData"
            rows={(
              [
                'childCount',
                'typeOfLiving',
                'employment',
                'occupation',
                'partTimeHours',
                'sickLeaveDays',
                'unemploymentDays',
                'retirementType',
                'retirementStart',
              ] as const
            ).filter((row) =>
              (row === 'occupation' &&
                Array.isArray(historyDocument.employment) &&
                !historyDocument.employment?.includes('fullTime') &&
                !historyDocument.employment?.includes('partTime')) ||
              (row === 'occupation' && !historyDocument.employment)
                ? row !== 'occupation'
                : (row === 'partTimeHours' &&
                      Array.isArray(historyDocument.employment) &&
                      !historyDocument.employment?.includes('partTime')) ||
                    (row === 'partTimeHours' && !historyDocument.employment)
                  ? row !== 'partTimeHours'
                  : (row === 'sickLeaveDays' &&
                        Array.isArray(historyDocument.employment) &&
                        !historyDocument.employment?.includes('sickLeave')) ||
                      (row === 'sickLeaveDays' && !historyDocument.employment)
                    ? row !== 'sickLeaveDays'
                    : (row === 'unemploymentDays' &&
                          Array.isArray(historyDocument.employment) &&
                          !historyDocument.employment?.includes('unemployed')) ||
                        (row === 'unemploymentDays' && !historyDocument.employment)
                      ? row !== 'unemploymentDays'
                      : (row === 'retirementType' &&
                            Array.isArray(historyDocument.employment) &&
                            !historyDocument.employment?.includes('retired')) ||
                          (row === 'retirementType' && !historyDocument.employment)
                        ? row !== 'retirementType'
                        : (row === 'retirementStart' &&
                              Array.isArray(historyDocument.employment) &&
                              (!historyDocument.employment?.includes('retired') ||
                                historyDocument.retirementType !== 'disabilityPension')) ||
                            (row === 'retirementStart' && !historyDocument.employment)
                          ? row !== 'retirementStart'
                          : row,
            )}
            platform="epilepsy"
            section="basicInformation"
          />
          <HistorySection
            d={historyDocument}
            title="drivingHealth"
            rows={(['driversLicense', 'driversLicenseGroup'] as const).filter((row) =>
              !historyDocument.driversLicense ||
              historyDocument.driversLicense === 'no' ||
              historyDocument.driversLicense === 'unknown'
                ? row !== 'driversLicenseGroup'
                : row,
            )}
            platform="epilepsy"
            section="drivingHealth"
          />
          {d.driversLicense &&
          d.driversLicense === 'yes' &&
          d.driversLicenseGroup &&
          d.driversLicenseGroup.includes('R1') ? (
            <StyledRow
              title={fm('background.drivingBansR1')}
              value={
                <>
                  {Array.isArray(d.drivingBanR1s)
                    ? d.drivingBanR1s?.map((doc, i) => (
                        <div key={i}>
                          {doc.startDate ? formatPartialDate(doc.startDate) : '-'} -{' '}
                          {doc.endDate ? formatPartialDate(doc.endDate) : '-'}
                        </div>
                      ))
                    : '-'}
                </>
              }
            />
          ) : (
            <>
              {historyDocument.drivingBanR1s && (
                <StyledRow
                  title={fm('background.drivingBansR1')}
                  value={
                    <>
                      {arrayExists(historyDocument.drivingBanR1s)
                        ? historyDocument.drivingBanR1s.value.map((set: IDrivingBan, i: number) => (
                            <div key={i}>
                              {set.startDate ? formatPartialDate(set.startDate) : '-'} -{' '}
                              {set.endDate ? formatPartialDate(set.endDate) : '-'}
                            </div>
                          ))
                        : '-'}
                    </>
                  }
                  date={historyDocument.drivingBanR1s.date}
                />
              )}
            </>
          )}
          {d.driversLicense &&
          d.driversLicense === 'yes' &&
          d.driversLicenseGroup &&
          d.driversLicenseGroup.includes('R2') ? (
            <StyledRow
              title={fm('background.drivingBansR2')}
              value={
                <>
                  {Array.isArray(d.drivingBanR2s)
                    ? d.drivingBanR2s?.map((doc, i) => (
                        <div key={i}>
                          {doc.startDate ? formatPartialDate(doc.startDate) : '-'} -{' '}
                          {doc.endDate ? formatPartialDate(doc.endDate) : '-'}
                        </div>
                      ))
                    : '-'}
                </>
              }
            />
          ) : (
            <>
              {historyDocument.drivingBanR2s && (
                <StyledRow
                  title={fm('background.drivingBansR2')}
                  value={
                    <>
                      {arrayExists(historyDocument.drivingBanR2s)
                        ? historyDocument.drivingBanR2s.value.map((set: IDrivingBan, i: number) => (
                            <div key={i}>
                              {set.startDate ? formatPartialDate(set.startDate) : '-'} -{' '}
                              {set.endDate ? formatPartialDate(set.endDate) : '-'}
                            </div>
                          ))
                        : '-'}
                    </>
                  }
                  date={historyDocument.drivingBanR2s.date}
                />
              )}
            </>
          )}
          <HistorySection
            d={historyDocument}
            title="birthAndEarlyStages"
            rows={(
              [
                'pregnancyTime',
                'weightBirth',
                'heightBirth',
                'headCircumferenceBirth',
                'apgar1min',
                'apgar5min',
                'apgar10min',
                'perinatalComplications',
                'complicationInfo',
                'feverCramps',
                'feverCrampsInfo',
                'disabledDiagnosis',
                'severity',
                'handedness',
              ] as const
            ).filter((row) =>
              row === 'complicationInfo' &&
              (!historyDocument.perinatalComplications || historyDocument.perinatalComplications === 'no')
                ? row !== 'complicationInfo'
                : row === 'feverCrampsInfo' && (!historyDocument.feverCramps || historyDocument.feverCramps === 'no')
                  ? row !== 'feverCrampsInfo'
                  : row === 'severity' &&
                      (!historyDocument.disabledDiagnosis || historyDocument.disabledDiagnosis === 'no')
                    ? row !== 'severity'
                    : row,
            )}
            platform="epilepsy"
            section="birthAndEarlyStages"
          />
          <HistorySection
            d={historyDocument}
            title="familyAnamnesis"
            rows={(
              ['epilepsyFirstDegreeRelative', 'relatives', 'relativityInfo', 'otherFamilyAnamnesis'] as const
            ).filter((row) =>
              !historyDocument.epilepsyFirstDegreeRelative ||
              !(
                historyDocument.epilepsyFirstDegreeRelative !== 'yes' ||
                historyDocument?.epilepsyFirstDegreeRelative?.value !== 'yes'
              )
                ? row !== 'relatives' && row !== 'relativityInfo'
                : row,
            )}
            platform="epilepsy"
            section="familyAnamnesisEpilepsy"
          />
        </>
      </PlatformConditional>
      <PlatformConditional platform={['sleepApnea']}>
        <>
          <StyledRow
            title={fm('myService.reporter')}
            value={
              <span style={{ fontWeight: 600 }}>
                {surveyCreator(historyDocument, mysqUserId, users, patientDelegates, fmFunc)}
              </span>
            }
          />
          {surveyModifyer(historyDocument, mysqUserId, users, patientDelegates, fmFunc) && (
            <StyledRow
              title={fm('myService.edited')}
              value={
                <span style={{ fontWeight: 600 }}>
                  {surveyModifyer(historyDocument, mysqUserId, users, patientDelegates, fmFunc)}
                </span>
              }
            />
          )}
          <HistorySection
            d={historyDocument}
            title="drivingHealth"
            rows={(
              [
                'driversLicense',
                'annualDrivingKM',
                'driversLicenseGroup',
                'professionalDriving',
                'drivingTestDate',
              ] as const
            ).filter((row) =>
              !historyDocument.driversLicense ||
              historyDocument.driversLicense === 'no' ||
              historyDocument.driversLicense === 'unknown'
                ? !['annualDrivingKM', 'driversLicenseGroup', 'professionalDriving', 'drivingTestDate'].includes(row)
                : row,
            )}
            platform="sleepApnea"
            section="drivingHealth"
          />
          {d.driversLicense &&
          d.driversLicense === 'yes' &&
          d.driversLicenseGroup &&
          d.driversLicenseGroup.includes('R1') ? (
            <StyledRow
              title={fm('background.drivingBansR1')}
              value={
                <>
                  {Array.isArray(d.drivingBanR1s) && d.drivingBanR1s.length > 0
                    ? d.drivingBanR1s?.map((doc, i) => (
                        <div key={i}>
                          {doc.startDate ? formatPartialDate(doc.startDate) : '-'} -{' '}
                          {doc.endDate ? formatPartialDate(doc.endDate) : '-'}
                        </div>
                      ))
                    : '-'}
                </>
              }
            />
          ) : (
            <>
              {historyDocument.drivingBanR1s && (
                <StyledRow
                  title={fm('background.drivingBansR1')}
                  value={
                    <>
                      {arrayExists(historyDocument.drivingBanR1s)
                        ? historyDocument.drivingBanR1s.value.map((set: IDrivingBan, i: number) => (
                            <div key={i}>
                              {set.startDate ? formatPartialDate(set.startDate) : '-'} -{' '}
                              {set.endDate ? formatPartialDate(set.endDate) : '-'}
                            </div>
                          ))
                        : '-'}
                    </>
                  }
                  date={historyDocument.drivingBanR1s.date}
                />
              )}
            </>
          )}
          {d.driversLicense &&
          d.driversLicense === 'yes' &&
          d.driversLicenseGroup &&
          d.driversLicenseGroup.includes('R2') ? (
            <StyledRow
              title={fm('background.drivingBansR2')}
              value={
                <>
                  {Array.isArray(d.drivingBanR2s) && d.drivingBanR2s.length > 0
                    ? d.drivingBanR2s?.map((doc, i) => (
                        <div key={i}>
                          {doc.startDate ? formatPartialDate(doc.startDate) : '-'} -{' '}
                          {doc.endDate ? formatPartialDate(doc.endDate) : '-'}
                        </div>
                      ))
                    : '-'}
                </>
              }
            />
          ) : (
            <>
              {historyDocument.drivingBanR2s && (
                <StyledRow
                  title={fm('background.drivingBansR2')}
                  value={
                    <>
                      {arrayExists(historyDocument.drivingBanR2s)
                        ? historyDocument.drivingBanR2s.value.map((set: IDrivingBan, i: number) => (
                            <div key={i}>
                              {set.startDate ? formatPartialDate(set.startDate) : '-'} -{' '}
                              {set.endDate ? formatPartialDate(set.endDate) : '-'}
                            </div>
                          ))
                        : '-'}
                    </>
                  }
                  date={historyDocument.drivingBanR2s.date}
                />
              )}
            </>
          )}
          {d.driversLicense &&
          d.driversLicense === 'yes' &&
          d.professionalDriving &&
          d.professionalDriving === 'yes' ? (
            <StyledRow
              title={fm('background.noProDrivingRequirements')}
              value={
                <>
                  {Array.isArray(d.noProDrivingRequirements) && d.noProDrivingRequirements.length > 0
                    ? d.noProDrivingRequirements.map((doc, i) => (
                        <div key={i}>
                          {doc.startDate ? formatPartialDate(doc.startDate) : '-'} -{' '}
                          {doc.endDate ? formatPartialDate(doc.endDate) : '-'}
                        </div>
                      ))
                    : '-'}
                </>
              }
            />
          ) : (
            <>
              {historyDocument.noProDrivingRequirements && (
                <StyledRow
                  title={fm('background.noProDrivingRequirements')}
                  value={
                    <>
                      {arrayExists(historyDocument.noProDrivingRequirements)
                        ? historyDocument.noProDrivingRequirements.value.map(
                            (set: INoProDrivingRequirement, i: number) => (
                              <div key={i}>
                                {set.startDate ? formatPartialDate(set.startDate) : '-'} -{' '}
                                {set.endDate ? formatPartialDate(set.endDate) : '-'}
                              </div>
                            ),
                          )
                        : '-'}
                    </>
                  }
                  date={historyDocument.noProDrivingRequirements.date}
                />
              )}
            </>
          )}
          <HistorySection
            d={historyDocument}
            title="smoking"
            rows={(
              ['smoker', 'smokingStartYear', 'cigaretteAmountPerDay', 'packYears', 'smokingEndYear'] as const
            ).filter((row) =>
              !historyDocument.smoker || historyDocument.smoker === 'no' || historyDocument.smoker === 'unknown'
                ? row !== 'smokingStartYear' && row !== 'cigaretteAmountPerDay' && row !== 'smokingEndYear'
                : historyDocument?.smoker === 'currentSmoker'
                  ? row !== 'smokingEndYear'
                  : row,
            )}
            platform="sleepApnea"
            section="smoking"
            docs={documents}
          />
          <HistorySection
            d={historyDocument}
            title="snusTitle"
            rows={(['snus', 'snusStart', 'snusPortions', 'snusEnd'] as const).filter((row) =>
              !historyDocument.snus || historyDocument.snus === 'no' || historyDocument.snus === 'unknown'
                ? row !== 'snusStart' && row !== 'snusPortions' && row !== 'snusEnd'
                : historyDocument?.smoker === 'yes'
                  ? row !== 'snusEnd'
                  : row,
            )}
            platform="sleepApnea"
            section="snus"
            docs={documents}
          />
          <HistorySection
            d={historyDocument}
            title="intoxicantUsage"
            rows={(['averageWeeklyAlcohol', 'otherIntoxicants', 'whichIntoxicants'] as const).filter((row) =>
              !historyDocument.otherIntoxicants ||
              historyDocument.otherIntoxicants === 'no' ||
              historyDocument.otherIntoxicants === 'unknown'
                ? row !== 'whichIntoxicants'
                : row,
            )}
            platform="sleepApnea"
            section="intoxicantUsage"
          />
          <HistorySection
            d={historyDocument}
            title="employment"
            rows={['employment']}
            platform="sleepApnea"
            section="employment"
          />
        </>
      </PlatformConditional>
      <PlatformConditional platform={'mgravis'}>
        <HistorySection
          d={historyDocument}
          title="basicData"
          rows={(['smoking', 'smokingStartYear', 'smokingEndYear'] as const).filter(
            (row) =>
              row === 'smoking' ||
              (row === 'smokingStartYear' && ['currentSmoker', 'formerSmoker'].includes(historyDocument?.smoking)) ||
              (row === 'smokingEndYear' && historyDocument?.smoking === 'formerSmoker'),
          )}
          platform="mgravis"
        />
        <>
          {Array.isArray(historyDocument?.pregnancies) && historyDocument?.pregnancies.length > 0 ? (
            <StyledRow
              title={fm('background.pregnancies')}
              value={
                <>
                  {historyDocument.pregnancies.map((set: IPregnancyMS, i: number) => (
                    <div key={i}>
                      {set.startDate ? formatPartialDate(set.startDate) : '-'} -{' '}
                      {set.endDate ? formatPartialDate(set.endDate) : '-'}
                    </div>
                  ))}
                </>
              }
            />
          ) : (
            <>
              {arrayExists(historyDocument?.pregnancies) ? (
                <StyledRow
                  title={fm('background.pregnancies')}
                  value={
                    <>
                      {historyDocument?.pregnancies.value.map((set: IPregnancyMS, i: number) => (
                        <div key={i}>
                          {set.startDate ? formatPartialDate(set.startDate) : '-'} -{' '}
                          {set.endDate ? formatPartialDate(set.endDate) : '-'}
                        </div>
                      ))}
                    </>
                  }
                  date={historyDocument?.pregnancies?.date}
                />
              ) : (
                <StyledRow title={fm('background.pregnancies')} value={'-'} />
              )}
            </>
          )}
        </>
      </PlatformConditional>
    </React.Fragment>
  );
};

interface IHistorySection {
  d: { [key: string]: any };
  title: LocalizationKey;
  rows: Array<keyof IBackground | 'packYears'>;
  platform: Platform;
  section?: string;
  docs?: IBackground[];
}
interface IStyledRow {
  title: string | JSX.Element;
  value?: any;
  date?: PartialDate;
  description?: JSX.Element;
}
interface IOwnProps {
  documents: IBackground[];
  d: IBackground;
  users: ICompactVaultUser[];
}

export default BackgroundHistoryRowData;
