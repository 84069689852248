import * as React from 'react';

import HistoryRowVerticalItem from 'Components/HistoryRowVerticalItem';
import { DoctorsOrdersContext } from '../..';

const AdditionalDocuments = ({ d }: IAdditionalDocuments): JSX.Element => (
  <DoctorsOrdersContext.Consumer>
    {({ fm }) => {
      const additionalDocumentsFormatter = (
        ad: IDoctorsOrderAdditional['additionalDocuments' | 'additionalQuestionnaires'],
        optsLocPath?: string,
      ): string =>
        Array.isArray(ad)
          ? ad
              .map((a) => fm(`doctorsOrders.additionalDocuments.${optsLocPath ?? 'documentsUsed'}.opts.${a}`))
              .join(', ')
          : '-';
      return (
        d && (
          <React.Fragment>
            <HistoryRowVerticalItem
              header={fm('doctorsOrders.additionalDocuments.documentsUsed.title')}
              value={additionalDocumentsFormatter(d.additionalDocuments)}
            />
            {Array.isArray(d.additionalDocuments) && d.additionalDocuments.includes('other') && (
              <HistoryRowVerticalItem
                header={fm('doctorsOrders.additionalDocuments.additionalDocumentsOther.title')}
                value={d.additionalDocumentsOther ?? '-'}
              />
            )}
            <HistoryRowVerticalItem
              header={fm('doctorsOrders.additionalDocuments.questionnairesUsed.title')}
              value={additionalDocumentsFormatter(d.additionalQuestionnaires, 'questionnairesUsed')}
            />
            {d.additionalQuestionnaires && d.additionalQuestionnaires?.includes('other') && (
              <HistoryRowVerticalItem
                header={fm('doctorsOrders.additionalDocuments.additionalDocumentsOther.title')}
                value={d.additionalQuestionnairesOther ?? '-'}
              />
            )}
          </React.Fragment>
        )
      );
    }}
  </DoctorsOrdersContext.Consumer>
);

interface IAdditionalDocuments {
  d: IDoctorsOrderAdditional;
}

export default AdditionalDocuments;
