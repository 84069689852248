import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import { formatPartialDate } from 'neuro-utils';
import { getCdrClass, getCdrTotalScore, getFtldCdrClass, getFtldCdrTotalScore } from 'Routes/Cognition/utils';

const CDR = ({ documents, startEdit, view }: IOwnProps): JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id="cognition.cdr.cdr" />}
      newButton={
        <DocumentCreationButton
          name="cdr"
          text="cognition.cdr.newCdr"
          onClick={startEdit({}, 'cdr')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={2}>
              <FormattedMessage id="general.date" />
            </Item>
            <Item xs={2}>
              <FormattedMessage id="cognition.cdr.cdrClass" />
            </Item>
            <Item xs={2}>
              <FormattedMessage id="cognition.cdr.cdrTotalScore" />
            </Item>
            <Item xs={2}>
              <FormattedMessage id="cognition.cdr.ftldCdrClass" />
            </Item>
            <Item xs={1}>
              <FormattedMessage id="cognition.cdr.ftldCdrTotalScore" />
            </Item>
            <Item xs={3} />
          </>
        }
        contentFormat={(d: ICDR): JSX.Element => (
          <>
            <Item xs={2}>{d.date ? formatPartialDate(d.date) : '-'}</Item>
            <Item xs={2}>{getCdrClass(d) ?? '-'}</Item>
            <Item xs={2}>{getCdrTotalScore(d) ?? '-'}</Item>
            <Item xs={2}>{getFtldCdrClass(d) ?? '-'}</Item>
            <Item xs={1}>{getFtldCdrTotalScore(d) ?? '-'}</Item>
            <Item xs={3}>
              <HistoryRowControls document={d} startEdit={startEdit} view={view} />
            </Item>
          </>
        )}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: ICDR[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default CDR;
