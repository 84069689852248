import * as React from 'react';
import { useIntl } from 'react-intl';
import { formatPartialDate, isPartialDate, partialDateToValue, sortPartialDate } from 'neuro-utils';

import FormSection from 'Components/FormSection';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import { getLatestTreatmentEndDate } from 'Routes/Notes/utils';
import { sortDocuments } from 'Utility/randomUtil';
import { omit } from 'Utility/ramdaReplacement';

import { omitControlProps } from 'Utility/documentHandling';
import { Container, Item } from 'Components/Grid';
import ActionButton from 'Components/ActionButton';
import ConditionalCollapse from 'Components/Collapse/ConditionalCollapse';

const NotesForm = ({ documents, formData, editing }: IOwnProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const thisDoc: INotes = documents.filter((d) => d._type === 'notes' && d._id === editing)[0];

  // All other notes docs
  const notesDocs = documents
    .filter((d) => d._type === 'notes' && d._id !== editing)
    ?.sort((n1, n2) => sortDocuments([{ type: 'date', sortField: 'date' }])(n1, n2));

  const isNewDoc = thisDoc?._commitsLength === 1;

  const [tdcsDeviceFixedTerm, setTdcsDeviceFixedTerm] = React.useState<boolean>(false);

  // Do not copy these fields, most of them are automatically generated from other data
  const ignoredFields = [
    'date',
    'symptomsDetails', // Removed from this form
    'neuroModulationActiveTreatment',
    'neuroModulationNextRMTDefinitionDate',
    'neuroModulationNextRMTDefinitionOrderDate',
    'neuroModulationNextInterview',
    'neuroModulationNextInterviewOrderDate',
    'neuroModulationTDCSTreatmentMonitoringFrequency',
    'neuroModulationTDCSTreatmentMonitoringFrequencyDate',
    'additionalDocuments',
    'additionalQuestionnaires',
    'neuroModulationRTMSIntensiveEnded',
    'neuroModulationRTMSUpkeepEnded',
    'neuroModulationTDCSIntensiveEnded',
    'neuroModulationTDCSUpkeepEnded',
  ];

  const [previousFieldsCopied, setPreviousFieldsCopied] = React.useState<boolean>(false);

  // Attempt to copy fields from previous document
  React.useEffect(() => {
    if (thisDoc) {
      if (thisDoc._commitsLength === 1 && !previousFieldsCopied) {
        const previousDoc = notesDocs[0]; // Latest previous notes doc
        if (!previousDoc) return;
        formData.onChange?.(omit(ignoredFields, omitControlProps<Record<string, any>>(previousDoc)));
        if (
          'tdcsDeviceLoanAgreementEndDate' in previousDoc &&
          isPartialDate(previousDoc.tdcsDeviceLoanAgreementEndDate)
        ) {
          setTdcsDeviceFixedTerm(true);
        }
        setPreviousFieldsCopied(true);
      } else {
        if (isPartialDate(thisDoc.tdcsDeviceLoanAgreementEndDate)) {
          setTdcsDeviceFixedTerm(true);
        }
      }
    }
  }, [thisDoc]);

  const editingEnabled = !!editing;

  const data = formData.document;

  // Get the latest treatment period date
  const treatmentPeriodDocs = (documents.filter((d) => d._type === 'ninmtTreatmentPeriod') ||
    []) as ININMTTreatmentPeriod[];

  const lastPeriodDate = treatmentPeriodDocs.sort((a, b) => sortPartialDate(b.date, a.date))[0]?.date || null;

  const activeRTMSDocs = documents.filter(
    (d: (typeof documents)[0]) =>
      d._type === 'rtms' &&
      sortPartialDate((d as IRTMS).date, lastPeriodDate ?? undefined) >= 0 &&
      sortPartialDate((d as IRTMS).date, data.date) <= 0 &&
      !(d as IRTMS).hasEnded,
  );

  const activeTDCSDocs = documents.filter(
    (d: (typeof documents)[0]) =>
      d._type === 'tdcs' &&
      sortPartialDate((d as ITDCS).date, lastPeriodDate ?? undefined) >= 0 &&
      sortPartialDate((d as IRTMS).date, data.date) <= 0 &&
      !(d as ITDCS).hasEnded,
  );

  const [latestRTMSEndDates, setLatestRTMSEndDates] = React.useState<
    { intense: PartialDate | null; upkeep: PartialDate | null } | null | 'noDocs'
  >(null);
  const [latestTDCSEndDates, setLatestTDCSEndDates] = React.useState<
    { intense: PartialDate | null; upkeep: PartialDate | null } | null | 'noDocs'
  >(null);
  const [dateUpdateCount, setDateUpdateCount] = React.useState<number>(0);

  React.useEffect(() => {
    setLatestRTMSEndDates(getLatestTreatmentEndDate(documents, 'rtms', lastPeriodDate, formData.document));
    setLatestTDCSEndDates(getLatestTreatmentEndDate(documents, 'tdcs', lastPeriodDate, formData.document));
    setDateUpdateCount(dateUpdateCount + 1);
  }, [formData.document.date]);

  React.useEffect(() => {
    if (formData && formData.onChange && isNewDoc) {
      if (latestRTMSEndDates && latestRTMSEndDates !== 'noDocs') {
        let update: 'intense' | 'upkeep' | undefined = undefined;
        if (latestRTMSEndDates.intense && latestRTMSEndDates.upkeep) {
          update = sortPartialDate(latestRTMSEndDates.intense, latestRTMSEndDates.upkeep) >= 0 ? 'intense' : 'upkeep';
        } else if (latestRTMSEndDates.intense) {
          update = 'intense';
        } else {
          update = 'upkeep';
        }

        if (update === 'intense' && (!formData.document.neuroModulationRTMSIntensiveEnded || dateUpdateCount > 1)) {
          formData.onChange({ ['neuroModulationRTMSIntensiveEnded']: latestRTMSEndDates.intense });
        }
        if (update === 'upkeep' && (!formData.document.neuroModulationRTMSUpkeepEnded || dateUpdateCount > 1)) {
          formData.onChange({ ['neuroModulationRTMSUpkeepEnded']: latestRTMSEndDates.upkeep });
        }
      } else if (latestRTMSEndDates === 'noDocs') {
        formData.onChange({ ['neuroModulationRTMSIntensiveEnded']: undefined });
        formData.onChange({ ['neuroModulationRTMSUpkeepEnded']: undefined });
      }
    }
  }, [thisDoc, latestRTMSEndDates]);

  React.useEffect(() => {
    if (formData && formData.onChange && isNewDoc) {
      if (latestTDCSEndDates && latestTDCSEndDates !== 'noDocs') {
        let update: 'intense' | 'upkeep' | undefined = undefined;
        if (latestTDCSEndDates.intense && latestTDCSEndDates.upkeep) {
          update = sortPartialDate(latestTDCSEndDates.intense, latestTDCSEndDates.upkeep) >= 0 ? 'intense' : 'upkeep';
        } else if (latestTDCSEndDates.intense) {
          update = 'intense';
        } else {
          update = 'upkeep';
        }

        if (update === 'intense' && (!formData.document.neuroModulationTDCSIntensiveEnded || dateUpdateCount > 1)) {
          formData.onChange({ ['neuroModulationTDCSIntensiveEnded']: latestTDCSEndDates.intense });
        }
        if (update === 'upkeep' && (!formData.document.neuroModulationTDCSUpkeepEnded || dateUpdateCount > 1)) {
          formData.onChange({ ['neuroModulationTDCSUpkeepEnded']: latestTDCSEndDates.upkeep });
        }
      } else if (latestTDCSEndDates === 'noDocs') {
        formData.onChange({ ['neuroModulationTDCSIntensiveEnded']: undefined });
        formData.onChange({ ['neuroModulationTDCSUpkeepEnded']: undefined });
      }
    }
  }, [thisDoc, latestRTMSEndDates]);

  /** Set active treatment ===> */
  const activeTreatment =
    activeRTMSDocs.length > 0 && activeTDCSDocs.length > 0
      ? 'both'
      : activeRTMSDocs.length > 0
        ? 'rtms'
        : activeTDCSDocs.length > 0
          ? 'tdcs'
          : 'none';

  React.useEffect(() => {
    if (formData?.onChange && isNewDoc) {
      formData.onChange?.({
        ['neuroModulationActiveTreatment']: activeTreatment,
      });
    }
  }, [thisDoc, activeTreatment]);
  /** <=== active treatment */

  /** Set next RMT definition ===> */
  const latestDoctorsOrdersWithNextRMT = (documents.filter((d) => d._type === 'doctorsOrders') as IDoctorsOrder[])
    .sort((a, b) => sortPartialDate(b.date, a.date))
    .find((d) => !!d.nextRMT && lastPeriodDate && partialDateToValue(d.date) >= partialDateToValue(lastPeriodDate));

  const [nextRMTSet, setRMTSet] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (formData?.onChange && isNewDoc && latestDoctorsOrdersWithNextRMT && !nextRMTSet) {
      if (latestDoctorsOrdersWithNextRMT.nextRMT && !thisDoc.neuroModulationNextRMTDefinitionDate) {
        formData.onChange?.({
          ['neuroModulationNextRMTDefinitionDate']: latestDoctorsOrdersWithNextRMT.nextRMT,
          ['neuroModulationNextRMTDefinitionOrderDate']: latestDoctorsOrdersWithNextRMT.date,
        });
        setRMTSet(true);
      }
    }
  }, [thisDoc, latestDoctorsOrdersWithNextRMT]);
  /** <=== next rmt */

  /** Set next interview fields automatically when creating a new notes document ===> */
  const latestDoctorsOrdersWithNextInterview = (documents.filter((d) => d._type === 'doctorsOrders') as IDoctorsOrder[])
    .sort((a, b) => sortPartialDate(b.date, a.date))
    .find(
      (d) => !!d.nextInterview && lastPeriodDate && partialDateToValue(d.date) >= partialDateToValue(lastPeriodDate),
    );

  const [nextInterviewSet, setNextInterviewSet] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (formData?.onChange && isNewDoc && latestDoctorsOrdersWithNextInterview && !nextInterviewSet) {
      if (latestDoctorsOrdersWithNextInterview.nextInterview && !thisDoc.neuroModulationNextInterview) {
        formData.onChange?.({
          ['neuroModulationNextInterview']: latestDoctorsOrdersWithNextInterview.nextInterview,
          ['neuroModulationNextInterviewOrderDate']: latestDoctorsOrdersWithNextInterview.date,
        });
        setNextInterviewSet(true);
      }
    }
  }, [thisDoc, latestDoctorsOrdersWithNextInterview]);
  /** <=== next interview */

  /** Set additional documents automatically when creating a new notes document ===> */
  const doctorsOrdersAdditional = documents.find((d) => d._type === 'doctorsOrdersAdditional') as
    | IDoctorsOrderAdditional
    | undefined;

  const [additionalDocsSet, setAdditionalDocsSet] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (formData?.onChange && isNewDoc && doctorsOrdersAdditional && !additionalDocsSet) {
      // Set possible additional documents if they dont exist
      if (doctorsOrdersAdditional.additionalDocuments && !thisDoc.additionalDocuments) {
        formData.onChange({
          ['additionalDocuments']: doctorsOrdersAdditional.additionalDocuments.map((ad) =>
            ad === 'other' ? doctorsOrdersAdditional.additionalDocumentsOther : ad,
          ),
        });
      }
      //Set possible questionnaires if they dont exist
      if (doctorsOrdersAdditional.additionalQuestionnaires && !thisDoc.additionalQuestionnaires) {
        formData.onChange({
          ['additionalQuestionnaires']: doctorsOrdersAdditional.additionalQuestionnaires.map((aq) =>
            aq === 'other' ? doctorsOrdersAdditional.additionalQuestionnairesOther : aq,
          ),
        });
      }
      setAdditionalDocsSet(true);
    }
  }, [thisDoc, doctorsOrdersAdditional]);
  /** <=== additional documents */

  /** Set TDCS treatment monitoring frequency automatically when creating a new notes document ===> */
  const latestDoctorsOrdersWithTdcsTreatmentMonitoringFrequency = (
    documents.filter((d) => d._type === 'doctorsOrders') as IDoctorsOrder[]
  )
    .sort((a, b) => sortPartialDate(b.date, a.date))
    .find(
      (d) =>
        d.tdcs &&
        typeof d.tdcs === 'object' &&
        d.tdcs.treatmentMonitoringFrequency &&
        lastPeriodDate &&
        partialDateToValue(d.date) >= partialDateToValue(lastPeriodDate),
    );

  const [tdcsTreatmentMonitoringFrequencySet, setTdcsTreatmentMonitoringFrequencySet] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (
      formData?.onChange &&
      isNewDoc &&
      ['tdcs', 'both'].includes(data.neuroModulationActiveTreatment ?? '') &&
      latestDoctorsOrdersWithTdcsTreatmentMonitoringFrequency &&
      !tdcsTreatmentMonitoringFrequencySet
    ) {
      const l = latestDoctorsOrdersWithTdcsTreatmentMonitoringFrequency;
      if (l.tdcs?.treatmentMonitoringFrequency && !thisDoc.neuroModulationTDCSTreatmentMonitoringFrequency) {
        formData.onChange?.({
          ['neuroModulationTDCSTreatmentMonitoringFrequency']: l.tdcs.treatmentMonitoringFrequency,
          ['neuroModulationTDCSTreatmentMonitoringFrequencyDate']: l.date,
        });
        setTdcsTreatmentMonitoringFrequencySet(true);
      }
    }
  }, [thisDoc, latestDoctorsOrdersWithTdcsTreatmentMonitoringFrequency]);
  /** <=== TDCS treatment monitoring frequency */

  const clearFollowUpPlanning = () => {
    formData?.onChange?.({
      followUpPlanning: undefined,
      neuroModulationPatientTransferredToOtherDevice: undefined,
      neuroModulationWhichOtherDevice: undefined,
      neuroModulationTransferDate: undefined,
      followUpPlanningDetails: undefined,
    });
  };

  return !isNewDoc || data.neuroModulationActiveTreatment ? (
    <React.Fragment>
      <FormSection>
        <FormRow title="general.date">
          <InputHandler editing={editingEnabled} name="date" type="PartialDate" formData={formData} dateDefault="now" />
        </FormRow>
      </FormSection>
      <FormSection header="notes.neuroModulationTreatment">
        <FormRow title="notes.neuroModulationActiveTreatment">
          <span style={{ fontWeight: 600 }}>
            {data.neuroModulationActiveTreatment
              ? formatMessage({ id: `notes.opts.${data.neuroModulationActiveTreatment}` })
              : '-'}
          </span>
        </FormRow>
        <FormRow title="notes.neuroModulationOtherDetails">
          <InputHandler
            editing={editingEnabled}
            name="neuroModulationOtherDetails"
            type="TextArea"
            formData={formData}
            placeholder="notes.neuroModulationOtherDetails"
          />
        </FormRow>
        <FormRow
          title="notes.neuroModulationNextRMTDefinitionDate"
          condition={
            ['rtms', 'both'].includes(data.neuroModulationActiveTreatment ?? '') ||
            isPartialDate(formData.document.neuroModulationNextRMTDefinitionDate)
          }
        >
          <span style={{ fontWeight: 600 }}>
            {formData.document.neuroModulationNextRMTDefinitionDate
              ? `${formatPartialDate(formData.document.neuroModulationNextRMTDefinitionDate)} ${formatMessage(
                  { id: 'notes.neuroModulationOrderDateString' },
                  {
                    date: formData.document.neuroModulationNextRMTDefinitionOrderDate
                      ? formatPartialDate(formData.document.neuroModulationNextRMTDefinitionOrderDate)
                      : '-',
                  },
                )}`
              : '-'}
          </span>
        </FormRow>
        <FormRow
          title="notes.neuroModulationNextRMTDetails"
          condition={
            ['rtms', 'both'].includes(data.neuroModulationActiveTreatment ?? '') ||
            !!formData.document.neuroModulationNextRMTDetails
          }
        >
          <InputHandler
            editing={editingEnabled}
            name="neuroModulationNextRMTDetails"
            type="TextArea"
            formData={formData}
            placeholder="notes.neuroModulationNextRMTDetails"
          />
        </FormRow>
        <FormRow title="notes.neuroModulationNextInterview">
          <InputHandler
            editing={false}
            name="neuroModulationOrderDateString"
            type="TextField"
            formData={{
              document: {
                neuroModulationOrderDateString: formData.document.neuroModulationNextInterview
                  ? formData.document.neuroModulationNextInterview +
                    ' ' +
                    formatMessage(
                      { id: 'notes.neuroModulationOrderDateString' },
                      {
                        date: formData.document.neuroModulationNextInterviewOrderDate
                          ? formatPartialDate(formData.document.neuroModulationNextInterviewOrderDate)
                          : '-',
                      },
                    )
                  : '-',
              },
            }}
          />
        </FormRow>
        <FormRow title="notes.neuroModulationNextInterviewDetails">
          <InputHandler
            editing={editingEnabled}
            name="neuroModulationNextInterviewDetails"
            type="TextArea"
            formData={formData}
            placeholder="notes.neuroModulationNextInterviewDetails"
          />
        </FormRow>
        <FormRow
          title="notes.neuroModulationTDCSTreatmentMonitoringFrequency"
          condition={
            ['tdcs', 'both'].includes(data.neuroModulationActiveTreatment ?? '') ||
            !!formData.document.neuroModulationTDCSTreatmentMonitoringFrequency
          }
        >
          <span style={{ fontWeight: 600 }}>
            {formData.document.neuroModulationTDCSTreatmentMonitoringFrequency
              ? formatMessage({
                  id: `doctorsOrders.tdcs.treatmentMonitoringFrequency.opts.${formData.document.neuroModulationTDCSTreatmentMonitoringFrequency}`,
                }) +
                ' ' +
                formatMessage(
                  {
                    id: 'notes.neuroModulationOrderDateString',
                  },
                  {
                    date: formData.document.neuroModulationTDCSTreatmentMonitoringFrequencyDate
                      ? formatPartialDate(formData.document.neuroModulationTDCSTreatmentMonitoringFrequencyDate)
                      : '-',
                  },
                )
              : '-'}
          </span>
        </FormRow>
        <FormRow
          title="notes.neuroModulationTDCSTreatmentMonitoringFrequencyDetails"
          condition={
            ['tdcs', 'both'].includes(data.neuroModulationActiveTreatment ?? '') ||
            !!formData.document.neuroModulationTDCSTreatmentMonitoringFrequencyDetails
          }
        >
          <InputHandler
            editing={editingEnabled}
            name="neuroModulationTDCSTreatmentMonitoringFrequencyDetails"
            type="TextArea"
            formData={formData}
            placeholder="notes.neuroModulationTDCSTreatmentMonitoringFrequencyDetails"
          />
        </FormRow>

        {data.neuroModulationRTMSIntensiveEnded && (
          <React.Fragment>
            <FormRow title="notes.neuroModulationRTMSIntensiveEnded">
              <InputHandler
                editing={false}
                name="neuroModulationRTMSIntensiveEnded"
                type="PartialDate"
                formData={{
                  onChange: () => '',
                  document: {
                    neuroModulationRTMSIntensiveEnded: formData.document.neuroModulationRTMSIntensiveEnded || '-',
                  },
                }}
              />
            </FormRow>
            <FormRow>
              <InputHandler
                editing={editingEnabled}
                name="neuroModulationRTMSIntensiveEndedDetails"
                type="TextArea"
                placeholder={'notes.neuroModulationRTMSIntensiveEndedDetailsPlaceholder'}
                formData={formData}
              />
            </FormRow>
          </React.Fragment>
        )}
        {data.neuroModulationRTMSUpkeepEnded && (
          <FormRow title="notes.neuroModulationRTMSUpkeepEnded">
            <InputHandler
              editing={false}
              name="neuroModulationRTMSUpkeepEnded"
              type="PartialDate"
              formData={{
                onChange: () => '',
                document: {
                  neuroModulationRTMSUpkeepEnded: formData.document.neuroModulationRTMSUpkeepEnded || '-',
                },
              }}
            />
          </FormRow>
        )}
        {data.neuroModulationTDCSIntensiveEnded && (
          <React.Fragment>
            <FormRow title="notes.neuroModulationTDCSIntensiveEnded">
              <InputHandler
                editing={false}
                name="neuroModulationTDCSIntensiveEnded"
                type="PartialDate"
                formData={{
                  onChange: () => '',
                  document: {
                    neuroModulationTDCSIntensiveEnded: formData.document.neuroModulationTDCSIntensiveEnded || '-',
                  },
                }}
              />
            </FormRow>
            <FormRow>
              <InputHandler
                editing={editingEnabled}
                name="neuroModulationTDCSIntensiveEndedDetails"
                type="TextArea"
                formData={formData}
                placeholder="notes.neuroModulationTDCSIntensiveEndedDetails"
              />
            </FormRow>
          </React.Fragment>
        )}
        {data.neuroModulationTDCSUpkeepEnded && (
          <FormRow title="notes.neuroModulationTDCSUpkeepEnded">
            <InputHandler
              editing={false}
              name="neuroModulationTDCSUpkeepEnded"
              type="PartialDate"
              formData={{
                onChange: () => '',
                document: {
                  neuroModulationTDCSUpkeepEnded: formData.document.neuroModulationTDCSUpkeepEnded || '-',
                },
              }}
            />
          </FormRow>
        )}
      </FormSection>

      <FormSection
        header="notes.tdcsDeviceHeader"
        condition={
          ['tdcs', 'both'].includes(data.neuroModulationActiveTreatment ?? '') ||
          !!(data.neuroModulationTDCSIntensiveEnded || data.neuroModulationTDCSUpkeepEnded)
        }
      >
        <ConditionalCollapse
          localeIDs={{ showMessage: 'notes.show', hideMessage: 'notes.hide' }}
          condition={!['tdcs', 'both'].includes(data.neuroModulationActiveTreatment ?? '')}
          amount={1}
        >
          <React.Fragment>
            <FormRow title="notes.tdcsDeviceIdentificationInfo">
              <InputHandler
                editing={editingEnabled}
                name="tdcsDeviceIdentificationInfo"
                type="TextArea"
                formData={formData}
                placeholder="notes.tdcsDeviceIdentificationInfo"
              />
            </FormRow>
            <FormRow title="notes.tdcsDeviceUsesAtTheTimeOfHandover">
              <InputHandler
                editing={editingEnabled}
                name="tdcsDeviceUsesAtTheTimeOfHandover"
                type="NumberField"
                formData={formData}
                width={10}
              />
            </FormRow>
            <FormRow title="notes.tdcsDeviceHandoverDate">
              <InputHandler
                editing={editingEnabled}
                name="tdcsDeviceHandoverDate"
                type="PartialDate"
                formData={formData}
              />
            </FormRow>
            <FormRow title="notes.tdcsDeviceFixedTermLoanAgreement">
              <InputHandler
                editing={editingEnabled}
                name="tdcsDeviceFixedTermLoanAgreement"
                type="CheckboxSingle"
                formData={{
                  onChange: () => {
                    if (tdcsDeviceFixedTerm) formData.onChange?.({ tdcsDeviceLoanAgreementEndDate: null });
                    setTdcsDeviceFixedTerm(!tdcsDeviceFixedTerm);
                  },
                  document: { tdcsDeviceFixedTermLoanAgreement: tdcsDeviceFixedTerm },
                }}
                option="true"
              />
            </FormRow>
            {tdcsDeviceFixedTerm && (
              <FormRow title="notes.tdcsDeviceLoanAgreementEndDate">
                <InputHandler
                  editing={editingEnabled}
                  name="tdcsDeviceLoanAgreementEndDate"
                  type="PartialDate"
                  formData={formData}
                />
              </FormRow>
            )}
            <FormRow title="notes.tdcsDeviceReturnDate">
              <InputHandler
                editing={editingEnabled}
                name="tdcsDeviceReturnDate"
                type="PartialDate"
                formData={formData}
              />
            </FormRow>
            <FormRow title="notes.tdcsDeviceCapHandoverDate">
              <InputHandler
                editing={editingEnabled}
                name="tdcsDeviceCapHandoverDate"
                type="PartialDate"
                formData={formData}
              />
            </FormRow>
            <FormRow title="notes.tdcsDeviceCapDetails">
              <InputHandler
                editing={editingEnabled}
                name="tdcsDeviceCapDetails"
                type="TextArea"
                formData={formData}
                placeholder="notes.tdcsDeviceCapDetails"
              />
            </FormRow>
            <FormRow title="notes.tdcsDeviceCablesHandoverDate">
              <InputHandler
                editing={editingEnabled}
                name="tdcsDeviceCablesHandoverDate"
                type="PartialDate"
                formData={formData}
              />
            </FormRow>
            <FormRow title="notes.tdcsDeviceElectrodesHandoverDate">
              <InputHandler
                editing={editingEnabled}
                name="tdcsDeviceElectrodesHandoverDate"
                type="PartialDate"
                formData={formData}
              />
            </FormRow>
          </React.Fragment>
        </ConditionalCollapse>
      </FormSection>

      <FormSection header="notes.additionalDocumentsHeader">
        <FormRow title="notes.additionalDocuments">
          <InputHandler
            editing={false}
            name="additionalDocuments"
            type="Checkbox"
            formData={{ document: { additionalDocuments: formData.document.additionalDocuments } }}
            optionFormatter={(option: string | number): string => {
              const o = option as 'bai' | 'gad-7' | 'other';
              if (o === 'bai' || o === 'gad-7')
                return formatMessage({ id: `doctorsOrders.additionalDocuments.documentsUsed.opts.${o}` });
              return o;
            }}
          />
        </FormRow>
        <FormRow title="notes.additionalQuestionnaires">
          <InputHandler
            editing={false}
            name="additionalQuestionnaires"
            type="Checkbox"
            formData={{ document: { additionalQuestionnaires: formData.document.additionalQuestionnaires } }}
            optionFormatter={(option: string | number): string => {
              const o = option as 'madrs' | 'gaf' | 'other';
              if (o === 'madrs' || o === 'gaf')
                return formatMessage({ id: `doctorsOrders.additionalDocuments.questionnairesUsed.opts.${o}` });
              return o;
            }}
          />
        </FormRow>
        <FormRow
          title="notes.additionalDocumentsDetails"
          condition={
            !!(formData.document.additionalDocuments || formData.document.additionalQuestionnaires) &&
            !!(
              (formData.document.additionalDocuments || []).length > 0 ||
              (formData.document.additionalQuestionnaires || []).length > 0
            )
          }
        >
          <InputHandler
            editing={editingEnabled}
            name="additionalDocumentsDetails"
            type="TextArea"
            placeholder={'notes.additionalDocumentsDetailsPlaceholder'}
            formData={formData}
          />
        </FormRow>
      </FormSection>

      <FormSection header="notes.patientsWishes">
        <FormRow title="notes.patientsWishes">
          <InputHandler
            editing={editingEnabled}
            name="patientsWishes"
            type="TextArea"
            formData={formData}
            placeholder="notes.patientsWishes"
          />
        </FormRow>
      </FormSection>

      <FormSection header="notes.proofsNotificationsPayersDetails">
        <FormRow title="notes.proofsNotificationsPayersDetails">
          <InputHandler
            editing={editingEnabled}
            name="proofsNotificationsPayersDetails"
            type="TextArea"
            formData={formData}
            placeholder="notes.proofsNotificationsPayersDetails"
          />
        </FormRow>
      </FormSection>

      <FormSection header="notes.followUpPlanning">
        <FormRow title="notes.followUpPlanning">
          <Container>
            <Item xs={true}>
              <InputHandler
                editing={editingEnabled}
                name="followUpPlanning"
                type="CheckboxSingle"
                formData={formData}
                option={'true'}
                optionFormatter={() => formatMessage({ id: 'general.yes' })}
              />
            </Item>
            <Item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div>
                <ActionButton
                  text="general.clear"
                  width={10}
                  height={3}
                  fontSize={14}
                  onClick={clearFollowUpPlanning}
                />
              </div>
            </Item>
          </Container>
        </FormRow>

        {!!data.followUpPlanning && (
          <React.Fragment>
            <FormRow title="notes.neuroModulationPatientTransferredToOtherDevice">
              <InputHandler
                editing={editingEnabled}
                name="neuroModulationPatientTransferredToOtherDevice"
                type="Radio"
                formData={formData}
                preset={'yesno'}
              />
            </FormRow>
            <FormRow
              title="notes.neuroModulationWhichOtherDevice"
              condition={!!data.neuroModulationPatientTransferredToOtherDevice}
            >
              <InputHandler
                editing={editingEnabled}
                name="neuroModulationWhichOtherDevice"
                type="Radio"
                formData={formData}
                options={['nexstim', 'axilum', 'dcStimulation']}
                optionFormatter={(o) => formatMessage({ id: 'notes.opts.' + o })}
              />
            </FormRow>
            <FormRow
              title="notes.neuroModulationTransferDate"
              condition={!!data.neuroModulationPatientTransferredToOtherDevice}
            >
              <InputHandler
                editing={editingEnabled}
                name="neuroModulationTransferDate"
                type="PartialDate"
                formData={formData}
              />
            </FormRow>
          </React.Fragment>
        )}

        <FormRow title="notes.followUpPlanningDetails" condition={!!data.followUpPlanning}>
          <InputHandler
            editing={editingEnabled}
            name="followUpPlanningDetails"
            type="TextArea"
            formData={formData}
            placeholder="notes.followUpPlanningDetails"
          />
        </FormRow>
      </FormSection>
    </React.Fragment>
  ) : null;
};

type TDocTypes =
  | INotes
  | IRTMS
  | ITDCS
  | (IDoctorsOrder & IDoctorsOrderOther)
  | ININMTTreatmentPeriod
  | IDoctorsOrderAdditional;

interface IOwnProps {
  documents: Array<TDocTypes>;
  formData: IFormData<INotes>;
  editing: string;
}

export default NotesForm;
