import { times } from 'ramda';

/**
 * Pads a number with leading 0's and fixed width
 * @param ln Length of padded number
 * @param n Number to pad
 */
const padNumber = (ln: number, n?: number): string => [...times(() => '0', ln), ...(n ? [n] : [])].join('').slice(-ln);

// Returns a SQL date formatted version of partial date.
// Currently has a workaround for months and dates as 00 is not a valid number.
// TODO: Fix this when we have a way to enforce that partial dates are completely filled.
const formatPartialDateToSQL = (date: PartialDate): string =>
  `${padNumber(4, date[0])}-${padNumber(2, date[1] ?? 1)}-${padNumber(2, date[2] ?? 1)}`;

export const formatDateToPartialDate = (date: string): PartialDate | null => {
  const dateArray = date?.split('-');
  return dateArray?.length > 0 ? (dateArray.map((d: string) => (d ? parseInt(d, 10) : null)) as PartialDate) : null;
};

export const convertPatientDataToUI = (data: IPatientAPI): IPatientUI => ({
  ...data,
  dateOfBirth: formatDateToPartialDate(data.dateOfBirth) ?? [1970, null, null],
  dateOfDeath: data.dateOfDeath ? formatDateToPartialDate(data.dateOfDeath) : null,
  inpatientHospitalDays:
    data.inpatientHospitalDays && typeof data.inpatientHospitalDays === 'string'
      ? parseInt(data.inpatientHospitalDays)
      : null,
  removeDate: data.removeDate ? formatDateToPartialDate(data.removeDate) : null,
});

type TOmittedFieldsFromPost =
  | keyof TExitusUI
  | 'id'
  | 'subjectId'
  | 'orgId'
  | 'removeDate'
  | 'dateOfBirth'
  | 'gender'
  | 'contactNotAllowed';

export interface IPatientDataForm
  extends Omit<IPatientAPI, TOmittedFieldsFromPost | 'address' | 'placeOfBirth' | 'countryOfBirth'> {
  address: IPatientAddress['address'];
  addressDetail: IPatientAddress['addressDetail'];
  city: IPatientAddress['city'];
  zipCode: IPatientAddress['zipCode'];
  country: IPatientAddress['country'];
}
interface IPatientDataPost extends Omit<IPatientAPI, TOmittedFieldsFromPost> {
  dateOfBirth: null;
  gender: null;
}

export const convertUIPatientDataToApiPostFormat = (data: IPatientDataForm): IPatientDataPost => ({
  ssn: data.ssn,
  ssnLocalization: data.ssnLocalization,
  firstNames: data.firstNames,
  lastNames: data.lastNames,

  address: {
    address: data.address || null,
    addressDetail: data.addressDetail || null,
    city: data.city || null,
    zipCode: data.zipCode || null,
    country: data.country || null,
    // No fields for these in the form
    protected: false,
    state: null,
  },

  // No fields for these in the form
  dateOfBirth: null,
  gender: null,
  placeOfBirth: null,
  countryOfBirth: null,
});

type TOmittedFieldsFromPatch =
  | keyof TExitusUI
  | 'id'
  | 'subjectId'
  | 'orgId'
  | 'ssn'
  | 'ssnLocalization'
  | 'removeDate';

export const convertUIPatientDataToApiPatchFormat = (
  data: Partial<IPatientUI> & { contactNotAllowed?: boolean | null },
): Omit<IPatientAPI, TOmittedFieldsFromPatch> => ({
  firstNames: data.firstNames || '',
  lastNames: data.lastNames || '',
  gender: data.gender || 'male',
  dateOfBirth: formatPartialDateToSQL(data?.dateOfBirth || [1970, null, null]),
  placeOfBirth: data.placeOfBirth || null,
  countryOfBirth: data.countryOfBirth || null,

  contactNotAllowed: data.contactNotAllowed,

  address: data.address || null,
});

export const convertUIExitusDataToApiPatchFormat = (data: TExitusUI): IExitusAPIPatch => ({
  isDead: data.isDead,
  dateOfDeath: data.dateOfDeath ? formatPartialDateToSQL(data?.dateOfDeath) : null,
  placeOfDeath: data.placeOfDeath || null,
  inpatientHospitalDays:
    data.inpatientHospitalDays || data.inpatientHospitalDays === 0 ? data.inpatientHospitalDays.toString() : null,

  reasonOfDeath: {
    reason: data.reasonOfDeath?.reason || null,
    reasonCode: data.reasonOfDeath?.reasonCode?.code
      ? {
          code: data.reasonOfDeath.reasonCode.code,
          codeSystem: 'icd10' as const,
        }
      : null,
    reasonOther: data.reasonOfDeath?.reasonOther || null,
    reasonDetail: data.reasonOfDeath?.reasonDetail || null,
  },
});

interface IExitusAPIPatch {
  isDead: boolean;
  dateOfDeath: string | null;
  placeOfDeath: string | null;
  inpatientHospitalDays: string | null;
  reasonOfDeath: {
    reason: string | null;
    reasonOther: string | null;
    reasonDetail: string | null;
    reasonCode: { code: string; codeSystem: 'icd10'; icd10description?: string } | null;
  };
}

export interface IDelegate {
  id: string;
  expired_at: number | null;
  first_names: string;
  last_names: string;
}
