import { exists } from 'neuro-utils';

export const mapResult = (value: number): number => {
  switch (value) {
    default:
    case 0:
      return 0;
    case 1:
      return 1;
    case 2:
    case 3:
      return 2;
    case 4:
    case 5:
      return 3;
    case 6:
      return 4;
  }
};

const visualCalculator = (visualFunctions: { [key: string]: any }): number | undefined => {
  const v = visualFunctions;

  const visualAcuityRight = exists(v?.visualAcuityRight) && v?.visualAcuityRight !== null ? v?.visualAcuityRight : 1.0;
  const visualAcuityLeft = exists(v?.visualAcuityLeft) && v?.visualAcuityLeft !== null ? v?.visualAcuityLeft : 1.0;

  const getGreaterValue = (value1: number | string | undefined, value2: number | string | undefined): number => {
    return Math.max(typeof value1 === 'number' ? value1 : 0, typeof value2 === 'number' ? value2 : 0);
  };
  const areBothEqual = (): boolean => {
    return visualAcuityLeft === visualAcuityRight;
  };
  const isLeftWorse = (): boolean => {
    return visualAcuityLeft < visualAcuityRight;
  };
  const acuityOfWorseEye = (): number => {
    return Math.min(visualAcuityRight, visualAcuityLeft);
  };
  const acuityOfBetterEye = (): number => {
    return Math.max(visualAcuityRight, visualAcuityLeft);
  };
  const acuityOfWorseEyeIn = (min: number, maxExclusive: number): boolean => {
    return acuityOfWorseEye() >= min && acuityOfWorseEye() < maxExclusive;
  };
  const acuityOfBetterEyeIn = (min: number, maxExclusive: number): boolean => {
    return acuityOfBetterEye() >= min && acuityOfBetterEye() < maxExclusive;
  };
  const scotomaOfWorseEye = (): number => {
    return areBothEqual()
      ? getGreaterValue(v?.scotomaLeft, v.scotomaRight)
      : isLeftWorse()
        ? v?.scotomaLeft
        : v?.scotomaRight;
  };
  const fieldOfWorseEye = (): number => {
    return areBothEqual()
      ? getGreaterValue(v?.visualFieldLeft, v?.visualFieldRight)
      : isLeftWorse()
        ? v?.visualFieldLeft
        : v?.visualFieldRight;
  };
  const disc = (): number => {
    return v?.discPallorLeft > 0 || v?.discPallorRight > 0 ? 1 : 0;
  };

  const isNormal = (value: number | 'notDone' | undefined): boolean => {
    return !value || (typeof value === 'number' && value === 0) || value === 'notDone';
  };

  const allNotDoneOrUndefined = (): boolean => {
    return Object.keys(v).every(
      (key) => !exists(v?.[key]) || v?.[key] === null || v?.[key] === 'notDone' || key === 'visionCorrection',
    );
  };

  let score = 0;

  if (allNotDoneOrUndefined()) {
    return undefined;
  }
  if (acuityOfWorseEye() >= 1.0 && isNormal(fieldOfWorseEye()) && isNormal(scotomaOfWorseEye()) && isNormal(disc())) {
    score = mapResult(0);
  }
  if (disc() > 0 || fieldOfWorseEye() > 0 || scotomaOfWorseEye() > 0 || acuityOfWorseEyeIn(0.67, 1.0)) {
    score = mapResult(1);
  }
  if (acuityOfWorseEyeIn(0.34, 0.67)) {
    score = mapResult(2);
  }
  if (scotomaOfWorseEye() > 1 || fieldOfWorseEye() > 1 || acuityOfWorseEyeIn(0.21, 0.34)) {
    score = mapResult(3);
  }
  if (
    fieldOfWorseEye() > 2 ||
    acuityOfWorseEyeIn(0.1, 0.21) ||
    (acuityOfWorseEyeIn(0.21, 0.34) && acuityOfBetterEyeIn(0.0, 0.34))
  ) {
    score = mapResult(4);
  }
  if (
    acuityOfWorseEyeIn(0.0, 0.1) ||
    ((fieldOfWorseEye() > 2 || acuityOfWorseEyeIn(0.1, 0.21)) && acuityOfBetterEyeIn(0.0, 0.34))
  ) {
    score = mapResult(5);
  }
  if (acuityOfWorseEyeIn(0.0, 0.1) && acuityOfBetterEyeIn(0.0, 0.34)) {
    score = mapResult(6);
  }
  return score;
};

export default visualCalculator;
