import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistoryRowBasicItem from '../../../../components/HistoryRowBasicItem';
import { Container, Item } from '../../../../components/Grid';

const fm = (id: string, values?: { [key: string]: string | number | null }): JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const OtherSymptomsHistoryRowData = ({ document }: IOwnProps): JSX.Element => (
  <Container alignItems="center">
    <Item xs={3}>
      <HistoryRowBasicItem
        header={fm('otherSymptoms.classification')}
        value={document?.classification ? fm(`otherSymptoms.${document.classification}.title`) : '-'}
      />
    </Item>
    <Item xs={5}>
      <HistoryRowBasicItem
        header={fm('otherSymptoms.symptoms')}
        value={
          document?.classification && document?.symptoms ? (
            <>
              {fm(`otherSymptoms.${document.classification}.opts.${document?.symptoms}`)}&nbsp;
              {document.symptoms === 'other' && document.freeText && <>({document.freeText})</>}
            </>
          ) : (
            '-'
          )
        }
      />
    </Item>
  </Container>
);

interface IOwnProps {
  document: IOtherSymptoms;
}

export default OtherSymptomsHistoryRowData;
