import * as React from 'react';
import { useIntl } from 'react-intl';
import FormSection from 'Components/FormSection';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import { diagnosticExamCodes } from 'Routes/ClpAndCnpTests/utils';

const DiagnosticExaminationForm = ({ formData, viewing, diagnosticExamKey }: IOwnProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  React.useEffect(() => {
    if (diagnosticExamKey && !viewing && !formData.document?.code) {
      formData.onChange?.({ code: diagnosticExamCodes[diagnosticExamKey] });
    }
  }, []);

  const onChangeType = (values: TOnChangeValues) => {
    let details = {};
    if (values['finding.type'] === 'normal') {
      // Clear abnormalDetails if normal type selected
      details = { abnormalDetails: null, supportDiagnosis: null };
    }
    formData.onChange?.({ finding: { type: values['finding.type'], ...details } });
  };

  return (
    <>
      <FormSection>
        <FormRow title="general.date">
          <InputHandler editing={!viewing} name="date" type="PartialDate" formData={formData} dateDefault="now" />
        </FormRow>
        <FormRow title="clpAndCnpTests.mgravis.findingType">
          <InputHandler
            name="finding.type"
            type="Radio"
            options={['normal', 'abnormal']}
            optionFormatter={(o) => fm(`clpAndCnpTests.mgravis.opts.${o}`)}
            formData={{ onChange: onChangeType, document: formData.document }}
            editing={!viewing}
          />
        </FormRow>
        <FormRow
          title="clpAndCnpTests.mgravis.abnormalDetails"
          condition={formData?.document?.finding?.type === 'abnormal'}
        >
          <InputHandler
            name="finding.abnormalDetails"
            type="TextArea"
            formData={formData}
            editing={!viewing}
            placeholder={'clpAndCnpTests.mgravis.abnormalDetails'}
            width={40}
          />
        </FormRow>
        <FormRow
          title="clpAndCnpTests.mgravis.supportDiagnosis"
          condition={formData?.document?.finding?.type === 'abnormal'}
        >
          <InputHandler
            name="finding.supportDiagnosis"
            type="CheckboxSingle"
            option={'true'}
            formData={formData}
            showLabel={false}
            editing={!viewing}
          />
        </FormRow>
      </FormSection>
    </>
  );
};

interface IOwnProps {
  formData: IFormData<IDiagnosticExamination>;
  viewing: boolean;
  diagnosticExamKey?: TDiagnosticExamKeys;
}

export default DiagnosticExaminationForm;
