import * as React from 'react';
import { TDocument } from '../../utils';
import MGCForm from './MGC';
import MGFAForm from './MGFA';

const FunctionalPerformanceForm = ({ formData, document, viewing = false }: IOwnProps): JSX.Element | null => {
  const { _type } = document ?? {};

  switch (_type) {
    case 'mgc':
      return <MGCForm formData={formData as IFormData<IMGC>} viewing={viewing} />;
    case 'mgfa':
      return <MGFAForm formData={formData as IFormData<IMGFA>} viewing={viewing} />;
    default:
      return null;
  }
};

interface IOwnProps {
  formData: IFormData<TDocument>;
  document?: TDocument;
  viewing?: boolean;
}

export default FunctionalPerformanceForm;
