import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Container, Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';
import { formatPartialDate } from 'neuro-utils';
import {
  uhdrsMotorDysarthriaScore,
  uhdrsMotorRetropulsionPullTestScore,
  uhdrsMotorScore,
  uhdrsMotorGaitScore,
  uhdrsMotorTandemWalkingScore,
} from '../../utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const UHDRS = ({ documents, startEdit, view }: IOwnProps): JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id="motorFunctionHuntington.uhdrs" />}
      newButton={
        <DocumentCreationButton
          name="uhdrsMotorRatingScale"
          text={'motorFunctionHuntington.newUHDRS'}
          onClick={startEdit({}, 'uhdrsMotorRatingScale')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={2}>
              <FormattedMessage id="general.date" />
            </Item>
            <Item xs={7}>
              <Container>
                <Item xs={3}>
                  <FormattedMessage id="motorFunctionHuntington.uhdrsScore" />
                </Item>
                <Item xs={9}>
                  <Container>
                    <Item xs={3}>
                      <FormattedMessage id="motorFunctionHuntington.uhdrsMotorDysarthria" />
                    </Item>
                    <Item xs={3} style={{ paddingRight: '2rem' }}>
                      <FormattedMessage id="motorFunctionHuntington.uhdrsMotorRetropulsionPullTest" />
                    </Item>
                    <Item xs={3}>
                      <FormattedMessage id="motorFunctionHuntington.uhdrsMotorGait" />
                    </Item>
                    <Item xs={3}>
                      <FormattedMessage id="motorFunctionHuntington.uhdrsMotorTandemWalking" />
                    </Item>
                  </Container>
                </Item>
              </Container>
            </Item>
          </>
        }
        contentFormat={(d: IUHDRSMotorRatingScale): JSX.Element => (
          <>
            <Item xs={2}>{formatPartialDate(d.date)}</Item>
            <Item xs={7}>
              <Container>
                <Item xs={3}>
                  {uhdrsMotorScore(d) === 'notCounted' || uhdrsMotorScore(d) === 'cantCount' ? '-' : uhdrsMotorScore(d)}
                </Item>
                <Item xs={9}>
                  <Container>
                    <Item xs={3}>
                      {uhdrsMotorDysarthriaScore(d) === 'notCounted' ? '-' : uhdrsMotorDysarthriaScore(d)}
                    </Item>
                    <Item xs={3} style={{ paddingRight: '2rem' }}>
                      {uhdrsMotorRetropulsionPullTestScore(d) === 'notCounted'
                        ? '-'
                        : uhdrsMotorRetropulsionPullTestScore(d)}
                    </Item>
                    <Item xs={3}>{uhdrsMotorGaitScore(d) === 'notCounted' ? '-' : uhdrsMotorGaitScore(d)}</Item>
                    <Item xs={3}>
                      {uhdrsMotorTandemWalkingScore(d) === 'notCounted' ? '-' : uhdrsMotorTandemWalkingScore(d)}
                    </Item>
                  </Container>
                </Item>
              </Container>
            </Item>
            <Item xs={3}>
              <HistoryRowControls document={d} startEdit={startEdit} view={view} />
            </Item>
          </>
        )}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IUHDRSMotorRatingScale[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default UHDRS;
