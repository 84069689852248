/**
 * Returns doc type of the currently edited document or undefined if no doc matches
 * @param documents Documents to check for
 * @param editingId ID of the currently edited document
 */
const getEditingDocType = (documents: Array<IControlProps>, editingId: string): string | undefined => {
  if (!Array.isArray(documents) || !editingId || documents.length <= 0) return undefined;

  const doc = documents.find((doc) => doc._id === editingId);
  if (!doc || !doc._type) return undefined;

  return doc._type;
};

export default getEditingDocType;
