import * as React from 'react';
import { equals } from 'ramda';
import { v4 } from 'uuid';
import TabContent from 'Components/TabContent';
import TabEditor from '../TabEditor';
import Treatment from '../Treatment';
import { sortPartialDate } from 'neuro-utils';
import ButtonRow from '../ButtonRow';
import { findPrecedingDoctorsOrder, getSessionNumber, subjectOfTreatmentTitle } from 'Utility/ninmtUtil';

const Treatments = ({
  editIndex,
  formData,
  fm,
  doctorsOrders,
}: {
  editIndex: number;
  formData: IFormData<IRTMS>;
  fm: (m: string) => string;
  doctorsOrders?: Array<IDoctorsOrder>;
}): JSX.Element => {
  const { document, onChange } = formData;

  // Ref for scrolling to top of treatments
  const ref = React.createRef() as React.RefObject<HTMLDivElement>;

  const elementInViewport = (el: HTMLDivElement): boolean => {
    const bb = el.getBoundingClientRect();
    const lowerBound = 80;
    return bb.top >= lowerBound && bb.top <= (window.innerHeight - lowerBound) / 3;
  };

  React.useEffect(() => {
    const newSessions = JSON.parse(JSON.stringify(document.sessions));
    if (newSessions?.[editIndex].date && !newSessions?.[editIndex].subjectOfTreatment) {
      newSessions[editIndex].subjectOfTreatment = [];
      onChange && onChange({ sessions: newSessions });
    }
  }, [document.sessions?.[editIndex].date]);

  const treatments =
    Array.isArray(document.sessions?.[editIndex].subjectOfTreatment) &&
    (document.sessions?.[editIndex]?.subjectOfTreatment ?? []).filter((s) => s).length > 0
      ? document.sessions?.[editIndex].subjectOfTreatment
      : [{ id: v4(), name: '' }];

  const treatmentOnChange =
    (index: number) =>
    (value: TOnChangeValues): void => {
      let newTreatments = JSON.parse(JSON.stringify(treatments));
      const newSessions = JSON.parse(JSON.stringify(document.sessions));
      // Check if there exists an treatment array with given index
      if (Array.isArray(treatments) && treatments.length >= index) {
        newTreatments[index] = Object.assign({}, newTreatments[index], value);
        newSessions[editIndex].subjectOfTreatment = newTreatments;
        onChange && onChange({ sessions: newSessions });
      } else {
        newTreatments = [Object.assign({ id: v4(), name: '' }, value)];
        newSessions[editIndex].subjectOfTreatment = newTreatments;
        // Treatment array does not exists
        onChange && onChange({ sessions: newSessions });
      }
    };

  const treatmentRemoveRestoreEvent =
    (restore?: true) =>
    (selectedTabIndex: number, changeSelectedTab?: (i: number) => () => void, deleteReason?: string) => {
      const thisSubjectOfTreatment = JSON.parse(JSON.stringify(treatments ?? []));
      const newSessions = JSON.parse(JSON.stringify(document.sessions));
      return () => {
        if (Array.isArray(thisSubjectOfTreatment) && thisSubjectOfTreatment.length >= selectedTabIndex) {
          thisSubjectOfTreatment[selectedTabIndex] = {
            ...thisSubjectOfTreatment[selectedTabIndex],
            deleted: restore ? false : true,
            deleteReason: restore ? null : deleteReason,
          };
          newSessions[editIndex].subjectOfTreatment = thisSubjectOfTreatment;
          if (onChange) {
            onChange({ sessions: newSessions });
          }
          if (changeSelectedTab && !thisSubjectOfTreatment?.[selectedTabIndex]) {
            const newIndex = thisSubjectOfTreatment ? thisSubjectOfTreatment.length - 1 : 0;
            changeSelectedTab(newIndex)();
          }
          if (!restore && ref.current && !elementInViewport(ref.current)) {
            scroll({ top: ref.current.offsetTop - 75, behavior: 'smooth' });
          }
        }
      };
    };

  const sessions = document?.sessions ?? [];
  let precedingDoctorsOrder: IDoctorsOrder | undefined = undefined;

  let sessionsSorted: typeof sessions = [];
  let currentIndex = 0;

  if (sessions) {
    sessionsSorted = sessions.slice().sort((s1, s2) => sortPartialDate(s2.date, s1.date));
    currentIndex = sessionsSorted.findIndex((s) => equals(sessions?.[editIndex ?? 0], s));
    precedingDoctorsOrder = findPrecedingDoctorsOrder('rtms', doctorsOrders ?? [], sessionsSorted, currentIndex);
  }

  const subOfTreatTabEditor = <TabEditor editIndex={editIndex} />;
  const subOfTreatButtonRow = (
    <ButtonRow
      editIndex={editIndex}
      remove={treatmentRemoveRestoreEvent()}
      restore={treatmentRemoveRestoreEvent(true)}
    />
  );

  return Array.isArray(treatments) ? (
    <div ref={ref}>
      <TabContent
        tabEditor={subOfTreatTabEditor}
        tabButtonRow={treatments.length > 1 ? subOfTreatButtonRow : undefined}
      >
        {treatments.map((s: IRTMSTreatment, i: number) => ({
          key: `${s.id}${i}`,
          id: `${s.id}Tab${i}`,
          title: subjectOfTreatmentTitle(s, 'rtms', fm),
          ended: s.deleted,
          content:
            // Treatments either have one item with a name or more than one item with/without a name
            (s.name && s.name.length > 0) || treatments.length > 1 ? (
              <Treatment
                document={s}
                onChange={treatmentOnChange(i)}
                session={document.sessions?.[editIndex]}
                sessionNumber={getSessionNumber(sessionsSorted ?? [], currentIndex, s)}
                precedingDoctorsOrder={precedingDoctorsOrder}
              />
            ) : (
              <div style={{ padding: '2rem 0 2rem 0' }}>{fm('rtms.subjectOfTreatment.copyDescription')}</div>
            ),
          count: i,
        }))}
      </TabContent>
    </div>
  ) : (
    <></>
  );
};

export default Treatments;
