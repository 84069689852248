import DocumentCreationButton from 'Components/DocumentCreationButton';
import { Item } from 'Components/Grid';
import HistoryRowControls from 'Components/HistoryRowControls';
import HistoryRowListing from 'Components/HistoryRowListing';
import HistorySection from 'Components/HistorySection';
import { diagnosticExamCodes } from 'Routes/DiagnosticTests/utils';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { useIntl } from 'react-intl';

const DiagnosticTestsHistory = ({ documents, startEdit, setDiagnosticExamKey }: IOwnProps) => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const neostigmineTestDocs = documents.filter((d) => d?.code?.code === diagnosticExamCodes?.['neostigmineTest']?.code);
  const iceCubeTestDocs = documents.filter((d) => d?.code?.code === diagnosticExamCodes?.['iceCubeTest']?.code);

  return (
    <>
      <HistorySection
        headerText={fm(`diagnosticTests.${diagnosticExamCodes.neostigmineTest?.code}`)}
        newButton={
          <DocumentCreationButton
            name="diagnosticExamination"
            text={'diagnosticTests.neostigmineTest.new'}
            onClick={(e) => {
              setDiagnosticExamKey?.('neostigmineTest');
              startEdit({} as IDiagnosticExamination, 'diagnosticExamination')(e);
            }}
            width={16}
            height={3}
            fontSize={14}
            alternate={true}
          />
        }
      >
        <HistoryRowListing
          documents={neostigmineTestDocs}
          headers={
            <>
              <Item xs={3}>{fm('general.date')}</Item>
              <Item xs={2}>{fm('diagnosticTests.interpretation')}</Item>
              <Item xs={7} />
            </>
          }
          contentFormat={(d: IDiagnosticExamination): JSX.Element => (
            <>
              <Item xs={3} style={{ fontWeight: 600 }}>
                {formatPartialDate(d?.date)}
              </Item>
              <Item xs={2} style={{ fontWeight: 600 }}>
                {d?.finding?.supportDiagnosis === true
                  ? fm('diagnosticTests.opts.support')
                  : d?.finding?.supportDiagnosis === false
                    ? fm('diagnosticTests.opts.noSupport')
                    : undefined}
              </Item>
              <Item xs={4}></Item>
              <Item xs={3}>
                <HistoryRowControls
                  document={d}
                  startEdit={(d) => (e) => {
                    setDiagnosticExamKey?.('neostigmineTest');
                    startEdit(d)(e);
                  }}
                  buttonDimensions={{ width: 16 }}
                />
              </Item>
            </>
          )}
        />
      </HistorySection>
      <HistorySection
        headerText={fm(`diagnosticTests.${diagnosticExamCodes.iceCubeTest?.code}`)}
        newButton={
          <DocumentCreationButton
            name="diagnosticExamination"
            text={'diagnosticTests.iceCubeTest.new'}
            onClick={(e) => {
              setDiagnosticExamKey?.('iceCubeTest');
              startEdit({} as IDiagnosticExamination, 'diagnosticExamination')(e);
            }}
            width={16}
            height={3}
            fontSize={14}
            alternate={true}
          />
        }
      >
        <HistoryRowListing
          documents={iceCubeTestDocs}
          headers={
            <>
              <Item xs={3}>{fm('general.date')}</Item>
              <Item xs={2}>{fm('diagnosticTests.interpretation')}</Item>
              <Item xs={7} />
            </>
          }
          contentFormat={(d: IDiagnosticExamination): JSX.Element => (
            <>
              <Item xs={3} style={{ fontWeight: 600 }}>
                {formatPartialDate(d?.date)}
              </Item>
              <Item xs={2} style={{ fontWeight: 600 }}>
                {d?.finding?.supportDiagnosis === true
                  ? fm('diagnosticTests.opts.support')
                  : d?.finding?.supportDiagnosis === false
                    ? fm('diagnosticTests.opts.noSupport')
                    : undefined}
              </Item>
              <Item xs={4}></Item>
              <Item xs={3}>
                <HistoryRowControls
                  document={d}
                  startEdit={(d) => (e) => {
                    setDiagnosticExamKey?.('iceCubeTest');
                    startEdit(d)(e);
                  }}
                  buttonDimensions={{ width: 16 }}
                />
              </Item>
            </>
          )}
        />
      </HistorySection>
    </>
  );
};

interface IOwnProps {
  documents: Array<IDiagnosticExamination>;
  startEdit: (document: IDiagnosticExamination, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
  setDiagnosticExamKey?: React.Dispatch<React.SetStateAction<TDiagnosticExamKeys | undefined>>;
}

export default DiagnosticTestsHistory;
