import DocumentCreationButton from 'Components/DocumentCreationButton';
import { Item } from 'Components/Grid';
import HistoryRowControls from 'Components/HistoryRowControls';
import HistoryRowListing from 'Components/HistoryRowListing';
import HistorySection from 'Components/HistorySection';
import { IHistoryContext, withHistoryContext } from 'Containers/FormContextHandler';
import { formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { madrsTotalScore } from 'Routes/InterviewSurveys/utils';

const getScoreDescriptionString = (score: number | 'notCounted') => {
  if (score === 'notCounted') return '';
  if (score >= 44) return '44+';
  if (score < 44 && score >= 31) return '31-43';
  if (score < 31 && score >= 25) return '25-30';
  if (score < 25 && score >= 15) return '15-24';
  if (score < 15 && score >= 8) return '8-14';
  return '0-7';
};

const MADRSHistory = ({ documents = [], startEdit, view }: IHistoryContext<IMADRS>): JSX.Element => (
  <HistorySection
    headerText={<FormattedMessage id="interviewSurveys.madrs.title" />}
    newButton={
      <DocumentCreationButton
        name="cmap"
        text={'interviewSurveys.madrs.newMadrs'}
        onClick={startEdit && startEdit({} as IMADRS, 'madrs')}
        width={15}
        height={3}
        fontSize={14}
        alternate={true}
      />
    }
  >
    <HistoryRowListing
      documents={documents?.filter((d) => d._type === 'madrs')}
      headers={
        <>
          <Item xs={2}>
            <FormattedMessage id="general.date" />
          </Item>
          <Item xs={2}>
            <FormattedMessage id="interviewSurveys.madrs.totalScore" />
          </Item>
        </>
      }
      contentFormat={(d: IGAF): JSX.Element => (
        <>
          <Item xs={2}>{formatPartialDate(d.date)}</Item>
          <Item xs={2}>{madrsTotalScore(d) === 'notCounted' ? '-' : madrsTotalScore(d)}</Item>
          <Item xs={5}>
            {madrsTotalScore(d) === 'notCounted' ? (
              ''
            ) : (
              <FormattedMessage
                id={`interviewSurveys.madrs.scoreDescriptions.${getScoreDescriptionString(madrsTotalScore(d))}`}
              />
            )}
          </Item>
          <Item xs={3}>{startEdit && <HistoryRowControls document={d} startEdit={startEdit} view={view} />}</Item>
        </>
      )}
    />
  </HistorySection>
);

export default withHistoryContext(MADRSHistory);
