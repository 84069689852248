import pyramidalCalculator from './Calculators/pyramidalCalculator';
import cerebellarCalculator from './Calculators/cerebellarCalculator';
import brainstemCalculator from './Calculators/brainstemCalculator';
import sensoryCalculator from './Calculators/sensoryCalculator';
import bowelAndBladderCalculator from './Calculators/bowelAndBladderCalculator';
import visualCalculator from './Calculators/visualCalculator';
import cerebralCalculator from './Calculators/cerebralCalculator';
import { filter } from '../utils';

const calculateScore = (subject: string, values: { [key: string]: any }): number | undefined => {
  const filteredValues =
    subject === 'pyramidalFunctions'
      ? Object.assign(
          {},
          {
            ambulatoryScore: values?.ambulatoryScore,
            pyramidalFunctions: filter(values?.pyramidalFunctions),
          },
        )
      : filter(values);

  switch (subject) {
    case 'pyramidalFunctions':
      return pyramidalCalculator(filteredValues);
    case 'cerebellarFunctions':
      return cerebellarCalculator(filteredValues);
    case 'brainstemFunctions':
      return brainstemCalculator(filteredValues);
    case 'sensoryFunctions':
      return sensoryCalculator(filteredValues);
    case 'bowelAndBladderFunctions':
      return bowelAndBladderCalculator(filteredValues);
    case 'visualAcuity':
      return visualCalculator(filteredValues);
    case 'cerebralFunctions':
      return cerebralCalculator(filteredValues);
    default:
      return undefined;
  }
};

export default calculateScore;
