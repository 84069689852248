import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import QuestionStepper from '../../../../../components/QuestionStepper';

import { steps as hineSteps, getStepContent as getHineSteps } from '../hineMMSettings';
import FormSection from '../../../../../components/FormSection';
import { getPatientAgeInMonthsWithOneDecimal } from '../../../../../utility/patientInfo';
import InfoPopover from '../../../../../components/InfoPopover';
import colors from '../../../../../config/theme/colors';
import { hineMMScore, isHineMMComplete, showPatientAgeInMonthsOnForm } from '../../../utils';
import { Container } from 'Components/Grid';
import SimpleList from 'Components/SimpleList';
import { getMissingFields } from 'Utility/documentHandling';
import { useIntl } from 'react-intl';

const HineMMForm = ({ formData, viewing }: IOwnProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });
  return (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="motor.ageMonth" condition={showPatientAgeInMonthsOnForm(formData.document.date)}>
        <InputHandler
          type="TextField"
          editing={false}
          name="age"
          formData={{
            onChange: formData.onChange,
            document: {
              age: formData.document.date ? getPatientAgeInMonthsWithOneDecimal(formData.document.date) : '',
            },
          }}
          disabled={true}
        />
      </FormRow>
      <FormSection>
        <QuestionStepper
          viewing={viewing}
          formData={formData}
          pageType={'motor'}
          docType={'hineMM'}
          steps={hineSteps}
          getStepContent={getHineSteps}
        />
      </FormSection>
      <FormSection>
        <FormRow title="motor.labels.hineMM.score">
          <Container style={{ fontSize: '1.8rem', fontWeight: 600, color: colors.primary }}>
            {isHineMMComplete(formData.document) ? (
              hineMMScore(formData.document)
            ) : (
              <InfoPopover
                text={
                  <SimpleList
                    title={fm('general.couldntCalculate')}
                    listItems={getMissingFields(
                      hineSteps.map((f) => fm(`motor.labels.hineMM.${f}`)),
                      formData.document,
                    )}
                  />
                }
                color="primary"
              />
            )}
          </Container>
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<IHINEMM>;
  viewing: boolean;
}

export default HineMMForm;
