import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistorySection from '../../../../components/HistorySection';

import { sortPartialDate } from 'neuro-utils';
import { mergeDocuments } from '../../utils';
import { rowData, TPulmonaryRowDataKey } from '../Form/Pulmonary/rowData';

import { History, TPulmonaryWithPreviousDates } from './Components/History';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const Pulmonary = ({ documents, startEdit }: IOwnProps): JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id={'pulmonary.airwayClearanceorSecretionMobilisation'} />}
      newButton={
        <DocumentCreationButton
          name="pulmonary"
          text={'pulmonary.newDocument'}
          onClick={startEdit({}, 'pulmonary')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
      hasHistoryRows={true}
    >
      <React.Fragment>
        {documents &&
          documents
            .sort((n1, n2) => n1._cdate - n2._cdate)
            .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
            .reverse()
            .map((d: IPulmonary, index) => {
              const historyDocument = d === documents[0] && documents.length > 1 ? mergeDocuments(documents) : d;
              return (
                <React.Fragment key={d._id}>
                  <History
                    d={historyDocument as TPulmonaryWithPreviousDates}
                    startEdit={startEdit}
                    rows={
                      Object.keys(rowData).filter((key) => {
                        return key !== 'assistanceInAirwayClearanceorSecretionMobilisation' &&
                          (d as { [key: string]: any })['assistanceInAirwayClearanceorSecretionMobilisation'] !== 'yes'
                          ? false
                          : key === 'ippvHoursPerDay' &&
                              (((d as { [key: string]: any })['ippv'] &&
                                (d as { [key: string]: any })['ippv'] !== 'daily') ||
                                !documents.find((d) => d && (d as { [key: string]: any })['ippv'] === 'daily'))
                            ? false
                            : key === 'otherAssistance' &&
                                (((d as { [key: string]: any })['other'] &&
                                  (d as { [key: string]: any })['other'] == 'no') ||
                                  !documents.find(
                                    (d) => d && ['daily', 'onDemand'].includes((d as { [key: string]: any })['other']),
                                  ))
                              ? false
                              : true;
                      }) as Array<TPulmonaryRowDataKey>
                    }
                    index={index}
                  />
                </React.Fragment>
              );
            })}
      </React.Fragment>
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IPulmonary[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default Pulmonary;
