import { isPartialDate, sortPartialDate } from 'neuro-utils';

/**
 * Get the ending date for 'rtms' | 'tdcs' docs
 * @param {IRTMS | ITDCS} document
 * @returns {PartialDate | null}
 */
const getEndDate = (document?: IRTMS | ITDCS): PartialDate | null => {
  if (isPartialDate(document?.endDate)) {
    return document?.endDate || null;
  }
  const allSessions = document?.sessions ? [...document.sessions] : [];
  const latestSession = allSessions.length > 0 ? allSessions.sort((a, b) => sortPartialDate(b.date, a.date))[0] : null;

  return latestSession?.date || null;
};

/**
 * Find the latest treatment end dates during current treatment period
 * @param {Array<INotes | IRTMS | ITDCS | (IDoctorsOrder & IDoctorsOrderOther) | ININMTTreatmentPeriod>} documents - all documents used in Notes section; treatments of selected type are filtered from this array
 * @param {'rtms' | 'tdcs'} type - type of treatment to look for which is 'rtms' or 'tdcs'
 * @param {PartialDate | null} lastPeriodDate - date of the current treatment period
 * @param {INotes} document optional - current Notes document, the date of the document is the point from where the previous end date is looked for
 * @returns {{ intense: PartialDate | null; upkeep: PartialDate | null } | null | 'noDocs'} - return upkeep and intense treatment end dates, or 'noDocs' if there is no treatments during current period
 */
export const getLatestTreatmentEndDate = (
  documents: Array<IRTMS | ITDCS | (IDoctorsOrder & IDoctorsOrderOther) | ININMTTreatmentPeriod>,
  type: 'rtms' | 'tdcs',
  lastPeriodDate: PartialDate | null,
  document?: INotes,
): { intense: PartialDate | null; upkeep: PartialDate | null } | null | 'noDocs' => {
  if (!lastPeriodDate) return null;

  const thisTypeDocuments = (documents.filter((d) => d._type === type) as Array<IRTMS | ITDCS>).sort((a, b) =>
    sortPartialDate(b.date, a.date),
  );
  const docsAfterDate = thisTypeDocuments.filter((d) => sortPartialDate(lastPeriodDate, d.date) <= 0);
  if (docsAfterDate.length === 0) return 'noDocs';

  const docsBeforeDocDate = document
    ? docsAfterDate.filter((d) => sortPartialDate(document.date, d.date) >= 0)
    : docsAfterDate;

  const intenseDate = getEndDate(docsBeforeDocDate.filter((da) => da.type === 'intense' && da.hasEnded)[0]);
  const upkeepDate = getEndDate(docsBeforeDocDate.filter((da) => da.type === 'upkeep' && da.hasEnded)[0]);

  if (!intenseDate && !upkeepDate) return 'noDocs';

  return { intense: intenseDate, upkeep: upkeepDate };
};
