import * as React from 'react';
import FormRow from '../../../../../../components/FormRow';
import FormSection from '../../../../../../components/FormSection';
import InputHandler from '../../../../../../components/InputHandler';
import PlatformCapabilities from '../../../../../../config/capabilities';
import { ICapabilityContextProps, withCapabilities } from '../../../../../../containers/CapabilityHandler';
import { IFormContext, withFormContext } from '../../../../../../containers/FormContextHandler';
import { optionFormatter } from '../../../../utils/functions';
import { assertCapabilities } from 'Store/index';

const MsSymptomsForm = ({ editing, formData, capabilityGroups = {} }: IMsSymptomsFormProps): JSX.Element => {
  let symptomsOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
  if (assertCapabilities([PlatformCapabilities.NMOSD_CAPABILITES], capabilityGroups))
    symptomsOptions = symptomsOptions.concat(['12', '13', '14', '15', '16']);
  return (
    <FormSection>
      <div style={{ marginBottom: '5rem' }} />
      <FormRow title="diagnosis.symptomOnsetDate">
        <InputHandler
          type="PartialDate"
          editing={!!editing}
          name="symptomOnsetDate"
          formData={formData}
          dateDefault="now"
        />
      </FormRow>
      <FormRow title="diagnosis.msSymptom.title">
        <InputHandler
          type="Checkbox"
          editing={!!editing}
          name="msSymptoms"
          formData={formData}
          options={symptomsOptions}
          optionFormatter={optionFormatter('msSymptom.opts')}
        />
      </FormRow>
      <FormRow title="diagnosis.msSymptomLevel.title">
        <InputHandler
          type="Checkbox"
          editing={!!editing}
          name="msSymptomLevel"
          formData={formData}
          options={['brainStem', 'cerebrum', 'opticNerve', 'cerebellum', 'spinalCord']}
          optionFormatter={optionFormatter('msSymptomLevel.opts')}
        />
      </FormRow>
      <FormRow title="diagnosis.msOriginOfInformation.title">
        <InputHandler
          type="Radio"
          editing={!!editing}
          name="msOriginOfInformation"
          formData={formData}
          options={['anamnesis', 'nurse', 'generalPractitioner', 'neurologist', 'specialist', 'ophtamologist']}
          optionFormatter={optionFormatter('msOriginOfInformation.opts')}
        />
      </FormRow>
      <FormRow title="diagnosis.msWasCortisoneGiven">
        <InputHandler
          type="Radio"
          editing={!!editing}
          name="msWasCortisoneGiven"
          formData={formData}
          options={['yes', 'no', 'unknown']}
          optionFormatter={optionFormatter('opts.yesNoUnknown')}
        />
      </FormRow>
      {formData?.document.msWasCortisoneGiven === 'yes' && (
        <FormRow title="diagnosis.msCortisoneMedicationRecorded">
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="msCortisoneMedicationRecorded"
            formData={formData}
            options={['yes', 'no', 'unknown']}
            optionFormatter={optionFormatter('opts.yesNoUnknown')}
          />
        </FormRow>
      )}
      <FormRow title="diagnosis.msWasPlasmaGiven">
        <InputHandler
          type="Radio"
          editing={!!editing}
          name="msWasPlasmaGiven"
          formData={formData}
          options={['yes', 'no', 'unknown']}
          optionFormatter={optionFormatter('opts.yesNoUnknown')}
        />
      </FormRow>
    </FormSection>
  );
};

interface IMsSymptomsFormProps extends IFormContext, ICapabilityContextProps {}

export default withCapabilities(withFormContext(MsSymptomsForm));
