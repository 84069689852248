const normalValues: { [key: string]: any } = {
  ambulatoryScore: '',
  pyramidalFunctions: {
    reflexes: {
      bicepsLeft: 2,
      bicepsRight: 2,
      tricepsLeft: 2,
      tricepsRight: 2,
      brachioradialisLeft: 2,
      brachioradialisRight: 2,
      patellaLeft: 2,
      patellaRight: 2,
      achillesLeft: 2,
      achillesRight: 2,
      babinskiLeft: 0,
      babinskiRight: 0,
      cutatenousLeft: 0,
      cutatenousRight: 0,
      palmomentalLeft: 0,
      palmomentalRight: 0,
    },
    limbStrength: {
      deltoidLeft: 5,
      deltoidRight: 5,
      bicepsLeft: 5,
      bicepsRight: 5,
      tricepsLeft: 5,
      tricepsRight: 5,
      wristFingerFlexorsLeft: 5,
      wristFingerFlexorsRight: 5,
      wristFingerExtensorsLeft: 5,
      wristFingerExtensorsRight: 5,
      hipFlexorsLeft: 5,
      hipFlexorsRight: 5,
      kneeFlexorsLeft: 5,
      kneeFlexorsRight: 5,
      kneeExtensorsLeft: 5,
      kneeExtensorsRight: 5,
      plantarFlexionLeft: 5,
      plantarFlexionRight: 5,
      dorsiflexionLeft: 5,
      dorsiflexionRight: 5,
    },
    functionalTests: {
      pronatorDriftUpperExtremitiesLeft: 0,
      pronatorDriftUpperExtremitiesRight: 0,
      positionTestUEDownwardDriftLeft: 0,
      positionTestUEDownwardDriftRight: 0,
      positionTestLESinkingLeft: 0,
      positionTestLESinkingRight: 0,
      ableToLiftOnlyOneLegAtATimeGradeLeft: 0,
      ableToLiftOnlyOneLegAtATimeGradeRight: 0,
      walkingOnHeelsLeft: 0,
      walkingOnHeelsRight: 0,
      walkingOnToesLeft: 0,
      walkingOnToesRight: 0,
      hoppingOnOneFootLeft: 0,
      hoppingOnOneFootRight: 0,
    },
    spasticity: {
      armsLeft: 0,
      armsRight: 0,
      legsLeft: 0,
      legsRight: 0,
      gaitLeft: 0,
      gaitRight: 0,
      overallMotorPerformance: 0,
    },
  },
  cerebellarFunctions: {
    headTremor: 0,
    truncalAtaxia: 0,
    tremor: {
      upperLimbTremorLeft: 0,
      upperLimbTremorRight: 0,
      lowerLimbTremorLeft: 0,
      lowerLimbTremorRight: 0,
    },
    rapidAlternatingMovements: {
      upperLimbRapidLeft: 0,
      upperLimbRapidRight: 0,
      lowerLimbRapidLeft: 0,
      lowerLimbRapidRight: 0,
    },
    tandemWalking: 0,
    gaitAtaxia: 0,
    rombergTest: 0,
    otherCerebellarTests: 0,
  },
  brainstemFunctions: {
    extraocularMovementsImpairment: 0,
    nystagmus: 0,
    trigeminalInjury: 0,
    facialWeakness: 0,
    hearingLoss: 0,
    dysarthria: 0,
    dysphagia: 0,
    otherCranialNerveFunctions: 0,
  },
  sensoryFunctions: {
    superficialSense: {
      upperExtremitiesLeft: 0,
      upperExtremitiesRight: 0,
      trunkLeft: 0,
      trunkRight: 0,
      lowerExtremitiesLeft: 0,
      lowerExtremitiesRight: 0,
    },
    vibrationSense: {
      upperExtremitiesLeft: 0,
      upperExtremitiesRight: 0,
      lowerExtremitiesLeft: 0,
      lowerExtremitiesRight: 0,
    },
    positionSense: {
      upperExtremitiesLeft: 0,
      upperExtremitiesRight: 0,
      lowerExtremitiesLeft: 0,
      lowerExtremitiesRight: 0,
    },
    lhermittesSign: 0,
    paresthesiae: 0,
  },
  bowelAndBladderFunctions: {
    urinaryRetention: 0,
    urinaryIncontinence: 0,
    urinaryCatheterization: 0,
    bowelDysfunction: 0,
  },
  visualAcuity: {
    visualAcuityLeft: 1.0,
    visualAcuityRight: 1.0,
    visualFieldLeft: 0,
    visualFieldRight: 0,
    scotomaLeft: 0,
    scotomaRight: 0,
    discPallorLeft: 0,
    discPallorRight: 0,
  },
  cerebralFunctions: {
    decreaseInMentation: 0,
    fatigue: 0,
  },
};

export default normalValues;
