import FormRow from 'Components/FormRow';
import FormSection from 'Components/FormSection';
import { Container, Item } from 'Components/Grid';
import InputHandler from 'Components/InputHandler';
import OptionChooser from 'Components/OptionChooser';
import { IFormContext, withFormContext } from 'Containers/FormContextHandler';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { gafClasses } from 'Routes/InterviewSurveys/utils';
import colors from '../../../../../config/theme/colors';

const GAF = ({ formData, view /*, documents*/ }: IFormContext<IGAF, any>): JSX.Element => {
  const [selectedGAF, setSelectedGAF] = React.useState<boolean | null>(null);
  // If gafClass is changed, set gafValue to undefined
  React.useEffect(() => {
    // On component mount if gaf has been selected, set state to avoid clearing the value
    if (!selectedGAF && formData.document.gafClass) {
      setSelectedGAF(true);
      return;
    }

    if (formData.document.gafClass && (formData.document.gafValue || formData.document.gafValue === 0)) {
      formData.onChange && formData.onChange({ gafValue: undefined });
    }
  }, [formData.document.gafClass]);

  return (
    <React.Fragment>
      <div style={{ paddingLeft: '1rem', fontStyle: 'italic', marginBottom: '2rem', color: colors.darkGray }}>
        <FormattedMessage id="interviewSurveys.gaf.instructions" values={{ br: <br /> }} />
      </div>
      <FormSection>
        <FormRow title="general.date">
          <InputHandler
            type="PartialDate"
            editing={!view?.viewing}
            name="date"
            formData={formData}
            dateDefault="now"
            isNotCancellable
          />
        </FormRow>
        <FormRow title="interviewSurveys.gaf.gafValue">
          <OptionChooser
            name="gafClass"
            formData={formData}
            opts={gafClasses}
            optionFormatter={(name: string | number): JSX.Element => (
              <Container alignItems={'center'}>
                <Item style={{ padding: '0 2rem', minWidth: '12rem' }}>
                  {formData.document.gafClass !== '0' && (
                    <InputHandler
                      type="NumberField"
                      editing={!view?.viewing && formData.document?.gafClass === name}
                      name="gafValue"
                      formData={{
                        onChange: formData.onChange,
                        document:
                          formData.document.gafClass === name
                            ? formData.document
                            : { ...formData.document, gafValue: undefined },
                      }}
                      precision={0}
                      width={8}
                      placeholder={`${name}`}
                      disablePlaceholderFormatting
                    />
                  )}
                </Item>
                <Item xs={true}>
                  <FormattedMessage id={`interviewSurveys.opts.gaf.${name}`} />
                </Item>
              </Container>
            )}
          />
        </FormRow>
      </FormSection>
      <FormSection>
        <div style={{ paddingLeft: '1rem', fontStyle: 'italic', color: colors.darkGray }}>
          American Psychiatric Association: Diagnostic and Statistical Manual of Mental Disorders, Fourth Edition.
          Washington, DC, American Psychiatric Association, 1994.
        </div>
      </FormSection>
    </React.Fragment>
  );
};

export default withFormContext(GAF);
