import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistoryRowBasicItem from '../../../../components/HistoryRowBasicItem';
import { Container, Item } from '../../../../components/Grid';

const fm = (id: string, values?: { [key: string]: string | number | null }): JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const ContraceptionHistoryRowData = ({ d }: IOwnProps): JSX.Element => (
  <Container alignItems="flex-start">
    <Item xs={2}>
      <HistoryRowBasicItem
        header={fm('comorbidity.classification')}
        value={
          d?.contraceptiveClassification
            ? fm(`comorbidity.opts.contraceptiveClassification.${d.contraceptiveClassification}`)
            : '-'
        }
      />
    </Item>
    <Item xs={7}>
      {d?.contraceptiveClassification === 'noContraception' && (
        <HistoryRowBasicItem header={fm('comorbidity.cause')} value={d.cause ?? '-'} />
      )}
      {d?.contraceptiveClassification !== 'noContraception' && (
        <HistoryRowBasicItem
          header={fm('comorbidity.methodOfContraception')}
          value={d.contraceptive ? fm(`comorbidity.opts.contraceptive.${d.contraceptive}`) : '-'}
        />
      )}
    </Item>
  </Container>
);

interface IOwnProps {
  d: IContraception;
}

export default ContraceptionHistoryRowData;
