// Define which fields to pick for shared form (for My documents)
export const sharedRowData: Partial<{
  [key in Platform]: { [key: string]: string[] };
}> = {
  sleepApnea: {
    drivingHealth: ['driversLicense', 'annualDrivingKM', 'driversLicenseGroup', 'professionalDriving'],
    smoking: ['smoker', 'smokingStartYear', 'cigaretteAmountPerDay', 'smokingEndYear'],
    snus: ['snus', 'snusStart', 'snusPortions', 'snusEnd'],
    intoxicantUsage: ['averageWeeklyAlcohol', 'otherIntoxicants', 'whichIntoxicants'],
    employment: ['employment'],
  },
  parkinson: {
    basicInformation: [
      'familyPrevalenceParkinson',
      'familyPrevalenceDisease',
      'familyMembersParkinson',
      'siblingDiagnosisAge',
      'maritalStatus',
      'typeOfLiving',
      'educationYears',
      'employment',
      'occupation',
      'partTimeHours',
      'sickLeaveDays',
      'unemploymentDays',
      'retirementType',
    ],
    drivingHealth: ['driversLicense', 'driversLicenseGroup'],
    earlierHeadInjury: ['earlierHeadInjury', 'earlierHeadInjuryYear'],
    powerOfAttorney: ['powerOfAttorney', 'powerOfAttorneyStart'],
    careAllowance: ['careAllowance', 'careAllowanceClass'],
    firearmsLicense: ['firearmsLicense'],
  },
};
