import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';
import { formatPartialDate } from 'neuro-utils';
import { countFunctionalCapacityScore, isFunctionalCapacityComplete } from '../../utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const UHDRSFunctionalCapacity = ({ documents, startEdit, view }: IOwnProps): JSX.Element => (
  <HistorySection
    headerText={<FormattedMessage id="functionalPerformance.uhdrsFunctionalCapacity" />}
    newButton={
      <DocumentCreationButton
        name="uhdrsFunctionalCapacity"
        text="functionalPerformance.newUhdrsFunctionalCapacity"
        onClick={startEdit({}, 'uhdrsFunctionalCapacity')}
        width={20}
        height={3}
        fontSize={14}
        alternate={true}
      />
    }
  >
    <HistoryRowListing
      documents={documents}
      headers={
        <>
          <Item xs={2}>
            <FormattedMessage id="general.date" />
          </Item>
          <Item xs={2}>
            <FormattedMessage id="functionalPerformance.labels.uhdrsFunctionalCapacity.score" />
          </Item>
          <Item xs={8}>&nbsp;</Item>
        </>
      }
      contentFormat={(d: IUHDRSFunctionalCapacity): JSX.Element => (
        <>
          <Item xs={2}>{formatPartialDate(d.date ?? undefined)}</Item>
          <Item xs={2}>{isFunctionalCapacityComplete(d) ? countFunctionalCapacityScore(d) : '-'}</Item>
          <Item xs={5}>&nbsp;</Item>
          <Item xs={3}>
            <HistoryRowControls document={d} startEdit={startEdit} view={view} />
          </Item>
        </>
      )}
    />
  </HistorySection>
);

interface IOwnProps {
  documents: Array<IUHDRSFunctionalCapacity>;
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default UHDRSFunctionalCapacity;
