import * as React from 'react';
import { useIntl } from 'react-intl';

import FormSection from 'Components/FormSection';

import FormRow from 'Components/FormRow';
import ActionButton from 'Components/ActionButton';
import GrantItem from './GrantItem';
import { actions } from 'Store/patient/actions';
import { Container, Item } from 'Components/Grid';
import { useAppDispatch, useAppSelector } from 'Store/index';

const Grants = () => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const userOrg = useAppSelector((s: IState) => s.session.data?.orgid);

  const usableOrgs = (useAppSelector((s: IState) => s.session.orgGroupArray) || []).filter((o) => o.orgId !== userOrg);

  const dispatch = useAppDispatch();

  const thisOrg = useAppSelector((s: IState) => s.session.data?.orgid);
  const grants = useAppSelector((s: IState) => s.patient.grants) || [];

  const receivedGrants = grants.filter((g) => g.granteeOrg === thisOrg);
  const givenGrants = grants.filter((g) => g.grantorOrg === thisOrg);

  const [editingGrant, setEditingGrant] = React.useState<IGrantFormData | null>(null);

  const isNewGrant = editingGrant && !givenGrants.find((g) => g.id === editingGrant.id);

  const onChange = (values: TAnyObject) => {
    setEditingGrant({ ...editingGrant, ...values } as IGrantFormData);
  };

  const onSave = () => {
    if (isNewGrant && editingGrant.granteeOrg) {
      actions.createNewGrant(editingGrant.granteeOrg, ['dbs', 'vns', 'surgicalTreatment'], ['*'])(dispatch);
    } else if (editingGrant?.granteeGroup && editingGrant.granteeOrg) {
      actions.updateExistingGrant(editingGrant.id, {
        granteeGroup: editingGrant.granteeGroup,
        granteeOrg: editingGrant.granteeOrg,
        allow_write: editingGrant.allow_write,
        allow_read: ['*'],
      })(dispatch);
    }
    setEditingGrant(null);
  };
  const onCancel = () => {
    setEditingGrant(null);
  };

  const setEditing = (id: string) => () => {
    const grant = givenGrants.find((g) => g.id === id);
    if (grant) {
      setEditingGrant({
        granteeGroup: grant.granteeGroup,
        granteeOrg: grant.granteeOrg,
        id: grant.id,
        allow_write: grant.allow_write,
        allow_read: grant.allow_read,
      });
    }
  };

  const onDelete = (id: string) => {
    if (id) {
      actions.deleteExistingGrant(id)(dispatch);
    }
  };

  const omittedOrgs = givenGrants.map((o) => o.granteeOrg);

  return (
    <>
      <FormSection header={'grants.receivedGrants'} style={{ borderTop: '0', paddingTop: '0' }}>
        {receivedGrants.length > 0
          ? receivedGrants.map((g) => (
              <Container key={g.id}>
                <Item style={{ marginBottom: '1rem', fontWeight: 600 }}>
                  {usableOrgs.find((o) => g.grantorOrg === o.orgId)?.displayName || g.grantorOrg}
                </Item>
              </Container>
            ))
          : fm('grants.noReceivedGrants')}
      </FormSection>
      <FormSection header={'grants.givenGrants'}>
        <FormRow title={'grants.addNewGrant'}>
          {!isNewGrant ? (
            <ActionButton
              text={'grants.newGrant'}
              onClick={() => setEditingGrant({ id: 'new' })}
              width={20}
              height={5}
              fontSize={18}
            />
          ) : (
            <GrantItem
              save={onSave}
              cancel={onCancel}
              onChange={onChange}
              setEditing={setEditing}
              fm={fm}
              editingGrant={editingGrant}
              omittedOrgs={omittedOrgs}
              organizations={usableOrgs}
            />
          )}
        </FormRow>
        <FormRow title={'grants.givenGrants'}>
          {Array.isArray(givenGrants) && givenGrants.length > 0
            ? givenGrants.map((g) => (
                <GrantItem
                  key={g.id}
                  save={onSave}
                  cancel={onCancel}
                  onChange={onChange}
                  setEditing={setEditing}
                  fm={fm}
                  thisGrant={g}
                  editingGrant={editingGrant}
                  onDelete={onDelete}
                  organizations={usableOrgs}
                />
              ))
            : fm('grants.noGivenGrants')}
        </FormRow>
      </FormSection>
    </>
  );
};

export interface IGrantFormData {
  id: string;
  granteeGroup?: TNeuroGrant['granteeGroup'];
  granteeOrg?: TNeuroGrant['granteeOrg'];
  allow_write?: TNeuroGrant['allow_write'];
  allow_read?: TNeuroGrant['allow_read'];
}

export default Grants;
