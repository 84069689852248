import * as React from 'react';
import HistoryRowVerticalItem from '../../../../../components/HistoryRowVerticalItem';
import { IHistoryContext, withHistoryContext } from '../../../../../containers/FormContextHandler';
import { formatPartialDate } from 'neuro-utils';
import { StyledBarHeader, StyledHeader, StyledRowContainerBar } from '../../../utils/styled';
import { Item } from 'Components/Grid';

const SMAhistory = ({ document, fm = (): string => '' }: IOwnProps): JSX.Element => {
  return (
    <div style={{ display: 'block', width: '100%' }}>
      {(document.smaType || document.smaGeneticConfirmation !== undefined) && (
        <React.Fragment>
          <StyledHeader>{fm('diagnosis.smaTypeAndGeneticConfirmation')}</StyledHeader>
          {!!document.smaType && (
            <>
              <span id={'smaSubType'} />
              <HistoryRowVerticalItem header={fm('diagnosis.smaType')} value={document.smaType} />
            </>
          )}
          {document.smaGeneticConfirmation !== undefined && (
            <>
              <span id={'geneticConfirmation'} />
              <HistoryRowVerticalItem
                header={fm('diagnosis.smaGeneticConfirmation')}
                value={
                  document.smaGeneticConfirmation === true
                    ? fm('diagnosis.opts.yesNo.yes')
                    : document.smaGeneticConfirmation === false
                      ? fm('diagnosis.opts.yesNo.no')
                      : undefined
                }
              />
            </>
          )}
        </React.Fragment>
      )}
      {document.smaGeneticConfirmation && (
        <React.Fragment>
          <StyledRowContainerBar alignItems="center" style={{ marginBottom: '1rem' }}>
            <Item xs={12}>
              <StyledBarHeader>{fm('diagnosis.smaGeneticConfirmationTitle')}</StyledBarHeader>
            </Item>
          </StyledRowContainerBar>
          {document.smaGeneticScreened !== undefined && (
            <>
              <span id={'geneticScreened'} />
              <HistoryRowVerticalItem
                header={fm('diagnosis.smaGeneticScreened')}
                value={document.smaGeneticScreened ? fm('diagnosis.opts.yesNo.yes') : fm('diagnosis.opts.yesNo.no')}
              />
            </>
          )}
          {!!document.smaGeneticDate && (
            <>
              <span id={'geneticDate'} />
              <HistoryRowVerticalItem
                header={fm('diagnosis.smaGeneticDate')}
                value={
                  document.smaGeneticDate && document.smaGeneticDate[0]
                    ? formatPartialDate(document.smaGeneticDate)
                    : '-'
                }
              />
            </>
          )}
          {!!document.smaSMN1MutationName && (
            <>
              <span id={'smn1Mutation'} />
              <HistoryRowVerticalItem
                header={fm('diagnosis.smaSMN1MutationName.title')}
                value={fm('diagnosis.smaSMN1MutationName.opts.' + document.smaSMN1MutationName)}
              />
            </>
          )}
          {!!document.smaSMN1TestingMethod && (
            <>
              <span id={'smn1TestingMethod'} />
              <HistoryRowVerticalItem
                header={fm('diagnosis.smaSMN1TestingMethod.title')}
                value={fm('diagnosis.smaSMN1TestingMethod.opts.' + document.smaSMN1TestingMethod)}
              />
            </>
          )}
          {document.smaSMN1TestingMethod === 'Other' && !!document.smaSMN1TestingMethodOther && (
            <HistoryRowVerticalItem
              header={fm('diagnosis.smaTestingMethodOther')}
              value={document.smaSMN1TestingMethodOther}
            />
          )}
          {document.smaSMN2CopyNumberTested !== undefined && (
            <>
              <span id={'smn2CopyNumberTested'} />
              <HistoryRowVerticalItem
                header={fm('diagnosis.smaSMN2CopyNumberTested')}
                value={
                  document.smaSMN2CopyNumberTested === true
                    ? fm('diagnosis.opts.yesNo.yes')
                    : fm('diagnosis.opts.yesNo.no')
                }
              />
            </>
          )}
          {document.smaSMN2CopyNumberTested === true && (
            <React.Fragment>
              {!!document.smaSMN2TestingMethod && (
                <>
                  <span id={'smn2TestingMethod'} />
                  <HistoryRowVerticalItem
                    header={fm('diagnosis.smaSMN2TestingMethod.title')}
                    value={fm('diagnosis.smaSMN2TestingMethod.opts.' + document.smaSMN2TestingMethod)}
                  />
                </>
              )}
              {document.smaSMN2TestingMethod === 'Other' && !!document.smaSMN2TestingMethodOther && (
                <HistoryRowVerticalItem
                  header={fm('diagnosis.smaTestingMethodOther')}
                  value={document.smaSMN2TestingMethodOther}
                />
              )}
              {!!document.smaSMN2CopyNumber && (
                <>
                  <span id={'smn2CopyNumber'} />
                  <HistoryRowVerticalItem
                    header={fm('diagnosis.smaSMN2CopyNumber')}
                    value={document.smaSMN2CopyNumber}
                  />
                </>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

interface IOwnProps extends IHistoryContext {
  document: IDiagnosis;
}

export default withHistoryContext(SMAhistory);
