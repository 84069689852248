import * as React from 'react';

import CollapseElem from '../../../../../components/Collapse';

export const ConditionalCollapse = ({
  condition,
  children,
  rows,
}: {
  condition: boolean;
  children: JSX.Element;
  rows: string[];
}): JSX.Element =>
  condition ? (
    <CollapseElem localeIDs={{ showMessage: 'pulmonary.show', hideMessage: 'pulmonary.hide' }} amount={rows.length}>
      <div style={{ marginBottom: '1rem' }}>{children}</div>
    </CollapseElem>
  ) : (
    children
  );
