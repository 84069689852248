import { path } from 'ramda';
import * as React from 'react';
import { OperationContext } from '../../config';
import EventStepper from '../../../../../components/EventStepper';
import { StepperHeaderValuePair } from '../../../../../components/EventStepper/components';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import { isNil } from 'Utility/ramdaReplacement';

const optionFormatter = (s: string | number, p: string, fm?: (id: string) => string): string =>
  fm ? fm(`surgicalTreatment.thermoCoagulation.${p}.${s}`) : s.toString();

const stepLabelText = (d: ICoagulation, fm?: (id: string) => string): string =>
  fm
    ? (d.coagulationArea
        ? fm(`surgicalTreatment.thermoCoagulation.coagulations.coagulationArea.opts.${d.coagulationArea}`) +
          (d.side ? ', ' : '')
        : '') + (d.side ? fm(`surgicalTreatment.thermoCoagulation.coagulations.side.opts.${d.side}`) : '')
    : '';

const StepContent = ({ d, fm }: { d: ICoagulation; fm?: (id: string) => string }): JSX.Element => {
  return (
    <React.Fragment>
      {['coagulationArea', 'side', 'seegLocation', 'stimulationAssist'].map((prop: string) => {
        const value: string | undefined = path([prop], d);
        return (
          <React.Fragment key={prop ?? ''}>
            {value && (
              <StepperHeaderValuePair
                header={<>{fm ? fm(`surgicalTreatment.thermoCoagulation.coagulations.${prop}.title`) : ''}</>}
                value={fm ? fm(`surgicalTreatment.thermoCoagulation.coagulations.${prop}.opts.${value}`) : '-'}
              />
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const EditingElement = ({
  formData,
  editing,
  fm,
}: {
  formData: IFormData<ICoagulation>;
  editing: boolean;
  fm?: (id: string) => string;
}): JSX.Element => {
  return (
    <React.Fragment>
      <FormRow title="surgicalTreatment.thermoCoagulation.coagulations.coagulationArea.title">
        <InputHandler
          type="Select"
          name="coagulationArea"
          editing={editing}
          formData={formData}
          options={['temporalBlock', 'frontalBlock', 'crownBlock', 'backOfSkullBlock', 'other']}
          optionFormatter={(id: string | number): string =>
            optionFormatter(id, 'coagulations.coagulationArea.opts', fm)
          }
          placeholder="surgicalTreatment.thermoCoagulation.coagulations.coagulationArea.placeholder"
        />
      </FormRow>
      <FormRow title="surgicalTreatment.thermoCoagulation.coagulations.side.title">
        <InputHandler
          type="Radio"
          name="side"
          editing={editing}
          formData={formData}
          options={['left', 'right']}
          optionFormatter={(id: string | number): string => optionFormatter(id, 'coagulations.side.opts', fm)}
          placeholder="surgicalTreatment.thermoCoagulation.coagulations.side.placeholder"
        />
      </FormRow>
      <FormRow title="surgicalTreatment.thermoCoagulation.coagulations.seegLocation.title">
        <InputHandler
          type="Radio"
          name="seegLocation"
          editing={editing}
          formData={formData}
          options={['yes', 'no']}
          optionFormatter={(id: string | number): string => optionFormatter(id, 'coagulations.seegLocation.opts', fm)}
        />
      </FormRow>
      <FormRow title="surgicalTreatment.thermoCoagulation.coagulations.stimulationAssist.title">
        <InputHandler
          type="Radio"
          name="stimulationAssist"
          editing={editing}
          formData={formData}
          options={['yes', 'no']}
          optionFormatter={(id: string | number): string =>
            optionFormatter(id, 'coagulations.stimulationAssist.opts', fm)
          }
        />
      </FormRow>
    </React.Fragment>
  );
};

const Thermocoagulation = ({ formData, editing, view, fm }: IFormContext): JSX.Element => {
  const editingDoc = !!editing && !view?.viewing;
  const { document, onChange } = formData as IFormData<ISurgicalTreatment>;

  return (
    <React.Fragment>
      <OperationContext.Consumer>
        {({ operationIndex }): JSX.Element => {
          const operations = document.operations?.map((o) => o) || [];
          const thermocoagulation = operations?.[operationIndex ?? 0] as IThermocoagulation;
          const coagulations = thermocoagulation.coagulations;

          const thermocoagulationOnChange = (values: TOnChangeValues): void => {
            const value = values['coagulations'];
            const newCoagulations = value;
            const changedOperation = { ...thermocoagulation, coagulations: newCoagulations };
            operations[operationIndex ?? 0] = changedOperation;
            onChange && onChange({ operations: operations });
          };

          return (
            <EventStepper
              name="coagulations"
              formData={{
                document: { coagulations: coagulations },
                onChange: thermocoagulationOnChange,
              }}
              stepLabelText={(d: ICoagulation): string => stepLabelText(d, fm)}
              stepContent={(d: ICoagulation): JSX.Element => <StepContent d={d} fm={fm} />}
              addNewTextHeader="surgicalTreatment.thermoCoagulation.coagulations.title"
              addNewTextButton="surgicalTreatment.thermoCoagulation.coagulations.add"
              previousEventsTextHeader="surgicalTreatment.thermoCoagulation.coagulations.previous"
              editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
                return (
                  <React.Fragment>
                    {!isNil(operationIndex) ? (
                      <EditingElement
                        formData={
                          { document: coagulations?.[index], onChange: onChange } as {
                            document: ICoagulation;
                            onChange: IFormData['onChange'];
                          }
                        }
                        editing={editingDoc}
                        fm={fm}
                      />
                    ) : undefined}
                  </React.Fragment>
                );
              }}
            />
          );
        }}
      </OperationContext.Consumer>
    </React.Fragment>
  );
};

export default withFormContext(Thermocoagulation);
