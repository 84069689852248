import * as React from 'react';
import FormRow from '../../../../../../components/FormRow';
import InputHandler from '../../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../../containers/FormContextHandler';
import { nowPartialDate } from 'neuro-utils';
import { isString } from '../../../../../../utility/typeGuards';
import { seizureClassifications } from '../../../../utils/definitions';
import { styled } from '@mui/system';
import colors from '../../../../../../config/theme/colors';
import { Container, Item } from 'Components/Grid';
import { formatSeizureClassification } from 'Routes/Seizure/utils';

const GroupHeader = styled(Container)({
  position: 'sticky' as const,
  height: '3.6rem',
  fontSize: '1.4rem',

  top: '-8px', // Counter padding and sticky needs this
  padding: '0 1.6rem',
  color: colors.darkGray,
  backgroundColor: colors.white,
  alignItems: 'center',
  marginTop: '0rem',
});

const getIndentedOption = (
  classificationString: string,
  group: string,
  regex: RegExp,
  indent: string,
  spaces: number,
): JSX.Element | null => {
  if (classificationString.match(regex)) {
    const [mainPart, ...rest] = classificationString.split(' ');
    const remaining = rest.join(' ');
    return (
      <React.Fragment key={classificationString}>
        {Array(spaces).fill('\u00A0').join('')}
        {`${mainPart} ${remaining}`}
        {indent && (
          <React.Fragment>
            <span style={{ padding: '0.4rem 0.5rem 0 0.5rem', fontSize: '1.2rem' }}>-</span>
            <span style={{ paddingTop: '0.4rem', fontSize: '1.2rem' }}>{group}</span>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  return null;
};

/**
 * Indents options that are subclasses of another number, e.g., 1.0.1 is indented under 1.0.
 * @param {{ classification: string } | string} o - The option to be formatted (either a classification object or string)
 * @param {string} group - The group to display next to the option
 * @returns {React.ReactNode} The formatted option with appropriate indentation
 */
const formatOptionIndent = (o: { classification: string } | string, group: string): React.ReactNode => {
  const classificationString = isString(o) ? o : o.classification;

  const fullIndentRegex = /^[0-9]\.[0-9]\.[0-9]\.[0-9]/;
  const partialIndentRegex = /^[0-9]\.[0-9]\.[0-9]\s/;

  const fullIndentedOption = getIndentedOption(classificationString, group, fullIndentRegex, group, 6);
  const partialIndentedOption = getIndentedOption(classificationString, group, partialIndentRegex, '', 3);

  return fullIndentedOption ? (
    fullIndentedOption
  ) : partialIndentedOption ? (
    partialIndentedOption
  ) : (
    <React.Fragment key={classificationString}>{classificationString}</React.Fragment>
  );
};

const _SeizureTypeForm = (props: IFormContext<ISeizureType>): JSX.Element => {
  const { editing, formData } = props;
  const prefix = 'diagnosis.epilepsy.seizureType.'; // for localization
  const options = seizureClassifications.map((sc) => {
    const pieces = sc.split('.');
    // Set parent (top) group as group, instead of close group
    let group = pieces.length > 2 ? [pieces[0], pieces[1]].join('.') : pieces[0];

    const findChildClassification = seizureClassifications.filter((clas) => clas.match('^' + sc));
    if (findChildClassification.length > 1) {
      // If this (top group) classification has child classifications, use this clas as group
      if (sc.split('.').length === 2) group = sc;
    }

    return {
      classification: sc,
      group: group,
    };
  }) as Array<{
    classification: string;
    group: string | null;
  }>;

  // Set visibleToPatient default value on mount
  React.useEffect(() => {
    if (formData.document?.visibleToPatient === undefined) {
      formData.onChange?.({ visibleToPatient: true });
    }
  }, []);

  return (
    <React.Fragment>
      <FormRow title={prefix + 'firstOccurenceDate'}>
        <InputHandler
          name="firstOccurenceDate"
          type="PartialDate"
          editing={!!editing}
          formData={formData}
          dateHook={{
            dateHookCeiling: nowPartialDate(),
          }}
        />
      </FormRow>
      <FormRow title={prefix + 'seizureClassification'}>
        <div style={{ margin: '0 0 2rem 0', fontWeight: 600 }}>
          {formatSeizureClassification(
            formData.document.classification?.classification as TSeizureClassification,
            'long',
            true,
          )}
        </div>
        <InputHandler
          type="AutoCompleteSelect"
          name="classification"
          editing={!!editing}
          formData={{
            onChange: formData?.onChange,
            document: { classification: formData?.document.classification ?? '' },
          }}
          options={options}
          placeholder={prefix + 'seizureClassificationPlaceholder'}
          width={50}
          getOptionLabel={(o): string =>
            o.classification ? `${o.classification} ${props.fm(`seizure.opts.${o.classification}`)}` : ''
          }
          groupID="group"
          renderOption={(o: any): React.ReactNode =>
            o &&
            formatOptionIndent(
              `${o.classification} ${props.fm(`seizure.opts.${o.classification}`)}`,
              o.classification && o.classification.split('.').length > 2
                ? props.fm(`seizure.opts.${o.classification.split('.').slice(0, -1).join('.')}`)
                : '',
            )
          }
          groupBy={(option: Record<string, string>) => {
            if (option['group'].split('.').length === 1) return ''; // option['group'].split('.')[0]
            return `${option['group']} ${props.fm(`seizure.opts.${option['group']}`)}`;
          }}
          renderGroup={(params) => (
            <li key={params.key}>
              {params.group && (
                <GroupHeader>
                  <Item>{params.group}</Item>
                </GroupHeader>
              )}
              <ul style={{ margin: 0, padding: 0 }}>{params.children}</ul>
            </li>
          )}
        />
      </FormRow>
      <FormRow title={prefix + 'visibleToPatient'}>
        <InputHandler
          name="visibleToPatient"
          type="CheckboxSingle"
          editing={!!editing}
          formData={formData}
          option={'true'}
          showLabel={false}
        />
      </FormRow>
      <FormRow title={prefix + 'seizureNameByPatient'}>
        <InputHandler
          name="nameByPatient"
          type="TextField"
          editing={!!editing}
          formData={formData}
          placeholder={prefix + 'seizureNameByPatientPlaceholder'}
          width={50}
        />
      </FormRow>
      <FormRow title={prefix + 'seizureFreeDescription'}>
        <InputHandler
          type="TextArea"
          name="freeDescription"
          editing={true}
          formData={formData}
          placeholder={prefix + 'seizureFreeDescriptionPlaceholder'}
          width={50}
        />
      </FormRow>
    </React.Fragment>
  );
};

/** Component rendering epilepsy platform's seizure type docs' form. */
const SeizureTypeForm = withFormContext(_SeizureTypeForm);
export default SeizureTypeForm;
