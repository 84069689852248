import { fetchWithOptions } from '../../../utility/fetch';
import { makeLog } from '../../../utility/logger';
import { parseJWTFromCookie } from '../../../utility/jwtAuthTools';
import { IntlShape } from 'react-intl';

const emptyResponse: IAdverseEffect[] = [{ eventName: null, frequencyClass: 'Not found', targetOrgan: null }];

const sortByKey =
  <T>(key: keyof T) =>
  (a: T, b: T): 1 | -1 | 0 => {
    const aProp = a[key];
    const bProp = b[key];
    return aProp > bProp ? 1 : bProp > aProp ? -1 : 0;
  };

const nameSorter = sortByKey<IAdverseEffect>('eventName');
const organSorter = sortByKey<IAdverseEffect>('targetOrgan');

const sortAdverseEvents = (events: IAdverseEffect[]): IAdverseEffect[] => {
  return events.slice(0).sort((a, b) => organSorter(a, b) || nameSorter(a, b));
};

const fetchAdverseEvents = async (
  formatMessage: IntlShape['formatMessage'],
  medicationName: string,
): Promise<IAdverseEffect[]> => {
  try {
    const apiResponse = await fetchWithOptions(
      `/api/adverseEvent/v1/medication?retailName=${medicationName}`,
      { neurojwt: parseJWTFromCookie() },
      { method: 'GET' },
    );

    if (apiResponse.status !== 200) return emptyResponse;
    const responsePayload = await apiResponse.json();

    const otherEvent = {
      eventName: formatMessage({ id: 'medication.otherAdverseEffect' }),
      targetOrgan: formatMessage({ id: 'medication.otherAdverseEffectGroup' }),
      frequencyClass: '',
    };

    // The response should always be an array after it's fixed, but lets make sure
    return Array.isArray(responsePayload) ? [otherEvent, ...sortAdverseEvents(responsePayload)] : [otherEvent];
  } catch (error) {
    makeLog(
      'Error',
      { name: 'Adverse effects fetching', message: `Failed to fetch adverse effects for medication ${medicationName}` },
      error,
    );
    return emptyResponse;
  }
};

export default fetchAdverseEvents;
