import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import Unit from 'Components/Unit';
import * as React from 'react';
import { TDCSContext } from 'Routes/Tdcs/Document';
import { styled } from '@mui/system';
import { defaultTDCSTreatmentUnits } from 'Routes/Tdcs/Document/config';
import { exists } from 'neuro-utils';
import { equals } from 'ramda';
import colors from '../../../../../../../config/theme/colors';
import { originAndDateOfReference, subjectOfTreatmentTitle, valueDiffersFromDoctorsOrders } from 'Utility/ninmtUtil';
import ToolTip from '../../../../../../../components/ToolTip';

const InputContainer = styled('div')({
  fontWeight: 600,
  margin: '0 0 2.5rem 0.9rem',
});

const Treatment = ({ document, onChange, session, sessionNumber, editingEvent = true }: ITreatment): JSX.Element => {
  const placeholder = editingEvent ? '' : '-';

  const tdcsContext = React.useContext(TDCSContext);
  const { isEditing, fm } = tdcsContext;

  const currentIndex = session?.subjectOfTreatment?.findIndex((s) => equals(s, document));

  /** Flips cathode to anode and vice versa */
  const f: { [key: string]: keyof ITDCSTreatment } = {
    cathode: 'anode',
    anode: 'cathode',
  };

  return (
    <div style={{ padding: '2rem 0 0 0' }}>
      {document.deleted ? (
        <React.Fragment>
          <FormRow title="tdcs.subjectOfTreatment.treatmentDeleteReason">
            <InputContainer>
              <InputHandler
                type="Radio"
                name="deleteReason"
                editing={isEditing && editingEvent}
                formData={{
                  document: { deleteReason: document.deleteReason || placeholder },
                  onChange: onChange,
                }}
                options={['partOfTreatmentProtocol', 'otherReason']}
                optionFormatter={(id: string | number) => fm(`tdcs.opts.treatmentDeleteReason.${id}`)}
              />
            </InputContainer>
          </FormRow>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {!editingEvent && (
            <React.Fragment>
              <FormRow title="rtms.subjectOfTreatment.name" bottomMargin={false}>
                <InputContainer style={{ fontWeight: 400 }}>
                  {subjectOfTreatmentTitle(document, 'tdcs', fm)}
                </InputContainer>
              </FormRow>
              <FormRow title="rtms.subjectOfTreatment.copyTitle" bottomMargin={false}>
                <InputContainer style={{ fontWeight: 400 }}>
                  {originAndDateOfReference(document, 'tdcs', fm)}
                </InputContainer>
              </FormRow>
            </React.Fragment>
          )}
          {['session', 'type', 'current', 'currentDuration'].map((field) => {
            switch (field) {
              case 'session': {
                return (
                  <FormRow key={field} title="tdcs.subjectOfTreatment.session" bottomMargin={false}>
                    <InputContainer style={{ fontWeight: 'normal' }}>
                      {exists(sessionNumber) ? (sessionNumber ?? 0) + 1 : '-'}
                    </InputContainer>
                  </FormRow>
                );
              }
              case 'current':
              case 'currentDuration': {
                return (
                  <FormRow key={field} title={`tdcs.subjectOfTreatment.${field}`} bottomMargin={false}>
                    <InputContainer>
                      <Unit unit={defaultTDCSTreatmentUnits[field]}>
                        <ToolTip
                          title={fm('tdcs.subjectOfTreatment.valueInDoctorsOrders')}
                          cursor={
                            valueDiffersFromDoctorsOrders(session, currentIndex ?? 0, field, document[field])
                              ? 'Pointer'
                              : 'Default'
                          }
                          description={
                            <>
                              {valueDiffersFromDoctorsOrders(
                                session,
                                currentIndex ?? 0,
                                field,
                                document[field],
                                undefined,
                                true,
                              ) ?? '-'}
                            </>
                          }
                          flipTitleAndDescStyle
                          hover={true}
                          disabled={!valueDiffersFromDoctorsOrders(session, currentIndex ?? 0, field, document[field])}
                          content={
                            <InputHandler
                              type="NumberField"
                              name={field}
                              editing={isEditing && editingEvent}
                              formData={{
                                document: {
                                  [field]: exists(document[field as keyof ITDCSTreatment])
                                    ? document[field as keyof ITDCSTreatment]
                                    : placeholder,
                                },
                                onChange: onChange,
                              }}
                              width={6}
                              precision={field === 'current' ? 1 : undefined}
                              style={
                                valueDiffersFromDoctorsOrders(session, currentIndex ?? 0, field, document[field])
                                  ? {
                                      backgroundColor: colors.appBlue.default,
                                      color: colors.white,
                                    }
                                  : undefined
                              }
                            />
                          }
                        ></ToolTip>
                      </Unit>
                    </InputContainer>
                  </FormRow>
                );
              }
              default: {
                return (
                  <React.Fragment key={field}>
                    <div style={{ marginBottom: !editingEvent ? '2rem' : '-0.5rem' }}>
                      <FormRow key="type" title="tdcs.subjectOfTreatment.type" bottomMargin={false}>
                        <InputContainer style={{ marginBottom: !editingEvent ? '0.5rem' : undefined }}>
                          <InputHandler
                            type="Radio"
                            name="type"
                            editing={isEditing && editingEvent}
                            formData={{
                              document: { type: document.type || placeholder },
                              onChange: onChange,
                            }}
                            options={['anode', 'cathode']}
                            optionFormatter={(id: string | number) =>
                              id !== '-' ? fm(`tdcs.opts.subjectOfTreatment.${id}`) : id
                            }
                            style={
                              valueDiffersFromDoctorsOrders(session, currentIndex ?? 0, field, document.type)
                                ? {
                                    backgroundColor: colors.appBlue.default,
                                    color: colors.white,
                                  }
                                : undefined
                            }
                            disabledOptions={['anode', 'cathode']}
                            color={
                              valueDiffersFromDoctorsOrders(session, currentIndex ?? 0, field, document.type)
                                ? 'error'
                                : undefined
                            }
                          />
                        </InputContainer>
                      </FormRow>
                    </div>
                    {document.type && (
                      <React.Fragment>
                        <FormRow
                          key={f[document.type]}
                          title={`tdcs.subjectOfTreatment.${f[document.type]}`}
                          bottomMargin={false}
                        >
                          <InputContainer>
                            <InputHandler
                              type="Radio"
                              name={f[document.type]}
                              editing={isEditing && editingEvent}
                              formData={{
                                document: { [f[document.type]]: document[f[document.type]] || placeholder },
                                onChange: onChange,
                              }}
                              options={['foreheadRight', 'foreheadLeft', 'other']}
                              optionFormatter={(id: string | number) =>
                                id !== '-' ? fm(`tdcs.opts.subjectOfTreatment.${id}`) : id
                              }
                              style={
                                valueDiffersFromDoctorsOrders(
                                  session,
                                  currentIndex ?? 0,
                                  f[document.type],
                                  document[f[document.type]] as string,
                                )
                                  ? {
                                      backgroundColor: colors.appBlue.default,
                                      radioColor: 'error',
                                      color: colors.white,
                                    }
                                  : undefined
                              }
                              disabledOptions={['foreheadRight', 'foreheadLeft', 'other']}
                            />
                          </InputContainer>
                        </FormRow>
                        {document[f[document.type]] === 'other' && (
                          <FormRow
                            key={`${f[document.type]}Other`}
                            title={`tdcs.subjectOfTreatment.${f[document.type]}Other`}
                            bottomMargin={false}
                          >
                            <InputContainer>
                              <InputHandler
                                type="TextArea"
                                name={`${f[document.type]}Other`}
                                editing={false}
                                formData={{
                                  document: {
                                    [`${f[document.type]}Other`]:
                                      document[`${f[document.type]}Other` as keyof ITDCSTreatment] || placeholder,
                                  },
                                  onChange: onChange,
                                }}
                                placeholder={`tdcs.subjectOfTreatment.${f[document.type]}OtherPlaceholder`}
                              />
                            </InputContainer>
                          </FormRow>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              }
            }
          })}
          <FormRow title="tdcs.subjectOfTreatment.additionalInformationSubject">
            <InputContainer>
              <InputHandler
                type="TextArea"
                name="additionalInformation"
                editing={isEditing && editingEvent}
                formData={{
                  document: { additionalInformation: document.additionalInformation || placeholder },
                  onChange: onChange,
                }}
                placeholder="tdcs.subjectOfTreatment.additionalInformationSubject"
              />
            </InputContainer>
          </FormRow>
        </React.Fragment>
      )}
    </div>
  );
};

interface ITreatment {
  document: ITDCSTreatment;
  onChange: (value: TOnChangeValues) => void;
  session?: ITDCSSession;
  sessionNumber?: number;
  precedingDoctorsOrder?: IDoctorsOrder;
  editingEvent?: boolean;
}

export default Treatment;
