import * as React from 'react';
import { Item } from '../../../../../../components/Grid';
import HistoryRow from '../../../../../../components/HistoryRow';
import HistoryRowControls from '../../../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../../../components/HistoryRowListing';
import { IHistoryContext, withHistoryContext } from '../../../../../../containers/FormContextHandler';
import { formatPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import { newButton } from '../../../../utils/functions';

const SyndromeHistory = ({ documents = [], startEdit }: IHistoryContext): JSX.Element => {
  const syndromeDocuments: ISyndrome[] = documents.filter((d: any) => d._type === 'syndrome') as ISyndrome[];
  return (
    <HistoryRow
      headerText={fm('diagnosis.epilepsy.syndrome.title')}
      rowButton={startEdit ? newButton('syndrome', startEdit({}, 'syndrome'), 'general.new', 15) : undefined}
    >
      <HistoryRowListing
        documents={syndromeDocuments}
        headers={
          <>
            <Item xs={4}>{fm('diagnosis.epilepsy.syndrome.title')}</Item>

            <Item xs={6}>{fm('diagnosis.epilepsy.orphaCode')}</Item>

            <Item xs={2}>{fm('diagnosis.epilepsy.diagnosisDate')}</Item>
          </>
        }
        contentFormat={(d: ISyndrome): JSX.Element => (
          <>
            <Item xs={4}>
              <div>
                {d?.syndrome ? fm(`diagnosis.epilepsy.syndrome.opts.${d.syndrome}`) : '-'}
                {d?.syndrome === 'otherKnownSyndrome' ? ':' : ''}
              </div>
              <div>{d?.syndrome === 'otherKnownSyndrome' ? d.syndromeSpecification : ''}</div>
            </Item>

            <Item xs={6}>{d?.orphaCode ?? '-'}</Item>

            <Item xs={2}>{formatPartialDate(d?.date)}</Item>
          </>
        )}
        collapse={true}
        makeControls
        historyRowControls={(d: IControlProps) =>
          startEdit ? <HistoryRowControls document={d} startEdit={startEdit} /> : <></>
        }
      />
    </HistoryRow>
  );
};

export default withHistoryContext(SyndromeHistory);
