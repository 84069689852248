import * as React from 'react';
import AidForm from './Aid';
import MotorFunctionForm from './MotorFunction';

const Form = ({ formData, document }: IOwnProps): JSX.Element | null => {
  return document?._type === 'motorfunction' ? (
    <MotorFunctionForm formData={formData as IFormData<IMotorFunction>} />
  ) : document?._type === 'mobilityAid' ? (
    <AidForm formData={formData as IFormData<IAid>} />
  ) : null;
};

interface IOwnProps {
  formData: IFormData<IAid | IMotorFunction>;
  document?: IAid | IMotorFunction;
}

export default Form;
