import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import { ReferenceText } from '../../../../../../utils/styled';
import PPMS from './PPMS';
import RRMS from './RRMS';

const McDonald2017 = ({ documents, formData, view, editingID }: IOwnProps): JSX.Element => {
  return (
    <React.Fragment>
      <RRMS documents={documents} formData={formData as IFormData<IMSCriteria>} view={view} editingID={editingID} />
      <PPMS documents={documents} formData={formData} view={view} editingID={editingID} />

      <Container>
        <Item style={{ padding: '2rem 0' }}>
          <ReferenceText>
            MS-tauti: Käypä hoito -suositus (Julkaistu: 23.01.2020). Suomalaisen Lääkäriseuran Duodecimin ja Suomen
            Neurologinen Yhdistys ry:n asettama työryhmä. Helsinki: Suomalainen Lääkäriseura Duodecim, 2017. Saatavilla
            Internetissä: www.kaypahoito.fi
          </ReferenceText>
        </Item>
      </Container>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents?: Array<IMSCriteria>;
  formData: IFormData<IMSCriteria>;
  view?: IView;
  editingID?: string;
}

export default McDonald2017;
