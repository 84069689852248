import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import Unit from '../../../../../components/Unit';

type TFieldConfig = (
  | {
      type: 'PartialDate';
      name: keyof INightOximetry;
      title?: string | undefined;
      titleDescription?: JSX.Element | string | undefined;
      dateDefault?: 'now' | PartialDate | undefined;
      isNotCancellable?: boolean;
    }
  | {
      type: 'NumberField';
      name: keyof INightOximetry;
      title?: string | undefined;
      titleDescription?: JSX.Element | string | undefined;
      precision?: number;
      min?: number;
      max?: number;
      placeholder?: string;
      unit?: string | JSX.Element;
    }
  | {
      type: 'Radio';
      name: keyof INightOximetry;
      title?: string | undefined;
      titleDescription?: string | JSX.Element | undefined;
      options: Array<string | number>;
      optFormatter?: (name: string | number) => JSX.Element;
    }
)[];

const fieldConfig: TFieldConfig = [
  {
    type: 'PartialDate',
    name: 'date',
    title: 'clpAndCnpTests.date',
    titleDescription: undefined,
    isNotCancellable: true,
  },
  {
    type: 'NumberField',
    name: 'saO295thPercentile',
    title: 'clpAndCnpTests.saO295thPercentile',
    precision: 2,
    min: 0,
    max: 100,
    placeholder: 'clpAndCnpTests.saO295thPercentile',
    unit: '%',
  },
  {
    type: 'NumberField',
    name: 'saO2Median',
    title: 'clpAndCnpTests.saO2Median',
    precision: 2,
    min: 0,
    max: 100,
    placeholder: 'clpAndCnpTests.saO2Median',
    unit: '%',
  },
  {
    type: 'NumberField',
    name: 'saO2Minimum',
    title: 'clpAndCnpTests.saO2Minimum',
    precision: 2,
    min: 0,
    max: 100,
    placeholder: 'clpAndCnpTests.saO2Minimum',
    unit: '%',
  },
  {
    type: 'NumberField',
    name: 'saO2LessThan88Percents',
    title: 'clpAndCnpTests.saO2LessThan88Percents',
    titleDescription: <FormattedMessage id="clpAndCnpTests.saO2LessThan88PercentsDescription" />,
    precision: 2,
    min: 0,
    max: 100,
    placeholder: 'clpAndCnpTests.saO2LessThan88Percents',
    unit: '%',
  },
  {
    type: 'NumberField',
    name: 'saO2LessThan88Minutes',
    title: 'clpAndCnpTests.saO2LessThan88Minutes',
    titleDescription: <FormattedMessage id="clpAndCnpTests.saO2LessThan88MinutesDescription" />,
    precision: 2,
    min: 0,
    max: 999999,
    placeholder: 'clpAndCnpTests.saO2LessThan88Minutes',
    unit: <FormattedMessage id="clpAndCnpTests.minutes" />,
  },
  {
    type: 'NumberField',
    name: 'odi4',
    title: 'clpAndCnpTests.odi4',
    precision: 2,
    min: 0,
    max: 999999,
    placeholder: 'clpAndCnpTests.odi4',
    unit: <FormattedMessage id="clpAndCnpTests.perHour" />,
  },
  {
    type: 'NumberField',
    name: 'pulse',
    title: 'clpAndCnpTests.pulse',
    precision: 0,
    min: 0,
    max: 999999,
    placeholder: 'clpAndCnpTests.pulse',
    unit: <FormattedMessage id="clpAndCnpTests.bpm" />,
  },
  {
    type: 'NumberField',
    name: 'cheyneStokesRespirationMinutes',
    title: 'clpAndCnpTests.cheyneStokesRespirationMinutes',
    precision: 2,
    min: 0,
    max: 999999,
    placeholder: 'clpAndCnpTests.cheyneStokesRespirationMinutes',
    unit: <FormattedMessage id="clpAndCnpTests.minutes" />,
  },
  {
    type: 'NumberField',
    name: 'cheyneStokesRespirationPercents',
    title: 'clpAndCnpTests.cheyneStokesRespirationPercents',
    precision: 2,
    min: 0,
    max: 100,
    placeholder: 'clpAndCnpTests.cheyneStokesRespirationPercents',
    unit: '%',
  },
];

const NightOximetryForm = ({ formData, viewing }: IOwnProps): JSX.Element => (
  <React.Fragment>
    {fieldConfig.map((item) => (
      <FormRow key={item.name} title={item.title} description={item.titleDescription}>
        {item.type === 'PartialDate' ? (
          <InputHandler
            type={item.type}
            editing={!viewing}
            name={item.name}
            formData={formData}
            dateDefault={item.dateDefault ?? 'now'}
            isNotCancellable={item.isNotCancellable}
          />
        ) : item.type === 'NumberField' ? (
          <Unit unit={item.unit ?? ''}>
            <InputHandler
              type={item.type}
              editing={!viewing}
              name={item.name}
              formData={formData}
              precision={item.precision}
              min={item.min}
              max={item.max}
              placeholder={item.placeholder}
            />
          </Unit>
        ) : null}
      </FormRow>
    ))}
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<INightOximetry>;
  viewing?: boolean;
}

export default NightOximetryForm;
