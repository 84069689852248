import * as React from 'react';
import FormRow from '../../../../../../components/FormRow';
import FormSection from '../../../../../../components/FormSection';
import InputHandler from '../../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../../containers/FormContextHandler';
import { optionFormatter } from '../../../../utils/functions';

const HuntingtonSymptomsForm = ({ editing, formData }: IFormContext): JSX.Element => {
  return (
    <React.Fragment>
      <FormSection>
        <div style={{ marginBottom: '5rem' }} />
        <FormRow title="diagnosis.symptomOnsetDate">
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="symptomOnsetDate"
            formData={formData}
            dateDefault="now"
          />
        </FormRow>
        <FormRow title="diagnosis.huntingtonSymptom.title">
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="symptoms"
            formData={formData}
            options={['cognitiveDisorders', 'behavioralDisorders', 'motorDisorders', 'unknown']}
            optionFormatter={optionFormatter('huntingtonSymptom.opts')}
          />
        </FormRow>
        <FormRow title="diagnosis.huntingtonSymptom.additionalInformation">
          <InputHandler type="TextArea" editing={!!editing} name="additionalInformation" formData={formData} />
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};

export default withFormContext(HuntingtonSymptomsForm);
