import { isNil } from 'Utility/ramdaReplacement';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';

const optionFormatter = (name: number | string): JSX.Element => (
  <FormattedMessage id={`comorbidity.opts.reasonForSickLeave.${name}`} />
);

const SickleaveForm = ({ editing, formData, diagnosis }: IOwnProps): JSX.Element => {
  const g473 = diagnosis?.includes('G47.3') ? true : false;
  const j961 = diagnosis?.includes('J96.1') ? true : false;
  const j969 = diagnosis?.includes('J96.9') ? true : false;

  const autoSelectG473 = g473 && !j961 && !j969 ? 'G47.3' : undefined;
  const autoSelectJ961 = j961 && !g473 && !j969 ? 'J96.1' : undefined;
  const autoSelectJ969 = j969 && !g473 && !j961 ? 'J96.9' : undefined;

  React.useEffect(() => {
    if (editing && isNil(formData.document.reasonForSickLeave)) {
      if (autoSelectJ969) {
        formData.onChange?.({ reasonForSickLeave: autoSelectJ969 });
      } else if (autoSelectJ961) {
        formData.onChange?.({ reasonForSickLeave: autoSelectJ961 });
      } else if (autoSelectG473) {
        formData.onChange?.({ reasonForSickLeave: autoSelectG473 });
      }
    }
  }, [editing, formData.document, autoSelectG473, autoSelectJ961, autoSelectJ969]);

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="comorbidity.startDate">
          <InputHandler
            type="PartialDate"
            name="date"
            editing={editing}
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
            dateHook={{ dateHookCeiling: formData.document.endDate }}
          />
        </FormRow>
        <FormRow title="comorbidity.endedDate">
          <InputHandler
            type="PartialDate"
            name="endDate"
            editing={editing}
            formData={formData}
            dateHook={{ dateHookFloor: formData.document.date }}
          />
        </FormRow>
        <FormRow title="comorbidity.reasonForSickLeave">
          <InputHandler
            type="Radio"
            name="reasonForSickLeave"
            editing={editing}
            formData={formData}
            options={['G47.3', 'J96.1', 'J96.9']}
            optionFormatter={optionFormatter}
          />
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  editing?: boolean;
  formData: IFormData<ISickLeave>;
  diagnosis?: Array<string>;
}

export default SickleaveForm;
