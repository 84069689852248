import * as React from 'react';
import FormRow from '../../../../../../components/FormRow';
import InputHandler from '../../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../../containers/FormContextHandler';

const SleepApneaSeverityForm = ({ editing, formData, fm }: IFormContext<ISleepApneaSeverity>): JSX.Element => (
  <React.Fragment>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={!!editing}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="diagnosis.sleepApnea.sleepApneaSeverity.severity">
      <InputHandler
        type="Radio"
        editing={!!editing}
        name="severity"
        formData={formData}
        options={['mild', 'moderate', 'severe']}
        optionFormatter={(s: string | number): string => fm(`diagnosis.sleepApnea.sleepApneaSeverity.opts.${s}`)}
      />
    </FormRow>
  </React.Fragment>
);

export default withFormContext(SleepApneaSeverityForm);
