import * as React from 'react';

import { TDocument } from '../../utils';

import ChestCircumferenceForm from './ChestCircumference';
import VentilationForm from './Ventilation';
import PulmonaryForm from './Pulmonary';

const Form = ({ formData, documents, editing }: IOwnProps): JSX.Element | null => {
  const document = documents && documents.find((d: TDocument) => d._id === editing);
  return document?._type === 'chestCircumference' ? (
    <ChestCircumferenceForm formData={formData} />
  ) : document?._type === 'ventilationAid' ? (
    <VentilationForm formData={formData} />
  ) : document?._type === 'pulmonary' ? (
    <PulmonaryForm
      formData={formData as IFormData<IPulmonary>}
      documents={documents?.filter((d: TDocument) => d._type === 'pulmonary') as IPulmonary[]}
      editing={editing}
    />
  ) : null;
};
interface IOwnProps {
  formData: IFormData<TDocument>;
  documents: TDocument[];
  editing: string;
  viewing?: boolean;
}

export default Form;
