import { fm as formatterFM } from 'Components/FormatMessage';
import FormRow from 'Components/FormRow';
import FormSection from 'Components/FormSection';
import FormSectionHistoryAcceptor from 'Components/FormSectionHistoryAcceptor';
import { Container, Item } from 'Components/Grid';
import InputHandler from 'Components/InputHandler';
import { IFormContext, withFormContext } from 'Containers/FormContextHandler';
import { sortPartialDate } from 'neuro-utils';
import * as React from 'react';

const MakeFormRow = ({
  title,
  description,
  name,
  formData,
  editing,
  type,
  option,
  options,
  optionSpecificInfo,
  placeholder,
  dependentFieldsList,
}: {
  title: string;
  description?: string;
  name: string;
  formData: IOwnProps['formData'];
  editing: string | undefined;
  type: 'Checkbox' | 'CheckboxSingle' | 'TextArea';
  option?: ICheckboxSingle['option'];
  options?: string[];
  optionSpecificInfo?: ICheckbox['optionSpecificInfo'];
  placeholder?: string;
  dependentFieldsList?: () => string[];
}): JSX.Element => (
  <FormRow title={title} bottomMargin={true} description={description}>
    <InputHandler
      editing={!!editing}
      formData={formData}
      name={name}
      type={type}
      option={option}
      options={options ?? undefined}
      optionFormatter={(name: string | number): JSX.Element =>
        name === 'true' ? formatterFM(title) : formatterFM(`diagnosis.ninmt.contraIndicationsToTreatment.opts.${name}`)
      }
      optionSpecificInfo={optionSpecificInfo}
      placeholder={placeholder}
      dependentFieldsList={dependentFieldsList}
    />
  </FormRow>
);

const ContraIndicationsToTreatmentForm = ({
  editing,
  formData,
  documents,
  fm,
  consumeTreatmentId,
}: IFormContext & {
  consumeTreatmentId: () => string | null;
}): JSX.Element => {
  const treatmentId = consumeTreatmentId() || formData.document?.treatmentPeriodId;

  React.useEffect(() => {
    if (treatmentId) {
      // If treatment period id is available (consumed), set the treatment id
      if (!formData.document?.treatmentPeriodId) {
        formData.onChange?.({ treatmentPeriodId: treatmentId });
      }
    } else {
      const nearestId =
        Array.isArray(documents) &&
        documents
          .slice()
          .sort((d1, d2) => sortPartialDate(d2.date, d1.date))
          .find((d) => d._type === 'ninmtTreatmentPeriod' && sortPartialDate(formData.document.date, d.date) >= 0)?._id;
      formData.onChange?.({ treatmentPeriodId: nearestId ?? '' });
    }
  }, [treatmentId, formData]);

  return (
    <React.Fragment>
      <FormSection>
        <Container>
          <Item xs={10}>
            <FormRow title="diagnosis.ninmt.contraIndicationsToTreatment.date">
              <div style={{ justifyContent: 'flex-start' }}>
                <InputHandler
                  type="PartialDate"
                  name="date"
                  formData={formData}
                  editing={!!editing}
                  isNotCancellable={true}
                  dateDefault="now"
                />
              </div>
            </FormRow>
          </Item>
          <Item xs={2} />
        </Container>
      </FormSection>
      <FormSectionHistoryAcceptor
        documents={documents?.filter(
          (d) =>
            d.treatmentPeriodId === formData.document.treatmentPeriodId && d._type === 'contraIndicationsToTreatment',
        )}
        formData={formData}
        documentID={editing}
        header="diagnosis.ninmt.contraIndicationsToTreatment.rtmsContraIndications"
        optionFormatter={(name: string | number | string[] | boolean, field?: string): JSX.Element => {
          if (typeof name === 'boolean' && field) {
            return field === 'noRtmsContraIndications' ? (
              <div>{fm('diagnosis.ninmt.contraIndicationsToTreatment.noRtmsContraIndications')}</div>
            ) : field === 'rtmsRegardlessOfContraIndications' ? (
              <div>{fm('diagnosis.ninmt.contraIndicationsToTreatment.rtmsRegardlessOfContraIndications')}</div>
            ) : (
              <></>
            );
          } else if (!Array.isArray(name)) {
            return <div>{name}</div>;
          } else if (Array.isArray(name) && name.length > 1) {
            const names = name.map((n, i) => {
              return <div key={i}>{fm(`diagnosis.ninmt.contraIndicationsToTreatment.opts.${n}`)}</div>;
            });
            return <>{names}</>;
          } else return <span>{fm(`diagnosis.ninmt.contraIndicationsToTreatment.opts.${name}`)}</span>;
        }}
        prevDocWithField={true}
      >
        {{
          tmsAbsoluteContraIndications: {
            element: (
              <MakeFormRow
                title="diagnosis.ninmt.contraIndicationsToTreatment.tmsAbsoluteContraIndications"
                name="tmsAbsoluteContraIndications"
                formData={formData}
                editing={editing}
                type="Checkbox"
                options={[
                  'foreignObjectsInBrain',
                  'otherMagneticForeignObjectsInHead',
                  'pacemaker',
                  'metallicCardiacValveProsthesis',
                  'innerEarProsthesis',
                  'stimulatorAtNeck',
                ]}
                optionSpecificInfo={[
                  {
                    otherMagneticForeignObjectsInHead: fm(
                      'diagnosis.ninmt.contraIndicationsToTreatment.optsInfo.otherMagneticForeignObjectsInHead',
                    ),
                  },
                ]}
                dependentFieldsList={(): string[] => ['noRtmsContraIndications']}
              />
            ),
          },
          tmsRelativeContraIndications: {
            element: (
              <MakeFormRow
                title="diagnosis.ninmt.contraIndicationsToTreatment.tmsRelativeContraIndications"
                name="tmsRelativeContraIndications"
                formData={formData}
                editing={editing}
                type="Checkbox"
                options={[
                  'intracranialForeignObjects',
                  'insulinPump',
                  'infusionPortsAtUpperBody',
                  'nerveStimulators',
                  'epilepsy',
                  'diseasesWithLowerSeizureThreshold',
                  'pregnancy',
                  'nonRemovableTattoosOrPiercingsInHead',
                ]}
                optionSpecificInfo={[
                  'nerveStimulators',
                  'epilepsy',
                  'diseasesWithLowerSeizureThreshold',
                  'pregnancy',
                  'nonRemovableTattoosOrPiercingsInHead',
                ].map((o) => ({ [o]: fm(`diagnosis.ninmt.contraIndicationsToTreatment.optsInfo.${o}`) }))}
                dependentFieldsList={(): string[] => ['noRtmsContraIndications']}
              />
            ),
          },
          noRtmsContraIndications: {
            element: (
              <MakeFormRow
                title="diagnosis.ninmt.contraIndicationsToTreatment.noRtmsContraIndications"
                name="noRtmsContraIndications"
                formData={formData}
                editing={editing}
                type="CheckboxSingle"
                option="true"
                dependentFieldsList={(): string[] => ['tmsAbsoluteContraIndications', 'tmsRelativeContraIndications']}
              />
            ),
          },
          rtmsRegardlessOfContraIndications: {
            element: (
              <MakeFormRow
                title="diagnosis.ninmt.contraIndicationsToTreatment.rtmsRegardlessOfContraIndications"
                name="rtmsRegardlessOfContraIndications"
                description={fm(
                  'diagnosis.ninmt.contraIndicationsToTreatment.treatmentGivenRegardlessOfContraIndicationsDescription',
                )}
                formData={formData}
                editing={editing}
                type="CheckboxSingle"
                option="true"
              />
            ),
          },
          rtmsContraIndicationsInformation: {
            element: (
              <MakeFormRow
                title="diagnosis.ninmt.contraIndicationsToTreatment.rtmsContraIndicationsInformation"
                name="rtmsContraIndicationsInformation"
                type="TextArea"
                formData={formData}
                editing={editing}
                placeholder="diagnosis.ninmt.contraIndicationsToTreatment.contraIndicationsInformationPlaceholder"
              />
            ),
          },
        }}
      </FormSectionHistoryAcceptor>
      <FormSectionHistoryAcceptor
        documents={documents?.filter(
          (d) =>
            d.treatmentPeriodId === formData.document.treatmentPeriodId && d._type === 'contraIndicationsToTreatment',
        )}
        formData={formData}
        documentID={editing}
        header="diagnosis.ninmt.contraIndicationsToTreatment.tdcsContraIndications"
        optionFormatter={(name: string | number | string[] | boolean, field?: string): JSX.Element => {
          if (typeof name === 'boolean' && field) {
            return field === 'noTdcsContraIndications' ? (
              <div>{fm('diagnosis.ninmt.contraIndicationsToTreatment.noTdcsContraIndications')}</div>
            ) : field === 'tdcsRegardlessOfContraIndications' ? (
              <div>{fm('diagnosis.ninmt.contraIndicationsToTreatment.tdcsRegardlessOfContraIndications')}</div>
            ) : (
              <></>
            );
          } else if (!Array.isArray(name)) {
            return <div>{name}</div>;
          } else if (Array.isArray(name) && name.length > 1) {
            const names = name.map((n, i) => {
              return <div key={i}>{fm(`diagnosis.ninmt.contraIndicationsToTreatment.opts.${n}`)}</div>;
            });
            return <>{names}</>;
          } else return <span>{fm(`diagnosis.ninmt.contraIndicationsToTreatment.opts.${name}`)}</span>;
        }}
        prevDocWithField={true}
      >
        {{
          dcsAbsoluteContraIndications: {
            element: (
              <MakeFormRow
                title="diagnosis.ninmt.contraIndicationsToTreatment.dcsAbsoluteContraIndications"
                name="dcsAbsoluteContraIndications"
                formData={formData}
                editing={editing}
                type="Checkbox"
                options={['metallicImplants', 'pacemaker', 'acuteRash']}
                dependentFieldsList={(): string[] => ['noTdcsContraIndications']}
              />
            ),
          },
          noTdcsContraIndications: {
            element: (
              <MakeFormRow
                title="diagnosis.ninmt.contraIndicationsToTreatment.noTdcsContraIndications"
                name="noTdcsContraIndications"
                formData={formData}
                editing={editing}
                type="CheckboxSingle"
                option="true"
                dependentFieldsList={(): string[] => ['dcsAbsoluteContraIndications']}
              />
            ),
          },
          tdcsRegardlessOfContraIndications: {
            element: (
              <MakeFormRow
                title="diagnosis.ninmt.contraIndicationsToTreatment.tdcsRegardlessOfContraIndications"
                description={fm(
                  'diagnosis.ninmt.contraIndicationsToTreatment.treatmentGivenRegardlessOfContraIndicationsDescription',
                )}
                name="tdcsRegardlessOfContraIndications"
                formData={formData}
                editing={editing}
                type="CheckboxSingle"
                option="true"
              />
            ),
          },
          tdcsContraIndicationsInformation: {
            element: (
              <MakeFormRow
                title="diagnosis.ninmt.contraIndicationsToTreatment.tdcsContraIndicationsInformation"
                name="tdcsContraIndicationsInformation"
                formData={formData}
                editing={editing}
                type="TextArea"
                placeholder="diagnosis.ninmt.contraIndicationsToTreatment.contraIndicationsInformationPlaceholder"
              />
            ),
          },
        }}
      </FormSectionHistoryAcceptor>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents?: Array<INMOSDCriteria>;
  formData: IFormData<INMOSDCriteria>;
  view?: IView;
  editingID?: string;
}

export default withFormContext(ContraIndicationsToTreatmentForm);
