import { isEmpty } from 'ramda';
import * as React from 'react';
import FormRow from '../../../../../../../components/FormRow';
import InputHandler from '../../../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../../../containers/FormContextHandler';
import { TMsCriteria, TMsCriteriaRevision } from '../../../../../utils/definitions';
import McDonald2010 from './McDonald2010/.';
import McDonald2017 from './McDonald2017/.';
import { isNil } from 'Utility/ramdaReplacement';

const msCriteriaOptions: Array<TMsCriteria> = ['mcdonald', 'poser', 'schumacher'];
const msRevisionOptions: Array<TMsCriteriaRevision> = ['2017', '2010', '2005', '2001', 'unknown'];

const MSDiagnosticCriteria = ({
  documents,
  formData = {
    document: {},
    onChange: (): void => {
      return;
    },
  },
  view,
  editing,
  fm = (): string => '',
}: IFormContext): JSX.Element => {
  const { document, onChange } = formData;

  const [criteria, setCriteria] = React.useState<TMsCriteria>('mcdonald');
  const [showRevision, setShowRevision] = React.useState<boolean>(false);
  const [revision, setRevision] = React.useState<TMsCriteriaRevision>('2017');

  React.useEffect(() => {
    if (editing && (isNil(document.criteria) || isEmpty(document.criteria))) {
      onChange?.({ criteria: 'mcdonald' });
    }
    if (document.criteria) setCriteria(document.criteria);
    if (editing && document.revision) setRevision(document.revision);
    if (document.criteria === 'mcdonald') {
      setShowRevision(true);
      if (editing && (isNil(document.revision) || isEmpty(document.revision))) onChange?.({ revision: '2017' });
    }
    if (document.criteria !== 'mcdonald') {
      setShowRevision(false);
      if (editing) onChange?.({ revision: '' });
    }
    if (document.revision) setRevision(document.revision);
  }, [document.criteria, document.revision, editing, onChange]);

  return (
    <React.Fragment>
      <FormRow title="diagnosis.msDiagnosticCriteria.criteria.title">
        <InputHandler
          type="Select"
          name="criteria"
          editing={!!editing}
          formData={formData}
          options={msCriteriaOptions}
          optionFormatter={(s?: React.ReactText): string => fm(`diagnosis.msDiagnosticCriteria.criteria.opts.${s}`)}
          placeholder="diagnosis.msDiagnosticCriteria.criteria.placeholder"
        />
      </FormRow>
      <FormRow title="diagnosis.msDiagnosticCriteria.mcDonaldRevision.title" condition={showRevision}>
        <InputHandler
          type="Select"
          name="revision"
          editing={!!editing}
          formData={formData}
          options={msRevisionOptions}
          optionFormatter={(s?: React.ReactText): string =>
            fm(`diagnosis.msDiagnosticCriteria.mcDonaldRevision.opts.${s}`)
          }
          placeholder="diagnosis.msDiagnosticCriteria.mcDonaldRevision.placeholder"
        />
      </FormRow>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!!editing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      {criteria === 'mcdonald' && formData ? (
        <React.Fragment>
          {revision === '2017' ? (
            <McDonald2017 documents={documents} formData={formData} view={view} editingID={editing} />
          ) : revision === '2010' ? (
            <McDonald2010 documents={documents} formData={formData} view={view} editingID={editing} />
          ) : (
            ''
          )}
        </React.Fragment>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default withFormContext(MSDiagnosticCriteria);
