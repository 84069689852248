import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';

const NocturnalPolygraphyForm = ({ formData }: IOwnProps): JSX.Element => (
  <React.Fragment>
    <FormRow title="general.date">
      <InputHandler
        type="PartialDate"
        editing={true}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
  </React.Fragment>
);

interface IOwnProps {
  formData: IFormData<INocturnalPolygraphy>;
}

export default NocturnalPolygraphyForm;
