import * as React from 'react';
import { styled } from '@mui/system';

import HistoryRowControls from '../../../components/HistoryRowControls';
import HistoryRow from '../../../components/HistoryRow';

import colors from '../../../config/theme/colors';
import { field } from '../../../config/theme/componentTheme';
import { formatPartialDate, sortPartialDate } from 'neuro-utils';

import { newButton } from './functions';
import { IDescription, ISection, parkinsonCriteriaType as TParkinsonCriteriaType } from './definitions';
import { IHistoryContext, withHistoryContext } from '../../../containers/FormContextHandler';
import HistoryRowListing from '../../../components/HistoryRowListing';
import { Container, Item } from '../../../components/Grid';
import FTDDiagnosticCriteriaHistory from '../Document/HistoryRowData/ParkinsonHistory/ParkinsonDiagnosticsCriteria/FTD';
import PPADiagnosticCriteriaHistory from '../Document/HistoryRowData/ParkinsonHistory/ParkinsonDiagnosticsCriteria/PPA';
import { equals, map, toString } from 'ramda';
import { fm } from 'Components/FormatMessage';

export const SubHeader = styled('div')({
  fontWeight: 600,
  margin: '0 0 1rem 0',
});

export const StyledHistory = styled('div')({
  marginBottom: '0.5rem',
});

export const StyledHeader = styled('div')({
  marginTop: '2rem',
  marginBottom: '1rem',
  fontSize: '2.2rem',
  fontWeight: 600,
  color: colors.primary,
});

export const StyledRowContainerBar = styled(Container)({
  backgroundColor: colors.defaultBackground,
  width: 'auto',
  height: '5rem',
  margin: '0 -2.5rem',
  padding: '0 2.5rem',
});

export const StyledBarHeader = styled('div')({
  fontWeight: 600,
  marginBottom: '-0.3rem',
});

export const StyledSubQuestionArea = styled('div')({
  padding: '0 3rem',
  backgroundColor: colors.defaultBackground,
});

export const ReferenceText = styled('div')({
  color: colors.tertiaryText,
});

const StyledHistoryContentComponent = ({
  documents = [],
  startEdit,
  view,
  type,
  clinicallyEst,
  probable,
  possible,
  checkClinicallyEstablishedMSA,
  checkClinicallyProbableMSA,
  fm = (): string => '',
}: IStyledHistoryProps): JSX.Element => {
  const doc: Array<TParkinsonCriteriaType> = documents
    .filter((d) => d._type === type)
    .sort((d1: TParkinsonCriteriaType, d2: TParkinsonCriteriaType) => sortPartialDate(d1.date, d2.date))
    .reverse() as Array<TParkinsonCriteriaType>;
  return !['ftd', 'ppa', 'msa'].includes(type) ? (
    <HistoryRow
      headerText={fm(`diagnosis.${type}.title`)}
      rowButton={startEdit ? newButton(type, startEdit({}, type), `diagnosis.${type}.new`, 15) : undefined}
    >
      <HistoryRowListing
        documents={doc}
        headers={
          <>
            <Item xs={2}>{fm('general.date')}</Item>
            <Item xs={2}>{clinicallyEst && fm(`diagnosis.${type}.clinicallyEstablished`)}</Item>
            <Item xs={2}>{probable && fm(`diagnosis.${type}.probable`)}</Item>
            <Item xs={6}>{possible && fm(`diagnosis.${type}.possible`)}</Item>
          </>
        }
        contentFormat={(d: (typeof doc)[0]): JSX.Element => (
          <>
            <Item xs={2} style={{ fontWeight: '600' }}>
              {d.date && formatPartialDate(d.date)}
            </Item>
            <Item xs={2}>{clinicallyEst && fm(`general.${clinicallyEst(d)}`)}</Item>
            <Item xs={2}>{probable && fm(`general.${probable(d)}`)}</Item>
            <Item xs={3}>{possible && fm(`general.${possible(d)}`)}</Item>
            <Item xs={3}>
              {startEdit ? <HistoryRowControls document={d} startEdit={startEdit} view={view} /> : null}
            </Item>
          </>
        )}
      />
    </HistoryRow>
  ) : type === 'msa' ? (
    <HistoryRow
      headerText={fm(`diagnosis.${type}.title`)}
      rowButton={startEdit ? newButton(type, startEdit({}, type), `diagnosis.${type}.new`, 15) : undefined}
    >
      <HistoryRowListing
        documents={doc}
        headers={
          <>
            <Item xs={2}>{fm('general.date')}</Item>
            <Item xs={2}>{checkClinicallyEstablishedMSA && fm(`diagnosis.msa2022.clinicallyEstablishedMSA`)}</Item>
            <Item xs={2}>{(probable || checkClinicallyProbableMSA) && fm(`diagnosis.${type}.probable`)}</Item>
            <Item xs={4}>{possible && fm(`diagnosis.${type}.possible`)}</Item>
          </>
        }
        contentFormat={(d: (typeof doc)[0]): JSX.Element => {
          const version = !('version' in d) || !d.version || d.version === '2008' ? '2008' : '2022';
          return (
            <>
              <Item xs={2} style={{ fontWeight: '600' }}>
                {d.date && formatPartialDate(d.date)}
              </Item>
              <Item xs={2}>
                {version === '2008'
                  ? ''
                  : checkClinicallyEstablishedMSA && fm(`general.${checkClinicallyEstablishedMSA(d as IMSA2022)}`)}
              </Item>
              <Item xs={2}>
                {version === '2008'
                  ? probable && fm(`general.${probable(d)}`)
                  : checkClinicallyProbableMSA && fm(`general.${checkClinicallyProbableMSA(d as IMSA2022)}`)}
              </Item>
              <Item xs={3}>{version === '2008' ? possible && fm(`general.${possible(d)}`) : ''}</Item>
              <Item xs={3}>
                {startEdit ? <HistoryRowControls document={d} startEdit={startEdit} view={view} /> : null}
              </Item>
            </>
          );
        }}
      />
    </HistoryRow>
  ) : (
    <HistoryRow
      headerText={fm(`diagnosis.${type}.title`)}
      rowButton={startEdit ? newButton(type, startEdit({}, type), `diagnosis.${type}.new`, 15) : undefined}
    >
      <HistoryRowListing
        documents={doc}
        headers={<></>}
        contentFormat={(d: (typeof doc)[0]): JSX.Element => (
          <React.Fragment>
            <Item xs={12} style={{ marginBottom: equals(d, doc[doc.length - 1]) ? undefined : '4.5rem' }}>
              {type === 'ftd' ? (
                <FTDDiagnosticCriteriaHistory d={d as IFTD} startEdit={startEdit} view={view} />
              ) : type === 'ppa' ? (
                <PPADiagnosticCriteriaHistory d={d as IPPA} startEdit={startEdit} view={view} />
              ) : (
                <></>
              )}
            </Item>
          </React.Fragment>
        )}
      />
    </HistoryRow>
  );
};

export const StyledHistoryContent = withHistoryContext(StyledHistoryContentComponent);
interface IStyledHistoryProps extends IHistoryContext {
  type: string;
  clinicallyEst?: (d: TParkinsonCriteriaType) => string;
  probable?: (d: Exclude<TParkinsonCriteriaType, IMSA2022>) => string;
  possible?: (d: Exclude<TParkinsonCriteriaType, IMSA2022>) => string;
  checkClinicallyEstablishedMSA?: (d: IMSA2022) => string;
  checkClinicallyProbableMSA?: (d: IMSA2022) => string;
}

export const StyledDescriptionItems = ({
  description: { fields, section, marginLeft },
  key,
  path,
}: {
  description: IDescription;
  key: string;
  path: string;
}): JSX.Element => (
  <div style={{ marginLeft: `${marginLeft}rem` }} key={key}>
    {fields.map((item: string, index: number) => (
      <div key={section + field + index}>{fm(`${path}.${section}.${item}`)}</div>
    ))}
  </div>
);

export const StyledDescriptionField = ({
  sections,
  sectionPath,
  path,
  title,
  sectionTitle,
}: {
  sections: Array<ISection>;
  sectionPath: string;
  path: string;
  title?: string;
  sectionTitle?: string;
}): JSX.Element => (
  <div>
    {title && <div>{fm(title)}</div>}
    {map(
      (section: ISection) => (
        <div style={{ marginTop: `${section.marginTop}rem` }} key={section.section}>
          <SubHeader>{fm(`${sectionPath}.${section.section}${sectionTitle ? `.${sectionTitle}` : ''}`)}</SubHeader>
          {map(
            (d: IDescription) => (
              <StyledDescriptionItems description={d} key={toString(d.fields)} path={path} />
            ),
            section.descriptions,
          )}
        </div>
      ),
      sections,
    )}
  </div>
);
