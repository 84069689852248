import { path } from 'ramda';
import * as React from 'react';
import EventStepper from '../../../../../components/EventStepper';
import { fm } from 'Components/FormatMessage';
import InputHandler from '../../../../../components/InputHandler';
import colors from '../../../../../config/theme/colors';
import { formatPartialDate, isPartialDate } from 'neuro-utils';
import { DiscontinuationDetails } from '../../../components';
import { getLatestDiscontinuation } from '../../../utils';

const DiscontinuationEvents = ({ formData, disabled }: IOwnProps): JSX.Element => {
  const getButtonDisabledMessage = (): JSX.Element | undefined => {
    if (disabled) {
      return fm('vns.addGeneratorFirst');
    }
    if (formData.document.status === 'ended') {
      return fm('vns.discontinuationMessageEnded');
    }
    if (formData.document.status === 'suspended') {
      const discontinuationType = getLatestDiscontinuation(formData.document.discontinuations)?.type;
      if (!discontinuationType) {
        return fm('vns.discontinuationMessageUndefined');
      }
      if (discontinuationType === 'generatorSwitchedOff') {
        return fm('vns.discontinuationMessageSwitchedOff');
      }
      return fm('vns.discontinuationMessageSuspended');
    }
    return undefined;
  };

  return (
    <EventStepper
      name="discontinuations"
      formData={formData}
      stepLabelText={(d: IVNSDiscontinuationEvent): string => formatPartialDate(d.date)}
      stepContent={(d: IVNSDiscontinuationEvent): JSX.Element => <DiscontinuationDetails discontinuation={d} />}
      addNewTextHeader="vns.newDiscontinuation"
      addNewTextButton="vns.newDiscontinuation"
      buttonDisabled={disabled || ['suspended', 'ended'].includes(formData.document.status ?? '')}
      buttonDisabledMessage={getButtonDisabledMessage()}
      /**
       * Discontinuation at index 0 should be the discontinuation under editing (when adding a new discontinuation)
       * Disable saving the discontinuation event until a valid discontinuation type type is selected
       */
      saveDisabled={!path(['discontinuations', 0, 'type'], formData.document)}
      saveDisabledMessage={fm('vns.chooseDiscontinuationTypeFirst')}
      previousEventsTextHeader="vns.discontinuations"
      editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => (
        <React.Fragment>
          <div style={{ marginBottom: '2rem' }}>
            <InputHandler
              type="PartialDate"
              editing={true}
              name="date"
              formData={{
                onChange,
                document: { date: formData.document.discontinuations?.[index]?.date || '' },
              }}
              dateDefault="now"
              isNotCancellable={true}
              dateHook={{
                dateHookFloor: formData.document.date,
              }}
            />
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <div style={{ color: colors.darkGray, marginBottom: '0.5rem' }}>{fm('vns.discontinuationReason')}</div>
            <InputHandler
              type="Radio"
              editing={true}
              name="reason"
              formData={{
                onChange,
                document: { reason: formData.document.discontinuations?.[index]?.reason || '' },
              }}
              options={['technicalCause', 'infection', 'otherSideEffect', 'deteriorationOfSeizureSituation', 'other']}
              optionFormatter={(name: string | number): JSX.Element => fm(`vns.opts.discontinuationReason.${name}`)}
            />
          </div>
          <div style={{ marginBottom: '2rem' }}>
            <div style={{ color: colors.darkGray, marginBottom: '0.5rem' }}>{fm('vns.discontinuationType')}</div>
            <InputHandler
              type="Radio"
              editing={true}
              name="type"
              formData={{
                onChange: onChange,
                document: {
                  type: formData.document.discontinuations?.[index]?.type || '',
                  endDate: formData.document.discontinuations?.[index]?.endDate,
                },
              }}
              options={[
                'generatorSwitchedOff',
                'generatorRemoved',
                'generatorAndPartOfLeadRemoved',
                'generatorAndLeadRemoved',
              ]}
              optionFormatter={(name: string | number): JSX.Element => fm(`vns.opts.discontinuationType.${name}`)}
              dependentFieldsList={(value: TFieldValue): string[] => {
                if (
                  // generatorSwitchedOff changed to generatorRemoval --> endDate is dependent
                  (path(['discontinuations', index, 'type'], formData.document) === 'generatorSwitchedOff' &&
                    ['generatorRemoved', 'generatorAndPartOfLeadRemoved', 'generatorAndLeadRemoved'].includes(
                      typeof value === 'string' ? value : '',
                    )) ||
                  // generatorRemoval changed to generatorSwitchedOff --> endDate is dependent
                  (['generatorRemoved', 'generatorAndPartOfLeadRemoved', 'generatorAndLeadRemoved'].includes(
                    path(['discontinuations', index, 'type'], formData.document) ?? '',
                  ) &&
                    value === 'generatorSwitchedOff')
                ) {
                  return ['endDate'];
                }
                return [];
              }}
              dependentFieldsRemovalWarning={true}
              dependentFieldsRemovalWarningText={fm('vns.discontinuationMessageTypeChange')}
            />
          </div>
          {path(['discontinuations', index, 'type'], formData.document) === 'generatorAndPartOfLeadRemoved' && (
            <div style={{ marginBottom: '2rem' }}>
              <div style={{ color: colors.darkGray, marginBottom: '0.5rem' }}>{fm('vns.lengthOfRemainingLead')}</div>
              <InputHandler
                type="Radio"
                editing={true}
                name="lengthOfRemainingLead"
                formData={{
                  onChange,
                  document: {
                    lengthOfRemainingLead: formData.document.discontinuations?.[index]?.lengthOfRemainingLead || '',
                  },
                }}
                options={['<20mm', '>20mm', 'unknown']}
                optionFormatter={(name: string | number): JSX.Element => fm(`vns.opts.lengthOfRemainingLead.${name}`)}
              />
            </div>
          )}
          <div style={{ marginBottom: '2rem' }}>
            <InputHandler
              type="TextArea"
              editing={true}
              name="additionalInformation"
              formData={{
                onChange,
                document: {
                  additionalInformation: formData.document.discontinuations?.[index]?.additionalInformation || '',
                },
              }}
              placeholder="vns.discontinuationAdditionalInformation"
            />
          </div>
          {isPartialDate(path(['discontinuations', index, 'endDate'], formData.document)) && (
            <div style={{ marginBottom: '2rem' }}>
              <div style={{ color: colors.darkGray, marginBottom: '0.5rem' }}>{fm('vns.discontinuationEndDate')}</div>
              <InputHandler
                type="PartialDate"
                editing={path(['discontinuations', index, 'type'], formData.document) === 'generatorSwitchedOff'}
                name="endDate"
                formData={{
                  onChange,
                  document: {
                    endDate: formData.document.discontinuations?.[index]?.endDate || '',
                  },
                }}
                dateHook={{
                  dateHookFloor: formData.document.discontinuations?.[index]?.date,
                }}
                isNotCancellable={true}
              />
            </div>
          )}
        </React.Fragment>
      )}
    />
  );
};

interface IOwnProps {
  formData: IFormData<IVNS>;
  disabled?: boolean;
}

export default DiscontinuationEvents;
