import * as React from 'react';

import HistoryRow from '../../../../../../components/HistoryRow';
import HistoryRowVerticalItem from '../../../../../../components/HistoryRowVerticalItem';

import { newButton } from '../../../../utils/functions';
import { IHistoryContext, withHistoryContext } from '../../../../../../containers/FormContextHandler';
import { calculateDaysDifference, formatPartialDate } from 'neuro-utils';

const FirstVisitSleepApneaHistory = ({ documents, startEdit, fm = (): string => '' }: IHistoryContext): JSX.Element => {
  const fvsa: ISleepApneaFirstVisit = documents?.filter((d) => d._type === 'sleepApneaFirstVisit')[0];

  const daysDifference = calculateDaysDifference(fvsa?.dateOfReferral, fvsa?.dateOfFirstVisit);

  return (
    <HistoryRow
      headerText={fm('diagnosis.sleepApnea.sleepApneaFirstVisit.title')}
      rowButton={
        startEdit
          ? newButton(
              'sleepApneaFirstVisit',
              startEdit(fvsa ?? {}, 'sleepApneaFirstVisit'),
              'general.edit',
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              fvsa,
            )
          : undefined
      }
    >
      <React.Fragment>
        <HistoryRowVerticalItem
          header={fm('diagnosis.sleepApnea.sleepApneaFirstVisit.referralOrAcute')}
          value={fvsa?.referralOrAcute && fm('diagnosis.sleepApnea.sleepApneaFirstVisit.opts.' + fvsa?.referralOrAcute)}
        />
        {fvsa?.referralOrAcute === 'referral' && (
          <>
            <HistoryRowVerticalItem
              header={fm('diagnosis.sleepApnea.sleepApneaFirstVisit.dateOfReferral')}
              value={fvsa?.dateOfReferral && formatPartialDate(fvsa?.dateOfReferral)}
            />
            <HistoryRowVerticalItem
              header={fm('diagnosis.sleepApnea.sleepApneaFirstVisit.dateOfFirstVisit')}
              value={fvsa?.dateOfFirstVisit && formatPartialDate(fvsa?.dateOfFirstVisit)}
            />
            <HistoryRowVerticalItem
              header={fm('diagnosis.sleepApnea.sleepApneaFirstVisit.delayFromArrivalToFirstVisit')}
              value={daysDifference && daysDifference + ' ' + fm('diagnosis.sleepApnea.days')}
            />
          </>
        )}
      </React.Fragment>
    </HistoryRow>
  );
};

export default withHistoryContext(FirstVisitSleepApneaHistory);
