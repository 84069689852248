import CollapseElem from 'Components/Collapse';
import { Container, Item } from 'Components/Grid';
import HistorySection from 'Components/HistorySection';
import { sortPartialDate } from 'neuro-utils';
import * as React from 'react';
import { IHistoryContext, withHistoryContext } from '../../../../../../containers/FormContextHandler';
import { newButton } from '../../../../utils/functions';
import { TreatmentPeriodEventListItem } from '../components';

const ContraIndicationToTreatmentHistory = ({
  documents = [],
  startEdit,
  fm = (): string => '',
  treatmentPeriod,
  setTreatmentId,
}: IHistoryContext & { treatmentPeriod: ININMTTreatmentPeriod; setTreatmentId: (id: string) => void }): JSX.Element => {
  const treatmentId = treatmentPeriod._id;

  const contraIndicationsToTreatment: Array<IContraIndicationToTreatment> = documents
    .filter((d: any) => d._type === 'contraIndicationsToTreatment' && d.treatmentPeriodId === treatmentId)
    .sort((n1, n2) => sortPartialDate(n2.date, n1.date));

  return (
    <HistorySection
      headerText={
        <span style={{ fontSize: '2.2rem' }}>{fm('diagnosis.ninmt.contraIndicationsToTreatment.title')}</span>
      }
      hasHistoryRows={false}
      newButton={
        startEdit
          ? newButton(
              'contraIndicationsToTreatment',
              (e: React.MouseEvent): void => {
                setTreatmentId(treatmentId);
                startEdit({}, 'contraIndicationsToTreatment')(e);
              },
              'general.new',
              15,
              true,
              treatmentPeriod._editing || !treatmentPeriod?.date,
              'diagnosis.ninmt.treatmentPeriodDisabledTooltip',
            )
          : undefined
      }
    >
      <React.Fragment>
        <Container>
          <Item xs={0} md={3} lg={3} />
          <Item xs={12} md={9} lg={9}>
            {contraIndicationsToTreatment.length > 0 && (
              <div style={{ marginBottom: contraIndicationsToTreatment.length > 1 ? '4.5rem' : undefined }}>
                <TreatmentPeriodEventListItem
                  treatmentPeriodEvent={contraIndicationsToTreatment[0]}
                  startEdit={startEdit}
                  fm={fm}
                />
              </div>
            )}
            {contraIndicationsToTreatment.length > 1 && (
              <CollapseElem
                localeIDs={{
                  showMessage: 'diagnosis.ninmt.contraIndicationsToTreatment.showPastContraIndications',
                  hideMessage: 'diagnosis.ninmt.contraIndicationsToTreatment.hidePastContraIndications',
                }}
                amount={contraIndicationsToTreatment.slice(1).length}
                collapseButtonPosition={'flex-start'}
              >
                {contraIndicationsToTreatment.slice(1).map((e, i, arr) => (
                  <div key={i} style={{ marginBottom: i < arr.length - 1 ? '4.5rem' : undefined }}>
                    <TreatmentPeriodEventListItem treatmentPeriodEvent={e} startEdit={startEdit} fm={fm} />
                  </div>
                ))}
              </CollapseElem>
            )}
          </Item>
        </Container>
      </React.Fragment>
    </HistorySection>
  );
};

export default withHistoryContext(ContraIndicationToTreatmentHistory);
