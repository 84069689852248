import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';

import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const CMAP = ({ documents, startEdit }: IOwnProps): JSX.Element => (
  <HistorySection
    headerText={<FormattedMessage id={'imaging.cmap'} />}
    newButton={
      <DocumentCreationButton
        name="cmap"
        text={'imaging.newcmap'}
        onClick={startEdit({} as ICMAP, 'cmap')}
        width={15}
        height={3}
        fontSize={14}
        alternate={true}
      />
    }
  >
    <HistoryRowListing
      documents={documents}
      headers={<></>}
      contentFormat={(): JSX.Element => <></>}
      makeDate
      makeControls
      historyRowControls={(d: IControlProps) => <HistoryRowControls document={d} startEdit={startEdit} />}
    />
  </HistorySection>
);

interface IOwnProps {
  documents: ICMAP[];
  startEdit: (document: ICMAP, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default CMAP;
