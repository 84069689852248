import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';

import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import { statementStyle } from '../Components/Statement';

const DEXA = ({ documents, startEdit }: IOwnProps): JSX.Element => {
  const showProcedureCodeHeader = documents.some((d) => d.procedureCode && d.procedureCode.length > 0);
  const showStatementHeader = documents.some((d) => d.statement && d.statement.length > 0);

  const showProcedureCode = (d: IMRI) => d.procedureCode && d.procedureCode.length > 0;
  const showStatement = (d: IMRI) => d.statement && d.statement.length > 0;
  return (
    <HistorySection
      headerText={<FormattedMessage id={'imaging.dexa'} />}
      newButton={
        <DocumentCreationButton
          name="dexa"
          text={'imaging.newdexa'}
          onClick={startEdit({} as IDEXA, 'dexa')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            {showProcedureCodeHeader && (
              <Item xs={2}>
                <FormattedMessage id="imaging.procedureCode" />
              </Item>
            )}
            {showStatementHeader && (
              <Item xs={3}>
                <FormattedMessage id="imaging.statementText" />
              </Item>
            )}
          </>
        }
        contentFormat={(d: IDEXA): JSX.Element => (
          <>
            {showProcedureCodeHeader && <Item xs={2}>{showProcedureCode(d) ? d.procedureCode : ''}</Item>}
            {showStatementHeader && (
              <Item xs={3} style={statementStyle}>
                {showStatement(d) ? d.statement : ''}
              </Item>
            )}
          </>
        )}
        makeDate
        makeControls
        historyRowControls={(d: IControlProps) => <HistoryRowControls document={d} startEdit={startEdit} />}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IDEXA[];
  startEdit: (document: IDEXA, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default DEXA;
