import { includes } from 'ramda';
import { sortPartialDate } from 'neuro-utils';

/**
 * Return latest diagnosis document from documents, based on type(s)
 * @param  {string|string[]} type - Type of diagnosis
 * @param  {IDiagnosis[]} documents - Diagnosis documents
 * @returns {IDiagnosis} Diagnosis document
 */
export const lastDiagnosis = (type: string | Array<string | undefined>, documents: Array<IDiagnosis>): IDiagnosis => {
  const arr = (typeof type === 'string' ? [type] : type) as Array<string | undefined>;
  return (
    documents
      ?.filter((d) => includes(d.diagnosis, arr))
      .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
      .reverse()?.[0] || {}
  );
};

/**
 * Count the amount of 'yes', 'no', 'unknown' answers in a document
 * @param  {string[]} fields - Which fields to count answers from
 * @param  {{[key:string]:'yes'|'no'|'unknown'|any}} document - Document
 * @returns {{ yes: number; no: number; unknown: number }} Numbers object
 */
export const countFieldAnswers = (
  fields: string[],
  document?: { [key: string]: 'yes' | 'no' | 'unknown' | any },
): { yes: number; no: number; unknown: number } => {
  const answers = { yes: 0, no: 0, unknown: 0 };
  fields.forEach((f) => {
    if (!document) answers.unknown++;
    else if (document[f] === 'yes') answers.yes++;
    else if (document[f] === 'no') answers.no++;
    else answers.unknown++;
  });
  return answers;
};

export const supportiveCriteria = [
  'beneficialResponceToDopaminergicTherapy',
  'presenceOfLevodopaDyskinesia',
  'restTremorOfALimb',
  'presenceOfOlfactoryLossOrCardiacSympatheticDenervation',
];

export const absoluteExclusionCriteria = [
  'unequivocalCerebellarAbnormalities',
  'downwardVerticalSupranuclearGazePalsy',
  'diagnosisOfProbableBehavioralVariantFrontotemporalDementia',
  'parkinsonianFeaturesRestrictedToTheLowerLimbs',
  'treatmentWithDopamineReceptorBlocker',
  'absenceOfObservableResponseToHighdoseLevodopa',
  'unequivocalCorticalSensoryLoss',
  'normalFunctionalNeuroimagingOfThePresynapticDopaminergicSystem',
  'documentationOfAlternativeConditionKnownToProduceParkinsonism',
];

export const redFlags = [
  'rapidProgressionOfGaitImpairment',
  'completeAbsenceOfProgressionOfMotorSymptoms',
  'earlyBulbarDysfunctionSevereDysphoniaOrDysarthria',
  'inspiratoryRespiratoryDysfunction',
  'severeAutonomicFailureInFirst5yOfDisease',
  'recurrentFalls',
  'disproportionateAnterocollisOrContracturesOfHandOrFeet',
  'absenceOfCommonNonmotorFeaturesOfDisease',
  'otherwiseUnexplainedPyramidalTractSigns',
  'bilateralSymmetricParkinsonism',
];

/**
 * Get clinically established PD status from PD document
 * Diagnosis of Clinically Established PD requires:
 *  1. Absence of absolute exclusion criteria
 *  2. At least two supportive criteria, and
 *  3. No red flags
 *
 * @param  {IPD} d - PD document
 * @returns {'yes'|'no'|'unknown'} Clinically established PD status
 */
export const clinicallyEstablishedPD = (d: IPD): string => {
  const absoluteExclusion =
    countFieldAnswers(absoluteExclusionCriteria, d).yes > 0
      ? 'yes'
      : countFieldAnswers(absoluteExclusionCriteria, d).unknown > 0
        ? 'unknown'
        : 'no';
  const supportive =
    countFieldAnswers(supportiveCriteria, d).yes > 1 &&
    countFieldAnswers(redFlags, d).yes + countFieldAnswers(redFlags, d).unknown === 0
      ? 'yes'
      : countFieldAnswers(supportiveCriteria, d).yes + countFieldAnswers(supportiveCriteria, d).unknown < 2 ||
          countFieldAnswers(redFlags, d).yes > 0
        ? 'no'
        : 'unknown';

  if (d?.parkinsonism === 'no') return 'no';
  if (d?.parkinsonism === 'unknown') return absoluteExclusion === 'yes' ? 'no' : 'unknown';
  if (absoluteExclusion === 'unknown' || supportive === 'unknown') return 'unknown';
  if (absoluteExclusion === 'yes' || supportive === 'no') return 'no';
  if (absoluteExclusion === 'no' && supportive === 'yes') return 'yes';
  return 'unknown';
};

/**
 * Get probable PD status from PD document
 * Diagnosis of Clinically Probable PD requires:
 * 1. Absence of absolute exclusion criteria
 * 2. Presence of red flags counterbalanced by supportive criteria
 * If 1 red flag is present, there must also be at least 1 supportive criterion
 * If 2 red flags, at least 2 supportive criteria are needed
 * No more than 2 red flags are allowed for this category
 *
 * @param  {IPD} d - PD document
 * @returns {'yes'|'no'|'unknown'} Probable PD status
 */
export const probablePD = (d: IPD): string => {
  if (d?.parkinsonism === 'no') return 'no';

  if (clinicallyEstablishedPD(d) === 'yes') return 'yes';

  if (countFieldAnswers(absoluteExclusionCriteria, d).yes > 0) return 'no';
  if (countFieldAnswers(absoluteExclusionCriteria, d).unknown > 0) return 'unknown';

  if (d?.parkinsonism === 'unknown') return 'unknown';

  if (countFieldAnswers(redFlags, d).yes >= 3) return 'no';

  const probable =
    countFieldAnswers(redFlags, d).yes + countFieldAnswers(redFlags, d).unknown <=
    Math.min(2, countFieldAnswers(supportiveCriteria, d).yes)
      ? 'yes'
      : countFieldAnswers(redFlags, d).yes >
          Math.min(2, countFieldAnswers(supportiveCriteria, d).yes + countFieldAnswers(supportiveCriteria, d).unknown)
        ? 'no'
        : 'unknown';

  return probable;
};
