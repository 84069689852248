import * as React from 'react';

import { styled } from '@mui/system';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import { Container, Item } from 'Components/Grid';
import { HistoryTabsContext } from 'Components/HistoryTabs';
import colors from '../../../../../../config/theme/colors';
import { styleDocument } from '../../../../../../config/theme/componentTheme';
import { formatPartialDate } from 'neuro-utils';
import HistoryRowControls from 'Components/HistoryRowControls';
import { isEmpty } from 'ramda';
import DeleteDialog, { IDeleteDetails } from 'Components/HistoryRowControls/DeleteDialog';
import { controlProps } from 'Utility/documentHandling';
import ToolTip from 'Components/ToolTip';

const InlineDiv = styled('div')({
  display: 'inline-flex',
});

const ContainerEvent = styled(Container)({
  width: 'auto !important',
  margin: '0 -2.5rem 0 -2.5rem',
  padding: '0rem 2.5rem',
});

const StyledSubHeader = styled(Item)({
  paddingLeft: '1rem',
  fontWeight: 600,
});

const StyledList = styled('div')({
  '& > div:not(:last-of-type)': {
    marginBottom: '1rem',
  },
});

const RemoveButton = ({ remove, text = 'general.delete', disabled }: IRemoveButton): JSX.Element => {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);

  const historyTabsContext = React.useContext(HistoryTabsContext);

  const { selected, changeSelected } = historyTabsContext;

  const toggleDeleteDialog = (): void => {
    setDeleteDialogOpen(!deleteDialogOpen);
  };

  return (
    <React.Fragment>
      <ActionButtonRounded
        text={text}
        onClick={toggleDeleteDialog}
        disabled={disabled}
        width={10}
        height={3.5}
        fontSize={16}
        colorScheme="error"
      />
      <DeleteDialog
        document={controlProps.reduce((current, key) => {
          (current as { [key: string]: any })[key] = null;
          return current;
        }, {} as IControlProps)}
        open={deleteDialogOpen}
        setOpen={toggleDeleteDialog}
        customDeleteHandler={(details?: IDeleteDetails) => {
          remove(selected, changeSelected, details)();
          toggleDeleteDialog();
        }}
      />
    </React.Fragment>
  );
};

type TChangeFunction = (i: number) => () => void;
type TRemoveFunction = (i: number, change?: TChangeFunction, details?: IDeleteDetails) => () => void;

interface IRemoveButton {
  remove: TRemoveFunction;
  text?: string;
  disabled?: boolean;
}

export const ButtonRow = ({
  remove,
  text,
  disabled,
}: {
  remove: TRemoveFunction;
  text?: string;
  disabled?: boolean;
}): JSX.Element => {
  return (
    <div
      style={{
        width: '100%',
        margin: `0rem -${styleDocument.padding} 0 -${styleDocument.padding}`,
        padding: `2rem ${styleDocument.padding}`,
        borderTop: `1px solid ${colors.gray}`,
      }}
    >
      <Container>
        <Item>
          <RemoveButton remove={remove} text={text} disabled={disabled} />
        </Item>
      </Container>
    </div>
  );
};

export const TreatmentPeriodEventListItem = ({
  treatmentPeriodEvent,
  startEdit,
  fm,
}: ITreatmentPeriodEventListItem): JSX.Element => {
  const type = treatmentPeriodEvent._type;
  switch (type) {
    case 'contraIndicationsToTreatment': {
      const event: IContraIndicationToTreatment = treatmentPeriodEvent;

      const fieldsToMap: Array<keyof typeof event> = (
        [
          'tmsAbsoluteContraIndications',
          'tmsRelativeContraIndications',
          'noRtmsContraIndications',
          'rtmsRegardlessOfContraIndications',
          'rtmsContraIndicationsInformation',
          'dcsAbsoluteContraIndications',
          'noTdcsContraIndications',
          'tdcsRegardlessOfContraIndications',
          'tdcsContraIndicationsInformation',
        ] as Array<keyof typeof event>
      ).filter(
        (f) =>
          event[f] &&
          (['tmsAbsoluteContraIndications', 'tmsRelativeContraIndications', 'dcsAbsoluteContraIndications'].includes(f)
            ? !isEmpty(event[f])
            : true),
      );

      const fieldsToMapByType: { [key: string]: typeof fieldsToMap } = {
        rtms: fieldsToMap.filter((f) => f.includes('tms')),
        tdcs: fieldsToMap.filter((f) => f.includes('dcs')),
      };

      return (
        <React.Fragment>
          <ContainerEvent style={{ marginBottom: '2rem' }}>
            <Item
              xs={9}
              style={{
                fontWeight: 600,
                backgroundColor: colors.lightGray,
                height: 40,
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '1rem',
              }}
            >
              {formatPartialDate(event.date)}
            </Item>
            <Item xs={3} style={{ backgroundColor: colors.lightGray }}>
              {startEdit && (
                <HistoryRowControls
                  document={treatmentPeriodEvent}
                  startEdit={startEdit}
                  newStyle
                  buttonDimensions={{ width: 7 }}
                />
              )}
            </Item>
          </ContainerEvent>
          {Object.keys(fieldsToMapByType)
            .filter((type) => !isEmpty(fieldsToMapByType[type]))
            .map((type, index) => {
              return (
                <React.Fragment key={type}>
                  <ContainerEvent style={{ marginBottom: '2rem', paddingTop: index > 0 ? '2.5rem' : undefined }}>
                    <StyledSubHeader>
                      {fm(`diagnosis.ninmt.contraIndicationsToTreatment.${type}ContraIndications`)}
                    </StyledSubHeader>
                  </ContainerEvent>
                  {fieldsToMapByType[type].map((field, i) => {
                    const value = event[field] as string | string[] | boolean | undefined;
                    return (
                      <React.Fragment key={`${field}${i}`}>
                        <ContainerEvent style={{ marginBottom: '2rem' }}>
                          <Item xs={5} style={{ paddingLeft: '1rem' }}>
                            {fm(`diagnosis.ninmt.contraIndicationsToTreatment.${field}`)}
                          </Item>
                          {!['noRtmsContraIndications', 'noTdcsContraIndications'].includes(field) && (
                            <Item xs={6} style={{ paddingRight: '1rem' }}>
                              {Array.isArray(value) && value.length > 1 ? (
                                <StyledList>
                                  <ul style={{ margin: 0, paddingLeft: '2rem' }}>
                                    {value?.map((v: string, i: number) => (
                                      <li key={`${v}${i}`}>
                                        {fm(`diagnosis.ninmt.contraIndicationsToTreatment.opts.${v}`)}
                                      </li>
                                    ))}
                                  </ul>
                                </StyledList>
                              ) : field === `${type}ContraIndicationsInformation` ? (
                                <Item xs={true} style={{ paddingRight: '2rem' }}>
                                  <span style={{ fontWeight: 600 }}>
                                    {typeof event[field] === 'string' ? (event[field] as string) : ''}
                                  </span>
                                </Item>
                              ) : (
                                <Container style={{ fontWeight: 600 }}>
                                  <Item>
                                    {value && (!Array.isArray(value) || value.length > 0)
                                      ? typeof value === 'boolean'
                                        ? fm('general.yes')
                                        : fm(`diagnosis.ninmt.contraIndicationsToTreatment.opts.${value}`)
                                      : '-'}
                                  </Item>
                                </Container>
                              )}
                            </Item>
                          )}
                        </ContainerEvent>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })}
        </React.Fragment>
      );
    }
    case 'symptomsAndLocations': {
      const event: ISymptomsAndLocations = treatmentPeriodEvent;

      return (
        <React.Fragment>
          <ContainerEvent style={{ marginBottom: '2rem' }}>
            <Item xs={9}>
              <ContainerEvent>
                <Item
                  xs={4}
                  style={{
                    fontWeight: 600,
                    backgroundColor: colors.lightGray,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '1rem',
                  }}
                >
                  {formatPartialDate(event.startDate)}
                  {event.endDate ? ` - ${formatPartialDate(event.endDate)}` : undefined}
                </Item>
                <Item
                  xs={8}
                  style={{
                    fontWeight: 600,
                    backgroundColor: colors.lightGray,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '1rem',
                  }}
                >
                  {event.symptom ? (
                    event.symptom !== 'other' && event.symptomLocation ? (
                      <React.Fragment>
                        {fm(`diagnosis.ninmt.symptomsAndLocations.opts.${event.symptom}`)}
                        {`: ${fm(`diagnosis.ninmt.symptomsAndLocations.opts.${event.symptomLocation}`)}`}
                      </React.Fragment>
                    ) : event.symptom === 'other' && event.symptomDescription && event.symptomDescription.length > 0 ? (
                      <div>
                        {fm(`diagnosis.ninmt.symptomsAndLocations.opts.${event.symptom}`)}
                        <InlineDiv>
                          <ToolTip
                            cursor={event.symptomDescription.length > 50 ? 'Pointer' : 'Default'}
                            description={event.symptomDescription}
                            hover={true}
                            disabled={event.symptomDescription.length <= 50}
                            content={
                              <span>
                                {event.symptomDescription.length > 50
                                  ? `: ${event.symptomDescription.slice(0, 50)}...`
                                  : `: ${event.symptomDescription}`}
                              </span>
                            }
                          />
                        </InlineDiv>
                        {event.mentalSymptom
                          ? ` (${fm('diagnosis.ninmt.symptomsAndLocations.mentalSymptom')})`
                          : undefined}
                      </div>
                    ) : (
                      <div>
                        {fm(`diagnosis.ninmt.symptomsAndLocations.opts.${event.symptom}`)}
                        {event.mentalSymptom
                          ? ` (${fm('diagnosis.ninmt.symptomsAndLocations.mentalSymptom')})`
                          : undefined}
                      </div>
                    )
                  ) : (
                    '-'
                  )}
                </Item>
                {((event.additionalInformation && event.additionalInformation.length > 0) ||
                  event.averageSymptomIntensity ||
                  event.averageSymptomIntensity === 0) && (
                  <ContainerEvent direction={'column'}>
                    {(event.averageSymptomIntensity || event.averageSymptomIntensity === 0) && (
                      <Item style={{ paddingLeft: '1rem', color: colors.tertiaryText }}>
                        {`${fm(
                          'diagnosis.ninmt.symptomsAndLocations.averagePainTitle',
                        )}: ${event.averageSymptomIntensity.toFixed(1)}`}
                      </Item>
                    )}
                    {(event.averageSymptomHarm || event.averageSymptomHarm === 0) && (
                      <Item style={{ paddingLeft: '1rem', color: colors.tertiaryText }}>
                        {`${fm(
                          'diagnosis.ninmt.symptomsAndLocations.averageHarmTitle',
                        )}: ${event.averageSymptomHarm.toFixed(1)}`}
                      </Item>
                    )}
                    {event.additionalInformation && event.additionalInformation.length > 0 && (
                      <Item style={{ paddingLeft: '1rem', color: colors.tertiaryText }}>
                        {`${fm('diagnosis.ninmt.symptomsAndLocations.additionalInformation')}: ${
                          event.additionalInformation
                        }`}
                      </Item>
                    )}
                  </ContainerEvent>
                )}
              </ContainerEvent>
            </Item>
            <Item xs={3} style={{ backgroundColor: colors.lightGray, height: 40 }}>
              {startEdit && (
                <HistoryRowControls
                  document={treatmentPeriodEvent}
                  startEdit={startEdit}
                  buttonDimensions={{ width: 7 }}
                  newStyle
                />
              )}
            </Item>
          </ContainerEvent>
        </React.Fragment>
      );
    }
    default:
      return <></>;
  }
};

interface ITreatmentPeriodEventListItem {
  treatmentPeriodEvent: IContraIndicationToTreatment | ISymptomsAndLocations;
  startEdit?: (document: { _id?: string }, name?: string) => (e: React.MouseEvent) => void;
  fm: (id: string) => string;
}
