import * as React from 'react';
import FormRow from '../../../../../../../../components/FormRow';
import FormSectionHistoryAcceptor from '../../../../../../../../components/FormSectionHistoryAcceptor';
import InputHandler from '../../../../../../../../components/InputHandler';
import { fm } from 'Components/FormatMessage';

const generalOptionFormatter = (s?: React.ReactText | boolean): JSX.Element => fm(`general.${s || 'empty'}`);

const formFields = [
  'opticNeuritis',
  'acuteMyelitis',
  'areaPostremaSyndrome',
  'acuteBrainstemSyndrome',
  'symptomaticNarcolepsy',
  'symptomaticCerebralSyndrome',
];

const setAllUnknown = (formData: IOwnProps['formData'], viewing: boolean): void => {
  formFields.forEach((f) => {
    if (!viewing && !(formData.document as { [key: string]: any })[f]) {
      formData?.onChange?.({
        [f]: 'unknown',
      });
    }
  });
};

const MakeFormRow = ({
  title,
  description,
  name,
  formData,
  viewing,
  leftMargin,
  bottomMargin = true,
}: {
  title: string;
  description?: JSX.Element;
  name: string;
  formData: IOwnProps['formData'];
  viewing: boolean;
  leftMargin?: number;
  bottomMargin?: boolean;
}): JSX.Element => (
  <FormRow
    title={title}
    description={description}
    headerWidth={6}
    bottomMargin={bottomMargin}
    headerStyle={{ marginLeft: `${leftMargin ?? 0}rem` }}
  >
    <InputHandler
      editing={!viewing}
      formData={formData}
      name={name}
      type="RadioButtonRow"
      preset="yesNoUnknown"
      height={3.5}
      width={9}
      optionFormatter={generalOptionFormatter}
    />
  </FormRow>
);

const NMOSDCoreClinicalCharacteristics = ({ documents, formData, view, editingID }: IOwnProps): JSX.Element => {
  const isViewing = !editingID && !!view?.viewing;
  const docID = editingID ?? view?.viewing;

  React.useEffect(() => {
    setAllUnknown(formData, isViewing);
  }, [formData, isViewing]);

  return (
    <React.Fragment>
      <FormSectionHistoryAcceptor
        formData={formData}
        documentID={docID}
        documents={documents}
        optionFormatter={generalOptionFormatter}
        header="diagnosis.nmosdDiagnosticCriteria.coreClinicalCharacteristics.title"
        hideCopyButton={isViewing}
      >
        {{
          opticNeuritis: {
            element: (
              <MakeFormRow
                title="diagnosis.nmosdDiagnosticCriteria.coreClinicalCharacteristics.opticNeuritis"
                name="opticNeuritis"
                formData={formData}
                viewing={isViewing}
              />
            ),
          },
          acuteMyelitis: {
            element: (
              <MakeFormRow
                title="diagnosis.nmosdDiagnosticCriteria.coreClinicalCharacteristics.acuteMyelitis"
                name="acuteMyelitis"
                formData={formData}
                viewing={isViewing}
              />
            ),
          },
          areaPostremaSyndrome: {
            element: (
              <MakeFormRow
                title="diagnosis.nmosdDiagnosticCriteria.coreClinicalCharacteristics.areaPostremaSyndrome"
                name="areaPostremaSyndrome"
                formData={formData}
                viewing={isViewing}
              />
            ),
          },
          acuteBrainstemSyndrome: {
            element: (
              <MakeFormRow
                title="diagnosis.nmosdDiagnosticCriteria.coreClinicalCharacteristics.acuteBrainstemSyndrome"
                name="acuteBrainstemSyndrome"
                formData={formData}
                viewing={isViewing}
              />
            ),
          },
          symptomaticNarcolepsy: {
            element: (
              <MakeFormRow
                title="diagnosis.nmosdDiagnosticCriteria.coreClinicalCharacteristics.symptomaticNarcolepsy"
                name="symptomaticNarcolepsy"
                formData={formData}
                viewing={isViewing}
              />
            ),
          },
          symptomaticCerebralSyndrome: {
            element: (
              <MakeFormRow
                title="diagnosis.nmosdDiagnosticCriteria.coreClinicalCharacteristics.symptomaticCerebralSyndrome"
                name="symptomaticCerebralSyndrome"
                formData={formData}
                viewing={isViewing}
                bottomMargin={false}
              />
            ),
          },
        }}
      </FormSectionHistoryAcceptor>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents?: Array<INMOSDCriteria>;
  formData: IFormData<INMOSDCriteria>;
  view?: IView;
  editingID?: string;
}

export default NMOSDCoreClinicalCharacteristics;
