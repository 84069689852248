import { INeuroContactInfo } from 'neuro-data-structures';
import { fetchWithOptions } from '../../utility/fetch';
import { getJWTData, parseJWTFromCookie } from '../../utility/jwtAuthTools';
import { makeLog } from '../../utility/logger';
import {
  convertPatientDataToUI,
  convertUIExitusDataToApiPatchFormat,
  convertUIPatientDataToApiPatchFormat,
  convertUIPatientDataToApiPostFormat,
  IDelegate,
  IPatientDataForm,
} from './util';

/**
 * Fetch patient data
 * @returns {IPatient|void} IPatient | null
 */
export const fetchPatientData = async (): Promise<IPatientUI | null> => {
  const { orgid, patientid } = getJWTData() || { orgid: null, patientid: null };
  if (!orgid || !patientid) return null;

  return fetchWithOptions(
    `/api/patient/v2/data/${orgid}/${patientid}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'GET' },
  )
    .then((res) => {
      if (res.status === 200) return res.json();
      else throw res;
    })
    .then((data: IPatientAPI) => {
      return convertPatientDataToUI(data);
    })
    .catch((e) => {
      makeLog('Error', { name: 'Get patient data', message: 'Response code not 200' }, e);
      return null;
    });
};

/**
 * Update patient data
 * @param {IPatient} data - Patient data
 * @returns {Promise<boolean|null>} if patch was successful
 */
export const patchPatient = async (
  data: Partial<IPatientUI> & { contactNotAllowed?: boolean | null },
): Promise<boolean | null> => {
  const { orgid, patientid } = getJWTData() || { orgid: null, patientid: null };
  if (!orgid || !patientid) return null;

  return fetchWithOptions(
    `/api/patient/v2/data/${orgid}/${patientid}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'PATCH', body: JSON.stringify(convertUIPatientDataToApiPatchFormat(data)) },
  )
    .then((res: Response) => {
      if (res.status === 200) return true;
      else throw res;
    })
    .catch((e) => {
      makeLog('Error', { name: 'Profile data save', message: 'Response code not 200' }, e);
      return null;
    });
};

/**
 * Update patient exitus data
 * @param {TExitus} data - Exitus data
 * @returns {Promise<boolean|null>} if patch was successful
 */
export const patchPatientExitus = async (data: TExitusUI): Promise<boolean | null> => {
  const { orgid, patientid } = getJWTData() || { orgid: null, patientid: null };
  if (!orgid || !patientid) return null;

  return fetchWithOptions(
    `/api/patient/v2/exitus/${orgid}/${patientid}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'PATCH', body: JSON.stringify(convertUIExitusDataToApiPatchFormat(data)) },
  )
    .then((res: Response) => {
      if (res.status === 200) return true;
      else throw res;
    })
    .catch((e) => {
      makeLog('Error', { name: 'Exitus data save', message: 'Response code not 200' }, e);
      return false;
    });
};

/**
 * Create new patient
 * @param {Partial<IPatientUI>} data - Most likely incomplete patient data
 * @returns {Promise<Response|null>} if creation was successful
 */
export const createPatient = async (data: IPatientDataForm): Promise<Response | null> => {
  const { orgid } = getJWTData() || { orgid: null };
  if (!orgid) return null;

  return fetchWithOptions(
    `/api/patient/v2/patient/${orgid}`,
    { neurojwt: parseJWTFromCookie(), jmr: 'true' },
    { method: 'POST', body: JSON.stringify(convertUIPatientDataToApiPostFormat(data)) },
  )
    .then((res: Response) => {
      if (res.status === 200) return res;
      else throw res;
    })
    .catch((e) => {
      makeLog('Error', { name: 'Patient creation', message: 'Response code not 200' }, e);
      return null;
    });
};

/**
 * Fetch patient grants
 * @returns {TNeuroGrant[] | null} List of grants or null
 */
export const fetchPatientGrants = async (): Promise<TNeuroGrant[] | null> => {
  const { orgid, patientid } = getJWTData() || {};
  if (!orgid || !patientid) return null;

  return fetchWithOptions(
    `/api/patient/v2/grant/${orgid}/${patientid}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'GET' },
  )
    .then((res) => {
      if (res.status === 200) return res.json();
      else throw res;
    })
    .then((data: TNeuroGrant[]) => {
      return data;
    })
    .catch((e) => {
      makeLog('Error', { name: 'Get patient grants', message: 'Response code not 200' }, e);
      return null;
    });
};

/**
 * Create new grant
 * @param {TNeuroGrant['granteeOrg']} granteeOrg - Organization to which the grant is given
 * @param {TNeuroGrant['flags']} flags - Which flags are given
 * @returns {TNeuroGrant | null} Full grant data or null
 */
export const createGrant = async (
  granteeOrg: TNeuroGrant['granteeOrg'],
  allow_write: TNeuroGrant['allow_write'],
  allow_read: TNeuroGrant['allow_read'],
): Promise<TNeuroGrant | null> => {
  const { orgid, patientid } = getJWTData() || {};
  if (!orgid || !patientid) return null;

  const body = {
    granteeGroup: 'FI',
    granteeOrg,
    allow_write,
    allow_read,
  };

  return fetchWithOptions(
    `/api/patient/v2/grant/${orgid}/${patientid}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'POST', body: JSON.stringify(body) },
  )
    .then((res: Response) => {
      if (res.status === 200) return res.json();
      else throw res;
    })
    .then((res: TNeuroGrant) => {
      return res;
    })
    .catch((e) => {
      makeLog('Error', { name: 'Grant creation', message: 'Response code not 200' }, e);
      return null;
    });
};

/**
 * Update existing grant
 * @param {TNeuroGrant['id']} id - Grant id
 * @param {{ granteeGroup?: TNeuroGrant['granteeGroup']; granteeOrg?: TNeuroGrant['granteeOrg']; flags?: TNeuroGrant['flags']; }} data - Grant data
 * @returns {TNeuroGrant | null} Updated grant data or null
 */
export const updateGrant = async (
  id: TNeuroGrant['id'],
  data: {
    granteeGroup?: TNeuroGrant['granteeGroup'];
    granteeOrg?: TNeuroGrant['granteeOrg'];
    allow_write?: TNeuroGrant['allow_write'];
    allow_read?: TNeuroGrant['allow_read'];
  },
): Promise<TNeuroGrant | null> => {
  const { orgid, patientid } = getJWTData() || {};
  if (!orgid || !patientid) return null;

  return fetchWithOptions(
    `/api/patient/v2/grant/${orgid}/${patientid}/${id}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'PUT', body: JSON.stringify(data) },
  )
    .then((res: Response) => {
      if (res.status === 200) return res.json();
      else throw res;
    })
    .then((res: TNeuroGrant) => {
      return res;
    })
    .catch((e) => {
      makeLog('Error', { name: 'Grant update', message: 'Response code not 200' }, e);
      return null;
    });
};

/**
 * Delete existing grant
 * @param {TNeuroGrant['id']} id - Grant id
 * @returns {TNeuroGrant['id'] | null} Grant id or null
 */
export const deleteGrant = async (id: TNeuroGrant['id']): Promise<TNeuroGrant['id'] | null> => {
  const { orgid, patientid } = getJWTData() || {};
  if (!orgid || !patientid) return null;

  return fetchWithOptions(
    `/api/patient/v2/grant/${orgid}/${patientid}/${id}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'DELETE' },
  )
    .then((res: Response) => {
      if (res.status === 200) return id;
      else throw res;
    })
    .catch((e) => {
      makeLog('Error', { name: 'Grant deletion', message: 'Response code not 200' }, e);
      return null;
    });
};

/**
 * Fetch patient delegates
 *
 */
export const fetchPatientDelegates = async (): Promise<IDelegate[]> => {
  return fetchWithOptions(`/api/delegates/v1`, { neurojwt: parseJWTFromCookie() }, { method: 'GET' })
    .then((res) => {
      if (res.status === 200) return res.json();
      else throw res;
    })
    .catch((e) => {
      makeLog('Error', { name: 'Get patient delegates', message: 'Response code not 200' }, e);
      return null;
    });
};

/**
 * Fetch patient contact info
 *
 */
export const fetchPatientContactInfo = async (): Promise<{
  contactNotAllowed: boolean;
  contacts: INeuroContactInfo[];
}> => {
  return fetchWithOptions(`/api/patient/v2/contacts`, { neurojwt: parseJWTFromCookie() }, { method: 'GET' })
    .then((res) => {
      if (res.status === 200) return res.json();
      else throw res;
    })
    .catch((e) => {
      makeLog('Error', { name: 'Get patient contact info', message: 'Response code not 200' }, e);
      return null;
    });
};

/**
 * Post a new patient contact info
 * @param {INeuroUIContactInfo} data - Contact info data
 * @returns {Promise<INeuroContactInfo|null>} Created contact info
 */
export const postPatientContactInfo = async (data: INeuroUIContactInfo): Promise<INeuroContactInfo | null> => {
  const { orgid, patientid } = getJWTData() || { orgid: null, patientid: null };
  if (!orgid || !patientid) return null;

  return fetchWithOptions(
    `/api/patient/v2/contacts`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'POST', body: JSON.stringify(data) },
  )
    .then((res: Response) => {
      if (res.status === 200) return res.json();
      else throw res;
    })
    .then((res: INeuroContactInfo) => {
      return res;
    })
    .catch((e) => {
      makeLog('Error', { name: 'Patient Contact info POST', message: 'Response code not 200' }, e);
      return null;
    });
};

/**
 * Delete existing Patient contact info
 * @param {INeuroContactInfo['id']} id - Contact info id
 * @returns {INeuroContactInfo['id'] | null} Contact info id or null
 */
export const deletePatientContactInfo = async (
  id: INeuroContactInfo['id'],
): Promise<INeuroContactInfo['id'] | null> => {
  const { orgid, patientid } = getJWTData() || {};
  if (!orgid || !patientid) return null;

  return fetchWithOptions(`/api/patient/v2/contacts/${id}`, { neurojwt: parseJWTFromCookie() }, { method: 'DELETE' })
    .then((res: Response) => {
      if (res.status === 200) return id;
      else throw res;
    })
    .catch((e) => {
      makeLog('Error', { name: 'Patient contact info deletion', message: 'Response code not 200' }, e);
      return null;
    });
};
