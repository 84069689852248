import * as React from 'react';
import { Container, Item } from 'Components/Grid';

const DosageFormat = ({ header, content }: IDosageFormatProps): JSX.Element => (
  <Container alignItems="center" style={{ paddingBottom: '1.5rem' }}>
    <Item xs={2}>{header}</Item>
    <Item xs={10}>{content}</Item>
  </Container>
);

interface IDosageFormatProps {
  header?: JSX.Element | string;
  content?: JSX.Element;
}

export default DosageFormat;
