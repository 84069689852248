import * as React from 'react';
import { path } from 'Utility/ramdaReplacement';
import { v4 as uuidv4 } from 'uuid';

const styles = {
  all: { userSelect: 'none' as const, display: 'block' },
  a: { fill: '#f1f2f6', stroke: '#045A8B', strokeWidth: 0.5 },
  b: { fill: '#F2F3F8', stroke: '#045A8B', strokeWidth: 0.5 },
  c: { fill: 'none', stroke: '#355b88', strokeWidth: 0.25, strokeMiterlimit: 10 },
  plus: { fontSize: 30, fill: 'red' },
  off: { fontSize: 18 },
};

const GeneratorSetting = ({ editable, formData, referenceData, name }: IOwnProps): JSX.Element => {
  const { document, onChange } = referenceData ? referenceData : formData;

  const id = uuidv4();
  // set data for re-rendering purpose
  const [data, setData] = React.useState<string>();
  const [clearSettings, setClearSettings] = React.useState<'right' | 'left' | undefined>(undefined);
  const rightGeneratorSetting = path(['rightGeneratorSetting'], document);
  const leftGeneratorSetting = path(['leftGeneratorSetting'], document);

  // Data will be rendered once component has been instantiated
  React.useEffect(() => {
    setData(path([name], document) as string);
  }, [document, name]);

  // Cycle data to the next value when clicked
  const nextValue = (cur?: string): string | undefined => {
    return cur === 'plus' ? undefined : 'plus';
  };

  // Save new status on each click
  const svgClick = (e: React.MouseEvent<SVGSVGElement>): void => {
    const target = e.target as HTMLDivElement;
    const targetID = target.getAttribute('id');

    // Distinguish svg clickable zones by form field name and document id
    if (targetID?.substr(0, name.length + id.length) === name + id) {
      // Get data from redux store and revolve it
      const nextData = nextValue(path([name], document) as string);

      // Re-rendering
      setData(nextData);

      // Storing data
      onChange && onChange({ [name]: nextData });
      const side = /^right/.test(name ?? '') ? 'right' : 'left';
      setClearSettings(side);
    }
  };

  // Hack to make sure the lead settings are cleared
  React.useEffect(() => {
    if (clearSettings === 'right' && onChange && rightGeneratorSetting === data) {
      // Changing generator polarity, leads settings will be reseted
      onChange({ rightSettings: {} });
      setClearSettings(undefined);
    }
    if (clearSettings === 'left' && onChange && leftGeneratorSetting === data) {
      // Changing generator polarity, leads settings will be reseted
      onChange({ leftSettings: {} });
      setClearSettings(undefined);
    }
  }, [data, rightGeneratorSetting, leftGeneratorSetting, clearSettings]);

  return (
    <React.Fragment>
      <svg
        viewBox={`-5 0 80 120`}
        width={80}
        height={120}
        onClick={editable ? svgClick : undefined}
        preserveAspectRatio="xMidYMid meet"
        style={styles.all}
      >
        <path
          style={styles.a}
          d="M385.9,262.4v53c0,13.5,10.4,34.2,36.3,34.2,20.8,0,33.5-20.8,33.8-31.8.2-6.9-.1-32.9,0-62.2,0-4.2-11.1-8.8-37.6-9.1S386.1,256.3,385.9,262.4Z"
          transform="translate(-385.7 -246.3)"
          id={`${name}${id}`}
        />
        <line style={styles.b} x1="0.3" y1="16.6" x2="70.3" y2="16.6" />
        <polyline style={styles.b} points="68.3 6.2 59.1 6.2 59.1 5 22.7 5 22.7 12.2 59.1 12.2 59.1 11 70.3 11" />
        <rect style={styles.c} x="24.4" y="6.6" width="1.9" height="4.09" />
        <rect style={styles.c} x="26.3" y="6.6" width="1.9" height="4.09" />
        <rect style={styles.c} x="28.1" y="6.6" width="1.9" height="4.09" />
        <rect style={styles.c} x="30" y="6.6" width="1.9" height="4.09" />
        <rect style={styles.c} x="31.9" y="6.6" width="1.9" height="4.09" />
        <rect style={styles.c} x="33.7" y="6.6" width="1.9" height="4.09" />
        <rect style={styles.c} x="35.6" y="6.6" width="1.9" height="4.09" />
        <rect style={styles.c} x="37.4" y="6.6" width="1.9" height="4.09" />
        <rect style={styles.c} x="39.3" y="6.6" width="1.9" height="4.09" />
        <rect style={styles.c} x="41.2" y="6.6" width="1.9" height="4.09" />
        <rect style={styles.c} x="43" y="6.6" width="1.9" height="4.09" />
        <rect style={styles.c} x="44.9" y="6.6" width="1.9" height="4.09" />
        <rect style={styles.c} x="46.8" y="6.6" width="1.9" height="4.09" />
        <rect style={styles.c} x="48.6" y="6.6" width="1.9" height="4.09" />
        <rect style={styles.c} x="50.5" y="6.6" width="1.9" height="4.09" />
        <rect style={styles.c} x="52.3" y="6.6" width="1.9" height="4.09" />
        <rect style={styles.c} x="54.2" y="6.6" width="1.9" height="4.09" />
        <rect style={styles.c} x="56.1" y="6.6" width="1.9" height="4.09" />
        {data === 'plus' ? (
          <text id={`${name}${id}`} style={styles.plus} textAnchor="middle" x="36" y="62">
            +
          </text>
        ) : (
          <text id={`${name}${id}`} style={styles.off} textAnchor="middle" x="36" y="60">
            OFF
          </text>
        )}
      </svg>
    </React.Fragment>
  );
};

interface IOwnProps {
  editable: boolean;
  formData: IFormData<IDBS>;
  referenceData?: IOwnProps['formData'];
  name: string;
}

export default GeneratorSetting;
