import { path } from 'ramda';
import * as React from 'react';
import { Navigate } from 'react-router-dom';

import RelapseForm from './Form';
import HistoryRowData from './HistoryRowData';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import HistoryRow from '../../../components/HistoryRow';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import HistoryRowControls from '../../../components/HistoryRowControls';

import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import DocumentCreationButton from '../../../components/DocumentCreationButton';

const Relapse = ({ documents }: IOwnProps): JSX.Element => {
  const [redirect, setRedirect] = React.useState<boolean>(false);

  const toggleRedirect = (): void => {
    setRedirect(!redirect);
  };

  return (
    <FormEditingHandler name="relapse" documents={documents}>
      {(...args): JSX.Element => {
        const [editing, startEdit, formData, , saveDraft] = args;
        return (
          <React.Fragment>
            <DocumentWrapper>
              <DocumentHeader
                name={'relapse'}
                headerId={'relapse.title'}
                editing={editing}
                editButtons={
                  <div>
                    <DocumentCreationButton name="relapse" text={'general.new'} onClick={startEdit({})} />
                  </div>
                }
              />
              {!editing && documents
                ? documents
                    .sort((n1, n2) => n1._cdate - n2._cdate)
                    .sort((n1, n2) => sortPartialDate(n1.startDate, n2.startDate))
                    .reverse()
                    .map((r: IRelapse) => (
                      <HistoryRow
                        headerText={r.startDate ? formatPartialDate(r.startDate) : undefined}
                        key={r._id}
                        rowButton={<HistoryRowControls document={r} startEdit={startEdit} />}
                        controlsMargin={false}
                      >
                        <HistoryRowData document={r} />
                      </HistoryRow>
                    ))
                : undefined}
              {editing ? (
                <RelapseForm
                  document={path([0], documents)}
                  editing={editing}
                  formData={formData}
                  saveDraft={saveDraft}
                  toggleRedirect={toggleRedirect}
                />
              ) : undefined}
            </DocumentWrapper>
            {redirect ? <Navigate to={{ pathname: '/medication' }} state={{ relocate: 'medication' }} /> : undefined}
          </React.Fragment>
        );
      }}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: IRelapse[];
}

export default Relapse;
