import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';

import { withFormContext, IFormContext } from '../../../../../containers/FormContextHandler';

const PeriodWithoutMedicationForm = ({
  editing,
  formData,
  fm,
}: IFormContext<IPeriodWithoutMedication>): JSX.Element => {
  const { document } = formData;
  const locPath = 'medication.periodWithoutMedication';

  return (
    <>
      <FormSection>
        <FormRow title="medication.startDate">
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="startDate"
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
            dateHook={{ dateHookCeiling: document.endDate }}
          />
        </FormRow>
        <FormRow title={locPath + '.reason'}>
          <InputHandler
            type="Radio"
            editing={!!editing}
            name="reason"
            formData={formData}
            options={[
              'SPMS',
              'PPMS',
              'symptomlessDisease',
              'pregnancy',
              'planningPregnancy',
              'noReimbursement',
              'patientRefusal',
              'other',
            ]}
            optionFormatter={(o: string | number): string => fm(locPath + '.opts.' + o)}
          />
        </FormRow>
        <FormRow title={locPath + '.reasonOther'} condition={document.reason === 'other'}>
          <InputHandler
            type="TextField"
            editing={!!editing}
            name="reasonOther"
            formData={formData}
            placeholder={locPath + '.reasonOtherPlaceholder'}
          />
        </FormRow>
        <FormRow title={locPath + '.endDate'}>
          <InputHandler
            type="PartialDate"
            editing={!!editing}
            name="endDate"
            formData={formData}
            dateHook={{ dateHookFloor: document.startDate }}
          />
        </FormRow>
      </FormSection>
    </>
  );
};

export default withFormContext(PeriodWithoutMedicationForm);
