import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import { isPartialDate, partialDateToValue } from 'neuro-utils';
import ToolTip from '../../../../../components/ToolTip';

const PregnancyForm = ({ documents, editing, formData }: IOwnProps): JSX.Element => {
  const [warningOpen, setWarningOpen] = React.useState<boolean>(false);
  const isMountRender = React.useRef<boolean>(true);

  const sleep = (milliseconds: number): any => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const isDateOverlap = React.useCallback(
    (date?: PartialDate): boolean => {
      if (!isPartialDate(date)) return false;
      const pregnancies = documents && documents.filter((pregnancy) => pregnancy._editing === false);

      return pregnancies.length > 0
        ? pregnancies.some((p): boolean => {
            if (partialDateToValue(p.date) === partialDateToValue(date)) {
              return true;
            }
            if (isPartialDate(p.endDate)) {
              return partialDateToValue(p.endDate) > partialDateToValue(date) &&
                partialDateToValue(p.date) < partialDateToValue(date)
                ? true
                : false;
            }
            return partialDateToValue(p.date) > partialDateToValue(date) ? false : true;
          })
        : false;
    },
    [documents],
  );

  React.useEffect(() => {
    if (isMountRender.current) {
      isMountRender.current = false;
      return;
    }
    setWarningOpen(isDateOverlap(formData.document.date));
  }, [formData.document.date, isDateOverlap]);

  React.useEffect(() => {
    warningOpen
      ? sleep(6000)
          .then(() => {
            setWarningOpen(false);
          })
          .catch(() => {
            setWarningOpen(false);
          })
      : undefined;
  }, [warningOpen]);

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="comorbidity.pregnancyStartDate">
          <ToolTip
            title={<FormattedMessage id="general.checkDates" />}
            description={<FormattedMessage id={'comorbidity.pregnancyAlreadyExists'} />}
            content={<div style={{ width: '20rem' }}></div>}
            placement={'top'}
            open={warningOpen}
          />
          <InputHandler
            type="PartialDate"
            name="date"
            editing={editing}
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
            dateHook={{ dateHookCeiling: formData.document.endDate }}
          />
        </FormRow>
        <FormRow title="comorbidity.pregnancyEndDate">
          <InputHandler
            type="PartialDate"
            name="endDate"
            editing={editing}
            formData={formData}
            dateHook={{ dateHookFloor: formData.document.date }}
          />
        </FormRow>
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<IPregnancy>;
  editing?: boolean;
  formData: IFormData<IPregnancy>;
}

export default PregnancyForm;
