import * as React from 'react';
import { Item } from '../../../../../../../components/Grid';
import HistoryRow from '../../../../../../../components/HistoryRow';
import HistoryRowControls from '../../../../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../../../../components/HistoryRowListing';
import { IHistoryContext, withHistoryContext } from '../../../../../../../containers/FormContextHandler';
import { formatPartialDate, sortPartialDate } from 'neuro-utils';
import { newButton } from '../../../../../utils/functions';
import {
  diagnosisCriteriaAreFullfilledWithAQP4,
  diagnosisCriteriaAreFullfilledWithoutAQP4,
} from '../../../../../utils/NMOSD';

const formatCriteria = (d: INMOSDCriteria, fm: (id: string) => string): JSX.Element | string => {
  const withAQP4 = diagnosisCriteriaAreFullfilledWithAQP4(d);
  const withoutAQP4 = diagnosisCriteriaAreFullfilledWithoutAQP4(d);
  if (d.AQP4IgGResult === 'negative' && withoutAQP4 === 'yes')
    return (
      <div>
        {fm('general.yes')} {fm('diagnosis.nmosdDiagnosticCriteria.history.withoutAQP4')}
      </div>
    );
  if (d.AQP4IgGResult === 'positive' && withAQP4 === 'yes')
    return (
      <div>
        {fm('general.yes')} {fm('diagnosis.nmosdDiagnosticCriteria.history.withAQP4')}
      </div>
    );
  if ((d.AQP4IgGResult === 'negative' && withoutAQP4 === 'no') || (d.AQP4IgGResult === 'positive' && withAQP4 === 'no'))
    return <div>{fm('general.no')}</div>;
  return fm('general.unknown');
};

const NMOSDDiagnosticCriteriaHistory = ({
  documents = [],
  startEdit,
  view,
  fm = (): string => '',
}: IHistoryContext): JSX.Element => {
  const nmosdCriteria: Array<INMOSDCriteria> = documents
    .filter((d) => d._type === 'nmosdDiagnosticCriteria')
    .sort((d1: INMOSDCriteria, d2: INMOSDCriteria) => sortPartialDate(d1.date, d2.date))
    .reverse() as Array<INMOSDCriteria>;
  return (
    <HistoryRow
      headerText={fm('diagnosis.nmosdDiagnosticCriteria.history.title')}
      rowButton={
        startEdit
          ? newButton('nmosdDiagnosticCriteria', startEdit({}, 'nmosdDiagnosticCriteria'), 'general.new', 15)
          : undefined
      }
    >
      <HistoryRowListing
        documents={nmosdCriteria}
        headers={
          <>
            <Item xs={2}>{fm('general.date')}</Item>
            <Item xs={3}>{fm('diagnosis.nmosdDiagnosticCriteria.history.usedCriteria')}</Item>
            <Item xs={7}>{fm('diagnosis.nmosdDiagnosticCriteria.history.criteriasAreFullfilled')}</Item>
          </>
        }
        contentFormat={(d: INMOSDCriteria): JSX.Element => (
          <>
            <Item xs={2} style={{ fontWeight: '600' }}>
              {d.date && formatPartialDate(d.date)}
            </Item>
            <Item xs={3}>{fm('diagnosis.nmosdDiagnosticCriteria.history.wingerchuk')}</Item>
            <Item xs={4}>{formatCriteria(d, fm)}</Item>
            <Item xs={3}>
              {startEdit ? <HistoryRowControls document={d} startEdit={startEdit} view={view} /> : null}
            </Item>
          </>
        )}
      />
    </HistoryRow>
  );
};

export default withHistoryContext(NMOSDDiagnosticCriteriaHistory);
