import { filter, find } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import UPDRSForm from './Form';
import { docType, TDocument } from '../utils';

import DBSEffectHistory from './HistoryRowData/DBSEffect';
import UPDRSIIIHistory from './HistoryRowData/III';
import LevodopatestHistory from './HistoryRowData/Levodopatest';
import UPDRSVHistory from './HistoryRowData/V';

const fm = (id: string, values?: { [key: string]: string | number | null | JSX.Element }): JSX.Element => (
  <FormattedMessage id={id} values={values || undefined} />
);

const UPDRSHistory = (
  documents: Array<TDocument>,
  startEdit: (document: TAnyObject) => (e: React.MouseEvent<Element, MouseEvent>) => void,
  view?: IView,
): JSX.Element => {
  const IIIDocs = documents && filter((d) => d._type === 'updrs_iii', documents);
  const VDocs = documents && filter((d) => d._type === 'updrs_v', documents);
  const LevodopaDocs = documents && filter((d) => d._type === 'levodopatest', documents);
  const DBSEffectDocs = documents && filter((d) => d._type === 'dbsEffect', documents);
  return (
    <React.Fragment>
      <UPDRSVHistory documents={VDocs as IUPDRSV[]} startEdit={startEdit} view={view} />

      <UPDRSIIIHistory documents={IIIDocs as IUPDRSIII[]} startEdit={startEdit} view={view} />

      <LevodopatestHistory
        documents={LevodopaDocs as ILevodopatest[]}
        allDocuments={documents}
        startEdit={startEdit}
        view={view}
      />

      <DBSEffectHistory
        documents={DBSEffectDocs as IDBSEffect[]}
        allDocuments={documents}
        startEdit={startEdit}
        view={view}
      />
    </React.Fragment>
  );
};

const UPDRS = ({ documents }: IOwnProps): JSX.Element => (
  <FormEditingHandler name="updrs" documents={documents}>
    {(
      editing,
      startEdit,
      formData,
      view?: IView,
      _saveDraft?: (id: string) => (event: React.MouseEvent) => void,
      setSubDocuments?: (subDocuments: { name: string; id: string }[]) => void,
    ): JSX.Element => (
      <DocumentWrapper>
        <DocumentHeader
          name={'updrs'}
          headerId={
            docType(editing, documents, view?.viewing) === 'updrs_iii'
              ? 'updrs.updrsIII'
              : docType(editing, documents, view?.viewing) === 'updrs_v'
                ? 'updrs.updrsV'
                : docType(editing, documents, view?.viewing) === 'levodopatest'
                  ? 'updrs.levodopatest'
                  : docType(editing, documents, view?.viewing) === 'dbsEffect'
                    ? 'updrs.dbsEffect'
                    : 'updrs.secondaryTitle'
          }
          editing={editing}
          editButtons={<div />}
          infoText={
            docType(editing, documents, view?.viewing) === 'updrs_iii' ? (
              fm('updrs.updrsIIIInfo', { ln: <br /> })
            ) : docType(editing, documents, view?.viewing) === 'updrs_v' ? (
              'updrs.updrsVInfo'
            ) : docType(editing, documents, view?.viewing) === 'levodopatest' ? (
              <div>
                {fm('updrs.levodopaDescription.header_execution')}
                <ul>
                  <li>{fm('updrs.levodopaDescription.1_patient12hWithoutMed')}</li>
                  <li>{fm('updrs.levodopaDescription.2_motorEstimate')}</li>
                  <li>{fm('updrs.levodopaDescription.3_patientMedication')}</li>
                  <li>{fm('updrs.levodopaDescription.4_updrsiiiTest')}</li>
                </ul>
                <br />
                {fm('updrs.levodopaDescription.ps_info')}
              </div>
            ) : docType(editing, documents, view?.viewing) === 'dbsEffect' ? (
              <div>
                {fm('updrs.dbsDescription.header_execution')}
                <ul>
                  <li>{fm('updrs.dbsDescription.1_patient12hWithoutMed')}</li>
                  <li>{fm('updrs.dbsDescription.2_motorEstimate')}</li>
                  <li>{fm('updrs.dbsDescription.3_patientDBSsetting')}</li>
                  <li>{fm('updrs.dbsDescription.4_updrsiiiTest')}</li>
                </ul>
                <br />
                {fm('updrs.dbsDescription.ps_info')}
              </div>
            ) : undefined
          }
        />
        {editing ? (
          <UPDRSForm
            formData={formData}
            document={documents && (find((d: TDocument) => d._id === editing, documents) as TDocument)}
            documents={documents}
            setSubDocuments={setSubDocuments}
          />
        ) : null}
        {view?.viewing ? (
          <UPDRSForm
            formData={{ document: view.document as IUPDRSIII }}
            document={documents && (find((d: TDocument) => d._id === view?.viewing, documents) as TDocument)}
            documents={documents}
            viewing={!!view.viewing}
          />
        ) : null}
        {!editing && !view?.viewing ? UPDRSHistory(documents, startEdit, view) : undefined}
      </DocumentWrapper>
    )}
  </FormEditingHandler>
);

interface IOwnProps {
  documents: Array<TDocument>;
}

export default UPDRS;
