import * as React from 'react';
import { electrodeConfiguration } from '../../Document/config';
import colors from '../../../../config/theme/colors';
import { ILeadClickerType } from '../Form/LeadClicker';

const styles = {
  all: { userSelect: 'none' as const },
  stick: { fill: '#F2F3F8', stroke: '#045A8B', strokeWidth: 1.0175, strokeMiterlimit: 10 },
  fillUndefined: { fill: colors.dbsUndefined },
  fillPositive: { fill: colors.dbsPositive },
  fillNegative: { fill: colors.dbsNegative },
  cup: { fill: '#F2F3F8', stroke: '#045A8B', strokeMiterlimit: 10 },
  top1: { fill: '#F2F3F8' },
  top2: { fill: '#045A8B' },
  electrodeUndefined: { fill: colors.dbsUndefined, stroke: '#045A8B', strokeWidth: 1.0175, strokeMiterlimit: 10 },
  electrodePositive: { fill: colors.dbsPositive, stroke: '#045A8B', strokeWidth: 1.0175, strokeMiterlimit: 10 },
  electrodeNegative: { fill: colors.dbsNegative, stroke: '#045A8B', strokeWidth: 1.0175, strokeMiterlimit: 10 },
  siding: { fill: '#F2F3F8', stroke: '#045A8B', strokeWidth: 1.0175, strokeMiterlimit: 10 },
  dottedLine: { fill: 'none', stroke: '#045A8B', strokeMiterlimit: 10, strokeDasharray: '1,2' },
  solidLine: { fill: 'none', stroke: '#000000', strokeWidth: 1 },
  solidBlueLine: { stroke: '#045A8B', strokeWidth: 1.0175, strokeMiterlimit: 10, fill: 'none' },
  shadow: { opacity: 0.3, fill: '#F2F3F8', stroke: '#045A8B', strokeWidth: 1.0175, strokeMiterlimit: 10 },
  idText: { fill: 'black', fontSize: 20 },
  st5: { fill: 'none', stroke: '#045A8B', strokeWidth: 1.0, strokeMiterlimit: 10 },
};

const stickLenght = 120;
const stickLenghtFactor8Elec = 430; // The smaller value the shorter stick case of 8 electrodes
const stickLenghtFactor4Elec = 30; // The smaller value the shorter stick case of 8 electrodes
const stickLenghtFactor = 116; // The smaller value the shorter stick
const viewBoxSize8Elec = 700; // Reservered view area for SVG case of 8 electrodes
const viewBoxSize = 530; // Reservered view area for SVG
const electrodeGap8 = 70; // Gap between electrodes case of 8 electrodes
const electrodeGap = 110; // Gap between electrodes

const directed = ({ width, onClick, name, xOffSet, lead }: IOwnProps): JSX.Element | null => {
  let temp: IElectrodeConfiguration | undefined;
  let selectedConf: IElectrodeConfiguration | undefined;

  if (name === 'leftSettings' && Object.keys(lead).length !== 0 && lead.leadLeft) {
    temp = electrodeConfiguration.find((item) => {
      return item.lead === lead.leadLeft;
    });
    // Adds electrode values (minus, plus, undefined) to selectedConf
    if (temp != null) {
      {
        lead.leftSettings
          ? (selectedConf = {
              lead: temp.lead,
              zones: temp.zones.map((item) => ({
                value: item.id.map((item2) => lead?.leftSettings?.[item2]),
                id: item.id,
                type: item.type,
              })),
            })
          : (selectedConf = {
              lead: temp.lead,
              zones: temp.zones.map((item) => ({
                value: [undefined, undefined, undefined],
                id: item.id,
                type: item.type,
              })),
            });
      }
    }
  } else if (name === 'rightSettings' && Object.keys(lead).length !== 0 && lead.leadRight) {
    temp = electrodeConfiguration.find((item) => {
      return item.lead === lead.leadRight;
    });
    // Adds electrode values (minus, plus, undefined) to selectedConf
    if (temp != null) {
      {
        lead.rightSettings
          ? (selectedConf = {
              lead: temp.lead,
              zones: temp.zones.map((item) => ({
                value: item.id.map((item2) => lead?.rightSettings?.[item2]),
                id: item.id,
                type: item.type,
              })),
            })
          : (selectedConf = {
              lead: temp.lead,
              zones: temp.zones.map((item) => ({
                value: [undefined, undefined, undefined],
                id: item.id,
                type: item.type,
              })),
            });
      }
    }
  }

  const translate8 = (index: number): number => {
    if (selectedConf?.zones.length === 9) {
      return index * electrodeGap8;
    } else return index * electrodeGap - stickLenghtFactor4Elec;
  };

  const getStickLength = (): number => {
    if (!selectedConf) return 0;
    if (selectedConf.zones.length === 9) {
      return selectedConf.zones.length * stickLenght - stickLenghtFactor8Elec;
    } else if (selectedConf.zones[selectedConf.zones.length - 1].type !== 4) {
      return (
        selectedConf.zones.length * stickLenght + selectedConf.zones.length - stickLenghtFactor - stickLenghtFactor4Elec
      );
    } else return selectedConf.zones.length * stickLenght + selectedConf.zones.length - stickLenghtFactor;
  };
  return selectedConf ? (
    <svg
      viewBox={`-${xOffSet} 0 ${width} ${selectedConf.zones.length === 9 ? viewBoxSize8Elec : viewBoxSize}`}
      width={'100%'}
      height={selectedConf.zones.length === 9 ? '100%' : '100%'} // keep this, if need to resize case of 8 electrodes
      onClick={onClick}
      preserveAspectRatio="xMidYMin meet"
      style={styles.all}
    >
      <polyline // Main vertical stick
        style={styles.stick}
        points={`35.3, ${getStickLength()}
                   35.3,20.48 92.3,20.48 92.3,
                   ${getStickLength()}`}
      />
      <path // top
        style={styles.stick}
        d="M63.8,28.8c-15.4,0-28.2-4.2-28.5-9.3c0.3-5.1,13-9.3,28.5-9.3s28.2,4.2,28.5,9.3 C92,24.6,79.2,28.8,63.8,28.8z"
        id="path170"
      />
      {selectedConf.zones[selectedConf.zones.length - 1].type === 4 ? (
        <g transform={`translate(0,${getStickLength() - 370})`}>
          {selectedConf.zones[selectedConf.zones.length - 1].value?.[0] === 'plus' ? (
            <path
              style={styles.fillPositive}
              d="M92.3,367.5c0-5.1-12.7-9.3-28.4-9.3c-15.7,0-28.4,4.2-28.4,9.3h-0.2V388c0,15.7,12.8,28.5,28.5,28.5  s28.5-12.8,28.5-28.5L92.3,367.5L92.3,367.5z"
              id={`${name}zone${selectedConf.zones[selectedConf.zones.length - 1].id}`}
              className={`cup`}
            />
          ) : selectedConf.zones[selectedConf.zones.length - 1].value?.[0] === 'minus' ? (
            <path
              style={styles.fillNegative}
              d="M92.3,367.5c0-5.1-12.7-9.3-28.4-9.3c-15.7,0-28.4,4.2-28.4,9.3h-0.2V388c0,15.7,12.8,28.5,28.5,28.5  s28.5-12.8,28.5-28.5L92.3,367.5L92.3,367.5z"
              id={`${name}zone${selectedConf.zones[selectedConf.zones.length - 1].id}`}
              className={`cup`}
            />
          ) : (
            <path
              style={styles.fillUndefined}
              d="M92.3,367.5c0-5.1-12.7-9.3-28.4-9.3c-15.7,0-28.4,4.2-28.4,9.3h-0.2V388c0,15.7,12.8,28.5,28.5,28.5  s28.5-12.8,28.5-28.5L92.3,367.5L92.3,367.5z"
              id={`${name}zone${selectedConf.zones[selectedConf.zones.length - 1].id}`}
              className={`cup`}
            />
          )}
          <path style={styles.solidBlueLine} d="M35.3,367.5V388c0,15.7,12.8,28.5,28.5,28.5s28.5-12.8,28.5-28.5v-20.5" />
          <ellipse style={styles.dottedLine} cx="63.9" cy="367.5" rx="28.4" ry="9.3" />
          <path style={styles.solidBlueLine} d="M92.2,367.5c0,5.1-12.7,9.3-28.4,9.3c-15.7,0-28.4-4.2-28.4-9.3" />
        </g>
      ) : (
        <g transform={`translate(0,${getStickLength() - 370})`}>
          <path style={styles.stick} id={`${name}zone${100 + 'B'}`} className={`cup`} />
          <path style={styles.stick} d="M35.3,367.5V388c0,15.7,12.8,28.5,28.5,28.5s28.5-12.8,28.5-28.5v-20.5" />
        </g>
      )}

      <text style={styles.idText} x="10" y={getStickLength()}>
        {selectedConf.zones[selectedConf.zones.length - 1].type === 4 ? `${'1'}` : undefined}
      </text>

      {
        selectedConf.zones.map((item: IZone, index: number) => {
          return (
            <g transform={`translate(0,${translate8(index)})`} key={index} id="g118">
              {item.type === 1 ? ( // 3 electrodes in a zone
                <g>
                  <path // Shadow right
                    style={styles.shadow}
                    d="M92.29,72.73c0-9.3-10.78-16.98-24.74-18.16l0,0v55.52l0.06,0c13.94,1.2,24.68,8.87,24.68,18.15V72.73z"
                    id="path92"
                  />
                  <path // Shadow left
                    style={styles.shadow}
                    d="M35.31,72.73c0-9.3,10.78-16.98,24.74-18.16v0v55.52l-0.06,0c-13.94,1.2-24.68,8.87-24.68,18.15V72.73z"
                    id="path94"
                  />
                  <line // Dotted line lower right
                    style={styles.dottedLine}
                    x1="92.29"
                    y1="128.25"
                    x2="124.6"
                    y2="119.25"
                    id="line110"
                  />
                  <line // Dotted line upper right
                    style={styles.dottedLine}
                    x1="67.55"
                    y1="54.57"
                    x2="102.06"
                    y2="42.57"
                    id="line112"
                  />
                  <line // Dotted line lower left
                    style={styles.dottedLine}
                    x1="35.45"
                    y1="128.25"
                    x2="3.14"
                    y2="119.25"
                    id="line114"
                  />
                  <line // Dotted line upper left
                    style={styles.dottedLine}
                    x1="60.19"
                    y1="54.57"
                    x2="25.68"
                    y2="42.57"
                    id="line116"
                  />
                  <line // Dotted line behind right
                    style={styles.dottedLine}
                    x1="68.75"
                    y1="107.21"
                    x2="101.06"
                    y2="98.21"
                    id="line88"
                  />
                  <line // Dotted line behind left
                    style={styles.dottedLine}
                    x1="59.32"
                    y1="107.21"
                    x2="27.01"
                    y2="98.21"
                    id="line90"
                  />
                  <path
                    style={
                      item?.value?.[0] === 'plus'
                        ? styles.electrodePositive
                        : item?.value?.[0] === 'minus'
                          ? styles.electrodeNegative
                          : styles.electrodeUndefined
                    }
                    d="m 0.74406632,60.729994 c 0,-9.3 10.77999968,-16.979996 24.73999968,-18.159996 v 0 55.519996 h -0.06 c -13.94,1.2 -24.67999968,8.869996 -24.67999968,18.149996 z"
                    id={`${name}zone${item.id[0]}`}
                    className={`${name}`}
                  />
                  {item?.value?.[0] === 'registering' && (
                    <text
                      style={{ ...styles.idText, fontWeight: 600 }}
                      textAnchor="middle"
                      x="14"
                      y="82"
                      id={`${name}zone${item.id[0]}`}
                    >
                      R
                    </text>
                  )}
                  <path
                    style={
                      item?.value?.[1] === 'plus'
                        ? styles.electrodePositive
                        : item?.value?.[1] === 'minus'
                          ? styles.electrodeNegative
                          : styles.electrodeUndefined
                    }
                    d="M126.8,60.73c0-9.3-10.78-16.98-24.74-18.16v0v55.52l0.06,0c13.94,1.2,24.68,8.87,24.68,18.15V60.73z"
                    id={`${name}zone${item.id[1]}`}
                    className={`${name}`}
                  />
                  {item?.value?.[1] === 'registering' && (
                    <text
                      style={{ ...styles.idText, fontWeight: 600 }}
                      textAnchor="middle"
                      x="114"
                      y="82"
                      id={`${name}zone${item.id[1]}`}
                    >
                      R
                    </text>
                  )}
                  <path
                    style={
                      item?.value?.[2] === 'plus'
                        ? styles.electrodePositive
                        : item?.value?.[2] === 'minus'
                          ? styles.electrodeNegative
                          : styles.electrodeUndefined
                    }
                    d="M41.3,139.09V85.45c5.32,4.2,13.42,6.88,22.5,6.88h0c9.08,0,17.18-2.68,22.5-6.88c0,0,0,53.64,0,53.64    c-5.21,4.31-13.35,7.08-22.5,7.08h0C54.78,146.17,46.52,143.3,41.3,139.09z"
                    id={`${name}zone${item.id[2]}`}
                    className={`${name}`}
                  />
                  {item?.value?.[2] === 'registering' && (
                    <text
                      style={{ ...styles.idText, fontWeight: 600 }}
                      textAnchor="middle"
                      x="64"
                      y="124"
                      id={`${name}zone${item.id[2]}`}
                    >
                      R
                    </text>
                  )}
                  <polygon // Siding left
                    transform="translate(-0.05593365,-117.03)"
                    style={styles.siding}
                    points="3,236.28 0.8,233.28 0.8,177.76 3,180.76"
                    id="polygon74-7"
                  />
                  <polygon // Siding right
                    style={styles.siding}
                    points="124.6,119.25 126.8,116.25 126.8,60.73 124.6,63.73  "
                    id="polygon108"
                  />
                  <text style={styles.idText} textAnchor="middle" x="0" y="37">
                    {item.id[0]}
                  </text>
                  <text style={styles.idText} textAnchor="middle" x="120" y="37">
                    {item.id[1]}
                  </text>
                  <text style={styles.idText} textAnchor="middle" x="63 " y="83">
                    {item.id[2]}
                  </text>
                </g>
              ) : item.type === 2 ? ( // 1 electrode in a zone
                <g>
                  <path
                    style={
                      item?.value?.[0] === 'plus'
                        ? styles.electrodePositive
                        : item?.value?.[0] === 'minus'
                          ? styles.electrodeNegative
                          : styles.electrodeUndefined
                    }
                    d="M35.31,123.53V69.88c5.32,4.2,15.92,8.03,28.49,8.03h0c14.46,0,23.17-4.26,28.49-8.46c0,0,0,53.64,0,
                                                      53.64c-5.21,4.31-15.69,8.65-28.49,8.65h0C49.78,131.75,40.53,127.74,35.31,123.53z"
                    id={`${name}zone${item.id[0]}`}
                    className={`${name}`}
                  />
                  {item?.value?.[0] === 'registering' && (
                    <text
                      style={{ ...styles.idText, fontWeight: 600 }}
                      textAnchor="middle"
                      x="64"
                      y="109"
                      id={`${name}zone${item.id[0]}`}
                    >
                      R
                    </text>
                  )}
                  <text style={styles.idText} textAnchor="middle" x="0" y="94">
                    {item.id[0]}
                  </text>
                </g>
              ) : item.type === 3 ? ( // Bottom w/o electrode
                <g></g> // Electrode bottom
              ) : (
                <g></g>
              )}
            </g>
          ); /*  return */
        }) /* map */
      }
    </svg>
  ) : null; /* return */
};

interface IOwnProps {
  width: number;
  height: number;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  name: string;
  xOffSet: number;
  lead: ILeadClickerType;
}

interface IZone {
  type: number;
  value?: (string | undefined)[] | undefined[];
  id: string[];
}

interface IElectrodeConfiguration {
  lead: string;
  zones: IZone[];
}

export default directed;
