import * as React from 'react';

import DocumentCreationButton from '../../../components/DocumentCreationButton';
import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import NutritionForm from './Form';

const Nutrition = ({ documents }: IOwnProps): JSX.Element => (
  <FormEditingHandler name="nutrition" documents={documents}>
    {(editing, startEdit, formData): JSX.Element => (
      <DocumentWrapper>
        <DocumentHeader
          name={'nutrition'}
          headerId={'nutrition.title'}
          editing={editing}
          editButtons={
            <DocumentCreationButton
              name="nutrition"
              document={documents?.length > 0 ? documents[0] : undefined}
              text={'general.edit'}
              onClick={startEdit(documents?.length > 0 ? { _id: documents[0]._id } : { _id: undefined })}
            />
          }
        />
        <NutritionForm editing={editing} formData={formData} document={documents?.[0]} />
      </DocumentWrapper>
    )}
  </FormEditingHandler>
);

interface IOwnProps {
  documents: INutrition[];
}

export default Nutrition;
