import * as React from 'react';

import DocumentLoader from '../containers/DocumentLoader';
import DashboardCreator from './_Dashboard';

import { capitalize } from '../utility/string';
import ErrorContainer from '../containers/ErrorContainer';

// Need to export React component, not element
export const Dashboard = ({ sections }: { sections: string[] }): JSX.Element => {
  return <DashboardCreator sections={sections} />;
};

// Fetch component for document routes and form editing tools and toolbar
class RouteClass extends React.Component<{ s: string }> {
  render(): React.ReactNode {
    return (
      <ErrorContainer>
        <DocumentLoader name={this.props.s}>
          {require('./' + capitalize(this.props.s) + '/Document/').default}
        </DocumentLoader>
      </ErrorContainer>
    );
  }
}

const routeElements = (sections: string[]): { element: JSX.Element; path: string }[] => {
  const routes: Array<{ element: JSX.Element; path: string }> = [];
  sections.forEach((s) => {
    routes.push({
      element: <RouteClass s={s} />,
      path: '/' + s,
    });
  });
  return routes;
};

export const documentRoutes = routeElements;
