import * as React from 'react';
import { useAppSelector as useSelector } from 'Store/index';

import DocumentCreationButton from '../../../components/DocumentCreationButton';
import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import FormEditingHandler from '../../../containers/FormEditingHandler';
import { smaLabResultTypes } from '../utils';

import Form from './Form';

import History from './HistoryRowData';

const Examination = ({ documents }: IOwnProps): JSX.Element => {
  const platform = useSelector((s: { session: ISessionStore }) => s.session.platforms?.selected);

  /** Filter documents if platform is SMA or DMD */
  const docs =
    platform && ['sma', 'dmd'].includes(platform)
      ? documents.filter(
          (d) =>
            d && d._type === 'examination' && typeof d.codeName === 'string' && smaLabResultTypes.includes(d.codeName),
        )
      : documents;

  return (
    <FormEditingHandler name="examination" documents={docs}>
      {(editing, startEdit, formData, view): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader
            name={'examination'}
            headerId={'examination.title'}
            editing={editing || view?.viewing}
            editButtons={
              <div>
                <DocumentCreationButton name="examination" text={'general.new'} onClick={startEdit({})} />
              </div>
            }
          />
          {editing || view?.viewing ? <Form formData={formData} view={view} /> : null}
          {!editing && !view?.viewing ? <History view={view} documents={docs} startEdit={startEdit} /> : <span />}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: Array<IExamination>;
}

export default Examination;
