import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import { IFormContext, withFormContext } from 'Containers/FormContextHandler';
import * as React from 'react';
import { styled } from '@mui/system';
import colors from '../../../../../../config/theme/colors';
import FormSectionHistoryAcceptor from 'Components/FormSectionHistoryAcceptor';
import { ReferenceText, SubHeader } from 'Routes/Diagnosis/utils/styled';
import { Container, Item } from 'Components/Grid';
import {
  checkCoreClinicalFeaturesForClinicallyEstablishedMSA,
  checkCoreClinicalFeaturesForClinicallyProbableMSA,
  checkDiagnosticCriteriaForClinicallyEstablishedMSA,
  checkDiagnosticCriteriaForClinicallyProbableMSA,
  checkExclusionCriteriaAbsent,
  checkMRIMarkersForClinicallyEstablishedMSAC,
  checkMRIMarkersForClinicallyEstablishedMSAP,
  checkSupportiveClinicalFeaturesForClinicallyEstablishedMSA,
  checkSupportiveClinicalFeaturesForClinicallyProbableMSA,
} from 'Routes/Diagnosis/utils/MSA';

const AndHeader = styled('div')({
  fontWeight: 'bold',
  margin: '2rem 0',
});

const OrGroupStyle = styled('div')({
  backgroundColor: colors.lightestGray,
  padding: '1rem 0 1rem 1rem',
});

const orGroupElement = (elem?: JSX.Element): JSX.Element => <OrGroupStyle>{elem}</OrGroupStyle>;

const formFields = [
  'essentialFeatures.sporadicProgressiveAdultOnsetDisease',
  'coreClinicalFeaturesForClinicallyEstablishedMSA.autonomicDysfunction.unexplainedVoidingDifficulties',
  'coreClinicalFeaturesForClinicallyEstablishedMSA.autonomicDysfunction.unexplainedUrinaryUrgeIncontinence',
  'coreClinicalFeaturesForClinicallyEstablishedMSA.autonomicDysfunction.neurogenicOrthostaticHypertension',
  'coreClinicalFeaturesForClinicallyEstablishedMSA.poorlyLevodopaResponsiveParkinsonism',
  'coreClinicalFeaturesForClinicallyEstablishedMSA.cerebellarSyndrome.gaitAtaxia',
  'coreClinicalFeaturesForClinicallyEstablishedMSA.cerebellarSyndrome.limbAtaxia',
  'coreClinicalFeaturesForClinicallyEstablishedMSA.cerebellarSyndrome.cerebellarDysarthria',
  'coreClinicalFeaturesForClinicallyEstablishedMSA.cerebellarSyndrome.oculomotorFeatures',
  'coreClinicalFeaturesForClinicallyProbableMSA.autonomicDysfunction.unexplainedVoidingDifficulties',
  'coreClinicalFeaturesForClinicallyProbableMSA.autonomicDysfunction.unexplainedUrinaryUrgeIncontinence',
  'coreClinicalFeaturesForClinicallyProbableMSA.autonomicDysfunction.neurogenicOrthostaticHypertension',
  'coreClinicalFeaturesForClinicallyProbableMSA.poorlyLevodopaResponsiveParkinsonism',
  'coreClinicalFeaturesForClinicallyProbableMSA.cerebellarSyndrome.gaitAtaxia',
  'coreClinicalFeaturesForClinicallyProbableMSA.cerebellarSyndrome.limbAtaxia',
  'coreClinicalFeaturesForClinicallyProbableMSA.cerebellarSyndrome.cerebellarDysarthria',
  'coreClinicalFeaturesForClinicallyProbableMSA.cerebellarSyndrome.oculomotorFeatures',
  'supportiveClinicalFeatures.supportiveMotorFeatures.rapidProgressionWithin3Years',
  'supportiveClinicalFeatures.supportiveMotorFeatures.moderateToSeverePosturalInstabilityWithin3Years',
  'supportiveClinicalFeatures.supportiveMotorFeatures.cranioservicalDystoniaLevodopaInduced',
  'supportiveClinicalFeatures.supportiveMotorFeatures.severeSpeechImpairmentWithin3Years',
  'supportiveClinicalFeatures.supportiveMotorFeatures.severeDysphagiaWithin3Years',
  'supportiveClinicalFeatures.supportiveMotorFeatures.unexplainedBabinskiSign',
  'supportiveClinicalFeatures.supportiveMotorFeatures.jerkyMyoclonicTremor',
  'supportiveClinicalFeatures.supportiveMotorFeatures.posturalDeformities',
  'supportiveClinicalFeatures.supportiveNonMotorFeatures.stridor',
  'supportiveClinicalFeatures.supportiveNonMotorFeatures.inspiratorySighs',
  'supportiveClinicalFeatures.supportiveNonMotorFeatures.coldDiscoloredHandsAndFeet',
  'supportiveClinicalFeatures.supportiveNonMotorFeatures.erectileDysfunctionUnder60',
  'supportiveClinicalFeatures.supportiveNonMotorFeatures.pathologicLaughterOrCrying',
  'mriMarkersOfClinicallyEstablishedMSA.msaP.atrophy.putamenSignalDecreaseOnIronSensitiveSequences',
  'mriMarkersOfClinicallyEstablishedMSA.msaP.atrophy.middleCerebellarPeduncle',
  'mriMarkersOfClinicallyEstablishedMSA.msaP.atrophy.pons',
  'mriMarkersOfClinicallyEstablishedMSA.msaP.atrophy.cerebellum',
  'mriMarkersOfClinicallyEstablishedMSA.msaP.hotCrossBunSign',
  'mriMarkersOfClinicallyEstablishedMSA.msaP.increasedDiffusivity.putamen',
  'mriMarkersOfClinicallyEstablishedMSA.msaP.increasedDiffusivity.middleCerebellarPeduncle',
  'mriMarkersOfClinicallyEstablishedMSA.msaC.atrophy.putamenSignalDecreaseOnIronSensitiveSequences',
  'mriMarkersOfClinicallyEstablishedMSA.msaC.atrophy.infratentorialStructures',
  'mriMarkersOfClinicallyEstablishedMSA.msaC.hotCrossBunSign',
  'mriMarkersOfClinicallyEstablishedMSA.msaC.increasedDiffusivity.putamen',
  'exclusionCriteria.persistentResponseToDopaminergicMedications',
  'exclusionCriteria.unexplainedAnosmia',
  'exclusionCriteria.fluctuatingCognitionWithAttentionVariation',
  'exclusionCriteria.earlyVisuoperceptualDecline',
  'exclusionCriteria.earlyVisualHallucinations',
  'exclusionCriteria.earlyOnsetDementia',
  'exclusionCriteria.downgazeSupranuclearPalsy',
  'exclusionCriteria.alternativeDiagnosisOnMRI',
  'exclusionCriteria.documentationOfAlternativeCondition',
];

const splitPath = (path: string) => path.split('.');

const constructInitialDocument = (): IMSA2022 => {
  const result: Partial<IMSA2022> = {};

  formFields.forEach((path) => {
    const keys = path.split('.');
    let current = result as any;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        // Last key in the path, set as 'unknown'
        current[key] = 'unknown';
      } else {
        // Intermediate key, create nested object if it doesn't exist
        if (!current[key]) {
          current[key] = {};
        }
        current = current[key];
      }
    });
  });

  return result as IMSA2022;
};

const setAllUnknown = (formData: IFormContext['formData'], viewing: boolean): void => {
  const initialDocument = constructInitialDocument();

  Object.keys(initialDocument).forEach((k) => {
    if (!viewing && !(formData?.document as { [key: string]: any })[k]) {
      formData?.onChange?.({
        [k]: initialDocument[k as keyof typeof initialDocument],
      });
    }
  });
};

const MakeFormRow = ({
  title,
  description,
  name,
  formData,
  viewing,
  bottomMargin = true,
  boldTitle = false,
}: {
  title: string;
  description?: JSX.Element | string;
  name: string;
  formData: IFormContext['formData'];
  viewing: boolean;
  bottomMargin?: boolean;
  boldTitle?: boolean;
}): JSX.Element => (
  <FormRow
    title={title}
    description={description}
    headerWidth={6}
    bottomMargin={bottomMargin}
    headerStyle={boldTitle ? { color: colors.primaryText, fontWeight: 600 } : undefined}
  >
    <InputHandler
      editing={!viewing}
      formData={formData}
      name={name}
      type="RadioButtonRow"
      preset="yesNoUnknown"
      height={3.5}
      width={9}
    />
  </FormRow>
);

const MakeSummaryRow = ({
  title,
  value,
  marginTop,
  marginBottom,
  fm,
}: {
  title: string;
  value: string | undefined;
  marginTop?: string | number;
  marginBottom?: string | number;
  fm: (id: string) => string;
}): JSX.Element => (
  <Container style={{ marginTop: marginTop ?? undefined, marginBottom: marginBottom ?? '2rem' }}>
    <Item xs={5} style={{ paddingRight: '1rem' }}>
      {fm(title)}
    </Item>
    <Item style={{ fontWeight: 600 }}>{fm(`general.${value}`)}</Item>
  </Container>
);

const MSAForm2022 = ({
  documents,
  formData,
  view,
  editing,
  fm = (): string => '',
}: IFormContext<IMSA2022>): JSX.Element => {
  const isViewing = !editing && !!view?.viewing;
  const docID = editing ?? view?.viewing;

  React.useEffect(() => {
    setAllUnknown(formData, isViewing);
  }, [formData, isViewing]);

  const commonFormSectionHistoryAcceptorProps = {
    formData: formData,
    documentID: docID,
    documents: documents,
    optionFormatter: (s: string): string => fm('general.' + s),
  };

  return (
    <React.Fragment>
      <FormRow title="general.date">
        <InputHandler
          type="PartialDate"
          editing={!isViewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      {/** Essential features */}
      <FormSectionHistoryAcceptor
        {...commonFormSectionHistoryAcceptorProps}
        header="diagnosis.msa2022.essentialFeatures"
        hideCopyButton={isViewing}
      >
        {{
          'essentialFeatures.sporadicProgressiveAdultOnsetDisease': {
            element: (
              <MakeFormRow
                title="diagnosis.msa2022.sporadicProgressiveAdultOnsetDisease"
                name="essentialFeatures.sporadicProgressiveAdultOnsetDisease"
                formData={formData}
                viewing={isViewing}
              />
            ),
          },
        }}
      </FormSectionHistoryAcceptor>
      {/** Clinically established & probable MSA */}
      {['coreClinicalFeaturesForClinicallyEstablishedMSA', 'coreClinicalFeaturesForClinicallyProbableMSA'].map(
        (section, index) => {
          const coreClinicalFeaturesAreMet = {
            coreClinicalFeaturesForClinicallyEstablishedMSA: checkCoreClinicalFeaturesForClinicallyEstablishedMSA(
              formData.document,
            ),
            coreClinicalFeaturesForClinicallyProbableMSA: checkCoreClinicalFeaturesForClinicallyProbableMSA(
              formData.document,
            ),
          }[section];
          return (
            <FormSectionHistoryAcceptor
              key={index}
              {...commonFormSectionHistoryAcceptorProps}
              header={`diagnosis.msa2022.${section}`}
              hideCopyButton={isViewing}
            >
              {{
                headerAutonomicDysfunction: {
                  header: <SubHeader>{fm('diagnosis.msa2022.autonomicDysfunction')}</SubHeader>,
                },
                group1: {
                  group: {
                    groupElement: orGroupElement,
                    children: formFields
                      .filter((f) => splitPath(f)[0] === section && splitPath(f)[1] === 'autonomicDysfunction')
                      .reduce(
                        (acc, f) => {
                          acc[f] = {
                            element: (
                              <MakeFormRow
                                title={`diagnosis.msa2022.${splitPath(f)[2]}`}
                                name={f}
                                formData={formData}
                                viewing={isViewing}
                                bottomMargin={false}
                              />
                            ),
                          };
                          return acc;
                        },
                        {} as { [key: string]: { element: JSX.Element } },
                      ),
                  },
                },
                and: { header: <AndHeader>{fm('diagnosis.msa2022.andAtLeastOneOf')}</AndHeader> },
                group2: {
                  group: {
                    groupElement: orGroupElement,
                    children: {
                      [`${section}.poorlyLevodopaResponsiveParkinsonism`]: {
                        element: (
                          <MakeFormRow
                            title="diagnosis.msa2022.poorlyLevodopaResponsiveParkinsonism"
                            name={`${section}.poorlyLevodopaResponsiveParkinsonism`}
                            formData={formData}
                            viewing={isViewing}
                            boldTitle
                          />
                        ),
                      },
                      cerebellarSyndromeHeader: {
                        header: <SubHeader>{fm('diagnosis.msa2022.cerebellarSyndrome')}</SubHeader>,
                      },
                      ...formFields
                        .filter((f) => splitPath(f)[0] === section && splitPath(f)[1] === 'cerebellarSyndrome')
                        .reduce(
                          (acc, f) => {
                            acc[f] = {
                              element: (
                                <MakeFormRow
                                  title={`diagnosis.msa2022.${splitPath(f)[2]}`}
                                  name={f}
                                  formData={formData}
                                  viewing={isViewing}
                                  bottomMargin={false}
                                />
                              ),
                            };
                            return acc;
                          },
                          {} as { [key: string]: { element: JSX.Element } },
                        ),
                    },
                  },
                },
                [`${section}AreMet`]: {
                  header: (
                    <MakeSummaryRow
                      title={`diagnosis.msa2022.${section}AreMet`}
                      value={coreClinicalFeaturesAreMet}
                      marginTop="3.5rem"
                      fm={fm}
                    />
                  ),
                },
              }}
            </FormSectionHistoryAcceptor>
          );
        },
      )}
      {/** Supportive clinical (motor or non-motor) features */}
      <FormSectionHistoryAcceptor
        {...commonFormSectionHistoryAcceptorProps}
        header="diagnosis.msa2022.supportiveClinicalFeatures"
        hideCopyButton={isViewing}
      >
        {{
          headerSupportiveMotorFeatures: {
            header: <SubHeader>{fm('diagnosis.msa2022.supportiveMotorFeatures')}</SubHeader>,
          },
          ...formFields
            .filter(
              (f) => splitPath(f)[0] === 'supportiveClinicalFeatures' && splitPath(f)[1] === 'supportiveMotorFeatures',
            )
            .reduce(
              (acc, f) => {
                acc[f] = {
                  element: (
                    <MakeFormRow
                      title={`diagnosis.msa2022.${splitPath(f)[2]}`}
                      name={f}
                      formData={formData}
                      viewing={isViewing}
                      bottomMargin={false}
                    />
                  ),
                };
                return acc;
              },
              {} as { [key: string]: { element: JSX.Element } },
            ),
          headerSupportiveNonMotorFeatures: {
            header: <SubHeader>{fm('diagnosis.msa2022.supportiveNonMotorFeatures')}</SubHeader>,
          },
          ...formFields
            .filter(
              (f) =>
                splitPath(f)[0] === 'supportiveClinicalFeatures' && splitPath(f)[1] === 'supportiveNonMotorFeatures',
            )
            .reduce(
              (acc, f) => {
                acc[f] = {
                  element: (
                    <MakeFormRow
                      title={`diagnosis.msa2022.${splitPath(f)[2]}`}
                      name={f}
                      formData={formData}
                      viewing={isViewing}
                      bottomMargin={false}
                    />
                  ),
                };
                return acc;
              },
              {} as { [key: string]: { element: JSX.Element } },
            ),
          supportiveClinicalFeaturesForClinicallyEstablishedMSAAreMet: {
            header: (
              <MakeSummaryRow
                title="diagnosis.msa2022.supportiveClinicalFeaturesForClinicallyEstablishedMSAAreMet"
                value={checkSupportiveClinicalFeaturesForClinicallyEstablishedMSA(formData.document)}
                marginTop="3.5rem"
                fm={fm}
              />
            ),
          },
          supportiveClinicalFeaturesForClinicallyProbableMSAAreMet: {
            header: (
              <MakeSummaryRow
                title="diagnosis.msa2022.supportiveClinicalFeaturesForClinicallyProbableMSAAreMet"
                value={checkSupportiveClinicalFeaturesForClinicallyProbableMSA(formData.document)}
                marginTop="3.5rem"
                fm={fm}
              />
            ),
          },
        }}
      </FormSectionHistoryAcceptor>
      {/** MRI markers of clinically established MSA */}
      <FormSectionHistoryAcceptor
        {...commonFormSectionHistoryAcceptorProps}
        header="diagnosis.msa2022.mriMarkersOfClinicallyEstablishedMSA"
        hideCopyButton={isViewing}
      >
        {{
          headerEachAffectedBrainRegionCountsAsMRIMarker: {
            header: (
              <div style={{ marginBottom: '2rem' }}>
                {fm('diagnosis.msa2022.eachAffectedBrainRegionCountsAsMRIMarker')}
              </div>
            ),
          },
          headerMsaP: {
            header: <SubHeader>{fm('diagnosis.msa2022.msaP')}</SubHeader>,
          },
          headerMsaPAtrophy: {
            header: <SubHeader>{fm('diagnosis.msa2022.atrophyOf')}</SubHeader>,
          },
          ...formFields
            .filter(
              (f) =>
                splitPath(f)[0] === 'mriMarkersOfClinicallyEstablishedMSA' &&
                splitPath(f)[1] === 'msaP' &&
                splitPath(f)[2] === 'atrophy',
            )
            .reduce(
              (acc, f) => {
                acc[f] = {
                  element: (
                    <MakeFormRow
                      title={`diagnosis.msa2022.${splitPath(f)[3]}`}
                      name={f}
                      formData={formData}
                      viewing={isViewing}
                      bottomMargin={splitPath(f)[3] === 'cerebellum' ? true : false}
                    />
                  ),
                };
                return acc;
              },
              {} as { [key: string]: { element: JSX.Element } },
            ),
          'mriMarkersOfClinicallyEstablishedMSA.msaP.hotCrossBunSign': {
            element: (
              <MakeFormRow
                title="diagnosis.msa2022.hotCrossBunSign"
                name="mriMarkersOfClinicallyEstablishedMSA.msaP.hotCrossBunSign"
                formData={formData}
                viewing={isViewing}
                bottomMargin={false}
                boldTitle
              />
            ),
          },
          headerMsaPIncreasedDiffusivity: {
            header: <SubHeader>{fm('diagnosis.msa2022.increasedDiffusivityOf')}</SubHeader>,
          },
          ...formFields
            .filter(
              (f) =>
                splitPath(f)[0] === 'mriMarkersOfClinicallyEstablishedMSA' &&
                splitPath(f)[1] === 'msaP' &&
                splitPath(f)[2] === 'increasedDiffusivity',
            )
            .reduce(
              (acc, f) => {
                acc[f] = {
                  element: (
                    <MakeFormRow
                      title={`diagnosis.msa2022.${splitPath(f)[3]}`}
                      name={f}
                      formData={formData}
                      viewing={isViewing}
                      bottomMargin={false}
                    />
                  ),
                };
                return acc;
              },
              {} as { [key: string]: { element: JSX.Element } },
            ),
          mriMarkersOfClinicallyEstablishedMSAPAreMet: {
            header: (
              <MakeSummaryRow
                title="diagnosis.msa2022.mriMarkersOfClinicallyEstablishedMSAPAreMet"
                value={checkMRIMarkersForClinicallyEstablishedMSAP(formData.document)}
                marginTop="3.5rem"
                marginBottom="3.5rem"
                fm={fm}
              />
            ),
          },
          headerMsaC: {
            header: <SubHeader>{fm('diagnosis.msa2022.msaC')}</SubHeader>,
          },
          headerMsaCAtrophy: {
            header: <SubHeader>{fm('diagnosis.msa2022.atrophyOf')}</SubHeader>,
          },
          ...formFields
            .filter(
              (f) =>
                splitPath(f)[0] === 'mriMarkersOfClinicallyEstablishedMSA' &&
                splitPath(f)[1] === 'msaC' &&
                splitPath(f)[2] === 'atrophy',
            )
            .reduce(
              (acc, f) => {
                acc[f] = {
                  element: (
                    <MakeFormRow
                      title={`diagnosis.msa2022.${splitPath(f)[3]}`}
                      name={f}
                      formData={formData}
                      viewing={isViewing}
                      bottomMargin={splitPath(f)[3] === 'infratentorialStructures' ? true : false}
                    />
                  ),
                };
                return acc;
              },
              {} as { [key: string]: { element: JSX.Element } },
            ),
          'mriMarkersOfClinicallyEstablishedMSA.msaC.hotCrossBunSign': {
            element: (
              <MakeFormRow
                title="diagnosis.msa2022.hotCrossBunSign"
                name="mriMarkersOfClinicallyEstablishedMSA.msaC.hotCrossBunSign"
                formData={formData}
                viewing={isViewing}
                bottomMargin={false}
                boldTitle
              />
            ),
          },
          headerMsaCIncreasedDiffusivity: {
            header: <SubHeader>{fm('diagnosis.msa2022.increasedDiffusivityOf')}</SubHeader>,
          },
          ...formFields
            .filter(
              (f) =>
                splitPath(f)[0] === 'mriMarkersOfClinicallyEstablishedMSA' &&
                splitPath(f)[1] === 'msaC' &&
                splitPath(f)[2] === 'increasedDiffusivity',
            )
            .reduce(
              (acc, f) => {
                acc[f] = {
                  element: (
                    <MakeFormRow
                      title={`diagnosis.msa2022.${splitPath(f)[3]}`}
                      name={f}
                      formData={formData}
                      viewing={isViewing}
                      bottomMargin={false}
                    />
                  ),
                };
                return acc;
              },
              {} as { [key: string]: { element: JSX.Element } },
            ),
          mriMarkersOfClinicallyEstablishedMSACAreMet: {
            header: (
              <MakeSummaryRow
                title="diagnosis.msa2022.mriMarkersOfClinicallyEstablishedMSACAreMet"
                value={checkMRIMarkersForClinicallyEstablishedMSAC(formData.document)}
                marginTop="3.5rem"
                fm={fm}
              />
            ),
          },
        }}
      </FormSectionHistoryAcceptor>
      {/** Exclusion criteria */}
      <FormSectionHistoryAcceptor
        {...commonFormSectionHistoryAcceptorProps}
        header="diagnosis.msa2022.exclusionCriteria"
        hideCopyButton={isViewing}
      >
        {{
          ...formFields
            .filter((f) => splitPath(f)[0] === 'exclusionCriteria')
            .reduce(
              (acc, f) => {
                acc[f] = {
                  element: (
                    <MakeFormRow
                      title={`diagnosis.msa2022.${splitPath(f)[1]}`}
                      name={f}
                      formData={formData}
                      viewing={isViewing}
                      bottomMargin={false}
                    />
                  ),
                };
                return acc;
              },
              {} as { [key: string]: { element: JSX.Element } },
            ),
          exclusionCriteriaAreAbsent: {
            header: (
              <MakeSummaryRow
                title="diagnosis.msa2022.exclusionCriteriaAreAbsent"
                value={checkExclusionCriteriaAbsent(formData.document)}
                marginTop="3.5rem"
                fm={fm}
              />
            ),
          },
        }}
      </FormSectionHistoryAcceptor>
      {/** Summary */}
      <FormSectionHistoryAcceptor
        {...commonFormSectionHistoryAcceptorProps}
        header="diagnosis.msa2022.summary"
        hideCopyButton={true}
      >
        {{
          headerClinicallyEstablishedMSA: {
            header: <SubHeader>{fm('diagnosis.msa2022.clinicallyEstablishedMSA')}</SubHeader>,
          },
          essentialFeaturesAreMet: {
            header: (
              <MakeSummaryRow
                title="diagnosis.msa2022.essentialFeaturesAreMet"
                value={formData.document.essentialFeatures?.sporadicProgressiveAdultOnsetDisease}
                fm={fm}
              />
            ),
          },
          coreClinicalFeaturesAreMet: {
            header: (
              <MakeSummaryRow
                title="diagnosis.msa2022.coreClinicalFeaturesAreMet"
                value={checkCoreClinicalFeaturesForClinicallyEstablishedMSA(formData.document)}
                fm={fm}
              />
            ),
          },
          supportiveClinicalFeaturesAreMet: {
            header: (
              <MakeSummaryRow
                title="diagnosis.msa2022.supportiveClinicalFeaturesAreMet"
                value={checkSupportiveClinicalFeaturesForClinicallyEstablishedMSA(formData.document)}
                fm={fm}
              />
            ),
          },
          mriMarkersOfClinicallyEstablishedMSAPAreMet: {
            header: (
              <MakeSummaryRow
                title="diagnosis.msa2022.mriMarkersOfClinicallyEstablishedMSAPAreMet"
                value={checkMRIMarkersForClinicallyEstablishedMSAP(formData.document)}
                fm={fm}
              />
            ),
          },
          mriMarkersOfClinicallyEstablishedMSACAreMet: {
            header: (
              <MakeSummaryRow
                title="diagnosis.msa2022.mriMarkersOfClinicallyEstablishedMSACAreMet"
                value={checkMRIMarkersForClinicallyEstablishedMSAC(formData.document)}
                fm={fm}
              />
            ),
          },
          exclusionCriteriaAreAbsent: {
            header: (
              <MakeSummaryRow
                title="diagnosis.msa2022.exclusionCriteriaAreAbsent"
                value={checkExclusionCriteriaAbsent(formData.document)}
                marginBottom="7rem"
                fm={fm}
              />
            ),
          },
          diagnosticCriteriaForClinicallyEstablishedMSAIsMet: {
            header: (
              <MakeSummaryRow
                title="diagnosis.msa2022.diagnosticCriteriaForClinicallyEstablishedMSAIsMet"
                value={checkDiagnosticCriteriaForClinicallyEstablishedMSA(formData.document)}
                marginBottom="7rem"
                fm={fm}
              />
            ),
          },
          headerClinicallyProbableMSA: {
            header: <SubHeader>{fm('diagnosis.msa2022.clinicallyProbableMSA')}</SubHeader>,
          },
          essentialFeaturesAreMet2: {
            header: (
              <MakeSummaryRow
                title="diagnosis.msa2022.essentialFeaturesAreMet"
                value={formData.document.essentialFeatures?.sporadicProgressiveAdultOnsetDisease}
                fm={fm}
              />
            ),
          },
          coreClinicalFeaturesAreMet2: {
            header: (
              <MakeSummaryRow
                title="diagnosis.msa2022.coreClinicalFeaturesAreMet"
                value={checkCoreClinicalFeaturesForClinicallyProbableMSA(formData.document)}
                fm={fm}
              />
            ),
          },
          supportiveClinicalFeaturesAreMet2: {
            header: (
              <MakeSummaryRow
                title="diagnosis.msa2022.supportiveClinicalFeaturesAreMet"
                value={checkCoreClinicalFeaturesForClinicallyProbableMSA(formData.document)}
                fm={fm}
              />
            ),
          },
          exclusionCriteriaAreAbsent2: {
            header: (
              <MakeSummaryRow
                title="diagnosis.msa2022.exclusionCriteriaAreAbsent"
                value={checkExclusionCriteriaAbsent(formData.document)}
                marginBottom="7rem"
                fm={fm}
              />
            ),
          },
          diagnosticCriteriaForClinicallyProbableMSAIsMet: {
            header: (
              <MakeSummaryRow
                title="diagnosis.msa2022.diagnosticCriteriaForClinicallyProbableMSAIsMet"
                value={checkDiagnosticCriteriaForClinicallyProbableMSA(formData.document)}
                fm={fm}
              />
            ),
          },
        }}
      </FormSectionHistoryAcceptor>
      {/** Reference text */}
      <Container>
        <Item style={{ padding: '2rem 0' }}>
          <ReferenceText>
            Wenning GK, Stankovic I, Vignatelli L, Fanciulli A, Calandra-Buonaura G, Seppi K, Palma JA, Meissner WG,
            Krismer F, Berg D, Cortelli P, Freeman R, Halliday G, Höglinger G, Lang A, Ling H, Litvan I, Low P, Miki Y,
            Panicker J, Pellecchia MT, Quinn N, Sakakibara R, Stamelou M, Tolosa E, Tsuji S, Warner T, Poewe W, Kaufmann
            H. The Movement Disorder Society Criteria for the Diagnosis of Multiple System Atrophy. Mov Disord. 2022
            Jun;37(6):1131-1148.
          </ReferenceText>
        </Item>
      </Container>
    </React.Fragment>
  );
};

export default withFormContext(MSAForm2022);
