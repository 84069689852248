import * as React from 'react';
import HistoryRowVerticalItem from 'Components/HistoryRowVerticalItem';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import { DoctorsOrdersContext } from '../..';
import TreatmentsTable from '../Components';
import { subjectOfTreatmentTitle } from 'Utility/ninmtUtil';

const TDCSHistory = ({ d }: ITDCSHistory): JSX.Element => (
  <DoctorsOrdersContext.Consumer>
    {({ fm }) => {
      return (
        d.tdcs && (
          <React.Fragment>
            <HistoryRowSubHeader header={fm('doctorsOrders.tdcs.header')} />
            <HistoryRowVerticalItem
              header={fm('doctorsOrders.tdcs.endTDCS.title')}
              value={d.tdcs.endTDCS ? fm('general.yes') : '-'}
              condition={!!d.tdcs.endTDCS}
            />
            {!d.tdcs.endTDCS && (
              <React.Fragment>
                <HistoryRowVerticalItem
                  header={fm('doctorsOrders.tdcs.treatmentFrequency.title')}
                  value={d.tdcs.treatmentFrequency ?? '-'}
                  condition={!!d.tdcs.treatmentFrequency}
                />
                <HistoryRowVerticalItem
                  header={fm('doctorsOrders.tdcs.treatmentMonitoringFrequency.title')}
                  value={
                    d.tdcs.treatmentMonitoringFrequency
                      ? fm(
                          `doctorsOrders.tdcs.treatmentMonitoringFrequency.opts.${d.tdcs.treatmentMonitoringFrequency}`,
                        )
                      : '-'
                  }
                  condition={!!d.tdcs.treatmentMonitoringFrequency}
                />
                {!d.tdcs.doesNotApplyToSubjectOfTreatment && (
                  <React.Fragment>
                    <TreatmentsTable document={d} treatmentType="tdcs" treatmentName="subjectOfTreatment" />
                    <HistoryRowVerticalItem
                      header={fm('doctorsOrders.tdcs.backupSubjectOfTreatment.title')}
                      value={d.tdcs.backupSubjectOfTreatment?.map((s) => subjectOfTreatmentTitle(s, 'tdcs', fm))}
                      condition={Array.isArray(d.tdcs.backupSubjectOfTreatment)}
                    />
                    <HistoryRowVerticalItem
                      header={fm('doctorsOrders.tdcs.additionalInformation.title')}
                      value={d.tdcs.additionalInformation ? d.tdcs.additionalInformation : '-'}
                      condition={!!d.tdcs.additionalInformation}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            <HistoryRowVerticalItem
              header={fm('doctorsOrders.tdcs.otherOrder.title')}
              value={d.tdcs.otherOrder}
              condition={!!d.tdcs.otherOrder}
            />
          </React.Fragment>
        )
      );
    }}
  </DoctorsOrdersContext.Consumer>
);

interface ITDCSHistory {
  d: IDoctorsOrder;
  mode?: 'default' | 'review';
}

export default TDCSHistory;
