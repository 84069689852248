import * as React from 'react';
import { exists, formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import { fm } from 'Components/FormatMessage';
import EventStepper from '../../../../../../components/EventStepper';
import InputHandler from '../../../../../../components/InputHandler';
import { papSettingsFields } from '../../../definitions';
import { defaultPapSettingsUnits } from '../../../config';
import {
  StepperHeaderFormInputPair,
  StepperHeaderValuePair,
} from '../../../../../../components/EventStepper/components';
import { FormattedMessage } from 'react-intl';
import Unit from '../../../../../../components/Unit';

const Settings = ({ formData, viewing }: IOwnProps): JSX.Element => {
  const latestDevice =
    Array.isArray(formData.document.devices) &&
    formData.document.devices.length > 0 &&
    formData.document.devices.find((d) => d.type) &&
    (formData.document.devices || [])
      .filter((d) => d.type)
      .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
      .reverse()[0].id;

  return (
    <EventStepper
      name="settings"
      formData={formData}
      stepLabelText={(d: IPapSetting): string => formatPartialDate(d.date)}
      stepContent={(d: IPapSetting): JSX.Element => {
        const device = d.deviceId
          ? (formData.document?.devices || []).find((device) => device.id === d.deviceId)?.type
          : undefined;
        return (
          <React.Fragment>
            {device && (
              <StepperHeaderValuePair
                header={fm('treatment.papTherapy.device')}
                value={fm('treatment.papTherapy.opts.' + device)}
              />
            )}
            {device &&
              papSettingsFields[device].map((field, index) => {
                const value = d[field.name as keyof IPapSetting];
                return (field.condition && field.condition(d)) || !field.condition ? (
                  <StepperHeaderValuePair
                    key={`${field.name}${index}`}
                    header={fm(`treatment.papTherapy.${field.title ?? field.name}`)}
                    value={
                      exists(value) ? (
                        field.name === 'epapAuto' ? (
                          fm(`treatment.papTherapy.opts.${value}`)
                        ) : (
                          <span>
                            {value}{' '}
                            {defaultPapSettingsUnits[field.name] === 'inhalationsPerMinute'
                              ? fm('treatment.papTherapy.inhalationsPerMinute')
                              : defaultPapSettingsUnits[field.name] ?? ''}
                          </span>
                        )
                      ) : (
                        '-'
                      )
                    }
                  />
                ) : null;
              })}
          </React.Fragment>
        );
      }}
      addNewTextHeader="treatment.papTherapy.newSettings"
      addNewTextButton="treatment.papTherapy.newSettings"
      buttonDisabled={!latestDevice}
      buttonDisabledMessage={fm('treatment.papTherapy.addDeviceFirst')}
      previousEventsTextHeader="treatment.papTherapy.previousSettings"
      noPreviousEventsTextHeader="treatment.papTherapy.noPreviousSettings"
      editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
        // Set type of the device selected into variable named 'device'
        const device = formData.document.devices?.find((d) => d.id === formData.document.settings?.[index]?.deviceId)
          ?.type;
        // Set all the devices' ids into a list named 'devices'
        const devices: string[] = (formData.document.devices || [])
          .filter((d) => d.type && isPartialDate(d.date))
          .map((d) => d.id);
        return (
          <React.Fragment>
            <StepperHeaderFormInputPair
              header={fm('treatment.papTherapy.device')}
              input={
                <InputHandler
                  type="Select"
                  editing={true}
                  name="deviceId"
                  formData={{ onChange, document: { deviceId: formData.document.settings?.[index]?.deviceId || '' } }}
                  options={devices}
                  optionFormatter={(name: React.ReactText): JSX.Element => {
                    const device = (formData.document.devices || []).find((d) => d.id === name);
                    if (!device) return <span>{''}</span>;
                    return (
                      <span>
                        <FormattedMessage id={`treatment.papTherapy.opts.${device?.type}`} />
                        {` - ${formatPartialDate(device?.date)}`}
                      </span>
                    );
                  }}
                />
              }
            />
            <StepperHeaderFormInputPair
              header={fm('treatment.papTherapy.settingsDate')}
              input={
                <InputHandler
                  type="PartialDate"
                  editing={true}
                  name="date"
                  formData={{
                    onChange,
                    document: { date: formData.document.settings?.[index]?.date || '' },
                  }}
                  dateDefault={
                    formData.document.settings && formData.document.settings.length === 1
                      ? formData.document.date
                      : 'now'
                  }
                  isNotCancellable={true}
                  dateHook={{
                    dateHookFloor: (formData.document.devices || []).find(
                      (d) => d.id === formData.document.settings?.[index]?.deviceId,
                    )?.date,
                  }}
                />
              }
            />
            {device &&
              papSettingsFields[device].map(
                (
                  field: {
                    name: string;
                    type: 'NumberField' | 'Select' | 'TextField' | 'Radio';
                    options?: (string | number)[];
                    title?: string;
                    condition?: (formData?: IPapSetting) => boolean;
                  },
                  i: number,
                ): JSX.Element =>
                  (field.condition && field.condition(formData.document.settings?.[index])) || !field.condition ? (
                    <StepperHeaderFormInputPair
                      key={`${field.name}${i}`}
                      header={fm(`treatment.papTherapy.${field.title ?? field.name}`)}
                      input={
                        <Unit
                          unit={
                            defaultPapSettingsUnits[field.name] === 'inhalationsPerMinute'
                              ? fm('treatment.papTherapy.inhalationsPerMinute')
                              : defaultPapSettingsUnits[field.name] ?? ''
                          }
                          fontWeight={viewing ? 'bold' : 'normal'}
                        >
                          <InputHandler
                            type={field.type}
                            editing={true}
                            name={field.name}
                            formData={{
                              onChange,
                              document: {
                                [field.name]: exists(
                                  formData.document.settings?.[index]?.[field.name as keyof IPapSetting],
                                )
                                  ? formData.document.settings?.[index]?.[field.name as keyof IPapSetting]
                                  : '',
                              },
                            }}
                            options={field.options}
                            optionFormatter={
                              field.name === 'epapAuto'
                                ? (name: string | number): JSX.Element => fm(`treatment.papTherapy.opts.${name}`)
                                : undefined
                            }
                            precision={2}
                            width={field.type === 'NumberField' ? 7 : 12}
                          />
                        </Unit>
                      }
                    />
                  ) : (
                    <React.Fragment key={`${field.name}${i}`}></React.Fragment>
                  ),
              )}
          </React.Fragment>
        );
      }}
      viewing={viewing}
      defaultValues={{ deviceId: latestDevice }}
    />
  );
};

interface IOwnProps {
  formData: IFormData<IPAPTherapy>;
  viewing: boolean;
}

export default Settings;
