import * as React from 'react';
import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import { optionFormatter } from '../../../utils/functions';

/** Switch an allele's lineage from maternal to paternal or vice verca. */
const switchLineage = (allele: IAllele, fromLineage: IAllele['lineage']): IAllele => {
  if (fromLineage === 'maternal') return { ...allele, lineage: 'paternal' };
  else if (fromLineage === 'paternal') return { ...allele, lineage: 'maternal' };
  else return allele;
};

/** Substitute changed value (lineage or CAG period length) to previous value. */
function substitute(
  base: Required<IHTT>['cagRepeatLengths'],
  changedEntry: { idx: number; name: string; value: any },
): IHTT['cagRepeatLengths'] {
  // update an allele - changing lineage affects the other allele too
  const arr = base.map<IAllele>((n, i) => {
    // change value of the current allele
    if (i === changedEntry.idx) {
      return { ...base[i], [changedEntry.name]: changedEntry.value };
    }
    // switch the other allele's lineage so that both alleles are not from same lineage
    else return changedEntry.name === 'lineage' ? switchLineage(n, changedEntry.value) : n;
  });
  // TS doesn't preserve tuple length in `map()` -> must assert arr to tuple
  return arr as typeof base;
}

/**
 * Initialize allele data in document or update its value. The data is a
 * tuple of exactly two alleles, and this function updates one of them,
 * except when lineage is changed, in which case both alleles need to be
 * updated.
 */
const onChangeAllele =
  (onChange: IFormData['onChange'], prevSuperVal: IHTT['cagRepeatLengths'] | undefined) =>
  (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];
    const [superName, _idx, thisName] = name.split('.');
    // define initial value
    const alleleInitVal: IAllele = { lineage: 'unknown', cagLength: null };
    const defaultSuperVal: IHTT['cagRepeatLengths'] = [{ ...alleleInitVal }, { ...alleleInitVal }];
    // attempt to parse index of allele within the tuple
    if (isNaN(parseInt(_idx))) return;
    const idx: number = parseInt(_idx);
    // substitute changed val either to previous val or default val if not initialized
    const nextSuperVal = prevSuperVal
      ? substitute(prevSuperVal, { idx, name: thisName, value })
      : substitute(defaultSuperVal, { idx, name: thisName, value });
    onChange?.({ [superName]: nextSuperVal });
  };

const HTTForm = ({ editing, formData }: IFormContext): JSX.Element => {
  return (
    <React.Fragment>
      <div style={{ marginBottom: '5rem' }} />
      <FormRow title="general.date">
        <InputHandler type="PartialDate" editing={!!editing} name="date" formData={formData} dateDefault="now" />
      </FormRow>
      <FormRow title="diagnosis.huntington.htt.title">
        <InputHandler
          type="Radio"
          editing={!!editing}
          name="status"
          formData={formData}
          options={['done', 'notDone', 'unknown']}
          optionFormatter={optionFormatter('huntington.htt.opts.doneNotDoneUnknown')}
        />
      </FormRow>
      {formData?.document.status === 'done' && (
        <React.Fragment>
          <FormSection header="diagnosis.huntington.htt.cagRepeatLengths">
            <FormRow title="diagnosis.huntington.htt.allele" titleValues={{ alleleId: '1' }}>
              <InputHandler
                type="NumberField"
                editing={!!editing}
                name="cagRepeatLengths.0.cagLength"
                formData={{
                  document: formData.document,
                  onChange: onChangeAllele(formData.onChange, formData.document.cagRepeatLengths),
                }}
              />
            </FormRow>
            <FormRow title="diagnosis.huntington.htt.whoseAllele">
              <InputHandler
                type="Radio"
                editing={!!editing}
                name="cagRepeatLengths.0.lineage"
                formData={{
                  document: formData.document,
                  onChange: onChangeAllele(formData.onChange, formData.document.cagRepeatLengths),
                }}
                options={['maternal', 'paternal', 'unknown']}
                optionFormatter={optionFormatter('huntington.htt.opts.alleleLine')}
              />
            </FormRow>
            <FormRow title="diagnosis.huntington.htt.allele" titleValues={{ alleleId: '2' }}>
              <InputHandler
                type="NumberField"
                editing={!!editing}
                name="cagRepeatLengths.1.cagLength"
                formData={{
                  document: formData.document,
                  onChange: onChangeAllele(formData.onChange, formData.document.cagRepeatLengths),
                }}
              />
            </FormRow>
          </FormSection>
          <FormSection header="diagnosis.huntington.htt.httHaplotype">
            <FormRow title="diagnosis.huntington.htt.httHaplotype">
              <InputHandler
                type="Radio"
                editing={!!editing}
                name="httHaplotype"
                formData={formData}
                options={['A', 'B', 'C', 'other']}
                optionFormatter={optionFormatter('huntington.htt.opts.httHaplotype')}
              />
            </FormRow>
            {formData.document.httHaplotype === 'other' && (
              <FormRow title="diagnosis.huntington.htt.httHaplotypeOther">
                <InputHandler type="TextField" editing={!!editing} name="httHaplotypeOther" formData={formData} />
              </FormRow>
            )}
            <FormRow title="diagnosis.huntington.htt.httHaplotypeAdditionalInformation">
              <InputHandler
                type="TextArea"
                editing={!!editing}
                name="httHaplotypeAdditionalInformation"
                formData={formData}
              />
            </FormRow>
          </FormSection>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default withFormContext(HTTForm);
