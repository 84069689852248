import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate } from 'neuro-utils';

import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';

const BAEP = ({ documents, startEdit }: IOwnProps): JSX.Element => {
  return (
    <HistorySection
      headerText={<FormattedMessage id={'clinicalstudy.baep'} />}
      newButton={
        <DocumentCreationButton
          name="baep"
          text={'clinicalstudy.newbaep'}
          onClick={startEdit({}, 'baep')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      <HistoryRowListing
        documents={documents}
        headers={
          <>
            <Item xs={2}>
              <FormattedMessage id={'general.date'} />
            </Item>
            <Item xs={8} />
          </>
        }
        contentFormat={(d: IBAEP): JSX.Element => (
          <>
            <Item xs={2} style={{ fontWeight: '600' }}>
              {formatPartialDate(d.date ?? undefined)}
            </Item>
            <Item xs={7} />
            <Item xs={3}>
              <HistoryRowControls document={d} startEdit={startEdit} />
            </Item>
          </>
        )}
      />
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IBAEP[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default BAEP;
