import { calculateDaysDifference, exists } from 'neuro-utils';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import Unit from '../../../../../components/Unit';
import { IFormContext, withFormContext } from '../../../../../containers/FormContextHandler';
import {
  checkIfAcuteTreatmentStart,
  checkIfCurrentDocumentIsTheFirstTreatment,
  getDateOfReferral,
  showDelay,
} from '../../../utils';

const locPath = 'treatment.patientDoesNotWantRespiratorySupportTherapy';

const PatientDoesNotWantRespiratorySupportTherapy = ({
  formData,
  view,
  documents = [],
  editing,
}: IFormContext<IPatientDoesNotWantRespiratorySupportTherapy, any>): JSX.Element => {
  const thisDocument = formData.document;
  const referralDate = getDateOfReferral(documents, 'respiratoryFirstVisit');
  const isAcute = checkIfAcuteTreatmentStart(documents, 'respiratoryFirstVisit');
  const isFirstDoc = checkIfCurrentDocumentIsTheFirstTreatment(documents, view, editing);

  const delayFromSuspicionToTreatmentStart = calculateDaysDifference(referralDate, thisDocument.date);

  return (
    <React.Fragment>
      <FormRow title={`${locPath}.startDate`}>
        <InputHandler
          type="PartialDate"
          editing={!view?.viewing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
          dateHook={{ dateHookCeiling: formData.document.endDate }}
        />
      </FormRow>
      <FormRow title={`${locPath}.delayFromSuspicionToTreatmentStart`} condition={showDelay(isAcute, isFirstDoc)}>
        <Unit unit={<FormattedMessage id="treatment.daysPlaceholder" />} fontWeight={view?.viewing ? 'bold' : 'normal'}>
          {exists(delayFromSuspicionToTreatmentStart) ? (delayFromSuspicionToTreatmentStart as number) : '-'}
        </Unit>
      </FormRow>
      <FormRow title={`${locPath}.endDate`}>
        <InputHandler
          type="PartialDate"
          editing={!view?.viewing}
          name="endDate"
          formData={formData}
          dateHook={{ dateHookFloor: formData.document.date }}
        />
      </FormRow>
    </React.Fragment>
  );
};

export default withFormContext(PatientDoesNotWantRespiratorySupportTherapy);
