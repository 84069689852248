import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../../components/FormRow';
import FormSection from '../../../../../components/FormSection';
import InputHandler from '../../../../../components/InputHandler';
import { methodsOfContraception } from '../../../utils';

const optionFormatter =
  (opt: number | string) =>
  (name: number | string): JSX.Element => <FormattedMessage id={`comorbidity.opts.${opt}.${name}`} />;

const ContraceptionForm = ({ editing, formData }: IOwnProps): JSX.Element => (
  <React.Fragment>
    <FormSection>
      <FormRow title="comorbidity.startDate">
        <InputHandler
          type="PartialDate"
          name="date"
          editing={editing}
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
          dateHook={{ dateHookCeiling: formData.document.endDate }}
        />
      </FormRow>
      <FormRow title="comorbidity.endDate">
        <InputHandler
          type="PartialDate"
          name="endDate"
          editing={editing}
          formData={formData}
          dateHook={{ dateHookFloor: formData.document.date }}
        />
      </FormRow>
    </FormSection>
    <FormSection>
      <FormRow title="comorbidity.classification">
        <InputHandler
          type="Radio"
          name="contraceptiveClassification"
          editing={editing}
          formData={{
            onChange: (values: TOnChangeValues): void => {
              if (formData.onChange) {
                formData.onChange(values);
                formData.onChange?.({
                  contraceptive: undefined,
                });
              }
            },
            document: formData.document,
          }}
          options={Object.keys(methodsOfContraception)}
          optionFormatter={optionFormatter('contraceptiveClassification')}
        />
      </FormRow>
      {formData.document.contraceptiveClassification === 'noContraception' && (
        <FormRow title="comorbidity.cause">
          <InputHandler type="TextArea" name="cause" editing={editing} formData={formData} />
        </FormRow>
      )}
      {formData.document.contraceptiveClassification &&
        formData.document.contraceptiveClassification !== 'noContraception' && (
          <FormRow title="comorbidity.methodOfContraception">
            <InputHandler
              type="Radio"
              name="contraceptive"
              editing={editing}
              formData={formData}
              options={methodsOfContraception[formData.document.contraceptiveClassification]}
              optionFormatter={optionFormatter('contraceptive')}
            />
          </FormRow>
        )}
    </FormSection>
  </React.Fragment>
);

interface IOwnProps {
  editing?: boolean;
  formData: IFormData<IContraception>;
}

export default ContraceptionForm;
