import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Item } from '../../../../components/Grid';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRowListing from '../../../../components/HistoryRowListing';
import HistorySection from '../../../../components/HistorySection';
import InfoPopover from '../../../../components/InfoPopover';

import { calculateLevodopaDBS, updrsScoreSum, getIIIDoc, TDocument, isUpdrsComplete } from '../../utils';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';
import { exists } from 'neuro-utils';

const DBSEffect = ({ documents, allDocuments, startEdit, view }: IOwnProps): JSX.Element => (
  <HistorySection
    headerText={<FormattedMessage id={'updrs.dbsEffect'} />}
    newButton={
      <DocumentCreationButton
        name="dbsEffect"
        text={'updrs.newDBSEffect'}
        onClick={startEdit({}, 'dbsEffect')}
        width={15}
        height={3}
        fontSize={14}
        alternate={true}
        creationConfirmationText={
          documents.length > 0 &&
          !(exists(calculateLevodopaDBS(allDocuments, documents[0])) || exists(documents?.[0]?.manualScore)) ? (
            <FormattedMessage id="updrs.completeOrCreateNew" />
          ) : undefined
        }
        creationConfirmationButtons={{
          cancel: {
            callback: () => startEdit(documents[0] as { [key: string]: any }, 'dbsEffect'),
            text: <FormattedMessage id="updrs.complete" />,
          },
          confirm: { text: 'updrs.createNew' },
        }}
      />
    }
  >
    <HistoryRowListing
      documents={documents}
      headers={
        <>
          <Item xs={4} style={{ whiteSpace: 'pre-wrap' }}>
            <FormattedMessage id={'updrs.updrsIIIBeforeDBS'} />
          </Item>
          <Item xs={4} style={{ whiteSpace: 'pre-wrap' }}>
            <FormattedMessage id={'updrs.updrsIIIAfterDBS'} />
          </Item>
          <Item xs={4}>
            <FormattedMessage id={'updrs.dbsEffectScore'} />
          </Item>
        </>
      }
      contentFormat={(d: IDBSEffect): JSX.Element => {
        const updrsIIIBeforeDoc = getIIIDoc(d['updrsIIIBeforeID'], allDocuments);
        const updrsIIIAfterDoc = getIIIDoc(d['updrsIIIAfterID'], allDocuments);
        return (
          <>
            <Item xs={4}>
              {updrsIIIBeforeDoc?.retrospectiveData?.[0] !== true &&
              isUpdrsComplete(updrsIIIBeforeDoc, d.testType) &&
              (updrsScoreSum(updrsIIIBeforeDoc) || updrsScoreSum(updrsIIIBeforeDoc) === 0) ? (
                <div>
                  {updrsScoreSum(updrsIIIBeforeDoc)}{' '}
                  <FormattedMessage id={`updrs.${d.testType === 'UPDRS' ? 'manualScale' : 'mdsScale'}`} />
                </div>
              ) : updrsIIIBeforeDoc?.retrospectiveData?.[0] === true &&
                (updrsIIIBeforeDoc?.manualScore || updrsIIIBeforeDoc?.manualScore === 0) ? (
                <div>
                  {updrsIIIBeforeDoc?.manualScore} <FormattedMessage id="updrs.manualScale" />
                </div>
              ) : (
                <InfoPopover text="general.notFilled" color="primary" />
              )}
            </Item>
            <Item xs={4}>
              {updrsIIIAfterDoc?.retrospectiveData?.[0] !== true &&
              isUpdrsComplete(updrsIIIAfterDoc, d.testType) &&
              (updrsScoreSum(updrsIIIAfterDoc) || updrsScoreSum(updrsIIIAfterDoc) === 0) ? (
                <div>
                  {updrsScoreSum(updrsIIIAfterDoc)}{' '}
                  <FormattedMessage id={`updrs.${d.testType === 'UPDRS' ? 'manualScale' : 'mdsScale'}`} />
                </div>
              ) : updrsIIIAfterDoc?.retrospectiveData?.[0] === true &&
                (updrsIIIAfterDoc?.manualScore || updrsIIIAfterDoc?.manualScore === 0) ? (
                <div>
                  {updrsIIIAfterDoc?.manualScore} <FormattedMessage id="updrs.manualScale" />
                </div>
              ) : (
                <InfoPopover text="general.notFilled" color="primary" />
              )}
            </Item>
            <Item xs={4}>
              {(calculateLevodopaDBS(allDocuments, d) || calculateLevodopaDBS(allDocuments, d) === 0) &&
              isUpdrsComplete(updrsIIIBeforeDoc, d.testType) &&
              isUpdrsComplete(updrsIIIAfterDoc, d.testType)
                ? Math.round((calculateLevodopaDBS(allDocuments, d) || 0) * 100) / 100 + ' %'
                : d.manualScore || d.manualScore === 0
                  ? d.manualScore + ' %'
                  : '-'}
            </Item>
          </>
        );
      }}
      makeDate
      makeControls
      historyRowControls={(d: IDBSEffect) => (
        <HistoryRowControls
          document={d}
          startEdit={startEdit}
          view={exists(calculateLevodopaDBS(allDocuments, d)) || exists(d?.manualScore) ? view : undefined}
          subDocs={[
            d.updrsIIIAfterID ? { name: 'updrs_iii', id: d.updrsIIIAfterID } : undefined,
            d.updrsIIIBeforeID ? { name: 'updrs_iii', id: d.updrsIIIBeforeID } : undefined,
          ]}
        />
      )}
    />
  </HistorySection>
);

interface IOwnProps {
  documents: IDBSEffect[];
  allDocuments: Array<TDocument>;
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default DBSEffect;
