import { exists } from 'neuro-utils';
import { resolvePath } from '../../utils';

const allNormal = (values: { [key: string]: any }): boolean => {
  return Object.keys(values).filter((key) => {
    return (
      typeof values[key] === 'object' &&
      values[key] !== null &&
      Object.keys(values[key]).filter((key2) => {
        return values[key][key2] && values[key][key2] !== 0 && values[key][key2] !== 'notDone';
      }).length > 0
    );
  }).length > 0
    ? false
    : true;
};

const allNotDoneOrUndefined = (values: { [key: string]: any }): boolean => {
  return Object.keys(values).filter((key) => {
    return typeof values?.[key] === 'object' && values?.[key] !== null
      ? !Object.keys(values?.[key]).every((key2) => !exists(values?.[key][key2]) || values?.[key][key2] === 'notDone')
      : !(!exists(values?.[key]) || values?.[key] === 'notDone');
  }).length > 0
    ? false
    : true;
};

const getLimbScore = (values: { [key: string]: any }, sense: string, limb: string): number => {
  return resolvePath(`${sense}.${limb.substring(0, 5)}Extremities${limb.substring(5)}`, values)
    ? resolvePath(`${sense}.${limb.substring(0, 5)}Extremities${limb.substring(5)}`, values)
    : 0;
};

const getTrunkScore = (values: { [key: string]: any }, side: string): number => {
  return resolvePath(`superficialSense.trunk${side}`, values)
    ? resolvePath(`superficialSense.trunk${side}`, values)
    : 0;
};

const sensoryCalculator = (sensoryFunctions: { [key: string]: any }): number | undefined => {
  const s = sensoryFunctions;
  const limbs = ['upperRight', 'upperLeft', 'lowerRight', 'lowerLeft'];

  const checkTrunkScore = (side: string): number => {
    return getTrunkScore(s, side);
  };

  const checkLimbScores = (sense: string, maxValue: number, level?: string, side?: string): number => {
    switch (level) {
      case 'upper':
        return side
          ? limbs
              .slice(side === 'Right' ? 0 : 1, side === 'Right' ? 1 : 2)
              .map((limb) => getLimbScore(s, sense, limb))
              .filter((score) => {
                return score > maxValue;
              }).length
          : limbs
              .slice(0, 2)
              .map((limb) => getLimbScore(s, sense, limb))
              .filter((score) => {
                return score > maxValue;
              }).length;
      case 'lower':
        return side
          ? limbs
              .slice(side === 'Right' ? 2 : 3, side === 'Right' ? 3 : 4)
              .map((limb) => getLimbScore(s, sense, limb))
              .filter((score) => {
                return score > maxValue;
              }).length
          : limbs
              .slice(2, 4)
              .map((limb) => getLimbScore(s, sense, limb))
              .filter((score) => {
                return score > maxValue;
              }).length;
      default:
        return limbs
          .map((limb) => getLimbScore(s, sense, limb))
          .filter((score) => {
            return score > maxValue;
          }).length;
    }
  };

  const lossOfSensation = (): number => {
    return (
      Number(
        checkLimbScores('superficialSense', 4, 'upper', 'Right') +
          checkLimbScores('vibrationSense', 2, 'upper', 'Right') +
          checkLimbScores('positionSense', 2, 'upper', 'Right') >
          1,
      ) +
      Number(
        checkLimbScores('superficialSense', 4, 'upper', 'Left') +
          checkLimbScores('vibrationSense', 2, 'upper', 'Left') +
          checkLimbScores('positionSense', 2, 'upper', 'Left') >
          1,
      ) +
      Number(
        checkLimbScores('superficialSense', 4, 'lower', 'Right') +
          checkLimbScores('vibrationSense', 2, 'lower', 'Right') +
          checkLimbScores('positionSense', 2, 'lower', 'Right') >
          1,
      ) +
      Number(
        checkLimbScores('superficialSense', 4, 'lower', 'Left') +
          checkLimbScores('vibrationSense', 2, 'lower', 'Left') +
          checkLimbScores('positionSense', 2, 'lower', 'Left') >
          1,
      )
    );
  };

  if (allNormal(s)) {
    if (allNotDoneOrUndefined(s)) {
      return undefined;
    }
    return 0;
  }
  if (
    checkLimbScores('superficialSense', 1) > 0 ||
    checkLimbScores('vibrationSense', 1) > 0 ||
    checkLimbScores('vibrationSense', 0) > 1 ||
    checkLimbScores('positionSense', 0) > 0 ||
    checkTrunkScore('Right') > 1 ||
    checkTrunkScore('Left') > 1
  ) {
    if (
      checkLimbScores('superficialSense', 2) > 0 ||
      checkLimbScores('superficialSense', 1) > 2 ||
      checkLimbScores('vibrationSense', 2) > 0 ||
      checkLimbScores('vibrationSense', 1) > 2 ||
      checkLimbScores('positionSense', 1) > 0
    ) {
      if (
        checkLimbScores('superficialSense', 3) > 0 ||
        checkLimbScores('superficialSense', 2) > 2 ||
        checkLimbScores('superficialSense', 2, 'upper') +
          checkLimbScores('vibrationSense', 2, 'lower') +
          checkLimbScores('positionSense', 2, 'lower') >
          2 ||
        checkLimbScores('superficialSense', 2, 'lower') +
          checkLimbScores('vibrationSense', 2, 'upper') +
          checkLimbScores('positionSense', 2, 'upper') >
          2 ||
        checkLimbScores('vibrationSense', 2) + checkLimbScores('positionSense', 2) > 2
      ) {
        if (
          lossOfSensation() > 0 ||
          checkLimbScores('superficialSense', 2) +
            checkLimbScores('vibrationSense', 2) +
            checkLimbScores('positionSense', 2) +
            Number(checkTrunkScore('Right') > 4) +
            Number(checkTrunkScore('Left') > 4) >
            3
        ) {
          if (lossOfSensation() + Number(checkTrunkScore('Right') > 3) + Number(checkTrunkScore('Left') > 3) > 4) {
            return 6;
          }
          return 5;
        }
        return 4;
      }
      if (lossOfSensation() > 0) {
        return 5;
      }
      if (
        checkLimbScores('superficialSense', 2) +
          checkLimbScores('vibrationSense', 2) +
          checkLimbScores('positionSense', 2) +
          Number(checkTrunkScore('Right') > 4) +
          Number(checkTrunkScore('Left') > 4) >
        3
      ) {
        return 5;
      }
      return 3;
    }
    return 2;
  }
  return 1;
};

export default sensoryCalculator;
