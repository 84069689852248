import { exists } from 'neuro-utils';

const cerebralCalculator = (cerebralFunctions: { [key: string]: any }): number | undefined => {
  const c = cerebralFunctions;

  const isNormal = (value: number | string | undefined): boolean => {
    return !value || value === 0 || value === 'notDone';
  };

  const isNotDoneOrUndefined = (value: number | string | undefined): boolean => {
    return !exists(value) || value === 'notDone';
  };

  if (isNormal(c?.decreaseInMentation) && isNormal(c?.fatigue)) {
    if (isNotDoneOrUndefined(c?.decreaseInMentation) && isNotDoneOrUndefined(c?.fatigue)) {
      return undefined;
    }
    return 0;
  }
  if (c?.decreaseInMentation > 1 || c?.fatigue > 1) {
    if (c?.decreaseInMentation > 2) {
      if (c?.decreaseInMentation > 3) {
        if (c?.decreaseInMentation > 4) {
          return 5;
        }
        return 4;
      }
      return 3;
    }
    return 2;
  }
  return 1;
};

export default cerebralCalculator;
