import * as React from 'react';

import DocumentHeader from '../../../components/DocumentHeader';
import DocumentWrapper from '../../../components/DocumentWrapper';
import FormEditingHandler from '../../../containers/FormEditingHandler';

import Form from './Form';
import ProceduresHistory from './History';

const getHeaderId = (editing?: string, view?: IView, procedureCode?: string) => {
  const openDoc = editing || view?.viewing;
  if (openDoc && procedureCode === '19647005') {
    return 'procedure.plasmaChange';
  }
  if (openDoc && procedureCode === '399750009') {
    return 'procedure.thymectomy';
  }
  return 'procedure.title';
};

const Procedure = ({ documents }: IOwnProps): JSX.Element => {
  const [procedureCode, setProcedureCode] = React.useState<string>('');

  return (
    <FormEditingHandler name="procedure" documents={documents}>
      {(editing, startEdit, formData, view): JSX.Element => (
        <DocumentWrapper>
          <DocumentHeader name={'procedure'} headerId={getHeaderId(editing, view, procedureCode)} editing={editing} />
          {editing ? <Form formData={formData} editing={editing} procedureCode={procedureCode} /> : null}
          {!editing && !view?.viewing ? (
            <ProceduresHistory documents={documents} startEdit={startEdit} setProcedureCode={setProcedureCode} />
          ) : null}
        </DocumentWrapper>
      )}
    </FormEditingHandler>
  );
};

interface IOwnProps {
  documents: Array<IProcedure>;
}

export default Procedure;
