import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import Unit from 'Components/Unit';
import * as React from 'react';
import { RTMSContext } from 'Routes/Rtms/Document';
import { styled } from '@mui/system';
import { defaultRTMSTreatmentOperators } from 'Routes/Rtms/Document/config';
import { exists } from 'neuro-utils';
import colors from '../../../../../../../config/theme/colors';
import { equals } from 'ramda';
import {
  cTBSFields,
  iTBSFields,
  originalRTMSFields,
  originAndDateOfReference,
  subjectOfTreatmentTitle,
  valueDiffersFromDoctorsOrders,
} from 'Utility/ninmtUtil';
import ToolTip from 'Components/ToolTip';

const InlineDiv = styled('div')({
  display: 'inline-flex',
});

const InputContainer = styled('div')({
  fontWeight: 600,
  margin: '0 0 2.5rem 0.9rem',
});

// If this functionality is to be kept, it should be moved to utils (and exported to doctor's orders for example)
const calculateValue = (doc: IRTMSTreatment, name: 'numberOfPulses') => {
  const value = doc[name];
  if (!value || value === null) return;
  return Array.isArray(value) ? value.reduce((p, c) => (p ?? 0) + (!!c || c === 0 ? c : 0), 0) : 0;
};

const Treatment = ({ document, onChange, session, sessionNumber, editingEvent = true }: ITreatment): JSX.Element => {
  const onChangeArray =
    (index: number, init: Array<null | number>) =>
    (value: TOnChangeValues): void => {
      const name = Object.keys(value)[0];
      let newValues = JSON.parse(JSON.stringify(document[name as keyof IRTMSTreatment] ?? []));
      if (!Array.isArray(newValues) || newValues.length < init.length) {
        newValues = init;
      }
      newValues[index] = value[name];
      onChange({ [name]: newValues });
    };

  const placeholder = editingEvent ? '' : '-';

  const fieldsByStimulationType = {
    iTBS: iTBSFields,
    cTBS: cTBSFields,
    originalRTMS: originalRTMSFields,
  };

  const rtmsContext = React.useContext(RTMSContext);
  const { isEditing, fm } = rtmsContext;

  const currentIndex = session?.subjectOfTreatment?.findIndex((s) => equals(s, document));

  return (
    <div style={{ padding: '2rem 0 0 0' }}>
      {document.deleted ? (
        <React.Fragment>
          <FormRow title="rtms.subjectOfTreatment.treatmentDeleteReason">
            <InputContainer>
              <InputHandler
                type="Radio"
                name="deleteReason"
                editing={isEditing && editingEvent}
                formData={{
                  document: { deleteReason: document.deleteReason || placeholder },
                  onChange: onChange,
                }}
                options={['partOfTreatmentProtocol', 'otherReason']}
                optionFormatter={(id: string | number) => fm(`rtms.opts.treatmentDeleteReason.${id}`)}
              />
            </InputContainer>
          </FormRow>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {!editingEvent && (
            <React.Fragment>
              <FormRow title="rtms.subjectOfTreatment.name" bottomMargin={false}>
                <InputContainer style={{ fontWeight: 400 }}>
                  {subjectOfTreatmentTitle(document, 'rtms', fm)}
                </InputContainer>
              </FormRow>
              <FormRow title="rtms.subjectOfTreatment.copyTitle" bottomMargin={false}>
                <InputContainer style={{ fontWeight: 400 }}>
                  {originAndDateOfReference(document, 'rtms', fm)}
                </InputContainer>
              </FormRow>
            </React.Fragment>
          )}
          <React.Fragment>
            {fieldsByStimulationType[document.stimulationType ?? 'originalRTMS'].map((field: string) => {
              switch (field) {
                case 'pulseIntensity':
                case 'numberOfPulses':
                case 'electricFieldStrength': {
                  return (
                    <FormRow key={field} title={`rtms.subjectOfTreatment.${field}`} bottomMargin={false}>
                      <InputContainer>
                        <Unit unit={field === 'numberOfPulses' ? undefined : fm(`rtms.units.${field}`)}>
                          <React.Fragment>
                            {[null, null]
                              .concat(field === 'numberOfPulses' ? [null] : [])
                              .map((_: null, index: number, arr: null[]) => (
                                <React.Fragment key={`${field}${index}`}>
                                  <InlineDiv>
                                    <ToolTip
                                      title={fm('rtms.subjectOfTreatment.valueInDoctorsOrders')}
                                      description={
                                        <>
                                          {valueDiffersFromDoctorsOrders(
                                            session,
                                            currentIndex ?? 0,
                                            field,
                                            document[field]?.[index],
                                            index,
                                            true,
                                          ) ?? '-'}
                                        </>
                                      }
                                      cursor={
                                        field !== 'electricFieldStrength' &&
                                        valueDiffersFromDoctorsOrders(
                                          session,
                                          currentIndex ?? 0,
                                          field,
                                          document[field]?.[index],
                                          index,
                                        )
                                          ? 'Pointer'
                                          : 'Default'
                                      }
                                      hover={true}
                                      flipTitleAndDescStyle
                                      disabled={
                                        field === 'electricFieldStrength' ||
                                        !valueDiffersFromDoctorsOrders(
                                          session,
                                          currentIndex ?? 0,
                                          field,
                                          document[field]?.[index],
                                          index,
                                        )
                                      }
                                      content={
                                        <InputHandler
                                          type="NumberField"
                                          name={field}
                                          editing={isEditing && editingEvent}
                                          formData={{
                                            document: {
                                              [field]: exists(document[field]?.[index])
                                                ? document[field]?.[index]
                                                : placeholder,
                                            },
                                            onChange: onChangeArray(index, document[field] ?? arr),
                                          }}
                                          width={6}
                                          style={
                                            field !== 'electricFieldStrength' &&
                                            valueDiffersFromDoctorsOrders(
                                              session,
                                              currentIndex ?? 0,
                                              field,
                                              document[field]?.[index],
                                              index,
                                            )
                                              ? {
                                                  backgroundColor: colors.appBlue.default,
                                                  color: colors.white,
                                                }
                                              : undefined
                                          }
                                        />
                                      }
                                    ></ToolTip>
                                  </InlineDiv>
                                  {index < arr.length - 1 && (
                                    <InlineDiv style={{ margin: '0rem 1rem 0rem 1rem' }}>
                                      {defaultRTMSTreatmentOperators[field]}
                                    </InlineDiv>
                                  )}
                                </React.Fragment>
                              ))}
                            {field === 'numberOfPulses' ? (
                              <React.Fragment>
                                <InlineDiv style={{ margin: '0rem 1rem 0rem 1rem' }}>{' ='}</InlineDiv>
                                <InlineDiv>{calculateValue(document, field)}</InlineDiv>
                              </React.Fragment>
                            ) : undefined}
                          </React.Fragment>
                        </Unit>
                      </InputContainer>
                    </FormRow>
                  );
                }
                case 'session': {
                  return (
                    <FormRow key={field} title="rtms.subjectOfTreatment.session" bottomMargin={false}>
                      <InputContainer style={{ fontWeight: 'normal' }}>
                        {exists(sessionNumber) ? (sessionNumber ?? 0) + 1 : '-'}
                      </InputContainer>
                    </FormRow>
                  );
                }
                case 'stimulationType': {
                  return (
                    <FormRow key={field} title="rtms.subjectOfTreatment.stimulationType" bottomMargin={false}>
                      <InputContainer style={{ fontWeight: 'normal' }}>
                        {document.stimulationType ? fm(`rtms.opts.stimulationType.${document.stimulationType}`) : '-'}
                      </InputContainer>
                    </FormRow>
                  );
                }
                default: {
                  if (['session', 'electricFieldStrength', 'additionalInformation'].includes(field)) {
                    return undefined;
                  }
                  return (
                    <FormRow key={field} title={`rtms.subjectOfTreatment.${field}`} bottomMargin={false}>
                      <InputContainer>
                        <Unit unit={fm(`rtms.units.${field}`)}>
                          <ToolTip
                            title={fm('rtms.subjectOfTreatment.valueInDoctorsOrders')}
                            description={
                              <>
                                {valueDiffersFromDoctorsOrders(
                                  session,
                                  currentIndex ?? 0,
                                  field,
                                  document[field as keyof IRTMSTreatment] as number,
                                  undefined,
                                  true,
                                )}
                              </>
                            }
                            cursor={
                              valueDiffersFromDoctorsOrders(
                                session,
                                currentIndex ?? 0,
                                field,
                                document[field as keyof IRTMSTreatment] as number,
                              )
                                ? 'Pointer'
                                : 'Default'
                            }
                            hover={true}
                            disabled={
                              !valueDiffersFromDoctorsOrders(
                                session,
                                currentIndex ?? 0,
                                field,
                                document[field as keyof IRTMSTreatment] as number,
                              )
                            }
                            flipTitleAndDescStyle
                            content={
                              <InputHandler
                                type="NumberField"
                                name={field}
                                editing={isEditing && editingEvent}
                                formData={{
                                  document: {
                                    [field]: exists(document[field as keyof IRTMSTreatment])
                                      ? document[field as keyof IRTMSTreatment]
                                      : placeholder,
                                  },
                                  onChange: onChange,
                                }}
                                width={6}
                                style={
                                  valueDiffersFromDoctorsOrders(
                                    session,
                                    currentIndex ?? 0,
                                    field,
                                    document[field as keyof IRTMSTreatment] as number,
                                  )
                                    ? {
                                        backgroundColor: colors.appBlue.default,
                                        color: colors.white,
                                      }
                                    : undefined
                                }
                                precision={field === 'peelingDepth' ? 1 : undefined}
                              />
                            }
                          ></ToolTip>
                        </Unit>
                      </InputContainer>
                    </FormRow>
                  );
                }
              }
            })}
            <FormRow title="rtms.subjectOfTreatment.additionalInformationSubject">
              <InputContainer>
                <InputHandler
                  type="TextArea"
                  name="additionalInformation"
                  editing={isEditing && editingEvent}
                  formData={{
                    document: { additionalInformation: document.additionalInformation || placeholder },
                    onChange: onChange,
                  }}
                  placeholder="rtms.subjectOfTreatment.additionalInformationSubject"
                />
              </InputContainer>
            </FormRow>
          </React.Fragment>
        </React.Fragment>
      )}
    </div>
  );
};

interface ITreatment {
  document: IRTMSTreatment;
  onChange: (value: TOnChangeValues) => void;
  session?: IRTMSSession;
  sessionNumber?: number;
  precedingDoctorsOrder?: IDoctorsOrder;
  editingEvent?: boolean;
}

export default Treatment;
