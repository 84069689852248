import * as React from 'react';
import { styled } from '@mui/system';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import Unit from 'Components/Unit';
import { exists } from 'neuro-utils';
import { DoctorsOrdersContext } from 'Routes/DoctorsOrders/Document';
import { Container, Item } from 'Components/Grid';
import colors from '../../../../../../config/theme/colors';

const InputContainer = styled('div')({
  fontWeight: 600,
  margin: '0 0 2.5rem 0',
});

const Treatment = ({ treatmentName, document, onChange }: ITreatment): JSX.Element => {
  const f: { [key: string]: keyof ITDCSTreatment } = {
    cathode: 'anode',
    anode: 'cathode',
  };
  return (
    <DoctorsOrdersContext.Consumer>
      {({ isEditing, fm }) => {
        const placeholder = isEditing ? '' : '-';
        return (
          <div style={{ padding: '2rem 0 0 0' }}>
            {['type', 'current', 'currentDuration'].map((field) => {
              switch (field) {
                case 'current':
                case 'currentDuration': {
                  return (
                    <FormRow
                      key={field}
                      title={`doctorsOrders.tdcs.${treatmentName}.${field}.title`}
                      headerWidth={5}
                      bottomMargin={false}
                    >
                      <InputContainer>
                        <Unit unit={fm(`doctorsOrders.tdcs.${treatmentName}.${field}.unit`)}>
                          <InputHandler
                            type="NumberField"
                            name={field}
                            editing={isEditing}
                            formData={{
                              document: {
                                [field]: exists(document[field as keyof ITDCSTreatment])
                                  ? document[field as keyof ITDCSTreatment]
                                  : placeholder,
                              },
                              onChange: onChange,
                            }}
                            width={6}
                            precision={field === 'current' ? 1 : undefined}
                          />
                        </Unit>
                      </InputContainer>
                    </FormRow>
                  );
                }
                default: {
                  return (
                    <React.Fragment key="typeAndOther">
                      <FormRow
                        title={`doctorsOrders.tdcs.${treatmentName}.type.title`}
                        headerWidth={5}
                        bottomMargin={false}
                      >
                        <InputContainer>
                          <InputHandler
                            type="Radio"
                            name="type"
                            editing={isEditing}
                            formData={{
                              document: { type: document.type || placeholder },
                              onChange: onChange,
                            }}
                            options={['anode', 'cathode']}
                            optionFormatter={(id: string | number) =>
                              fm(`doctorsOrders.tdcs.${treatmentName}.type.opts.${id}`)
                            }
                          />
                        </InputContainer>
                      </FormRow>
                      {document.name && (
                        <Container>
                          <Item xs={5} style={{ color: colors.tertiaryText }}>
                            {fm('doctorsOrders.tdcs.subjectOfTreatment.explanation.title')}
                          </Item>
                          <Item xs={true}>
                            <InputContainer style={{ paddingRight: '1rem' }}>
                              {document.name !== 'other' ? (
                                fm(`doctorsOrders.subjectOfTreatmentNameExplanations.${document.name}`)
                              ) : (
                                <InputHandler
                                  type="TextArea"
                                  editing={isEditing}
                                  name="explanationOther"
                                  formData={{
                                    document: { explanationOther: document.explanationOther || placeholder },
                                    onChange: onChange,
                                  }}
                                  width={20}
                                  placeholder={
                                    'doctorsOrders.tdcs.subjectOfTreatment.explanation.explanationOtherPlaceholder'
                                  }
                                  maxLength={120}
                                />
                              )}
                            </InputContainer>
                          </Item>
                          <Item xs={2}>{fm('doctorsOrders.tdcs.subjectOfTreatment.instructionForPatient')}</Item>
                        </Container>
                      )}

                      {document.type && (
                        <React.Fragment>
                          <FormRow
                            key={f[document.type]}
                            title={`doctorsOrders.tdcs.${treatmentName}.${f[document.type]}.title`}
                            headerWidth={5}
                            bottomMargin={false}
                          >
                            <InputContainer>
                              <InputHandler
                                type="Radio"
                                name={f[document.type]}
                                editing={isEditing}
                                formData={{
                                  document: { [f[document.type]]: document[f[document.type]] || placeholder },
                                  onChange: onChange,
                                }}
                                options={['foreheadRight', 'foreheadLeft', 'other']}
                                optionFormatter={(id: string | number) =>
                                  fm(`doctorsOrders.tdcs.${treatmentName}.${f[document.type as string]}.opts.${id}`)
                                }
                              />
                            </InputContainer>
                          </FormRow>
                          {document[f[document.type]] === 'other' && (
                            <FormRow
                              key={`${f[document.type]}Other`}
                              title={`doctorsOrders.tdcs.${treatmentName}.${f[document.type]}Other.title`}
                              headerWidth={5}
                              bottomMargin={false}
                            >
                              <InputContainer>
                                <InputHandler
                                  type="TextArea"
                                  name={`${f[document.type]}Other`}
                                  editing={isEditing}
                                  formData={{
                                    document: {
                                      [`${f[document.type]}Other`]:
                                        document[`${f[document.type]}Other` as keyof ITDCSTreatment] || placeholder,
                                    },
                                    onChange: onChange,
                                  }}
                                  placeholder={`tdcs.subjectOfTreatment.${f[document.type]}OtherPlaceholder`}
                                />
                              </InputContainer>
                            </FormRow>
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  );
                }
              }
            })}
          </div>
        );
      }}
    </DoctorsOrdersContext.Consumer>
  );
};

interface ITreatment {
  treatmentName: 'subjectOfTreatment' | 'backupSubjectOfTreatment';
  document: ITDCSTreatment;
  onChange: (value: TOnChangeValues) => void;
}

export default Treatment;
