import * as React from 'react';

import FormSection from 'Components/FormSection';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';

const _BPForm = ({ formData }: IOwnProps): JSX.Element => (
  <FormSection>
    <FormRow>
      <InputHandler editing={true} name="date" type="PartialDate" formData={formData} />
    </FormRow>
  </FormSection>
);

interface IOwnProps {
  formData: IFormData<any>;
}

export default _BPForm;
