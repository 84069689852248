import * as React from 'react';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import { useIntl } from 'react-intl';
import { styled } from '@mui/system';

import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRow from 'Components/HistoryRow';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import HistoryRowVerticalItem from 'Components/HistoryRowVerticalItem';
import colors from '../../../../config/theme/colors';
import { Container, Item } from 'Components/Grid';
import HistoryRowTable from 'Components/HistoryRowTable';

const StyledViewSelector = styled('span', {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ active }: { active: boolean }) => ({
  color: colors.primary,
  userSelect: 'none' as const,
  textDecoration: active ? 'none' : 'underline',
  fontWeight: active ? 'bold' : 'normal',
  cursor: active ? 'default' : 'pointer',
  marginLeft: '2rem',
}));

type TViews = 'list' | 'table';

const NotesHistory = ({ documents, startEdit }: IOwnProps): JSX.Element => {
  const [selectedView, setSelectedView] = React.useState<TViews>('list');
  const viewsArray: Array<TViews> = ['list', 'table'];

  // Only notes docs and sort by date ascending for table view
  const notesDocs = documents.filter((d) => d._type === 'notes').sort((a, b) => sortPartialDate(a.date, b.date));

  const tableHeaders = [
    'neuroModulationTreatment', // Header
    'neuroModulationActiveTreatment',
    'neuroModulationOtherDetails',
    'neuroModulationNextRMTDefinitionDate',
    'neuroModulationNextRMTDefinitionOrderDate',
    'neuroModulationNextRMTDetails',
    'neuroModulationNextInterview',
    'neuroModulationNextInterviewOrderDate',
    'neuroModulationNextInterviewDetails',
    'neuroModulationTDCSTreatmentMonitoringFrequency',
    'neuroModulationTDCSTreatmentMonitoringFrequencyDate',
    'neuroModulationTDCSTreatmentMonitoringFrequencyDetails',
    'neuroModulationRTMSIntensiveEnded',
    'neuroModulationRTMSIntensiveEndedDetails',
    'neuroModulationRTMSUpkeepEnded',
    'neuroModulationTDCSIntensiveEnded',
    'neuroModulationTDCSIntensiveEndedDetails',
    'neuroModulationTDCSUpkeepEnded',

    'tdcsDeviceHeader', // Header
    'tdcsDeviceIdentificationInfo',
    'tdcsDeviceUsesAtTheTimeOfHandover',
    'tdcsDeviceHandoverDate',
    'tdcsDeviceLoanAgreementEndDate',
    'tdcsDeviceReturnDate',
    'tdcsDeviceCapHandoverDate',
    'tdcsDeviceCapDetails',
    'tdcsDeviceCablesHandoverDate',
    'tdcsDeviceElectrodesHandoverDate',

    'additionalDocumentsHeader', // Header
    'additionalDocuments',
    'additionalQuestionnaires',
    'additionalDocumentsDetails',

    'patientsWishesHeader', // Header
    'patientsWishes',

    'proofsNotificationsPayers', // Header
    'proofsNotificationsPayersDetails',

    'followUpPlanningHeader', // Header
    'followUpPlanning',
    'neuroModulationPatientTransferredToOtherDevice',
    'neuroModulationWhichOtherDevice',
    'neuroModulationTransferDate',
    'followUpPlanningDetails',
  ];

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const tdcsDeviceDataExists = (d: INotes): boolean =>
    !!(
      (d.tdcsDeviceIdentificationInfo && d.tdcsDeviceIdentificationInfo.length > 0) ||
      d.tdcsDeviceUsesAtTheTimeOfHandover ||
      d.tdcsDeviceUsesAtTheTimeOfHandover === 0 ||
      isPartialDate(d.tdcsDeviceHandoverDate) ||
      isPartialDate(d.tdcsDeviceLoanAgreementEndDate) ||
      isPartialDate(d.tdcsDeviceReturnDate) ||
      isPartialDate(d.tdcsDeviceCapHandoverDate) ||
      (d.tdcsDeviceCapDetails && d.tdcsDeviceCapDetails.length > 0) ||
      isPartialDate(d.tdcsDeviceCablesHandoverDate) ||
      isPartialDate(d.tdcsDeviceElectrodesHandoverDate)
    );

  return (
    <>
      <Container style={{ marginBottom: '0.5rem' }} justifyContent="flex-end">
        <Item>
          {viewsArray.map((v) => (
            <StyledViewSelector active={v === selectedView} key={v} onClick={() => setSelectedView(v)}>
              {fm('general.' + v)}
            </StyledViewSelector>
          ))}
        </Item>
      </Container>
      {selectedView === 'list' ? (
        notesDocs
          .sort((n1, n2) => n1._cdate - n2._cdate)
          .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
          .reverse()
          .map((d: INotes) => (
            <HistoryRow
              headerText={d.date ? formatPartialDate(d.date) : ''}
              key={d._id}
              rowButton={<HistoryRowControls document={d} hideButton showEditInMenu startEdit={startEdit} />}
              controlsMargin={false}
            >
              <>
                <HistoryRowSubHeader header={fm('notes.neuroModulationTreatment')} />

                <HistoryRowVerticalItem
                  header={fm('notes.neuroModulationActiveTreatment')}
                  value={d.neuroModulationActiveTreatment ? fm(`notes.opts.${d.neuroModulationActiveTreatment}`) : '-'}
                />

                <HistoryRowVerticalItem
                  header={fm('notes.neuroModulationOtherDetails')}
                  value={d.neuroModulationOtherDetails}
                />
                <HistoryRowVerticalItem
                  header={fm('notes.neuroModulationNextRMTDefinitionDate')}
                  value={
                    isPartialDate(d.neuroModulationNextRMTDefinitionDate)
                      ? `${formatPartialDate(d.neuroModulationNextRMTDefinitionDate)} ${
                          d.neuroModulationNextRMTDefinitionOrderDate
                            ? '(' + formatPartialDate(d.neuroModulationNextRMTDefinitionOrderDate) + ')'
                            : ''
                        }`
                      : '-'
                  }
                />

                {d.neuroModulationNextRMTDetails && (
                  <HistoryRowVerticalItem
                    header={fm('notes.neuroModulationNextRMTDetails')}
                    value={d.neuroModulationNextRMTDetails}
                  />
                )}

                <HistoryRowVerticalItem
                  header={fm('notes.neuroModulationNextInterview')}
                  value={
                    d.neuroModulationNextInterview
                      ? `${d.neuroModulationNextInterview} ${
                          d.neuroModulationNextInterviewOrderDate
                            ? '(' + formatPartialDate(d.neuroModulationNextInterviewOrderDate) + ')'
                            : ''
                        }`
                      : undefined
                  }
                />

                {d.neuroModulationNextInterviewDetails && (
                  <HistoryRowVerticalItem
                    header={fm('notes.neuroModulationNextInterviewDetails')}
                    value={d.neuroModulationNextInterviewDetails}
                  />
                )}

                {d.neuroModulationTDCSTreatmentMonitoringFrequency && (
                  <HistoryRowVerticalItem
                    header={fm('notes.neuroModulationTDCSTreatmentMonitoringFrequency')}
                    value={
                      d.neuroModulationTDCSTreatmentMonitoringFrequency
                        ? `${fm(
                            `doctorsOrders.tdcs.treatmentMonitoringFrequency.opts.${d.neuroModulationTDCSTreatmentMonitoringFrequency}`,
                          )} ${
                            d.neuroModulationTDCSTreatmentMonitoringFrequencyDate
                              ? '(' + formatPartialDate(d.neuroModulationTDCSTreatmentMonitoringFrequencyDate) + ')'
                              : ''
                          }`
                        : undefined
                    }
                  />
                )}

                {d.neuroModulationTDCSTreatmentMonitoringFrequencyDetails && (
                  <HistoryRowVerticalItem
                    header={fm('notes.neuroModulationTDCSTreatmentMonitoringFrequencyDetails')}
                    value={d.neuroModulationTDCSTreatmentMonitoringFrequencyDetails}
                  />
                )}

                {d.neuroModulationRTMSIntensiveEnded && (
                  <>
                    <HistoryRowVerticalItem
                      header={fm('notes.neuroModulationRTMSIntensiveEnded')}
                      value={
                        d.neuroModulationRTMSIntensiveEnded
                          ? formatPartialDate(d.neuroModulationRTMSIntensiveEnded)
                          : null
                      }
                    />
                    {d.neuroModulationRTMSIntensiveEndedDetails && (
                      <HistoryRowVerticalItem header={''} value={d.neuroModulationRTMSIntensiveEndedDetails} />
                    )}
                  </>
                )}
                {d.neuroModulationRTMSUpkeepEnded && (
                  <HistoryRowVerticalItem
                    header={fm('notes.neuroModulationRTMSUpkeepEnded')}
                    value={
                      d.neuroModulationRTMSUpkeepEnded ? formatPartialDate(d.neuroModulationRTMSUpkeepEnded) : null
                    }
                  />
                )}

                {d.neuroModulationTDCSIntensiveEnded && (
                  <>
                    <HistoryRowVerticalItem
                      header={fm('notes.neuroModulationTDCSIntensiveEnded')}
                      value={
                        d.neuroModulationTDCSIntensiveEnded
                          ? formatPartialDate(d.neuroModulationTDCSIntensiveEnded)
                          : null
                      }
                    />
                    {d.neuroModulationTDCSIntensiveEndedDetails && (
                      <HistoryRowVerticalItem header={''} value={d.neuroModulationTDCSIntensiveEndedDetails} />
                    )}
                  </>
                )}
                {d.neuroModulationTDCSUpkeepEnded && (
                  <HistoryRowVerticalItem
                    header={fm('notes.neuroModulationTDCSUpkeepEnded')}
                    value={
                      d.neuroModulationTDCSUpkeepEnded ? formatPartialDate(d.neuroModulationTDCSUpkeepEnded) : null
                    }
                  />
                )}

                {tdcsDeviceDataExists(d) && (
                  <>
                    <HistoryRowSubHeader header={fm('notes.tdcsDeviceHeader')} />
                    <HistoryRowVerticalItem
                      header={fm('notes.tdcsDeviceIdentificationInfo')}
                      value={d.tdcsDeviceIdentificationInfo}
                    />
                    <HistoryRowVerticalItem
                      header={fm('notes.tdcsDeviceUsesAtTheTimeOfHandover')}
                      value={d.tdcsDeviceUsesAtTheTimeOfHandover}
                    />
                    <HistoryRowVerticalItem
                      header={fm('notes.tdcsDeviceHandoverDate')}
                      value={d.tdcsDeviceHandoverDate ? formatPartialDate(d.tdcsDeviceHandoverDate) : null}
                    />
                    <HistoryRowVerticalItem
                      header={fm('notes.tdcsDeviceLoanAgreementEndDate')}
                      value={
                        d.tdcsDeviceLoanAgreementEndDate ? formatPartialDate(d.tdcsDeviceLoanAgreementEndDate) : null
                      }
                      condition={isPartialDate(d.tdcsDeviceLoanAgreementEndDate)}
                    />
                    <HistoryRowVerticalItem
                      header={fm('notes.tdcsDeviceReturnDate')}
                      value={d.tdcsDeviceReturnDate ? formatPartialDate(d.tdcsDeviceReturnDate) : null}
                    />
                    <HistoryRowVerticalItem
                      header={fm('notes.tdcsDeviceCapHandoverDate')}
                      value={d.tdcsDeviceCapHandoverDate ? formatPartialDate(d.tdcsDeviceCapHandoverDate) : null}
                    />
                    <HistoryRowVerticalItem header={fm('notes.tdcsDeviceCapDetails')} value={d.tdcsDeviceCapDetails} />
                    <HistoryRowVerticalItem
                      header={fm('notes.tdcsDeviceCablesHandoverDate')}
                      value={d.tdcsDeviceCablesHandoverDate ? formatPartialDate(d.tdcsDeviceCablesHandoverDate) : null}
                    />
                    <HistoryRowVerticalItem
                      header={fm('notes.tdcsDeviceElectrodesHandoverDate')}
                      value={
                        d.tdcsDeviceElectrodesHandoverDate
                          ? formatPartialDate(d.tdcsDeviceElectrodesHandoverDate)
                          : null
                      }
                    />
                  </>
                )}

                <HistoryRowSubHeader header={fm('notes.additionalDocumentsHeader')} />
                <HistoryRowVerticalItem
                  header={fm('notes.additionalDocuments')}
                  value={
                    d.additionalDocuments && d.additionalDocuments.length > 0
                      ? d.additionalDocuments.map((v) =>
                          v === 'bai' || v === 'gad-7'
                            ? fm('doctorsOrders.additionalDocuments.documentsUsed.opts.' + v)
                            : v,
                        )
                      : '-'
                  }
                />
                <HistoryRowVerticalItem
                  header={fm('notes.additionalQuestionnaires')}
                  value={
                    d.additionalQuestionnaires && d.additionalQuestionnaires.length > 0
                      ? d.additionalQuestionnaires.map((v) =>
                          v === 'madrs' || v === 'gaf'
                            ? fm('doctorsOrders.additionalDocuments.questionnairesUsed.opts.' + v)
                            : v,
                        )
                      : '-'
                  }
                />
                {!!(d.additionalDocuments || d.additionalQuestionnaires) &&
                  !!((d.additionalDocuments || []).length > 0 || (d.additionalQuestionnaires || []).length > 0) && (
                    <HistoryRowVerticalItem
                      header={fm('notes.additionalDocumentsDetails')}
                      value={d.additionalDocumentsDetails}
                    />
                  )}

                <HistoryRowSubHeader header={fm('notes.patientsWishes')} />
                <HistoryRowVerticalItem header={fm('notes.patientsWishes')} value={d.patientsWishes} />

                <HistoryRowSubHeader header={fm('notes.proofsNotificationsPayersDetails')} />
                <HistoryRowVerticalItem
                  header={fm('notes.proofsNotificationsPayersDetails')}
                  value={d.proofsNotificationsPayersDetails}
                />

                <HistoryRowSubHeader header={fm('notes.followUpPlanning')} />
                <HistoryRowVerticalItem
                  header={fm('notes.followUpPlanning')}
                  value={
                    d.followUpPlanning !== undefined && d.followUpPlanning !== null
                      ? fm('general.' + d.followUpPlanning)
                      : null
                  }
                />

                {!!d.followUpPlanning && (
                  <>
                    <HistoryRowVerticalItem
                      header={fm('notes.neuroModulationPatientTransferredToOtherDevice')}
                      value={
                        d.neuroModulationPatientTransferredToOtherDevice !== undefined &&
                        d.neuroModulationPatientTransferredToOtherDevice !== null
                          ? fm('general.' + d.neuroModulationPatientTransferredToOtherDevice)
                          : null
                      }
                    />
                    {!!d.neuroModulationPatientTransferredToOtherDevice && (
                      <>
                        <HistoryRowVerticalItem
                          header={fm('notes.neuroModulationWhichOtherDevice')}
                          value={
                            d.neuroModulationWhichOtherDevice !== undefined &&
                            d.neuroModulationWhichOtherDevice !== null
                              ? fm('notes.opts.' + d.neuroModulationWhichOtherDevice)
                              : null
                          }
                        />
                        <HistoryRowVerticalItem
                          header={fm('notes.neuroModulationTransferDate')}
                          value={
                            isPartialDate(d.neuroModulationTransferDate)
                              ? formatPartialDate(d.neuroModulationTransferDate)
                              : null
                          }
                        />
                      </>
                    )}
                  </>
                )}

                {!!d.followUpPlanning && (
                  <HistoryRowVerticalItem
                    header={fm('notes.followUpPlanningDetails')}
                    value={d.followUpPlanningDetails}
                  />
                )}
              </>
            </HistoryRow>
          ))
      ) : (
        <>
          <HistoryRowTable
            headers={tableHeaders}
            documents={notesDocs}
            headersFormatter={(header: string) => {
              if (header === 'neuroModulationTreatment')
                return (
                  <div style={{ fontWeight: 600, marginBottom: '-2rem' }}>{fm('notes.neuroModulationTreatment')}</div>
                );
              if (header === 'tdcsDeviceHeader')
                return <div style={{ fontWeight: 600, marginBottom: '-2rem' }}>{fm('notes.tdcsDeviceHeader')}</div>;
              if (header === 'additionalDocumentsHeader')
                return (
                  <div style={{ fontWeight: 600, marginBottom: '-2rem' }}>{fm('notes.additionalDocumentsHeader')}</div>
                );
              if (header === 'patientsWishesHeader')
                return <div style={{ fontWeight: 600, marginBottom: '-2rem' }}>{fm('notes.patientsWishes')}</div>;
              if (header === 'followUpPlanningHeader')
                return <div style={{ fontWeight: 600, marginBottom: '-2rem' }}>{fm('notes.followUpPlanning')}</div>;
              if (header === 'proofsNotificationsPayers')
                return (
                  <div style={{ fontWeight: 600, marginBottom: '-2rem' }}>
                    {fm('notes.proofsNotificationsPayersDetails')}
                  </div>
                );
              return fm('notes.' + header);
            }}
            valueFormatter={(field: string, value: TFieldValue) => {
              if (isPartialDate(value)) return formatPartialDate(value);
              if (value === true || value === false) return fm('general.' + value);
              if (Array.isArray(value) && value.length === 0) return '-';
              if ((field === 'neuroModulationWhichOtherDevice' || field === 'neuroModulationActiveTreatment') && value)
                return fm('notes.opts.' + value);
              if (field === 'neuroModulationTDCSTreatmentMonitoringFrequency' && value)
                return fm(`doctorsOrders.tdcs.treatmentMonitoringFrequency.opts.${value}`);
              if (field === 'additionalDocuments' && value)
                return `${
                  Array.isArray(value)
                    ? value
                        .map((v) =>
                          v === 'bai' || v === 'gad-7'
                            ? fm('doctorsOrders.additionalDocuments.documentsUsed.opts.' + v)
                            : v,
                        )
                        .join(', ')
                    : value ?? ''
                }`;
              if (field === 'additionalQuestionnaires' && value)
                return `${
                  Array.isArray(value)
                    ? value
                        .map((v) =>
                          v === 'madrs' || v === 'gaf'
                            ? fm('doctorsOrders.additionalDocuments.questionnairesUsed.opts.' + v)
                            : v,
                        )
                        .join(', ')
                    : value ?? ''
                }`;

              if (
                field === 'neuroModulationTreatment' ||
                field === 'tdcsDeviceHeader' ||
                field === 'additionalDocumentsHeader' ||
                field === 'patientsWishesHeader' ||
                field === 'followUpPlanningHeader' ||
                field === 'proofsNotificationsPayers'
              )
                return '';
              return value ? `${value}` : '-';
            }}
            // headerColumnSpans={(index: number) => {
            //   if (index === 11) return 2;
            //   return 1;
            // }}
          />
        </>
      )}
    </>
  );
};

interface IOwnProps {
  documents: Array<INotes | IRTMS | ITDCS | (IDoctorsOrder & IDoctorsOrderOther) | ININMTTreatmentPeriod>;
  startEdit: (document: INotes, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
}

export default NotesHistory;
