import * as React from 'react';
import { formatPartialDate, isPartialDate } from 'neuro-utils';
import { styled } from '@mui/system';
import { useMediaQuery, useTheme } from '@mui/material';
import { PageControls } from 'Components/PagingWrapper';

const TableCell = styled('td', {
  shouldForwardProp: (prop) => prop !== 'firstCell',
})(({ firstCell }: { firstCell: boolean }) => ({
  paddingRight: firstCell ? '3rem' : '2rem',
  paddingBottom: '2rem',
  width: firstCell ? '20%' : '16%',
  maxWidth: firstCell ? '20%' : '16%',
  wordWrap: 'break-word' as const,
}));

const HistoryTable = ({
  documents,
  headers,
  headersFormatter,
  valueFormatter,
  headerColumnSpans,
}: IOwnProps): JSX.Element => {
  const documentsList = documents || [];

  const theme = useTheme();
  const viewportBiggerThanMd = useMediaQuery(theme.breakpoints.up('md'));
  // How many columns can be shown
  const shownDataCount = viewportBiggerThanMd ? 5 : 3;
  // How many items are stepped through with pagingcontrols
  const stepSize = 1;

  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const shownListItems = documentsList.slice(currentPage * stepSize, currentPage * stepSize + shownDataCount);
  // Only show paging controls if there are more than one page
  const showPagingControls = documentsList.length > shownDataCount;

  // Make date headers
  let dates = shownListItems.map((d) => (isPartialDate(d.date) ? formatPartialDate(d.date) : ''));
  dates?.unshift(''); // First date should be empty to account for headers
  dates = dates?.slice(0, shownDataCount + 1);

  // Make first column headers and data cells
  const data = headers.map((h) => {
    const takeFields = shownListItems?.map((d) => {
      const field = d[h];
      return valueFormatter(h, field);
    });
    return [headersFormatter(h), ...(takeFields || [])];
  });

  React.useEffect(() => {
    // In case the page width is changed, set the page to 0 if it would overflow
    if (currentPage > Math.ceil(documentsList.length / shownDataCount) - 1) setCurrentPage(0);
  }, [shownDataCount]);

  return (
    <>
      {showPagingControls && (
        <PageControls
          limitOfItemsInPage={shownDataCount}
          length={documentsList.length}
          pageNumber={currentPage}
          setPageNumber={setCurrentPage}
          stepSize={stepSize}
          controlsJustify="end"
        />
      )}
      <table style={{ tableLayout: 'fixed' /** Needed so that cells wont overflow */, width: '100%' }}>
        <thead>
          <tr>
            {dates?.map((h, i) => (
              <th style={{ textAlign: 'left' }} key={i}>
                {h}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => (
            <tr key={i}>
              {row.map((cell, idx) => (
                <TableCell firstCell={idx === 0} key={idx} colSpan={headerColumnSpans?.(i)}>
                  {cell}
                </TableCell>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

interface IOwnProps {
  documents?: document[];
  headers: string[];
  headersFormatter: (header: string) => string | JSX.Element;
  valueFormatter: (field: string, value: TFieldValue) => string;
  /** Kinda weird way to change specific headers column spans. Need to make sure the index doesn't change, eg. when adding rows/headers */
  headerColumnSpans?: (index: number) => number;
}

export default HistoryTable;
