import * as React from 'react';
import { useIntl } from 'react-intl';
import { formatPartialDate } from 'neuro-utils';
import HistorySection from 'Components/HistorySection';
import HistoryRow from 'Components/HistoryRow';
import HistoryRowControls from 'Components/HistoryRowControls';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import HistoryRowVerticalItem from 'Components/HistoryRowVerticalItem';
import { EventListing, EventTable, StyledViewSelector } from './components';
import { RTMSContext } from '..';
import { Container, Item } from 'Components/Grid';
import { headerText } from 'Utility/ninmtUtil';

const endDate = (d: IRTMS) => {
  return Array.isArray(d?.sessions) && d.sessions?.length > 0 && d.sessions[0]?.date ? d.sessions[0]?.date : null;
};

const HistoryRowData = ({
  d,
  fm,
  allDocs,
}: {
  d: IRTMS;
  fm: (id: string) => string;
  allDocs: Array<IRTMS>;
}): JSX.Element => {
  const [selectedView, setSelectedView] = React.useState<'list' | 'table'>('list');
  const viewsArray: Array<'list' | 'table'> = ['list', 'table'];

  const endDateVar = endDate(d);

  const allSessions = allDocs.flatMap((ad) => ad.sessions).filter((s) => s !== undefined) as IRTMSSession[];

  return (
    <React.Fragment>
      {((Array.isArray(d.sessions) && d.sessions.length > 0) ||
        (Array.isArray(d.unusedSessions) && d.unusedSessions.length > 0)) && (
        <React.Fragment>
          <HistoryRowSubHeader
            header={
              <Container>
                <Item xs={3}>{fm('rtms.sessionsAndProtocols')}</Item>
                {Array.isArray(d.sessions) && d.sessions.length > 0 && (
                  <Item xs={9}>
                    <Container justifyContent="flex-end">
                      <Item>
                        {viewsArray.map((v) => (
                          <StyledViewSelector active={v === selectedView} key={v} onClick={() => setSelectedView(v)}>
                            {fm('general.' + v)}
                          </StyledViewSelector>
                        ))}
                      </Item>
                    </Container>
                  </Item>
                )}
              </Container>
            }
          />
          {
            {
              list: (
                <EventListing
                  type="sessions"
                  events={d.sessions ?? []}
                  secondaryEvents={d.unusedSessions ?? []}
                  eventsBeyondThisDoc={allSessions}
                />
              ),
              table: (
                <EventTable
                  type="sessions"
                  events={d.sessions ?? []}
                  secondaryEvents={d.unusedSessions ?? []}
                  eventsBeyondThisDoc={allSessions}
                />
              ),
            }[selectedView]
          }
        </React.Fragment>
      )}
      {Array.isArray(d.complications) && d.complications.length > 0 && (
        <React.Fragment>
          <HistoryRowSubHeader header={fm('rtms.complications')} />
          <EventListing type="complications" events={d.complications ?? []} />
        </React.Fragment>
      )}
      {Array.isArray(d.adverseEffects) && d.adverseEffects.length > 0 && (
        <React.Fragment>
          <HistoryRowSubHeader header={fm('rtms.adverseEffects')} />
          <EventListing type="adverseEffects" events={d.adverseEffects ?? []} />
        </React.Fragment>
      )}
      {d.hasEnded && (
        <React.Fragment>
          <HistoryRowSubHeader header={fm('rtms.ending')} />
          <HistoryRowVerticalItem
            header={fm('rtms.endDate')}
            value={d.endDate ? formatPartialDate(d.endDate) : endDateVar ? formatPartialDate(endDateVar) : '-'}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const History = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  return (
    <RTMSContext.Consumer>
      {({ documents, startEdit }) => (
        <React.Fragment>
          {documents?.filter((d) => !d.hasEnded).length > 0 && (
            <HistorySection headerText={fm('rtms.rtmsOngoing')} hasHistoryRows={true}>
              {documents
                ?.filter((d) => !d.hasEnded)
                .map((d: IRTMS) => (
                  <HistoryRow
                    headerText={headerText(d, fm)}
                    key={d._id}
                    rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
                    controlsMargin={false}
                  >
                    <HistoryRowData d={d} fm={fm} allDocs={documents} />
                  </HistoryRow>
                ))}
            </HistorySection>
          )}
          {documents?.filter((d) => d.hasEnded)?.length > 0 && (
            <HistorySection headerText={fm('rtms.rtmsEnded')} inactive={true} hasHistoryRows={true}>
              {documents
                ?.filter((d) => d.hasEnded)
                .map((d: IRTMS) => (
                  <HistoryRow
                    headerText={headerText({ ...d, endDate: d.endDate ?? (endDate(d) || undefined) }, fm)}
                    key={d._id}
                    rowButton={<HistoryRowControls document={d} startEdit={startEdit} />}
                    controlsMargin={false}
                  >
                    <HistoryRowData d={d} fm={fm} allDocs={documents} />
                  </HistoryRow>
                ))}
            </HistorySection>
          )}
        </React.Fragment>
      )}
    </RTMSContext.Consumer>
  );
};

export default History;
