type TPrecisionMasker = (date: PartialDate) => PartialDate;

/**
 * Creates PartialDate precision transformer
 * @description Allows ensuring that compared partial dates have the same precision. E.g. 2020-02 vs 2020-02-01 are evalauted as 2020-02 vs. 2020-02.
 * @param a PartialDate A
 * @param b PartialDate B
 * @returns Returns a function that transforms all passed partial dates to least precision.
 */
export const getPrecisionMask = (a: PartialDate, b: PartialDate): TPrecisionMasker => {
  const precision = Math.max(0, Math.min(a.filter((d) => d).length, b.filter((d) => d).length, 3));
  return (date: PartialDate): PartialDate => {
    return [...date.slice(0, precision), ...[null, null, null].slice(0, Math.max(0, 3 - precision))] as PartialDate;
  };
};

const findMatchingAdverseEvents = (
  adverseEvents: IMedicationAdverseEvent[],
  medID: string,
): IMedicationAdverseEvent[] => {
  if (!adverseEvents || !Array.isArray(adverseEvents) || !medID) return [];

  return adverseEvents.filter((adv) => {
    if (adv.medicationId !== medID) return false;

    // const precisionMaskStart = getPrecisionMask(startDate, adv.date);

    // if (partialDateToValue(precisionMaskStart(adv.date)) < partialDateToValue(precisionMaskStart(startDate)))
    //   return false;

    // if (endDate) {
    //   const precisionMaskEnd = getPrecisionMask(endDate, adv.date);
    //   if (partialDateToValue(precisionMaskStart(endDate)) < partialDateToValue(precisionMaskEnd(adv.date)))
    //     return false;
    // }

    return true;
  });
};

export default findMatchingAdverseEvents;
