import { includes } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import EventStepper from '../../../../../../components/EventStepper';
import { StepperHeaderValuePair } from '../../../../../../components/EventStepper/components';
import FormRow from '../../../../../../components/FormRow';
import InputHandler from '../../../../../../components/InputHandler';
import { IFormContext, withFormContext } from '../../../../../../containers/FormContextHandler';
import { fm } from 'Components/FormatMessage';

const EtiologyForm = ({ editing, formData }: IFormContext): JSX.Element => {
  return (
    <React.Fragment>
      <FormRow title="diagnosis.epilepsy.diagnosisDate">
        <InputHandler
          type="PartialDate"
          editing={!!editing}
          name="date"
          formData={formData}
          dateDefault="now"
          isNotCancellable={true}
        />
      </FormRow>
      <FormRow title="diagnosis.epilepsy.etiology.class">
        <InputHandler
          type="Radio"
          editing={!!editing}
          name="class"
          formData={formData}
          options={['structural', 'genetic', 'infectional', 'metabolism', 'immune', 'unknown']}
          optionFormatter={(name: string | number): JSX.Element => (
            <FormattedMessage id={`diagnosis.epilepsy.etiology.opts.${name}`} />
          )}
          dependentFieldsList={(): string[] => [
            'metabolismSpecification',
            'unknownSpecification',
            'orphaCodeMetabolismUnknown',
            'subClasses',
          ]}
          dependentFieldsRemovalWarning
        />
      </FormRow>
      {editing && formData?.document.class !== 'metabolism' && formData?.document.class !== 'unknown' ? (
        <EventStepper
          name="subClasses"
          formData={formData}
          buttonDisabled={!formData?.document.class}
          buttonDisabledMessage={fm('diagnosis.epilepsy.etiology.subClassDisabledMessage')}
          stepLabelText={(d: IEtiologySubClasses): JSX.Element | string => (
            <>
              {d.subClassStructural
                ? fm(`diagnosis.epilepsy.etiology.opts.${d.subClassStructural}`)
                : d.subClassGenetic
                  ? fm(`diagnosis.epilepsy.etiology.opts.${d.subClassGenetic}`)
                  : d.subClassImmune
                    ? fm(`diagnosis.epilepsy.etiology.opts.${d.subClassImmune}`)
                    : d.subClassInfectional
                      ? fm(`diagnosis.epilepsy.etiology.opts.${d.subClassInfectional}`)
                      : '-'}
              {d.specificClass ? (
                <span>
                  {': '}
                  {fm(`diagnosis.epilepsy.etiology.opts.${d.specificClass}`)}
                </span>
              ) : d.structuralOtherSpecification ? (
                <span>
                  {': '}
                  {d.structuralOtherSpecification}
                </span>
              ) : (
                ''
              )}
            </>
          )}
          stepContent={(d: IEtiologySubClasses): JSX.Element => (
            <>
              {d.superSpecificClass ? (
                <StepperHeaderValuePair
                  header={<FormattedMessage id="diagnosis.epilepsy.etiology.specification" />}
                  value={fm(`diagnosis.epilepsy.etiology.opts.${d.superSpecificClass}`)}
                />
              ) : undefined}
              {d.specificClassInput ? (
                <StepperHeaderValuePair
                  header={<FormattedMessage id="diagnosis.epilepsy.etiology.specification" />}
                  value={d.specificClassInput}
                />
              ) : undefined}
              {formData?.document.class === 'structural' ? (
                <>
                  <StepperHeaderValuePair
                    header={<FormattedMessage id="diagnosis.epilepsy.etiology.side" />}
                    value={d.side ? fm(`diagnosis.epilepsy.etiology.opts.${d.side}`) : '-'}
                  />
                  <StepperHeaderValuePair
                    header={<FormattedMessage id="diagnosis.epilepsy.etiology.field" />}
                    value={d.field ? fm(`diagnosis.epilepsy.etiology.opts.${d.field}`) : '-'}
                  />
                </>
              ) : undefined}
              {formData?.document.class === 'genetic' ? (
                <>
                  {d.subClassGenetic === 'singleGeneMutation' ? (
                    <>
                      <StepperHeaderValuePair
                        header={<FormattedMessage id="geneTest.gene" />}
                        value={d.geneInput ? d.geneInput : '-'}
                      />
                      <StepperHeaderValuePair
                        header={<FormattedMessage id="geneTest.mutation" />}
                        value={d.mutationInput ? d.mutationInput : '-'}
                      />
                      <StepperHeaderValuePair
                        header={<FormattedMessage id="diagnosis.epilepsy.etiology.omimTitleShort" />}
                        value={d.omimInput ? d.omimInput : '-'}
                      />
                    </>
                  ) : d.subClassGenetic === 'copyNumberVariation' ? (
                    <>
                      <StepperHeaderValuePair
                        header={<FormattedMessage id="diagnosis.epilepsy.etiology.chromosome" />}
                        value={d.chromosomeInput ? d.chromosomeInput : '-'}
                      />
                      <StepperHeaderValuePair
                        header={<FormattedMessage id="diagnosis.epilepsy.etiology.specification" />}
                        value={d.specificationInput ? d.specificationInput : '-'}
                      />
                      <StepperHeaderValuePair
                        header={<FormattedMessage id="diagnosis.epilepsy.etiology.omimTitleShort" />}
                        value={d.omimInput ? d.omimInput : '-'}
                      />
                    </>
                  ) : d.subClassGenetic === 'otherChromosomalVariation' ? (
                    <>
                      <StepperHeaderValuePair
                        header={<FormattedMessage id="diagnosis.epilepsy.etiology.specification" />}
                        value={
                          d.specification ? (
                            <span>
                              {fm(`diagnosis.epilepsy.etiology.opts.${d.specification}`)}
                              {d.chromosomalVariationInput ? (
                                <span>
                                  {': '}
                                  {d.chromosomalVariationInput}
                                </span>
                              ) : (
                                ''
                              )}
                            </span>
                          ) : (
                            '-'
                          )
                        }
                      />
                      {d.chromosome ? (
                        <StepperHeaderValuePair
                          header={<FormattedMessage id="diagnosis.epilepsy.etiology.chromosome" />}
                          value={
                            <span>
                              {fm(`diagnosis.epilepsy.etiology.opts.${d.chromosome}`)}
                              {d.otherChromosomeInput ? (
                                <span>
                                  {': '}
                                  {d.otherChromosomeInput}
                                </span>
                              ) : (
                                ''
                              )}
                            </span>
                          }
                        />
                      ) : undefined}
                    </>
                  ) : d.subClassGenetic === 'familial' ? (
                    <StepperHeaderValuePair
                      header={<FormattedMessage id="diagnosis.epilepsy.etiology.determiner" />}
                      value={d.familialInput ? d.familialInput : '-'}
                    />
                  ) : undefined}
                </>
              ) : undefined}
              {formData?.document.class === 'infectional' ? (
                d.subClassInfectional && d.subClassInfectional !== 'unknownShort' ? (
                  <StepperHeaderValuePair
                    header={<FormattedMessage id="diagnosis.epilepsy.etiology.specification" />}
                    value={d.infectionSpecification ? d.infectionSpecification : '-'}
                  />
                ) : undefined
              ) : undefined}
              {formData?.document.class === 'immune' ? (
                d.subClassImmune === 'otherKnownAntidote' ? (
                  <StepperHeaderValuePair
                    header={<FormattedMessage id="diagnosis.epilepsy.etiology.specification" />}
                    value={d.antidoteInput ? d.antidoteInput : '-'}
                  />
                ) : undefined
              ) : undefined}
              {d.orphaCode ? (
                <StepperHeaderValuePair
                  header={<FormattedMessage id="diagnosis.epilepsy.orphaCode" />}
                  value={d.orphaCode ? d.orphaCode : '-'}
                />
              ) : undefined}
            </>
          )}
          addNewTextHeader="diagnosis.epilepsy.etiology.specificClass"
          addNewTextButton="diagnosis.epilepsy.etiology.addSpecificClass"
          previousEventsTextHeader="diagnosis.epilepsy.etiology.classes"
          editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => (
            <React.Fragment>
              <FormRow
                title={'diagnosis.epilepsy.etiology.specificClass'}
                condition={formData?.document.class === 'structural'}
              >
                <InputHandler
                  type="Radio"
                  editing={!!editing}
                  name="subClassStructural"
                  formData={{
                    onChange,
                    document: { subClassStructural: formData?.document.subClasses?.[index]?.subClassStructural },
                  }}
                  options={[
                    'cortical',
                    'vascular',
                    'hippocampusSclerosis',
                    'perinatal',
                    'transientIschaemicAttack',
                    'hypoxicIschemic',
                    'porencephalicCyst',
                    'encephalocele',
                    'hydrocephalus',
                    'toxicEncephalopathy',
                    'traumaticBrainInjury',
                    'brainTumor',
                    'brainDystrophy',
                    'brainInfection',
                    'other',
                  ]}
                  optionFormatter={(name: string | number): JSX.Element => (
                    <FormattedMessage id={`diagnosis.epilepsy.etiology.opts.${name}`} />
                  )}
                  dependentFieldsList={(): string[] => [
                    'specificClass',
                    'structuralOtherSpecification',
                    'specificClassInput',
                  ]}
                />
              </FormRow>
              <FormRow
                title="diagnosis.epilepsy.etiology.specification"
                condition={
                  formData?.document.class === 'structural' &&
                  formData?.document.subClasses?.[index].subClassStructural === 'other'
                }
              >
                <InputHandler
                  type="TextField"
                  editing={!!editing}
                  name="structuralOtherSpecification"
                  formData={{
                    onChange,
                    document: {
                      structuralOtherSpecification:
                        formData?.document.subClasses?.[index]?.structuralOtherSpecification,
                    },
                  }}
                  placeholder={'diagnosis.epilepsy.etiology.specification'}
                />
              </FormRow>
              <FormRow
                title="diagnosis.epilepsy.etiology.specificClass"
                condition={
                  formData?.document.class === 'structural' &&
                  includes(formData?.document.subClasses?.[index].subClassStructural, [
                    'cortical',
                    'vascular',
                    'perinatal',
                    'transientIschaemicAttack',
                    'hypoxicIschemic',
                    'hydrocephalus',
                    'traumaticBrainInjury',
                    'brainTumor',
                    'brainDystrophy',
                    'brainInfection',
                  ])
                }
              >
                <InputHandler
                  type="Radio"
                  editing={!!editing}
                  name="specificClass"
                  formData={{
                    onChange,
                    document: { specificClass: formData?.document.subClasses?.[index]?.specificClass },
                  }}
                  options={
                    formData?.document.subClasses?.[index].subClassStructural === 'cortical'
                      ? [
                          'focalCorticalDysplacia',
                          'tuberousSclerosis',
                          'polymicrogyria',
                          'subependymalNodular',
                          'subcorticalBand',
                          'hemimegalEncephaly',
                          'schizencephaly',
                          'lissencephaly',
                          'hypothalamusHamartoma',
                          'other',
                        ]
                      : formData?.document.subClasses?.[index].subClassStructural === 'vascular'
                        ? ['brainCavernousMalformation', 'arteryVeinMalformation', 'strugeWeber', 'other']
                        : formData?.document.subClasses?.[index].subClassStructural === 'perinatal' ||
                            formData?.document.subClasses?.[index].subClassStructural === 'transientIschaemicAttack'
                          ? ['stroke', 'cerebralHemorrhage']
                          : formData?.document.subClasses?.[index].subClassStructural === 'hypoxicIschemic'
                            ? ['prePerinatal', 'resuscitation', 'other']
                            : formData?.document.subClasses?.[index].subClassStructural === 'hydrocephalus'
                              ? ['obstructive', 'nph', 'other']
                              : formData?.document.subClasses?.[index].subClassStructural === 'traumaticBrainInjury'
                                ? [
                                    'brainContusion',
                                    'diffuseBrainInjury',
                                    'traumaticIntraserebralHemorrhage',
                                    'subduralHematoma',
                                    'epiduralHematoma',
                                    'traumaticSubarachnoidHemorrhage',
                                  ]
                                : formData?.document.subClasses?.[index].subClassStructural === 'brainTumor'
                                  ? ['meningioma', 'ganglioglioma', 'dnet', 'otherGlioma', 'metastasises', 'other']
                                  : formData?.document.subClasses?.[index].subClassStructural === 'brainDystrophy'
                                    ? [
                                        'alzheimer',
                                        'vascularDementia',
                                        'mixedDementia',
                                        'frontotemporalDementia',
                                        'alcoholRelatedDystrophy',
                                        'dementia',
                                        'childhoodNeurodegenerativeDisease',
                                        'other',
                                      ]
                                    : formData?.document.subClasses?.[index].subClassStructural === 'brainInfection'
                                      ? ['virusEncephalocele', 'bacterialMeningitis', 'brainAbscess', 'other']
                                      : []
                  }
                  optionFormatter={(name: string | number): JSX.Element => (
                    <FormattedMessage id={`diagnosis.epilepsy.etiology.opts.${name}`} />
                  )}
                  dependentFieldsList={(): string[] => ['superSpecificClass', 'specificClassInput']}
                />
              </FormRow>
              <FormRow
                title="diagnosis.epilepsy.etiology.specification"
                condition={
                  formData?.document.class === 'structural' &&
                  (formData?.document.subClasses?.[index].subClassStructural === 'cortical' ||
                    formData?.document.subClasses?.[index].subClassStructural === 'vascular' ||
                    formData?.document.subClasses?.[index].subClassStructural === 'perinatal' ||
                    formData?.document.subClasses?.[index].subClassStructural === 'hypoxicIschemic' ||
                    formData?.document.subClasses?.[index].subClassStructural === 'hydrocephalus' ||
                    formData?.document.subClasses?.[index].subClassStructural === 'transientIschaemicAttack' ||
                    formData?.document.subClasses?.[index].subClassStructural === 'brainTumor' ||
                    formData?.document.subClasses?.[index].subClassStructural === 'brainDystrophy' ||
                    formData?.document.subClasses?.[index].subClassStructural === 'brainInfection' ||
                    formData?.document.subClasses?.[index].subClassStructural === 'other') &&
                  (formData?.document.subClasses?.[index].specificClass === 'stroke' ||
                    formData?.document.subClasses?.[index].specificClass === 'cerebralHemorrhage' ||
                    formData?.document.subClasses?.[index].specificClass === 'otherGlioma' ||
                    formData?.document.subClasses?.[index].specificClass === 'other' ||
                    formData?.document.subClasses?.[index].specificClass === 'childhoodNeurodegenerativeDisease')
                }
              >
                <InputHandler
                  type="Radio"
                  editing={!!editing}
                  name="superSpecificClass"
                  formData={{
                    onChange,
                    document: { superSpecificClass: formData?.document.subClasses?.[index]?.superSpecificClass },
                  }}
                  options={
                    formData?.document.subClasses?.[index].specificClass === 'stroke'
                      ? formData?.document.subClasses?.[index].subClassStructural === 'perinatal'
                        ? ['atherosclerosis', 'sinusthrombosis']
                        : ['subarachnoidHemorrhage', 'intraserebralHemorrhage']
                      : formData?.document.subClasses?.[index].specificClass === 'cerebralHemorrhage'
                        ? ['subarachnoidHemorrhage', 'intraserebralHemorrhage']
                        : formData?.document.subClasses?.[index].specificClass === 'otherGlioma'
                          ? ['gr23', 'glioblastoma']
                          : []
                  }
                  optionFormatter={(name: string | number): JSX.Element => (
                    <FormattedMessage id={`diagnosis.epilepsy.etiology.opts.${name}`} />
                  )}
                />
                {(formData?.document.subClasses?.[index].specificClass === 'other' ||
                  (formData?.document.subClasses?.[index].specificClass === 'childhoodNeurodegenerativeDisease' &&
                    formData?.document.subClasses?.[index].subClassStructural === 'brainDystrophy')) &&
                (formData?.document.subClasses?.[index].subClassStructural === 'cortical' ||
                  formData?.document.subClasses?.[index].subClassStructural === 'vascular' ||
                  formData?.document.subClasses?.[index].subClassStructural === 'hypoxicIschemic' ||
                  formData?.document.subClasses?.[index].subClassStructural === 'hydrocephalus' ||
                  formData?.document.subClasses?.[index].subClassStructural === 'brainTumor' ||
                  formData?.document.subClasses?.[index].subClassStructural === 'brainDystrophy' ||
                  formData?.document.subClasses?.[index].subClassStructural === 'brainInfection') &&
                formData?.document.class === 'structural' ? (
                  <InputHandler
                    type="TextField"
                    editing={!!editing}
                    name="specificClassInput"
                    formData={{
                      onChange,
                      document: { specificClassInput: formData?.document.subClasses?.[index]?.specificClassInput },
                    }}
                    placeholder={'diagnosis.epilepsy.etiology.specification'}
                  />
                ) : undefined}
              </FormRow>
              <FormRow
                title="diagnosis.epilepsy.etiology.side"
                condition={
                  formData?.document.class === 'structural' &&
                  formData?.document.subClasses?.[index].subClassStructural !== undefined
                }
              >
                <InputHandler
                  type="Radio"
                  editing={!!editing}
                  name="side"
                  formData={{
                    onChange,
                    document: { side: formData?.document.subClasses?.[index]?.side },
                  }}
                  options={['right', 'left', 'bilateral']}
                  optionFormatter={(name: string | number): JSX.Element => (
                    <FormattedMessage id={`diagnosis.epilepsy.etiology.opts.${name}`} />
                  )}
                />
              </FormRow>
              <FormRow
                title="diagnosis.epilepsy.etiology.field"
                condition={
                  formData?.document.class === 'structural' &&
                  formData?.document.subClasses?.[index].subClassStructural !== undefined
                }
              >
                <InputHandler
                  type="Radio"
                  editing={!!editing}
                  name="field"
                  formData={{
                    onChange,
                    document: { field: formData?.document.subClasses?.[index]?.field },
                  }}
                  options={[
                    'frontalLobe',
                    'temporalLobe',
                    'parietalLobe',
                    'occipitalLobe',
                    'multilobar',
                    'hemispheric',
                  ]}
                  optionFormatter={(name: string | number): JSX.Element => (
                    <FormattedMessage id={`diagnosis.epilepsy.etiology.opts.${name}`} />
                  )}
                />
              </FormRow>
              <FormRow
                title="diagnosis.epilepsy.etiology.specificClass"
                condition={formData?.document.class === 'genetic'}
              >
                <InputHandler
                  type="Radio"
                  editing={!!editing}
                  name="subClassGenetic"
                  formData={{
                    onChange,
                    document: { subClassGenetic: formData?.document.subClasses?.[index]?.subClassGenetic },
                  }}
                  options={['singleGeneMutation', 'copyNumberVariation', 'otherChromosomalVariation', 'familial']}
                  optionFormatter={(name: string | number): JSX.Element => (
                    <FormattedMessage id={`diagnosis.epilepsy.etiology.opts.${name}`} />
                  )}
                  dependentFieldsList={(): string[] => [
                    'geneInput',
                    'mutationInput',
                    'chromosomeInput',
                    'specificationInput',
                    'omimInput',
                    'specification',
                    'familialInput',
                    'chromosomalVariationInput',
                    'chromosome',
                  ]}
                />
              </FormRow>
              {formData?.document.class === 'genetic' &&
              formData?.document.subClasses?.[index].subClassGenetic === 'singleGeneMutation' ? (
                <>
                  <FormRow title="diagnosis.epilepsy.etiology.whichGene">
                    <InputHandler
                      type="TextField"
                      editing={!!editing}
                      name="geneInput"
                      formData={{
                        onChange,
                        document: { geneInput: formData?.document.subClasses?.[index]?.geneInput },
                      }}
                      placeholder={'diagnosis.epilepsy.etiology.whichGene'}
                    />
                  </FormRow>
                  <FormRow title="diagnosis.epilepsy.etiology.whichMutation">
                    <InputHandler
                      type="TextField"
                      editing={!!editing}
                      name="mutationInput"
                      formData={{
                        onChange,
                        document: { mutationInput: formData?.document.subClasses?.[index]?.mutationInput },
                      }}
                      placeholder={'diagnosis.epilepsy.etiology.whichMutation'}
                    />
                  </FormRow>
                  <FormRow title="diagnosis.epilepsy.etiology.omimTitle">
                    <InputHandler
                      type="NumberField"
                      editing={!!editing}
                      name="omimInput"
                      formData={{
                        onChange,
                        document: { omimInput: formData?.document.subClasses?.[index]?.omimInput },
                      }}
                      placeholder={'diagnosis.epilepsy.etiology.omimTitleShort'}
                    />
                  </FormRow>
                </>
              ) : formData?.document.class === 'genetic' &&
                formData?.document.subClasses?.[index].subClassGenetic === 'copyNumberVariation' ? (
                <>
                  <FormRow title="diagnosis.epilepsy.etiology.whichChromosome">
                    <InputHandler
                      type="TextField"
                      editing={!!editing}
                      name="chromosomeInput"
                      formData={{
                        onChange,
                        document: { chromosomeInput: formData?.document.subClasses?.[index]?.chromosomeInput },
                      }}
                      placeholder={'diagnosis.epilepsy.etiology.whichChromosome'}
                    />
                  </FormRow>
                  <FormRow title="diagnosis.epilepsy.etiology.determiner">
                    <InputHandler
                      type="TextField"
                      editing={!!editing}
                      name="specificationInput"
                      formData={{
                        onChange,
                        document: { specificationInput: formData?.document.subClasses?.[index]?.specificationInput },
                      }}
                      placeholder={'diagnosis.epilepsy.etiology.determiner'}
                    />
                  </FormRow>
                  <FormRow title="diagnosis.epilepsy.etiology.omimTitle">
                    <InputHandler
                      type="NumberField"
                      editing={!!editing}
                      name="omimInput"
                      formData={{
                        onChange,
                        document: { omimInput: formData?.document.subClasses?.[index]?.omimInput },
                      }}
                      placeholder={'diagnosis.epilepsy.etiology.omimTitleShort'}
                    />
                  </FormRow>
                </>
              ) : formData?.document.class === 'genetic' &&
                formData?.document.subClasses?.[index].subClassGenetic === 'otherChromosomalVariation' ? (
                <>
                  <FormRow title="diagnosis.epilepsy.etiology.determiner">
                    <InputHandler
                      type="Radio"
                      editing={!!editing}
                      name="specification"
                      formData={{
                        onChange,
                        document: { specification: formData?.document.subClasses?.[index]?.specification },
                      }}
                      options={['21trisoms', 'ringChromosome', 'fragileX', 'other']}
                      optionFormatter={(name: string | number): JSX.Element => (
                        <FormattedMessage id={`diagnosis.epilepsy.etiology.opts.${name}`} />
                      )}
                      dependentFieldsList={(): string[] => [
                        'chromosome',
                        'otherChromosomeInput',
                        'chromosomalVariationInput',
                      ]}
                    />
                  </FormRow>
                  <FormRow
                    title="diagnosis.epilepsy.etiology.whatOtherChromosomal"
                    condition={formData?.document.subClasses?.[index]?.specification === 'other'}
                  >
                    <InputHandler
                      type="TextField"
                      editing={!!editing}
                      name="chromosomalVariationInput"
                      formData={{
                        onChange,
                        document: {
                          chromosomalVariationInput: formData?.document.subClasses?.[index]?.chromosomalVariationInput,
                        },
                      }}
                      placeholder={'diagnosis.epilepsy.etiology.whatOtherChromosomal'}
                    />
                  </FormRow>
                  <FormRow
                    title="diagnosis.epilepsy.etiology.whichChromosome"
                    condition={formData?.document.subClasses?.[index]?.specification === 'ringChromosome'}
                  >
                    <InputHandler
                      type="Radio"
                      editing={!!editing}
                      name="chromosome"
                      formData={{
                        onChange,
                        document: { chromosome: formData?.document.subClasses?.[index]?.chromosome },
                      }}
                      options={['20', '14', '17', 'other']}
                      optionFormatter={(name: string | number): JSX.Element => (
                        <FormattedMessage id={`diagnosis.epilepsy.etiology.opts.${name}`} />
                      )}
                      dependentFieldsList={(): string[] => ['otherChromosomeInput']}
                    />
                  </FormRow>
                  <FormRow
                    title="diagnosis.epilepsy.etiology.determiner"
                    condition={
                      formData?.document.subClasses?.[index]?.chromosome === 'other' &&
                      formData?.document.subClasses?.[index]?.specification === 'ringChromosome'
                    }
                  >
                    <InputHandler
                      type="TextField"
                      editing={!!editing}
                      name="otherChromosomeInput"
                      formData={{
                        onChange,
                        document: {
                          otherChromosomeInput: formData?.document.subClasses?.[index]?.otherChromosomeInput,
                        },
                      }}
                      placeholder={'diagnosis.epilepsy.etiology.determiner'}
                    />
                  </FormRow>
                </>
              ) : formData?.document.class === 'genetic' &&
                formData?.document.subClasses?.[index].subClassGenetic === 'familial' ? (
                <>
                  <FormRow title="diagnosis.epilepsy.etiology.determiner">
                    <InputHandler
                      type="TextField"
                      editing={!!editing}
                      name="familialInput"
                      formData={{
                        onChange,
                        document: { familialInput: formData?.document.subClasses?.[index]?.familialInput },
                      }}
                      placeholder={'diagnosis.epilepsy.etiology.determiner'}
                    />
                  </FormRow>
                </>
              ) : undefined}
              <FormRow
                title="diagnosis.epilepsy.etiology.specificClass"
                condition={formData?.document.class === 'infectional'}
              >
                <InputHandler
                  type="Radio"
                  editing={!!editing}
                  name="subClassInfectional"
                  formData={{
                    onChange,
                    document: { subClassInfectional: formData?.document.subClasses?.[index]?.subClassInfectional },
                  }}
                  options={['virus', 'bacteria', 'otherKnown', 'unknownShort']}
                  optionFormatter={(name: string | number): JSX.Element => (
                    <FormattedMessage id={`diagnosis.epilepsy.etiology.opts.${name}`} />
                  )}
                  dependentFieldsList={(): string[] => ['infectionSpecification']}
                />
              </FormRow>
              <FormRow
                title="diagnosis.epilepsy.etiology.specification"
                condition={
                  formData?.document.class === 'infectional' &&
                  (formData?.document.subClasses?.[index]?.subClassInfectional === 'virus' ||
                    formData?.document.subClasses?.[index]?.subClassInfectional === 'bacteria' ||
                    formData?.document.subClasses?.[index]?.subClassInfectional === 'otherKnown')
                }
              >
                <InputHandler
                  type="TextField"
                  editing={!!editing}
                  name="infectionSpecification"
                  formData={{
                    onChange,
                    document: {
                      infectionSpecification: formData?.document.subClasses?.[index]?.infectionSpecification,
                    },
                  }}
                  placeholder="diagnosis.epilepsy.etiology.specification"
                />
              </FormRow>
              <FormRow
                title="diagnosis.epilepsy.etiology.specificClass"
                condition={formData?.document.class === 'immune'}
              >
                <InputHandler
                  type="Radio"
                  editing={!!editing}
                  name="subClassImmune"
                  formData={{
                    onChange,
                    document: { subClassImmune: formData?.document.subClasses?.[index]?.subClassImmune },
                  }}
                  options={['unknownShort', 'rasmussen', 'gad', 'nmdar', 'otherKnownAntidote']}
                  optionFormatter={(name: string | number): JSX.Element => (
                    <FormattedMessage id={`diagnosis.epilepsy.etiology.opts.${name}`} />
                  )}
                  dependentFieldsList={(): string[] => ['antidoteInput']}
                />
              </FormRow>
              <FormRow
                title="diagnosis.epilepsy.etiology.specification"
                condition={
                  formData?.document.class === 'immune' &&
                  formData?.document.subClasses?.[index]?.subClassImmune === 'otherKnownAntidote'
                }
              >
                <InputHandler
                  type="TextField"
                  editing={!!editing}
                  name="antidoteInput"
                  formData={{
                    onChange,
                    document: { antidoteInput: formData?.document.subClasses?.[index]?.antidoteInput },
                  }}
                  placeholder="diagnosis.epilepsy.etiology.specification"
                />
              </FormRow>
              <FormRow title="diagnosis.epilepsy.orphaCode">
                <InputHandler
                  type="NumberField"
                  editing={!!editing}
                  name="orphaCode"
                  formData={{
                    onChange,
                    document: { orphaCode: formData?.document.subClasses?.[index]?.orphaCode },
                  }}
                  placeholder={'diagnosis.epilepsy.orphaCode'}
                />
              </FormRow>
            </React.Fragment>
          )}
        />
      ) : undefined}
      <FormRow title="diagnosis.epilepsy.etiology.specification" condition={formData?.document.class === 'metabolism'}>
        <InputHandler
          type="TextArea"
          width={50}
          editing={!!editing}
          name="metabolismSpecification"
          formData={formData}
          placeholder="diagnosis.epilepsy.etiology.specification"
        />
      </FormRow>
      <FormRow title="diagnosis.epilepsy.etiology.specification" condition={formData?.document.class === 'unknown'}>
        <InputHandler
          type="TextArea"
          width={50}
          editing={!!editing}
          name="unknownSpecification"
          formData={formData}
          placeholder="diagnosis.epilepsy.etiology.specification"
        />
      </FormRow>
      {formData?.document.class === 'metabolism' || formData?.document.class === 'unknown' ? (
        <FormRow title="diagnosis.epilepsy.orphaCode">
          <InputHandler
            type="NumberField"
            editing={!!editing}
            name="orphaCodeMetabolismUnknown"
            formData={formData}
            placeholder={'diagnosis.epilepsy.orphaCode'}
          />
        </FormRow>
      ) : undefined}
    </React.Fragment>
  );
};

export default withFormContext(EtiologyForm);
