import * as React from 'react';
import { TDocument } from '../../utils';

import DBSEffect from './DBSEffect';
import Levodopatest from './Levodopa';
import UPDRSIIIForm from './UPDRSIII';
import UPDRSVForm from './UPDRSV';

const UPDRSForm = ({
  formData,
  document,
  documents,
  viewing = false,
  setSubDocuments,
}: IOwnProps): JSX.Element | null =>
  document._type === 'updrs_iii' ? (
    <UPDRSIIIForm
      formData={formData as IFormData<IUPDRSIII>}
      viewing={viewing}
      documents={documents.filter((item) => item._type === 'updrs_iii') as Array<IUPDRSIII>}
    />
  ) : document._type === 'updrs_v' ? (
    <UPDRSVForm formData={formData} viewing={viewing} />
  ) : document._type === 'levodopatest' ? (
    <Levodopatest
      formData={formData}
      documents={documents as Array<IUPDRSIII | ILevodopatest>}
      viewing={viewing}
      setSubDocuments={setSubDocuments}
    />
  ) : document._type === 'dbsEffect' ? (
    <DBSEffect
      formData={formData}
      documents={documents as Array<IUPDRSIII | IDBSEffect | IDBS>}
      viewing={viewing}
      setSubDocuments={setSubDocuments}
    />
  ) : null;

interface IOwnProps {
  formData: IFormData<TDocument>;
  document: TDocument;
  documents: Array<TDocument>;
  viewing?: boolean;
  setSubDocuments?: (subDocuments: { name: string; id: string }[]) => void;
}

export default UPDRSForm;
