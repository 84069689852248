import * as React from 'react';

const svgImage = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 400 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      fillRule: 'evenodd' as const,
      clipRule: 'evenodd' as const,
      strokeLinecap: 'round' as const,
      strokeLinejoin: 'round' as const,
      strokeMiterlimit: 1.5,
    }}
  >
    <g transform="matrix(0.406083,0,0,0.406083,-189.313,-38.5237)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-26.8163,17.8886)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,48.1873,-1.71329)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-107.81,18.5432)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-240.73,21.6417)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-187.567,23.5074)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-220.225,-27.4029)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-211.511,33.4175)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-265.452,32.1628)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-67.6385,64.6478)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,-145.911,36.5121)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.406083,0,0,0.406083,14.8808,59.7164)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.178718,-0.234827,0.3466,0.263784,-239.317,184.827)">
      <rect x="763.845" y="554.566" width="11.338" height="266.459" />
    </g>
    <g transform="matrix(0.366398,-0.175088,0.175088,0.366398,-255.059,39.2477)">
      <rect x="731.179" y="863.229" width="88.328" height="10.12" />
    </g>
    <g transform="matrix(0.38385,-0.132523,0.132523,0.38385,-308.944,-70.3051)">
      <rect x="731.179" y="863.229" width="88.328" height="10.12" />
    </g>
    <g transform="matrix(0.384482,-0.130678,0.130678,0.384482,-283.233,56.1178)">
      <circle cx="784.129" cy="472.301" r="48.515" style={{ fill: 'white', stroke: 'black', strokeWidth: '9.76px' }} />
    </g>
    <g transform="matrix(0.157263,-0.0534507,0.0534507,0.157263,-72.8369,99.5159)">
      <circle cx="763.123" cy="476.444" r="17.882" />
    </g>
    <g transform="matrix(0.694817,-0.0226072,0.0115696,0.355583,-383.307,-34.4841)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.847487,0,0,0.0959804,-248.033,136.638)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.810419,-0.368934,0.0397671,0.0873545,-276.267,467.554)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.73995,-0.130907,0.0619783,0.350331,-475.517,55.256)">
      <rect x="713.895" y="579.44" width="3.847" height="178.197" />
    </g>
    <g transform="matrix(0.541527,0,0,0.582975,-281.642,-194.735)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.855673,-0.0504591,0.0419725,0.711759,-301.824,-226.372)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.806372,0.290664,-0.24157,0.670172,-145.798,-428.566)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.82565,-0.230271,0.196863,0.705865,-355.611,-159.136)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.541527,0,0,0.584263,-256.725,-206.706)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
    <g transform="matrix(0.857159,0,0,0.682099,-314.587,-281.498)">
      <rect x="602.429" y="720.136" width="93.055" height="5.527" />
    </g>
  </svg>
);

export default svgImage;
