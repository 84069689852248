export const npiFormFields: { [key: string]: string[] } = {
  delusions: [
    // Screening question
    'doesPatientHaveBeliefsThatAreAgainstReality',
    // Additional questions
    'doesPatientBelieveTheyAreInDanger',
    'doesPatientBelieveOthersStealFromThem',
    'doesPatientBelieveTheirSpouseIsUnfaithful',
    'doesPatientBelieveTheirHomeIsInhabitedByUninvitedGuests',
    'doesPatientBelieveTheirSpouseOrOthersAreNotWhoTheySayTheyAre',
    'doesPatientBelieveTheirPlaceIsNotTheirHome',
    'doesPatientBelieveTheirFamilyIsGoingToAbandonThem',
    'doesPatientBelievePeopleInTelevisionAndNewspapersAreInTheirHome',
    'doesPatientHaveOtherAbnormalBeliefs',
    // Frequency, severity and stress
    'frequency',
    'severity',
    'stress',
  ],
  hallucinations: [
    // Screening question
    'doesPatientHaveHallucinations',
    // Additional questions
    'doesPatientSayTheyAreHearingNoisesOrBehaveInSuchManner',
    'doesPatientSpeakToPeopleThatDoNotExist',
    'doesPatientSayTheySeeThingsOthersDoNotOrBehaveInSuchManner',
    'doesPatientSayTheySmellSmellsOtherDoNot',
    'doesPatientSayTheyFeelSomethingOnTheirSkinOrBehaveInSuchManner',
    'doesPatientDescribeTastesWithUnknownOrigin',
    'doesPatientDescribeOtherAbnormalSensoryExperiences',
    // Frequency, severity and stress
    'frequency',
    'severity',
    'stress',
  ],
  restlessnessOrAggressiveness: [
    // Screening question
    'doesPatientHavePeriodsWhenTheyRefuseToCooperateOrAreDifficultToHandle',
    // Additional questions
    'doesPatientGetAngryWithPeopleWhoTryToHelpThem',
    'isPatientStubborn',
    'isPatientUncooperative',
    'doesPatientBehaveInOtherWaysThatMakeThemDifficultToHandle',
    'doesPatientShoutOrSwearAngrily',
    'doesPatientThrowThingsAround',
    'doesPatientTryToHitOrHarmOthers',
    'doesPatientBehaveInOtherWaysThatAreAggressiveOrAgitated',
    // Frequency, severity and stress
    'frequency',
    'severity',
    'stress',
  ],
  depression: [
    // Screening question
    'doesPatientSeemOrSayThatTheyAreSadOrDepressed',
    // Additional questions
    'doesPatientCryOrSobOccasionally',
    'doesPatientSpeakOrBehaveInASadOrDepressedManner',
    'doesPatientUnderestimateThemselvesOrSayTheyHaveFailed',
    'doesPatientSayTheyAreABadPersonThatDeservesToBePunished',
    'doesPatientSeemDiscouragedOrSayTheyDoNotHaveAFuture',
    'doesPatientSayTheirFamilyIsBetterOffWithoutThem',
    'doesPatientExpressTheyWishToDieOrDoTheySpeakAboutSuicide',
    'doesPatientExpressOtherSignsOfDepressionOrSadness',
    // Frequency, severity and stress
    'frequency',
    'severity',
    'stress',
  ],
  anxiety: [
    // Screening question
    'isPatientVeryNervousWorriedOrAfraidWithoutAVisibleReason',
    // Additional questions
    'doesPatientSayTheyAreWorriedOfEventsThatHaveBeenPlanned',
    'doesPatientFeelDelicateAtTimesOrAreTheyUnableToRelax',
    'doesPatientHaveShortnessOfBreathAtTimes',
    'doesPatientComplainAboutANervousStomachAndHeartbeat',
    'doesPatientAvoidPlacesOrSituationsThatIncreaseNervousness',
    'doesPatientBecomeNervousOrPanicWhenSeparatedFromYou',
    'doesPatientShowOtherSignsOfAnxiety',
    // Frequency, severity and stress
    'frequency',
    'severity',
    'stress',
  ],
  elevatedMood: [
    // Screening question
    'doesPatientSeemTooGladOrHappyWithoutAReason',
    // Additional questions
    'doesPatientSeemTooContentOrHappyComparedToNormal',
    'doesPatientLaughAtOrAreTheyAmusedAboutThingsOthersAreNot',
    'doesPatientHaveChildishSenseOfHumorAndTendencyToLaughInappropriately',
    'doesPatientTellJokesOrMakeRemarksThatOnlyTheyFindFunny',
    'doesPatientMakeChildishPranks',
    'doesPatientBragOrClaimToHaveMoreAbilitiesOrWealthThanTheyHave',
    'doesPatientSeemOtherwiseTooContentOrHappy',
    // Frequency, severity and stress
    'frequency',
    'severity',
    'stress',
  ],
  apathy: [
    // Screening question
    'isPatientReluctantOrIndifferentOrUninterestedInTheirSurroundings',
    // Additional questions
    'doesPatientSeemMorePassiveThanBefore',
    'doesPatientInitiateConversationLessOften',
    'doesPatientIndicateLessTendernessOrEmotionalityThanBefore',
    'doesPatientParticipateLessInHouseholdChoresThanBefore',
    'doesPatientShowLessInterestInWhatOthersAreDoingAndPlanningThanBefore',
    'hasPatientLostTheirInterestInFriendsAndFamily',
    'isPatientInableToGetInterestedInThingsThatInterestedThemBefore',
    'doesPatientIndicateInOtherWaysTheyDoNotCareToStartAnythingNew',
    // Frequency, severity and stress
    'frequency',
    'severity',
    'stress',
  ],
  licentiousness: [
    // Screening question
    'doesPatientSeemToActImpulsivelyWithoutThinkingFirst',
    // Additional questions
    'doesPatientActImpulsivelyWithoutThinkingOfConsequences',
    'doesPatientSpeakToUnknownPeopleLikeTheyKnewThem',
    'doesPatientSayTactlessOrInsultingThingsToPeople',
    'doesPatientSpeakAbruptlyOrMakeSexualRemarksTheyWouldNotNormallyMake',
    'doesPatientSpeakOpenlyAboutPersonalAndPrivateThings',
    'doesPatientTakeLibertiesOrTouchOrHugPeopleInAWayThatIsNotNormalToThem',
    'doesPatientShowOtherSignsThatTheyAreNotInControlOfTheirImpulses',
    // Frequency, severity and stress
    'frequency',
    'severity',
    'stress',
  ],
  irritabilityOrMoodSwings: [
    // Screening question
    'doesPatientBecomeIrritatedOrDisturbedEasily',
    // Additional questions
    'doesPatientLoseTheirTemperEasilyFromSmallThings',
    'doesPatientExpressMoodSwingsFromHappyToAngry',
    'doesPatientHaveSuddenBurstsOfAnger',
    'isPatientImpatient',
    'isPatientCrankyAndIrritated',
    'isPatientCombativeAndDifficultToComeAlongWith',
    'doesPatientShowOtherSignsOfIrritability',
    // Frequency, severity and stress
    'frequency',
    'severity',
    'stress',
  ],
  abnormalMotoricBehavior: [
    // Screening question
    'doesPatientWalkBackAndForthWhileRepeatingTheSameThings',
    // Additional questions
    'doesPatientWalkAroundTheApartmentWithoutAClearPurposse',
    'doesPatientDelvePlacesOpeningAndEmptyingBoxesAndCabinets',
    'doesPatientGetDressedAndUndressedRepeatedly',
    'doesPatientHaveHabitsAndActionsTheyDoRepeatAfterRepeat',
    'doesPatientRepeatSpecificThingsSuchAsFiddling',
    'doesPatientHurryNervously',
    'doesPatientRepeatOtherThings',
    // Frequency, severity and stress
    'frequency',
    'severity',
    'stress',
  ],
  sleepDisorders: [
    // Screening question
    'doesPatientHaveProblemsWithSleeping',
    // Additional questions
    'doesPatientHaveDifficultiesFallingAsleep',
    'doesPatientWakeUpDuringTheNight',
    'doesPatientWanderOrWalkBackAndForthOrActInappropriatelyDuringTheNight',
    'doesPatientWakeYouUpDuringTheNight',
    'doesPatientWakeUpDuringTheNightDressingUpAndThinkingItIsMorning',
    'doesPatientWakeUpTooEarlyInTheMorning',
    'doesPatientSleepTooMuchDuringTheDay',
    'doesPatientHaveOtherActionsRelatedToSleepDisordersDuringTheNight',
    // Frequency, severity and stress
    'frequency',
    'severity',
    'stress',
  ],
  appetiteAndEatingDisorders: [
    // Screening question
    'havePatientsAppetiteWeightOrEatingHabitsChanged',
    // Additional questions
    'hasPatientLostTheirAppetite',
    'hasPatientsAppetiteIncreased',
    'hasPatientsWeightDecreased',
    'hasPatientsWeightIncreased',
    'haveThereBeenChangesInPatientsEatingHabits',
    'haveThereBeenChangesInPatientsDiet',
    'doesPatientAlwaysEatTheSameTypeOfFoodOrAlwaysInTheSameSpecificOrder',
    'hasThereBeenAnyOtherChangesInPatientsAppetiteOrEating',
    // Frequency, severity and stress
    'frequency',
    'severity',
    'stress',
  ],
  summary: [
    'frequencyAndSeverityTotalScoreFromDelusionsToAbnormalMotoricBehavior',
    'stressTotalScoreFromDelusionsToAbnormalMotoricBehavior',
    'frequencyAndSeverityTotalScoreFromSleepDisorders',
    'stressTotalScoreFromSleepDisorders',
    'frequencyAndSeverityTotalScoreFromEatingAndAppetiteDisorders',
    'stressTotalScoreFromEatingAndAppetiteDisorders',
  ],
};

export const npiHistoryFields = [
  'totalScoreFromAToJ',
  'stressScoreFromAToJ',
  'screeningQuestionKScore',
  'screeningQuestionKStressScore',
  'screeningQuestionLScore',
  'screeningQuestionLStressScore',
];

const createNpiFormStructure = (): { [key: string]: any } => {
  return Object.keys(npiFormFields).reduce(
    (current, key) => {
      if (key !== 'summary') {
        current[key] = {
          // First element of array is the screening question
          screeningQuestion: {
            title: `diagnosticSurvey.npi.${key}.${npiFormFields[key][0]}`,
            options: ['no', 'yes', 'notEvaluable'],
            optionFormat: 'diagnosticSurvey.npi.opts.screeningQuestion',
          },
          // There are 7-9 additional questions
          additionalQuestions: npiFormFields[key].slice(1, npiFormFields[key].length - 3).map((field) => {
            return {
              title: `diagnosticSurvey.npi.${key}.${field}`,
              options: ['no', 'yes'],
              optionFormat: 'diagnosticSurvey.npi.opts.yesNo',
            };
          }),
          // Last 3 elements are frequency, severity and stress
          frequencySeverityAndStress: npiFormFields[key].slice(npiFormFields[key].length - 3).map((field, index) => {
            const scales = [
              // Frequency
              [1, 2, 3, 4],
              // Severity
              [1, 2, 3],
              // Stress
              [0, 1, 2, 3, 4, 5],
            ];
            return {
              title: `diagnosticSurvey.npi.${key}.${field}`,
              options: scales[index],
              optionFormat: `diagnosticSurvey.npi.${key}.${field}.opts`,
            };
          }),
        };
      } else {
        current[key] = npiFormFields[key];
      }
      return current;
    },
    {} as { [key: string]: { [key: string]: any } },
  );
};

export const npiFormStructure: { [key: string]: any } = createNpiFormStructure();

export const maxNpiScores: { [key: string]: number } = {
  frequencyAndSeverityTotalScoreFromDelusionsToAbnormalMotoricBehavior: 120,
  stressTotalScoreFromDelusionsToAbnormalMotoricBehavior: 50,
  frequencyAndSeverityTotalScoreFromSleepDisorders: 12,
  stressTotalScoreFromSleepDisorders: 5,
  frequencyAndSeverityTotalScoreFromEatingAndAppetiteDisorders: 12,
  stressTotalScoreFromEatingAndAppetiteDisorders: 5,
};

export const npiFormInformation: { [key: string]: Array<{ title: string; content: string }> } = {
  survey: [
    { title: 'diagnosticSurvey.npi.infoTitle', content: 'diagnosticSurvey.npi.info' },
    { title: 'diagnosticSurvey.npi.infoATitle', content: 'diagnosticSurvey.npi.infoA' },
    { title: 'diagnosticSurvey.npi.infoBTitle', content: 'diagnosticSurvey.npi.infoB' },
  ],
  screeningQuestions: [
    { title: 'diagnosticSurvey.npi.infoCTitle', content: 'diagnosticSurvey.npi.infoC' },
    { title: 'diagnosticSurvey.npi.infoGTitle', content: 'diagnosticSurvey.npi.infoG' },
  ],
  additionalQuestions: [{ title: 'diagnosticSurvey.npi.infoDTitle', content: 'diagnosticSurvey.npi.infoD' }],
  frequency: [{ title: 'diagnosticSurvey.npi.infoETitle', content: 'diagnosticSurvey.npi.infoE' }],
  severity: [{ title: 'diagnosticSurvey.npi.infoFTitle', content: 'diagnosticSurvey.npi.infoF' }],
  neurovegetativeChanges: [{ title: 'diagnosticSurvey.npi.infoHTitle', content: 'diagnosticSurvey.npi.infoH' }],
};
