import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate, sortPartialDate } from 'neuro-utils';

import HistorySection from '../../../../components/HistorySection';
import HistoryRowControls from '../../../../components/HistoryRowControls';
import HistoryRow from '../../../../components/HistoryRow';
import HistoryRowVerticalItem from '../../../../components/HistoryRowVerticalItem';
import DocumentCreationButton from '../../../../components/DocumentCreationButton';

const pbaSFields = [
  'depressedMood',
  'suicidalIdeation',
  'anxiety',
  'irritability',
  'angryOrAggressiveBehaviour',
  'apathy',
  'perseveration',
  'obsessiveCompulsiveBehaviours',
  'delusionsOrParanoidThinking',
  'hallucinations',
  'disorientedBehaviour',
];

const HistoryRowContent = ({ document }: { document: IPBAS }): JSX.Element => (
  <>
    {pbaSFields.map((f) => {
      const severity: string | number | undefined = document[(f + 'Severity') as keyof IPBAS] as
        | string
        | number
        | undefined;
      const frequency: string | number | undefined = document[(f + 'Frequency') as keyof IPBAS] as
        | string
        | number
        | undefined;
      const score =
        (severity || severity === 0) &&
        (frequency || frequency === 0) &&
        ![8, 9, 'U', 'N'].includes(severity) &&
        !['U', 'N'].includes(`${frequency}`)
          ? (parseInt(severity.toString()) * parseInt(frequency.toString())).toString()
          : (severity || severity === 0) && (frequency || frequency === 0)
            ? 'N/A'
            : '-';
      const worst = document[(f + 'Worst') as keyof IPBAS] ?? '-';
      return (
        <HistoryRowVerticalItem
          key={f}
          header={<FormattedMessage id={`behaviour.labels.pbaS.${f}`} />}
          value={
            <>
              {f !== 'hallucinations'
                ? `Score: ${score}, Worst: ${worst}`
                : `Score: ${score}, Worst: ${worst}, Modality: `}
              {f === 'hallucinations' &&
                (document.hallucinationsModality && Array.isArray(document.hallucinationsModality)
                  ? document.hallucinationsModality
                  : []
                ).map((mod: string, i: number) => (
                  <span key={mod}>
                    <FormattedMessage id={`behaviour.opts.pbaS.modality.${mod}`} />
                    {i <
                    (document.hallucinationsModality && Array.isArray(document.hallucinationsModality)
                      ? document.hallucinationsModality
                      : []
                    ).length -
                      1
                      ? ', '
                      : ''}
                  </span>
                ))}
            </>
          }
        />
      );
    })}
  </>
);

const PBAS = ({ documents, startEdit, view }: IOwnProps): JSX.Element => {
  const sortedDocs = documents.sort((n1, n2) => sortPartialDate(n1.date, n2.date)).reverse();
  return (
    <HistorySection
      headerText={<FormattedMessage id="behaviour.pbaS.pbaS" />}
      hasHistoryRows
      newButton={
        <DocumentCreationButton
          name="pbaS"
          text="behaviour.pbaS.newPbaS"
          onClick={startEdit({}, 'pbaS')}
          width={15}
          height={3}
          fontSize={14}
          alternate={true}
        />
      }
    >
      {sortedDocs.map((r) => (
        <HistoryRow
          headerText={r.date ? formatPartialDate(r.date) : undefined}
          key={r._id}
          rowButton={<HistoryRowControls document={r} startEdit={startEdit} view={view} />}
          controlsMargin={false}
        >
          <HistoryRowContent document={r} />
        </HistoryRow>
      ))}
    </HistorySection>
  );
};

interface IOwnProps {
  documents: IPBAS[];
  startEdit: (document: TAnyObject, name?: string) => (e: React.MouseEvent<Element, MouseEvent>) => void;
  view?: IView;
}

export default PBAS;
