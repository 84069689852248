import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FormRow from '../../../../components/FormRow';
import FormSection from '../../../../components/FormSection';
import InputHandler from '../../../../components/InputHandler';
import { otherSymptoms } from '../../utils';

const OtherSymptomsForm = ({ editing, formData }: IOwnProps): JSX.Element => (
  <FormSection>
    <FormRow title="otherSymptoms.startDate">
      <InputHandler
        type="PartialDate"
        editing={!!editing}
        name="date"
        formData={formData}
        dateDefault="now"
        isNotCancellable={true}
      />
    </FormRow>
    <FormRow title="otherSymptoms.endDate">
      <InputHandler type="PartialDate" formData={formData} name="endDate" editing={true} />
    </FormRow>
    <FormRow title="otherSymptoms.classification">
      <InputHandler
        type="Radio"
        editing={!!editing}
        name="classification"
        formData={{
          onChange: (values: TOnChangeValues): void => {
            const { onChange } = formData;
            if (!onChange) return;
            const name = Object.keys(values)[0];
            const value = values[name];
            onChange({ [name]: value, symptoms: undefined, freeText: undefined });
          },
          document: formData.document,
        }}
        options={Object.keys(otherSymptoms)}
        optionFormatter={(opt: number | string): JSX.Element => <FormattedMessage id={`otherSymptoms.${opt}.title`} />}
      />
    </FormRow>
    {formData.document?.classification && (
      <FormRow title="otherSymptoms.symptoms">
        <InputHandler
          type="Radio"
          editing={!!editing}
          name="symptoms"
          formData={formData}
          options={otherSymptoms[formData.document.classification]}
          optionFormatter={(opt: number | string): JSX.Element => (
            <FormattedMessage id={`otherSymptoms.${formData.document.classification}.opts.${opt}`} />
          )}
        />
        {formData.document.symptoms === 'other' ? (
          <InputHandler
            type="TextField"
            editing={!!editing}
            name="freeText"
            formData={formData}
            placeholder={'otherSymptoms.symptoms'}
          />
        ) : undefined}
      </FormRow>
    )}
  </FormSection>
);

interface IOwnProps {
  editing?: string;
  formData: IFormData<IOtherSymptoms>;
  document?: IOtherSymptoms;
}

export default OtherSymptomsForm;
