export const steps = [
  'entryItem',
  { handFromLapToTable: ['handFromLapToTableRight', 'handFromLapToTableLeft'] },
  { tracingAPath: ['tracingAPathRight', 'tracingAPathLeft'] },
  { pickUpTokens: ['pickUpTokensRight', 'pickUpTokensLeft'] },
  { placeTokenIntoCup: ['placeTokenIntoCupRight', 'placeTokenIntoCupLeft'] },
  { reachToSideAndTouchToken: ['reachToSideAndTouchTokenRight', 'reachToSideAndTouchTokenLeft'] },
  { pushButtonLight: ['pushButtonLightRight', 'pushButtonLightLeft'] },
  'tearingPaper',
  { openZipLockContainer: ['openZipLockContainerRight', 'openZipLockContainerLeft'] },
  { cupToMouth200G: ['cupToMouth200GRight', 'cupToMouth200GLeft'] },
  { moveWeightOnTableHorizontal200G: ['moveWeightOnTableHorizontal200GRight', 'moveWeightOnTableHorizontal200GLeft'] },
  { moveWeightOnTableHorizontal500G: ['moveWeightOnTableHorizontal500GRight', 'moveWeightOnTableHorizontal500GLeft'] },
  { moveWeightOnTableDiagonal200G: ['moveWeightOnTableDiagonal200GRight', 'moveWeightOnTableDiagonal200GLeft'] },
  'weightFromLapToTable500G',
  'bringBothArmsAboveHeadAbduction',
  {
    aboveShoulderHeight500GAbduction: ['aboveShoulderHeight500GAbductionRight', 'aboveShoulderHeight500GAbductionLeft'],
  },
  { aboveShoulderHeight1KGAbduction: ['aboveShoulderHeight1KGAbductionRight', 'aboveShoulderHeight1KGAbductionLeft'] },
  { handAboveShoulderHeightFlexion: ['handAboveShoulderHeightFlexionRight', 'handAboveShoulderHeightFlexionLeft'] },
  { aboveShoulderHeight500GFlexion: ['aboveShoulderHeight500GFlexionRight', 'aboveShoulderHeight500GFlexionLeft'] },
  { aboveShoulderHeight1KGFlexion: ['aboveShoulderHeight1KGFlexionRight', 'aboveShoulderHeight1KGFlexionLeft'] },
] as Array<string | { [key: string]: string[] }>;

export const getStepContent = (
  stepName: string,
): { opts: number[]; optsLocale: string; info?: string; height?: number; assets?: number[] } => {
  switch (stepName) {
    case 'entryItem':
      return { opts: [0, 1, 2, 3, 4, 5, 6], optsLocale: 'entryItem' };
    case 'handFromLapToTableRight':
    case 'handFromLapToTableLeft':
      return { opts: [0, 1, 2], optsLocale: 'handFromLapToTable' };
    case 'tracingAPathRight':
    case 'tracingAPathLeft':
      return { opts: [0, 1, 2], optsLocale: 'tracingAPath' };
    case 'pickUpTokensRight':
    case 'pickUpTokensLeft':
      return { opts: [0, 1, 2], optsLocale: 'pickUpTokens' };
    case 'placeTokenIntoCupRight':
    case 'placeTokenIntoCupLeft':
      return { opts: [0, 1, 2], optsLocale: 'placeTokenIntoCup', height: 43, assets: [0, 1, 2] };
    case 'reachToSideAndTouchTokenRight':
    case 'reachToSideAndTouchTokenLeft':
      return { opts: [0, 1, 2], optsLocale: 'reachToSideAndTouchToken', height: 17 };
    case 'pushButtonLightRight':
    case 'pushButtonLightLeft':
      return { opts: [0, 1, 2], optsLocale: 'pushButtonLight' };
    case 'tearingPaper':
      return { opts: [0, 1, 2], optsLocale: 'tearingPaper' };
    case 'openZipLockContainerRight':
    case 'openZipLockContainerLeft':
      return { opts: [0, 1], optsLocale: 'openZipLockContainer' };
    case 'cupToMouth200GRight':
    case 'cupToMouth200GLeft':
      return { opts: [0, 1, 2], optsLocale: 'cupToMouth200G' };
    case 'moveWeightOnTableHorizontal200GRight':
    case 'moveWeightOnTableHorizontal200GLeft':
      return { opts: [0, 1, 2], optsLocale: 'moveWeightOnTableHorizontal200G' };
    case 'moveWeightOnTableHorizontal500GRight':
    case 'moveWeightOnTableHorizontal500GLeft':
      return { opts: [0, 1, 2], optsLocale: 'moveWeightOnTableHorizontal500G' };
    case 'moveWeightOnTableDiagonal200GRight':
    case 'moveWeightOnTableDiagonal200GLeft':
      return { opts: [0, 1, 2], optsLocale: 'moveWeightOnTableDiagonal200G' };
    case 'weightFromLapToTable500G':
      return { opts: [0, 1, 2], optsLocale: 'weightFromLapToTable500G' };
    case 'bringBothArmsAboveHeadAbduction':
      return { opts: [0, 1, 2], optsLocale: 'bringBothArmsAboveHeadAbduction' };
    case 'aboveShoulderHeight500GAbductionRight':
    case 'aboveShoulderHeight500GAbductionLeft':
      return { opts: [0, 1, 2], optsLocale: 'aboveShoulderHeight500GAbduction' };
    case 'aboveShoulderHeight1KGAbductionRight':
    case 'aboveShoulderHeight1KGAbductionLeft':
      return { opts: [0, 1, 2], optsLocale: 'aboveShoulderHeight1KGAbduction' };
    case 'handAboveShoulderHeightFlexionRight':
    case 'handAboveShoulderHeightFlexionLeft':
      return { opts: [0, 1, 2], optsLocale: 'handAboveShoulderHeightFlexion' };
    case 'aboveShoulderHeight500GFlexionRight':
    case 'aboveShoulderHeight500GFlexionLeft':
      return { opts: [0, 1, 2], optsLocale: 'aboveShoulderHeight500GFlexion' };
    case 'aboveShoulderHeight1KGFlexionRight':
    case 'aboveShoulderHeight1KGFlexionLeft':
      return { opts: [0, 1, 2], optsLocale: 'aboveShoulderHeight1KGFlexion' };
    default:
      return { opts: [0], optsLocale: '' };
  }
};
