import * as React from 'react';

import FormRow from '../../../../../components/FormRow';
import InputHandler from '../../../../../components/InputHandler';
import QuestionStepper from '../../../../../components/QuestionStepper';

import { getStepContent as getSteps3, steps as steps3 } from '../updrsIIISettings';
import { getStepContent as getSteps3Old, steps as steps3Old } from '../updrsIIIOldSettings';
import FormSection from '../../../../../components/FormSection';
import { isUpdrsComplete, mdsUPDRSFields, updrsFields, updrsScoreSum } from '../../../utils';
import { FormattedMessage, useIntl } from 'react-intl';
import colors from '../../../../../config/theme/colors';
import { styled } from '@mui/system';
import InfoPopover from '../../../../../components/InfoPopover';
import { useSelector } from 'react-redux';
import SimpleList from 'Components/SimpleList';
import { getMissingFields } from 'Utility/documentHandling';

const phaseFormatter = (o: string | number): JSX.Element => (
  <div style={{ display: 'inline-block', textTransform: 'capitalize' }}>{o}</div>
);

const StyledScore = styled('b')({
  fontSize: '2rem',
  fontWeight: 600,
  color: colors.primary,
  textTransform: 'uppercase',
  marginRight: '2rem',
  marginLeft: '2lem',
});

const UPDRSIIIForm = ({ formData, viewing, documents }: IOwnProps): JSX.Element => {
  const uiLanguage = useSelector((s: IState) => s.settings.userSettings.uiLanguage);

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  React.useEffect(() => {
    // Make here the decision of which test is rendered and which test is the alternative one
    let temp: 'UPDRS' | 'MDSUPDRS';
    if (documents && documents.length > 0) {
      if (documents.filter((item) => item.testType === 'UPDRS' && !item.type).length > 0) {
        temp = 'UPDRS';
      } else {
        temp = 'MDSUPDRS';
      }
    } else {
      temp = 'MDSUPDRS';
    }
    if (!formData.document.testType) {
      formData.onChange &&
        formData.onChange({
          testType: temp,
        });
    }
  }, [documents, formData]);

  const onTestTypeChange = (values: TOnChangeValues): void => {
    const value = values['testType'];
    formData.onChange && formData.onChange({ testType: value });
    if (value === 'MDSUPDRS' && formData.document?.retrospectiveData?.[0] === true) {
      formData.onChange && formData.onChange({ retrospectiveData: [] });
    }
  };

  return (
    <React.Fragment>
      <FormSection>
        <FormRow title="general.date">
          <InputHandler
            type="PartialDate"
            editing={!viewing}
            name="date"
            formData={formData}
            dateDefault="now"
            isNotCancellable={true}
          />
        </FormRow>
        <FormRow
          title="updrs.state"
          description={<FormattedMessage id="updrs.stateDescription" values={{ ln: <br /> }} />}
        >
          <InputHandler
            type="Radio"
            editing={!viewing}
            name="state"
            formData={formData}
            options={['on', 'off']}
            optionFormatter={phaseFormatter}
          />
        </FormRow>
        <FormRow title="updrs.minutesSinceLevodopa">
          <InputHandler
            type="NumberField"
            editing={!viewing}
            name="minutesSinceLevodopa"
            formData={formData}
            placeholder="updrs.minutes"
            width={6}
            height={3}
            maxLength={6}
            min={0}
            max={1440}
            precision={0}
          />
        </FormRow>
        <FormRow title={'updrs.updrsOrMdsupdrs'}>
          <InputHandler
            type="Radio"
            name="testType"
            editing={!viewing}
            formData={{
              onChange: onTestTypeChange,
              document: formData.document,
            }}
            options={['UPDRS', 'MDSUPDRS']}
            optionFormatter={(n: string | number): JSX.Element => (
              <FormattedMessage id={`updrs.${n === 'UPDRS' ? 'updrsIII' : 'mds'}`} />
            )}
            disabledOptions={
              updrsScoreSum(formData.document) > 0 || formData.document.speech ? ['UPDRS', 'MDSUPDRS'] : []
            }
          />
        </FormRow>
        <FormRow title="updrs.totalScoreEntry">
          <InputHandler
            type="Checkbox"
            editing={!viewing}
            name="retrospectiveData"
            formData={formData}
            preset="yes"
            disabled={formData.document.testType === 'MDSUPDRS'}
          />
        </FormRow>
      </FormSection>
      {!formData.document.retrospectiveData?.[0] && (
        <React.Fragment>
          {formData.document?.testType && formData.document.testType === 'UPDRS' ? (
            <FormSection header="updrs.updrsIII">
              <QuestionStepper
                formData={formData}
                steps={steps3Old}
                pageType={'updrs'}
                docType={'iii'}
                getStepContent={getSteps3Old}
                viewing={viewing}
                numberFormatter={(n) => (n === 'UR' && uiLanguage === 'fi' ? 'EA' : n)}
              />
            </FormSection>
          ) : (
            <FormSection header="updrs.mds">
              <div style={{ fontStyle: 'italic', color: colors.darkGray, margin: '2rem 0 3rem 0' }}>
                <FormattedMessage id={'updrs.mdsPermission'} />
              </div>
              <QuestionStepper
                formData={formData}
                steps={steps3}
                pageType={'updrs'}
                docType={'iii'}
                getStepContent={getSteps3}
                viewing={viewing}
                numberFormatter={(n) => (n === 'UR' && uiLanguage === 'fi' ? 'EA' : n)}
              />
            </FormSection>
          )}
          {formData.document?.testType && formData.document.testType === 'MDSUPDRS' && (
            <FormSection header="updrs.dyskinesiaImpact">
              <FormRow title="updrs.dyskinesiaPresent">
                <InputHandler
                  type="Radio"
                  editing={!viewing}
                  name="dyskinesiaPresent"
                  formData={formData}
                  preset="yesno"
                />
              </FormRow>
              <FormRow title="updrs.dyskinesiaInterference">
                <InputHandler
                  type="Radio"
                  editing={!viewing}
                  name="dyskinesiaInterference"
                  formData={formData}
                  preset="yesno"
                />
              </FormRow>
            </FormSection>
          )}
        </React.Fragment>
      )}
      <FormSection header={formData.document?.retrospectiveData?.[0] ? 'updrs.updrsIII' : undefined}>
        {!formData.document.retrospectiveData?.[0] ? (
          <React.Fragment>
            <FormRow title="updrs.score" bottomMargin={true}>
              {!isUpdrsComplete(formData.document, formData.document.testType) ? (
                <InfoPopover
                  text={
                    <SimpleList
                      title={fm('general.couldntCalculate')}
                      listItems={getMissingFields(
                        formData.document.testType === 'MDSUPDRS' ? mdsUPDRSFields : updrsFields,
                        formData.document,
                      ).map((f) => {
                        const usedSteps = formData.document.testType === 'MDSUPDRS' ? steps3 : steps3Old;
                        if (usedSteps.findIndex((s) => s === f) >= 0) {
                          return fm(`updrs.labels.iii.${f}`);
                        } else {
                          const mainLabelObj = usedSteps.find(
                            (s) => typeof s !== 'string' && Object.values(s)?.[0]?.includes(f),
                          );
                          const mainLabel = mainLabelObj && Object.keys(mainLabelObj)?.[0];
                          return `${fm(`updrs.labels.iii.${mainLabel}`)}: ${fm(`updrs.labels.iii.${f}`)}`;
                        }
                      })}
                    />
                  }
                  color="primary"
                />
              ) : (
                <StyledScore>{updrsScoreSum(formData.document)}</StyledScore>
              )}
            </FormRow>
            {formData.document?.testType && formData.document.testType === 'MDSUPDRS' ? (
              <span style={{ fontStyle: 'italic', color: colors.darkGray }}>
                <FormattedMessage id="updrs.copyrightMDSIII" />
              </span>
            ) : (
              <span style={{ fontStyle: 'italic', color: colors.darkGray }}>
                Fahn S, Elton R, Members of the UPDRS Development Committee. In: Fahn S, Marsden CD, Calne DB, Goldstein
                M, eds. Recent Developments in Parkinson’s Disease. Vol.2. Florham Park, NJ. Macmillan Health Care
                Information 1987, pp153163, 293¬304
              </span>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <FormRow title="updrs.manualScore" bottomMargin={true}>
              <InputHandler
                type="NumberField"
                editing={!viewing}
                name="manualScore"
                formData={formData}
                placeholder="updrs.scoreManual"
                min={0}
                max={108}
                width={12.6}
                height={3}
                maxLength={3}
              />
            </FormRow>
            <span style={{ fontStyle: 'italic', color: colors.darkGray }}>
              Fahn S, Elton R, Members of the UPDRS Development Committee. In: Fahn S, Marsden CD, Calne DB, Goldstein
              M, eds. Recent Developments in Parkinson’s Disease. Vol.2. Florham Park, NJ. Macmillan Health Care
              Information 1987, pp153163, 293¬304
            </span>
          </React.Fragment>
        )}
      </FormSection>
    </React.Fragment>
  );
};

interface IOwnProps {
  formData: IFormData<IUPDRSIII>;
  viewing: boolean;
  documents?: IUPDRSIII[];
}

export default UPDRSIIIForm;
