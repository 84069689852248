import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import HistoryRowVerticalItem from 'Components/HistoryRowVerticalItem';
import { formatPartialDate, isPartialDate } from 'neuro-utils';
import * as React from 'react';
import { DoctorsOrdersContext } from '../..';

const OtherHistory = ({ d }: IOtherHistory): JSX.Element => (
  <DoctorsOrdersContext.Consumer>
    {({ fm }) => {
      return (
        <React.Fragment>
          <HistoryRowSubHeader header={fm('doctorsOrders.other.header')} />
          {d.treatmentType?.includes('rtms') && (
            <HistoryRowVerticalItem
              header={fm('doctorsOrders.other.nextRMT.title')}
              value={d.rtms && isPartialDate(d.nextRMT) ? formatPartialDate(d.nextRMT) : '-'}
              condition={isPartialDate(d.nextRMT)}
            />
          )}
          <HistoryRowVerticalItem
            header={fm('doctorsOrders.other.nextInterview.title')}
            value={d.nextInterview ?? '-'}
            condition={!!d.nextInterview}
          />
          <HistoryRowVerticalItem
            header={fm('doctorsOrders.other.ninmtMeeting.title')}
            value={d.ninmtMeeting ? fm(`general.${d.ninmtMeeting}`) : '-'}
            condition={!!d.ninmtMeeting}
          />
          {d.ninmtMeeting === 'yes' && (
            <HistoryRowVerticalItem
              header={fm('doctorsOrders.other.addedToAgenda.title')}
              value={d.addedToAgenda ? fm(`general.${d.addedToAgenda}`) : '-'}
              condition={!!d.addedToAgenda}
            />
          )}
          <HistoryRowVerticalItem
            header={fm('doctorsOrders.other.requestFollowUp.title')}
            value={d.requestFollowUp ? fm(`general.${d.requestFollowUp}`) : '-'}
            condition={!!d.requestFollowUp}
          />
        </React.Fragment>
      );
    }}
  </DoctorsOrdersContext.Consumer>
);

interface IOtherHistory {
  d: IDoctorsOrder;
  mode?: 'default' | 'review';
}

export default OtherHistory;
